import { success } from '@redux-requests/core';
import {
    CLEAR_STEP_AND_MAP_IMAGES,
    SET_MAP_COVER_IMAGE,
    ADD_MAP_TAGS,
    CHANGE_GATES_STATUSES,
    ADD_NEW_TAGS_TO_MAP,
    DELETE_TAGS,
    DELETE_CATEGORIES,
    EDIT_CATEGORY_NAME,
    EDIT_CATEGORY_PRIORITY,
    EDIT_TAG_NAME,
    MOVE_TAG_TO_CATEGORY,
    ADD_NEW_CATEGORY,
    ADD_NEW_CATEGORY_WITH_TAGS,
} from './types';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_MAP_COVER_IMAGE:
            return { ...state, image_temp: action.blobUrl };
        case CLEAR_STEP_AND_MAP_IMAGES:
            return { ...state, stepImage: [], mapImage: [] };
        case success(CHANGE_GATES_STATUSES): {
            const newState = { ...state };
            newState.gates_statuses = action.response.data.gates_statuses;
            newState.permissions_gates = action.response.data.permission_gates;
            return newState;
        }
        case success(ADD_MAP_TAGS): {
            const oldTagObjects = state.tag_objects;
            const oldTagObjectsFind = oldTagObjects.concat(action.response.data.tags);
            return {
                ...state,
                tag_objects: oldTagObjectsFind,
            };
        }
        case ADD_NEW_TAGS_TO_MAP: {
            return {
                ...state,
                tag_objects: [...state.tag_objects, ...action.request.data.tags],
            };
        }
        case success(ADD_NEW_TAGS_TO_MAP): {
            const tagObjects = state.tag_objects;
            const newTags = action.response.data.tags;
            const tagsCategories = state.tags_categories;
            newTags.forEach(newTag => {
                const tagIndex = tagObjects.findIndex(tag => newTag.name === tag.name);
                const tagCategoryIndex = tagsCategories.findIndex(category => category._id === newTag.category_id);
                if (tagCategoryIndex > -1) {
                    newTag.category_priority = tagsCategories[tagCategoryIndex]?.priority;
                }
                if (tagIndex > -1) {
                    tagObjects[tagIndex] = newTag;
                }
            });
            return {
                ...state,
                tag_objects: [...tagObjects],
            };
        }

        case DELETE_TAGS: {
            let updatedTagList = [];
            const deletedTagId = action.request.data.tag_ids;

            updatedTagList = state.tag_objects.filter(function (tag) {
                return deletedTagId.indexOf(tag._id) === -1;
            });

            return {
                ...state,
                tag_objects: updatedTagList,
            };
        }

        case EDIT_TAG_NAME: {
            let updatedTagList = [];
            const editedTagId = action.request.data.tag_id;
            const categoryId = action.request.data.category_id;
            let editedName = action.request.data.name;

            updatedTagList = state.tag_objects.map(function (tag) {
                if (tag._id == editedTagId) {
                    tag.name = editedName;
                    return tag;
                }

                return tag;
            });

            return {
                ...state,
                tag_objects: updatedTagList,
            };
        }
        case MOVE_TAG_TO_CATEGORY: {
            let updatedTagList = [];
            const editedTagId = action.request.data.tag_id;
            const categoryId = action.request.data.category_id;
            // let editedName = action.request.data.name;

            updatedTagList = state.tag_objects.map(tag => {
                if (tag._id == editedTagId) {
                    tag.category_id = categoryId;
                    return tag;
                }

                return tag;
            });

            return {
                ...state,
                tag_objects: updatedTagList,
            };
        }
        case ADD_NEW_CATEGORY: {
            const category = action.request.data.categories[0];
            category.priority = -1;
            return {
                ...state,
                tags_categories: [...state.tags_categories, category],
            };
        }
        case success(ADD_NEW_CATEGORY_WITH_TAGS): {
            let highestProitity = -1;
            state.tags_categories.forEach(category => {
                if (category.priority > highestProitity) highestProitity = category.priority;
            });
            const responseData = action.response.data;
            const createdCategory = responseData.categories[0];
            createdCategory.priority = highestProitity + 1;
            const createdTagsArray = responseData.tags;
            return {
                ...state,
                tags_categories: [...state.tags_categories, createdCategory],
                tag_objects: [...state.tag_objects, ...createdTagsArray],
                // check if it's needed to udpate state.mapData.tags_categories
            };
        }
        case EDIT_CATEGORY_NAME: {
            let updatedCategoryList = [];
            const editedCategoryId = action.request.data.category_id;
            let editedName = action.request.data.name;
            let tagObjects = state.tag_objects;

            let tempPriority = 0;
            state.tags_categories.forEach(c => {
                if (c.priority > tempPriority) {
                    tempPriority = c.priority;
                    // return c;
                }
            });
            const updatedTagObjects = tagObjects.map(tagObject => {
                if (tagObject.category_id === editedCategoryId) {
                    return { ...tagObject, category: editedName };
                } else {
                    return tagObject;
                }
            });
            updatedCategoryList = state.tags_categories.map(function (category) {
                // When user creates new category, we first display it beofre sendinig the request
                // The else if condition set the new _id for the new category the user just created
                if (category._id == editedCategoryId) {
                    category.name = editedName;
                    return category;
                } else if (category._id == category.name) {
                    category.priority = tempPriority + 1;
                    category._id = editedCategoryId;
                    return category;
                }

                return category;
            });

            return {
                ...state,
                tags_categories: updatedCategoryList,
                tag_objects: updatedTagObjects,
            };
        }
        case EDIT_CATEGORY_PRIORITY: {
            let updatedCategoryList = [];
            const editedCategoryId = action.request.data.category_id;
            let editedCategoryPriority = action.request.data.priority;
            updatedCategoryList = state.tags_categories.map(function (category) {
                if (category._id == editedCategoryId) {
                    category.priority = editedCategoryPriority;
                    return category;
                }
                return category;
            });
            return {
                ...state,
                tags_categories: updatedCategoryList,
            };
        }
        case DELETE_CATEGORIES: {
            let updatedCategoryList = [];
            let updatedTagObjects = [];
            const deletedCategoryId = action.request.data.category_ids[0];

            updatedCategoryList = state.tags_categories.filter(function (category) {
                return deletedCategoryId.indexOf(category._id) === -1;
            });
            updatedTagObjects = state.tag_objects.filter(tag => tag.category_id !== deletedCategoryId);

            return {
                ...state,
                tags_categories: updatedCategoryList,
                tag_objects: updatedTagObjects,
            };
        }
        default:
            return state;
    }
};

export default reducer;
