import { IconClap } from '../../common/icons';

import styles from './index.module.scss';

const ClapIndication = ({ clapsCount = 0 }) => {
    if (!clapsCount) {
        return null;
    }
    return (
        <div className={styles.clapIndicationWrapper}>
            <span>{clapsCount}</span>
            <IconClap width={16} height={16} isClapped />
        </div>
    );
};

export default ClapIndication;
