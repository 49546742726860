import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { carouselPropTypes } from './propTypes';
import styles from './index.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import MagicSliderDots from 'react-magic-slider-dots';
import { X } from 'phosphor-react';

const ImageCarousel = ({ images, settings, events, onImageClick, addDeleteIcon = false, onDeleteClick, classNameDiv, classNameImage }) => {
    let drag = false;

    const ImgArrowLeft = ({ onClick, currentSlide: rightArrowClicksNumber }) =>
        rightArrowClicksNumber > 0 && <img className={styles.ImgArrowLeft} src='../../assets/img/arrow-slider.svg' alt='left' onClick={onClick} />;

    const ImgArrowRight = ({ onClick, currentSlide: rightClicksNumber, slideCount: totalImages }) => {
        const hasMoreThanOneImage = totalImages > 1;
        const imagesToShow = settings?.slidesToShow ?? 1.85;
        const hasMoreImages = imagesToShow + rightClicksNumber < totalImages;

        return (
            hasMoreThanOneImage &&
            hasMoreImages && <img className={styles.ImgArrowRight} src='../../assets/img/arrow-slider.svg' alt='right' onClick={onClick} />
        );
    };

    const DeleteButton = ({ imageIndex }) => (
        <div className={styles.deleteButton}>
            <X
                onClick={() => {
                    onDeleteClick?.(imageIndex);
                }}
                size={10}
                color='#3A3C3F'
                weight='bold'
            />
        </div>
    );

    const sliderConfig = {
        ...settings,
        speed: 300,
        adaptiveHeight: true,
        infinite: false,
        prevArrow: <ImgArrowLeft />,
        nextArrow: <ImgArrowRight />,
        className: classNames(settings.className, styles.slider),
        appendDots: dots => {
            return <MagicSliderDots dots={dots} numDotsToShow={images.length > 3 ? 4 : images.length} dotWidth={20} />;
        },
    };

    const getImageSrc = image => {
        if (typeof image === 'object') {
            return image.src;
        }
        return image;
    };

    const shouldRenderDeleteButton = image => {
        if (!addDeleteIcon) return false;
        if (typeof image === 'string') return true;
        if (image?.addDeleteIcon) return true;
        return false;
    };

    return (
        <div onClick={e => e?.stopPropagation()}>
            <Slider {...sliderConfig} {...events} className={styles.slides}>
                {images?.length &&
                    images.map((image, index) => {
                        return (
                            <div key={index} className={classNameDiv}>
                                <img
                                    onMouseDown={() => (drag = false)}
                                    onMouseMove={() => (drag = true)}
                                    onMouseUp={() => {
                                        if (!drag) onImageClick?.(index);
                                    }}
                                    className={classNameImage}
                                    alt='carousel-slide'
                                    src={getImageSrc(image)}
                                />
                                {shouldRenderDeleteButton(image) && <DeleteButton imageIndex={index} />}
                            </div>
                        );
                    })}
            </Slider>
        </div>
    );
};

ImageCarousel.propTypes = carouselPropTypes;

export default ImageCarousel;
