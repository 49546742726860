import { createContext } from 'react';
import { noop } from 'lodash';

const TextInputContext = createContext({
    addMemberTag: noop,
    addPlaceTag: noop,
    editorState: null,
    setEditorState: noop,
});

const TextInputProvider = ({ children }) => {
    const addMemberTag = () => noop;
    const addPlaceTag = () => noop;

    return (
        <TextInputContext.Provider
            value={{
                addMemberTag,
                addPlaceTag,
            }}
        >
            {children}
        </TextInputContext.Provider>
    );
};

export { TextInputProvider, TextInputContext };
