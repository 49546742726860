import {
    GET_POST,
    GET_POST_COMMENTS,
    CLAP_POST,
    ADD_COMMENT,
    PIN_POST,
    ADD_REPLY,
    EDIT_COMMENT,
    DELETE_COMMENT,
    DELETE_REPLY,
    CLAP_COMMENT,
    CLAP_REPLY,
    GET_CLAPS,
    DELETE_POST,
    EDIT_POST,
    EDIT_REPLY,
    OPEN_MORE_REPLIES,
    CLEAR_COMMENTS_CACHE,
    GET_TAGS,
    EDIT_TAGS,
} from './types';

export const getPostComments = (postId, cursor) => ({
    type: GET_POST_COMMENTS,
    request: {
        url: `/steps3/posts`,
        method: 'GET',
        params: { cursor },
    },
    meta: { postId },
});

export const getPost = (postId, cursor = null, commentsLimit = 10) => ({
    type: GET_POST,
    request: {
        method: 'GET',
        url: `/steps3/posts/${postId}`,
        params: { cursor, comments_limit: commentsLimit },
    },
    meta: {
        postId,
        thunk: true,
    },
});

export const clapPost = ({ mapId, stepId, postId, newClapStatus }) => ({
    type: CLAP_POST,
    request: {
        method: 'POST',
        url: `/steps3/posts/${postId}/clap`,
        data: {
            action: newClapStatus,
        },
    },
    meta: {
        mapId,
        stepId,
        postId,
        newClapStatus,
        thunk: true,
    },
});

export const clapComment = (mapId, stepId, postId, commentId, newClapStatus) => ({
    type: CLAP_COMMENT,
    request: {
        method: 'POST',
        url: `/steps3/posts/${commentId}/clap`,
        data: {
            action: newClapStatus,
        },
    },
    meta: {
        mapId,
        stepId,
        postId,
        commentId,
        newClapStatus,
        thunk: true,
    },
});

export const clapReply = (postId, commentId, replyId, newClapStatus) => ({
    type: CLAP_REPLY,
    request: {
        method: 'POST',
        url: `/steps3/posts/${replyId}/clap`,
        data: {
            action: newClapStatus,
        },
    },
    meta: {
        commentId,
        replyId,
        newClapStatus,
        postId,
        thunk: true,
    },
});

export const commentOnPost = ({ data, mapId, stepId, postId, userName, userImageId, createdAt, replaceCurrent }) => ({
    type: ADD_COMMENT,
    request: {
        method: 'POST',
        url: `/steps3/posts`,
        data,
    },
    meta: {
        mapId,
        stepId,
        postId,
        userName,
        userImageId,
        createdAt,
        replaceCurrent,
        thunk: true,
    },
});

export const replyOnComment = ({ data, mapId, stepId, postId, commentId, userName, userImageId, createdAt }) => ({
    type: ADD_REPLY,
    request: {
        method: 'POST',
        url: `/steps3/posts`,
        data,
    },
    meta: {
        mapId,
        stepId,
        postId,
        commentId,
        userName,
        userImageId,
        createdAt,
        thunk: true,
    },
});

export const deleteComment = (mapId, stepId, postId, commentId) => ({
    type: DELETE_COMMENT,
    request: {
        method: 'DELETE',
        url: `/steps3/posts/${commentId}`,
    },
    meta: {
        mapId,
        stepId,
        postId,
        commentId,
        thunk: true,
    },
});

export const deleteReply = (mapId, stepId, postId, commentId, replyId) => ({
    type: DELETE_REPLY,
    request: {
        method: 'DELETE',
        url: `/steps3/posts/${replyId}`,
    },
    meta: {
        mapId,
        stepId,
        postId,
        commentId,
        replyId,
        thunk: true,
    },
});

export const editComment = (id, parentPostId, data) => ({
    type: EDIT_COMMENT,
    request: {
        method: 'PUT',
        url: `/steps3/posts/${id}`,
        data,
    },
    meta: {
        mapId: data?.mapId,
        commentId: id,
        postId: parentPostId,
        stepId: data?.stepId,
        thunk: true,
    },
});

export const editReply = (id, postId, commentId, data) => ({
    type: EDIT_REPLY,
    request: {
        method: 'PUT',
        url: `/steps3/posts/${id}`,
        data,
    },
    meta: {
        mapId: data?.mapId,
        replyId: id,
        stepId: data?.stepId,
        postId,
        commentId,
        thunk: true,
    },
});

export const pinPost = (postId, newPinStatus) => ({
    type: PIN_POST,
    request: {
        method: 'POST',
        url: `/steps3/posts/${postId}/pin`,
        data: {
            action: newPinStatus,
        },
    },
    meta: {
        postId,
        action: newPinStatus,
        thunk: true,
    },
});

export const getClaps = (postId, cursor) => ({
    type: GET_CLAPS,
    request: {
        method: 'GET',
        url: `/steps3/posts/${postId}/claps`,
        params: {
            cursor,
        },
    },
    meta: {
        postId,
        thunk: true,
    },
});

export const deletePost = (mapId, stepId, postId) => ({
    type: DELETE_POST,
    request: {
        method: 'DELETE',
        url: `/steps3/posts/${postId}`,
    },
    meta: {
        mapId,
        stepId,
        postId,
        thunk: true,
    },
});

export const editPost = (id, data) => ({
    type: EDIT_POST,
    request: {
        method: 'PUT',
        url: `/steps3/posts/${id}`,
        data,
    },
    meta: {
        postId: id,
        data,
        thunk: true,
    },
});

export const openMoreReplies = ({ postId, commentId, repliesOpened, cursor }) => ({
    type: OPEN_MORE_REPLIES,
    request: {
        method: 'GET',
        url: `/steps3/posts/`,
        params: { cursor },
    },
    meta: {
        postId,
        commentId,
        repliesOpened,
    },
});

export const clearCommentsCache = ({ postId }) => ({
    type: CLEAR_COMMENTS_CACHE,
    payload: {
        postId,
    },
});

export const getTags = ({ searchTerm, mapId, lat = 0, lon = 0, tagListType = 'USERS' }) => ({
    type: GET_TAGS,
    request: {
        method: 'GET',
        url: `/steps3/tagging`,
        params: {
            search_term: searchTerm,
            map_id: mapId,
            tags_list_type: tagListType,
            lat,
            lon,
        },
    },
});

export const editTags = (postId = null, textArray = []) => {
    return {
        type: EDIT_TAGS,
        request: {
            method: 'PUT',
            url: `/steps3/tagging/${postId}`,
            data: {
                text_array: textArray,
            },
        },
        meta: {
            postId,
            textArray,
        },
    };
};
