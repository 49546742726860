import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { useQuery } from '../../hooks/useQuery';
import { PLATFORM_TYPE } from '../../config/constants';
import LoginInput from '../../components/common/inputs/LoginInput';
import { userActions } from '../../redux/user';
import CustomButton, { ButtonType } from '../../components/common/buttons/CustomButton';
import ResetSuccess from './components/ResetSuccess';
import copiesPrefix from '../../copies.json';
import Logo from '../../components/common/Logo';

const copies = copiesPrefix.createPassword;

const ResetPassword = () => {
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [typePassword, setTypePassword] = useState('password');
    const [typeRepeatPassword, setTypeRepeatPassword] = useState('password');
    const [isError, setError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();
    const { token } = query;
    
    const handlePasswordChange = useCallback(e => {
        setPassword(e.target.value);
    }, []);
    
    const handleRepeatPasswordChange = useCallback(e => {
        setRepeatPassword(e.target.value);
    }, []);
    
    const onSubmit = useCallback(() => {
        setLoader(true);
        const clientType = PLATFORM_TYPE.MAP_MANAGER;
        const data = JSON.stringify({ token, password, clientType });
        dispatch(userActions.resetPassword(data)).then(({ payload, error }) => {
            if (payload) {
                setSuccess(true);
            } else if (error) {
                setLoader(false);
                setError(true);
            }
        });
    }, [navigate, password, repeatPassword, token]);
    
    if (success) {
        return <ResetSuccess/>;
    }
    
    return (
        <div className={styles.manualResetBlock}>
            <span className="absolute top-4 left-4 md:top-10 md:left-10">
                <Logo/>
            </span>
            <div className={styles.textCenter}>
                <div className={styles.titleText}>{copies.title}</div>
                <div className={styles.forgotPasswordText}>
                    <span>{copies.description}</span>
                </div>
                <div className={styles.forgotEmailBlock}>
                    <LoginInput
                        setTypePassword={setTypePassword}
                        typePassword={typePassword}
                        onClick={() => setError(false)}
                        type={typePassword}
                        setState={setPassword}
                        onChange={handlePasswordChange}
                        value={password}
                        errorText={copies.password_error}
                        error={isError}
                        placeholder={copies.password_input_placeholder}
                        onKeyDown={null}
                    />
                    <LoginInput
                        setTypePassword={setTypeRepeatPassword}
                        typePassword={typeRepeatPassword}
                        onClick={() => setError(false)}
                        type={typeRepeatPassword}
                        setState={setRepeatPassword}
                        onChange={handleRepeatPasswordChange}
                        value={repeatPassword}
                        errorText={copies.repeat_password_error}
                        error={isError}
                        placeholder={copies.confirm_password_input_placeholder}
                        onKeyDown={null}
                    />
                </div>
                <CustomButton
                    size={'largeSize'}
                    onClick={onSubmit}
                    className={styles.loginBtnCont}
                    type={ButtonType.PRIMARY_BLUE}
                    text={copies.save_button_text}
                    disabled={password.length === 0 || repeatPassword.length === 0 || password !== repeatPassword || loader}
                    loader={loader}
                />
            </div>
        </div>
    );
};

export default ResetPassword;
