import { useState, useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import Comment from '../Comment';
import classNames from 'classnames';
import InfiniteComments from '../InfiniteComments';
import { useDispatch, useSelector } from 'react-redux';
import { postSelectors, postActions } from '../../../redux/posts';
import { noop } from 'lodash';

export const ShowCommentsOptions = {
    ALL: 'SHOW_ALL',
    NONE: 'SHOW_NONE',
    PARTIAL: 'SHOW_PARTIAL',
};

const CommentsSection = ({
    postId,
    comments = [],
    cursor = null,
    commentsSettings = {},
    repliesSettings = {},
    isReply = false,
    repliesCursor = null,
    showReplies = true,
    totalComments,
    relatedCommentId = '',
    scrollableTarget = '',
    commentId = '',
    className,
    onShowMoreCommentClicked = noop,
    fromWhere,
}) => {
    const dispatch = useDispatch();

    const { isShowAll, numberOfLastCommentsToShow, numberToLoad } = isReply ? repliesSettings : commentsSettings;
    const [isLoading, setIsLoading] = useState(false);

    const onShowPreviousClick = async () => {
        if (isReply) {
            setIsLoading(true);
            if (comments.length < totalComments)
                await dispatch(
                    postActions.openMoreReplies({
                        postId,
                        commentId,
                        repliesOpened: comments.length,
                        cursor: repliesCursor,
                    })
                );
            setIsLoading(false);
        } else {
            onShowMoreCommentClicked();
        }
    };

    const PREVIOUS_NUMBER = totalComments - comments.length;
    const COMMENT_OR_REPLY = isReply ? 'Reply' : 'Comment';
    const SHOW_PREVIOUS_MESSAGE = `Show ${PREVIOUS_NUMBER} more ${COMMENT_OR_REPLY}`;

    return (
        isShowAll !== ShowCommentsOptions.NONE && (
            <div className={classNames(styles.container, className)}>
                {isShowAll === ShowCommentsOptions.ALL && comments?.length > 0 ? (
                    <InfiniteComments
                        postId={postId}
                        comments={comments}
                        cursor={cursor}
                        repliesSettings={repliesSettings}
                        showReplies={showReplies}
                        isReply={isReply}
                        className={styles.infiniteCommentsStyle}
                        scrollableTarget={scrollableTarget}
                    />
                ) : (
                    <>
                        {comments?.map(comment => (
                            <Comment
                                fromWhere={fromWhere}
                                key={comment.id}
                                comment={comment}
                                showReplies={showReplies}
                                repliesSettings={repliesSettings}
                                isReply={isReply}
                                relatedCommentId={relatedCommentId}
                            />
                        ))}
                        {isLoading ? (
                            <span className={styles.loadingBlock}>
                                <img className={styles.loading} src='/assets/img/loadingSpinner.svg' alt='loading' />
                                <span>Loading</span>
                            </span>
                        ) : (
                            totalComments > comments?.length &&
                            !!numberOfLastCommentsToShow && (
                                <span
                                    className={classNames(styles.loadPreviousComments, styles.loadPreviousReplies)}
                                    onClick={onShowPreviousClick}
                                >
                                    {SHOW_PREVIOUS_MESSAGE}
                                </span>
                            )
                        )}
                    </>
                )}
            </div>
        )
    );
};

export default CommentsSection;

CommentsSection.propTypes = {
    comments: PropTypes.array,
    commentsSettings: PropTypes.exact({
        // isShowAll: PropTypes.oneOf([ShowCommentsOptions.ALL, ShowCommentsOptions.NONE, ShowCommentsOptions.PARTIAL]),
        numberOfLastCommentsToShow: PropTypes.number,
        numberToLoad: PropTypes.number,
    }),
    repliesSettings: PropTypes.exact({
        isShowAll: PropTypes.oneOf([ShowCommentsOptions.ALL, ShowCommentsOptions.NONE, ShowCommentsOptions.PARTIAL]),
        numberOfLastCommentsToShow: PropTypes.number,
        numberToLoad: PropTypes.number,
    }),
    isReply: PropTypes.bool,
    showReplies: PropTypes.bool,
};

CommentsSection.defualtProps = {
    commentsSettings: {
        isShowAll: true,
        numberOfLastCommentsToShow: 1,
    },
};
