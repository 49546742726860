import { useState, useRef, useEffect } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';
import { MixpanelTagContext } from '../../../constants';
import { TagActions } from '../constants';
import { TagButton, UntagButton } from './TagButton';
import { mergeAdjacentTextArrayTextItems, htmlToTextArray, isPostContentChanged, removeTag, createLocationTagSpan, insertLocationTagToWrapper } from '../utils';
import LocationSuggestionsPopup from './LocationSuggestionPopover';

import styles from '../TagLocationInPost.module.scss';

const track = ({ text, postId, mapId, mapName }) => {
    mixpanel.track(mixpanelEvents.POST_TAG_POPUP_SHOWED, {
        [mixpanelProperties.POST_ID]: postId,
        [mixpanelProperties.MAP_ID]: mapId,
        [mixpanelProperties.CONTEXT]: MixpanelTagContext.WHILE_POSTING,
        [mixpanelProperties.MAP_NAME]: mapName,
        [mixpanelProperties.TEXT]: text,
    });
};

const TagLocationPopover = ({
    textId,
    tagIds,
    postId,
    mapId = '',
    mapName = '',
    selectedText = '',
    action = null,
    isActive = false,
    onTag = noop,
    onUntag = noop,
    onClose = noop,
    position = {},
    onError = noop,
    lastRange,
    textArray = [],
}) => {
    const popupRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [lastSelectionValue, setLastSelectionValue] = useState(selectedText);
    const [isLocationSuggestionView, setIsLocationSuggestionView] = useState(false);
    const isTagView = isActive && !isLocationSuggestionView && action === TagActions.TAG;
    const isUntagView = isActive && !isLocationSuggestionView && action === TagActions.UNTAG;

    const handleClose = () => {
        setIsLocationSuggestionView(false);
        setSuggestions([]);
        onClose();
    };

    useClickAway(popupRef, handleClose);

    useEffect(() => {
        if (!isLocationSuggestionView) {
            return;
        }

        track({ text: lastSelectionValue, postId, mapId, mapName });
    }, [isLocationSuggestionView]);

    useEffect(() => {
        if (!selectedText) {
            return;
        }

        setLastSelectionValue(selectedText);
    }, [selectedText]);

    useEffect(() => {
        window.addEventListener('scroll', handleClose, true);
    }, []);

    // TODO: disable tagging if API is pending
    return (
        <div
            id='tag-location-popup'
            role='presentation'
            ref={popupRef}
            style={{ top: position?.top || 0, left: position?.left || 0 }}
            className={classNames(styles.tagLocationContainer, suggestions.length ? 'initial' : 'flex', {
                [styles.isHidden]: !isActive,
            })}
        >
            {isTagView && (
                <TagButton
                    onClick={() => {
                        if (tagIds.length > 0) {
                            // shouldn't be able to tag with tagIds within selection
                            console.error('unexpected number of tags', { tagIds });
                            onError();
                            return;
                        }
                        setIsLocationSuggestionView(true);
                    }}
                />
            )}
            {isUntagView && (
                <UntagButton
                    onClick={() => {
                        const post = document.getElementById(textId); // TODO: use ref instead of id
                        if (tagIds.length !== 1) {
                            // shouldn't be able to untag more than 1 tag at a time
                            console.error('unexpected number of tags', { tagIds });
                            onError();
                            return;
                        }
                        removeTag(tagIds[0], onError);
                        const newTextArray = mergeAdjacentTextArrayTextItems(htmlToTextArray(post));
                        const isPostChanged = isPostContentChanged({
                            textArray,
                            newTextArray,
                            onError,
                        });

                        if (isPostChanged) {
                            throw new Error('post content changed');
                        }

                        onUntag({ textArray: newTextArray });
                    }}
                />
            )}
            {isActive && isLocationSuggestionView && (
                <LocationSuggestionsPopup
                    onClick={locationData => {
                        const post = document.getElementById(textId); // TODO: use ref instead of id
                        insertLocationTagToWrapper(lastRange, createLocationTagSpan(locationData), textId, onError);
                        // TODO: next - postClone?
                        const newTextArray = mergeAdjacentTextArrayTextItems(htmlToTextArray(post));
                        const isPostChanged = isPostContentChanged({
                            textArray,
                            newTextArray,
                            onError,
                        });

                        if (isPostChanged) {
                            console.error('post content changed');
                            return;
                        }

                        onTag({ textArray: newTextArray, location: locationData, tagText: selectedText });
                    }}
                    searchTerm={lastSelectionValue}
                    mapId={mapId}
                />
            )}
        </div>
    );
};

export default TagLocationPopover;
