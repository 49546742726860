import { useState } from 'react';
import styles from './DiscoveredBlock.module.scss';
import copies from '../../../copies.json';
import { useNavigate, generatePath } from 'react-router-dom';
import Routes from '../../../config/routes';
import { AvatarSizes, Avatar } from '../../common/Avatar';
import { generateImageUrl } from '../../../helpers';
import { UserTagPopover } from '../../common/TextArrayBody/components/UserTagPopover';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const DiscoveredBlock = ({ createdAt, className, step, user = {} }) => {
    const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
    const navigate = useNavigate();

    const onClickStepTitle = () => {
        const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId: step?.id });
        navigate(url);
    };

    return (
        <ClickAwayListener onClickAway={() => setIsUserPopupOpen(false)}>
            <div className={classNames([styles.container, className])}>
                <div className={styles.discoverer}>
                    {!!step && (
                        <span className={styles.stepTitle} onClick={onClickStepTitle}>
                            {step.title}
                        </span>
                    )}
                    <UserTagPopover userId={user?._id} userName={user.display_name} isActive={isUserPopupOpen}>
                        <span
                            className={styles.discovererName}
                            onClick={() => {
                                setIsUserPopupOpen(!isUserPopupOpen);
                            }}
                        >
                            <span>{copies.post.addedBy}</span>
                            <Avatar
                                src={generateImageUrl({ imageId: user.image, height: 20, width: 20, reason: 'mm_discoverer_image' })}
                                size={AvatarSizes.EXTRA_SMALL}
                            />
                            {user?.display_name}
                        </span>
                    </UserTagPopover>
                </div>
                <div className={styles.date}>
                    <span>{`${months[new Date(createdAt).getMonth()]} ${new Date(createdAt).getDate()}, ${new Date(createdAt).getFullYear()}`}</span>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default DiscoveredBlock;
