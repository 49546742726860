import copiesPrefix from '../copies.json';

const copies = copiesPrefix.requestNotifications;

export const PLATFORM_TYPE = {
    MAP_MANAGER: 'map_manager',
    MAP_MANAGER_MOBILE: 'map_manager_mobile',
};
export const LOGIN_TYPES = {
    APPLE: 'apple',
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    EMAIL_PASSWORD: 'email_password',
    TOKEN: 'token',
};

export const MAP_TEMPLATES = {
    OTHER: 'other',
    BLOGGER: 'influencer',
    COMMUNITY: 'community',
};

export const REQUEST_NOTIFICATIONS = {
    IMG_WAITING: '/assets/img/icon-waiting.svg',
    IMG_SUCCESS: '/assets/img/icon-success.png',
    IMG_ERROR: '/assets/img/icon-error.png',
    UPLOAD_IMG_ERROR: '/assets/img/icon-upload-error.svg',
    POSTING: copies.reply_success,
    SHARE: copies.share,
    REPLY_SUCCESS: copies.post_success,
    REPLY_ERROR: copies.post_error,
    POST_SUCCESS: copies.post_success,
    POST_ERROR: copies.post_error,
    TAGS_SUCCESS: copies.ops_success,
    OPS_ERROR: copies.map_setting_error,
    OPS_SUCCESS: copies.map_setting_success,
    MAP_SETTING_ERROR: copies.ops_error,
    MAP_SETTING_SUCCESS: copies.ops_success,
    UPLOAD_KML_FILE_ERROR: copies.upload_kml_file_error,
    UPLOAD_EXEL_FILE_ERROR: copies.upload_exel_file_error,
    POST_EDIT_SUCCESS: copies.post_edit_success,
    UPLOAD_INSTAGRAM_ERROR: copies.share,
};
