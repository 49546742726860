import { noop } from 'lodash';
import { X } from 'phosphor-react';
import classNames from 'classnames';

import styles from './ImagesCarousel.module.scss';

const Image = ({ src = '', isLoading = false, onClick = noop, onRemoveImage = noop }) => {
    return (
        <div className={classNames(styles.imageWrapper, isLoading === true ? 'animate' : '')}>
            <span className={styles.removeImageIcon} onClick={onRemoveImage}>
                <X size={20} />
            </span>
            <img
                alt=''
                src={src}
                onClick={onClick}
                className={classNames(styles.image, {
                    [styles.isImageLoading]: isLoading,
                })}
            />
        </div>
    );
};

export default Image;
