import axios from 'axios';
import thunk from 'redux-thunk';
import { isArray } from 'lodash';

import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';
import config from '../config';
import mapReducer from './map/reducer';
import userReducer from './user/slice';
import stepReducer from './step/reducer';
import postReducer from './post/reducer';
import onboardReducer from './onboard/reducer';
import memberReducer from './member/reducer';
import userAction from './userAction/reducer';
import modalsReducer from './modals/reducer';
import feed from './feed/reducer';
import postsReducer from './posts/reducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

const { requestsReducer: requests, requestsMiddleware } = handleRequests({
  driver: createDriver(axios),
  onRequest(request) {
    const accessToken = localStorage.getItem('at');
    //todo: sometimes when logout and login the domainAddress is duplicated by dispatch action that is no a function, need to check why is happen
    if (isArray(request)) {
      request = request.map(req => ({
        ...req,
        url: config.domain.address + req.url,
        headers: {
          ...req.headers,
          Authorization: `${accessToken}`,
          app_version: config.version.path,
          app_platform: 'map_manager',
        },
      }));
    } else {
      request.url = config.domain.address + request.url;
      request.headers = {
        ...request.headers,
        Authorization: `${accessToken}`,
        app_version: config.version.path,
        app_platform: 'map_manager',
      };
    }
    
    return request;
  },
});

let rootReducer = {
  requests,
  user: userReducer,
  step: stepReducer,
  post: postReducer,
  onboard: onboardReducer,
  member: memberReducer,
  modals: modalsReducer,
  posts: postsReducer,
  userAction,
  feed,
};

const middlewares = [...requestsMiddleware, thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.unshift(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: process.env.REACT_APP_STEPS_ENV !== 'production',
});

if (process.env.NODE_ENV !== 'production') window.store = store;

export const addMapReducer = maps => {
  for (let map of maps) {
    if (rootReducer[map._id]) continue;
    rootReducer = {
      ...rootReducer,
      [map._id]: mapReducer(map),
    };
  }
  
  store.replaceReducer(combineReducers(rootReducer));
};
