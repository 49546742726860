import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getStepsByBounds } from '../../../../helpers';
import { userSelectors } from '../../../../redux/user';
import { mapSelectors, mapActions } from '../../../../redux/map';
import { stepActions, stepSelectors } from '../../../../redux/step';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { useOutletContext } from 'react-router';
import { useContext } from 'react';
import { TagsContext } from '../../../../context';
import TagsFilter from './TagsFilter';
import SearchBar from './SearchBar';
import List from './List';
import StepsFilters from './StepsFilters';

export const TABS = { STEPS: 'steps', FEED: 'feed' };

const StepsList = ({ className, isSideListOpen = true }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId)) || {};
    const totalSteps = useSelector(mapSelectors.map(mapId))?.steps_count;
    const restore_id = useSelector(stepSelectors.restoreId);
    const statusSteps = useSelector(stepSelectors.statusSteps);
    const [filteredSteps, setFilteredSteps] = useState({ steps: [], cursor: null, total: 0 });
    const stepPendingCount = useSelector(stepSelectors.stepPendingCount);
    const stepsList = useSelector(mapSelectors.map(mapId))?.currentList;
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [sortedBy, setSorted] = useState('last_edited');

    const isSaveStepsListCache = stepsList.isSaveStepsListCache;

    const { selectedTags, setSelectedTags, isFiltered } = useContext(TagsContext);
    const { handleFilter } = useOutletContext();

    useEffect(() => {
        setSelectedTags([]);
        dispatch(stepActions.getMapSteps({ mapId }));
    }, [mapId]);

    useEffect(() => {
        if (selectedTags?.length === 0) setFilteredSteps({ steps: [], cursor: null, total: 0 });
        else updateFilteredSteps();
        handleFilter(selectedTags);
    }, [selectedTags]);

    useEffect(() => {
        (async () => {
            if (!mapCenter.bounds) {
                return;
            }

            if (isSaveStepsListCache) {
                setRememberPosition(false);
                dispatch(mapActions.setStepsListAnchor(mapId, { anchorPosition: 0 }));
                return;
            }

            setIsLoading(true);
            dispatch(
                mapActions.updateStepsList(mapId, {
                    steps: [{ isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }],
                    total: stepsList.total,
                })
            );
            try {
                const result = await getStepsByBounds({
                    mapId,
                    bounds: mapCenter.bounds,
                    cursor: null,
                    sortedBy: sortedBy,
                    status: statusSteps,
                    tag: selectedTags.map(tag => tag._id),
                });

                dispatch(mapActions.updateStepsList(mapId, result));
                setIsLoading(false);
            } catch (e) {
                console.error(e);
            }
            setIsLoading(false);
        })();
    }, [mapCenter, sortedBy, selectedTags, restore_id, statusSteps, totalSteps]);

    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setShow(true);
            }, 3000);
        }
        if (
            stepPendingCount > 0 &&
            map?.flags?.MAP_MANAGER_TOOLTIPS?.TOOLTIP_REVIEW_PENDING &&
            (localStorage.getItem('tooltipStorage') && localStorage.getItem('tooltipStorage').split(',').includes('stepPending') ? false : true) &&
            show
        ) {
            mixpanel.track(mixpanelEvents.TOOLTIP_SHOW, {
                [mixpanelProperties.TIP]: 'pending_review',
            });
        }
    }, [show, stepPendingCount]);

    const setRememberPosition = isSaveStepsListCache => {
        dispatch(mapActions.setIsSaveStepsListCache(mapId, { isSaveStepsListCache }));
    };

    const updateFilteredSteps = async () => {
        const { steps, cursor } = filteredSteps;
        const selectedTagsIds = selectedTags.map(tag => tag._id);
        const updatedFilteredSteps = await getStepsByBounds({ mapId, tag: selectedTagsIds, cursor });
        if (steps) {
            setFilteredSteps({ ...updatedFilteredSteps, steps: steps.concat(updatedFilteredSteps.steps) });
        } else {
            setFilteredSteps(updatedFilteredSteps);
        }
    };

    return (
        <div className={classNames(styles.container, className)}>
            <SearchBar />
            <div className={styles.stepsTab} style={{ display: 'flex' }}>
                <StepsFilters isSideListOpen={isSideListOpen} sortedBy={sortedBy} onSort={setSorted} />
                <TagsFilter />
                <div
                    className={classNames(styles.content, {
                        [styles.emptyFilter]: isFiltered && stepsList?.total === 0,
                    })}
                    id='stepPreviewList'
                    data-cy='steps-list'
                >
                    <List isLoading={isLoading} sortedBy={sortedBy} setRememberPosition={setRememberPosition} filteredSteps={filteredSteps} />
                </div>
            </div>
        </div>
    );
};

export default StepsList;
