import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentActivities, arrToObj, generateImageUrl } from '../../helpers';
import { userSelectors } from '../../redux/user';
import InfiniteScroll from 'react-infinite-scroll-component';
import Avatar from '@material-ui/core/Avatar';
import * as timeago from 'timeago.js';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import ReactLoading from 'react-loading';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';
import { FormattedMessage } from "react-intl";

const copies = copiesPrefix.recentActivities;

const locale = function (number, index) {
    // number: the time ago / time in number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
        ['now'],
        ['%s seconds ago'],
        ['1 minute ago'],
        ['%s minutes ago'],
        ['1 hour ago'],
        ['%s hours ago'],
        ['1 day ago'],
        ['%s days ago'],
        ['1 week ago'],
        ['%s weeks ago'],
        ['1 month ago'],
        ['%s months ago'],
        ['1 year ago'],
        ['%s years ago'],
    ][index];
};
timeago.register('my-locale-full', locale);

const UpdatesCard = ({ onClickViewAll }) => {
    const [activitiesList, setActivitiesList] = useState({
        activities: [],
        cursor: null,
        total: 0,
    });
    const mapId = useSelector(userSelectors.selectedMapId);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(async () => {
        if (!mapId) return;
        setIsLoading(true);
        const response = await getRecentActivities(mapId, {
            cursor: null,
            isOnlyUnhandled: true,
        });
        setActivitiesList({
            activities: arrToObj(response.recent_activities, '_id'),
            cursor: response.cursor,
            total: response.total_results,
            totalUnhandled: response.total_unhandled || null,
        });
        setIsLoading(false);
    }, [mapId]);
    
    const loadNextPage = async () => {
        const response = await getRecentActivities(mapId, {
            cursor: activitiesList.cursor,
            isOnlyUnhandled: true,
        });
        setActivitiesList({
            ...activitiesList,
            activities: {
                ...activitiesList.activities,
                ...arrToObj(response.recent_activities, '_id'),
            },
            cursor: response.cursor,
            total: response.total_results,
        });
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div style={{ display: 'flex' }}>
                    <span className={styles.title}>Updates</span>
                    {!isLoading && activitiesList.total != 0 && <span className={styles.newsCount}>{activitiesList.total} NEW</span>}
                </div>
            </div>
            {isLoading || activitiesList.total == 0 ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center',
                    }}
                >
                    {activitiesList.total == 0 && !isLoading ? (
                        <div className={styles.emptyContent}>
                            <img src='/assets/img/steps3Images/emptyRecent.png' alt=''/>
                            <p>
                                <FormattedMessage id="recentActivities.no_unresolved"/>
                            </p>
                        </div>
                    ) : (
                        <ReactLoading type='spokes' color='#B5B5B5' height={60} width={28}/>
                    )}
                </div>
            ) : (
                <div id='activitiesCardList' className={styles.activityList}>
                    <InfiniteScroll
                        dataLength={Object.values(activitiesList.activities).length}
                        next={loadNextPage}
                        hasMore={!!activitiesList.cursor}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget='activitiesCardList'
                    >
                        {Object.values(activitiesList.activities).map(activity => (
                            <UserActivity data={activity}/>
                        ))}
                    </InfiniteScroll>
                </div>
            )}
        </div>
    );
};

const UserActivity = ({ data }) => {
    return (
        <div className={styles.userActivityContainer}>
            <Avatar
                style={{ border: '1px solid #AEB1B6' }}
                src={generateImageUrl({
                    imageId: data?.user_preview?.image,
                    width: 40,
                    height: 40,
                    reason: 'recent_activity_avatar_insight',
                })}
            />
            <span className={styles.activityContent}>
                {data.text_array.map(textObj => (
                    <span
                        style={{
                            fontWeight: textObj.text_type === 'text' ? 'normal' : 'bold',
                        }}
                    >
                        {textObj.text}
                    </span>
                ))}
            </span>
            <span className={styles.timestamp}>{timeago.format(data.created_at, 'my-locale-full')}</span>
        </div>
    );
};

export default UpdatesCard;
