import React, { useRef } from 'react';
import styles from './index.module.scss';
import { noop } from 'lodash';

export const FileTypes = {
    ALL: '*',
    IMAGES: 'image/*',
    VIDEOS: 'video/*',
};

const FileInput = ({ children, onClick: onClickProp = noop, onFileAdd = noop, allowedTypes = FileTypes.ALL }) => {
    const inputRef = useRef(null);

    const onClick = () => {
        inputRef.current.click();
        onClickProp();
    };

    return (
        <>
            {React.cloneElement(children, { onClick: onClick })}
            <input className={styles.invisibleInput} ref={inputRef} type='file' onChange={e => onFileAdd(e.target.files[0])} accept={allowedTypes} />
        </>
    );
};

export default FileInput;
