import { isValidElement } from 'react';
import classNames from 'classnames';
import MenuItem from './MenuItem';

import styles from './DropdownMenu.module.scss';

const Menu = ({ items = [], className, onItemClick, isOpen = false }) => {
    // TODO: support react element for items. react devs want to write react components
    if (!isOpen) {
        return null;
    }

    return (
        <div className={classNames(styles.dropdownMenu, className)}>
            {items.map(item => {
                if (isValidElement(item)) {
                    return item;
                }

                return (
                    <MenuItem
                        key={`${item.id}-${item.title}`}
                        {...item}
                        onClick={() => {
                            item.onClick();
                            onItemClick();
                        }}
                    />
                );
            })}
        </div>
    );
};

export default Menu;
