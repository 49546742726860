import React, { useState, useContext } from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import { mapActions, mapSelectors } from '../../../../redux/map';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { ToastDurations, ToastContext, CHANGES_SAVED_TEXT } from '../../../../context/ToastContext';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.addDescription;

const ModalsAddDescription = ({ mapId, onClose }) => {
    const dispatch = useDispatch();
    const map = useSelector(mapSelectors.map(mapId));
    const [disable, setDisable] = useState(true);
    const [loaderButton, setLoaderButton] = useState(false);
    const [text, setText] = useState(map?.description || '');
    const { setToast } = useContext(ToastContext);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    const handleChangeText = e => {
        setText(e.target.value);
        if (e.target.value.length >= 10) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };

    const handleSaveDescription = () => {
        setLoaderButton(true);
        setDisable(true);
        const data = {
            description: text?.trim(),
        };
        dispatch(mapActions.updateMap(map?._id, data)).then(({ data }) => {
            if (data) {
                setToast({
                    text: CHANGES_SAVED_TEXT,
                    timeout: ToastDurations.SHORT,
                });
                setDisable(false);
                setLoaderButton(false);
                onClose();
            }
        });
        mixpanel.track(mixpanelEvents.ADD_MAP_DESCRIPTION, {
            [mixpanelProperties.FROM_WHERE]: 'map_stages',
            [mixpanelProperties.ACTION]: map?.description?.length === 0 ? 'add' : 'modify',
        });
    };

    return (
        <div className={styles.modals}>
            <ClickAwayListener onClickAway={onClose}>
                <div className={styles.block}>
                    <div className={styles.modalsHeader}>
                        <div onClick={onClose} className={styles.closeBlock}>
                            <img src='/assets/img/icon_cancel_blue.svg' />
                        </div>
                    </div>
                    <div className={styles.modalsBody}>
                        <div className={styles.titleText}>{copies.title}</div>
                        <div className={styles.titleTextSecond}>{copies.subtitle}</div>
                        <textarea
                            value={text}
                            disabled={loaderButton}
                            onChange={e => handleChangeText(e)}
                            className={styles.modalsTextarea}
                            placeholder={copies.input_placeholder}
                            autoFocus
                            onFocus={function (e) {
                                let val = e.target.value;
                                e.target.value = '';
                                e.target.value = val;
                            }}
                            cols='30'
                            rows='10'
                        />
                    </div>
                    <div className={styles.modalsFooter}>
                        <CustomButton className={styles.buttonSave} onClick={() => handleSaveDescription()} type={ButtonType.PRIMARY} disabled={disable}>
                            {loaderButton ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={35} height={35} /> : copies.save_button}
                        </CustomButton>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default ModalsAddDescription;
