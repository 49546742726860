import React from 'react';
import styles from './index.module.scss';

const HighlightSlide = ({ slide }) => {
    const { imageSrc, title, description, backgroundColor, imageStyle, titleStyle } = slide;
    return (
        <div className={styles.block} style={{ backgroundColor: backgroundColor }}>
            <img className={styles.img} src={imageSrc} alt='' style={imageStyle} />
            <h3 className={styles.title} style={titleStyle}>
                {title}
            </h3>
            <p className={styles.description}>{description}</p>
        </div>
    );
};

export default HighlightSlide;
