import React, { useState, useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { useSelector } from 'react-redux';
import { mapSelectors } from '../../redux/map';
import FilterTags from '../../components/FilterTags';
import { userSelectors } from '../../redux/user';

const TagsContext = React.createContext({});

/**
 * @param {array} selectedTags only used for in tests
 */
const TagsContextProvider = ({ children, selectedTags: initSelectedTags = [] }) => {
    const [openedTagsFilter, setOpenedTagsFilter] = useState(false);
    const [selectedTags, setSelectedTags] = useState(initSelectedTags);
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const isFiltered = selectedTags.length > 0;

    const clearFilteredTag = () => {
        setSelectedTags([]);
    };

    const onClickFilterButton = ({ fromWhere = '' }) => {
        setOpenedTagsFilter(true);
        mixpanel.track(mixpanelEvents.FILTER_OPEN, {
            [mixpanelProperties.FROM_WHERE]: fromWhere,
        });
    };

    const handleFilter = tags => {
        setSelectedTags(tags);
        setOpenedTagsFilter(false);
    };

    const handleSelectClick = useCallback(
        tagData => {
            const newSelectedTags = selectedTags?.find(item => item._id === tagData._id)
                ? selectedTags.filter(tag => tag._id !== tagData._id)
                : [...selectedTags, tagData];
            setSelectedTags(newSelectedTags);
        },
        [selectedTags, isFiltered, map]
    );

    return (
        <TagsContext.Provider
            value={{
                clearFilteredTag,
                onClickFilterButton,
                handleFilter,
                handleSelectClick,
                openedTagsFilter,
                selectedTags,
                isFiltered,
                setOpenedTagsFilter,
                setSelectedTags,
            }}
        >
            {openedTagsFilter && (
                <FilterTags
                    isFiltered={isFiltered}
                    clearFilteredTag={clearFilteredTag}
                    handleFilter={handleFilter}
                    onClickPrev={() => setOpenedTagsFilter(false)}
                    selectedTags={selectedTags}
                    handleSelectClick={handleSelectClick}
                />
            )}
            {children}
        </TagsContext.Provider>
    );
};

export { TagsContext, TagsContextProvider };
