import React from 'react';
import { noop } from 'lodash';
import styles from './index.module.scss';
import CircularProgressBar from '../../common/CircularProgressBar';
import CustomButton, { ButtonSize, ButtonType } from '../../common/buttons/CustomButton';

const Level = ({ title, completedTasks = 0, totalTasks = 0, cta = { text: 'Click', onClick: noop } }) => {
    const currentProgress = Math.min(completedTasks, totalTasks);
    const isCompleted = currentProgress >= totalTasks;

    return (
        <div className={styles.block}>
            <CircularProgressBar progress={completedTasks} outOf={totalTasks} />
            <span className={styles.title}>{title}</span>
            <span className={styles.count}>{`${currentProgress}/${totalTasks}`}</span>
            <CustomButton
                className={styles.button}
                type={isCompleted ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                size={ButtonSize.MEDIUM}
                onClick={cta.onClick}
            >
                {cta.text}
            </CustomButton>
        </div>
    );
};

export default Level;
