import React, { useState } from 'react';
import { noop } from 'lodash';
import CreatePost from '../../../CreatePost';
import ExitModal from '../../../ExitModal';
import copiesPrefix from '../../../../copies.json';
import BaseModal from '../../../../modalSysyem/BaseModal';
import styles from './index.module.scss';

const copies = copiesPrefix?.deletePost;

const ModalsCreatePost = ({
    setDataRequest,
    onCancel,
    clickReplyPage,
    answerToUser,
    postStepId,
    onClose,
    user,
    postId,
    onReply,
    mapId,
    addImage,
    listView,
    setUpdatedStepId,
    fromRecentActivities,
    updateStepPostsCount,
    onReplySuccess = noop,
}) => {
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const [text, setText] = useState('');
    const [isExitModalOpen, setIsExitModalOpen] = useState(false);

    const closeCreatePost = () => {
        if (text?.length > 0 || images?.length > 0) {
            setIsExitModalOpen(true);
        } else {
            onCancel();
        }
    };

    const clickClose = () => {
        setIsExitModalOpen(false);
    };

    const clickDiscard = () => {
        setImages([]);
        setFiles([]);
        setText('');
        setIsExitModalOpen(false);
        onCancel();
    };

    return (
        <BaseModal isActive onClose={onClose}>
            <div className={styles.modals}>
                <div className={styles.block}>
                    <div className={styles.modalsHeader}>
                        <div onClick={() => closeCreatePost()} className={styles.closeBlock}>
                            <img src='/assets/img/icon_cancel_blue.svg' />
                        </div>
                    </div>
                    <CreatePost
                        updateStepPostsCount={updateStepPostsCount}
                        fromRecentActivities={fromRecentActivities}
                        addImage={addImage}
                        setDataRequest={setDataRequest}
                        setIsExitModalOpen={setIsExitModalOpen}
                        clickReplyPage={clickReplyPage}
                        onCancel={onCancel}
                        user={user}
                        postStepId={postStepId}
                        postId={postId}
                        answerToUser={answerToUser}
                        setImages={setImages}
                        images={images}
                        setFiles={setFiles}
                        files={files}
                        setText={setText}
                        text={text}
                        mapId={mapId}
                        onReply={onReply}
                        listView={listView}
                        setUpdatedStepId={setUpdatedStepId}
                        onReplySuccess={onReplySuccess}
                    />
                    {isExitModalOpen && (
                        <ExitModal
                            tertiaryText={copies?.cancel_button}
                            textDanger={copies?.delete_button}
                            text={copies?.description}
                            onCancel={() => clickClose()}
                            onDiscard={() => clickDiscard()}
                        />
                    )}
                </div>
            </div>
        </BaseModal>
    );
};

export default ModalsCreatePost;
