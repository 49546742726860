import { createSlice } from '@reduxjs/toolkit';
import { createMap, getUserData, login, registration, resolveIP } from './thunks';

const initialState = {
  maps: [],
  first_name: '',
  last_name: '',
  name: '',
  image: '',
  location: {},
  selectedMapId: null,
  id: null,
  isAuth: -1,
  requestMessage: {},
  has_logged_in_from_mobile: false,
  user_image_temp: null,
};

const AuthState = {
  LOGGED_IN: 1,
  LOGGED_OUT: 0,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedMapId: (state, action) => {
      state.selectedMapId = action.payload;
    },
    setUserAuth(state, action) {
      return {
        ...state,
        selectedMapId: action.payload === AuthState.LOGGED_OUT
            ? null
            : state.selectedMapId,
        maps: action.payload === AuthState.LOGGED_OUT
            ? []
            : state.maps,
        isAuth: action.payload
      };
    },
    setUserCoverImage(state, action) {
      state.user_image_temp = action.payload;
    },
    setMessageRequest: (state, action) => {
      state.requestMessage = action.payload;
    },
    setMaps: (state, action) => {
      return { ...state, maps: action.payload };
    },
  },
  extraReducers: builder => {
    builder
        .addCase(createMap.fulfilled, (state, action) => {
          state.maps.push(action.payload);
        })
        .addCase(registration.fulfilled, (state, action) => ({ ...state, ...action.payload }))
        .addCase(login.fulfilled, (state, action) => ({ ...state, ...action.payload }))
        .addCase(resolveIP.fulfilled, (state, action) => {
          return { ...state, location: action.payload.data };
        })
        .addCase(getUserData.fulfilled, (state, action) => {
          return { ...state, ...action.payload };
        });
  },
});

export const { setMessageRequest, setMaps, setSelectedMapId, setUserAuth, setUserCoverImage } = userSlice.actions;
export default userSlice.reducer;
