import styles from './DropPinView.module.scss';

const getSideContainer = () => {
    return document.querySelector('[class*=StepsManager_sideContainer]');
};

const getMapBoxLogo = () => {
    return document.querySelector('[class*=mapboxgl-ctrl-logo]');
};

const getMapView = () => {
    return document.querySelector('[class*=StepsManager_mapViewFull]') || document.querySelector('[class*=StepsManager_mapViewMini]');
};

export const minimizeSidebar = () => {
    getSideContainer()?.classList.add(styles.minimized);
    getMapBoxLogo()?.classList.remove('l-500');
    getMapBoxLogo()?.classList.add('l-62');
};

export const expandSidebar = () => {
    getSideContainer()?.classList.remove(styles.minimized);
    getMapBoxLogo()?.classList.remove('l-62');
    getMapBoxLogo()?.classList.add('l-500');
};

export const setLocationFoundCursor = () => {
    getMapView()?.classList.remove(styles.pinCursor);
    getMapView()?.classList.add(styles.locationFoundCursor);
};

export const setLocationPinCursor = () => {
    getMapView()?.classList.remove(styles.locationFoundCursor);
    getMapView()?.classList.add(styles.pinCursor);
};

export const resetMouseCursor = () => {
    getMapView()?.classList.remove(styles.locationFoundCursor, styles.pinCursor);
};
