import styles from './index.module.scss';

const OrDevider = () => {
    return (
        <span className={styles.devider}>
            <hr className={styles.line} />
            <span>Or</span>
            <hr className={styles.line} />
        </span>
    );
};

export default OrDevider;
