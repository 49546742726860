import { useState, createContext } from 'react';
import { noop } from 'lodash';

// Should be used to determine any popup-ish component in the app.
// We dont want a user tag popup to exist with location tagging popup for example.
// TODO: the name "popup" is a bit misleading. It should be something else
const PopupContext = createContext({
    activePopupId: null,
    setActivePopupId: noop,
});

const PopupContextProvider = ({ children }) => {
    const [activePopupId, setActivePopupId] = useState(null);
    return <PopupContext.Provider value={{ activePopupId, setActivePopupId }}>{children}</PopupContext.Provider>;
};

export { PopupContextProvider, PopupContext };
