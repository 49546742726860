import React, { useState, useEffect } from 'react';
import { noop } from 'lodash';
import { ArrowRight, X } from 'phosphor-react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import BaseModal from '../../../modalSysyem/BaseModal';
import PaginationSlider, { arrowDirection } from '../../common/PaginationSlider';
import slidesData from './slidesData';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, mapSelectors } from '../../../redux/map';
import { userSelectors } from '../../../redux/user';
import { buttonCta } from '../contants';
import AdvancedLevelPopUpSlide from './AdvancedLevelPopUpSlide';
import CustomButton, { ButtonType } from '../../common/buttons/CustomButton';
import ROUTES from '../../../config/routes';
import mixpanel, { mixpanelEvents } from '../../../helpers/mixpanel';

import styles from './index.module.scss';

const AdvancedLevelPopUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId)) || {};
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [advancedLevelPopUpOpen, setAdvancedLevelPopUpOpen] = useState(false);
    const { SHOW_ADVANCE_LEVEL_TOOLTIP: shouldShowAdvancedLevelTooltip } = map?.flags?.TOOLTIP || {};

    const onClose = () => {
        setAdvancedLevelPopUpOpen(false);
    };

    useEffect(() => {
        // Timeout for testing purposes
        setTimeout(() => {
            if (!map?.flags) {
                return;
            }

            setAdvancedLevelPopUpOpen(shouldShowAdvancedLevelTooltip);
        }, 100);
    }, [shouldShowAdvancedLevelTooltip]);

    useEffect(() => {
        dispatch(
            mapActions.toggleFlag({
                mapId,
                flagGroup: 'TOOLTIP',
                flag: 'SHOW_ADVANCE_LEVEL_TOOLTIP',
                setTo: false,
            })
        );
    }, []);

    useEffect(() => {
        if (!advancedLevelPopUpOpen) {
            return;
        }

        mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_COMPLETE_POPUP_SHOWED);
    }, [advancedLevelPopUpOpen]);

    const onClickTryInsights = () => {
        onClose();
        navigate(ROUTES.INSIGHTS);
    };

    const onClickTryAnnouncement = () => {
        onClose();
        setAdvancedLevelPopUpOpen(false);
        navigate(`/${ROUTES.MAP}/${ROUTES.FEED}`, {
            state: {
                triggerAddAnnouncment: true,
            },
        });
    };

    const primaryButtonActions = {
        [buttonCta.INSIGHTS]: onClickTryInsights,
        [buttonCta.ANNOUNCEMENT]: onClickTryAnnouncement,
        [buttonCta.GOT_IT]: onClose,
    };

    const renderPrimaryButton = () => {
        if (currentSlideIndex === 0) return;
        const buttonData = slidesData[currentSlideIndex].primaryButton;
        return (
            <CustomButton className={styles.primaryButton} type={buttonData.type} onClick={primaryButtonActions[buttonData.cta]}>
                {buttonData.text}
            </CustomButton>
        );
    };

    const renderSkipButton = () => {
        if (currentSlideIndex !== 0) return;
        return (
            <CustomButton className={styles.secondaryButton} type={ButtonType.TRANSPARANT} disabled={false} onClick={onClose}>
                Skip
            </CustomButton>
        );
    };

    const renderXButton = () => {
        if (currentSlideIndex === 0) return;
        return <X className={styles.xButton} size={16} color='#212B37' onClick={onClose} />;
    };

    return (
        <BaseModal isActive={advancedLevelPopUpOpen} onClose={onClose} className={styles.block}>
            <PaginationSlider
                slides={slidesData.map(slide => (
                    <AdvancedLevelPopUpSlide slide={slide} />
                ))}
                onSlideChange={slideIndex => setCurrentSlideIndex(slideIndex)}
            >
                {renderXButton()}
                {renderPrimaryButton()}
                {renderSkipButton()}
                <PaginationSlider.Arrow
                    direction={arrowDirection.PREV}
                    className={classNames(styles.prevButton, { [styles.hideButton]: currentSlideIndex === 0 })}
                />
                <PaginationSlider.CustomNavigationButton className={classNames(styles.nextButton, { [styles.buttonPositionRight]: currentSlideIndex === 0 })}>
                    {currentSlideIndex === 0 ? (
                        <CustomButton className={styles.button} type={slidesData[0].primaryButton.type}>
                            {slidesData[0].primaryButton.text}
                        </CustomButton>
                    ) : (
                        <div className={styles.arrow}>
                            <ArrowRight size={24} color='#212B37' />
                        </div>
                    )}
                </PaginationSlider.CustomNavigationButton>
            </PaginationSlider>
        </BaseModal>
    );
};

export default AdvancedLevelPopUp;
