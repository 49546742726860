import { useLocation } from 'react-router-dom';

export const useQuery = () => {
    const location = useLocation();
    const hashes = location.search.slice(location.search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
        const [key, val] = hash.split('=');
        return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
};
