import React, { useState } from "react";
import { EditButton } from "./EditButton";
import IconTitle from "../../common/icons/IconTitle";
import { useIntl } from "react-intl";
import { noop } from "lodash";
import classNames from "classnames";
import { useInputFocus } from "../../../hooks";

const ICON_COLOR = "#8F9DAE";
const ICON_SIZE = 24;

export const PlaceInfoInput = ({
  value = '',
  onChange = noop,
  onEdit = noop,
  icon: Icon,
  placeholderId,
  type = "text",
  isDisabled = false,
  link = null
}) => {
  const intl = useIntl();
  const [isEditView, setIsEditView] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const ref = useInputFocus(isEditView);
  
  return (
      <div
          className={classNames("border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-200 py-4 px-6 flex items-center", {
            "bg-gray-100": isEditView,
          })}
          onMouseOver={() => !isDisabled && setIsHovered(true)}
          onMouseOut={() => !isDisabled && setIsHovered(false)}
      >
        <span className="w-6 mr-5 flex items-center justify-center">
            <Icon stroke={ICON_COLOR} size={ICON_SIZE}/>
        </span>
        {(link && !isEditView)
            ? <span className="w-full pr-4 border-0 h-full bg-transparent m-0 text-sm outline-0 truncate">
                  {link}
              </span>
            : <input
                ref={ref}
                value={value}
                type={type}
                className="w-full border-0 h-full bg-transparent m-0 text-sm outline-0 truncate"
                disabled={isDisabled || !isEditView}
                placeholder={placeholderId && intl.formatMessage({ id: placeholderId })}
                onChange={event => onChange(event.target.value)}
                onBlur={() => {
                  setIsHovered(false);
                  setIsEditView(false);
                }}
            />}
        {!isEditView &&
            <EditButton isHovered={isHovered} onClick={() => {
              onEdit();
              setIsEditView(true);
            }}/>}
      </div>
  );
};
