import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import ROUTES from '../../../config/routes';
import { createMap } from '../../../redux/map/actions';
import { mapActions } from '../../../redux/map';
import { getManagerMaps, getUploadImageInfo, uploadImageToS3 } from '../../../helpers';
import { addMapReducer } from '../../../redux/store';
import OnboardMap from '../OnboardMap';
import Import from '../Import';
import { arrToObj, isOnboarding } from '../../../helpers';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';
import { userActions } from '../../../redux/user';
import PickStep from './steps/PickStep';
import StepDescription from './steps/StepDescription';
import FinalScreenDesktop from './steps/FinalScreenDesktop';
import NextStep from './steps/NextStep';
import BackButton from './BackButton';
import WhatIsAStepExlanation from './mobile/WhatIsAStep';
import FinalScreenMobile from './mobile/FinalScreenMobile';
import Logo from "../../../components/common/Logo";

const MapCreationFinish = ({
    selectedMap,
    updateMaps,
    user,
    page,
    handleClickNext,
    handleClickBack,
    mapName,
    toggleImagePreview,
    containerChange = noop,
    setLoading,
    type,
    importSteps,
    handleImportSteps,
    setImportSteps,
    backToDefaultPage,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [location, setLocation] = useState(null);
    const [imageLoading, setImageLoader] = useState(false);
    const [files, setFiles] = useState([]);
    const [postImages, setPostImages] = useState([]);
    const [text, setText] = useState('');
    const [addedStepsCount, setAddedStepsCount] = useState(0);
    const [waitingStepsCount, setWaitingStepsCount] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [showHintScreen, setShowHintScreen] = useState();
    
    const setCursorPosition = (oInput, oStart, oEnd) => {
        if (oInput.setSelectionRange) {
            oInput.setSelectionRange(oStart, oEnd);
        } else if (oInput.createTextRange) {
            let range = oInput.createTextRange();
            range.collapse(true);
            range.moveEnd('character', oEnd);
            range.moveStart('character', oStart);
            range.select();
        }
    };
    
    useEffect(() => {
        const textarea = document.getElementById('description');
        if (textarea && page === 3) {
            const iLength = textarea.value.length;
            setCursorPosition(textarea, iLength, iLength);
            textarea.focus();
        }
    }, [page]);
    
    const changeIsHintStatus = useCallback(newStatus => setShowHintScreen(newStatus), []);
    const lastStepClicked = useCallback(() => {
        navigate('/' + ROUTES.MAP);
        containerChange('steps');
    }, []);
    
    const nextStepClicked = useCallback(
        updatedText => {
            if (page === 3) {
                mixpanel.track(mixpanelEvents.OB_MANUAL_CONTENT_ADD, {
                    [mixpanelProperties.POST_CONTENT]:
                        files.length > 0 && text.length > 0 ? 'both' : text?.length > 0 ? 'text' : files.length > 0 ? 'image' : 'none',
                    [mixpanelProperties.MAP_NAME]: mapName,
                });
                mixpanel.track(mixpanelEvents.MM_OB_MANUAL_STEP_NEXT_CLICKED, {
                    [mixpanelProperties.IS_OB]: isOnboarding(),
                });
                dispatchCreateMap(updatedText);
            } else {
                // the solution for now because its hard for now understand the ob code
                if (page === 2)
                    mixpanel.track(mixpanelEvents.MM_OB_MANUAL_STEP_NEXT_CLICKED, {
                        [mixpanelProperties.IS_OB]: isOnboarding(),
                    });
                handleClickNext(page + 1);
            }
        },
        [page, files.length]
    );
    
    const dispatchCreateMap = useCallback(
        async updatedText => {
            const name = mapName;
            const client_type = 'map_manager';
            setLoading(true);
            const mapData = JSON.stringify({
                name,
                client_type,
            });
            
            const res = await dispatch(createMap(mapData));
            const resMapData = res.data;
            
            if (resMapData) {
                const mapData = resMapData.map;
                
                const { data } = await dispatch(
                    mapActions.createStepPost(mapData._id, {
                        lat: location?.lat || location?.location?.lat,
                        lon: location?.lon || location?.location?.lng,
                        title: location?.title || location?.description,
                        text: updatedText,
                        attachments: files?.map(image => ({ type: 'IMAGE', image_id: image.imageId })),
                        locationId: location?.id,
                        placeId: !location?.id && location?.place_id,
                    })
                );
                setPostImages(data?.post?.attachments);
                mixpanel.track(mixpanelEvents.OB_FINISH, {
                    [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                    [mixpanelProperties.MAP_NAME]: mapName,
                });
                const maps = await getManagerMaps();
                localStorage.setItem('isOb', false);
                addMapReducer(maps);
                dispatch(userActions.setMaps(maps));
                updateMaps?.(maps);
                setLoading(false);
                
                navigate('/' + ROUTES.MAP);
                containerChange('steps');
                
                localStorage.setItem('userIdLocal', mapData.creator);
                return mapData._id;
            }
        },
        [mapName, files, location, text, page]
    );
    
    const onLoadFiles = async files => {
        mixpanel.track(mixpanelEvents.MM_OB_MANUAL_STEP_ADD_IMAGE_CLICKED, {
            [mixpanelProperties.IS_OB]: isOnboarding(),
        });
        setImageLoader(true);
        await Promise.all(
            Object.values(files).map(
                file =>
                    new Promise((resolve, reject) =>
                        getUploadImageInfo()
                            .then(info =>
                                uploadImageToS3({
                                    uploadUrl: info.url,
                                    file,
                                    onUploadedFile: () => {
                                        setFiles(state => state.concat({ file: file, imageId: info['image_id'] }));
                                        mixpanel.track(mixpanelEvents.MM_OB_MANUAL_STEP_IMAGE_ADDED, {
                                            [mixpanelProperties.IS_OB]: isOnboarding(),
                                        });
                                        resolve();
                                    },
                                })
                            )
                            .catch(reject)
                    )
            )
        );
        setImageLoader(false);
    };
    
    const removeFile = idx => {
        setFiles([...files.slice(0, idx), ...files.slice(idx + 1)]);
    };
    
    useEffect(() => {
        if (!importSteps) {
            if (page === 4) {
                mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                    [mixpanelProperties.PAGE]: 'finish',
                    [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                    [mixpanelProperties.FIRST_MAP]: parseInt(localStorage.getItem('temp_mapCount')) === 1,
                    [mixpanelProperties.MAP_NAME]: mapName,
                });
            } else if (page === 2) {
                mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                    [mixpanelProperties.PAGE]: 'location',
                    [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                    [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                    [mixpanelProperties.MAP_NAME]: mapName,
                });
            } else if (page === 3) {
                mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                    [mixpanelProperties.PAGE]: 'content',
                    [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                    [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                    [mixpanelProperties.MAP_NAME]: mapName,
                });
            }
        }
    }, [page, importSteps]);
    
    return (
        <>
            <div
                role='presentation'
                onClick={() => {
                    backToDefaultPage();
                    mixpanel.track(mixpanelEvents.MM_OB_LETS_CREATE_YOUR_MAP_STEPS_LOGO_CLICKED, {
                        [mixpanelProperties.IS_OB]: isOnboarding(),
                    });
                }}
                className={classNames(styles.logoBlock, styles.blueLogo)}
            >
                <Logo size={Logo.Sizes.SMALL}/>
            </div>
            {importSteps ? (
                <Import
                    updateMaps={updateMaps}
                    setImportSteps={setImportSteps}
                    handleClickBack={handleClickBack}
                    page={page}
                    handleImportSteps={handleImportSteps}
                    type={type}
                    mapName={mapName}
                    setIsImport={value => setIsImport(value)}
                    setAddedStepsCount={value => setAddedStepsCount(value)}
                    setWaitingStepsCount={value => setWaitingStepsCount(value)}
                />
            ) : (
                <div className={styles.addFirstStepBlock}>
                    <div className={styles.pickStepSide}>
                        <div className={styles.firstBlock}>
                            {page === 2 && (showHintScreen ? <WhatIsAStepExlanation/> : <PickStep location={location} setLocation={setLocation}/>)}
                            {page === 3 && (
                                <StepDescription
                                    handleTextChange={e => {
                                        setText(e.target.value);
                                    }}
                                    onLoadFiles={onLoadFiles}
                                    text={text}
                                    imageLoading={imageLoading}
                                    files={files}
                                    toggleImagePreview={toggleImagePreview}
                                    removeFile={removeFile}
                                />
                            )}
                            {page === 4 && (
                                <FinalScreenDesktop addedStepsCount={addedStepsCount} mapName={mapName} type={type} waitingStepsCount={waitingStepsCount}/>
                            )}
                        </div>
                        
                        {page !== 4 && (
                            <BackButton
                                handleBackClick={() => {
                                    let backPage;
                                    backPage = page - 1;
                                    handleClickBack(backPage);
                                }}
                            />
                        )}
                        
                        <NextStep
                            page={page}
                            lastStepClicked={lastStepClicked}
                            nextClicked={() => nextStepClicked(text)}
                            isHint={showHintScreen}
                            closeHint={() => changeIsHintStatus(false)}
                            disabled={!location || imageLoading}
                        />
                    </div>
                    <div className={styles.mapCheck}>
                        <OnboardMap
                            mapId={isImport ? selectedMap?._id : null}
                            location={location}
                            locations={arrToObj(location ? [location] : [], '_id')}
                            files={postImages}
                            text={text}
                            page={page}
                            mapName={mapName}
                            user={user}
                            isImport={isImport}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
export default MapCreationFinish;
