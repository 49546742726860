import * as React from 'react';
const SVGComponent = props => (
    <svg width={32} height={36} viewBox='0 0 32 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='m16 32.667-.832 1.248a1.5 1.5 0 0 0 1.664 0L16 32.667Zm0 0c.832 1.248.832 1.247.833 1.247h0l.004-.002.01-.007.03-.02.112-.077a38.309 38.309 0 0 0 1.82-1.363 42.07 42.07 0 0 0 4.28-3.913c3.06-3.23 6.411-7.929 6.411-13.199a13.5 13.5 0 1 0-27 0c0 5.27 3.351 9.969 6.411 13.199a42.073 42.073 0 0 0 5.695 4.989 25.67 25.67 0 0 0 .517.363l.03.021.01.007.003.002h.001L16 32.667Z'
            fill='#2171EC'
            stroke='#055ADC'
            strokeWidth={3}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M16 23.333a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z' fill='#fff' />
        <path d='M20.056 16H16v-5.667' stroke='#2171EC' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);
export default SVGComponent;
