import React, { useCallback, useEffect, useContext, useState } from 'react';
import styles from './PlaceInfo.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { X } from 'phosphor-react';
import { noop } from 'lodash';
import { mapSelectors } from '../../redux/map';
import IconAddress from '../common/icons/IconAddress';
import IconPhone from '../common/icons/IconPhone';
import IconWebSite from '../common/icons/IconWebSite';
import IconTitle from '../common/icons/IconTitle';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/circleLoader.json';
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import ModalsGoogleDataRemove from '../common/modals/ModalsGoogleDataRemove';
import Skeleton from '../common/Skeletons/StepInfoSkeleton';
import BaseModal, { ModalSizes } from '../../modalSysyem/BaseModal';
import copiesPrefix from '../../copies.json';
import { stepActions } from '../../redux/step2';
import context from '../../constants/context';
import { ToastContext, ToastTypes } from '../../context';
import { PlaceInfoInput } from "./components/PlaceInfoInput";
import { useNavigate } from "react-router";
import { PlaceInfoAddress } from "./components/PlaceInfoAddress";
import { PlaceInfoPhone } from "./components/PlaceInfoPhone";
import { PlaceInfoOpeningHours } from "./components/PlaceInfoOpeningHours";
import { PlaceInfoWebsite } from "./components/PlaceInfoWebsite";

const copies = copiesPrefix.stepInfo;

const useStyles = makeStyles(theme => ({
  root: {
    width: 150 + theme.spacing(3) * 2,
  },
  tooltipBlock: {
    display: 'flex',
    alignItems: 'center',
    height: '15px',
    maxWidth: '166px',
    borderRadius: '2px',
    backgroundColor: '#3a3c3f',
    '& .MuiTooltip-arrow:before': {
      backgroundColor: '#3a3c3f',
    },
    boxShadow: '0 -3px 4px 0 rgba(102, 106, 112, 0.19)',
    letterSpacing: '0.01px',
    fontFamily: 'Roboto-Regular',
    margin: 0,
    marginTop: '10px',
    fontSize: '13px',
    padding: '10.6px 7px 8px 8px',
  },
}));

const PlaceInfo = ({ mapId, stepId, onPrevClick = noop, setRemoveGooglePosts = noop, onUpdateSuccess = noop }) => {
  const classes = useStyles();
  const step = useSelector(mapSelectors.steps(mapId))[stepId];
  const hpaw = step.hpaw;
  const address = hpaw.address;
  const hours = hpaw.hours;
  const phone = hpaw.phone;
  const website = hpaw.website;
  const dispatch = useDispatch();
  const { setToast } = useContext(ToastContext);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    address,
    website,
    phone,
    title: step.title
  });
  const [updating, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [openPopup, setPopup] = useState(false);
  const [googleStep, setGoogleStep] = useState(false);
  const navigate = useNavigate();
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
  };
  
  useEffect(() => {
    if (step && step?.place_id) {
      setGoogleStep(true);
    } else {
      setGoogleStep(false);
    }
  }, [step]);
  
  const updateStepInfo = useCallback(
      async (objectData, isGoogleStep) => {
        if (isGoogleStep && objectData?.title && objectData?.title !== step?.title) {
          setPopup(true);
          return;
        }
        
        setUpdate(true);
        mixpanel.track(mixpanelEvents.STEP_INFO_EDIT, {
          [mixpanelProperties.STEP_TITLE]: step?.title,
          [mixpanelProperties.STEP_ID]: step?._id,
          [mixpanelProperties.HAS_INFO]: 'no',
          [mixpanelProperties.INFO_TYPE]: Object.keys(objectData),
        });
        if (Object.keys(objectData).some(item => item === 'title') && step.place_id) {
          const newAttachments = step.posts_attachments_list.posts_attachments.filter(item => item.type !== 'GOOGLE_IMAGE');
          setRemoveGooglePosts({ stepId: step?._id, newAttachments });
        }
        
        await dispatch(
            stepActions.updateStep(mapId, step?._id, {
              address: objectData.address,
              website: objectData.website,
              phone: objectData.phone,
              title: objectData.title,
              opening_hours: objectData.opening_hours,
              context: context.STEP_PROFILE,
            })
        )
            .then(response => {
              if (response.data) {
                setData(null);
                setLoader(false);
                setUpdate(false);
                onUpdateSuccess();
              } else {
                setData(null);
                setLoader(false);
                setUpdate(false);
                setToast({
                  type: ToastTypes.ERROR,
                  id: 'error',
                });
              }
            })
            .catch(error => {
              console.error(error);
              setData(null);
              setLoader(false);
              setUpdate(false);
            });
      },
      [step, mapId]
  );
  
  const editStepInfo = useCallback(
      (type, value) => {
        if (type === 'title' && value?.length > 0) {
          setError(false);
        } else if (type === 'title' && value?.length === 0) {
          setError(true);
        }
        
        switch (type) {
          case 'title':
            if (value === step?.title) {
              setData(({ title, ...state }) => state);
            } else {
              setData(state => ({ ...state, title: value }));
            }
            
            break;
          
          case 'address':
            if (value === address) {
              setData(state => ({ ...state, address }));
            } else {
              setData(state => ({ ...state, address: value }));
            }
            break;
          
          case 'phone':
            if (value === phone) {
              setData(state => ({ ...state, phone }));
            } else {
              setData(state => ({ ...state, phone: value }));
            }
            break;
          
          case 'opening_hours':
            if (value === hours) {
              setData(state => ({
                ...state,
                opening_hours: hours,
              }));
            } else {
              setData(state => ({
                ...state,
                opening_hours: value,
              }));
            }
            break;
          
          case 'website':
            setData(state => ({ ...state, website: value }));
            break;
          
          default:
            break;
        }
      },
      [data]
  );
  
  if (loader) {
    return <Skeleton/>;
  }
  
  if (openPopup) {
    return (
        <ModalsGoogleDataRemove
            onUpdate={() => {
              setPopup(false);
              setGoogleStep(false);
              updateStepInfo(data, false);
            }}
            onCancel={() => {
              const newData = { ...data };
              delete newData.title;
              setData(newData);
              setPopup(false);
            }}
        />
    );
  }
  
  return (
      <BaseModal disableClickAway isActive onClose={onPrevClick} className={styles.modal} size={ModalSizes.MEDIUM}>
        <div className="flex justify-between p-4 border-b border-gray-200 border-solid border-t-0 border-l-0 border-r-0">
          <span className={styles.title}>{copies.title}</span>
          <span data-cy='close-button' className='flex-center cursor-pointer'>
              <X className={styles.xButton} size={16} color='#666A70' onClick={() => onPrevClick(false)}/>
          </span>
        </div>
        <div className="w-full">
          <PlaceInfoInput
              value={data?.title || step?.title}
              icon={IconTitle}
              isDisabled={updating}
              placeholderId="stepInfo.add_step_name"
              onChange={value => editStepInfo('title', value)}/>
          <PlaceInfoAddress
              icon={IconAddress}
              stepId={stepId}
              isDisabled={updating}
              value={data?.address || step.geocode_address?.formatted_address}
              placeholderId="stepInfo.add_address"/>
          <PlaceInfoPhone
              value={data?.phone}
              icon={IconPhone}
              isDisabled={updating}
              type="phone"
              placeholderId="stepInfo.add_phone"
              onChange={value => editStepInfo('phone', value)}/>
          <PlaceInfoOpeningHours
              value={data?.opening_hours}
              isDisabled
              placeholderId="stepInfo.add_hours"
              onChange={value => editStepInfo('opening_hours', value)}/>
          <PlaceInfoWebsite
              value={data?.website}
              icon={IconWebSite}
              isDisabled={updating}
              placeholderId="stepInfo.add_website"
              onChange={value => editStepInfo('website', value)}/>
        </div>
        <div className={styles.footer}>
          <Tooltip
              // FIXME:
              // disableHoverListener={open}
              TransitionComponent={Zoom}
              placement='right-end'
              arrow
              title={copies.name_required_error}
              classes={{ tooltip: classes.tooltipBlock }}
              open={error}
          >
            <div>
              <CustomButton
                  className={styles.doneButton}
                  disabled={error || !data || Object.keys(data)?.length === 0}
                  onClick={() => {
                    updateStepInfo(data, googleStep);
                  }}
                  size={'smallSize'}
                  type={ButtonType.PRIMARY_BLUE}
                  text={
                    updating ? (
                        <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={40} height={40}/>
                    ) : (
                        <FormattedMessage id='step_info.save' defaultMessage='Save'/>
                    )
                  }
              />
            </div>
          </Tooltip>
        </div>
      </BaseModal>
  );
};

export default PlaceInfo;
