import { useState } from 'react';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import PostSlider from '../../../../components/PostSlider';
import { TextArrayBody } from '../../../../components/common';
import { BaseCard } from '../BaseCard';
import { Outcomes } from '../../constants';

import styles from './index.module.scss';
import Outcome from '../Outcome';
import BaseModal from '../../../../modalSysyem/BaseModal';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';

const PendingStepCard = ({
    outcome,
    avatarImageId = '',
    createdAt = 0,
    textArray = [],
    message = '',
    postBody = [],
    postAttachments = [],
    onDecline = noop,
    onAddToMap = noop,
    onDisregard = noop,
    onTagClick = noop,
    managerPreview,
    handlerManager,
    ...rest
}) => {
    const user = useSelector(userSelectors.user);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getManagerPreview = () => {
        if (managerPreview && Object.keys(managerPreview).length > 0) {
            return managerPreview;
        }

        if (user && Object.keys(user).length > 0) {
            return {
                image: user.image,
                display_name: user.display_name,
            };
        }

        return {};
    };

    return (
        <>
            <BaseCard
                avatarImgId={avatarImageId}
                createdAt={createdAt}
                textArray={textArray}
                message={message}
                onClose={onDisregard}
                onTagClick={onTagClick}
                {...rest}
                shouldRenderFooter={outcome !== Outcomes.UNHANDLED}
                footer={
                    <Outcome
                        text={<FormattedMessage id={`mapUpdates.${outcome === Outcomes.APPROVE ? 'approved_by' : 'declined_by'}`} />}
                        managerPreview={getManagerPreview()}
                    />
                }
                buttons={[
                    <CustomButton
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.MEDIUM}
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        clicked={outcome !== Outcomes.UNHANDLED || isDisabled}
                    >
                        <FormattedMessage id='mapUpdates.decline' />
                    </CustomButton>,
                    <CustomButton
                        singleClick
                        size={ButtonSize.MEDIUM}
                        type={ButtonType.PRIMARY}
                        onClick={onAddToMap}
                        clicked={outcome !== Outcomes.UNHANDLED || isDisabled}
                    >
                        <FormattedMessage id='mapUpdates.add_to_map' />
                    </CustomButton>,
                ]}
            >
                <TextArrayBody body={postBody} isTaggingDisabled onTagClick={onTagClick} />
                {postAttachments.length > 0 && (
                    <PostSlider
                        width={504}
                        height={504}
                        slidesToShow={1}
                        slidesToScroll={1}
                        dots={false}
                        classNamesPostImg={styles.postImg}
                        images={postAttachments}
                    />
                )}
            </BaseCard>
            <BaseModal isActive={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className='subtitle-bold m-b-8'>
                    <FormattedMessage id='mapUpdates.remove_step_title' />
                </div>
                <p>
                    <FormattedMessage
                        id='mapUpdates.remove_step_description'
                        values={{
                            stepName: textArray[textArray.length - 1]?.text,
                        }}
                    />
                </p>
                <div className='flex space-between'>
                    <CustomButton type={ButtonType.GHOST_BLACK} size={ButtonSize.MEDIUM} onClick={() => setIsModalOpen(false)}>
                        <FormattedMessage id='mapUpdates.cancel' />
                    </CustomButton>
                    <CustomButton
                        type={ButtonType.PRIMARY}
                        size={ButtonSize.MEDIUM}
                        onClick={() => {
                            onDecline();
                            setIsModalOpen(false);
                            setIsDisabled(true);
                        }}
                    >
                        <FormattedMessage id='mapUpdates.archive_step' />
                    </CustomButton>
                </div>
            </BaseModal>
        </>
    );
};

export default PendingStepCard;
