import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import DropZone from '../DropZone';
import DragAndDrop from '../DragAndDrop';
import classNames from 'classnames';
import CustomButton, { ButtonType } from '../../../components/common/buttons/CustomButton';
import ImportLoader from './components/ImportLoader';
import { FileType } from '../../../helpers/constants';
import VideoPlayer from './components/VideoPlayer';
import { getManagerMaps, getUploadImportFile, isOnboarding, uploadImageToS3 } from '../../../helpers';
import { createMap } from '../../../redux/map/actions';
import { useDispatch, useSelector } from 'react-redux';
import { checkImportSteps, importPreviewSteps, importSteps } from '../../../redux/onboard/actions';
import { addMapReducer } from '../../../redux/store';
import RequestMessage from '../../../components/common/RequestMessage';
import { userActions, userSelectors } from '../../../redux/user';
import { REQUEST_NOTIFICATIONS } from '../../../config/constants';
import IconBackArrow from '../../../components/common/icons/IconBackArrow';
import IconDownload from '../../../components/common/icons/IconDownload';
import IconPlayVideo from '../../../components/common/icons/IconPlayVideo';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';

const GOOGLE_MAPS_SUBTITLE =
    'Importing from Google My Maps is a quick and easy way to add multiple places to your map at once. To import from Google My Maps, upload a KML file with your places. ';

const Import = ({
    startImportForImportPage,
    type,
    handleImportSteps,
    handleClickBack,
    page,
    setImportSteps,
    mapId,
    mapName,
    setAddedStepsCount,
    setWaitingStepsCount,
    updateMaps,
    setIsImport,
}) => {
    const dispatch = useDispatch();
    const maps = useSelector(userSelectors.maps);
    const [uploaded, setUpload] = useState(false);
    const [files, setFiles] = useState([]);
    const [importing, setImport] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);
    const [loader, setLoader] = useState(false);
    const [importFileId, setImportFiledId] = useState(null);
    const [foundedSteps, setFoundedSteps] = useState(0);
    const [startImport, setStartImport] = useState(false);
    const [importId, setImportId] = useState(null);
    const [dataRequest, setDataRequest] = useState({});
    const [createdMapId, setCreatedMapId] = useState(mapId);
    const [loaderCount, setLoaderCount] = useState(0);
    
    const removeFile = idx => {
        setFiles([...files.slice(0, idx), ...files.slice(idx + 1)]);
    };
    
    const checkStepsImported = useCallback(importId => {
        dispatch(checkImportSteps(importId))
            .then(async ({ data }) => {
                const percent = Math.round((data?.import_state?.created_posts_count / data?.import_state?.found_posts_count) * 100);
                if (percent > 0) {
                    setLoaderCount(percent - 1);
                } else {
                    setLoaderCount(percent);
                }
                if (!!data?.import_state?.is_completed) {
                    const maps = await getManagerMaps();
                    addMapReducer(maps);
                    updateMaps?.(maps);
                    setLoaderCount(percent);
                    setTimeout(() => {
                        handleImportSteps();
                        setAddedStepsCount(data?.import_state?.created_posts_count);
                        setWaitingStepsCount(data?.import_state?.pending_posts_count);
                        setImportId(null);
                        setStartImport(false);
                        setIsImport(true);
                    }, 1000);
                }
                return data;
            })
            .then(data => {
                if (!data?.import_state.is_completed && !data?.import_state.is_failed) {
                    setTimeout(() => checkStepsImported(importId), 500);
                } else {
                    if (data?.import_state?.failure_message) {
                        dispatch(
                            userActions.setMessageRequest({
                                text: data?.import_state?.failure_message,
                                status: 'error',
                                img: REQUEST_NOTIFICATIONS.UPLOAD_IMG_ERROR,
                            })
                        );
                    }
                    setTimeout(() => {
                        setStartImport(false);
                        setImport(false);
                        setUpload(false);
                        
                        //ob finish here
                        mixpanel.track(mixpanelEvents.OB_FINISH, {
                            [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                            [mixpanelProperties.MAP_NAME]: mapName,
                        });
                    }, 1000);
                }
            });
    }, []);
    
    useEffect(() => {
        if (startImport && importId) {
            if (startImportForImportPage) {
                mixpanel.track(mixpanelEvents.IMPORT_STEPS_START, { [mixpanelProperties.ADD_STEPS]: localStorage.getItem('importMode') });
            }
            checkStepsImported(importId);
        }
    }, [startImport, importId]);
    
    useEffect(() => {
        if (uploaded) {
            mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                [mixpanelProperties.PAGE]: 'approve',
                [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                [mixpanelProperties.MAP_NAME]: mapName,
            });
        }
    }, [uploaded]);
    
    useEffect(() => {
        if (type === FileType.GOOGLE) {
            mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                [mixpanelProperties.PAGE]: 'import',
                [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                [mixpanelProperties.MAP_NAME]: mapName,
            });
        } else if (type === FileType.EXCEL) {
            mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                [mixpanelProperties.PAGE]: 'import',
                [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                [mixpanelProperties.MAP_NAME]: mapName,
            });
        }
    }, [type]);
    
    const onLoadFiles = useCallback(
        async files => {
            setLoader(true);
            for (const file of files) {
                if (
                    type === FileType.GOOGLE
                        ? file.type === 'application/vnd.google-earth.kml+xml' || file.type === 'application/inkml+xml' || file?.name.split('.').pop() === 'kml'
                        : type === FileType.EXCEL &&
                        (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                            file.type === 'application/vnd.ms-excel' ||
                            file?.name.split('.').pop() === '.xls' ||
                            file?.name.split('.').pop() === '.xlsx')
                ) {
                    getUploadImportFile(type === FileType.GOOGLE ? 'KML' : 'EXCEL').then(info => {
                        uploadImageToS3({
                            uploadUrl: info.url,
                            file,
                            onUploadedFile: () => {
                                setFiles(state => state.concat({ file: file, imageId: info['import_file_id'] }));
                                setImportFiledId(info['import_file_id']);
                                if (
                                    type === FileType.GOOGLE &&
                                    (file.type === 'application/vnd.google-earth.kml+xml' ||
                                        file.type === 'application/inkml+xml' ||
                                        file?.name.split('.').pop() === 'kml')
                                ) {
                                    const name = mapName;
                                    const client_type = 'map_manager';
                                    const template = 'community';
                                    const mapData = JSON.stringify({
                                        name,
                                        client_type,
                                        template,
                                    });
                                    if (createdMapId) {
                                        setImportId(null);
                                        dispatch(
                                            importSteps({
                                                import_type: 'KML',
                                                map_id: createdMapId,
                                                import_file_id: info['import_file_id'],
                                            })
                                        ).then(async ({ data }) => {
                                            setStartImport(true);
                                            setImportId(data?.import_id);
                                            setFoundedSteps(data?.found_posts_count);
                                            setLoader(false);
                                            setImport(true);
                                        });
                                    } else {
                                        dispatch(createMap(mapData)).then(async ({ data, error }) => {
                                            if (data) {
                                                const isUserFirstTime = isOnboarding()
                                                localStorage.setItem('recent_mapId', data?.id);
                                                setCreatedMapId(data?.id);
                                                dispatch(
                                                    importSteps({
                                                        import_type: 'KML',
                                                        map_id: data?.id,
                                                        import_file_id: info['import_file_id'],
                                                    })
                                                ).then(async ({ data }) => {
                                                    setStartImport(true);
                                                    setImportId(data?.import_id);
                                                    setFoundedSteps(data?.found_posts_count);
                                                    setLoader(false);
                                                    setImport(true);
                                                    mixpanel.track(mixpanelEvents.MM_OB_GOOGLE_KML_FILE_UPLOADED, {
                                                        [mixpanelProperties.IS_OB]: isUserFirstTime,
                                                    });
                                                    // await checkImport(data?.import_id);
                                                });
                                            }
                                            if (error) {
                                                dispatch(
                                                    userActions.setMessageRequest({
                                                        text: 'Error upload file',
                                                        status: 'error',
                                                        img: REQUEST_NOTIFICATIONS.UPLOAD_IMG_ERROR,
                                                    })
                                                );
                                                setStartImport(false);
                                                setImport(false);
                                                setUpload(false);
                                                setLoader(false);
                                            }
                                        });
                                    }
                                } else if (
                                    file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                    file?.type === 'application/vnd.ms-excel' ||
                                    file?.name.split('.').pop() === '.xls' ||
                                    file?.name.split('.').pop() === '.xlsx'
                                ) {
                                    dispatch(importPreviewSteps(info['import_file_id'])).then(({ data, error }) => {
                                        if (data) {
                                            setFoundedSteps(data?.found_posts_count);
                                            setUpload(true);
                                        }
                                        if (error) {
                                            setUpload(false);
                                            alert('Upload error');
                                        }
                                        setLoader(false);
                                    });
                                }
                            },
                        });
                    });
                } else {
                    setLoader(false);
                    if (type === FileType.GOOGLE) {
                        dispatch(
                            userActions.setMessageRequest({
                                text: REQUEST_NOTIFICATIONS.UPLOAD_KML_FILE_ERROR,
                                status: 'error',
                                img: REQUEST_NOTIFICATIONS.UPLOAD_IMG_ERROR,
                            })
                        );
                    } else if (type === FileType.EXCEL) {
                        dispatch(
                            userActions.setMessageRequest({
                                text: REQUEST_NOTIFICATIONS.UPLOAD_EXEL_FILE_ERROR,
                                status: 'error',
                                img: REQUEST_NOTIFICATIONS.UPLOAD_IMG_ERROR,
                            })
                        );
                    }
                }
            }
        },
        [type, mapName, createdMapId]
    );
    
    const handleConvertSteps = useCallback(() => {
        const name = mapName;
        const client_type = 'map_manager';
        const template = 'community';
        const mapData = JSON.stringify({
            name,
            client_type,
            template,
        });
        if (createdMapId) {
            setImportId(null);
            dispatch(
                importSteps({
                    import_type: 'EXCEL',
                    map_id: createdMapId,
                    import_file_id: importFileId,
                })
            ).then(async ({ data }) => {
                setStartImport(true);
                setImportId(data?.import_id);
            });
        } else {
            if (mapId) {
                setCreatedMapId(mapId);
                dispatch(
                    importSteps({
                        import_type: 'EXCEL',
                        map_id: mapId,
                        import_file_id: importFileId,
                    })
                ).then(async ({ data }) => {
                    setStartImport(true);
                    setImportId(data?.import_id);
                });
            } else
                dispatch(createMap(mapData)).then(async ({ data }) => {
                    setCreatedMapId(data?.id);
                    localStorage.setItem('recent_mapId', data?.id);
                    dispatch(
                        importSteps({
                            import_type: 'EXCEL',
                            map_id: data?.id,
                            import_file_id: importFileId,
                        })
                    ).then(async ({ data }) => {
                        mixpanel.track(mixpanelEvents.MM_OB_EXCEL_FILE_UPLOADED, {
                            [mixpanelProperties.IS_OB]: isOnboarding(),
                        });
                        setStartImport(true);
                        setImportId(data?.import_id);
                    });
                });
        }
        setImport(true);
    }, [mapName, importFileId, createdMapId]);
    return (
        <div className={classNames(styles.block, { [styles.uploadingBlock]: importing })}>
            {importing ? (
                <ImportLoader loaderCount={loaderCount} foundedSteps={foundedSteps} mapName={mapName} type={type}/>
            ) : (
                <div className={styles.contentContainer}>
                    <p className={styles.title}>
                        {type === FileType.EXCEL && <img src='/assets/img/steps3Images/icoExel.svg' alt=''/>}
                        {type === FileType.GOOGLE && <img src='/assets/img/steps3Images/GoogleMaps_logo.svg' alt=''/>}
                        {type === FileType.EXCEL && 'Import from Excel'}
                        {type === FileType.GOOGLE && 'Import from Google My Maps'}
                    </p>
                    {type === FileType.GOOGLE ? (
                        <>
                            <p className={styles.subTitle}>{GOOGLE_MAPS_SUBTITLE}</p>
                            <p className={styles.footerText}>
                                <CustomButton
                                    onClick={() => {
                                        setOpenVideo(true);
                                        mixpanel.track(mixpanelEvents.MM_OB_GOOGLE_YOUTUBE_VIDEO_WATCHED, {
                                            [mixpanelProperties.IS_OB]: isOnboarding(),
                                        });
                                    }}
                                    size={'smallSize'}
                                    type={ButtonType.TERTIARY_BLACK}
                                    text={
                                        <>
                                            <IconPlayVideo/>
                                            <label className={styles.videoText}>Video: </label>
                                            How do I export a file from Google My Maps?
                                        </>
                                    }
                                />
                            </p>
                        </>
                    ) : (
                        <>
                            <p className={styles.subTitle}>
                                Importing from Excel is a quick and easy way to add multiple places to your map at once.{' '}
                            </p>
                            <div className={classNames(styles.downloadContainer)}>
                                <label className={styles.downloadContent}>
                                    Use our template to provide information about the places you want to add, then upload your Excel file here.
                                </label>
                                <span className={classNames(styles.downloadBtn, styles.footerText)}>
                                    <a href='https://static.steps.me/import-to-steps-excel-template.xlsx' download>
                                        <CustomButton
                                            onClick={() =>
                                                mixpanel.track(mixpanelEvents.MM_OB_EXCEL_TEMPLATE_DOWNLOADED, {
                                                    [mixpanelProperties.IS_OB]: isOnboarding(),
                                                })
                                            }
                                            size={'smallSize'}
                                            type={ButtonType.TERTIARY_BLACK}
                                            text={
                                                <>
                                                    <IconDownload/>
                                                    <label>Download a file template</label>
                                                </>
                                            }
                                        />
                                    </a>
                                </span>
                            </div>
                        </>
                    )}
                    {uploaded ? (
                        <div className={classNames(styles.uploadBlock, styles.uploaded)}>
                            <div className={styles.content}>
                                <p className={styles.text}>We found {foundedSteps} locations in your file</p>
                                <div className={styles.dFlex}>
                                    <CustomButton
                                        size={'largeSize'}
                                        onClick={() => {
                                            removeFile();
                                            setUpload(false);
                                        }}
                                        className={styles.clear}
                                        type={ButtonType.SECONDARY_BLACK}
                                        text={'Clear'}
                                    />
                                    <CustomButton
                                        size={'largeSize'}
                                        onClick={() => {
                                            handleConvertSteps();
                                        }}
                                        type={ButtonType.PRIMARY_BLUE}
                                        text={'Convert to places'}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <DragAndDrop type={type} className={styles.uploadBlock} onDrop={files => onLoadFiles(files, 'drop')}>
                                <DropZone loading={loader} type={type} addOn='step' onLoadFiles={files => onLoadFiles(files, 'upload')}/>
                                <p className={styles.text}>Or drag and drop your file here</p>
                            </DragAndDrop>
                        </>
                    )}
                </div>
            )}
            {!importing && (
                <div className={styles.backBtn}>
                    <CustomButton
                        size={'largeSize'}
                        onClick={() => {
                            handleClickBack(page - 1);
                            setImportSteps(false);
                            if (type === FileType.GOOGLE)
                                mixpanel.track(mixpanelEvents.MM_OB_GOOGLE_BACK_BUTTON_CLICKED, {
                                    [mixpanelProperties.IS_OB]: isOnboarding(),
                                });
                        }}
                        type={ButtonType.TERTIARY_BLACK}
                        text={
                            <div className={styles.btnText}>
                                <IconBackArrow/>
                                Back
                            </div>
                        }
                    />
                </div>
            )}
            {openVideo && <VideoPlayer onClose={() => setOpenVideo(false)}/>}
            <RequestMessage importFile setDataRequest={setDataRequest} dataRequest={dataRequest}/>
        </div>
    );
};
export default Import;
