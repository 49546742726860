import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ShareTypes } from '../../constants';
import { copyToClipboard, createDeepLink, sendEventNotification } from '../../helpers';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import styles from './index.module.scss';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ModalsDownloadApp from '../common/modals/ModalsDownloadApp';
import copiesPrefix from '../../copies.json';
import { mapSelectors } from '../../redux/map';

export const copies = copiesPrefix.shareMap;

export const ShareTab = ({ image, prompt, isParallel, shareWhat, selectedStep, onCopyLinkClick, fromWhere }) => {
    const mapId = useSelector(state => state.user.selectedMapId);
    const newDeepLink = useSelector(mapSelectors.generateDeepLink(mapId));
    // const mapImage = useSelector(state => state[mapId].mapImage);
    // const stepImage = useSelector(state => state[mapId].stepImage);
    const dispatch = useDispatch();

    const [isAppInstalled, setIsAppInstalled] = useState(false);
    const [isInstagramTooltipOpen, setIsInstagramTooltipOpen] = useState(false);
    const [shareLink, setShareLink] = useState({
        facebook: '',
        twitter: '',
        copy: '',
    });
    const inProductShareMethod = shareWhat === 'step' ? ShareTypes.STEP_PROFILE : ShareTypes.MAP_MAP_VIEW;

    useEffect(() => {
        (async function () {
            Promise.all([
                createDeepLink(mapId, {
                    link_type: 'map',
                    channel: 'Facebook',
                    reason: 'create_facebook_share_link',
                    inProductShareMethod: inProductShareMethod,
                }),
                createDeepLink(mapId, {
                    link_type: 'map',
                    channel: 'Twitter',
                    reason: 'create_twitter_share_link',
                    inProductShareMethod: inProductShareMethod,
                }),
                createDeepLink(mapId, {
                    link_type: shareWhat === 'step' ? 'step' : 'map',
                    channel: 'copy_link',
                    reason: 'create_copy_share_link',
                    data: shareWhat === 'step' ? { step_id: selectedStep._id } : {},
                    inProductShareMethod: inProductShareMethod,
                }),
            ]).then(links => {
                const [facebook, twitter, copy] = links;
                handleSocaialEvent(fromWhere, copy || newDeepLink);
                setShareLink({
                    facebook,
                    twitter,
                    copy,
                });
            });
        })();

        return () => {
            dispatch(mapActions.clearStepAndMapImages(mapId));
        };
    }, []);

    const handleCopyLink = () => {
        onCopyLinkClick();
        copyToClipboard(shareLink.copy || newDeepLink);
        handleSocaialEvent('copy_link', shareLink.copy || newDeepLink);
    };

    const onClickInstagramButton = () => {
        setIsInstagramTooltipOpen(true);
        const data = {
            shared_item_type: selectedStep ? 'step' : 'map',
            shared_item_id: selectedStep?._id || mapId,
        };
        sendEventNotification({
            data,
            eventName: 'INSTAGRAM_STORY_SHARE_NOTIFICATION_REQUESTED',
        });
        handleSocaialEvent('Instagram', '');
    };

    const handleSocaialEvent = (shareWith, url) => {
        mixpanel.track(mixpanelEvents.SHARE_CLICK, {
            [mixpanelProperties.SHARE_VIA]: shareWith,
            [mixpanelProperties.SHARE_ELEMENT]: selectedStep ? 'step' : 'map',
            [mixpanelProperties.STEP_ID]: selectedStep ? selectedStep._id : undefined,
            [mixpanelProperties.STEP_NAME]: selectedStep ? selectedStep.title : undefined,
            [mixpanelProperties.URL]: url,
        });
    };

    return (
        <div className={styles.sharePopup} style={{ borderRadius: shareWhat === 'step' ? '25px' : '' }}>
            <div style={{ marginTop: shareWhat === 'step' ? '30px' : '0px' }}>
                <div className={styles.shareYourMap}>{prompt ? prompt.title : shareWhat === 'step' ? copies.title_step : copies.title}</div>
                <div className={styles.subtitleShareYourMap}>{prompt ? prompt.subTitle : shareWhat === 'step' ? '' : copies.description}</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {(shareWhat === 'step' && !Boolean(image)) || (shareWhat === 'map' && !Boolean(image)) ? (
                        <div
                            className={'animate'}
                            style={{
                                width: '320px',
                                height: '320px',
                                margin: '24px',
                            }}
                        />
                    ) : (
                        <img
                            height={355}
                            alt='map image'
                            style={{ margin: '8px' }}
                            src={
                                shareWhat === 'step'
                                    ? image
                                        ? `data:image/png;base64,${Buffer.from(image).toString('base64')}`
                                        : null
                                    : image
                                    ? `data:image/png;base64,${Buffer.from(image).toString('base64')}`
                                    : null
                            }
                        />
                    )}
                </div>

                <div className={styles.shareOnSocial}>
                    <div className={styles.shareLinks}>
                        <div style={{ position: 'relative' }}>
                            {isInstagramTooltipOpen && (
                                <div className={styles.instagramTooltip}>
                                    <img
                                        onClick={() => setIsInstagramTooltipOpen(false)}
                                        className={styles.tooltipCancel}
                                        src={'assets/img/steps3Images/x-white.svg'}
                                        width={10}
                                        height={10}
                                    />
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '11px',
                                        }}
                                    >
                                        {copies.check_phone}
                                    </span>
                                    <span>{copies.instagram_check}</span>
                                    <div className={styles.tooltipButtons}>
                                        <button className={styles.sendAgain} onClick={onClickInstagramButton}>
                                            {copies.send_again}
                                        </button>
                                        <button onClick={() => setIsInstagramTooltipOpen(false)} className={styles.gotIt}>
                                            {copies.got_it}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <button onClick={() => onClickInstagramButton()} className={styles.instagramShare}>
                                {copies.instagram}
                            </button>
                        </div>
                        <TwitterShareButton onClick={() => handleSocaialEvent('Twitter', shareLink.twitter)} url={shareLink.twitter}>
                            <button className={styles.twitterShare}>{copies.twitter}</button>
                        </TwitterShareButton>
                        <FacebookShareButton onClick={() => handleSocaialEvent('Facebook', shareLink.facebook)} iconOnly={true} url={shareLink.facebook}>
                            <button className={styles.faceBookShare}>{copies.facebook}</button>
                        </FacebookShareButton>
                    </div>
                </div>
                <div className={styles.copyLink} style={{ marginTop: '10px' }}>
                    <div
                        style={{
                            marginRight: '8px',
                            color: '#aeb1b6',
                            fontSize: '15px',
                        }}
                    >
                        {shareLink.copy || newDeepLink}
                    </div>
                    <button onClick={handleCopyLink} className={styles.copyLinkBtnBlack}>
                        {copies.copy_link}
                    </button>
                </div>
            </div>
            {isAppInstalled && <ModalsDownloadApp fromWhere={'share'} openDownloadModal={isAppInstalled} closeModal={() => setIsAppInstalled(false)} />}
        </div>
    );
};