import React, { useCallback, useEffect, useState, useMemo } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { mapTagObjects } from "../../helpers";
import { mapActions, mapSelectors } from "../../redux/map";
import { userSelectors } from "../../redux/user";
import EmptyTags from "../EmptyTags";
import Tags from "./Tags";
import mixpanel, { mixpanelEvents, mixpanelProperties } from "../../helpers/mixpanel";
import { userActionSelectors } from "../../redux/userAction";
import ROUTES from "../../config/routes";
import { useNavigate } from "react-router";
import { noop } from "lodash";
import BaseModal, { ModalSizes } from "../../modalSysyem/BaseModal";
import { X } from "phosphor-react";
import Lottie from "react-lottie";
import loaderAnimation from "../../lottie/circleLoader.json";
import copiesPrefix from "../../copies.json";

const copies = copiesPrefix.filters;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimation
};

const FilterTags = ({
  isFiltered,
  onClickPrev = noop,
  handleFilter,
  handleSelectClick,
  selectedTags,
  clearFilteredTag
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapId = useSelector(userSelectors.selectedMapId);
  const map = useSelector(mapSelectors.map(mapId));
  const steps = useSelector(mapSelectors.steps(mapId));
  const [tags, setTags] = useState(selectedTags);
  const [filteredSteps, setFilteredSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const currentPage = useSelector(userActionSelectors.currentPage);
  let tagsObject = useMemo(() => mapTagObjects(map?.tag_objects), [map]);
  
  useEffect(() => {
    dispatch(mapActions.getAllStepsPreviewData(mapId, "active"));
  }, [mapId]);

  const handleSelectTagClick = value => {
    const newTags = tags?.find(item => item._id === value._id) ? tags.filter(tag => tag._id !== value._id) : [...tags, value];
    setTags(newTags);
  };

  useEffect(() => {
    const tagsObject = tags;
    if (tagsObject.length > 0) {
      const tags = {};
      tagsObject.forEach(tag => {
        if (tags[tag.category_id]) tags[tag.category_id].push(tag._id);
        else tags[tag.category_id] = [tag._id];
      });
      const tagsQuery = Object.values(tags).reduce((all, curr) => {
        all.push(curr.map(tag => tag));
        return all;
      }, []);
      if (Object.values(steps).length > 0) {
        let stepsList = Object.values(steps);
        let filteredStepList = stepsList.filter(step => tagsQuery.every(tag => tag.some(item => step?.selected_tags_ids?.includes(item))));
        if (isFiltered && Object.values(tags)?.length === 0) {
          setFilteredSteps(stepsList?.length);
          return;
        }
        setFilteredSteps(filteredStepList?.length);
      }
    }
    if (isFiltered && filteredSteps === 0) {
      // Allow 'Show All' click Steps when removing step's tag - when tag exits only in this specifiec step.
      setFilteredSteps(1);
    }
  }, [tags, steps, isFiltered]);

  const renderTags = useCallback(
    (category, idx) => {
      const selectedCategoryTags = tags.filter(item => item.category_id === category.category_id)?.length;
      return (
        <Tags
          category={category}
          selectedCategoryTags={selectedCategoryTags}
          selectedTags={tags}
          idx={idx}
          handleSelectClick={handleSelectClick}
          handleSelectTagClick={handleSelectTagClick}
        />
      );
    },
    [tags, handleSelectTagClick]
  );

  const handleClose = useCallback(() => {
    onClickPrev();
    if (!isFiltered) {
      clearFilteredTag();
    }
  }, [isFiltered]);

  const handleClickFilter = useCallback(() => {
    if (tags.length === 0) {
      handleClose();
      clearFilteredTag();
    } else {
      setLoading(true);
      handleFilter(tags);
    }
  }, [tags, currentPage]);

  const handleClearClick = () => {
    setTags([]);
  };

  const handleMapSettingsComponents = useCallback(() => {
    onClickPrev();
    navigate("/" + ROUTES.TAGS);
    dispatch(mapActions.setToGeneral(map._id, false));
    dispatch(mapActions.setToComponents(map._id, true));
  }, [dispatch, map._id]);

  const renderDoneButtonText = () => {
    if (tags?.length === 0) return copies.show_all_steps;
    if (filteredSteps > 0) return `${copies.show} ${filteredSteps} ${copies.steps}`;
    return copies.no_match;
  };

  return (
    <BaseModal isActive onClose={onClickPrev} size={ModalSizes.LARGE} className={styles.block}>
      <div className={styles.header}>
        <div className={styles.title}>{copies.title}</div>
        <X
          className={styles.backButton}
          size="18"
          color="#3A3C3F"
          onClick={() => {
            onClickPrev();
            mixpanel.track(
              mixpanelEvents.FILTER_CLOSE,
              { [mixpanelProperties.HOW]: "close" },
              {
                [mixpanelProperties.NUMBER_OF_FILTERS]: filteredSteps
              }
            );
          }}
        />
      </div>
      <div className={styles.content}>
        {Object.values(tagsObject).length === 0 ? (
          <EmptyTags onClick={handleMapSettingsComponents} />
        ) : (
          Object.values(tagsObject).map((item, idx) => renderTags(item, idx))
        )}
      </div>
      {Object.values(tagsObject).length > 0 && (
        <div className={styles.footer}>
          <div>
                <span onClick={handleClearClick} className={styles.clear}>
                    {tags.length > 1 ? copies.clear_filters : copies.clear_filter}
                </span>
          </div>
          <div>
            <button
              className={classNames({
                [styles.disabled]: filteredSteps === 0
              })}
              disabled={filteredSteps === 0}
              onClick={() => {
                handleClickFilter();
                mixpanel.track(mixpanelEvents.FILTER_CLOSE, {
                  [mixpanelProperties.HOW]: "button",
                  [mixpanelProperties.NUMBER_OF_FILTERS]: tags?.length
                });
              }}
            >
              {loading ?
                <Lottie options={defaultOptions} isStopped={false} isPaused={false}
                        width={40} height={40} /> : renderDoneButtonText()}
            </button>
          </div>
        </div>
      )}
    </BaseModal>
  );
};

export default FilterTags;
