import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SettingsTabs } from '../constants';
import { userSelectors } from '../../../redux/user';
import { Users, GlobeSimple, Star } from '../../../components/common/icons';

import styles from './index.module.scss';

const MenuItems = [
    {
        name: SettingsTabs.GENERAL,
        Icon: GlobeSimple,
    },
    {
        name: SettingsTabs.MEMBERS,
        Icon: Users,
    },
];

const StepSubMenu = ({ setSelectedTab, selectedTab }) => {
    const mapId = useSelector(userSelectors.selectedMapId);

    return (
        <div className={styles.topBar}>
            {MenuItems.map(({ name, Icon }) => {
                const isSelected = name === selectedTab;
                return (
                    <Link
                        key={name}
                        to={`/settings/${name.toLowerCase()}?map_id=${mapId}`}
                        className={classNames(styles.menuButton, {
                            [styles.isSelected]: isSelected,
                        })}
                        onClick={() => {
                            setSelectedTab(name);
                        }}
                    >
                        <button>
                            <Icon size={16} color={isSelected ? '#2171ec' : '#666a70'} />
                            <div
                                className={classNames(styles.label, {
                                    [styles.isSelected]: isSelected,
                                })}
                            >
                                <FormattedMessage id={`mapSettings.tabs.${name}`} />
                            </div>
                        </button>
                    </Link>
                );
            })}
        </div>
    );
};

export default StepSubMenu;
