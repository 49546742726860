import React, { useState, useRef, useEffect, useContext } from 'react';
import { noop, get } from 'lodash';
import styles from './index.module.scss';
import './mapboxgl.css';
import { initMap, resetMap } from '../core/init';
import { setSource } from '../core/source';
import { createStepPopup, deleteAllPopupsFromMap, deletePopupFromMap, createStepMarker, stepTypes, isPopupOnStep, isPopupExist } from '../core/markers';
import { findStepType } from '../../../helpers';
import DisplayNamePopup from '../popups/DisplayName';
import ConfirmLocation from '../popups/ConfirmLocation';
import ImageOnly from '../popups/ImageOnly';
import AddStepPopUp from '../popups/AddStep';
import StepPreview from '../popups/StepPreview';
import { Provider } from 'react-redux';
import { store } from '../../../redux/store';
import { useDebounce } from '../../../hooks';
import classNames from 'classnames';
import { StepTypes } from '../../../constants';
import { Plus, Minus } from 'phosphor-react';
import { DropPinViewContext, useDropPin } from '../../../components/AddStep/components/DropPinView';

const mapboxgl = process.env.NODE_ENV === 'test' ? require('mapbox-gl/dist/mapbox-gl.js') : require('!mapbox-gl');

let temporaryStepElement = null;
let selectedStepElement = null;

const TEMPORARY_POPUP_ID = 'TemporaryD3isplayNamePopup';

const StepsMap = ({
    mapId,
    mapName,
    steps,
    center,
    zoom,
    onBoundsChange,
    allowOverlap,
    temporaryStep,
    onClickMap,
    disableTemporaryByClicking,
    disableOpeningStepPreview,
    onClickTemporaryStep = noop,
    selectedStepId,
    onConfirmLocation,
    onClickStepPreview = noop,
    onAddStep = noop,
    zoomPanelClassName,
    onCancelPopup,
    disableDragRotate,
    disableAnimation,
    fitBoundsBySteps = [],
    onChangeCenterOfAllSteps = noop,
    disableEasing = false,
    withLoader = false,
    className,
}) => {
    const map = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [boundsChange, setBoundsChange] = useState(null);
    const boundsChangeDebounce = useDebounce(boundsChange, 500);
    const { setIsPinDropped, isDropPinView, setIsLocationFound } = useContext(DropPinViewContext);
    const { confirmDropPinLocation, cancelDropPinLocation } = useDropPin(map);

    const createTemporaryStep = (step, type) => {
        deleteTemporaryStepElement();
        const marker = createStepMarker(map.current, step, type || stepTypes.temporaryStep, {
            onClick: () => {
                if (type === stepTypes.temporaryStep) {
                    marker.remove();
                    temporaryStepElement = null;
                }
                onClickTemporaryStep && onClickTemporaryStep();
            },
        });
        temporaryStepElement = marker;
    };

    const renderTemporaryStepPopUp = temporaryStep => {
        const tempStepTitle = temporaryStep.title;
        const tempStepImage = temporaryStep.image;
        const isSuggestToAdd = temporaryStep.suggestToAdd;
        const isConfirmLocation = temporaryStep.isConfirmLocation;
        const isStickToMap = temporaryStep.isStickToMap;
        if (!temporaryStep) {
            return null;
        }
        if (temporaryStep.customPopup) {
            const CustomPopup = temporaryStep.customPopup;
            createTemporaryStep(temporaryStep, isStickToMap ? stepTypes.selectedStep : stepTypes.temporaryStep);
            return <CustomPopup />;
        }
        if (tempStepImage) {
            return <ImageOnly image={tempStepImage} />;
        }
        if (isSuggestToAdd) {
            // TODO: should move outside along with the popup
            setIsPinDropped(true);
            return (
                <AddStepPopUp
                    mapId={mapId}
                    mapName={mapName}
                    step={{ ...temporaryStep, ...temporaryStep.position }}
                    createTemporaryStep={createTemporaryStep}
                    position={temporaryStep.position}
                    droppedPinFlow={isDropPinView}
                    title={tempStepTitle}
                    address={temporaryStep.address}
                    onAddressFound={() => setIsLocationFound(true)}
                    onAddressPending={() => setIsLocationFound(false)}
                    onConfirm={address => {
                        const location = { ...temporaryStep, address };
                        confirmDropPinLocation(location);
                        deletePopupFromMap(map.current, TEMPORARY_POPUP_ID);
                        onAddStep(location, isDropPinView);
                    }}
                    onCancel={address => {
                        const location = { ...temporaryStep, address };
                        cancelDropPinLocation(location);
                        onCancelPopup && onCancelPopup();
                    }}
                />
            );
        }
        if (isConfirmLocation) {
            return (
                <ConfirmLocation
                    title={tempStepTitle}
                    onConfirm={() => onConfirmLocation && onConfirmLocation(temporaryStep)}
                    onCancel={() => onCancelPopup && onCancelPopup()}
                />
            );
        }
        return <DisplayNamePopup title={tempStepTitle} />;
    };

    useEffect(() => {
        if (!boundsChangeDebounce) {
            return;
        }
        onBoundsChange && onBoundsChange(boundsChangeDebounce);
    }, [boundsChangeDebounce]);

    useEffect(() => {
        if (!map.current?._mapId) {
            return;
        }
        if (center) {
            if (disableAnimation) {
                map.current.setCenter(center);
                map.current.setZoom(zoom);
            } else {
                map.current.flyTo({
                    center: center,
                    essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                    zoom, // change zoom while flyTo if zoom is defined
                });
            }
        }
    }, [center, zoom, disableAnimation]);

    useEffect(() => {
        selectedStepElement?.remove();
        selectedStepElement = null;
        const step = steps.find(step => step._id === selectedStepId);
        if (!step) return;
        const stepType = stepTypes[findStepType(step)];

        if (selectedStepId && steps.length > 0) {
            selectedStepElement = createStepMarker(map.current, step, stepType, {});
        }
    }, [selectedStepId, steps]);

    useEffect(() => {
        if (!map.current || !map.current?._mapId || !fitBoundsBySteps || fitBoundsBySteps.length === 0) return;
        try {
            const bounds = new mapboxgl.LngLatBounds();
            for (const step of fitBoundsBySteps) {
                const lon = step.lon || step.lng || 0;
                const lat = step.lat || 0;
                const coordinates = [lon, lat];
                bounds?.extend(coordinates);
            }

            const fitBounds = [Object.values(bounds._sw), Object.values(bounds._ne)];
            map.current.fitBounds(fitBounds, { padding: 50, maxZoom: 12 });
        } catch (e) {
            console.error(e);
        }
    }, [fitBoundsBySteps, map.current]);

    useEffect(() => {
        deletePopupFromMap(map.current, TEMPORARY_POPUP_ID);
        if (temporaryStep) {
            const tempStep = {
                ...temporaryStep.position,
                title: temporaryStep.title,
            };

            if (isDropPinView || temporaryStep.customPopup) {
                createStepPopup(map.current, tempStep, TEMPORARY_POPUP_ID, renderTemporaryStepPopUp(temporaryStep));
            } else {
                createTemporaryStep(tempStep, temporaryStep.isStickToMap ? stepTypes.selectedStep : stepTypes.temporaryStep);
                if ((tempStep.title && tempStep.title !== 'selected location') || temporaryStep.image) {
                    createStepPopup(map.current, tempStep, TEMPORARY_POPUP_ID, renderTemporaryStepPopUp(temporaryStep));
                }
            }
        } else {
            deleteTemporaryStepElement();
        }
    }, [temporaryStep]);

    const deleteTemporaryStepElement = () => {
        temporaryStepElement?.remove();
        temporaryStepElement = null;
    };

    useEffect(() => {
        if (!map.current?._mapId) return;
        deleteAllPopupsFromMap(map.current);
        resetMap(map.current);
        setBoundsChange({
            bounds: map.current.getBounds(),
            zoom: map.current.getZoom(),
            position: map.current.getCenter(),
        });
    }, [mapId, map.current]);

    useEffect(() => {
        if (!map.current?._mapId) return;
        if (disableDragRotate) map.current.dragRotate.disable();
        else map.current.dragRotate.enable();
    }, [disableDragRotate, map.current]);

    useEffect(() => {
        if (!map || map.current instanceof mapboxgl.Map) {
            return;
        }

        map.current = initMap(map.current, {
            allowOverlap,
            markerClassName: styles.stepMarker,
            zoom,
            center,
            onClickStep,
            onMouseEnterStep,
            onMouseLeaveStep,
            onClickMap: onClickMapEvent,
            disableEasing,
        });
        map.current.on('drag', () => {
            setBoundsChange({
                bounds: map.current?.getBounds(),
                zoom: map.current?.getZoom(),
                position: map.current?.getCenter(),
            });
        });
        map.current.on('zoom', () => {
            setBoundsChange({
                bounds: map.current?.getBounds(),
                zoom: map.current?.getZoom(),
                position: map.current?.getCenter(),
            });
            if (isPopupExist(map.current, 'StepPreview')) {
                deletePopupFromMap(map.current, 'StepPreview');
            }
        });

        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [map.current]);

    useEffect(() => {
        if (!map.current) return;

        const markers = {
            [StepTypes.NEW]: [],
            [StepTypes.RECOMMENDED]: [],
            [StepTypes.REGULAR]: [],
        };

        steps.forEach(step => {
            const stepType = getStepTypeByIndication(step);
            step.active && markers[stepType || step.preview_type || StepTypes.REGULAR]?.push(step);
        });

        setSource(map.current, 'steps-source', markers[StepTypes.REGULAR]);
        setSource(map.current, 'steps-new-source', markers[StepTypes.NEW]);
        setSource(map.current, 'steps-recommended-source', markers[StepTypes.RECOMMENDED]);

        const bounds = new mapboxgl.LngLatBounds();
        for (const step of steps) {
            const lon = step.lon || step.lng || 0;
            const lat = step.lat || 0;
            const coordinates = [lon, lat];
            bounds?.extend(coordinates);
        }

        if (bounds._ne && bounds._sw) {
            const centerLon = (bounds._sw.lng + bounds._ne.lng) / 2;
            const centerLat = (bounds._sw.lat + bounds._ne.lat) / 2;
            onChangeCenterOfAllSteps({ lon: centerLon, lat: centerLat });
        }
    }, [steps, map.current]);

    const onClickStep = step => {
        !disableOpeningStepPreview &&
        createStepPopup(
            map.current,
            step,
            'StepPreview',
            <Provider store={store}>
                <StepPreview
                    mapId={mapId}
                    stepId={step._id}
                    onClick={args => {
                        deletePopupFromMap(map.current, 'StepPreview');
                        onClickStepPreview({ ...args, step: step });
                    }}
                />
            </Provider>,
        );
    };

    const getStepTypeByIndication = step => {
        return get(step, 'indications[0]', []).type;
    };

    const onClickMapEvent = e => {
        const lngLat = { lat: e.lngLat.lat, lon: e.lngLat.lng };
        const popup = isPopupExist(map.current, 'StepPreview');
        if (popup) {
            deletePopupFromMap(map.current, 'StepPreview');
        } else {
            !disableTemporaryByClicking && createTemporaryStep(lngLat);
        }
        onClickMap({ lngLat, isPopupExist: !!popup });
    };

    const onMouseEnterStep = step => {
        if (!isPopupOnStep(map.current, step)) createStepPopup(map.current, step, 'DisplayNamePopup',
            <DisplayNamePopup title={step.title} />);
    };

    const onMouseLeaveStep = step => {
        deletePopupFromMap(map.current, 'DisplayNamePopup');
    };

    return (
        <div style={{ height: '100%' }}>
            {withLoader && isLoading && <div className={classNames(styles.loader, 'animate')} />}
            <div className={classNames([styles.bottomRightContainer, zoomPanelClassName])}>
                <button
                    className={styles.circleButton}
                    onClick={() =>
                        map.current.flyTo({
                            center: map.current.getCenter(),
                            essential: true,
                            zoom: map.current.getZoom() + 0.5,
                        })
                    }
                >
                    <Plus size={16} color='#3A3C3F' weight='bold' />
                </button>
                <button
                    className={styles.circleButton}
                    onClick={() =>
                        map.current.flyTo({
                            center: map.current.getCenter(),
                            essential: true,
                            zoom: map.current.getZoom() - 0.5,
                        })
                    }
                >
                    <Minus size={16} color='#3A3C3F' weight='bold' />
                </button>
            </div>
            <div
                ref={map}
                className={classNames(styles.map, className, {
                    [styles.isLoading]: isLoading,
                })}
            />
        </div>
    );
};

export default StepsMap;
