import { useContext } from 'react';
import { CursorFollower } from '../CursorFollower';
import { DropPinViewContext } from './DropPinViewContext';
import styles from './DropPinView.module.scss';

const DropPinViewMessage = () => {
    const { isPinDropped } = useContext(DropPinViewContext);
    return (
        <CursorFollower isEnabled={!isPinDropped} className={styles.dropPinTooltip}>
            Click on the map to drop the pin
        </CursorFollower>
    );
};

export default DropPinViewMessage;
