import { success, error } from '@redux-requests/core';
import {} from './types';

const reducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
