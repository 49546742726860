import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { LockSimple as LockIcon } from 'phosphor-react';
import { MapTrifold, Newspaper, Tag, BellSimple, Chat, Plus } from 'phosphor-react';
import IconInsights from '../../icons/IconInsights';
import IconImport from '../../icons/import';
import IconMembers from '../../icons/IconMembers';
import IconMapSettings from '../../icons/IconMapSettings';

const menuButtonConfig = { size: '20', color: '#515B67', stroke: '#515B67' };

export const menuButtonType = {
    map: {
        title: 'Places',
        icon: <MapTrifold {...menuButtonConfig} />,
    },
    feed: {
        title: 'Feed',
        icon: <Newspaper {...menuButtonConfig} />,
    },
    tags: {
        title: 'Tags',
        icon: <Tag {...menuButtonConfig} />,
    },
    insights: {
        title: 'Insights',
        icon: <IconInsights {...menuButtonConfig} />,
    },
    updates: {
        title: 'Updates',
        icon: <BellSimple {...menuButtonConfig} />,
    },
    imports: {
        title: 'Imports',
        icon: <IconImport {...menuButtonConfig} />,
    },
    members: {
        title: 'Members',
        icon: <IconMembers {...menuButtonConfig} />,
    },
    settings: {
        title: 'Settings',
        icon: <IconMapSettings {...menuButtonConfig} />,
    },
    feedback: {
        title: 'Feedback',
        icon: <Chat {...menuButtonConfig} />,
    },
    createMap: {
        title: 'Create new map',
        icon: <Plus {...menuButtonConfig} />,
    },
};

const MenuButton = ({ containerState, type, onClick, onHover, number, className, isLocked }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { title, icon } = type;
    const isActive = containerState || isHovered;
    
    const renderLeftSection = () => {
        if (isLocked) return <LockIcon className={styles.lock} color={isActive ? '#212B37' : '#515b67'} size={18}/>;
        if (number > 0 && !containerState) return <div className={styles.redCircle}>{number}</div>;
    };
    
    return (
        <div
            data-cy={`menu-item-${title}`}
            onMouseLeave={() => {
                setIsHovered(false);
                onHover && onHover(title.toLowerCase(), false);
            }}
            onMouseOver={() => {
                setIsHovered(true);
                onHover && onHover(title.toLowerCase(), true);
            }}
            onClick={onClick}
            className={classNames(
                styles.link,
                {
                    [styles.activeLink]: isActive,
                    [styles.hoveredLink]: isHovered && !containerState,
                },
                className
            )}
        >
            <div
                className={classNames(styles.icon, {
                    [styles.activeIcon]: containerState || isHovered,
                })}
            >
                {icon}
            </div>
            {title}
            {renderLeftSection()}
        </div>
    );
};

export default MenuButton;
