export const getStorage = (key, defaultValue = null) => {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {
        return defaultValue;
    }
};

export const signTokens = ({ accessToken, refreshToken }) => {
    localStorage.setItem('at', accessToken);
    localStorage.setItem('rt', refreshToken);
};

export const clearStorageOnLogout = () => {
    const tooltipArray = localStorage.getItem('tooltipStorage') && localStorage.getItem('tooltipStorage').split(',');
    localStorage.clear();
    localStorage.setItem('tooltipStorage', tooltipArray);
};

export const isOnboarding = () => {
    try {
        return localStorage.getItem('isOb') === 'true';
    } catch (e) {
        return false;
    }
};
