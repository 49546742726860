import { useState, createContext } from 'react';
import { noop } from 'lodash';
import { expandSidebar, resetMouseCursor } from './helpers';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

const DropPinViewContext = createContext({
    isDropPinView: false,
    isPinDropped: false,
    isLocationFound: false,
    setIsPinDropped: noop,
    setIsLocationFound: noop,
    onDropPinConfirm: noop,
    setOnDropPinConfirm: noop,
});

const DropPinViewProvider = ({ children }) => {
    const [location, setLocation] = useState(null);
    const [isDropPinView, setIsDropPinView] = useState(false);
    const [isPinDropped, setIsPinDropped] = useState(false);
    const [isLocationFound, setIsLocationFound] = useState(false);
    const [onDropPinConfirm, setOnDropPinConfirm] = useState(noop);

    return (
        <DropPinViewContext.Provider
            value={{
                isDropPinView,
                isPinDropped,
                isLocationFound,
                setIsPinDropped,
                setIsLocationFound,
                setIsDropPinView,
                location,
                setLocation,
                onDropPinConfirm: data => {
                    expandSidebar();
                    resetMouseCursor();
                    setIsPinDropped(false);
                    setIsDropPinView(false);
                    onDropPinConfirm(data);
                },
                setOnDropPinConfirm: callback => {
                    setOnDropPinConfirm(() => callback);
                },
                onDropPinCancel: params => {
                    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_DROP_PIN_PLACE_SELECTED_CANCEL, {
                        [mixpanelProperties.FLOW]: 'drop_pin',
                        [mixpanelProperties.ADDRESS]: params?.address,
                        [mixpanelProperties.COORDINATES]: [params?.position?.lat, params?.position?.lon],
                    });
                },
            }}
        >
            {children}
        </DropPinViewContext.Provider>
    );
};

export { DropPinViewContext, DropPinViewProvider };
