import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Star, { starType } from './Star';
import styles from './index.module.scss';

const NUM_OF_STARS = 5;

const Rating = ({ selectedStarts: numSelected = 0, className, updateRating, starSize = 22 }) => {
    const [selectedStars, setSelectedStars] = useState(numSelected);
    const [hoveredStars, setHoveredStars] = useState(0);

    useEffect(() => {
        setSelectedStars(numSelected);
    }, [numSelected]);

    const onStarClick = starIndex => {
        const updatedRating = starIndex + 1;
        if (!updateRating) return;
        setSelectedStars(updatedRating);
        updateRating(updatedRating);
    };

    const onStarHover = starIndex => {
        setHoveredStars(starIndex + 1);
    };

    const findStarType = starIndex => {
        if (hoveredStars) {
            if (starIndex < hoveredStars) {
                return starType.HOVERED;
            }
        } else if (selectedStars && starIndex < selectedStars) return starType.SELECTED;
        return starType.EMPTY;
    };

    const renderStars = () => {
        const starsToRender = [...Array(NUM_OF_STARS)];
        return starsToRender.map((e, starIndex) => (
            <Star
                key={starIndex}
                fill={findStarType(starIndex)}
                onMouseEnter={updateRating ? () => onStarHover(starIndex) : null}
                onClick={() => onStarClick(starIndex)}
                size={starSize}
            />
        ));
    };

    return (
        <div className={classNames(styles.block, className)} onMouseLeave={() => setHoveredStars(0)}>
            {renderStars()}
        </div>
    );
};

export default Rating;
