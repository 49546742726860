import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import copiesPrefix from '../../../../../../copies.json';

const copies = copiesPrefix?.listView?.bulkActions;

const BulkActions = ({ number, onClose, handleArchive, handleAddTags }) => {
    const [hoveredAdd, setHoverAdd] = useState(false);
    const [hoveredArchive, setHoverArchive] = useState(false);

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <div className={styles.number}>{number}</div>
                <div className={styles.content}>
                    <span className={styles.title}>{number > 1 ? copies.steps_selected : copies.step_selected}</span>
                    <div className={styles.actions}>
                        <div onMouseOver={() => setHoverAdd(true)} onMouseLeave={() => setHoverAdd(false)} onClick={handleAddTags} className={styles.action}>
                            {hoveredAdd ? (
                                <img src='/assets/img/steps3Images/hoveredAddTag.svg' alt='' />
                            ) : (
                                <img src='/assets/img/steps3Images/AddTag.svg' alt='' />
                            )}
                            <span className={classNames(hoveredAdd && styles.hovered)}>{copies.add_tags}</span>
                        </div>
                        <div
                            onMouseOver={() => setHoverArchive(true)}
                            onMouseLeave={() => setHoverArchive(false)}
                            onClick={handleArchive}
                            className={styles.action}
                        >
                            {hoveredArchive ? (
                                <img src='/assets/img/steps3Images/hoveredArchive.svg' alt='' />
                            ) : (
                                <img src='/assets/img/steps3Images/IcoArchive.svg' alt='' />
                            )}
                            <span className={classNames(hoveredArchive && styles.hovered)}>{copies.archive}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BulkActions;
