import { useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions } from '../../redux/map';
import config from '../../config';
import { HTML_COPIED_TEXT, LINK_COPIED_TEXT, ToastContext } from '../../context/ToastContext';
import BaseModal, { ModalSizes } from '../../modalSysyem/BaseModal';
import { X } from 'phosphor-react';
import { ShareTypes } from '../../constants';
import { ErrorBoundary } from 'react-error-boundary';
import { copies, ShareTab } from './ShareTab';
import { EmbedTab } from './EmbedTab';

const TOAST_TIMEOUT = 6000;

const ShareMapPopUp = ({ selectedMap, selectedStep, setSharePopup, shareWhat, isParallel, setIsParallel, prompt, fromWhere = 'step_profile' }) => {
    const [selectedTab, setSelectedTab] = useState('share');
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const mapId = useSelector(state => state.user.selectedMapId);
    const [image, setImage] = useState(null);
    const dispatch = useDispatch();
    const { setToast } = useContext(ToastContext);

    useEffect(() => {
        (async () => {
            try {
                if (selectedStep) {
                    dispatch(mapActions.createDeepLink('step', mapId, selectedStep._id, ShareTypes.SHARE_STEP));
                    const url = new URL(config.domain.address);
                    url.pathname += `/story-sharing-images/step/${selectedStep._id}`;
                    url.searchParams.append('reason', 'mm_instagram_share_preview');
                    url.searchParams.append('with_logo', 'false');

                    const accessToken = localStorage.getItem('at');

                    fetch(url.toString(), {
                        method: 'GET',
                        headers: {
                            Authorization: `${accessToken}`,
                            app_version: config.version.path,
                            app_platform: 'map_manager',
                        },
                    })
                        .then(res => {
                            return res.arrayBuffer();
                        })
                        .then(buffer => {
                            setImage(buffer);
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                    dispatch(mapActions.createDeepLink('map', selectedMap._id, undefined, ShareTypes.MAP_MAP_VIEW));

                    const accessToken = localStorage.getItem('at');
                    const url = new URL(config.domain.address);
                    url.pathname += `/story-sharing-images/map/${mapId}`;
                    url.searchParams.append('reason', 'mm_instagram_share_preview');
                    url.searchParams.append('with_logo', 'false');

                    fetch(url.toString(), {
                        method: 'GET',
                        headers: {
                            Authorization: `${accessToken}`,
                            app_version: config.version.path,
                            app_platform: 'map_manager',
                        },
                    })
                        .then(res => {
                            return res.arrayBuffer();
                        })
                        .then(buffer => {
                            setImage(buffer);
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }
            } catch (error) {
                console.error(error);
            }

            if (isParallel) {
                setIsParallel(false);
            }
        })();
    }, []);

    const setHtmlCopiedToast = () => setToast({ text: HTML_COPIED_TEXT, timeout: TOAST_TIMEOUT });
    const setLinkCopiedToast = () => setToast({ text: LINK_COPIED_TEXT, timeout: TOAST_TIMEOUT });

    return (
        <ErrorBoundary>
            <BaseModal isActive size={ModalSizes.MEDIUM} onClose={isTooltipOpen ? () => {} : () => setSharePopup(false)} className={styles.modal}>
                <X
                    className={styles.closeBtn}
                    onClick={() => {
                        setSharePopup(false);
                    }}
                    size={16}
                    data-cy='exit-popup'
                    data-testid='exit-popup'
                />
                {shareWhat !== 'step' && (
                    <div className={styles.tabsContainer}>
                        {!prompt && (
                            <>
                                <div
                                    onClick={() => setSelectedTab('share')}
                                    className={selectedTab == 'share' ? styles.selectedTab : styles.tab}
                                    style={{
                                        marginLeft: '64px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {copies.share_tab}
                                </div>
                                <div
                                    onClick={() => setSelectedTab('embed')}
                                    style={{ cursor: 'pointer' }}
                                    className={selectedTab == 'embed' ? styles.selectedTab : styles.tab}
                                >
                                    {copies.embed_tab}
                                </div>
                            </>
                        )}
                    </div>
                )}
                {selectedTab === 'share' ? (
                    <ShareTab
                        image={image}
                        shareWhat={shareWhat}
                        isTooltipOpen={isTooltipOpen}
                        setSharePopup={setSharePopup}
                        selectedStep={selectedStep}
                        selectedMap={selectedMap}
                        prompt={prompt}
                        isParallel={isParallel}
                        setIsTooltipOpen={setIsTooltipOpen}
                        onCopyLinkClick={setLinkCopiedToast}
                        fromWhere={fromWhere}
                    />
                ) : (
                    <EmbedTab selectedMap={selectedMap} onCopyLinkClick={setLinkCopiedToast} onCopyHtmlClick={setHtmlCopiedToast} />
                )}
            </BaseModal>
        </ErrorBoundary>
    );
};

export default ShareMapPopUp;
