import { useState, createContext } from 'react';
import { noop } from 'lodash';

const ModalContext = createContext({
    modal: null,
    setModal: noop,
});

const ModalProvider = ({ children }) => {
    const [modal, setModal] = useState(null);

    return (
        <ModalContext.Provider value={{ setModal }}>
            {children}
            {modal}
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };
