import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { CircleNotch, X } from 'phosphor-react';
import Loader from '../../../../components/common/icons/IconLoader';
import { getAddressByCoordinates } from '../../../../helpers';
import { stepTypes } from '../../core/markers';
import copies from '../../../../copies.json';
import styles from './index.module.scss';
import DropPinFlow from '../../../../components/AddStep/components/DropPinView/DropPinFlow';

const AddStepPopUp = ({
    onConfirm,
    className,
    title,
    onCancel,
    step,
    droppedPinFlow,
    position,
    mapId,
    mapName,
    address: defaultAddress,
    onAddressFound = noop,
    onAddressPending = noop,
    createTemporaryStep = noop,
}) => {
    const [address, setAddress] = useState(defaultAddress);
    const [isClicked, setClicked] = useState(false);

    useEffect(() => {
        (async () => {
            if (!droppedPinFlow) {
                return;
            }

            setAddress('');
            onAddressPending();
            createTemporaryStep(step, stepTypes.DROP_PIN_LOCATION_SELECTED);

            const address = await getAddressByCoordinates(position);
            if (!address) {
                return;
            }

            setAddress(address);
            onAddressFound(address);
            createTemporaryStep(step, stepTypes.DROP_PIN_LOCATION_FOUND);
        })();
    }, [position]);

    const renderDropPinPopover = () => {
        if (address) {
            const params = {
                mapId,
                mapName,
                address,
                isClicked,
                onCancel: () => onCancel(address),
                position,
                fromFlow: 'dropPin',
                onClick: () => setClicked(true),
                onDone: place => onConfirm(place),
            };
            return <DropPinFlow {...params} />;
        }

        return (
            <div>
                <CircleNotch size={20} className='spin' color='#2171EC' />
                <span>{copies?.addStep?.drop_pin_loading}</span>
            </div>
        );
    };

    if (droppedPinFlow) {
        return (
            <div
                className={classNames({
                    [styles.addressStepPopover]: true,
                    [styles.isLoading]: !address,
                    [styles.block]: true,
                    className,
                })}
                onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            >
                {renderDropPinPopover()}
            </div>
        );
    }

    return (
        <div
            className={classNames([styles.block, className])}
            onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <img className={styles.cancel} alt='close-popup' src={'assets/img/steps3Images/x.svg'} onClick={() => onCancel(address)} />
            <span className={styles.title}>
                Add <span>'{title}'</span> to the map
            </span>
            <button
                onClick={() => {
                    setClicked(true);
                    onConfirm(address);
                }}
                className={styles.btnConfirm}
            >
                {isClicked ? <Loader size='22px' /> : 'Add Step'}
            </button>
        </div>
    );
};

export default AddStepPopUp;
