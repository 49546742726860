import { onboardMapCenter } from './selectors';
import { setOnboardMapCenter } from './actions';

export const onboardSelectors = {
    onboardMapCenter,
};

export const onboardActions = {
    setOnboardMapCenter,
};
