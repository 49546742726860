import React from 'react';

const ChosenTag = ({ tagName, className, stepElement }) => {
    return (
        <div className={className}>
            {!stepElement && <img src={'../assets/img/check.svg'} alt={'tag img'} />}
            <span>{tagName}</span>
        </div>
    );
};

export default ChosenTag;
