import PropTypes from 'prop-types';

export const carouselPropTypes = {
    images: PropTypes.array.isRequired,
    onImageClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    addDeleteIcon: PropTypes.bool,
    imagesToShow: PropTypes.number,
    settings: PropTypes.shape({
        centerPadding: PropTypes.string,
        slide: PropTypes.string,
        easing: PropTypes.string,
        autoplay: PropTypes.bool,
        centerMode: PropTypes.bool,
        dots: PropTypes.bool,
        draggable: PropTypes.bool,
        fade: PropTypes.bool,
        focusOnSelect: PropTypes.bool,
        infinite: PropTypes.bool,
        accessibility: PropTypes.bool,
        adaptiveHeight: PropTypes.bool,
        arrows: PropTypes.bool,
        pauseOnDotsHover: PropTypes.bool,
        pauseOnFocus: PropTypes.bool,
        pauseOnHover: PropTypes.bool,
        useCSS: PropTypes.bool,
        useTransform: PropTypes.bool,
        variableWidth: PropTypes.bool,
        vertical: PropTypes.bool,
        swipeToSlide: PropTypes.bool,
        swipe: PropTypes.bool,
        touchMove: PropTypes.bool,
        rtl: PropTypes.bool,
        slidesPerRow: PropTypes.number,
        slidesToScroll: PropTypes.number,
        slidesToShow: PropTypes.number,
        speed: PropTypes.number,
        rows: PropTypes.number,
        touchThreshold: PropTypes.number,
        autoplaySpeed: PropTypes.number,
        initialSlide: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    events: PropTypes.shape({
        onEdge: PropTypes.func,
        onInit: PropTypes.func,
        onLazyLoad: PropTypes.func,
        onReInit: PropTypes.func,
        onSwipe: PropTypes.func,
        beforeChange: PropTypes.func,
    }),
};
