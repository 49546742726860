import styles from './index.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Toggle = ({ leftSection, rightSection, onChange, className = {}, selected }) => {
    const { text: leftText, value: leftValue } = leftSection;
    const { text: rightText, value: rightValue } = rightSection;

    return (
        <div className={classNames(styles.container, className)}>
            <span
                className={classNames(styles.section, {
                    [styles.selected]: leftValue === selected,
                })}
                onClick={() => onChange(leftSection)}
            >
                {leftText}
            </span>
            <span
                className={classNames(styles.section, {
                    [styles.selected]: rightValue === selected,
                })}
                style={{ width: '62px' }}
                onClick={() => onChange(rightSection)}
            >
                {rightText}
            </span>
        </div>
    );
};

export default Toggle;

Toggle.propTypes = {
    leftSection: PropTypes.exact({
        text: PropTypes.string,
        value: PropTypes.any,
    }),
    rightSection: PropTypes.exact({
        text: PropTypes.string,
        value: PropTypes.any,
    }),
    onChange: PropTypes.func,
    selected: PropTypes.string,
};
