import Comment from './Comment';
import { generateImageUrl } from '../helpers/index';
import { PostTypes } from '../constants';
import { AvatarSizes } from '../components/common/Avatar';
import { Types, ImageReasons } from './constants';
import { TextArray } from './TextArray';

const POST_IMAGE_WIDTH = 504;
const POST_IMAGE_HEIGHT = 325;

class Post {
    constructor(post = {}, width = 200, height = 200, commentsList = {}) {
        const {
            _id,
            location_title,
            creator_preview = {},
            text_array = [],
            attachments = [],
            step_id,
            step_title,
            created_at,
            step_address,
            claps_count = 0,
            comments_count = 0,
            is_clapped_by_user = false,
            is_pinned = false,
            type = '',
            parent_post_id = '',
            location = {},
            map_id,
            secondaries = [],
            secondaries_cursor = null,
            step_thumbnail_id,
            steps_mentions = [],
        } = post;
        const images = attachments.filter(({ type }) => type === Types.IMAGE || type === Types.GOOGLE_IMAGE || type === Types.INSTAGRAM_IMAGE);
        const postImageId = images[0]?.attachment?.image_id;
        const creatorImageId = creator_preview?.image;

        this.id = _id;
        this.attachments = images.map(({ attachment }) => generateImageUrl({ imageId: attachment.image_id, reason: ImageReasons.POST, height, width }));
        this.imagesId = images.map(({ attachment }) => attachment.image_id);
        this.fullSizeImages = images.map(({ attachment }) => generateImageUrl({ imageId: attachment.image_id, reason: ImageReasons.POST }));
        this.attachmentsList = images;
        this.clapsCount = claps_count;
        this.isClapped = is_clapped_by_user;
        this.comments = secondaries?.map(comment => new Comment(comment));
        this.commentsCursor = secondaries_cursor;
        this.totalComments = comments_count;
        this.creator = creator_preview;
        this.mentions = steps_mentions;

        if (commentsList.posts?.length) {
            this.comments = commentsList.posts.map(comment => new Comment(comment));
            this.commentsCursor = commentsList.cursor;
            this.totalComments = commentsList.total_results;
        }

        this.address = step_address;
        this.title = location_title;
        this.userName = creator_preview.display_name;
        this.userType = creator_preview.indicator;
        this.userId = creator_preview._id;
        this.stepId = step_id;
        this.stepTitle = step_title;
        this.createdAt = created_at;
        this.parentPostId = parent_post_id;
        this.isPinned = is_pinned;
        this.type = type;
        this.isFeedPost = type === PostTypes.FEED;
        this.isAnnouncement = type === PostTypes.ANNOUNCEMENT && is_pinned;
        this.coordinates = location?.coordinates;
        this.mapId = map_id;
        this.postImage = generateImageUrl({
            reason: ImageReasons.POST,
            imageId: postImageId || step_thumbnail_id,
            width: POST_IMAGE_WIDTH,
            height: POST_IMAGE_HEIGHT,
        });
        this.creatorAvatarImage = generateImageUrl({
            reason: ImageReasons.AVATAR,
            imageId: creatorImageId,
            width: AvatarSizes.MEDIUM,
            height: AvatarSizes.MEDIUM,
        });
        this.body = text_array.map((item, index) => new TextArray({ ...item, id: index }, this.id));
    }
}

export default Post;
