import { ovalLayer, recommendedLayer, stepsLayer, newStepsLayer } from './layers';
import { createStepMarker, stepTypes } from './markers';

const SIDEBAR_WIDTH = 500;
const MAPBOX_STYLE = 'mapbox://styles/stepsprod/ckncu0enk1tdx17n0ruk0rz5k';

const mapboxgl = process.env.NODE_ENV === 'test' ? require('mapbox-gl/dist/mapbox-gl.js') : require('!mapbox-gl');
mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlcHNwcm9kIiwiYSI6ImNrbW5va2Y0bTB5eGEycXJ6aTVnYnh2aXMifQ.ybxcnXuaQ4WB00KuJdvuWg';
mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js', null, true);

const hoveredSteps = {};

export const initMap = (
    container,
    { center, zoom, allowOverlap, onMouseEnterStep, onMouseLeaveStep, onClickStep, onClickMap, markerClassName, disableEasing = false },
) => {
    const map = new mapboxgl.Map({
        container,
        style: MAPBOX_STYLE,
        center: center || [34.8, 32],
        minZoom: 1.65,
        zoom: zoom || 8,
    });

    map.easeTo({
        animate: true,
        padding: { top: 0, bottom: 0, left: disableEasing ? 0 : SIDEBAR_WIDTH, right: 0 },
        duration: 500,
    });

    hoveredSteps[map._mapId] = {
        mouseHovered: null,
        selected: {},
        hardSelected: {},
    };

    map.on('load', () => {
        addImage(map, '/assets/img/steps3Images/step-marker-oval.png', 'oval');
        addImage(map, '/assets/img/steps3Images/step-marker.png', 'step');
        addImage(map, '/assets/img/steps3Images/recommemded-marker.png', 'recommended');
        addImage(map, '/assets/img/steps3Images/new-marker.png', 'new'); // png or jpeg

        if (!allowOverlap) map.addLayer(ovalLayer(false));
        map.addLayer(stepsLayer({ allowOverlap }));
        map.addLayer(newStepsLayer({ allowOverlap }));
        map.addLayer(recommendedLayer({ allowOverlap }));

        map.on('mouseenter', ['steps-layer', 'recommended-layer', 'new-steps-layer'], e => {
            map.getCanvas().style.cursor = 'pointer';
            const step = e.features[0].properties;
            const hoveredStep = hoveredSteps[map._mapId].mouseHovered;
            if (hoveredStep?._id == step._id) return;
            const parsedIndications = JSON.parse(step.indications);
            const stepType = stepTypes[parsedIndications[0]?.type || step.preview_type] || stepTypes.REGULAR;
            const elementDOM = createStepMarker(map, step, stepType, {
                onMouseLeave: () => {
                    if (hoveredSteps[map._mapId].mouseHovered?._id === step?._id) {
                        hoveredSteps[map._mapId].mouseHovered?.elementDOM.remove();
                        hoveredSteps[map._mapId].mouseHovered = null;
                        onMouseLeaveStep && onMouseLeaveStep(step);
                    }
                },
                onClick: () => {
                    for (const selectedStepId of Object.keys(hoveredSteps[map._mapId].selected)) {
                        deleteSelectedStep(map, selectedStepId);
                    }
                    selectStep(map, step);
                    onClickStep && onClickStep(step);
                },
                className: markerClassName,
            });
            if (hoveredStep) hoveredStep.elementDOM.remove();
            hoveredSteps[map._mapId].mouseHovered = { _id: step._id, elementDOM };
            onMouseEnterStep && onMouseEnterStep(step);
        });
        map.on('mouseleave', 'steps-layer', e => {
            map.getCanvas().style.cursor = '';
        });
        map.on('zoom', e => {
            for (const selectedStepId of Object.keys(hoveredSteps[map._mapId].selected)) {
                deleteSelectedStep(map, selectedStepId);
            }
        });
        map.on('click', e => {
            for (const selectedStepId of Object.keys(hoveredSteps[map._mapId].selected)) {
                deleteSelectedStep(map, selectedStepId);
            }
            onClickMap && onClickMap(e);
        });
    });
    return map;
};

export const selectStep = (map, step, isHardSelected) => {
    if (hoveredSteps[map._mapId].mouseHovered._id == step._id) {
        hoveredSteps[map._mapId][isHardSelected ? 'hardSelected' : 'selected'][step._id] = hoveredSteps[map._mapId].mouseHovered.elementDOM;
        hoveredSteps[map._mapId].mouseHovered = null;
        return;
    }
    const elementDOM = createStepMarker(map, step, stepTypes.selectedStep, {});
    hoveredSteps[map._mapId][isHardSelected ? 'hardSelected' : 'selected'][step._id] = elementDOM;
};

export const deleteSelectedStep = (map, stepId) => {
    hoveredSteps[map._mapId].selected[stepId]?.remove();
    delete hoveredSteps[map._mapId].selected[stepId];
};

export const resetMap = map => {
    if (hoveredSteps[map._mapId]) {
        hoveredSteps[map._mapId].mouseHovered?.elementDOM.remove();
        hoveredSteps[map._mapId].mouseHovered = null;
        for (const selectedStepId of Object.keys(hoveredSteps[map._mapId].selected)) {
            deleteSelectedStep(map, selectedStepId);
        }
    }
};

const addImage = (map, url, key) => {
    map.loadImage(url, (error, image) => {
        if (error) return;
        else if (!map.hasImage(key)) map.addImage(key, image, { sdf: false });
    });
};
