import React, { useEffect } from 'react';
import styles from './index.module.scss';
import StepGhostSuggestToAdd from './StepGhostSuggestToAdd';
import StepGhostPresentationOnly from './StepGhostPresentationOnly';
import { mapActions } from '../../../../../redux/map';

const StepGhost = ({ title, onClick, onCancel, page, location, files, text, hover, mapId, stepId }) => {
    return page === 4 ? (
        <StepGhostSuggestToAdd
            mapId={mapId}
            location={location}
            text={text}
            files={files}
            title={title}
            onClick={onClick}
            onCancel={onCancel}
            hover={hover}
            stepId={stepId}
        />
    ) : (
        <StepGhostPresentationOnly title={title} />
    );
};

export default StepGhost;
