import { useMemo, useState, useEffect } from 'react';
import { noop } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { MapPin, At } from 'phosphor-react';
import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import CustomButton, { ButtonSize, ButtonType } from '../../../common/buttons/CustomButton';
import { PostHeadline, AnnouncementTag } from '../../../common/PostHeadline';
import { userSelectors } from '../../../../redux/user';
import { Checkbox } from '../../../common/inputs';
import { PopperTooltip } from '../../../common/Tooltips';
import { IconDelete } from '../../../common/icons';
import usePost from '../usePost';
import copiesPrefix from '../../../../copies.json';
import { generateImageUrl } from '../../../../helpers';
import { AvatarSizes } from '../../../common/Avatar';
import { TextInput } from '../../../TextInput';
import { UserTypes, PostTypes } from '../../../../constants';
import { mapSelectors } from '../../../../redux/map';
import { feedActions, feedSelectors } from '../../../../redux/feed';
import AnnouncementConfirmationPopover from './AnnouncementConfirmationPopover';
import useTextInput from '../../../TextInput/useTextInput';
import ExitConfirmationModal from './ExitConfirmationModal';
import Routes from '../../../../config/routes';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { useImageCarousel, Images } from '../../../../lib/ImagesCarousel';

import styles from './CreateNewPostModal.module.scss';
import { postSelectors } from '../../../../redux/posts';
import { FormattedMessage } from 'react-intl';

const ANNOUNCEMENT_CONFIRMATION = 'announcementConfirmation';
const copies = copiesPrefix.feed?.addPostModal;

const CreateNewPostModal = ({
    postId,
    stepId,
    onClose = noop,
    onSuccess = noop,
    imageIds = [],
    body: initBody = [],
    postType: initPostType = PostTypes.FEED,
    isOpen = false,
    isAnnounceable = false,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, post } = usePost();
    const { tagMember, tagLocation, mentionTriggers } = useTextInput();
    const user = useSelector(userSelectors.user);
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const mapName = useSelector(mapSelectors.mapName(mapId));
    const announcementId = useSelector(feedSelectors.getAnnouncementId);
    const isConfirmedAnnouncementPopover = useSelector(feedSelectors.isConfirmedAnnouncementPopover);
    const userRoleInSelectedMap = useSelector(mapSelectors.userRole(mapId, user?.id));
    const [body, setBody] = useState(initBody); // aka textarray
    const [postType, setPostType] = useState(initPostType);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const isAnnouncementLocked = map?.flags?.blockedFeatures?.CREATE_ANNOUNCEMENT;
    const reduxPost = useSelector(postSelectors.postById(postId));
    const { images, uploadImages, isUploadImagesPending, onRemoveImage } = useImageCarousel({ imageIds });

    useEffect(() => {
        setBody(initBody);
    }, [isOpen]);

    const creatorAvatarImage = useMemo(() => {
        return generateImageUrl({
            imageId: user.image,
            reason: 'avatar',
            width: AvatarSizes.MEDIUM,
            height: AvatarSizes.MEDIUM,
        });
    }, [user.image]);

    const showAnnouncementCheckboxTooltip = useMemo(() => {
        if (isConfirmedAnnouncementPopover) return false;
        // if has annnouncement, always show the confirmation popover
        if (announcementId && postType === PostTypes.ANNOUNCEMENT) return true;
        if (localStorage.getItem(ANNOUNCEMENT_CONFIRMATION)) return false;
        return true;
    }, [announcementId, isConfirmedAnnouncementPopover, postType]);

    const track = event => {
        mixpanel.track(mixpanelEvents.POST_POPUP_ACTION_CLICKED, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: mapName,
            [mixpanelProperties.USER_ID]: user?.id,
            [mixpanelProperties.USER_STATUS]: userRoleInSelectedMap === 'owner' ? UserTypes.MAP_CREATOR : UserTypes.MAP_MANAGER,
            [mixpanelProperties.ACTION]: event,
        });
    };

    const trackPostOnFeed = () => {
        mixpanel.track(mixpanelEvents.MM_FEED_POST_POSTED, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: mapName,
            [mixpanelProperties.USER_ID]: user?.id,
            [mixpanelProperties.USER_STATUS]: userRoleInSelectedMap === 'owner' ? UserTypes.MAP_CREATOR : UserTypes.MAP_MANAGER,
        });
    };

    const isDisabled = (body.length === 0 && images.length === 0) || isUploadImagesPending;

    const handleOnClose = () => {
        if (body.length || isUploadImagesPending) {
            setIsConfirmationModalOpen(true);
            return;
        }

        onClose();
    };

    return (
        <>
            <ExitConfirmationModal
                isOpen={isConfirmationModalOpen}
                onConfirm={() => {
                    setIsConfirmationModalOpen(false);
                    onClose();
                }}
                onCancel={() => setIsConfirmationModalOpen(false)}
            />
            {!isConfirmationModalOpen && (
                <BaseModal isActive={isOpen} onClose={handleOnClose} size={ModalSizes.LARGE} className={styles.newPostModal} disableClickAway>
                    <Images.DragAndDrop onDrop={uploadImages}>
                        <div className={styles.header}>
                            <h1 className={styles.title}>
                                <FormattedMessage id={postType === PostTypes.EDIT_POST ? 'feed.editPostModal.title' : 'feed.addPostModal.title'} />
                            </h1>
                            <span onClick={handleOnClose} className={styles.closeButton}>
                                <IconDelete size={24} stroke='#599AFE' />
                            </span>
                        </div>
                        <div className={styles.body}>
                            <PostHeadline title={user.name} badge={postType === PostTypes.ANNOUNCEMENT && <AnnouncementTag />} mainImage={creatorAvatarImage} />
                            <TextInput
                                id='create-new-post-modal'
                                data-cy='create-new-post-modal'
                                data={reduxPost?.textArray?.length > 0 ? reduxPost.textArray : body}
                                postId={postId}
                                onChange={(_value, textArray) => setBody(textArray)}
                                className={styles.textArea}
                                placeholder={copies?.placeholder}
                                mentionTriggers={mentionTriggers}
                            />
                            <Images.Carousel images={images} onRemoveImage={onRemoveImage} />
                            <p className={styles.taggingTip}>
                                <span>Tip: Use </span>
                                <MapPin size={15} color='#B5B7BB' />
                                <span>{'  '} to tag a step.</span>
                            </p>
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.leftSide}>
                                <span className={styles.footerButton}>
                                    <Images.Button buttonSize={25} onLoadFiles={uploadImages} />
                                </span>
                                <span className={styles.footerButton} data-cy='tag-location-button'>
                                    <MapPin
                                        size={25}
                                        color='#1463DD'
                                        cursor='pointer'
                                        onClick={() => {
                                            track('tag_location');
                                            tagLocation();
                                        }}
                                    />
                                </span>
                                <span className={styles.footerButton} data-cy='tag-member-button'>
                                    <At
                                        size={25}
                                        color='#1463DD'
                                        cursor='pointer'
                                        onClick={() => {
                                            track('tag_member');
                                            tagMember();
                                        }}
                                    />
                                </span>
                            </div>
                            <div className={styles.rightSide}>
                                {isAnnounceable && (
                                    <Checkbox
                                        id='announcement-checkbox'
                                        disabled={isAnnouncementLocked}
                                        onChange={isChecked => setPostType(isChecked ? PostTypes.ANNOUNCEMENT : PostTypes.FEED)}
                                        showCheckboxTooltip={isAnnouncementLocked || showAnnouncementCheckboxTooltip}
                                        initialState={initPostType === PostTypes.ANNOUNCEMENT}
                                        checkboxTooltip={({ isClicked, children }) => (
                                            <PopperTooltip
                                                isClicked={isClicked}
                                                offsetTop={20}
                                                placement='top'
                                                triggeredBy={isAnnouncementLocked ? 'hover' : 'click'}
                                                isActive={postType === PostTypes.ANNOUNCEMENT || isAnnouncementLocked}
                                                tooltip={
                                                    <AnnouncementConfirmationPopover
                                                        isLocked={isAnnouncementLocked}
                                                        onConfirm={() => {
                                                            if (isAnnouncementLocked) {
                                                                onClose();
                                                                navigate(`/${Routes.MAP}/${Routes.MAP_STAGES}`);
                                                                return;
                                                            }
                                                            localStorage.setItem(ANNOUNCEMENT_CONFIRMATION, true);
                                                            dispatch(
                                                                feedActions.setIsConfirmedAnnouncementPopover({
                                                                    isConfirmed: true,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                }
                                                tooltipClass={styles.announcementConfirmationTooltip}
                                            >
                                                {children}
                                            </PopperTooltip>
                                        )}
                                        tooltip={copies[isAnnouncementLocked ? 'locked_announcement_text_first' : 'make_announcement_checkbox_tooltip']}
                                        label={copies?.make_announcement_checkbox}
                                    />
                                )}
                                <CustomButton
                                    loader={isLoading}
                                    className={styles.ctaButton}
                                    type={ButtonType.PRIMARY}
                                    size={ButtonSize.MEDIUM}
                                    disabled={isDisabled}
                                    onClick={() => {
                                        if (isLoading) {
                                            return;
                                        }

                                        trackPostOnFeed();
                                        post({
                                            type: postType,
                                            imageIds: images.map(image => image.imageId),
                                            body,
                                            onSuccess,
                                            postId,
                                            stepId,
                                        });
                                    }}
                                >
                                    {copies?.cta}
                                </CustomButton>
                            </div>
                        </div>
                    </Images.DragAndDrop>
                </BaseModal>
            )}
        </>
    );
};

export default CreateNewPostModal;
