import React, { useState, useMemo } from 'react';
import styles from './index.module.scss';
import noop from 'lodash';
import PaginationSlider, { arrowDirection } from '../../common/PaginationSlider';
import HighlightSlide from './HighlightSlide';
import slidesData from './slidesData';
import { buttonCta } from '../contants';
import CustomButton, { ButtonType } from '../../common/buttons/CustomButton';
import copiesPrefix from '../../../copies.json';

const copies = copiesPrefix.mapStages;

const HighlightsSlider = ({ reachedLevel, onClickTryInsights = noop, onClickTryAnnouncement = noop }) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const tryButtonCta = {
        [buttonCta.INSIGHTS]: onClickTryInsights,
        [buttonCta.ANNOUNCEMENT]: onClickTryAnnouncement,
    };

    const currentSlideCta = useMemo(() => slidesData[currentSlideIndex]?.cta, [currentSlideIndex]);

    return (
        <>
            <PaginationSlider
                slides={slidesData.map(slide => (
                    <HighlightSlide slide={slide} />
                ))}
                options={{ loop: true }}
                onSlideChange={slideIndex => setCurrentSlideIndex(slideIndex)}
            >
                <h2 className={styles.title}>{copies.sliderTitle}</h2>
                <div className={styles.footer}>
                    <div className={styles.arrows}>
                        <PaginationSlider.Arrow direction={arrowDirection.PREV} />
                        <PaginationSlider.Arrow />
                    </div>
                    {currentSlideCta && reachedLevel && (
                        <CustomButton className={styles.tryButton} type={ButtonType.PRIMARY_BLACK} onClick={tryButtonCta[currentSlideCta]}>
                            {copies.try}
                        </CustomButton>
                    )}
                </div>
            </PaginationSlider>
        </>
    );
};

export default HighlightsSlider;
