import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import QRCode from 'qrcode.react';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { createDeepLink } from '../../../../helpers';
import copiesPrefix from '../../../../copies.json';
import { userSelectors } from '../../../../redux/user';

const copies = copiesPrefix.downloadAppModal;

const ModalsDownloadApp = ({ openDownloadModal, closeModal, qrCode }) => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const [link, setLink] = useState('');

    useEffect(() => {
        if (openDownloadModal) {
            mixpanel.track(mixpanelEvents.DOWNLOAD_APP_SHOW);
        }
    }, [openDownloadModal]);

    useEffect(() => {
        createDeepLink(mapId, {
            client_type: 'map_manager',
            feature: 'download_step_app_modal',
            link_type: 'map_store',
            reason: 'map_manager_download_item',
            stage: 'main_menu',
        }).then(link => {
            setLink(link);
        });
    }, []);

    const handleDownloadName = store => {
        mixpanel.track(mixpanelEvents.DOWNLOAD_APP_DONE, {
            [mixpanelProperties.HOW]: store,
        });
    };

    return (
        <div className={styles.modals}>
            <div className={styles.block}>
                <div onClick={() => closeModal()} className={styles.closeBlock}>
                    <img src='/assets/img/icon_cancel_blue.svg' />
                </div>
                <div className={styles.body}>
                    <p className={styles.title}>{copies.title}</p>
                    <p className={styles.text}>{copies.subtitle}</p>
                    <div className={styles.QRcode}>
                        <QRCode value={link} size={180} />
                    </div>
                    <div className={styles.divider}>
                        <div className={styles.leftHr} />
                        <span className={styles.orText}>{copies.divider}</span>
                        <div className={styles.rightHr} />
                    </div>
                    <p className={styles.text}>{copies.app_store_label}</p>
                    <div className={styles.iconBlock}>
                        <a
                            onClick={() => handleDownloadName('app_store')}
                            className={styles.iconApple}
                            href={'https://apps.apple.com/app/steps-map-your-world/id1449597018'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/steps3Images/appleAppIcon.svg'} alt={''} />
                        </a>
                        <a
                            onClick={() => handleDownloadName('google_play')}
                            className={styles.iconGoogle}
                            href={'https://play.google.com/store/apps/details?id=com.steps.app.steps'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/steps3Images/googleAppIcon.svg'} alt={''} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalsDownloadApp;
