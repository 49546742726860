import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styles from './addStepForm.module.scss';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, mapSelectors } from '../../redux/map';
import { modalsActions } from '../../redux/modals';
import { userSelectors } from '../../redux/user';
import BackArrowButton from '../common/buttons/BackArrowButton';
import { CurrentUserAvatar, AvatarSizesNames } from '../common/Avatar';
import CustomButton, { ButtonSize, ButtonType } from '../common/buttons/CustomButton';
import { noop } from 'lodash';
import copiesPrefix from '../../copies.json';
import ImageCarousel from '../../lib/ImageCarousel';
import DragAndDrop from '../DragAndDrop';
import DropZone from '../DropZone';
import { getUploadImageInfo, uploadImageToS3, getImageSrcFromFile } from '../../helpers';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import ImageLoader from '../common/ImageLoader';

const copies = copiesPrefix.addStep;

const IMAGE_CAROUSEL_HEIGHT = 238;
const IMAGE_CAROUSEL_WIDTH = 318;

const AddStepForm = ({
  onBack = noop,
  onError = noop,
  onStepAdded = noop,
  location = {},
  place = {},
  fromWhere = ''
}) => {
  const dispatch = useDispatch();
  const mapId = useSelector(userSelectors.selectedMapId);
  const mapName = useSelector(mapSelectors.mapName(mapId));
  const ghostStep = useSelector(mapSelectors.ghostStep(mapId));
  const [text, setText] = useState('');
  const [imageLoading, setImageLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const user = useSelector(userSelectors.user);
  const [isCreatingStep, setIsCreatingStep] = useState(false);
  
  // TODO: using location here is kind of a hack.
  // There's a case where we add step through drop a pin, which involve low level creation
  // of the step via lib components. These should not involve mm terminology like ghostStep,
  // which we rely on here.
  // We should refactor the lib component to get PopoverComponents with custom actions like
  // setting ghostStep.
  const lat = ghostStep?.position?.lat || get(ghostStep, 'position[0]') || location.lat;
  const lon = ghostStep?.position?.lon || ghostStep?.position?.lng || get(ghostStep, 'position[1]') || location.lon;
  const title = ghostStep?.title || location.title || place.title;
  const address = ghostStep?.address || location.address || place?.secondary_title;
  
  useEffect(() => {
    if (!ghostStep) {
      onError();
      return;
    }
    
    if (ghostStep?.image) {
      const filesToLoadObj = { 0: ghostStep.image };
      onLoadFiles(filesToLoadObj);
    }
    if (ghostStep?.googlePlaceImages) {
      const filesToGenerate = ghostStep.googlePlaceImages.map(image => adaptGooglePhotosToGenereateFileStructure(image));
      setFiles(filesToGenerate);
    }
  }, []);
  
  const adaptGooglePhotosToGenereateFileStructure = image => ({
    attachment: { image_id: image.image_id },
    hideDeleteIcon: true,
    addImageToStep: false
  });
  
  const onLoadFiles = async newFiles => {
    setImageLoader(true);
    const images = [];
    await Promise.all(
        Object.values(newFiles).map(
            file =>
                new Promise((resolve, reject) =>
                    getUploadImageInfo()
                        .then(info =>
                            uploadImageToS3({
                              uploadUrl: info.url,
                              file,
                              onUploadedFile: () => {
                                mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_STEP_ADD_CONTENT_PHOTO_UPLOADED, {
                                  [mixpanelProperties.FLOW]: fromWhere,
                                });
                                images.push({ file, imageId: info['image_id'], addImageToStep: true });
                                resolve();
                              },
                            })
                        )
                        .catch(reject)
                )
        )
    );
    setImageLoader(false);
    setFiles([...images, ...files]);
  };
  
  const removeFile = idx => {
    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_STEP_ADD_CONTENT_PHOTO_REMOVED, { [mixpanelProperties.FLOW]: fromWhere });
    setFiles([...files.slice(0, idx), ...files.slice(idx + 1)]);
  };
  
  const generateNewImagesUrl = () =>
      files.map(image => ({
        src: getImageSrcFromFile({
          image,
          isGenerateInFixedSize: true,
          width: IMAGE_CAROUSEL_WIDTH,
          height: IMAGE_CAROUSEL_HEIGHT,
          reason: 'map_manager_AddPost',
        }),
        addDeleteIcon: !image.hideDeleteIcon,
      }));
  
  const generatedSizedImages = useMemo(generateNewImagesUrl, [files]);
  
  const addStep = () => {
    setIsCreatingStep(true);
    const filteredImages = files.filter(image => image.addImageToStep);
    dispatch(
        mapActions.createStepPost(mapId, {
          lat,
          lon,
          title,
          text,
          attachments: filteredImages.map(image => ({
            type: 'IMAGE',
            image_id: image.imageId,
          })),
          locationId: ghostStep.type === 'location' ? ghostStep._id : null,
          placeId: ghostStep.type === 'place' ? ghostStep._id : null,
        })
    ).then(({ data, error }) => {
      mixpanel.track(mixpanelEvents.MM_STEP_CREATION_STEP_ADDED, {
        [mixpanelProperties.MAP_ID]: mapId,
        [mixpanelProperties.MAP_NAME]: mapName,
        [mixpanelProperties.TEXT]: text,
        [mixpanelProperties.FLOW]: fromWhere,
      });
      for (const textObj of [
        {
          text,
          text_type: 'text',
        },
      ]) {
        if (textObj.text_type !== 'text')
          mixpanel.track(mixpanelEvents.ADD_STEP_TAGGED, {
            [mixpanelProperties.TAG_TYPE]: textObj.text_type,
            [mixpanelProperties.TAG]: textObj.text,
            [mixpanelProperties.TAG_ID]: textObj.user_id || textObj.place_id || textObj.step_id,
            [mixpanelProperties.STEP_NAME]: title,
            [mixpanelProperties.STEP_ID]: data?.post?.step_id,
          });
      }
      setIsCreatingStep(false);
      if (data) onStepAdded(data.step);
    });
  };
  
  const onImageClick = useCallback(
      index => {
        dispatch(
            modalsActions.toggleImagePreview({
              imagePreviewShow: true,
              imagePreviewArray: generatedSizedImages,
              imagePreviewIndex: index,
            })
        );
      },
      [generatedSizedImages]
  );
  
  if (!ghostStep) {
    return null;
  }
  
  return (
      <div className={styles.container}>
        <DragAndDrop onDrop={files => onLoadFiles(files)}>
          <div className={styles.header}>
                    <span className={styles.icon}>
                        <BackArrowButton onClick={onBack}/>
                    </span>
            <span className={styles.title}>{copies.title}</span>
          </div>
          <div className={styles.location}>
            <span className={styles.locationTitle}>{title}</span>
            <span className={styles.locationAddress}>{address}</span>
          </div>
          <div className={styles.text}>
            <div className={styles.user}>
              <CurrentUserAvatar size={AvatarSizesNames.MEDIUM}/>
              <span className={styles.userName}>{user.name}</span>
            </div>
            <textarea
                onChange={e => setText(e.target.value)}
                value={text}
                className={styles.textarea}
                autoFocus
                name=''
                id=''
                cols='30'
                placeholder={copies.placeholder}
            />
          </div>
          <div className={styles.images}>
            {imageLoading ? (
                <ImageLoader className={styles.loadImage}/>
            ) : (
                <ImageCarousel
                    classNameDiv={styles.imgObj}
                    classNameImage={styles.image}
                    settings={{
                      slidesToShow: 1.3,
                      slidesToScroll: 1,
                    }}
                    images={generatedSizedImages}
                    addDeleteIcon
                    onDeleteClick={removeFile}
                    onImageClick={onImageClick}
                />
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.uploadPhoto}>
              <DropZone
                  addOne='post'
                  text={copies.add_image}
                  onLoadFiles={files => onLoadFiles(files, 'upload')}
                  onClick={() => {
                    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_STEP_ADD_CONTENT_UPLOAD_PHOTO_CLICKED, {
                      [mixpanelProperties.FLOW]: fromWhere,
                    });
                  }}
              />
            </div>
            <CustomButton
                className={styles.addStepButton}
                type={ButtonType.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={addStep}
                loader={isCreatingStep}
                disabled={imageLoading}
            >
              {copies.add_step_button}
            </CustomButton>
          </div>
        </DragAndDrop>
      </div>
  );
};

export default AddStepForm;
