import React, { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import Tag from '../Tag';
import styles from '../index.module.scss';
import NewTagCategoryInput from '../../TagsAndRatings/NewTagToCategoryInput';

const Tags = ({ category, selectedCategoryTags, selectedTags, idx, handleSelectClick, handleSelectTagClick, addTagInput = false, step }) => {
    const [opened, setOpen] = useState(false);
    const [hiddenCount, setHiddenCount] = useState(null);
    const containerRef = useRef(null);
    const { tags, category: categoryTitle, category_id: categoryId } = category;
    const renderTag = (tag, idx) => {
        return (
            <Tag
                fromWhere={'filter_list'}
                id={tag._id}
                handleSelectTagClick={handleSelectTagClick}
                onClick={handleSelectClick}
                checked={selectedTags.some(item => item._id === tag._id)}
                idx={idx}
                tag={tag}
            />
        );
    };

    const button = hiddenCount && (
        <span onClick={() => setOpen(s => !s)} className={classNames(styles.show, { [styles.showed]: opened })}>
            {opened ? 'Show Less' : `Show ${hiddenCount} more`}
        </span>
    );

    useEffect(() => {
        if (containerRef.current) {
            const { y, height } = containerRef.current.getBoundingClientRect();
            for (let i = 0; i < containerRef.current.children.length; i += 1) {
                const child = containerRef.current.children[i];
                if (y + height <= child.getBoundingClientRect().y) {
                    setHiddenCount(tags.length - i);
                    break;
                }
            }
        }
    }, [tags]);

    return (
        <Fragment key={idx}>
            <p className={classNames(styles.title, { [styles.marginTop]: idx === 0 })}>
                <span className={styles.text}>
                    {categoryTitle}
                    {selectedCategoryTags > 0 && <span className={styles.selectedCategoryTags}>({selectedCategoryTags})</span>}
                </span>
            </p>
            <Grid item id={categoryId ? categoryId : idx} ref={containerRef} className={styles.tagsBlock} container>
                {tags.map(renderTag)}
                {addTagInput && <NewTagCategoryInput category={category} step={step}/>}
            </Grid>
        </Fragment>
    );
};

export default Tags;
