import styles from './index.module.scss';
import Tag from '../Tag';
import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { mapActions, mapSelectors } from '../../redux/map';
import { useSelector, useDispatch } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import classNames from 'classnames';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { Flipper, Flipped } from 'react-flip-toolkit';
import MoreButton from '../common/buttons/MoreButton';
import ModalsDelete from '../common/modals/ModalsDelete';
import ModalsRename from '../common/modals/ModalsRename';
import copiesPrefix from '../../copies.json';
import { removeWhitespace } from '../../helpers';
import { NewTagInput } from './components/NewTagInput';

const copies = copiesPrefix.tags.tagTemplates.categories;

const TagCategory = ({ categories = [], tags = [], isFiltered, mapId }) => {
    const [deleteCategoryPopUp, setDeleteCategoryPopUp] = useState(false);
    const [editValue, setEditValue] = useState(null);
    const [editCategoryPopUp, setEditCategoryPopUp] = useState(false);
    const [value, setValue] = useState(null);
    const user = useSelector(state => state.user);

    const steps = useSelector(state => state[mapId]?.steps);

    const [isExist, setIsExist] = useState(false);
    const [addNewTagObj, setAddNewTagObj] = useState({ categoryName: false });
    const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState({
        categoryId: null,
    });
    const [disable, setDisable] = useState(true);
    const [errorDuplicate, setErrorDuplicate] = useState(false);
    const dispatch = useDispatch();
    const inputRefTag = useRef();
    const obj_tags = useSelector(mapSelectors.mapTags(mapId));

    const handleEditCategoryById = (categoryName, categoryId) => {
        dispatch(mapActions.editCategoryName(mapId, categoryId, removeWhitespace(categoryName)));
    };

    const handleDeleteCategoryById = categoryId => {
        if (categoryId) {
            dispatch(mapActions.deleteCategories(mapId, [categoryId]));
        }
    };

    const handleIsExist = (itemName, array, item = false) => {
        let ans;
        // If the user also sent an item (a full object)
        if (item) {
            ans = array.filter(Boolean).find(a => (itemName !== '' ? a.name.toLowerCase() === itemName.toLowerCase() && item._id !== a._id : false));
        } else {
            ans = array.filter(Boolean).find(a => (itemName !== '' ? a.name.toLowerCase() === itemName.toLowerCase() : false));
        }
        if (ans) {
            setIsExist(true);
            return true;
        } else {
            setIsExist(false);
            return false;
        }
    };

    const handleUnsavedTag = () => {
        setAddNewTagObj({ categoryName: false });
        mixpanel.track(mixpanelEvents.ADD_TAG, {
            [mixpanelProperties.ADD_TO]: 'map',
            [mixpanelProperties.FROM_WHERE]: 'step components',
            [mixpanelProperties.HOW]: 'category_button',
        });
    };

    const handleNewTagOnChange = tagName => {
        let isTagExist;
        const isEmpty = removeWhitespace(inputRefTag.current?.value);

        if (isEmpty !== '') {
            isTagExist = handleIsExist(tagName, tags);
            isTagExist = tags.find(t => {
                return tagName !== '' ? removeWhitespace(t.name).toLowerCase() === tagName.toLowerCase() : false;
            });
        }
        if (!isTagExist) {
            setErrorDuplicate(false);
            setDisable(true);
            if (tagName !== '') {
                setDisable(false);
            } else {
                setDisable(true);
            }
        } else {
            setErrorDuplicate(true);
            setDisable(true);
        }
    };

    const handleAddNewTagButton = categoryName => {
        if (categoryName === addNewTagObj.categoryName) {
            setAddNewTagObj({ categoryName: false });
        } else {
            setAddNewTagObj({ categoryName });
        }
    };

    const handleSubmitNewTagToCategory = (tagName, category, priority) => {
        if (tagName === '') {
            return handleUnsavedTag();
        }
        const newTag = {
            category_priority: priority,
            name: tagName,
            category_id: category._id,
            category: category.name,
        };
        dispatch(mapActions.addNewTagToMap(mapId, { tags: [newTag] }));
        mixpanel.track(mixpanelEvents.ADD_TAG, {
            [mixpanelProperties.ADD_TO]: 'map',
            [mixpanelProperties.FROM_WHERE]: 'step_components',
            [mixpanelProperties.HOW]: 'category_button',
            [mixpanelProperties.IS_STEP_CREATED_BY_SAME_USER]: user.name,
            [mixpanelProperties.IS_FIRST_ON_MAP]: obj_tags.length > 0 ? false : true,
        });
        setAddNewTagObj({ categoryName: false });
        setDisable(true);
    };

    const handleCategoryMenuClick = (categoryId, index, event) => {
        if (categoryId === isCategoryMenuOpen.categoryId) {
            setIsCategoryMenuOpen({ categoryId: false });
        } else {
            setIsCategoryMenuOpen({ categoryId });
        }
    };

    const handleClose = category => {
        if (isCategoryMenuOpen.categoryId === category._id) {
            setIsCategoryMenuOpen({ categoryId: null });
        }
    };

    const handleMoveCategoryById = async (direction, category, index) => {
        if (direction === 'up' && index !== 0) {
            const pUp2 = category.priority + 1;
            await dispatch(mapActions.editCategoryPriority(mapId, category._id, pUp2)).then(async () => {
                // Get all ccategories sorted by priority from the BackEnd
                await dispatch(mapActions.fetchSingleMap(mapId));
            });
        } else if (direction === 'down' && index !== categories.length - 1) {
            const pUp2 = category.priority - 1;
            await dispatch(mapActions.editCategoryPriority(mapId, category._id, pUp2)).then(async () => {
                // Get all ccategories sorted by priority from the BackEnd
                await dispatch(mapActions.fetchSingleMap(mapId));
            });
        }

        setIsCategoryMenuOpen({ categoryId: false });
    };

    const handleTagSubmittion = (value, category) => {
        if (value.length > 0 && value.trim().length !== 0 && !disable) {
            handleSubmitNewTagToCategory(value, category);
        } else {
            setAddNewTagObj({ categoryName: false });
            setDisable(true);
        }
    };

    return (
        <>
            <Flipper flipKey={categories}>
                {categories.map((category, index) => {
                    const categoryTitle = category.name.charAt(0).toUpperCase() + category.name.slice(1);
                    let tagsPerCategory = [];
                    tags.forEach(tag => {
                        if (tag.category_id === category._id) {
                            tagsPerCategory.push(tag);
                        }
                    });
                    return (
                        category._id &&
                        ((isFiltered && tagsPerCategory.length > 0) || !isFiltered) && (
                            <Flipped flipId={category._id} key={category._id}>
                                <div className={styles.category} key={category._id}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        <div className={styles.categoryTitle}>{categoryTitle}</div>
                                        <div
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: '0',
                                            }}
                                        >
                                            <ClickAwayListener onClickAway={() => handleClose(category, categories.length)}>
                                                <div className={styles.dropDownAction} onClick={e => handleCategoryMenuClick(category._id, index, e)}>
                                                    <MoreButton opened={isCategoryMenuOpen.categoryId === category._id} />
                                                    {isCategoryMenuOpen.categoryId === category._id && (
                                                        <div className={styles.actionBlockForCategory}>
                                                            <span
                                                                onClick={() => {
                                                                    setValue(category);
                                                                    setIsCategoryMenuOpen({
                                                                        categoryId: false,
                                                                    });
                                                                    setEditCategoryPopUp(true);
                                                                    setEditValue(category);
                                                                }}
                                                                className={classNames(styles.textAction)}
                                                            >
                                                                {copies.rename}
                                                            </span>
                                                            {categories.length > 1 && index !== 0 && (
                                                                <span
                                                                    onClick={async () => {
                                                                        setIsCategoryMenuOpen({
                                                                            categoryId: false,
                                                                        });
                                                                        await handleMoveCategoryById('up', category, index);
                                                                    }}
                                                                    className={classNames(styles.textAction)}
                                                                >
                                                                    {copies.move_up}
                                                                </span>
                                                            )}
                                                            {categories.length > 1 && index !== categories.length - 1 && (
                                                                <span
                                                                    onClick={async () => {
                                                                        setIsCategoryMenuOpen({
                                                                            categoryId: false,
                                                                        });
                                                                        await handleMoveCategoryById('down', category, index);
                                                                    }}
                                                                    className={classNames(styles.textAction)}
                                                                >
                                                                    {copies.move_down}
                                                                </span>
                                                            )}
                                                            <span
                                                                onClick={() => {
                                                                    setIsCategoryMenuOpen({
                                                                        categoryId: false,
                                                                    });
                                                                    setDeleteCategoryPopUp(true);
                                                                    setEditValue(category);
                                                                }}
                                                                className={classNames(styles.textAction, styles.textActionDelete)}
                                                            >
                                                                {copies.delete}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </ClickAwayListener>
                                        </div>
                                    </div>
                                    <div className={styles.categoryTags}>
                                        {tagsPerCategory.map(tag => {
                                            let counter = 0;
                                            Object.entries(steps).forEach(([key, step]) => {
                                                step.selected_tags_ids?.map(t => {
                                                    if (t == tag._id) {
                                                        counter++;
                                                    }
                                                });
                                            });
                                            return (
                                                <Tag
                                                    key={tag?._id}
                                                    tag={tag}
                                                    tags={tagsPerCategory}
                                                    categories={categories}
                                                    category={category}
                                                    counter={counter}
                                                />
                                            );
                                        })}
                                        {addNewTagObj.categoryName === categoryTitle && (
                                            <NewTagInput
                                                isDisabled={disable}
                                                isValueDuplicate={errorDuplicate}
                                                onClickAway={value => handleTagSubmittion(value, category)}
                                                onSubmit={value => handleTagSubmittion(value, category)}
                                                onChange={handleNewTagOnChange}
                                                onKeyPress={(value, keyCode) => {
                                                    if (disable || keyCode !== 13 || value.length === 0) {
                                                        return;
                                                    }
                                                    handleSubmitNewTagToCategory(value, category);
                                                }}
                                                onCancel={() => {
                                                    handleUnsavedTag();
                                                    setDisable(true);
                                                }}
                                            />
                                        )}
                                        <button onClick={() => handleAddNewTagButton(categoryTitle)} className={styles.addNewTagButton}>
                                            <FormattedMessage id='tags.tagTemplates.categories.add_tag' />
                                        </button>
                                    </div>
                                </div>
                            </Flipped>
                        )
                    );
                })}
            </Flipper>
            {editCategoryPopUp && (
                <ModalsRename
                    isExist={isExist}
                    content={editValue}
                    placeholder={'Category name'}
                    oldName={value.name}
                    textFirst={'Rename Category'}
                    textSecond={
                        <>
                            <span className={styles.modalRenameText}>{copies.rename_category}</span> <span className={styles.bold}>{value.name}</span>?
                        </>
                    }
                    handleOnChange={value => {
                        handleIsExist(value, categories, value);
                        setEditValue(editValue => ({
                            ...editValue,
                            name: value,
                        }));
                    }}
                    onCancel={() => {
                        setIsExist(false);
                        setEditCategoryPopUp(false);
                    }}
                    onRename={() => {
                        handleEditCategoryById(editValue.name, editValue._id);
                        setEditCategoryPopUp(false);
                        setIsExist(false);
                    }}
                />
            )}
            {deleteCategoryPopUp && (
                <ModalsDelete
                    textFirst={`${copies.deleteCategory.title} <b>"${editValue.name}"</b> ${copies.deleteCategory.title_category}`}
                    textSecond={copies.deleteCategory.description}
                    onCancel={() => setDeleteCategoryPopUp(false)}
                    onDecline={() => {
                        handleDeleteCategoryById(editValue._id);
                        setDeleteCategoryPopUp(false);
                    }}
                />
            )}
        </>
    );
};

export default TagCategory;
