import { success, error } from '@redux-requests/core';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { arrToObj } from '../../helpers';
import { StepTypes } from '../../constants';
import {
    GET_STEP_BY_ID,
    LIKE_STEP,
    EDIT_STEP_RATING,
    EDIT_STEP_LOCATION,
    RESTORE_STEP,
    ADD_NEW_TAG_TO_STEP,
    ADD_TAG_TO_STEP,
    CHANGE_STEP_TYPE,
    DELETE_TAG_FROM_STEP,
    GET_FULL_STEP_DATA,
    GET_POSTS,
    CREATE_POST,
    UPDATE_STEP,
    DELETE_IMAGE,
} from './types';
import { DELETE_POST } from '../posts/types';

const reducer = (state, action, baseState) => {
    switch (action.type) {
        case GET_FULL_STEP_DATA: {
            return {
                ...state,
                isStepPending: true,
            };
        }
        case error(GET_FULL_STEP_DATA): {
            return {
                ...state,
                isStepPending: false,
            };
        }

        case success(GET_STEP_BY_ID):
        case success(GET_FULL_STEP_DATA):
            return {
                ...state,
                ...action.response.data.step,
                isShortData: false,
                isStepPending: false,
                postsList: {
                    postsListIds: action.response.data.posts_list.posts.map(post => post._id),
                    cursor: action.response.data.posts_list.cursor,
                    total: action.response.data.posts_list.total_results,
                },
            };
        case success(UPDATE_STEP): {
            return {
                ...state,
                thumbnail_image_id: action.response.data.step.thumbnail_image_id,
                hpaw: {
                    ...action.response.data.step.hpaw,
                },
            };
        }
        case success(GET_POSTS):
            return {
                ...state,
                postsList: {
                    postsListIds: [...state.postsList.postsListIds, ...action.response.data.posts.map(post => post._id)],
                    cursor: action.response.data.cursor,
                    total: action.response.data.total_results,
                },
            };

        case DELETE_POST: {
            const { postId: postToRemoveId } = action.meta;
            // deleted from feed
            if (!state.postsList) return state;
            const filteredStepProfilePosts = state.postsList.postsListIds.filter(postId => postId !== postToRemoveId);

            return {
                ...state,
                postsList: {
                    ...state.postsList,
                    postsListIds: filteredStepProfilePosts,
                    total: filteredStepProfilePosts.length,
                },
            };
        }
        case DELETE_IMAGE: {
            const { imageId } = action.meta;
            const attachments = { ...state.posts_attachments_list };
            attachments.posts_attachments = attachments.posts_attachments.filter(attachemnt => attachemnt.attachment.id != imageId);
            attachments.total_results -= 1;
            return { ...state, posts_attachments_list: attachments };
        }
        case LIKE_STEP: {
            const { stepId, isLiked, likesCount } = action.meta;
            const newLikesCount = (likesCount || state.likes_count || 0) + (isLiked ? 1 : -1);
            return {
                ...state,
                is_liked_by_user: isLiked,
                likes_count: newLikesCount,
            };
        }

        case success(EDIT_STEP_RATING): {
            const subcomponents = action.response.data.subcomponents;
            const newRatingComponent = action.meta.newRatingComponent;
            const ratings = arrToObj(state?.components || [], 'type').rating?.rating_subcomponents;
            const ratingsComponent = arrToObj(state?.components || [], 'type').rating;
            const noRated = ratings.filter(item => item?.type === 'score1to5').some(item => item?.self && item?.self === '0');
            const newAmount = ratingsComponent.amount === 0 ? ratingsComponent.amount + 1 : noRated ? ratingsComponent.amount + 1 : ratingsComponent.amount;

            subcomponents.map(component => {
                const scoreRating = ratings?.filter(item => item?.type === 'score1to5');
                const rating = scoreRating?.find(item => item.id === component.rating_subcomponent_id);
                const test = newRatingComponent.subcomponents.find(item => item.rating_subcomponent_id === component.rating_subcomponent_id);
                let self;
                if (typeof self === 'string') {
                    self = 0;
                } else {
                    self = rating?.self;
                }
                const newAverage = (rating.average * rating.count - self + test.value) / (self > 0 ? rating.count : rating.count + 1);
                rating.self = test.value;
                rating.average = rating.count === 0 ? test.value : newAverage;
                rating.count = rating.count === 0 ? 1 : self > 0 ? rating.count : rating.count + 1;
            });
            ratingsComponent.rating_subcomponents = ratings;
            ratingsComponent.rating_subcomponents_preview = ratings;
            ratingsComponent.amount = newAmount;
            return {
                ...state,
            };
        }

        case success(DELETE_TAG_FROM_STEP): {
            const afterAddPost = action.meta.afterAddPost;
            const components = state.components;
            const newTags = action.meta.tags || [];
            const newStatus = action.meta.requestAction.request.data.status;
            if (components) {
                const tagsComponent = components.find(component => component.id === 'selected_tags');
                const setStepTag = (tag, status) => {
                    if (tag) {
                        // eslint-disable-next-line no-param-reassign
                        tag.is_selected = status;
                    }
                    return tag;
                };
                let stepTagsPreview = null;
                let selectedTags = null;
                newTags.forEach(newTag => {
                    const stepTags = tagsComponent.tags.find(item => item._id === newTag);
                    stepTagsPreview = tagsComponent.tags_preview.filter(item => item._id !== newTag);
                    selectedTags = state.selected_tags_ids.filter(item => item._id !== newTag);
                    tagsComponent.tags_preview = stepTagsPreview;
                    return setStepTag(stepTags, newStatus);
                });
                if (!afterAddPost) {
                    tagsComponent.tags_preview = stepTagsPreview;
                }
                state.selected_tags_ids = selectedTags;
            }
            return {
                ...state,
                components: components,
            };
        }

        case success(ADD_TAG_TO_STEP): {
            const components = state?.components;
            const newTagsIds = action.meta.tagsIds || [];
            const newTags = action.meta.tags || [];
            const newStatus = action.meta.requestAction.request.data.status;
            const tagObjects = baseState.tag_objects;
            if (components) {
                const tagsComponent = components.find(component => component.type === 'selected_tags');
                const setStepTag = (tag, status) => {
                    if (tag) {
                        // eslint-disable-next-line no-param-reassign
                        tag.is_selected = status;
                    }
                    return tag;
                };

                newTagsIds.forEach(newTag => {
                    const updatedStepTags = baseState.tag_objects.find(tag => tag._id === newTag); // this var contains tags which just added to the map
                    let stepTags;
                    let tagIsExist = false;
                    if (newTags.length > 0) {
                        const updatedTags = tagsComponent.tags.concat(newTags);
                        stepTags = updatedTags.find(item => item._id === newTag);
                    } else {
                        stepTags = tagsComponent.tags.find(item => item._id === newTag);
                    }
                    // Update components['selected_tags'].tags:
                    tagsComponent.tags.forEach(tagObject => {
                        if (tagObject._id === newTag) {
                            tagObject.is_selected = true;
                            tagIsExist = true;
                        }
                    });
                    // addTagTo components['selected_tags'].tags if not already exists.
                    if (!tagIsExist) {
                        const tagObjectIndex = tagObjects.findIndex(tag => tag._id === newTag);
                        tagObjectIndex > -1 &&
                        tagsComponent.tags.push({
                            ...tagObjects[tagObjectIndex],
                            is_selected: true,
                        });
                    }
                    tagsComponent.tags_preview.push(updatedStepTags);
                    state?.selected_tags_ids?.push(updatedStepTags?._id);
                    return setStepTag(stepTags, newStatus);
                });
            }
            return {
                ...state,
                components: components,
                selected_tags_ids: newTags,
            };
        }

        case success(EDIT_STEP_LOCATION):
            return {
                ...state,
                title: action.response.data.step.title,
                lat: action.response.data.step.lat,
                lon: action.response.data.step.lon,
            };
        case RESTORE_STEP:
            return {
                ...state,
                archived: false,
            };
        case CHANGE_STEP_TYPE: {
            const { stepType } = action.meta;
            let indications = state.indications;
            const stepTypeAlreadyExist = indications.some(indication => indication.type === stepType);
            if (stepType === StepTypes.REGULAR || stepTypeAlreadyExist) {
                indications = indications.filter(indication => indication.type !== stepType);
            } else {
                indications = [{ type: stepType }, ...indications];
            }
            return {
                ...state,
                indications,
            };
        }

        case success(ADD_NEW_TAG_TO_STEP): {
            const oldTags = arrToObj(state.components || [], 'type').selected_tags?.tags;
            let newTags = action.response.data.tags;
            const setStepTag = (tag, status) => {
                if (tag) {
                    // eslint-disable-next-line no-param-reassign
                    tag.is_selected = status;
                    tag.category = action.meta.categoryName;
                }
                return tag;
            };
            newTags.forEach(newTag => {
                return setStepTag(newTag, true);
            });
            oldTags.push(...newTags);
            return {
                ...state,
            };
        }
        case success(CREATE_POST): {
            const newAttachmentsState = action.response.data.post.attachments;
            const oldAttachments = state.posts_attachments_list?.posts_attachments || [];
            return {
                ...state,
                posts_attachments_list: {
                    ...state.posts_attachments_list,
                    posts_attachments: [...oldAttachments, ...newAttachmentsState],
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
