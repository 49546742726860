import React from 'react';
import styles from './index.module.scss';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import loaderAnimation from '../../../lottie/loadingImage.json';

const ImageLoader = ({ className }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };
    return (
        <div className={classNames(styles.loaderContent, className)}>
            <div className={styles.loader}>
                <Lottie isClickToPauseDisabled={true} options={defaultOptions} isStopped={false} isPaused={false} width={20} height={20} />
            </div>
        </div>
    );
};

export default ImageLoader;
