import React from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import mixpanel, { mixpanelEvents } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';

const copies = copiesPrefix.tags;

const NoTags = ({ onClickTagTemplates }) => {
    return (
        <div>
            <div className={styles.noTagsTitle}>{copies.no_tags}</div>
            <div className={styles.noTagsSubTitle}>{copies.no_tags_description}</div>
            <CustomButton
                className={styles.button}
                onClick={() => {
                    onClickTagTemplates();
                    mixpanel.track(mixpanelEvents.TAGS_TEMPLATES_SCREEN_SHOW);
                }}
                size={'mediumSize'}
                type={ButtonType.PRIMARY_BLACK}
                text={
                    <>
                        <img className={styles.templateImg} alt='' src='/assets/img/tagTemplatesWhite.svg' />
                        {copies.choose_from_template}
                    </>
                }
            />
        </div>
    );
};

export default NoTags;
