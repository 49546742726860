import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { PaginationSliderContext } from '../index';

export const directions = {
    NEXT: 'right',
    PREV: 'left',
};

const ArrowButton = ({ direction = directions.NEXT, onClick, className, size = 24, style }) => {
    const { prevRef, nextRef } = useContext(PaginationSliderContext);

    return (
        <div style={style} className={classNames(styles.block, className)} onClick={onClick} ref={direction === directions.NEXT ? nextRef : prevRef}>
            {direction === directions.NEXT ? <ArrowRight size={size} color='#212B37' /> : <ArrowLeft size={size} color='#212B37' />}
        </div>
    );
};

export default ArrowButton;
