import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const Tag = ({ tag, idx, checked, handleSelectTag }) => {
    return (
        <div key={idx} className={styles.tag}>
            <span
                onClick={() => handleSelectTag(tag)}
                className={classNames(styles.titleTag, {
                    [styles.tagSelected]: checked,
                })}
            >
                {tag.name}
            </span>
        </div>
    );
};

export default Tag;
