import { useEffect, useMemo, useState } from 'react';
import CustomButton from '../../components/common/buttons/CustomButton';
import { capitalize, noop } from 'lodash';
import useToast from '../../hooks/useToast';
import classnames from 'classnames';
import { generateImageUrl } from '../../helpers';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import config from '../../config';

const createAskAroundPrompt = async ({ question, userId, mapId, firstName, lastName, imageUrl }) => {
  const url = `${config.domain.address}/atly/autls/ask-around/create-prompt`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      asking_user_id: userId,
      target_map_id: mapId,
      first_name: firstName,
      last_name: lastName,
      image_url: imageUrl,
      prompt: question,
    }),
    headers: {
      app_platform: 'map_manager',
      app_version: config.version.path,
    },
  });
  
  const results = await response.json();
  return results.ask_around_id;
};

const MAX_LENGTH = 120;

export const AskAroundQuestion = ({
  userImageId,
  userId,
  firstName,
  lastName,
  mapId,
  mapName,
  onSuccess = noop,
  isSubmitted = false,
}) => {
  const { setErrorToast } = useToast();
  const [hasError, setHasError] = useState(false);
  const [inputLengthError, setInputLengthError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [askAroundInput, setAskAroundInput] = useState('');
  const [inputLength, setInputLength] = useState(0);
  const fullName = useMemo(() => `${capitalize(firstName)} ${capitalize(lastName)}`, [firstName, lastName]);
  const imageSrc = useMemo(() => {
    return generateImageUrl({
      imageId: userImageId,
      width: 32,
      height: 32,
      reason: 'ask-around',
    });
  }, [userImageId]);
  
  useEffect(() => {
    // auto resize textarea height when typing
    const tx = document.getElementsByTagName('textarea');
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
      tx[i].addEventListener('input', OnInput, false);
    }
    
    function OnInput() {
      this.style.height = 0;
      this.style.height = (this.scrollHeight) + 'px';
    }
  }, []);
  
  if (!fullName || !imageSrc) {
    return null;
  }
  
  return (
    <section className='bg-white rounded-lg shadow p-3 flex flex-col justify-between'>
      <div className='flex items-center gap-2 mb-2'>
        <img
          src={imageSrc}
          alt={fullName}
          className='rounded-full w-8 bg-gray-300 h-8'/>
        <div className='font-bold text-sm text-blue-900'>{fullName}</div>
      </div>
      <div
        id='ask-around-input'
        className='max-h-60 overflow-y-auto'>
        <textarea
          autoFocus
          value={askAroundInput}
          className={classnames('p-0 resize-none border-none bg-white align-left w-full box-border text-xl placeholder:font-medium placeholder:text-gray-400 outline outline-none font-medium text-blue-900', {
            'pointer-events-none': isSubmitted,
          })}
          placeholder='Send me your best coffee spots in NYC...'
          onChange={(event) => {
            setAskAroundInput(event.target.value);
            setInputLength(event.target.value.length);
            setInputLengthError(event.target.value.length > MAX_LENGTH);
            setHasError(false);
          }}/>
      </div>
      <div className='text-xs text-slate-400 w-full flex justify-between mb-4'>
        <span className='text-rose-500 text-[13px] font-medium leading-tight tracking-tight'>
          {inputLengthError && 'Too many characters.'}
        </span>
        <span className='text-[11px] font-semibold text-slate-400 leading-none tracking-tight'>
          {inputLength}/{MAX_LENGTH}
        </span>
      </div>
      {hasError &&
        <div
          className='text-red-500 w-full text-left my-2.5 p-0 text-sm tracking-wide'>
          Please type a question
        </div>}
      {!isSubmitted &&
        <div className='flex justify-center'>
          <CustomButton
            loader={isLoading}
            withLoader={isLoading}
            disabled={isLoading || inputLengthError}
            spinnerType='black'
            type={CustomButton.Types.ATLY_PRIMARY}
            size={CustomButton.Sizes.FULL}
            onClick={async () => {
              if (!askAroundInput) {
                setHasError(true);
                return;
              }
              
              if (isLoading || inputLengthError) {
                return;
              }
              
              setIsLoading(true);
              
              try {
                mixpanel.track(mixpanelEvents.ASK_AROUND_CLICKED, {
                  [mixpanelProperties.CHARACTER_COUNT]: askAroundInput.length,
                });
                
                const askAroundId = await createAskAroundPrompt({
                  mapId,
                  question: askAroundInput,
                  userId,
                  firstName,
                  lastName,
                  imageUrl: imageSrc,
                });
                
                const link = `${config.domain.web}/aa/${askAroundId}`;
                onSuccess(link);
              } catch (error) {
                console.error(error);
                setErrorToast({
                  id: 'Something went wrong. Please try again.',
                  color: 'black'
                });
                setIsLoading(false);
              }
            }}>
            Ask around
          </CustomButton>
        </div>}
    </section>
  );
};