import React from 'react';
import ForgotPassword from '../../containers/ForgotPassword';
import Register from '../../containers/Register';
import Login from '../../containers/Login';
import styles from './index.module.scss';
import { Route, Routes } from 'react-router-dom';
import ResetPassword from '../../containers/ResetPassword';
import RequestMessage from '../../components/common/RequestMessage';
import { TokenCheck } from '../../components';

const LoginPage = props => {
    return (
        <div className={styles.loginLayout}>
            <Routes>
                <Route path='forgot-password' element={<ForgotPassword/>}/>
                <Route path='passwordrecovery' element={(
                    <TokenCheck>
                        <ResetPassword/>
                    </TokenCheck>
                )}/>
                <Route path='sign-up' element={<Register/>}/>
                <Route index element={<Login {...props} />}/>
            </Routes>
            <RequestMessage/>
        </div>
    );
};

export default LoginPage;
