import classNames from "classnames";
import styles from "../PlaceInfo.module.scss";
import React from "react";

const DAY_HOURS_REGEX = /(\w+):\s(.+)/;
export const OpeningHours = ({ children }) => {
  return (
      <div>
        {children
            ?.split('\n')
            .filter(day => day.trim() !== '')
            .map((day, index) => {
              try {
                const [, dayName, dayHours] = day.match(DAY_HOURS_REGEX);
                return (
                    <div key={index} className="text-sm leading-tight">
                      <span>{dayName}</span>:{' '}
                      <span className={classNames({
                        [styles.closed]: dayHours.toLowerCase() === 'closed',
                      })}>
                            {dayHours}
                          </span>
                    </div>
                );
              } catch (error) {
                console.warn(error);
                return day;
              }
            })}
      </div>
  )
};