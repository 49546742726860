import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import Onboard from './containers/Onboard';
import ROUTES from './config/routes';
import Loader from './layouts/Loader';
import MapManager from './layouts/MapManager';
import LoginPage from './layouts/LoginPage';
import { refreshToken } from './helpers';
import { userActions, userSelectors } from './redux/user';
import mixpanel, { mixpanelProperties } from './helpers/mixpanel';
import MobileInitialScreen from './containers/MobileInitialScreen';
import StepsList from './containers/StepsManager/components/StepsList';
import StepProfile from './components/StepProfile';
import AddStepFlow from './components/AddStep';
import { PostPage } from './components/PostStructure/PostPage';
import ListView from './containers/StepsManager/components/ListView';
import TagsPage from './containers/TagsPage';
import Insights from './containers/Insights';
import MapStages from './components/MapStages';
import RecentActivities from './containers/RecentActivities';
import StepsManager from './containers/StepsManager';
import FeedBack from './containers/FeedBack';
import { MapSettings } from './containers/MapSettings';
import { Feed } from './components/Feed';
import SelectLocation from './components/SelectLocation';
import CreatePlace from './components/CreatePlace';
import RequireAuth from './HOC/RequireAuth';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import { TokenCheck } from './components';
import config from './config';
import { AskAround } from './containers/AskAround';
import { CanonicalHelmet } from './components/common/Helmet/CanonicalHelmet';

let isWaitToTimer = false;
const isWeb = window.innerWidth > 720 ? true : false;

const App = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const isUserAuth = useSelector(userSelectors.isAuth);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!localStorage.getItem('mimic')) {
            return;
        }

        import('mimic');
    }, []);

    console.log({ search: searchParams.get('search') });
    useEffect(() => {
        if (searchParams.get('search')) {
            localStorage.setItem('pendingSearch', searchParams.get('search'));
        }
    }, []);

    useEffect(() => {
        console.log({ 'config.version.path': config.version.path });
        startTimer(50);
    }, []);

    useEffect(() => {
        if (isUserAuth !== 0) startTimer(60000);
        else isWaitToTimer = false;
    }, [isUserAuth]);

    const startTimer = time => {
        if (isWaitToTimer) return;
        isWaitToTimer = true;
        setTimeout(() => {
            if (isUserAuth !== 0) renewToken();
            else isWaitToTimer = false;
        }, time);
    };

    const renewToken = async () => {
        try {
            const at = localStorage.getItem('at');
            const rt = localStorage.getItem('rt');
            if (!at || !rt || at === 'undefined' || rt === 'undefined' || at.length < 10 || rt.length < 10) {
                isWaitToTimer = false;
                dispatch(userActions.setUserAuth(0));
                return;
            }
            const tokens = await refreshToken({ at, rt });
            localStorage.setItem('at', tokens.accessToken);
            localStorage.setItem('rt', tokens.refreshToken);
            isUserAuth !== 1 && dispatch(userActions.setUserAuth(1));
            const expire = tokens.expiry;
            isWaitToTimer = false;
            startTimer((expire - Number(String(Date.now()).slice(0, 10))) * 900);
        } catch (e) {
            isWaitToTimer = false;
            dispatch(userActions.setUserAuth(0));
        }
    };

    const handleLoader = loaded => {
        setIsLoading(loaded);
    };

    const onLogout = () => {
        mixpanel.reset();
        mixpanel.unregister(mixpanelProperties.MAP_ID);
        mixpanel.unregister(mixpanelProperties.MAP_NAME);
        mixpanel.unregister(mixpanelProperties.USER_ID);
        mixpanel.unregister(mixpanelProperties.USER_NAME);
        dispatch(userActions.setUserAuth(0));
    };

    console.log('app version', config.version.path);

    const LoginRedirect = ({ redirectTo = '' }) => {
        if (isUserAuth === 0) {
            let shouldRedirect = false;
            const url = new URL(window.location.href);
            if (location.host === 'devdashboard.steps.me') {
                url.host = 'devdashboard.atly.com';
                shouldRedirect = true;
            }

            if (location.host === 'mapmanager.steps.me') {
                url.host = 'mapmanager.atly.com';
                shouldRedirect = true;
            }

            if (shouldRedirect) {
                window.location.replace(url.href);
                return null;
            }

            return <LoginPage redirectTo={redirectTo} setIsLoading={handleLoader} isLoading={isLoading} />;
        }

        if (isUserAuth === 1) {
            return <Navigate to={`/${redirectTo}` || '/' + ROUTES.MAP} />;
        }

        return null;
    };

    if (isWeb) {
        return (
            <>
                <CanonicalHelmet/>
                <Routes>
                    {/*<Route*/}
                    {/*    path={ROUTES.AUTH}*/}
                    {/*    element={*/}
                    {/*        <InstagramImport*/}
                    {/*            isImportMode={!!localStorage.getItem('importMode')}*/}
                    {/*            onClickPrev={() => (localStorage.getItem('importMode')*/}
                    {/*                ? navigate('/' + ROUTES.MAP)*/}
                    {/*                : navigate(ROUTES.ONBOARD))}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*/>*/}
                    <Route path={ROUTES.ASK_AROUND} element={<AskAround />} />
                    <Route path={`${ROUTES.LOGIN}/*`} element={<LoginRedirect />} />
                    <Route element={<RequireAuth userAuth={isUserAuth} />}>
                        <Route path={ROUTES.ONBOARD} element={<Onboard onLogout={onLogout} />} />
                        <Route path={ROUTES.DASHBOARD} element={<MapManager setIsLoading={handleLoader} isLoading={isLoading} onLogout={onLogout} />}>
                            <Route path='/' element={<Navigate to={ROUTES.MAP} />} />
                            <Route path={ROUTES.MAP} element={<StepsManager />}>
                                <Route path={ROUTES.STEP_PROFILE} element={<StepProfile />} />
                                <Route path={ROUTES.FEED} element={<Feed />} />
                                <Route path={ROUTES.POST_PAGE} element={<PostPage />} />
                                <Route path={ROUTES.ADD_STEP} element={<AddStepFlow />} />
                                <Route index element={<StepsList />} />
                                <Route path={ROUTES.LOCATION} element={<SelectLocation />} />
                                <Route path={ROUTES.NEW_PLACE} element={<CreatePlace />} />
                                <Route path={ROUTES.MAP_STAGES} element={<MapStages />} />
                            </Route>
                            <Route path={ROUTES.LIST} element={<ListView />} />
                            {/*<Route path={ROUTES.IMPORTED} element={<ImportModule />} />*/}
                            <Route path={ROUTES.TAGS} element={<TagsPage />} />
                            <Route path={ROUTES.INSIGHTS} element={<Insights />} />
                            <Route path={ROUTES.UPDATES} element={<RecentActivities />} />
                            <Route path={ROUTES.MAP_SETTING} element={<MapSettings />} />
                            <Route path={ROUTES.MAP_SETTINGS_GENERAL} element={<MapSettings tabName='general' />} />
                            <Route path={ROUTES.MAP_SETTINGS_RATINGS} element={<MapSettings tabName='ratings' />} />
                            <Route path={ROUTES.MEMBERS} element={<MapSettings tabName='members' />} />
                            <Route path={ROUTES.FEEDBACK} element={<FeedBack />} />
                        </Route>
                        <Route path='/*' element={<Navigate to={`/${ROUTES.MAP}`} />} />
                    </Route>
                </Routes>
                {isLoading && <Loader />}
            </>
        );
    }

    // Mobile
    return (
        <>
            <CanonicalHelmet/>
            {isLoading && <Loader />}
            <Routes>
                <Route path={ROUTES.ASK_AROUND} element={<AskAround />} />
                {/*<Route*/}
                {/*    path={`${ROUTES.LOGIN}/*`}*/}
                {/*    element={<LoginRedirect redirectTo={ROUTES.ASK_AROUND}/>}*/}
                {/*/>*/}
                <Route
                    path={ROUTES.RESET_PASSWORD}
                    element={
                        <TokenCheck>
                            <ResetPassword withLogo />
                        </TokenCheck>
                    }
                />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route exact path={ROUTES.DASHBOARD} element={<MobileInitialScreen />} />
                <Route path={ROUTES.ENTRY} element={<Navigate to={ROUTES.DASHBOARD} replace />} />
            </Routes>
        </>
    );
};

export default App;
