import React from 'react';

const IconX = ({ color }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
        <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2.5 5h15' />
        <path
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            d='M6.666 5V3.333c0-.442.176-.866.488-1.178.313-.313.737-.488 1.179-.488h3.333c.442 0 .866.175 1.178.488.313.312.489.736.489 1.178V5m2.5 0v11.667c0 .442-.176.866-.489 1.178-.312.313-.736.488-1.178.488H5.833c-.442 0-.866-.175-1.179-.488-.312-.312-.488-.736-.488-1.178V5h11.667zM8.334 9.167v5M11.666 9.167v5'
        />
    </svg>
);

export default IconX;
