import React from 'react';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';

import styles from './index.module.scss';

const ExitModal = ({ text, subText, onCancel, onDiscard, tertiaryText, textDanger }) => {
    return (
        <div onClick={onCancel} className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.panel}>
                    <span className={styles.text}>{text}</span>
                    <span className={styles.subText}>{subText}</span>
                    <div className={styles.buttons}>
                        <CustomButton onClick={onCancel} type={ButtonType.SECONDARY_BLACK} text={tertiaryText} size={'mediumSize'} />
                        <CustomButton className={styles.discard} onClick={onDiscard} type={ButtonType.DANGER} text={textDanger} size={'mediumSize'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExitModal;
