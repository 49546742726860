import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';
import { CaretDown } from 'phosphor-react';

const copies = copiesPrefix.mapManager;

const SortSteps = ({ sortedBy, setSorted, isSideListOpen }) => {
    const [opened, setOpen] = useState(false);
    const [hoveredSort, setHoveredSort] = useState(false);

    const handleClickAway = useCallback(() => {
        setOpen(false);
    }, []);

    const handleSort = value => {
        mixpanel.track(mixpanelEvents.MAP_SORT_LIST, {
            [mixpanelProperties.SORT_BY]: value === 'last_edited' ? 'last_edit' : 'popular',
        });
        setSorted(value);
        setOpen(false);
    };

    const handleDropDown = useCallback(() => {
        setOpen(!opened);
    }, [opened]);

    return (
        <div
            className={classNames(styles.block, {
                [styles.fixBlock]: !isSideListOpen,
            })}
        >
            <ClickAwayListener onClickAway={handleClickAway}>
                <div role='presentation' onClick={() => handleDropDown()}>
                    <div
                        onMouseOver={() => {
                            setHoveredSort(true);
                        }}
                        onMouseLeave={() => {
                            setHoveredSort(false);
                        }}
                        role='presentation'
                        className={classNames(styles.sort, { [styles.sortActive]: opened || hoveredSort })}
                    >
                        <span>Sort by: {sortedBy === 'last_edited' ? copies.sort.recent : copies.sort.popular}</span>
                        <CaretDown className={classNames([styles.caret, opened ? styles.rotate : styles.rotate360])} size={12} color='#3a3c3f' />
                    </div>
                    {opened && (
                        <div className={styles.dropDownContent}>
                            <span
                                onClick={() => handleSort('last_edited')}
                                className={classNames(styles.sortText, sortedBy === 'last_edited' && styles.sortedBy)}
                            >
                                {sortedBy === 'last_edited' && <img src='/assets/img/check.svg' alt='' />}
                                {copies.sort.recent}
                            </span>
                            <span
                                onClick={() => handleSort('views_count')}
                                className={classNames(styles.sortText, sortedBy === 'views_count' && styles.sortedBy)}
                            >
                                {sortedBy === 'views_count' && <img src='/assets/img/check.svg' alt='' />}
                                {copies.sort.popular}
                            </span>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default SortSteps;
