import ImagesCarousel from './ImagesCarousel';
import DropZone from '../../components/DropZone';
import DragAndDrop from '../../components/DragAndDrop';

export { default as ImagesCarousel } from './ImagesCarousel';
export { default as Image } from './Image';
export { default as useImageCarousel } from './useImageCarousel';

const Images = {
    Carousel: ImagesCarousel,
    DragAndDrop,
    Button: DropZone,
};

export { Images };
