import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { HeartStraight } from 'phosphor-react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

// TODO: Format numbers with loadash
const Like = ({ isLiked, onClickLike = noop, likesCount, className }) => {
    return (
        <div className={classNames(styles.block, className)}>
            <HeartStraight
                className={styles.heart}
                size={24}
                color={isLiked ? '#FF5F4D' : '#3A3C3F'}
                weight={isLiked ? 'fill' : 'regular'}
                onClick={onClickLike}
            />
            {likesCount > 0 && <span>{likesCount}</span>}
        </div>
    );
};

export default Like;

Like.propTypes = {
    isLiked: PropTypes.bool,
    onCLickLike: PropTypes.func,
    likesCount: PropTypes.number,
};
