import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import spinnerImage from './spinner.png';
import styles from './index.module.scss';

const LightBackgroundButtonTypes = {
  SECONDARY: 'Secondary',
  TERTIARY: 'Tertiary',
  PRIMARY_ON_BLUE: 'PrimaryOnBlue',
  PRIMARY_ON_BLACK: 'PrimaryOnBlack',
  SECONDARY_BLUE: 'SecondaryBlue',
  SECONDARY_BLACK: 'SecondaryBlack',
  SECONDARY_ON_BLUE: 'SecondaryOnBlue',
  SECONDARY_ON_BLACK: 'SecondaryOnBlack',
  TERTIARY_BLUE: 'TertiaryBlue',
  TERTIARY_BLACK: 'TertiaryBlack',
  TERTIARY_ON_BLUE: 'TertiaryOnBlue',
  TERTIARY_ON_BLACK: 'TertiaryOnBlack',
  GHOST: 'Ghost',
  GHOST2: 'Ghost2',
  GHOST_BLACK: 'GhostBlack',
  TRANSPARANT: 'Transparent',
};

const DarkBackgroundButtonTypes = {
  PRIMARY_BLUE: 'PrimaryBlue',
  PRIMARY: 'Primary',
  PRIMARY_BLACK: 'PrimaryBlack',
  DANGER: 'Negative',
  BLACK: 'Black',
};

export const ButtonType = {
  ...LightBackgroundButtonTypes,
  ...DarkBackgroundButtonTypes,
  ATLY_PRIMARY: 'AtlyPrimary',
};

export const ButtonSize = {
  SMALL: 'smallSize',
  MEDIUM: 'mediumSize',
  LARGE: 'largeSize',
  FULL: 'fullSize',
  NARROW: 'narrowSize',
};

const LoadingSpinner = ({ isLoading = false, isLight = true, withLoader }) => {
  if (!isLoading) {
    return null;
  }
  
  if (withLoader) {
    return <img className={styles.loading} alt='' src={spinnerImage}/>;
  }
  
  return <img className={styles.loading} alt='' src={isLight ? '/assets/img/loadingSpinner.svg' : '/assets/img/loadingSpinnerWhite.svg'}/>;
};

const CustomButton = ({
  className,
  withLoader,
  loader,
  type,
  size,
  disabled,
  onClick,
  singleClick,
  clicked,
  text,
  onKeyPress,
  onMouseLeave,
  onMouseOver,
  children,
}) => {
  const [isClicked, setIsClicked] = useState(clicked);
  
  useEffect(() => setIsClicked(clicked), [clicked]);
  
  return (
      <button
          data-testid='custom-button'
          className={classNames([styles.block, styles[`${isClicked ? 'selected' : ''}${type}`], styles[`${size}`], className])}
          disabled={Boolean(disabled)}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          onClick={e => {
            singleClick && setIsClicked(true);
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            onClick && !isClicked && onClick(e);
          }}
          onKeyPress={onKeyPress}
          value={text || children}
      >
        <span
            data-testid='custom-button-content'
            className={classNames({
              [styles.buttonTextWrapper]: true,
              [styles.hidden]: loader && !withLoader,
            })}>
          {text || children}
        </span>
        <LoadingSpinner
            isLoading={loader}
            withLoader={withLoader}
            isLight={Object.values(LightBackgroundButtonTypes).includes(type)}/>
      </button>
  );
};

CustomButton.Types = ButtonType;
CustomButton.Sizes = ButtonSize;

export default CustomButton;
