import { isEmpty, get } from 'lodash';

export const post = state => state.post.post;
export const posts = state => state.post.posts;
export const secondary_posts = state => state.post.secondary_posts;
export const cursor = state => state.post.cursor;
export const parent_posts = state => state.post.parent_posts;
export const show_text = state => state.post.show_text;
export const postById = postId => state => state.post.post2[postId]
export const currentPostClapCount = state => get(state, 'post.post.claps_count', null);
export const currentPostCommentsCount = state => get(state, 'post.post.comments_count', null);
export const currentPostComments = state => get(state, 'post.post.comments', []);
export const isSeenAnnouncement =
    ({ userId, postId }) =>
    state => {
        if (!userId || !postId) {
            return false;
        }

        const seenAnnouncement = state.post?.seenAnnouncement;
        if (isEmpty(seenAnnouncement)) {
            return false;
        }

        return seenAnnouncement[userId]?.includes(postId);
    };
