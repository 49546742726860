import React, { useCallback, useState, useLayoutEffect } from 'react';
import styles from './index.module.scss';
import ChosenTag from '../common/info/ChosenTag';
import classNames from 'classnames';

const PreviewTags = ({ tagsPreview, stepElement, statusSteps }) => {
    const [rect, setRect] = useState(null);
    useLayoutEffect(() => {
        if (!stepElement) {
            setRect(document.getElementById('tagBlockStepProfile'));
        } else if (stepElement && statusSteps === 'archived') {
            setRect(document.getElementById('tagBlockArchived'));
        } else {
            setRect(document.getElementById('tagBlock'));
        }
    }, [statusSteps, stepElement]);

    const renderTags = useCallback(
        (tags, stepElement) => {
            const final = [];
            let totalWidth = 0;
            if (tags?.length > 0) {
                if (rect) {
                    const { width: maxWidth } = rect?.getBoundingClientRect();
                    for (let i = 0; i < tags.length; i += 1) {
                        const node = document.createElement('span');
                        node.classList.add(stepElement ? styles.tagNameNew : styles.tagName);
                        node.innerText = tags[i]?.name;
                        node.style.cssText = 'max-height: 0; margin: 0; padding-left: 50px;';
                        document.body.appendChild(node);
                        const { width } = node.getBoundingClientRect();
                        if (totalWidth + width <= maxWidth) {
                            if (tags[i]) {
                                final.push(tags[i]);
                                totalWidth += width;
                            }
                        }
                        document.body.removeChild(node);
                    }
                }
            }
            let newFinal;
            if (tags?.length === 1) {
                newFinal = tags;
            } else {
                newFinal = final;
            }
            return (
                <>
                    {newFinal.map((el, idx) => (
                        <>
                            <ChosenTag stepElement={stepElement} className={classNames(stepElement ? styles.tagNameNew : styles.tagName)} tagName={el?.name} />
                        </>
                    ))}
                    {stepElement && tags?.length - final?.length > 0 && <div className={styles.tagNameNew}>+{tags?.length - final?.length}</div>}
                </>
            );
        },
        [rect]
    );

    return <>{rect && renderTags(tagsPreview, stepElement)}</>;
};

export default PreviewTags;
