import React, { useState } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import CustomButton, { ButtonSize, ButtonType } from '../../common/buttons/CustomButton';
import copiesPrefix from '../../../copies.json';

const copies = copiesPrefix.tags;

const Category = ({ category, isSelected, handleSelectCategory }) => {
    const [categoryIsHovered, setCategoryIsHovered] = useState(false);
    return (
        <div
            className={classnames(styles.container, {
                [styles.hoveredCategory]: categoryIsHovered,
                [styles.selectedCategory]: isSelected,
            })}
            onMouseEnter={() => setCategoryIsHovered(true)}
            onMouseLeave={() => setCategoryIsHovered(false)}
        >
            <div className={styles.block}>
                <div className={styles.header}>
                    <h3>{category.name}</h3>
                    {isSelected && (
                        <div className={styles.checkIcon}>
                            <img src='../assets/img/checkWhite.svg' height='10px' alt='' />
                        </div>
                    )}
                </div>
                <div className={styles.tags}>
                    {category.tags?.map(tag => (
                        <div className={styles.tag}>{tag}</div>
                    ))}
                </div>
            </div>
            {categoryIsHovered && (
                <>
                    <div className={styles.bluryBackground} />
                    <div className={styles.selectCategoryOverlay}>
                        <CustomButton
                            onClick={() => handleSelectCategory(category)}
                            size={ButtonSize.MEDIUM}
                            type={isSelected ? ButtonType.SECONDARY_BLACK : ButtonType.SECONDARY_BLUE}
                            className={styles.selectButton}
                            text={isSelected ? copies.remove_category : copies.select_category}
                        ></CustomButton>
                    </div>
                </>
            )}
        </div>
    );
};

export default Category;
