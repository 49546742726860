import React from 'react';
import { useDispatch } from 'react-redux';
import { stepActions } from '../../redux/step';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/circleLoader.json';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import copiesPrefix from '../../copies.json';
import { noop } from 'lodash';

import styles from './index.module.scss';

const copies = copiesPrefix.archiveSteps;

const ArchivedStepPopup = ({
    handleBack,
    mapId,
    setDeleteIdStep,
    stepId,
    stepTitle,
    handleArchivePopup,
    onClickArchive,
    isDeletePost,
    forSeveralSteps,
    setBulkLoader = noop,
    isLastPost,
    deletedPost,
    fromRecentActivities,
    closeStepProfile,
    archiveAll,
    firstCursor,
}) => {
    const dispatch = useDispatch();
    const handleArchiveBtn = () => {
        if (setDeleteIdStep) setDeleteIdStep(stepId);
        if (forSeveralSteps) closeStepProfile();
        if (forSeveralSteps) setBulkLoader(true);
        handleArchivePopup(false);
        
        const stepsHandle = archiveAll ? 'excludeStepsIds' : 'stepsId';
        
        if (!isDeletePost) {
            dispatch(
                stepActions.archiveStep({
                    mapId: mapId,
                    deleteAll: archiveAll,
                    [stepsHandle]: forSeveralSteps ? stepId : [stepId],
                    routeName: fromRecentActivities ? 'mapUpdates' : 'mapView',
                    firstCursorSteps: firstCursor,
                }),
            );
        }
        
        if (forSeveralSteps) 
            setBulkLoader(false);

        if (!fromRecentActivities && handleBack) {
            handleBack();
        }

        mixpanel.track(mixpanelEvents.LV_BULK_ARCHIVE_SUBMIT);
        mixpanel.track(mixpanelEvents.STEP_ARCHIVE, {
            [mixpanelProperties.STEP_TITLE]: stepTitle,
            [mixpanelProperties.STEP_ID]: stepId,
        });
    };
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };
    
    return (
        <div className={styles.container}>
            <div
                className={classNames(styles.block, {
                    [styles.longBlock]: isLastPost,
                })}
            >
                {isLastPost ? (
                    <p className={styles.text}>{copies.description_last_post}</p>
                ) : (
                    <p className={styles.text}>
                        {copies.description}
                        {isDeletePost ? copies.delete_action : copies.archive_action}
                        <br />
                        {isDeletePost ? (
                            copies.this_post
                        ) : forSeveralSteps ? (
                            <span>{copies.these_steps}</span>
                        ) : (
                            <>
                                <b
                                    className={classNames({
                                        [styles.bold]: stepTitle?.search(/[\u0590-\u05FF]/) >= 0 /*Hebrew text check*/,
                                    })}
                                >
                                    “{stepTitle}”
                                </b>{' '}
                                ?
                            </>
                        )}
                    </p>
                )}
                <Grid container justify={'center'} alignItems={'center'} item xs={12}>
                    <CustomButton
                        className={styles.mr16}
                        size={'mediumSize'}
                        onClick={() => handleArchivePopup(false)}
                        type={ButtonType.SECONDARY_BLACK}
                        text={copies.cancel}
                    />
                    <CustomButton
                        size={'mediumSize'}
                        onClick={() => {
                            onClickArchive && onClickArchive(true);
                            handleArchiveBtn();
                        }}
                        type={ButtonType.DANGER}
                        text={
                            deletedPost ? (
                                <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={25} height={25} />
                            ) : isDeletePost ? (
                                copies.delete
                            ) : (
                                copies.archive
                            )
                        }
                    />
                </Grid>
            </div>
        </div>
    );
};

export default ArchivedStepPopup;
