import React, { useCallback } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { Star } from 'phosphor-react';
import copiesPrefix from '../../../../copies.json';
import Rating from '../../../common/Ratings';

const copies = copiesPrefix.stepProfile;

const Ratings = ({ selectedStars, ratingsCount, ratingTitle, onClickMore, className }) => {
    const isRated = selectedStars && ratingsCount;
    const renderRating = useCallback(
        () => (
            <>
                <Rating selectedStarts={selectedStars} />
                <span className={styles.ratingTitle}>{ratingTitle}</span>
                <span className={styles.ratingsCounter}>({ratingsCount})</span>
            </>
        ),
        [selectedStars, ratingsCount]
    );

    const noRating = (
        <>
            <Star className={styles.emptyIcon} size={16} color='#B5B7BB' weight='fill' />
            <span className={styles.emptyTags}>{copies.no_ratings}</span>
        </>
    );

    return (
        <div className={classNames(styles.block, className)}>
            {isRated ? renderRating() : noRating}
            <button className={classNames(styles.showMoreButton, { [styles.rightMargin]: isRated })} onClick={onClickMore}>
                {isRated ? copies.edit : copies.rate}
            </button>
        </div>
    );
};

export default Ratings;
