import React from 'react';
import styles from './index.module.scss';
import config from '../../../../config';
import Facebook from './Facebook';
const FacebookLoginButton = ({ fbRef, handleSocialLogin, handleSocialLoginFailure, text, fireMixpanelEvent }) => {
    return (
        <div onClick={fireMixpanelEvent}>
            <Facebook
                autoCleanUri
                ref={fbRef}
                className={styles.facebookButton}
                provider='facebook'
                appId={config.social.facebookClientId}
                onLoginSuccess={user => handleSocialLogin(user, 'facebook')}
                onLoginFailure={handleSocialLoginFailure}
            >
                <img src='/assets/img/steps3LoginLayout/Facebook.svg' alt='' className={styles.facebookIcon} />
                <span>{text}</span>
            </Facebook>
        </div>
    );
};

export default FacebookLoginButton;
