import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { X } from '../../icons';
import { replaceMultipleSpacesWithSingleSpace } from '../utils';

import styles from '../TextArrayBody.module.scss';

const CTABanner = ({ onCtaClick = noop, children, onClose = noop }) => {
    return (
        <div className={styles.placeNotInMap}>
            <span className={styles.placeName}>{replaceMultipleSpacesWithSingleSpace(children)}</span>
            <span>
                <FormattedMessage id='feed.step_not_in_map' />
            </span>{' '}
            <span className='link' onClick={onCtaClick}>
                <FormattedMessage id='feed.add_it' />
            </span>{' '}
            <X size={14} className={styles.closeBannerButton} onClick={onClose} />
        </div>
    );
};

export default CTABanner;
