import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import styles from './index.module.scss';
import EmptyImage from './EmptyImage';

const EmptyImages = ({ onClickAddPost = noop, className }) => {
    const onClickAddImage = () => {
        onClickAddPost({ triggerAddImage: true });
    };
    return (
        <div className={classNames(styles.block, className)}>
            <EmptyImage onClickAddPost={onClickAddImage} />
            <EmptyImage onClickAddPost={onClickAddImage} />
        </div>
    );
};

export default EmptyImages;
