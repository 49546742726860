import styles from './index.module.scss';
import { MapPin } from 'phosphor-react';

const TITLE = 'What\'s a step?';
const FIRST_PARAGRAPH = 'A step is any place on your map, like a restaurant, park, or coffee shop.';
const SECOND_PARAGRAPH = 'Steps can include photos, comments, ratings and other useful information.';

const WhatIsAStepExlanation = () => {
    return (
        <div className={styles.container}>
            <header>
                <MapPin width={25} height={25} color='#666a70' weight='fill' />
                <span>{TITLE}</span>
            </header>

            <section className={styles.description}>
                <p>{FIRST_PARAGRAPH}</p>
                <p>{SECOND_PARAGRAPH}</p>
            </section>
        </div>
    );
};

export default WhatIsAStepExlanation;
