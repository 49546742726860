import React from 'react';

const IconMembers = ({ stroke }) => (
    <svg width='21' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#a)' stroke={stroke} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'>
            <path d='M14.619 18.253V16.61a3.29 3.29 0 0 0-3.288-3.289H4.755a3.287 3.287 0 0 0-3.288 3.289v1.644M8.043 10.031a3.288 3.288 0 1 0 0-6.577 3.288 3.288 0 0 0 0 6.577zM19.55 18.253V16.61a3.29 3.29 0 0 0-2.465-3.182M13.797 3.56a3.288 3.288 0 0 1 0 6.373' />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' transform='translate(.645 .987)' d='M0 0h19.727v19.733H0z' />
            </clipPath>
        </defs>
    </svg>
);

export default IconMembers;
