import React from 'react';
import { noop } from 'lodash';
import styles from './index.module.scss';
import classNames from 'classnames';
import { DotsThreeVertical } from 'phosphor-react';

const MoreButton = ({
    opened,
    onClick = noop,
    size = 26,
    stopPropagation = false,
    className,
}) => {
    return (
        <DotsThreeVertical
            size={size}
            color='3A3C3F'
            data-testid='more-button'
            className={classNames(className, "hover:bg-gray-100 cursor-pointer rounded-full", {
                [styles.opened]: opened
            })}
            onClick={event => {
                stopPropagation && event.stopPropagation();
                onClick(event);
            }}/>
    );
};

export default MoreButton;
