import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import { userSelectors } from '../../../../redux/user';
import Outcome from '../Outcome';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import { BaseCard } from '../BaseCard';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.mapUpdates;

const Outcomes = {
    UNHANDLED: 'unhandled',
    DELETE: 'delete',
    DISREGARD: 'disregard',
};

const ReportStepCard = ({
    outcome = '',
    handlerManager,
    managerPreview,
    avatarImageId = '',
    textArray = [],
    createdAt = 0,
    message = '',
    onViewOnMapClick = noop,
    onDisregard = noop,
    onTagClick = noop,
    onRemove = noop,
    onKeep = noop,
    ...rest
}) => {
    const user = useSelector(userSelectors.user);
    const maps = useSelector(userSelectors.maps);
    const [isDisabled, setIsDisabled] = useState(false);
    const selectedMapId = useSelector(userSelectors.selectedMapId);
    const selectedMap = maps?.filter(({ _id }) => _id === selectedMapId)[0];
    const managers = selectedMap?.managers;
    const hasHandlerManager = handlerManager && handlerManager !== user.id;
    const managerInfomation = managers?.filter(({ _id }) => _id === handlerManager)[0];

    return (
        <BaseCard
            avatarImgId={avatarImageId}
            createdAt={createdAt}
            textArray={textArray}
            message={message}
            onClose={onDisregard}
            onTagClick={onTagClick}
            shouldRenderFooter={outcome === Outcomes.DELETE || outcome === Outcomes.DISREGARD || hasHandlerManager}
            footer={<Outcome text={outcome === Outcomes.DISREGARD ? copies.kept_by : copies.removed_by} managerPreview={managerPreview || managerInfomation} />}
            buttons={[
                <CustomButton type={ButtonType.SECONDARY_BLACK} size={ButtonSize.MEDIUM} onClick={onViewOnMapClick}>
                    <FormattedMessage id='mapUpdates.view_on_map' />
                </CustomButton>,
                <CustomButton
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.MEDIUM}
                    disabled={isDisabled}
                    onClick={() => {
                        onRemove();
                        setIsDisabled(true);
                    }}
                    clicked={outcome !== Outcomes.UNHANDLED}
                >
                    {outcome === Outcomes.DELETE ? copies.removed : copies.archive_step}
                </CustomButton>,
                <CustomButton
                    disabled={isDisabled}
                    singleClick
                    size={ButtonSize.MEDIUM}
                    type={ButtonType.PRIMARY}
                    onClick={onKeep}
                    clicked={outcome !== Outcomes.UNHANDLED}
                >
                    {outcome === Outcomes.DISREGARD ? copies.kept : copies.keep_step}
                </CustomButton>,
            ]}
            {...rest}
        />
    );
};

export default ReportStepCard;
