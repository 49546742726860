import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import mixpanel from 'mixpanel-browser';
import styles from './index.module.scss';
import FacebookLoginButton from '../../components/common/buttons/FacebookLoginButton';
import GoogleLoginButton from '../../components/common/buttons/GoogleLoginButton';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../config/routes';
import { LOGIN_TYPES } from '../../config/constants';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/user';
import { getHashValue } from '../../helpers';
import { mixpanelEvents } from '../../helpers/mixpanel';
import AppleLoginButton from '../../components/common/buttons/AppleLoginButton/AppleLoginButton';
import LoginInput from '../../components/common/inputs/LoginInput';
import { userActionActions } from '../../redux/userAction';
import CustomButton, { ButtonType } from '../../components/common/buttons/CustomButton';
import copiesPrefix from '../../copies.json';
import Logo from '../../components/common/Logo';
import classNames from "classnames";

const copies = copiesPrefix.login;

const Login = props => {
  const fbRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorEmail, setErrorMail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [email, setMail] = useState('');
  const [password, setPass] = useState('');
  const [loader, setLoader] = useState(false);
  const [typePassword, setTypePassword] = useState('password');
  const isMobile = window.innerWidth < 720;
  
  const handleChange = e => {
    if (email.length > 0) {
      setErrorMail(false);
    }
    setMail(e.target.value);
  };
  const handlePass = e => {
    if (password.length > 0) {
      setErrorPassword(false);
    }
    setPass(e.target.value);
  };
  
  const redirectForgotPassword = () => {
    navigate(ROUTES.FORGOT_PASSWORD, { from: ROUTES.LOGIN });
    dispatch(userActionActions.handleExpiredForgotToken(false));
  };
  
  const handleLogin = () => {
    setErrorMail(false);
    setErrorPassword(false);
    setLoader(true);
    mixpanel.track(mixpanelEvents.LOG_IN_MANUAL_CLICK);
    dispatch(
        userActions.login({
          email,
          password,
          provider: LOGIN_TYPES.EMAIL_PASSWORD,
          distinctId: mixpanel.get_distinct_id(),
        }),
    )
        .then(({ payload: user, error }) => {
          console.log('LOGIN USER', user);
          if (user) {
            const userId = user.id || user._id;
            localStorage.setItem('page', 'login');
            mixpanel.identify(userId);
            mixpanel.people.set('$user_id', userId);
            
            dispatch(userActions.setUserAuth(1));
            
            props?.setIsLoading(true);
            setLoader(false);
            // navigate(props.redirectTo || ('/' + ROUTES.MAP));
            
            if (localStorage.getItem('userId') !== userId) {
              localStorage.setItem('tooltipStorage', '');
            }
            
            setErrorMail(false);
            setErrorPassword(false);
            props?.setIsLoading(false);
          }
          
          if (error || !user) {
            setErrorMail(true);
            setErrorPassword(true);
            setLoader(false);
            props?.setIsLoading(false);
          }
        })
        .catch(() => {
          setErrorMail(true);
          setErrorPassword(true);
          setLoader(false);
          props?.setIsLoading(false);
        });
  };
  
  useEffect(() => {
    const token = getHashValue('id_token');
    if (token) {
      handleSocial({ tokenId: token }, LOGIN_TYPES.GOOGLE);
    } else {
      mixpanel.track(mixpanelEvents.LOG_IN_PREVIEW);
    }
  }, []);
  
  const handleSocial = (data, nameType) => {
    props?.setIsLoading(true);
    let provider;
    let token;
    let first_name;
    let last_name;
    let name;
    if (nameType === LOGIN_TYPES.GOOGLE) {
      provider = LOGIN_TYPES.GOOGLE;
      token = data.tokenId;
    }
    if (nameType === LOGIN_TYPES.FACEBOOK) {
      provider = LOGIN_TYPES.FACEBOOK;
      token = data._token.accessToken;
    }
    if (nameType === LOGIN_TYPES.APPLE) {
      provider = LOGIN_TYPES.APPLE;
      token = data.authorization.id_token;
      if (data?.user) {
        first_name = data?.user?.name?.firstName;
        last_name = data?.user?.name?.lastName;
        name = `${data?.user?.name?.firstName} ${data?.user?.name?.lastName}`;
      }
    }
    dispatch(
        userActions.login({
          provider,
          token,
          distinctId: mixpanel.get_distinct_id(),
          first_name,
          last_name,
          name,
        }),
    ).then(({ payload, error }) => {
      if (payload) {
        localStorage.setItem('page', 'login');
        const { id } = payload;
        mixpanel.identify(id);
        mixpanel.people.set('$user_id', id);
        dispatch(userActions.setUserAuth(1));
        
        // navigate(props?.redirectTo ? props.redirectTo : ('/' + ROUTES.MAP));
        
        if (localStorage.getItem('userId') !== (payload?.id || payload?._id)) {
          localStorage.setItem('tooltipStorage', '');
        }
      }
      
      if (error) {
        // navigate(ROUTES.LOGIN);
      }
      
      props?.setIsLoading(false);
    });
  };
  
  const keyDownHandle = e => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  
  const handleSocialFailure = response => {
  };
  const handleSignUpPage = () => {
    navigate(ROUTES.SIGN_UP);
    mixpanel.track(mixpanelEvents.LOG_IN_SIGN_UP_CLICKED);
  };
  
  return (
      <>
        <span className='absolute top-4 left-4 md:top-10 md:left-10'>
            <Logo size={isMobile ? Logo.Sizes.SMALL : Logo.Sizes.MEDIUM}/>
        </span>
        <div className={classNames(styles.loginBlock, "mt-20 md:mt-0")}>
          <div className={styles.textCenter}>
            <div className={styles.titleText}>{copies.title}</div>
            <p>
              {copies.description}
              <span onClick={handleSignUpPage} data-cy='sign-up-link'>
                  {copies.signup_link}
              </span>
            </p>
            <div className='lg:flex flex-col lg:flex-row items-center'>
              <Grid item xs={12}>
                <div className={styles.facebookButtonBlock}>
                  <FacebookLoginButton
                      fbRef={fbRef}
                      handleSocialLogin={data => handleSocial(data, LOGIN_TYPES.FACEBOOK)}
                      handleSocialLoginFailure={handleSocialFailure}
                      text={copies.facebook}
                      fireMixpanelEvent={() => mixpanel.track(mixpanelEvents.LOG_IN_FB_CLICK)}
                  />
                </div>
                <div className={styles.googleButtonBlock}>
                  <GoogleLoginButton
                      handleSocialLogin={data => handleSocial(data, LOGIN_TYPES.GOOGLE)}
                      handleSocialLoginFailure={handleSocialFailure}
                      text={copies.google}
                      fireMixpanelEvent={() => mixpanel.track(mixpanelEvents.LOG_IN_GOOGLE_CLICK)}
                  />
                </div>
                <div className={styles.appleButtonBlock}>
                  <AppleLoginButton
                      handleSocialLoginFailure={handleSocialFailure}
                      handleSocialLogin={data => handleSocial(data, LOGIN_TYPES.APPLE)}
                      text={copies.apple}
                      fireMixpanelEvent={() => {
                        mixpanel.track(mixpanelEvents.LOG_IN_APPLE_CLICK);
                      }}
                  />
                </div>
              </Grid>
              <div className={styles.divider}>
                <hr size={107}/>
                <div className={styles.leftHr}/>
                <span className={styles.orText}>Or</span>
                <div className={styles.rightHr}/>
                <hr size={107}/>
              </div>
              <Grid item xs={12}>
                <LoginInput
                    onClick={() => setErrorMail(false)}
                    type='email'
                    setState={setMail}
                    onChange={handleChange}
                    value={email}
                    errorText={copies.email_error}
                    error={errorEmail}
                    placeholder={copies.email_placeholder}
                    onKeyDown={null}
                />
                <LoginInput
                    onClick={() => setErrorPassword(false)}
                    typePassword={typePassword}
                    setTypePassword={setTypePassword}
                    type={typePassword}
                    setState={setPass}
                    onChange={handlePass}
                    value={password}
                    errorText={copies.password_error}
                    error={errorPassword}
                    placeholder={copies.password_placeholder}
                    onKeyDown={e => keyDownHandle(e)}
                />
                
                <p onClick={redirectForgotPassword} className={styles.textForgot}>
                  {copies.forgot_password}
                </p>
                {password.length > 0 && email.length > 0 && (
                    <div className={styles.manualButtonBlock}>
                      <CustomButton
                          size={'mediumSize'}
                          onClick={handleLogin}
                          type={ButtonType.SECONDARY_BLUE}
                          disabled={loader}
                          loader={loader}
                          text={copies.login_button}
                      />
                    </div>
                )}
              </Grid>
            </div>
            <div className={styles.fixBlock}/>
          </div>
        </div>
      </>
  );
};

export default Login;
