import { getClientType } from '../helpers';
import axios from './axios';

export const createMap = data => axios.post('/map2', data);
export const editUserData = ({ firstName, lastName }) => axios.put('/user', { first_name: firstName, last_name: lastName });
export const getUserData = () => axios.get('/postStart');
export const resolveIp = () =>
    axios
        .get('/resolveIp?reason=searchingByLocation')
        .unwrap()
        .then(payload => payload)
        .catch(error => {
            console.log(error);
        });

export const resetPassword = data => axios.post('/passwordrecovery/resetbytoken', data);
export const forgotPassword = data => axios.post('/passwordrecovery/request', data);
export const registration = ({ email, password, firstName, lastName, distinctId }) =>
    axios.post('/signup', {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        distinct_id: distinctId,
        client_type: 'map_manager',
    });
export const login = async ({ token, email, password, provider, distinctId: distinct_id, name, first_name, last_name }) =>
    axios.post('/login', { token, distinct_id, email, password, provider, name, first_name, last_name, client_type: getClientType() });
