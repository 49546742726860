import classNames from 'classnames';
import styles from './index.module.scss';

const SearchResultsSkeleton = () => {
    return (
        <div className={styles.loadingBlock}>
            <div className={classNames([styles.loadingSearchCategory, 'animate1'])} style={{ opacity: '1.1' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.1' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.1' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.75' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.90' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.90' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.80' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.70' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.60' }} />
            <div className={classNames([styles.loadingSearchResult, 'animate1'])} style={{ opacity: '1.50' }} />
        </div>
    );
};

export default SearchResultsSkeleton;
