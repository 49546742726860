import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, mapSelectors } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';

export const FromWhere = {
    MAP_SETTINGS: 'SETTINGS',
    MAP_UPDATES: 'MAP_UPDATES',
}

const useWelcomeMessage = (fromWhere = FromWhere.MAP_UPDATES) => {
    const dispatch = useDispatch();
    const [body, setBody] = useState('');
    const [sendAutomatically, setSendAutomatically] = useState(null);
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const welcomeMessageSettings = map?.welcome_message_settings || {
        send_automatically: false,
        body: '',
    };

    useEffect(() => {
        setBody(welcomeMessageSettings.body);
    }, [welcomeMessageSettings.body]);

    useEffect(() => {
        setSendAutomatically(welcomeMessageSettings.send_automatically);
    }, [welcomeMessageSettings.send_automatically]);

    const updateWelcomeMessage = async ({ body, sendAutomatically }) => {
        const data = await dispatch(
            mapActions.updateMap(mapId, {
                welcome_message_settings: {
                    body,
                    send_automatically: sendAutomatically,
                },
            })
        );
        trackUpdateWelcomeMessage()
        return data;
    };

    const trackUpdateWelcomeMessage = () => {
        mixpanel.track(mixpanelEvents[`WELCOME_MESSAGE_${fromWhere}_MESSAGE_SAVED`], {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: map?.name,
            [mixpanelProperties.MESSAGE_TEXT]: body,
        });
    }

    const setSendAutomaticallyAndTrack = (toggleState) => {
        setSendAutomatically(toggleState)
        mixpanel.track(mixpanelEvents[`WELCOME_MESSAGE_${fromWhere}_SEND_AUTOMATICALLY`], {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: map?.name,
            [mixpanelProperties.MESSAGE_TEXT]: body,
            [mixpanelProperties.ON_OR_OFF]: toggleState ? 'on' : 'off',
            [mixpanelProperties.IS_CUSTOM]: !!body,
        });
    }

    return {
        updateWelcomeMessage,
        welcomeMessage: welcomeMessageSettings,
        body,
        setBody,
        sendAutomatically,
        setSendAutomatically: setSendAutomaticallyAndTrack,
    };
};

export default useWelcomeMessage;
