import React from 'react';

const IconClock = ({ stroke }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
        <g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
            <g stroke={stroke} strokeWidth='2'>
                <g transform='translate(-338 -361) translate(339 362)'>
                    <circle cx='10.5' cy='10.5' r='10.5' />
                    <path d='M10.5 4.2L10.5 10.5 14.7 12.6' />
                </g>
            </g>
        </g>
    </svg>
);

export default IconClock;
