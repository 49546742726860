import classNames from 'classnames';

const Skeleton = () => (
    <div>
        <div
            style={{
                padding: '14px 16px',
                display: 'flex',
                borderBottom: '0.0625rem solid #dddddd',
            }}
        >
            <div
                className={classNames('animate')}
                style={{
                    width: '100px',
                    height: '20px',
                    margin: '0 auto 10px 0',
                }}
            />
            <div className={classNames('animate')} style={{ width: '20px', height: '20px' }} />
        </div>
        <div
            style={{
                padding: '19px 16px',
                display: 'flex',
                borderBottom: '0.0625rem solid #dddddd',
            }}
        >
            <div className={classNames('animate')} style={{ width: '20px', height: '20px' }} />
            <div
                className={classNames('animate')}
                style={{
                    width: '200px',
                    height: '20px',
                    marginLeft: '15px',
                }}
            />
        </div>
        <div
            style={{
                padding: '19px 16px',
                display: 'flex',
                borderBottom: '0.0625rem solid #dddddd',
            }}
        >
            <div className={classNames('animate')} style={{ width: '20px', height: '20px' }} />
            <div
                className={classNames('animate')}
                style={{
                    width: '200px',
                    height: '20px',
                    marginLeft: '15px',
                }}
            />
        </div>
        <div
            style={{
                padding: '19px 16px',
                display: 'flex',
                borderBottom: '0.0625rem solid #dddddd',
            }}
        >
            <div className={classNames('animate')} style={{ width: '20px', height: '20px' }} />
            <div
                className={classNames('animate')}
                style={{
                    width: '200px',
                    height: '20px',
                    marginLeft: '15px',
                }}
            />
        </div>
        <div
            style={{
                padding: '19px 16px',
                display: 'flex',
                borderBottom: '0.0625rem solid #dddddd',
            }}
        >
            <div className={classNames('animate')} style={{ width: '20px', height: '20px' }} />
            <div
                className={classNames('animate')}
                style={{
                    width: '200px',
                    height: '20px',
                    marginLeft: '15px',
                }}
            />
        </div>
        <div
            style={{
                padding: '19px 16px',
                display: 'flex',
                borderBottom: '0.0625rem solid #dddddd',
            }}
        >
            <div className={classNames('animate')} style={{ width: '20px', height: '20px' }} />
            <div
                className={classNames('animate')}
                style={{
                    width: '200px',
                    height: '20px',
                    marginLeft: '15px',
                }}
            />
        </div>
        <div
            style={{
                padding: '19px 16px',
                display: 'flex',
            }}
        >
            <div
                className={classNames('animate')}
                style={{
                    width: '50px',
                    height: '20px',
                    margin: '0 0 0 auto',
                }}
            />
        </div>
    </div>
);

export default Skeleton;
