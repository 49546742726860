import { GET_MEMBERS, DEEP_LINK, GET_MANAGERS } from './types';

import { success, error } from '@redux-requests/core';

const initialState = {
    totalMembersCount: null,
    deepLink: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case error(GET_MEMBERS): {
            return {
                ...state,
                totalMembersCount: null,
            };
        }

        case success(GET_MEMBERS): {
            return {
                ...state,
                totalMembersCount: action.response.data.total_results,
            };
        }

        case success(GET_MANAGERS): {
            return {
                ...state,
                totalMembersCount: action.response.data.total_results,
            };
        }

        case DEEP_LINK:
            return {
                ...state,
                deepLink: action.payload.data,
            };

        default:
            return state;
    }
};
export default reducer;
