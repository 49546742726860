import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import Avatar from '@material-ui/core/Avatar';
import { generateImageUrl } from '../../helpers';
import * as timeago from 'timeago.js';
import Linkify from 'react-linkify';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

const locale = function (number, index) {
    // number: the time ago / time in number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
        ['just now', 'right now'],
        ['%s seconds ago', 'in %s seconds'],
        ['1 minute ago', 'in 1 minute'],
        ['%s minutes ago', 'in %s minutes'],
        ['1 hour ago', 'in 1 hour'],
        ['%s hours ago', 'in %s hours'],
        ['1 day ago', 'in 1 day'],
        ['%s days ago', 'in %s days'],
        ['1 week ago', 'in 1 week'],
        ['%s weeks ago', 'in %s weeks'],
        ['1 month ago', 'in 1 month'],
        ['%s months ago', 'in %s months'],
        ['1 year ago', 'in 1 year'],
        ['%s years ago', 'in %s years'],
    ][index];
};
timeago.register('pt_BR', locale);

const PostSummary = ({ el }) => {
    const elementTextPost = useRef(null);
    const [textPost, setTextPost] = useState(null);

    useEffect(() => {
        if (elementTextPost?.current) {
            setTextPost(elementTextPost?.current?.firstChild?.outerHTML);
        }
    }, [el]);

    const iconType = type => {
        switch (type) {
            case 'DISCOVERER':
                return '/assets/img/discoverIcon.svg';
            case 'SHARED_BY':
                return '/assets/img/shareIcon.svg';
            case 'ADMIN':
                return '/assets/img/adminIcon.svg';
            default:
                return '';
        }
    };
    return (
        <div className={styles.block}>
            {el?.attachment && (
                <div className={styles.imagesBlock}>
                    <img
                        style={{ width: 207 }}
                        src={generateImageUrl({
                            imageId: el?.attachment?.image_id,
                            width: 209,
                            height: 161,
                            reason: 'post_summary',
                            rotate: true,
                        })}
                        alt={''}
                        width='310'
                        height='175'
                    />
                </div>
            )}
        </div>
    );
};

export default PostSummary;
