import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { generatePath, resolvePath, useLocation, useNavigate } from "react-router";
import { CreatePostInput, CreateNewPostModal, AddStepToMapModal } from "./FeedPost";
import useFeed from "./useFeed";
import FeedPosts from "./FeedPosts";
import { userSelectors } from "../../redux/user";
import { feedActions, feedSelectors } from "../../redux/feed";
import { mapSelectors } from "../../redux/map";
import { PlatformTypes, PostTypes, TagTypes } from "../../constants";
import mixpanel, { mixpanelEvents, mixpanelProperties } from "../../helpers/mixpanel";
import Routes from "../../config/routes";

import styles from "./Feed.module.scss";

const ModalNames = {
  ADD: "AddAnnouncementModal",
  REPLACE: "AnnouncementExistsModal",
  ADD_STEP: "AddStepToMapModal"
};

const Feed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: prevUrlState } = useLocation();
  const userId = useSelector(userSelectors.user)?.id;
  const mapId = useSelector(userSelectors.selectedMapId);
  const mapName = useSelector(mapSelectors.mapName(mapId));
  const userRoleInSelectedMap = useSelector(mapSelectors.userRole(mapId, userId));
  const isFeedLoading = useSelector(state => state.feed.isFeedLoading);
  const announcementId = useSelector(feedSelectors.getAnnouncementId);
  const feedPostsIds = useSelector(feedSelectors.getFeed);
  const isPostAdded = useSelector(feedSelectors.isPostAdded);
  const feedCursor = useSelector(feedSelectors.feedCursor);
  const { fetchFeed, loadMorePosts, firstUnseenByUserPostId } = useFeed({ mapId });
  const [activeModalName, setActiveModalName] = useState("");
  const [mentionedPlaces, setMentionedPlaces] = useState([]);

  useEffect(() => {
    if (!prevUrlState?.triggerAddAnnouncment) return;
    setActiveModalName(ModalNames.ADD);
  }, []);

  useEffect(() => {
    fetchFeed({ quiet: false });
  }, [mapId, isPostAdded]);

  const closeModal = () => setActiveModalName("");

  const handleAddStepModalAction = () => {
    closeModal();
    const pendingPlaces = mentionedPlaces.slice(0, -1);
    setMentionedPlaces(pendingPlaces);
  };

  const onClickPostPage = postId => {
    const { pathname } = resolvePath(Routes.POST_PAGE, Routes.MAP);
    const pathWithParams = generatePath(pathname, { postId });
    navigate(`/${pathWithParams}`);
  };

  const goToAddStep = () => {
    navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`);
  };

  const goToStepProfile = stepId => {
    const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId });
    navigate(url);
  };

  return (
    <div className={classNames(styles.container)}>
      <CreatePostInput
        onClick={() => {
          mixpanel.track(mixpanelEvents.POST_POPUP_SHOW, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: mapName,
            [mixpanelProperties.USER_ID]: userId,
            [mixpanelProperties.USER_TYPE_STATUS]: userRoleInSelectedMap === "owner" ? PlatformTypes.ADMIN : PlatformTypes.MAP_MANAGER
          });
          setActiveModalName(ModalNames.ADD);
        }}
      />
      <FeedPosts
        mapId={mapId}
        loadMorePosts={loadMorePosts}
        onPostClick={onClickPostPage}
        announcementId={announcementId}
        feedPostsIds={feedPostsIds}
        isLoading={isFeedLoading}
        cursor={feedCursor}
        goToAddStep={goToAddStep}
        goToStepProfile={goToStepProfile}
        onTagClick={tag => {
          if (tag.type === TagTypes.PLACE_TAG) {
            goToStepProfile(tag.id);
          }
        }}
        firstUnseenByUserPostId={firstUnseenByUserPostId}
        onTagError={() => {
          fetchFeed({ quiet: false });
        }}
        onTagPlace={place => {
          if (!place || place.type !== TagTypes.PLACE_TAG) {
            return;
          }

          setMentionedPlaces(places =>
            places.concat({
              id: place.id,
              name: place.name,
              position: []
            })
          );

          setActiveModalName(ModalNames.ADD_STEP);
        }}
      />
      {activeModalName === ModalNames.ADD_STEP && (
        <AddStepToMapModal
          isOpen
          mapId={mapId}
          placeId={mentionedPlaces[0]?.id}
          placeName={mentionedPlaces[0]?.name}
          placePosition={mentionedPlaces[0]?.position}
          mapName={mapName}
          onConfirm={handleAddStepModalAction}
          onClose={handleAddStepModalAction}
        />
      )}
      {activeModalName === ModalNames.ADD && (
        <CreateNewPostModal
          isOpen
          isAnnounceable
          postType={prevUrlState?.triggerAddAnnouncment ? PostTypes.ANNOUNCEMENT : PostTypes.FEED}
          onSuccess={async post => {
            closeModal();

            if (post.type === PostTypes.ANNOUNCEMENT) {
              dispatch(feedActions.addAnnouncement({ announcement: post }));
            } else {
              await fetchFeed({ quiet: false });
            }

            const placeTags = post.text_array.filter(({ text_type }) => {
              return text_type === TagTypes.PLACE_TAG;
            });

            if (!placeTags.length) {
              return;
            }

            placeTags.forEach(place => {
              setMentionedPlaces(places =>
                places.concat({
                  id: place.place_id,
                  name: place.text,
                  position: []
                })
              );
            });
            setActiveModalName(ModalNames.ADD_STEP);
          }}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default Feed;
