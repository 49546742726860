import React from 'react';
import { noop } from 'lodash';
import { useDispatch } from 'react-redux';
import { postActions } from '../../../../redux/post';
import Grid from '@material-ui/core/Grid';
import { mapActions } from '../../../../redux/map';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import copiesPrefix from '../../../../copies.json';

import styles from './index.module.scss';

const copies = copiesPrefix.deletePost;

const ModalsDeletePost = ({
    archiveData,
    pageClick,
    onClickPrev = noop,
    handlePostPageClick = noop,
    handleDeletePostPopup = noop,
    setDeletePostImage = noop,
    updateStepPostsCount = noop,
    onDeleteSuccess = noop,
    onDelete = noop,
    onDeletePostImage = noop,
    cancelText = copies.cancel_button,
    deleteText = copies.delete_button,
    bodyText = copies.description,
}) => {
    const dispatch = useDispatch();
    const handleArchiveBtn = () => {
        onDelete();
        dispatch(postActions.deleteStepPost(archiveData?.map_id, archiveData?.step_id, archiveData?.id, pageClick)).then(({ data, error }) => {
            if (data) {
                const stepId = archiveData?.step_id;
                const postId = archiveData?.id;
                const countComments = archiveData?.data?.comments_count;
                updateStepPostsCount(stepId, true, countComments);
                onDeletePostImage({ stepId, postId });
                dispatch(
                    mapActions.deletePostImage(archiveData?.map_id, {
                        stepId,
                        postId,
                    })
                );
                if (archiveData?.contentParent) handlePostPageClick(archiveData?.parent_id);
                if (pageClick === 'post-deleted') onClickPrev();

                data.success && onDeleteSuccess(postId);
            }
        });
        handleDeletePostPopup(false);
    };

    return (
        <div className={styles.modals}>
            <div className={styles.block}>
                <p className={styles.text}>{bodyText}</p>
                <Grid container justify={'space-evenly'} alignItems={'flex-end'} item xs={12}>
                    <CustomButton size={'mediumSize'} onClick={() => handleDeletePostPopup(false)} type={ButtonType.SECONDARY_BLACK} text={cancelText} />
                    <CustomButton size={'mediumSize'} onClick={handleArchiveBtn} type={ButtonType.DANGER} text={deleteText} />
                </Grid>
            </div>
        </div>
    );
};

export default ModalsDeletePost;
