import { useRef, useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import { IconX } from '../../../common/icons';
import { removeWhitespace } from '../../../../helpers';
import copies from '../../../../copies.json';
import { ToastContext } from '../../../../context';
import { useDebounce } from '../../../../hooks';

import styles from '../../index.module.scss';
import { noop } from 'lodash';

const NewTagInput = ({ isDisabled, isValueDuplicate, onClickAway, onSubmit = noop, onChange = noop, onKeyPress = noop, onCancel = noop }) => {
    const { setToast } = useContext(ToastContext);
    const isDuplicateDebounce = useDebounce(isValueDuplicate, 500);
    const [value, setValue] = useState('');
    const inputRefTag = useRef();

    useEffect(() => {
        if (value && isDuplicateDebounce) {
            setToast({
                text: copies.tags.tag_exists,
                className: styles.existingTagToast,
            });
        }
    }, [isDuplicateDebounce]);

    return (
        <>
            <ClickAwayListener onClickAway={() => onClickAway(value)}>
                <div className={styles.addNewTagContainer}>
                    <div className={classNames({ [styles.enableInput]: !isDisabled }, { [styles.disableInput]: isDisabled })}>
                        <input
                            autoFocus
                            className={styles.input}
                            ref={inputRefTag}
                            onChange={event => {
                                const tagName = removeWhitespace(event.target.value);
                                setValue(tagName);
                                onChange(tagName);
                            }}
                            onKeyPress={event => {
                                onKeyPress(event.target.value, event.charCode);
                            }}
                            placeholder={copies.tags.tagTemplates.categories.tag_name_placeholder}
                        />
                        <button className={styles.firstButton} onClick={onCancel}>
                            <IconX color={isDisabled ? '#B5B7BB' : '#777a80'} />
                        </button>
                        <button
                            className={classNames({ [styles.enable]: !isDisabled }, { [styles.disable]: isDisabled })}
                            style={{ border: 'none' }}
                            onClick={() => onSubmit(value)}
                        >
                            <img style={{ marginTop: '4px' }} src='/assets/img/iconCheckWhite.svg' alt='' />
                        </button>
                    </div>
                </div>
            </ClickAwayListener>
        </>
    );
};

export default NewTagInput;
