import React from 'react';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import Outcome from '../Outcome';
import copies from '../../../../copies.json';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import { BaseCard } from '../BaseCard';

const RatedCard = ({ textArray, createdAt, onDisregard = noop, onSendThanks = noop, outcome, handlerManager, managerPreview, onTagClick = noop, ...rest }) => {
    const user = useSelector(userSelectors.user);
    const maps = useSelector(userSelectors.maps);
    const selectedMapId = useSelector(userSelectors.selectedMapId);
    const selectedMap = maps?.filter(({ _id }) => _id === selectedMapId)[0];
    const managers = selectedMap?.managers;
    const hasHandlerManager = handlerManager && handlerManager !== user.id;
    const managerInfomation = managers?.filter(({ _id }) => _id === handlerManager)[0];

    return (
        <BaseCard
            textArray={textArray}
            createdAt={createdAt}
            onClose={onDisregard}
            onTagClick={onTagClick}
            {...rest}
            shouldRenderFooter={outcome === 'thanks' || hasHandlerManager}
            footer={<Outcome text={copies.mapUpdates.thanked_by} managerPreview={managerPreview || managerInfomation} />}
            buttons={[
                <CustomButton onClick={onSendThanks} singleClick clicked={outcome !== 'unhandled'} type={ButtonType.PRIMARY} size={ButtonSize.MEDIUM}>
                    {copies.mapUpdates.send_thanks}
                </CustomButton>,
            ]}
        />
    );
};

export default RatedCard;
