import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { SortDirection } from '../../../constants/sort';
import Triangle from './Triangle';

const Colors = {
    ACTIVE: '#2171EC',
    DISABLED: '#D1E3FF',
};

const MARGIN_DIVISION = 5;

const SortArrows = ({ size = 3, sort, className }) => {
    const marginBetweenArrows = Math.max(Math.floor(size / MARGIN_DIVISION), 1);
    const pxMargin = `${marginBetweenArrows}px`;
    return (
        <div className={classNames(styles.block, className)}>
            <Triangle
                size={size}
                color={sort === SortDirection.ASC ? Colors.ACTIVE : Colors.DISABLED}
                className={styles.turnedOverTriangle}
                style={{ marginBottom: pxMargin }}
            />
            <Triangle
                size={size}
                color={sort === SortDirection.DES ? Colors.ACTIVE : Colors.DISABLED}
                className={styles.trinagle}
                style={{ marginTop: pxMargin }}
            />
        </div>
    );
};

SortArrows.propTypes = {
    size: PropTypes.number,
    sort: PropTypes.string,
};

export default SortArrows;
