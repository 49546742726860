import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import CustomButton, { ButtonSize, ButtonType } from '../../../common/buttons/CustomButton';

import styles from './CreateNewPostModal.module.scss';

const ExitConfirmationModal = ({ onConfirm = noop, onCancel = noop, isOpen = false }) => {
    return (
        <BaseModal isActive={isOpen} size={ModalSizes.SMALL} className={styles.exitConfirmationModal}>
            <FormattedMessage id='feed.exitPostCreateConfirmation.title' tagName='h1' />
            <FormattedMessage id='feed.exitPostCreateConfirmation.description' tagName='p' />
            <div className={styles.footer}>
                <CustomButton
                    text={<FormattedMessage id='feed.exitPostCreateConfirmation.confirm' />}
                    size={ButtonSize.SMALL}
                    type={ButtonType.GHOST2}
                    onClick={onConfirm}
                />
                <CustomButton
                    text={<FormattedMessage id='feed.exitPostCreateConfirmation.cancel' />}
                    size={ButtonSize.SMALL}
                    type={ButtonType.PRIMARY}
                    onClick={onCancel}
                />
            </div>
        </BaseModal>
    );
};

export default ExitConfirmationModal;
