import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PostSummary from '../../../../../components/PostSummary';
import { generateImageUrl } from '../../../../../helpers';
import { modalsActions } from '../../../../../redux/modals';
import { useDispatch } from 'react-redux';

const PostSummaryRoll = ({ stepPostsImage }) => {
    const slider = useRef(null);
    const dispatch = useDispatch();

    const toggleImagePreview = ({ imagePreviewShow, imagePreviewArray, imagePreviewIndex }) => {
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: imagePreviewShow,
                imagePreviewArray: imagePreviewArray,
                imagePreviewIndex: imagePreviewIndex,
            })
        );
    };
    useEffect(() => {
        if (slider) {
            slider.current.slickGoTo(0);
        }
    }, [slider]);
    const ImgArrowLeft = ({ onClick, currentSlide }) =>
        currentSlide > 0 && <img className={styles.ImgArrowLeft} src='../../assets/img/arrow-slider.svg' alt='left' onClick={onClick} />;

    const ImgArrowRight = ({ onClick, currentSlide, slideCount }) =>
        currentSlide < slideCount - 1.85 && <img className={styles.ImgArrowRight} src='../../assets/img/arrow-slider.svg' alt='right' onClick={onClick} />;

    const SLIDER_SETTINGS = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: stepPostsImage?.length > 1 ? 1.85 : 1,
        slidesToScroll: 1,
        prevArrow: <ImgArrowLeft />,
        nextArrow: <ImgArrowRight />,
    };
    return (
        <Slider id ref={slider} {...SLIDER_SETTINGS}>
            {stepPostsImage?.map((pending, index) => (
                <div
                    onClick={() =>
                        toggleImagePreview({
                            imagePreviewShow: true,
                            imagePreviewIndex: index,
                            imagePreviewArray: stepPostsImage
                        })
                    }
                    key={index}
                    className={styles.paddingSlider}
                >
                    <PostSummary el={pending} />
                </div>
            ))}
        </Slider>
    );
};

export default PostSummaryRoll;
