import React from 'react';

const IconUpload = () => (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13.75 10h3.75a.624.624 0 0 1 .625.625v5a.624.624 0 0 1-.625.625h-15a.625.625 0 0 1-.625-.625v-5A.625.625 0 0 1 2.5 10h3.75M10 10V1.875M6.25 5.625 10 1.875l3.75 3.75'
            stroke='#2171EC'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path d='M14.688 14.063a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874z' fill='#2171EC' />
    </svg>
);

export default IconUpload;
