import { forwardRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { noop } from "lodash";
import { userSelectors } from "../../../redux/user";
import useAddStep from "../../Feed/FeedPost/AddStepToMapModal/useAddStep";
import { TagTypes } from "../../../constants";
import { TagLocationInPost } from "../../../features/tag-location-in-post";
import styles from "./TextArrayBody.module.scss";
import useToast from "../../../hooks/useToast";
import { BodyItem, CTABanner } from "./components";

window.addEventListener("copy", event => {
  // There's a bug when copying from this component and trying to create a new post
  // If there's a link, it's ommited via DraftJS for some reason.
  // We want to refactor DraftJS out, but until then this is a workaround for
  // "cleaning up" the copied text.
  const selection = document.getSelection();
  event.clipboardData.setData("text/plain", selection.toString());
  event.preventDefault();
});

const TextArrayBody = (
  {
    className,
    children,
    postId,
    id = postId,
    body: textArray = [],
    onClick = noop,
    onTagPlace = noop,
    onTagError = noop,
    isLoading = false,
    isTaggingDisabled = false,
    onTagClick = noop
  },
  ref
) => {
  const { setToast } = useToast();
  const { setStepToAdd } = useAddStep();
  const mapId = useSelector(userSelectors.selectedMapId);

  return (
    <ErrorBoundary>
      <TagLocationInPost
        isTaggingDisabled={isTaggingDisabled}
        textArray={textArray}
        textId={`tag-location-in-post-${id}`}
        postId={postId}
        mapId={mapId}
        onTag={onTagPlace}
        onError={() => {
          setToast({
            id: "post.tag_error",
            withIcon: false
          });
          onTagError();
        }}
      >
        {({ body, id, isTextSelected }) => {
          // TODO: optimize this, no JSON parse in render fuctions
          const dontShowPlaceIds = JSON.parse(localStorage.getItem("dontShowPlaceIds") || "[]");
          const placeTag = body.find(({ text_type }) => text_type === TagTypes.PLACE_TAG);
          const shouldShowPlaceNotInMap = placeTag && !dontShowPlaceIds.includes(placeTag.place_id) && !isTaggingDisabled;
          return (
            <>
              <div
                className={classNames(styles.body, className, {
                  [styles.isLoading]: isLoading
                })}
                onClick={event => {
                  if (isLoading || isTextSelected) {
                    event.preventDefault();
                    return;
                  }
                  onClick();
                }}
              >
                <span>{children}</span>
                <span
                  id={id}
                  ref={ref}
                  role="article"
                  className={styles.bodyWrapper}
                  // These are added to prevent edge case bugs in text selection
                  // specifically when triple clicking on a sentence.
                  // TODO: After contentEditable is removed (MM-Links-are-not-linkified-4273),
                  //       we can probably remove these as well.
                  suppressContentEditableWarning
                  spellCheck="false">
                  {body.map((item, index) => {
                    const { id, uuid, text, text_type, place_id, step_id, user_id } = item;
                    const userPopoverId = uuid;
                    const isTag = [TagTypes.PLACE_TAG, TagTypes.STEP_TAG, TagTypes.USER_TAG].includes(text_type);

                    if (!text) {
                      return null;
                    }

                    return (
                      <BodyItem
                        placeId={place_id}
                        stepId={step_id}
                        userId={user_id}
                        key={uuid}
                        uuid={uuid}
                        id={id}
                        userPopoverId={userPopoverId}
                        isTag={isTag}
                        item={item}
                        postId={postId}
                        text={text}
                        textType={text_type}
                        setStepToAdd={setStepToAdd}
                        onClick={() => {
                          if (!isTag) {
                            return;
                          }

                          onTagClick();
                        }}
                      />
                    );
                  })}
                </span>
              </div>
              {shouldShowPlaceNotInMap && (
                <CTABanner
                  onCtaClick={() => {
                    setStepToAdd({ placeId: placeTag.place_id || placeTag.id });
                  }}
                  onClose={() => {
                    const dontShowPlaceIds = JSON.parse(localStorage.getItem("dontShowPlaceIds") || "[]");
                    localStorage.setItem("dontShowPlaceIds", JSON.stringify([...dontShowPlaceIds, placeTag.place_id || placeTag.id]));
                  }}
                >
                  {placeTag.text}
                </CTABanner>
              )}
            </>
          );
        }}
      </TagLocationInPost>
    </ErrorBoundary>
  );
};

export default forwardRef(TextArrayBody);
