import { useRef, useState } from 'react';
import { noop } from 'lodash';
import { ImageSquare } from 'phosphor-react';
import classNames from 'classnames';
import { getUploadImageInfo, uploadImageToS3, blobUrl } from '../../../../helpers';
import ImageCarousel from '../../../../lib/ImageCarousel';
import styles from './index.module.scss';
import { TextInput } from '../../../TextInput';

export const LINE_HEIGHT = 19.5;

const Input = ({
    id,
    onClick = noop,
    onTextChange = noop,
    onEnter = noop,
    onEsc = noop,
    placeholder,
    withFilePicker = false,
    disabled = false,
    className,
    defaultValue = [],
    multipleUpload,
    startingInputRows = 1,
    tag = {},
    isFocus = false,
}) => {
    const fileInputRef = useRef();
    const [value, setValue] = useState(defaultValue);
    const [files, _setFiles] = useState([]);
    const [isUploadingImage, setUploadingImage] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const keyEvents = {
        Enter: onEnter,
        Escape: onEsc,
    };

    const clearContent = () => {
        setValue('');
        _setFiles([]);
    };

    const setFiles = file =>
        _setFiles(state => {
            if (multipleUpload) {
                const fileInState = state.find(stateFile => stateFile.imageId == file.imageId);
                const idx = state.indexOf(fileInState);
                return fileInState ? [...state.slice(0, idx), file, ...state.slice(idx + 1)] : state.concat(file);
            } else return [file];
        });

    const uploadFile = async file => {
        setUploadingImage(true);
        const info = await getUploadImageInfo();
        const fileState = {
            file,
            imageId: info.image_id,
            loading: true,
        };
        setFiles({ ...fileState });
        uploadImageToS3({
            uploadUrl: info.url,
            file,
            onUploadedFile: () => {
                delete fileState.loading;
                setFiles({ ...fileState });
                setUploadingImage(false);
            },
        });
        return info.image_id;
    };

    const onLoadFiles = async files => {
        for (const file of files) {
            uploadFile(file);
        }
        fileInputRef.current.value = '';
    };

    const onFilePickerClicked = () => {
        fileInputRef.current.click();
    };

    const removeFile = indexToDelete => {
        return _setFiles([...files.slice(0, indexToDelete), ...files.slice(indexToDelete + 1)]);
    };

    const onKeyEvent = (event, textArray, value) => {
        if (isUploadingImage) return;
        if (!value || event.key !== 'Enter' || event.shiftKey || event.key === 'Escape') {
            return;
        }

        keyEvents[event.key]({ textArray, files, value });
        clearContent();
        event.preventDefault();
    };

    const handleTextChange = (value, textArray) => {
        setValue(value);
        onTextChange(textArray);
    };

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.container, className)}
                onClick={event => {
                    event.stopPropagation();
                    onClick();
                }}
            >
                <div className={styles.inputWrapper}>
                    <TextInput
                        id={id}
                        isFocus={isFocus}
                        disabled={disabled}
                        onChange={handleTextChange}
                        className={styles.input}
                        placeholder={placeholder}
                        onKeyDown={onKeyEvent}
                        onEscape={onEsc}
                        data={value}
                        tag={tag}
                        deleteOnEnter={true}
                    />
                </div>
                {withFilePicker && (
                    <div className={styles.uploadImageWrapper}>
                        <ImageSquare
                            className={styles.camera}
                            size={25}
                            onClick={onFilePickerClicked}
                            color={isHovered ? '#2171EC' : '#3A3C3F'}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            weight='light'
                        />
                        <input
                            type='file'
                            className={styles.fileInput}
                            ref={fileInputRef}
                            onInputCapture={e => onLoadFiles(e.target.files)}
                            multiple={multipleUpload}
                        />
                    </div>
                )}
            </div>
            {isUploadingImage && <img className={styles.loading} src='/assets/img/loadingSpinner.svg' alt='loading' />}
            {!!files.length && !isUploadingImage && (
                <div className={styles.image}>
                    <ImageCarousel
                        classNameDiv={styles.imgBlock}
                        classNameImage={styles.imgSlide}
                        addDeleteIcon
                        onDeleteClick={removeFile}
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }}
                        images={files.map(file => blobUrl(file.file))}
                    />
                </div>
            )}
        </div>
    );
};

export default Input;
