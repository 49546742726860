import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Check, Minus } from 'phosphor-react';

const Checkbox = ({ className, checked, unSelect, onClick }) => {
    return (
        <div className={classNames(styles.block, { [styles.selected]: checked }, className)} onClick={onClick}>
            {unSelect ? <Minus color='#fff' size={12} weight='bold' /> : <Check color='#fff' size={14} weight='bold' />}
        </div>
    );
};

export default Checkbox;
