import { useMemo } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { StepTypes, StepStatuses } from '../../../constants';
import { isStepTypeOf } from '../../../helpers';

import styles from './StepBadge.module.scss';

const StepBadge = ({ businessStatus = '', stepIndications = [] }) => {
    const isTemporarilyClosed = businessStatus === StepStatuses.TEMPORARILY_CLOSED;
    const isPermanentlyClosed = businessStatus === StepStatuses.PERMANENTLY_CLOSED;
    const isNewStep = useMemo(() => isStepTypeOf(StepTypes.NEW, { indications: stepIndications }), [stepIndications]);

    if (isPermanentlyClosed) {
        return (
            <span className={styles.closedBadge}>
                <FormattedMessage id='stepBadge.permanently_closed' />
            </span>
        );
    }

    if (isTemporarilyClosed) {
        return (
            <span className={classNames(styles.closedBadge, styles.temporary)}>
                <FormattedMessage id='stepBadge.temporarily_closed' />
            </span>
        );
    }

    if (isNewStep) {
        return (
            <span className={styles.newBadge}>
                <FormattedMessage id='stepBadge.new' />
            </span>
        );
    }

    return null;
};

export default StepBadge;
