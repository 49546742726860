import React, { useState, useMemo, useEffect } from 'react';
import { noop } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { mapSelectors, mapActions } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import { useDebounce } from '../../hooks';
import SearchInput from '../common/inputs/SearchInput2';
import { ArrowLeft } from 'phosphor-react';
import copiesPrefix from '../../copies.json';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import SearchSteps, { CreatePlaceBehaviors, SearchTypes } from '../SearchSteps2';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { getPlaceCoordinates } from '../../helpers';
import EditNameOfPlace from './EditNameOfPlace';

import styles from './EditStepDetails.module.scss';

const copies = copiesPrefix.addStep;
const INPUTS_DEFAULT_VALUE = { value: '', isLoading: false, isSearchPopUpOpen: true };

const EditStepDetailsOld = ({
                                nameOfPlace = '',
                                isLocationInputClear = false,
                                isNameInputClear = false,
                                isLocationInputDisabled = false,
                                onClickPrev,
                                onClickStepName: onClickStepNameProp = noop,
                                onClickDone = noop,
                                step = null,
                                position = null,
                                address = '',
                                title = copies.title,
                                fromWhere = '',
                                isPlaceClicked = false,
                            }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const ghostStep = useSelector(mapSelectors.ghostStep(mapId));
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [customNameOfPlace, setCustomNameOfPlace] = useState(nameOfPlace);
    const [, setStepName] = useState({
        value: isNameInputClear ? '' : nameOfPlace || ghostStep?.title,
        isLoading: false,
        isSearchPopUpOpen: false,
        hideAddButton: true,
    });
    const [location, setLocation] = useState({
        value: isLocationInputClear ? '' : step?.geocode_address?.formatted_address || ghostStep?.address || address,
        isLoading: false,
        isSearchPopUpOpen: false,
    });
    const isDisabled = useMemo(
        () => {
            const ghostStepExists = Boolean(ghostStep?.lat && ghostStep?.lon) || Boolean(ghostStep?.position);
            const hasStepName = Boolean(location.value || customNameOfPlace);

            return !ghostStepExists || !hasStepName;
        }, [customNameOfPlace, ghostStep, location.value]);

    const locationDebounce = useDebounce(location.value, 250);

    const changeLocationLoading = isLoading => {

        setLocation(prevLocation => ({ ...prevLocation, isLoading }));
    };

    const closeLocationSearchPopUp = () => {

        setLocation(prevLocation => ({
            ...prevLocation,
            isSearchPopUpOpen: false,
        }));
    };

    const discardLocationInput = () => {
        mapActions.setGhostStep(mapId, {});
        setSearchValue('');
        setLocation(INPUTS_DEFAULT_VALUE);
    };

    const onClickPlace = async place => {
        // TODO: - important - DO something with placeID
        const result = await getPlaceCoordinates(place);

        mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_NAME_NAME_ADDED, {
            [mixpanelProperties.FLOW]: fromWhere,
            [mixpanelProperties.IS_GOOGLE_LOCATION]: result?.type === 'place',
        });

        if (result.location) {
            updateResultLocation(result);
        } else {
            await getPlaceCoordinates(result);
        }

        const position = result.location || {
            lat: result.lat,
            lng: result.lon,
        };

        onClickStepNameProp();
        setStepName(prevStepName => ({
            ...prevStepName,
            isSearchPopUpOpen: false,
            value: result.title,
        }));

        setLocation(prevLocation => ({
            ...prevLocation,
            value: result.title,
            isSearchPopUpOpen: false,
        }));

        dispatch(
            mapActions.setGhostStep(mapId, {
                position,
                address: result.title,
                type: result.location_id ? 'location' : 'place',
                title: step ? step.title : nameOfPlace,
                suggestToAdd: false,
                isOpenTooltip: false,
                isStickToMap: true,
            })
        );

        dispatch(
            mapActions.setMapCenter(mapId, {
                position,
                zoom: 18,
            })
        );
        setSearchValue('');
    };

    const onClickLocationResult = async result => {
        mixpanel.track(mixpanelEvents.MM_STEP_CREATION_CREATE_NEW_PLACE_LOCATION_ADDED, {
            [mixpanelProperties.FLOW]: 'new_location',
        });

        if (result.location) {
            updateResultLocation(result);
        } else {
            await getPlaceCoordinates(result);
        }

        const position = result.location || {
            lat: result.lat,
            lng: result.lon,
        };


        setLocation(prevLocation => ({
            ...prevLocation,
            value: result.title,
            isSearchPopUpOpen: false,
        }));
        dispatch(
            mapActions.setGhostStep(mapId, {
                position,
                address: result.title,
                type: result.location_id ? 'location' : 'place',
                title: step ? step.title : nameOfPlace,
                suggestToAdd: false,
                isOpenTooltip: false,
                stickToMap: true,
            })
        );

        dispatch(
            mapActions.setMapCenter(mapId, {
                position,
                zoom: 18,
            })
        );

        setSearchValue('');
    };

    const updateResultLocation = result => {
        const resultCoordicnates = result.location;
        result.location = {
            lon: resultCoordicnates[0] || resultCoordicnates.lng,
            lat: resultCoordicnates[1] || resultCoordicnates.lat,
        };
    };

    useEffect(() => {
        if (!step) {
            return;
        }

        dispatch(
            mapActions.setGhostStep(mapId, {
                position: { lat: step.lat, lon: step.lon },
                title: step.title,
                suggestToAdd: false,
                isOpenTooltip: false,
                isStickToMap: true,
            })
        );
    }, [step]);

    return (
        <div className={styles.block}>
            <div className={styles.header} data-testid='back-button'>
                <ArrowLeft className={styles.backButton} size='20' color='#2171EC' onClick={onClickPrev}/>
                <h3 className={styles.title}>{step ? copies.editTitle : title}</h3>
            </div>
            <div className={styles.inputWrapper}>
                {isPlaceClicked && (
                    <EditNameOfPlace
                        stepName={nameOfPlace}
                        onChange={setCustomNameOfPlace}
                    />
                )}
                <h2 className={styles.label}>{copies.location}</h2>
                <SearchInput
                    id='location-input'
                    // value={location.value}
                    position={position}
                    placeholder={copies.where_place_located}
                    loading={location.isLoading && location.value || isLoading}
                    onChange={event => {
                        setSearchValue(event.target.value);
                        setIsLoading(true);
                    }}
                    onClickDiscard={!isLocationInputDisabled && discardLocationInput}
                    disabled={isLocationInputDisabled}
                />
                {searchValue && !isLoading && (
                    <SearchSteps
                        position={position}
                        className={styles.searchPopUp}
                        searchValue={searchValue}
                        ghostStep={ghostStep}
                        searchValueDebounce={locationDebounce}
                        createPlaceBehavior={CreatePlaceBehaviors.HIDE}

                        onLoadingStart={() => {
                            changeLocationLoading(true);
                            setIsLoading(true);
                        }}
                        onLoadingEnd={() => {
                            changeLocationLoading(false);
                            setIsLoading(false);
                        }}

                        onClickArea={onClickLocationResult}
                        onClickPlace={onClickPlace}

                        onCancel={closeLocationSearchPopUp}
                        searchTypes={[SearchTypes.LOCATION]}
                        showActionButtons
                        isUseSearchForStepCreation
                    />
                )}
            </div>
            <CustomButton
                className={styles.nextButton}
                type={ButtonType.PRIMARY}
                onClick={() => onClickDone(customNameOfPlace || ghostStep.title, locationDebounce, ghostStep, fromWhere)}
                disabled={isDisabled}
            >
                {step ? copies.done : copies.next}
            </CustomButton>
        </div>
    );
};

export default EditStepDetailsOld;
