import React, { useRef, useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import styles from './index.module.scss';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import IconEditAccordion from '../../../../components/common/icons/IconEditAccordion';
import CustomButton, { ButtonType, ButtonSize } from '../../../../components/common/buttons/CustomButton';
import useWelcomeMessage, { FromWhere as welcomeMessageFromWHere } from '../../../../components/WelcomeMessage/useWelcomeMessage';
import Switch from '../../../../components/common/buttons/Switch';
import { TextArea, LimiterPosition } from '../../../../components/common/inputs/TextArea';
import copiesPrefix from '../../../../copies.json';
import { ToastContext, ToastTypes } from '../../../../context';

const copies = copiesPrefix.mapSettings.sections.welcomeMessage;

const loadingAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
};

const WelcomeMessage = () => {
    const { setToast } = useContext(ToastContext);
    const { updateWelcomeMessage, welcomeMessage, body, setBody, sendAutomatically, setSendAutomatically } = useWelcomeMessage(
        welcomeMessageFromWHere.MAP_SETTINGS
    );
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [isToggleChanged, setIsToggleChanged] = useState(false);
    const [isBodyChanged, setIsBodyChanged] = useState(false);
    const [isSavePending, setIsSavePending] = useState(false);
    const [initialData, setInitialData] = useState({ body: '', toggle: false });
    const accordionRef = useRef(null);
    
    useEffect(() => {
        if (open) {
            setInitialData({ body: welcomeMessage.body, toggle: welcomeMessage.send_automatically });
            setBody(welcomeMessage.body);
        }
    }, [open]);
    
    const onCancel = clearData => {
        setOpen(prevOpen => !prevOpen);
        if (clearData) {
            setBody(initialData.body);
            setSendAutomatically(isToggleChanged ? !sendAutomatically : sendAutomatically);
            setIsBodyChanged(false);
            setIsToggleChanged(false);
        }
        setIsSavePending(false);
        setIsBodyChanged(false);
        setIsToggleChanged(false);
    };
    
    const onSave = async () => {
        if (isSavePending) return;
        setIsSavePending(true);
        try {
            await updateWelcomeMessage({
                body,
                sendAutomatically,
            });
            setToast({ text: copies.updated });
            onCancel();
        } catch {
            setToast({ text: copies.failed, type: ToastTypes.ERROR });
            setIsSavePending(false);
        }
    };
    
    return (
        <div
            onMouseOver={() => !open && setHover(true)}
            onMouseLeave={() => !open && setHover(false)}
            className={classNames(styles.accordionContainer, {
                [styles.hovered]: hover,
                [styles.active]: open,
            })}
        >
            <Accordion
                onChange={() => {
                    setHover(false);
                    onCancel(open);
                }}
                className={styles.accordionBlock}
                expanded={open}
            >
                <AccordionSummary
                    ref={accordionRef}
                    className={classNames(styles.accordionContent)}
                    expandIcon={open ? null : <IconEditAccordion/>}
                    aria-controls='panel-welcomeMessage'
                    id='panel-welcomeMessage'
                >
                    <Typography className={styles.accordionHeading}>{copies.title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                    <div className={styles.contentWrapper}>
                        <p className={styles.description}>{copies.description}</p>
                        <TextArea
                            isActive={open}
                            className={styles.textArea}
                            inputClassName={styles.input}
                            maxLength={120}
                            withOutline={false}
                            limiterPosition={LimiterPosition.INLINE}
                            placeholder={copies.input_placeholder}
                            onChange={body => {
                                setBody(body);
                                setIsBodyChanged(body !== welcomeMessage.body);
                            }}
                            value={body}
                        />
                        <Switch
                            className={styles.switchContainer}
                            text={copies.auto_add_toggle}
                            onChange={status => {
                                setSendAutomatically(status);
                                setIsToggleChanged(status !== welcomeMessage.send_automatically);
                            }}
                            isSwitchOn={sendAutomatically}
                        />
                        <div className={styles.footer}>
                            <CustomButton onClick={() => onCancel(true)} className={styles.cancelBtn} type={ButtonType.TERTIARY_BLACK}>
                                {copies.cancel}
                            </CustomButton>
                            <CustomButton
                                onClick={onSave}
                                size={ButtonSize.MEDIUM}
                                type={ButtonType.PRIMARY_BLUE}
                                className={styles.saveButton}
                                disabled={!isToggleChanged && !isBodyChanged}
                                text={
                                    isSavePending ? (
                                        <Lottie
                                            options={loadingAnimationOptions}
                                            isClickToPauseDisabled
                                            isStopped={false}
                                            isPaused={false}
                                            width={30}
                                            height={30}
                                        />
                                    ) : (
                                        copies.save_button
                                    )
                                }
                            ></CustomButton>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default WelcomeMessage;
