import { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { noop } from 'lodash';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import { usePortal } from '../../../hooks';
import Menu from './Menu';
import MoreButton from '../buttons/MoreButton';
import { DropdownMenuContext } from './DropdownMenuContext';

import styles from './DropdownMenu.module.scss';

export const MenuSize = {
    Small: { value: 96, name: 'small' },
    Medium: { value: 128, name: 'medium' },
    NatiSize: { value: 160, name: 'nati' },
    Large: { value: 200, name: 'large' },
    ExtraLarge: { value: 235, name: 'extra-large' },
};

const getXOffScreenPixels = (x, width) => {
    if (x - width < 0) return x - width;
    if (x + width > window.innerWidth) return window.innerWidth - x + width;
    return 0;
};

const DropdownMenu = ({
    options = [],
    preventDropdownCloseOnClickAway = false,
    onClickAway = noop,
    onClick = noop,
    className,
    menuClassName,
    menuSize = MenuSize.Medium,
    buttonSize = 22,
}) => {
    const uuid = useMemo(uuidv4, []);
    const Portal = usePortal('#dropdown-menu-root');
    const { activeDropdownId, setActiveDropdownId } = useContext(DropdownMenuContext);
    const [style, setStyle] = useState({});
    const dropdownMenuRef = useRef(null);
    const isOpen = useMemo(() => activeDropdownId === uuid, [activeDropdownId, uuid]);
    
    const handleScroll = () => {
        setActiveDropdownId(null);
    };
    
    const updatePosition = () => {
        const offset = dropdownMenuRef.current?.getBoundingClientRect();
        const top = offset?.top;
        let left = offset?.left + menuSize.value / 2 + offset?.width / 2;
        if (!top || !left) {
            return;
        }
        
        const offScreenPixels = getXOffScreenPixels(left, menuSize.value);
        if (offScreenPixels > 0) left = left + offset.width - offScreenPixels;
        if (offScreenPixels < 0) left = offset.width + menuSize.value;
        
        setStyle({ top, left });
    };
    
    // TODO: display none until this hook finishes
    useEffect(() => {
        if (!dropdownMenuRef.current) {
            return;
        }
        
        updatePosition();
    }, [dropdownMenuRef, menuSize, isOpen]);
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return window.removeEventListener('scroll', handleScroll);
    }, []);
    
    
    // TODO: Warning: unmountComponentAtNode(): The node you're attempting to unmount was rendered by React and is not a top-level container. You may
    // have accidentally passed in a React root node instead of its container.
    return (
        <>
            <MoreButton
                stopPropagation
                className={className}
                size={buttonSize}
                opened={isOpen}
                onClick={() => {
                    setActiveDropdownId(uuid === activeDropdownId ? null : uuid);
                    onClick();
                }}
            />
            <ClickAwayListener
                onClickAway={() => {
                    onClickAway();
                    if (preventDropdownCloseOnClickAway) {
                        return;
                    }
                    setActiveDropdownId(null);
                }}
            >
                <div
                    role='listbox'
                    className={classNames(styles.menuWrapper, className)}
                    ref={dropdownMenuRef}>
                    <Portal>
                        <div
                            style={{ top: style.top, left: style.left }}
                            className={classNames(styles.menu, menuClassName)}>
                            <Menu
                                isOpen={isOpen}
                                items={options.filter(Boolean)}
                                className={styles[menuSize.name]}
                                onItemClick={() => {
                                    onClick();
                                    setActiveDropdownId(null);
                                }}
                            />
                        </div>
                    </Portal>
                </div>
            </ClickAwayListener>
        </>
    );
};

export default DropdownMenu;
