import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import DragAndDrop from './DragAndDrop';
import { generateImageUrl, getUploadMapImgLink, uploadImageToS3 } from '../../../../helpers';
import { mapActions, mapSelectors } from '../../../../redux/map';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import { ClickAwayListener } from '@material-ui/core';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.addImage;

const TYPE_TASKS_2 = {
    MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS: 'add_steps',
    MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS: 'add_tags',
    MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_IMAGE: 'add_image',
    MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_DESCRIPTION: 'add_description',
    MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP: 'share',
};
const TYPE_TASKS = [
    'MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS',
    'MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS',
    'MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_IMAGE',
    'MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_DESCRIPTION',
    'MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP',
];

const ModalsAddImage = ({ percent, onClose, mapId }) => {
    const dispatch = useDispatch();
    const mapImage = useSelector(mapSelectors.map(mapId));
    const map = useSelector(mapSelectors.mapSelector(mapId));
    const guideTaskCompleted = useSelector(mapSelectors.guideTaskCompleted(mapId));
    const [imageLoader, setImageLoader] = useState(false);
    const [file, setFile] = useState(false);
    const [disableImage, setDisableImage] = useState(true);
    const [disable, setDisable] = useState(true);
    const [loaderButton, setLoaderButton] = useState(false);
    const [hovered, setHovered] = useState(false);
    const coverChangeImageInput = useRef(null);
    const coverChangeImageInput2 = useRef(null);

    useLayoutEffect(() => {
        coverChangeImageInput2.current?.addEventListener('change', onLoadFiles);
        coverChangeImageInput.current?.addEventListener('change', onLoadFiles);
        return () => {
            coverChangeImageInput2.current?.removeEventListener('change', onLoadFiles);
            coverChangeImageInput.current?.removeEventListener('change', onLoadFiles);
        };
    });

    useEffect(() => {
        if (map?.image) {
            setDisableImage(false);
            setImageLoader(true);
        }
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    const onLoadFiles = e => {
        setImageLoader(true);
        setDisable(false);
        setDisableImage(false);
        setFile(e?.target?.files ? e.target.files[0] : e[0]);
    };
    const handleSaveImage = async item => {
        setLoaderButton(true);
        setDisable(true);
        setDisableImage(true);
        const data = await getUploadMapImgLink(map?._id);
        const listCompleted = guideTaskCompleted && TYPE_TASKS.filter(e => guideTaskCompleted[e] === true).map(key => TYPE_TASKS_2[key]);
        uploadImageToS3({
            uploadUrl: data.url,
            file: item,
            onUploadedFile: () => {
                dispatch(mapActions.setMapCoverImage(map?._id, URL.createObjectURL(item)));
                dispatch(mapActions.setGuideTaskComplete(map?._id, 'MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_IMAGE'));
                mixpanel.track(mixpanelEvents.PAR_ADD_IMAGE_SUBMIT, {
                    [mixpanelProperties.FROM_WHERE]: 'parallel_guide',
                });
                setLoaderButton(false);
                setDisableImage(false);
                setDisable(false);
                onClose();
            },
        });
    };

    return (
        <div className={styles.modals}>
            <ClickAwayListener onClickAway={onClose}>
                <div className={styles.block}>
                    <div className={styles.modalsHeader}>
                        <div onClick={onClose} className={styles.closeBlock}>
                            <img src='/assets/img/icon_cancel_blue.svg' />
                        </div>
                    </div>
                    <div className={styles.modalsBody}>
                        <div className={styles.titleText}>{copies.title}</div>
                        <div className={styles.titleTextSecond}>{copies.subtitle}</div>
                        <div className={styles.modalsImageDrop}>
                            <DragAndDrop imageLoader={imageLoader} onDrop={files => onLoadFiles(files)}>
                                {imageLoader ? (
                                    <div className={styles.img}>
                                        <img
                                            onMouseOver={() => setHovered(true)}
                                            src={
                                                file
                                                    ? URL.createObjectURL(file)
                                                    : mapImage?.image_temp
                                                    ? mapImage?.image_temp
                                                    : generateImageUrl({
                                                          imageId: map?.image,
                                                          width: 266,
                                                          height: 266,
                                                          reason: 'get_image_for_modal',
                                                      })
                                            }
                                            alt=''
                                        />
                                        {hovered && (
                                            <div onMouseLeave={() => setHovered(false)} className={styles.hoveredBlock}>
                                                <button disabled={disableImage} onClick={() => coverChangeImageInput.current.click()}>
                                                    {copies.change_image_button}
                                                </button>
                                                <input
                                                    style={{
                                                        opacity: '0',
                                                        top: '0',
                                                        position: 'absolute',
                                                        zIndex: -1,
                                                    }}
                                                    accept={['image/png', 'image/jpeg', 'image/jpg']}
                                                    type='file'
                                                    ref={coverChangeImageInput}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        <img className={styles.imgEmpty} alt={''} src={'/assets/img/steps3Images/dropImage.svg'} />
                                        <p className={styles.text}>{copies.drag_and_drop}</p>
                                        <div className={styles.button}>
                                            <CustomButton
                                                className={styles.buttonSave}
                                                onClick={() => coverChangeImageInput2.current.click()}
                                                type={ButtonType.PRIMARY}
                                                disabled={disable}
                                                text={'Upload'}
                                            />
                                            <input
                                                style={{
                                                    top: '0',
                                                    opacity: '0',
                                                    position: 'absolute',
                                                    zIndex: -1,
                                                }}
                                                accept={['image/png', 'image/jpeg', 'image/jpg']}
                                                type='file'
                                                ref={coverChangeImageInput2}
                                            />
                                        </div>
                                    </>
                                )}
                            </DragAndDrop>
                        </div>
                    </div>
                    <div className={styles.modalsFooter}>
                        <CustomButton className={styles.buttonSave} onClick={() => handleSaveImage(file)} type={ButtonType.PRIMARY} disabled={disable}>
                            {loaderButton ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={35} height={35} /> : copies.save_button}
                        </CustomButton>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default ModalsAddImage;
