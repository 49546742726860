import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const ConfirmLocationPopup = ({ onConfirm, className, title, onCancel }) => {
    return (
        <div
            className={classNames([styles.block, className])}
            onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <img className={styles.cancel} src={'/assets/img/steps3Images/x.svg'} onClick={onCancel} />
            <span className={styles.title}>{title}</span>
            <button onClick={onConfirm} className={styles.btnConfirm}>
                Confirm
            </button>
        </div>
    );
};

export default ConfirmLocationPopup;
