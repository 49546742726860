import React from 'react';
import styles from './index.module.scss';
import copiesPrefix from '../../../../../../copies.json';
import CustomButton, { ButtonType, ButtonSize } from '../../../../../../components/common/buttons/CustomButton';

const copies = copiesPrefix.listView;

const NoStepsMatch = ({ onClickClearFilters, isSearchValue = true }) => (
    <div className={styles.block}>
        {!isSearchValue ? (
            <>
                <span className={styles.text}>{copies.no_steps}</span>
                <CustomButton type={ButtonType.SECONDARY} size={ButtonSize.MEDIUM} text={copies.clear_filters} onClick={onClickClearFilters} />
            </>
        ) : (
            <>
                <img alt='' src='/assets/img/noResults.svg' />
                <h3 className={styles.boldText}>{copies.no_steps_to_show}</h3>
                <p>{copies.no_match}</p>
            </>
        )}
    </div>
);

export default NoStepsMatch;
