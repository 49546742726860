import CustomButton from '../../components/common/buttons/CustomButton';
import { CopySimple } from '../../components/common/icons';
import { useCallback, useMemo } from 'react';
import { useToast } from '../../hooks';
import mixpanel, { mixpanelEvents } from "../../helpers/mixpanel";

const copyToClipboard = async (text, onSuccess, onError) => {
  return navigator.clipboard
    .writeText(text)
    .then((clipText) => {
      onSuccess(clipText);
    })
    .catch((err) => {
      onError(err);
    });
};
export const AskAroundLinkBox = ({ link, mapId, mapName }) => {
  const { setToast } = useToast();
  const url = useMemo(() => {
    return new URL(link);
  }, [link]);
  
  const copyUrl = useCallback(() => {
    mixpanel.track(mixpanelEvents.ASK_AROUND_COPY_LINK_CLICKED);
    
    return copyToClipboard(
      url.href,
      () => setToast({ id: 'Link copied to clipboard.' }),
      () => setToast({ id: 'Something went wrong. Please try again.' })
    );
  }, [url]);
  
  return (
    <div className='bg-white rounded-lg shadow-lg p-3 flex flex-col justify-between items-center text-center'>
      <div className='tracking-wide text-sm'>
        <h2 className='text-base font-semibold text-blue-900 m-0'>
          Here’s your link
        </h2>
        <p className='m-0 px-8'>
          Share this link on your socials to start collecting recs!
        </p>
      </div>
      <a
        href={url.href}
        target="_blank"
        className='my-4 text-blue-700 no-underline w-full text-center text-sm'>
        {url.origin + url.pathname}
      </a>
      <CustomButton
        onClick={copyUrl}
        className="w-full"
        type={CustomButton.Types.ATLY_PRIMARY}
        size={CustomButton.Sizes.NARROW}>
        <span className="text-white mr-2">Copy link</span>
        <CopySimple color='white'/>
      </CustomButton>
    </div>
  );
};
