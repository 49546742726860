import React, { useCallback, useLayoutEffect, useRef, useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import styles from './index.module.scss';
import IconEditAccordion from '../../../../components/common/icons/IconEditAccordion';
import { blobUrl, generateImageUrl, getUploadMapImgLink, uploadImageToS3 } from '../../../../helpers';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import { mapActions } from '../../../../redux/map';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import { userActionActions, userActionSelectors } from '../../../../redux/userAction';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { CHANGES_SAVED_TEXT, ToastContext, ToastDurations } from '../../../../context/ToastContext';

const ACCEPT_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

const MapImage = ({ onClick, setClose, map }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const goto = useSelector(userActionSelectors.goto);
    const [hover, setHover] = useState(false);
    const [hoverImage, setHoverImage] = useState(false);
    const fileInput = useRef(null);
    const accordionRef = useRef(null);
    const [file, setFile] = useState(null);
    const [disabled, setDisable] = useState(true);
    const [loader, setLoader] = useState(false);
    const { setToast } = useContext(ToastContext);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    useEffect(() => {
        if (!goto) return;
        if (goto == 'image') {
            setHover(false);
            setOpen(true);
        }
        dispatch(userActionActions.goto(null));
    }, [goto]);

    useLayoutEffect(() => {
        fileInput.current?.addEventListener('change', changeMapCoverPhoto);
        return () => {
            fileInput.current?.removeEventListener('change', changeMapCoverPhoto);
        };
    });

    const changeMapCoverPhoto = e => {
        const [file] = e?.target?.files;
        setFile({ file });
        setDisable(false);
    };

    // const browse = useCallback(async (e) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     const [file] = e?.target?.files;
    //     setFile({file});
    //     setDisable(false);
    // }, [dispatch, map]);

    const onSave = file => {
        setLoader(true);
        mixpanel.track(mixpanelEvents.PAR_ADD_IMAGE_SUBMIT, {
            [mixpanelProperties.FROM_WHERE]: 'map_settings',
        });
        getUploadMapImgLink(map?._id).then(data => {
            uploadImageToS3({
                uploadUrl: data.url,
                file: file,
                onUploadedFile: () => {
                    dispatch(mapActions.setMapCoverImage(map._id, URL.createObjectURL(file)));
                    accordionRef.current.click();
                    setDisable(true);
                    setLoader(false);
                    setFile(null);
                    setToast({
                        text: CHANGES_SAVED_TEXT,
                        timeout: ToastDurations.SHORT,
                    });
                },
            });
        });
    };

    const onCancel = useCallback(() => {
        accordionRef.current.click();
        if (!disabled) {
            setFile(null);
        }
        setDisable(true);
    }, [disabled]);

    return (
        <div
            onMouseOver={() => !open && setHover(true)}
            onMouseLeave={() => !open && setHover(false)}
            className={classNames(styles.accordionContainer, {
                [styles.hovered]: hover,
                [styles.active]: open,
            })}
        >
            <Accordion
                onChange={() => {
                    setHover(false);
                    setOpen(!open);
                }}
                className={styles.accordionBlock}
                expanded={open}
            >
                <AccordionSummary
                    ref={accordionRef}
                    className={classNames(styles.accordionContent)}
                    expandIcon={open ? null : <IconEditAccordion />}
                    aria-controls='panel-mapImage'
                    id='panel-mapImage'
                >
                    <Typography className={styles.accordionHeading}>Image</Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                    <div onMouseOver={() => setHoverImage(true)} onMouseLeave={() => setHoverImage(false)} className={styles.imageBlock}>
                        {hoverImage && (
                            <div className={styles.hoverBlock}>
                                <CustomButton
                                    className={styles.editBtn}
                                    onClick={() => {
                                        mixpanel.track(mixpanelEvents.PAR_ADD_IMAGE_CLICK, {
                                            [mixpanelProperties.FROM_WHERE]: 'map_settings',
                                        });
                                        fileInput.current.click();
                                    }}
                                    type={ButtonType.BLACK}
                                >
                                    Edit Image
                                </CustomButton>
                                <input
                                    style={{
                                        display: 'none',
                                        position: 'absolute',
                                    }}
                                    accept={ACCEPT_TYPES}
                                    type='file'
                                    ref={fileInput}
                                />
                            </div>
                        )}
                        {file ? (
                            <img src={blobUrl(file?.file)} alt='' />
                        ) : (
                            <img
                                src={
                                    map?.image_temp ||
                                    generateImageUrl({
                                        imageId: map?.image,
                                        width: 243,
                                        height: 242,
                                        reason: 'show_map_image',
                                    })
                                }
                                alt=''
                            />
                        )}
                    </div>
                    <div className={styles.accordionFooter}>
                        <CustomButton className={styles.cancelBtn} onClick={() => onCancel()} type={ButtonType.TERTIARY_BLACK}>
                            Cancel
                        </CustomButton>
                        <CustomButton
                            className={styles.saveBtn}
                            disabled={disabled}
                            onClick={() => onSave(file?.file)}
                            type={ButtonType.PRIMARY}
                            text={loader ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={40} height={40} /> : 'Save'}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default MapImage;
