import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, useContext } from 'react';
import styles from './index.module.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import IconEditAccordion from '../../../../components/common/icons/IconEditAccordion';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { mapActions } from '../../../../redux/map';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import { useDispatch } from 'react-redux';
import config from '../../../../config';
import Lottie from 'react-lottie';
import Switch from '../../../../components/common/buttons/Switch';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { ToastContext, ToastDurations, CHANGES_SAVED_TEXT } from '../../../../context/ToastContext';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.mapSettings.sections.permissions;

const findIsAskPermissionGate = permissionGates => {
    return permissionGates.find(obj => obj.type === 'ADD_STEP_TO_MAP')?.isAsk;
};

const MapPermissions = ({ onClick, setClose, map }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [findValue, setFindValue] = useState(map?.is_secret);
    const permissionGates = map?.permissions_gates || [];
    const [membersValue, setMembersValue] = useState(map?.gates_statuses?.ALLOW_EDIT || findIsAskPermissionGate(permissionGates));
    const accordionRef = useRef(null);
    const [disabled, setDisable] = useState(true);
    const [loader, setLoader] = useState(false);
    const [isStepsApproveByAdmin, setIsStepsApproveByAdmin] = useState(!map?.gates_statuses?.ALLOW_EDIT && findIsAskPermissionGate(permissionGates));
    const { setToast } = useContext(ToastContext);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    useEffect(() => {
        setFindValue(map?.is_secret);
        setMembersValue(map?.gates_statuses?.ALLOW_EDIT || findIsAskPermissionGate(permissionGates));
        setIsStepsApproveByAdmin(!map?.gates_statuses?.ALLOW_EDIT && findIsAskPermissionGate(permissionGates));
    }, [map]);

    const handleChangeFindMap = useCallback(
        value => {
            setFindValue(value);
        },
        [membersValue, map]
    );

    useLayoutEffect(() => {
        if (isStepsApproveByAdmin !== (!map?.gates_statuses?.ALLOW_EDIT && findIsAskPermissionGate(permissionGates))) setDisable(false);
        else if (findValue !== map?.is_secret) {
            setDisable(false);
        } else if (membersValue !== (map?.gates_statuses?.ALLOW_EDIT || findIsAskPermissionGate(permissionGates))) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [isStepsApproveByAdmin, membersValue, findValue]);

    const handleChangeMembers = useCallback(
        value => {
            if (!value) setIsStepsApproveByAdmin(false);
            setMembersValue(value);
        },
        [findValue, map]
    );

    const onCancel = useCallback(() => {
        accordionRef.current.click();
        setFindValue(map?.is_secret);
        setMembersValue(map?.gates_statuses?.ALLOW_EDIT || findIsAskPermissionGate(permissionGates));
        setDisable(true);
    }, [map]);

    const onSave = () => {
        const requestArray = [];
        const accessToken = localStorage.getItem('at');
        mixpanel.track(mixpanelEvents.MODIFY_MAP_PERMISSIONS, {
            [mixpanelProperties.CAN_FIND]: findValue ? 'private' : 'public',
            [mixpanelProperties.CAN_ADD]: membersValue ? 'community' : 'guide',
        });
        if (findValue !== map?.is_secret) {
            const isSecret = fetch(config.domain.address + `/map/${map?._id}/secret`, {
                method: 'PUT',
                body: JSON.stringify({ is_secret: findValue }),
                headers: {
                    Authorization: accessToken,
                    app_version: config.version.path,
                    app_platform: 'map_manager',
                },
            }).then(res => res.json());
            requestArray.push(isSecret);
            dispatch(mapActions.changeSecretMap(map?._id, { is_secret: findValue }));
        }
        if (!isStepsApproveByAdmin) {
            const permission = fetch(config.domain.address + `/map/${map?._id}/permissions`, {
                method: 'PUT',
                body: JSON.stringify({
                    gates_statuses: [{ type: 'ALLOW_EDIT', to_enable: membersValue }],
                }),
                headers: {
                    Authorization: accessToken,
                    app_version: config.version.path,
                    app_platform: 'map_manager',
                },
            }).then(res => res.json());
            requestArray.push(permission);
            dispatch(mapActions.changePermissionsMap(map?._id, membersValue));
        }
        setLoader(true);
        dispatch(
            mapActions.changeGatesStatuses(map?._id, [
                {
                    type: 'ALLOW_STEPS_SUGGESTIONS',
                    to_enable: isStepsApproveByAdmin,
                },
                {
                    type: 'ALLOW_EDIT',
                    to_enable: isStepsApproveByAdmin ? false : membersValue,
                },
            ])
        );

        setLoader(false);
        accordionRef.current.click();
        setDisable(true);
        setToast({ text: CHANGES_SAVED_TEXT, timeout: ToastDurations.SHORT });
    };

    return (
        <div
            onMouseOver={() => !open && setHover(true)}
            onMouseLeave={() => !open && setHover(false)}
            className={classNames(styles.accordionContainer, {
                [styles.hovered]: hover,
                [styles.active]: open,
            })}
        >
            <Accordion
                onChange={() => {
                    if (open) {
                        onClick(true);
                        setTimeout(() => {
                            setClose(true);
                        }, 100);
                        setTimeout(() => {
                            onClick(false);
                            setClose(false);
                        }, 1100);
                    } else {
                        onClick(false);
                        setClose(false);
                    }
                    setHover(false);
                    setOpen(!open);
                }}
                className={styles.accordionBlock}
            >
                <AccordionSummary
                    ref={accordionRef}
                    className={styles.accordionContent}
                    expandIcon={open ? null : <IconEditAccordion />}
                    aria-controls='panel-mapPermissions'
                    id='panel-mapPermissions'
                >
                    <Typography className={styles.accordionHeading}>
                        Map permissions{' '}
                        {!open && (
                            <span className={styles.type}>
                                {map?.is_secret ? copies.private_map : copies.public_map} |{' '}
                                {map?.gates_statuses?.ALLOW_EDIT || map?.gates_statuses?.ALLOW_STEPS_SUGGESTIONS ? copies.community : copies.guide}
                            </span>
                        )}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                    <Grid item>
                        <p className={styles.detailsTitle}>{copies.privacySection.title}</p>
                        <div className={classNames(styles.chooseSection)}>
                            <div className={styles.newRadioBlock} onClick={() => handleChangeFindMap(false)}>
                                <input checked={!findValue} name='find' type='radio' />
                                <div
                                    className={classNames(styles.newRadio, {
                                        [styles.newRadioActive]: !findValue,
                                    })}
                                >
                                    {!findValue && <div className={styles.checked} />}
                                </div>
                            </div>
                            <div className={styles.radioLabel} onClick={() => handleChangeFindMap(false)}>
                                <p className={styles.typeName}>{copies.privacySection.options.public.title}</p>
                                <p className={classNames(styles.typeDescription, styles.gray)}>{copies.privacySection.options.public.description}</p>
                            </div>
                        </div>
                        <div className={classNames(styles.chooseSection)}>
                            <div className={styles.newRadioBlock} onClick={() => handleChangeFindMap(true)}>
                                <input checked={findValue} name='find' type='radio' />
                                <div
                                    className={classNames(styles.newRadio, {
                                        [styles.newRadioActive]: findValue,
                                    })}
                                >
                                    {findValue && <div className={styles.checked} />}
                                </div>
                            </div>
                            <div className={styles.radioLabel} onClick={() => handleChangeFindMap(true)}>
                                <p className={styles.typeName}>{copies.privacySection.options.private.title}</p>
                                <p className={classNames(styles.typeDescription, styles.gray)}>{copies.privacySection.options.private.description}</p>
                            </div>
                        </div>
                        <hr className={styles.hr} />
                    </Grid>
                    <Grid item>
                        <p className={styles.detailsTitle}>{copies.mapTypeSection.title}</p>
                        <div className={classNames(styles.chooseSection)}>
                            <div className={styles.newRadioBlock} onClick={() => handleChangeMembers(true)}>
                                <input checked={membersValue} name='members' type='radio' />
                                <div
                                    className={classNames(styles.newRadio, {
                                        [styles.newRadioActive]: membersValue,
                                    })}
                                >
                                    {membersValue && <div className={styles.checked} />}
                                </div>
                            </div>
                            <div className={styles.radioLabel} onClick={() => handleChangeMembers(true)}>
                                <p className={styles.typeName}>{copies.mapTypeSection.options.community.title}</p>
                                <p className={styles.typeDescription}>{copies.mapTypeSection.options.community.description}</p>
                                <div className={styles.allowStrepsSuggestionsContainer}>
                                    <Switch isSwitchOn={isStepsApproveByAdmin} onChange={status => setIsStepsApproveByAdmin(status)} />
                                    <span className={styles.typeDescription} onClick={() => setIsStepsApproveByAdmin(!isStepsApproveByAdmin)}>
                                        {copies.mapTypeSection.options.community.approve_toggle}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.radioLabel}></div>
                        <div className={classNames(styles.chooseSection)}>
                            <div className={styles.newRadioBlock} onClick={() => handleChangeMembers(false)}>
                                <input checked={!membersValue} name='members' type='radio' />
                                <div
                                    className={classNames(styles.newRadio, {
                                        [styles.newRadioActive]: !membersValue,
                                    })}
                                >
                                    {!membersValue && <div className={styles.checked} />}
                                </div>
                            </div>
                            <div className={styles.radioLabel} onClick={() => handleChangeMembers(false)}>
                                <p className={styles.typeName}>{copies.mapTypeSection.options.guide.title}</p>
                                <p className={styles.typeDescription}>{copies.mapTypeSection.options.guide.description}</p>
                            </div>
                        </div>
                    </Grid>
                    <div className={styles.accordionFooter}>
                        <CustomButton className={styles.cancelBtn} onClick={() => onCancel()} type={ButtonType.TERTIARY_BLACK}>
                            {copies.cancel_button}
                        </CustomButton>
                        <CustomButton
                            className={styles.saveBtn}
                            disabled={disabled}
                            onClick={() => onSave()}
                            type={ButtonType.PRIMARY}
                            text={loader ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={40} height={40} /> : copies.save_button}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default MapPermissions;
