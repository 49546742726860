export const isAuthSelector = state => state.user.isAuth;
export const isAuthErrorSelector = state => state.user.isAuthError;
export const locationSelector = state => state.user.location;
export const user = state => state.user;
export const selectedMapId = state => state.user.selectedMapId;
export const isAuth = state => state.user.isAuth;
export const requestMessageSelector = state => state.user.requestMessage;
export const maps = state => state.user.maps;
export const userImageTemp = state => state.user.user_image_temp;
export const whatsNew = state => state.user.whatsNew;
