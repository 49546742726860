import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, mapSelectors } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import EntryPoint from './EntryPage';
import AddStepForm from './AddStepForm';
import { EditStepDetails } from '../EditStepDetails';
import TagsAndRatings from '../TagsAndRatings';
import { DropPinView, expandSidebar, minimizeSidebar } from './components/DropPinView';
import { generatePath, useLocation, useNavigate, useOutletContext } from 'react-router';
import Routes from '../../config/routes';
import { userActionActions } from '../../redux/userAction';
import copiesPrefix from '../../copies.json';
import { getPlaceCoordinates } from '../../helpers';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import EmptySideBar from './components/DropPinView/EmptySideBar';

const copies = copiesPrefix.addStep;

export const AddPlaceSteps = {
  ENTRY_PAGE: 0,
  ADD_STEP_FORM: 1,
  EDIT_STEP_DETAILS: 2,
  DROP_PIN: 3,
  LOCATION_FROM_PHOTO: 4,
  TAGS_AND_RATINGS: 5,
  EMPTY_SIDE_BAR: 6,
};

const AddPlaceFlow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const flowStepId = state?.flowStepId;
  const { setIsMapClean, setDisableClickOnMap: isDisableClickOnMap } = useOutletContext();
  const mapId = useSelector(userSelectors.selectedMapId);
  const mapName = useSelector(mapSelectors.mapName(mapId));
  const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
  const [currentStepInFlow, setCurrentStepInFlow] = useState({
    step: flowStepId || AddPlaceSteps.ENTRY_PAGE,
    params: state?.params || {},
  });
  const [previousSteps, setPreviousSteps] = useState([]);
  const ghostStep = useSelector(mapSelectors.ghostStep(mapId));
  
  useEffect(() => {
    isDisableClickOnMap(true);
    setIsMapClean(true);
    
    return () => {
      setCurrentStepInFlow(AddPlaceSteps.ENTRY_PAGE);
      setIsMapClean(false);
      expandSidebar();
    };
  }, []);
  
  const changeStepInFlow = (nextStep, paramsForCurrentStep) => {
    setPreviousSteps([...previousSteps, {
      ...currentStepInFlow,
      params: { ...currentStepInFlow.params, ...paramsForCurrentStep },
    }]);
    setCurrentStepInFlow(nextStep);
  };
  
  const onClickPlace = async place => {
    if (!place?.location?.coordinates) {
      mixpanel.track(mixpanelEvents.MM_STEP_CREATION_SEARCH_LOCATION_CHOOSEN, {
        [mixpanelProperties.FLOW]: 'google_location',
      });
    }
    
    isDisableClickOnMap(true);
    
    if (place.result_type === 'address') {
      changeStepInFlow({
        step: AddPlaceSteps.EDIT_STEP_DETAILS,
        params: {
          nameOfPlace: '',
          isPlaceClicked: true,
          fromWhere: 'google_location',
        },
      });
    } else {
      changeStepInFlow({
        step: AddPlaceSteps.ADD_STEP_FORM,
        params: {
          fromWhere: 'google_location',
        },
      });
    }
  };
  
  const onClickCreatePlace = searchValue => {
    const newAddStepTitle = copies.create_place_for.replace('{placeName}', searchValue);
    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_STEP_SEARCH_NEW_PLACE_CHOOSEN, {
      [mixpanelProperties.FLOW]: 'new_location',
    });
    
    changeStepInFlow({
      step: AddPlaceSteps.EDIT_STEP_DETAILS,
      params: {
        nameOfPlace: searchValue,
        isPlaceClicked: true,
        isCreateAPlaceClicked: true,
        title: newAddStepTitle,
        fromWhere: 'new_location',
      },
    });
  };
  
  const onClickArea = area => {
    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_SEARCH_ADDRESS_CHOOSEN, {
      [mixpanelProperties.FLOW]: 'address',
    });
    dispatch(
        mapActions.setGhostStep(mapId, {
          position: area.location,
          address: area.description,
          type: area.location_id ? 'location' : 'place',
          title: area.title,
          isStickToMap: true,
        }),
    );
    isDisableClickOnMap(true);
    changeStepInFlow({
      step: AddPlaceSteps.EDIT_STEP_DETAILS,
      params: {
        isLocationInputDisabled: true,
        isNameInputClear: true,
        fromWhere: 'address',
      },
    });
  };
  
  const changeToTagsAndRatings = step => {
    dispatch(mapActions.getFullStepData(mapId, step._id));
    changeStepInFlow({ step: AddPlaceSteps.TAGS_AND_RATINGS, params: { step } });
  };
  
  const onLocationFromPhotoSuccess = () => {
    isDisableClickOnMap(true);
    changeStepInFlow({ step: AddPlaceSteps.EMPTY_SIDE_BAR });
    minimizeSidebar();
  };
  
  const onBack = () => {
    const newArray = [...previousSteps];
    const hasPreviousStep = newArray.pop();
    
    if (!hasPreviousStep) {
      dispatch(mapActions.setGhostStep(mapId, null));
      onClose();
      return;
    }
    
    setCurrentStepInFlow(hasPreviousStep);
    setPreviousSteps(newArray);
  };
  
  const navigateToStepProfile = ({ _id }) => {
    const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId: _id });
    navigate(`/${Routes.MAP}`); // Prevent navigate to addStep on stepProfile backButton click
    navigate(url);
  };
  
  const onClose = (options = {}) => {
    const { disableNavigateBack } = options;
    if (!disableNavigateBack) {
      navigate(-1);
    }
    dispatch(userActionActions.setSelectedPlaceToAdd(null));
    setIsMapClean(false);
    expandSidebar();
  };
  
  const onAddStepMiniFlowFinished = async (place, fromWhere) => {
    let ghostStepParams;
    
    if (place?.place_id) {
      place = await getPlaceCoordinates(place);
      const { location, title } = place;
      ghostStepParams = { position: location, title };
    } else {
      const { position, location, title } = place;
      ghostStepParams = {
        position: position || {
          lat: location?.coordinates[1],
          lng: location?.coordinates[0],
        },
        title,
      };
    }
    
    const { secondary_title, address } = place;
    const subTitle = secondary_title || address;
    
    ghostStepParams = { ...ghostStepParams, isStickToMap: true, subTitle };
    await dispatch(mapActions.setGhostStep(mapId, ghostStepParams));
    dispatch(
        mapActions.setMapCenter(mapId, {
          ...mapCenter,
          zoom: 10,
          position: ghostStepParams.position,
        }),
    );
    
    changeStepInFlow({
      step: AddPlaceSteps.ADD_STEP_FORM,
      params: {
        isFromDropPin: true,
        isLocationInputDisabled: true,
        previousFlowStage: AddPlaceSteps.DROP_PIN,
        place,
        fromWhere,
      },
    });
    isDisableClickOnMap(true);
    expandSidebar();
  };
  
  const stepper = {
    [AddPlaceSteps.ENTRY_PAGE]: params => (
        <EntryPoint
            onClickPlace={onClickPlace}
            onClickCreatePlace={onClickCreatePlace}
            onClickArea={onClickArea}
            onClickStep={navigateToStepProfile}
            onLocationFromPhotoSuccess={onLocationFromPhotoSuccess}
            onLocationFromPhotoClicked={() => {
              mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_STEP_PHOTO_LOCATION_CLICKED, {
                [mixpanelProperties.MAP_ID]: mapId,
                [mixpanelProperties.MAP_NAME]: mapName,
                [mixpanelProperties.FLOW]: 'location_from_photo',
              });
            }}
            onClickDropPin={() => {
              mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_STEP_DROP_PIN_CLICKED, {
                [mixpanelProperties.FLOW]: 'drop_pin',
                [mixpanelProperties.MAP_ID]: mapId,
                [mixpanelProperties.MAP_NAME]: mapName,
              });
              isDisableClickOnMap(false);
              changeStepInFlow({ step: AddPlaceSteps.DROP_PIN });
            }}
            onClickPrev={() => {
              dispatch(mapActions.setGhostStep(mapId, null));
              onClose();
            }}
            onAddStepMiniFlowFinished={onAddStepMiniFlowFinished}
            {...params}
        />
    ),
    [AddPlaceSteps.DROP_PIN]: () => (
        <DropPinView
            onConfirm={place => {
              onAddStepMiniFlowFinished(place.address, 'drop_pin');
            }}
            onCancel={params => {
              mixpanel.track(mixpanelEvents.MM_STEP_CREATION_DROP_PIN_PLACE_SELECTED_CANCEL, {
                [mixpanelProperties.MAP_ID]: mapId,
                [mixpanelProperties.MAP_NAME]: mapName,
                [mixpanelProperties.FLOW]: 'drop_pin',
                [mixpanelProperties.ADDRESS]: params?.address,
                [mixpanelProperties.COORDINATES]: [params?.position?.lat, params?.position?.lon],
              });
              isDisableClickOnMap(true);
              dispatch(mapActions.setGhostStep(mapId, null));
              onBack();
            }}
        />
    ),
    [AddPlaceSteps.EDIT_STEP_DETAILS]: params => {
      return (
          <EditStepDetails
              step={state?.step || state?.params?.step || params?.step}
              onClickDone={(title, location) => {
                changeStepInFlow(
                    {
                      step: AddPlaceSteps.ADD_STEP_FORM,
                      params: {
                        location: {
                          title,
                          address: location,
                          position: ghostStep.position,
                        },
                        fromWhere: params.fromWhere
                      },
                    },
                    {
                      nameOfPlace: title,
                      address: location,
                      isNameInputClear: false,
                      isLocationInputClear: false
                    },
                );
              }}
              onClickPrev={() => {
                const isPreviousDropPin = previousSteps[previousSteps.length - 1]?.step === AddPlaceSteps.DROP_PIN;
                isPreviousDropPin ? isDisableClickOnMap(false) : isDisableClickOnMap(true);
                dispatch(mapActions.setGhostStep(mapId, null));
                onBack();
              }}
              {...params}
          />
      )
    },
    [AddPlaceSteps.ADD_STEP_FORM]: params => (
        <AddStepForm
            onError={() => {
              changeStepInFlow({ step: AddPlaceSteps.ENTRY_PAGE });
            }}
            onBack={() => {
              if (previousSteps[previousSteps.length - 1]?.step === AddPlaceSteps.DROP_PIN) {
                isDisableClickOnMap(false);
              }
              onBack();
            }}
            onStepAdded={changeToTagsAndRatings}
            {...params}
        />
    ),
    [AddPlaceSteps.TAGS_AND_RATINGS]: ({ step }) => (
        <TagsAndRatings
            step={step}
            onDone={() => {
              onClose({ disableNavigateBack: true });
              dispatch(mapActions.setGhostStep(mapId, null));
              navigateToStepProfile(step);
            }}
            onClose={() => {
              onClose();
              dispatch(mapActions.setGhostStep(mapId, null));
            }}
        />
    ),
    [AddPlaceSteps.EMPTY_SIDE_BAR]: () => (
        <EmptySideBar
            onClickExit={() => {
              onBack();
              dispatch(mapActions.setGhostStep(mapId, null));
              expandSidebar();
            }}
        />
    ),
  };
  
  // FIXME: type aa, a step existing will show in "Create a place" form => flow is stuck
  if (!currentStepInFlow || !stepper[currentStepInFlow.step]) {
    console.trace(`AddStepPage: step ${currentStepInFlow?.step} not found`, { currentStepInFlow });
    return stepper[AddPlaceSteps.ENTRY_PAGE]();
  }
  
  return stepper[currentStepInFlow.step](currentStepInFlow.params);
};

export default AddPlaceFlow;
