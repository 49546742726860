import { useState, createContext } from 'react';
import { noop } from 'lodash';

const DropdownMenuContext = createContext({
    activeDropdownId: null,
    setActiveDropdownId: noop,
});

const DropdownMenuProvider = ({ children }) => {
    const [activeDropdownId, setActiveDropdownId] = useState(null);

    return (
        <DropdownMenuContext.Provider
            value={{
                activeDropdownId,
                setActiveDropdownId,
            }}
        >
            {children}
        </DropdownMenuContext.Provider>
    );
};

export { DropdownMenuContext, DropdownMenuProvider };
