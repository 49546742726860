import { useContext, useEffect } from 'react';
import { noop } from 'lodash';
import { DropPinViewContext } from './DropPinViewContext';
import { setLocationFoundCursor, setLocationPinCursor, expandSidebar, minimizeSidebar, resetMouseCursor } from './helpers';
import EmptySideBar from './EmptySideBar';
import DropPinViewMessage from './DropPinViewMessage';

const DropPinView = ({ onConfirm = noop, onCancel = noop }) => {
    const { isLocationFound, setIsDropPinView = noop, setOnDropPinConfirm = noop, setIsPinDropped = noop, location } = useContext(DropPinViewContext) || {};

    useEffect(() => {
        minimizeSidebar();
        setLocationPinCursor();
        setIsDropPinView(true);
    }, []);

    useEffect(() => {
        if (!isLocationFound) {
            setLocationPinCursor();
            return;
        }
        setLocationFoundCursor();
    }, [isLocationFound]);

    useEffect(() => {
        // TODO: another way to communicate onNext with the map? this is very confusing
        if (!onConfirm) {
            return;
        }

        setOnDropPinConfirm(onConfirm);
    }, [onConfirm]);

    const onClickExit = () => {
        expandSidebar();
        resetMouseCursor();
        setIsPinDropped(false);
        setIsDropPinView(false);
        onCancel(location);
    };

    return (
        <>
            <EmptySideBar onClickExit={onClickExit} />
            <DropPinViewMessage />
        </>
    );
};

export default DropPinView;
