import { success, error } from '@redux-requests/core';
import {
    ADD_NEW_TAG_TO_STEP,
    ADD_TAG_TO_STEP,
    DELETE_TAG_FROM_STEP,
    EDIT_STEP_RATING,
    CHANGE_PREVIEW_TAGS,
    GET_STEP,
    RESTORE_STEP,
    ARCHIVE_STEP,
    SET_CHANGE_LOCATION,
    SET_CHANGE_LOCATION_ID,
    GET_PENDING_STEPS,
    DELETE_PENDING_STEPS,
    POST_PENDING_STEPS,
    CHANGE_STATUS_STEPS,
    SEARCH_STEPS,
} from './types';
import { CREATE_POSTS_IN_POST, DELETE_POST } from '../post/types';

const initialState = {
    step: null,
    filteredSteps: { steps: [], cursor: null, total: 0 },
    title: '',
    tags: [],
    tagsPreview: [],
    ratings: [],
    amount: 0,
    ratingsPreview: [],
    likesCount: 0,
    postsCount: 0,
    viewsCount: 0,
    address: '',
    hours: '',
    phone: '',
    website: '',
    restoreId: null,
    archivedId: null,
    statusSteps: 'active',
    changeLocations: {},
    changeLocationId: null,
    changeLocationShow: false,
    stepPendingCount: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case success(SEARCH_STEPS): {
            return state;
        }

        case success(DELETE_PENDING_STEPS): {
            return {
                ...state,
                stepPendingCount: state.stepPendingCount - 1,
            };
        }
        case success(POST_PENDING_STEPS): {
            return {
                ...state,
                stepPendingCount: state.stepPendingCount - 1,
            };
        }
        case success(GET_PENDING_STEPS): {
            return {
                ...state,
                stepPendingCount: action?.response?.data?.total_results,
            };
        }
        case SET_CHANGE_LOCATION: {
            return {
                ...state,
                changeLocations: action.payload.data,
            };
        }
        case SET_CHANGE_LOCATION_ID: {
            return {
                ...state,
                changeLocationId: action.payload.id,
                changeLocationShow: action.payload.show,
            };
        }
        case success(GET_STEP): {
            const tagsComponent = action.response.data.step?.components?.find(component => component?.tags);
            const tags = tagsComponent.tags;
            const tagsPreviewComponent = action.response.data.step?.components?.find(component => component?.tags_preview);
            const tagsPreview = tagsPreviewComponent.tags_preview;
            const ratingComponent = action.response.data.step?.components?.find(component => component.type === 'rating');
            const ratingFilter = action.response.data.step?.components?.find(component => component?.rating_subcomponents);
            const ratings = ratingFilter?.rating_subcomponents;
            const scoreRating = ratings?.filter(item => item?.type === 'score1to5');
            const ratingPreviewFilter = action.response.data.step?.components?.find(component => component?.rating_subcomponents);
            const ratingsPreview = ratingPreviewFilter?.rating_subcomponents;
            const scoreRatingPreview = ratingsPreview?.filter(item => item?.type === 'score1to5');
            const amount = ratingComponent?.amount;
            return {
                ...state,
                step: action.response.data.step,
                title: action.response.data.step.title,
                likesCount: action.response.data.step.likes_count,
                postsCount: action.response.data.step.posts_count,
                viewsCount: action.response.data.step.views_count,
                tags: tags,
                tagsPreview: tagsPreview,
                ratings: scoreRating,
                ratingsPreview: scoreRatingPreview,
                amount: amount,
            };
        }
        case error(GET_STEP): {
            return {
                ...state,
            };
        }
        case error(ADD_TAG_TO_STEP): {
            return {
                ...state,
            };
        }
        case error(DELETE_TAG_FROM_STEP): {
            return {
                ...state,
            };
        }
        case CHANGE_PREVIEW_TAGS: {
            return {
                ...state,
                tagsPreview: action.payload.data,
            };
        }
        case CHANGE_STATUS_STEPS: {
            return {
                ...state,
                statusSteps: action.payload.data,
            };
        }
        case success(ADD_NEW_TAG_TO_STEP): {
            const oldTags = state.tags;
            let newTag = action.response.data.tags[0];
            newTag.category = action.meta.categoryName;
            oldTags.push(newTag);
            return {
                ...state,
                tags: oldTags,
            };
        }
        case error(ADD_NEW_TAG_TO_STEP): {
            return {
                ...state,
            };
        }
        case error(EDIT_STEP_RATING): {
            return {
                ...state,
            };
        }
        case success(CREATE_POSTS_IN_POST): {
            let updateStep;
            const oldStep = state.step;
            const setPost = (step, count) => {
                if (step) {
                    // eslint-disable-next-line no-param-reassign
                    step.posts_count = step.posts_count + count;
                }
                return step;
            };
            updateStep = setPost(oldStep, 1);
            return {
                ...state,
                step: updateStep,
            };
        }
        case success(DELETE_POST): {
            const oldStep = state.step;
            const postId = action.meta.postId;
            let updateStep;
            const setPost = (step, count) => {
                if (step) {
                    // eslint-disable-next-line no-param-reassign
                    step.posts_count = step.posts_count - count;
                    if (step?.secondaries) {
                        step.secondaries = step?.secondaries.filter(el => el?._id !== postId);
                    }
                }
                return step;
            };
            updateStep = setPost(oldStep, 1);
            return {
                ...state,
                step: updateStep,
            };
        }
        case RESTORE_STEP: {
            const stepId = action.meta.stepId;
            return {
                ...state,
                restoreId: stepId,
            };
        }
        case success(RESTORE_STEP): {
            return {
                ...state,
                restoreId: null,
            };
        }
        case ARCHIVE_STEP: {
            const stepIds = action.meta.stepId;
            return {
                ...state,
                archivedId: stepIds,
            };
        }
        case success(ARCHIVE_STEP): {
            return {
                ...state,
                archivedId: null,
            };
        }
        default:
            return state;
    }
};

export default reducer;
