import { ImageReasons } from './constants';
import { generateImageUrl } from '../helpers/index';
import { upperFirst, lowerCase } from 'lodash';

class TaggableItem {
    constructor({ type = '', id = '', text = '', image = 'MAP_IMG_DEFAULT_3', role = '', address = '', size = '50' }) {
        this.id = id;
        this.title = text;
        this.text = text;
        this.name = text;
        this.type = type;
        this.display = text;
        this.address = address;
        this.subtitle = upperFirst(lowerCase(role));
        this.avatar = generateImageUrl({
            reason: ImageReasons.AVATAR,
            imageId: image,
            width: size,
            height: size,
        });
    }
}

export default TaggableItem;
