import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import mixpanel, { mixpanelEvents } from '../../helpers/mixpanel';

export const GrowthCardType = {
    NewMember: {
        image: 'new_members.svg',
        title: 'New members',
    },
    Views: {
        image: 'views.svg',
        title: 'Views',
        info: [
            { image: 'map_pin.svg', title: 'Places views' },
            { image: 'map.svg', title: 'Map views' },
            { image: 'post.svg', title: 'Post views' },
        ],
    },
    Interactions: {
        image: 'interactions.svg',
        title: 'Activity',
        info: [
            { image: 'map_pin.svg', title: 'New steps' },
            { image: 'post.svg', title: 'New posts' },
            { image: 'navigation.svg', title: 'Navigations' },
            { image: 'replies.svg', title: 'Replies' },
            { image: 'claps.svg', title: 'Claps' },
            { image: 'likes.svg', title: 'Likes' },
        ],
    },
};

const GrowthCard = ({ growthCardType, value, changedPrecent }) => {
    const [infoHovered, setInfoHovered] = useState(false);
    
    useEffect(() => {
        if (infoHovered) mixpanel.track(mixpanelEvents.MAP_INSIGHTS_HOVER);
    }, [infoHovered]);
    
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div style={{ display: 'flex' }}>
                    <img src={`/assets/img/steps3Images/insights/${growthCardType.image}`} style={{ alignSelf: 'center' }} width={20} height={20} alt=''/>
                    <span className={styles.title}>{growthCardType?.title}</span>
                </div>
                {growthCardType.info && (
                    <div style={{ position: 'relative' }}>
                        <img
                            src={`/assets/img/steps3Images/insights/info${infoHovered ? '_hovered' : ''}.svg`}
                            onMouseEnter={() => setInfoHovered(true)}
                            onMouseLeave={() => setInfoHovered(false)}
                            width={20}
                            height={20}
                            alt=''
                        />
                        {infoHovered && <InfoPanel infoArray={growthCardType.info}/>}
                    </div>
                )}
            </div>
            <div className={styles.footer}>
                <div className={styles.content}>
                    {value == undefined ? (
                        <div className={'animate'} style={{ width: '80px', height: '40px' }}/>
                    ) : (
                        <>
                            <span className={styles.growthNumber}>
                                {value}
                                <span className={styles.total}>Total</span>
                            </span>
                            {changedPrecent && (
                                <div style={{ display: 'flex' }}>
                                    <img
                                        src={`/assets/img/steps3Images/insights/trending_${Math.round(changedPrecent) < 0 ? 'down' : 'up'}.svg`}
                                        width={20}
                                        height={20}
                                        alt=''
                                    />
                                    <span
                                        className={styles.growthPrecentage}
                                        style={{
                                            color: Math.round(changedPrecent) < 0 ? '#FF7262' : '#02c77c',
                                        }}
                                    >
                                        {Math.abs(Math.round(changedPrecent))}%
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const InfoPanel = ({ infoArray }) => {
    return (
        <div className={styles.moreInfoContainer}>
            <span className={styles.moreInfoTitle}>Users interactions consist of:</span>
            {infoArray.map(info => (
                <div className={styles.infoObjContainer}>
                    <img src={`/assets/img/steps3Images/insights/${info.image}`} width={20} height={20} alt=''/>
                    <span className={styles.infoObjTitle}>{info.title}</span>
                </div>
            ))}
        </div>
    );
};

export default GrowthCard;
