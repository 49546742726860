import {
    GET_STEP_BY_ID,
    LIKE_STEP,
    ADD_TAGS,
    CHANGE_STEP_TYPE,
    DELETE_TAG_FROM_STEP,
    GET_POSTS,
    ADD_TAG_TO_STEP,
    CREATE_STEP_FROM_PLACE,
    UPDATE_STEP,
    DELETE_IMAGE,
} from './types';

export const getPosts = (mapId, stepId, cursor) => ({
    type: GET_POSTS,
    request: {
        method: 'GET',
        url: `/steps3/posts?cursor=${cursor}`,
    },
    meta: {
        mapId,
        stepId,
        thunk: true,
    },
});

export const deleteImage = (mapId, { imageId, stepId, postId, context }) => ({
    type: DELETE_IMAGE,
    request: {
        method: 'DELETE',
        url: `/steps3/images/${imageId}?context=${context}`,
    },
    meta: {
        mapId,
        stepId,
        postId,
        imageId,
        thunk: true,
    },
});

export const updateStep = (mapId, stepId, data) => ({
    type: UPDATE_STEP,
    request: {
        method: 'PUT',
        url: `/step/${stepId}`,
        data,
    },
    meta: {
        mapId,
        stepId,
        thunk: true,
    },
});

export const getStepById = (mapId, stepId) => ({
    type: GET_STEP_BY_ID,
    request: {
        method: 'GET',
        url: `/steps3/steps/${stepId}?with_secondaries=true`,
    },
    meta: {
        mapId,
        stepId,
        thunk: true,
    },
});

export const likeStep = (stepId, isLike, likesCount) => ({
    type: LIKE_STEP,
    request: {
        method: 'POST',
        url: `/steps3/steps/${stepId}/like`,
        data: {
            action: isLike,
        },
    },
    meta: {
        stepId,
        isLike,
        likesCount,
        thunk: true,
    },
});

export const addTags = (stepId, mapId, tagId, status, tags) => ({
    type: ADD_TAGS,
    request: {
        method: 'POST',
        url: `/step/${stepId}/tag/${mapId}`,
        data: {
            status,
            tags: tagId,
            is_new_schema: true,
        },
    },
    meta: {
        mapId,
        stepId,
        tags: tags,
        tagsIds: tagId,
        thunk: true,
    },
});

export const changeStepType = (mapId, stepId, stepType) => ({
    type: CHANGE_STEP_TYPE,
    request: {
        method: 'PATCH',
        url: `/steps3/steps/${stepId}/preview`,
        data: {
            id: stepId,
            // TODO: update indications
            preview_type: stepType,
        },
    },
    meta: {
        mapId,
        stepId,
        stepType,
        thunk: true,
    },
});

export const deleteTagFromStep = (stepId, mapId, tagId, status, afterAddPost) => ({
    type: DELETE_TAG_FROM_STEP,
    request: {
        method: 'DELETE',
        url: `/step/${stepId}/tag/${mapId}`,
        data: {
            status,
            tags: tagId,
            is_new_schema: true,
        },
    },
    meta: {
        afterAddPost,
        stepId,
        mapId,
        tags: tagId,
        thunk: true,
    },
});

export const addTagToStep = (stepId, mapId, tagId, status, tags) => ({
    type: ADD_TAG_TO_STEP,
    request: {
        method: 'POST',
        url: `/step/${stepId}/tag/${mapId}`,
        data: {
            status,
            tags: tagId,
            is_new_schema: true,
        },
    },
    meta: {
        mapId,
        stepId,
        tags: tags,
        tagsIds: tagId,
        thunk: true,
    },
});

export const stepCreationPreview = ({ mapId, placeId }) => ({
    type: CREATE_STEP_FROM_PLACE,
    request: {
        method: 'GET',
        url: '/steps3/step-creation-preview',
        params: {
            map_id: mapId,
            place_id: placeId,
        },
    },
    meta: {
        thunk: true,
    },
});
