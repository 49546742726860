import * as React from 'react';

const MapUnpin = ({ width = 20, height = 22 }) => (
    <svg width={width} height={height} viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M10 12.625C11.3807 12.625 12.5 11.5057 12.5 10.125C12.5 8.74429 11.3807 7.625 10 7.625C8.61929 7.625 7.5 8.74429 7.5 10.125C7.5 11.5057 8.61929 12.625 10 12.625Z'
            fill='white'
            stroke='#2171EC'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M16.25 10.125C16.25 15.75 10 20.125 10 20.125C10 20.125 3.75 15.75 3.75 10.125C3.75 8.4674 4.40848 6.87769 5.58058 5.70558C6.75269 4.53348 8.3424 3.875 10 3.875C11.6576 3.875 13.2473 4.53348 14.4194 5.70558C15.5915 6.87769 16.25 8.4674 16.25 10.125V10.125Z'
            stroke='#2171EC'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M18.3333 20.3333L3.33331 2' stroke='white' strokeWidth={3} strokeLinecap='round' />
        <path d='M18.3333 20.3333L3.33331 2' stroke='#2171EC' strokeWidth={1.5} strokeLinecap='round' />
    </svg>
);

export default MapUnpin;
