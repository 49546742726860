import { copies } from './ShareTab';
import { useSelector } from 'react-redux';
import config from '../../config';
import { useEffect } from 'react';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import styles from './index.module.scss';
import { copyToClipboard } from '../../helpers';
import { SITE_URL } from '../../constants';
import { mapSelectors } from '../../redux/map';

const copiesEmbed = copies.embed;
export const EmbedTab = ({ onCopyHtmlClick, onCopyLinkClick }) => {
    const mapId = useSelector(state => state.user.selectedMapId);
    // const deepLink = useSelector(memberSelectors.setDeepLinkSelector);
    const iframeHTML = `<iframe src="${config.domain.web}/i/${mapId}" style="border: 1px solid #ddd; width:50%; height:50%;"></iframe>`;
    const newDeepLink = useSelector(mapSelectors.generateDeepLink(mapId));

    useEffect(() => {
        mixpanel.track(mixpanelEvents.SHARE_EMBED_SHOW);
    }, []);

    const handleEmbedEvent = button => {
        mixpanel.track(mixpanelEvents.SHARE_EMBED_CLICK, {
            [mixpanelProperties.BUTTON]: button,
        });
    };

    return (
        <div className={styles.sharePopup}>
            <div>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '19px',
                        color: '#3a3c3f',
                        marginTop: '24px',
                        fontWeight: 'bold',
                    }}
                >
                    {copiesEmbed.title}
                </div>
                <div
                    style={{
                        width: '380px',
                        margin: '8px auto 16px auto',
                        textAlign: 'center',
                        color: '#aeb1b6',
                        fontSize: '15px',
                    }}
                >
                    {copiesEmbed.description}
                </div>

                <div className={styles.imageFrame} style={{ borderBottom: 'none' }}>
                    <iframe className={styles.frame} src={`${config.domain.web}/i/${mapId}`} />
                </div>

                <div
                    className={styles.shareOnSocial}
                    style={{
                        margin: '24px auto',
                        textAlign: 'center',
                        fontSize: '15px',
                    }}
                >
                    <div
                        style={{
                            color: '#aeb1b6',
                            fontSize: '13px',
                        }}
                    >
                        {iframeHTML}
                    </div>
                </div>
                <div className={styles.copyLink}>
                    <div
                        onClick={() => {
                            onCopyHtmlClick();
                            copyToClipboard(iframeHTML);
                            handleEmbedEvent('copy_html');
                        }}
                        className={styles.copyLinkBtn}
                        style={{
                            marginRight: '26px',
                            color: '#ffffff',
                            backgroundColor: '#2171ec',
                        }}
                    >
                        {copiesEmbed.copy_html}
                    </div>
                    <a
                        href={`${SITE_URL}/iframe-doc`}
                        onClick={() => handleEmbedEvent('info_&_customize')}
                        target='_blank'
                        style={{
                            color: '#3a3c3f',
                            fontSize: '15px',
                            marginRight: '5px',
                        }}
                        rel='noreferrer'
                    >
                        {copiesEmbed.options}
                    </a>
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        width: '420',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',

                            width: '210px',
                            borderTop: '1px solid #dddddd',
                        }}
                    />
                    <div
                        style={{
                            margin: '10px',
                        }}
                    >
                        {copies.or}
                    </div>
                    <div
                        style={{
                            display: 'flex',

                            width: '210px',
                            borderTop: '1px solid #dddddd',
                        }}
                    />
                </div>

                <div
                    style={{
                        width: 'fit-content',
                        margin: '6px auto 5px auto',
                        color: '#666a70',
                        fontSize: '15px',
                    }}
                >
                    {copiesEmbed.get_link}
                </div>

                <div className={styles.copyEmbedLink}>
                    <div
                        style={{
                            marginRight: '8px',
                            color: '#aeb1b6',
                            fontSize: '15px',
                            flex: '1',
                        }}
                    >
                        {newDeepLink}
                    </div>
                    <button
                        onClick={() => {
                            onCopyLinkClick();
                            copyToClipboard(newDeepLink);
                            handleEmbedEvent('copy_link');
                        }}
                        className={styles.copyLinkBtnBlack}
                    >
                        {copiesEmbed.copy_link}
                    </button>
                </div>
            </div>
        </div>
    );
};