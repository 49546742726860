import { useDispatch, useSelector } from 'react-redux';
import SearchResult, { iconType } from '../common/buttons/SearchResult';
import { userSelectors } from '../../redux/user';
import { mapActions } from '../../redux/map';
import copies from '../../copies.json';
import { fetchLocationResults } from '../../helpers';

import styles from './CoordinatesSearchResults.module.scss';

const CoordinatesResults = ({ latitude, longitute, onClick = () => {}, onResults = () => {}, withTitle = true }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);

    const onCoordinatesClick = async () => {
        const steps = await fetchLocationResults({
            mapId,
            lat: latitude,
            lon: longitute,
        });
        dispatch(
            mapActions.setGhostStep(mapId, {
                title: 'selected location',
                position: { lat: latitude, lon: longitute },
                suggestToAdd: true,
                isOpenTooltip: false,
                isStickToMap: false,
                type: 'location',
            })
        );
        dispatch(
            mapActions.setMapCenter(mapId, {
                position: { lat: latitude, lon: longitute },
                zoom: 18,
            })
        );
        onResults(steps);
    };

    if (!latitude && !longitute) {
        return null;
    }

    return (
        <div className={styles.container}>
            {withTitle && <p className={styles.categoryTitle}>{copies.coordinates.title}</p>}
            <SearchResult
                type='coordinate'
                content={`${latitude} ${longitute}`}
                className={styles.searchResult}
                iconSrc={iconType.step}
                onClick={() => {
                    onCoordinatesClick();
                    onClick();
                }}
            />
        </div>
    );
};

export default CoordinatesResults;
