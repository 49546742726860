export const DELETE_POST = 'DELETE_POST';
export const CHANGE_PIN_POST = 'CHANGE_PIN_POST';
export const GET_UPLOAD_POST_IMG_LINK = 'GET_UPLOAD_POST_IMG_LINK';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const GET_COMMENTS = 'GET_COMMENTS';
export const CHANGE_CLAP_POST = 'CHANGE_CLAP_POST';
export const GET_POSTS_IN_POST = 'GET_POSTS_IN_POST';
export const CREATE_POSTS_IN_POST = 'CREATE_POSTS_IN_POST';
export const GET_COMMENTS_IN_POST = 'GET_COMMENTS_IN_POST';
export const GET_COMMENTS_ON_REPLY = 'GET_COMMENTS_ON_REPLY';
export const SHOW_TEXT = 'SHOW_TEXT';
export const EDIT_POST = 'EDIT_POST';
export const POST_ANNOUNCEMENT = 'POST_ANNOUNCEMENT';
export const SEEN_ANNOUNCEMENT = 'SEEN_ANNOUNCEMENT';
export const CREATE_FEED_POST = 'CREATE_FEED_POST';
