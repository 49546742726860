import React, { useEffect, useState, createContext } from 'react';
import { noop } from 'lodash';

// Mixpanel context
const MixpanelContext = createContext({
    context: null,
    setContext: noop,
});

// MixpanelProvider component
const MixpanelProvider = ({ context: initContext, getContext, children }) => {
    const [context, setContext] = useState(initContext);

    useEffect(() => {
        // Set the initial mixpanel context
        if (context) {
            setContext(context);
        } else if (getContext) {
            setContext(getContext());
        }
    }, [context, getContext]);

    return <MixpanelContext.Provider value={{ context, setContext }}>{children}</MixpanelContext.Provider>;
};

export { MixpanelProvider, MixpanelContext };
