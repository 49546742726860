import axios from 'axios';
import config from '../config';

const instance = axios.create({
    baseURL: config.domain.address,
    headers: {
        app_version: config.version.path,
        app_platform: 'map_manager',
    },
});

instance.interceptors.request.use(config => {
    const accessToken = localStorage.getItem('at');
    config.headers.Authorization = accessToken;
    return config;
});

export default instance;
