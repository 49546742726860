import React, { useEffect, useState, useRef } from 'react';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { mapActions, mapSelectors } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import { stepActions } from '../../redux/step';
import { getPlaceCoordinates, checkIfAlreadyInPosition } from '../../helpers';
import SearchResult, { iconType, actionButtons } from '../common/buttons/SearchResult';
import { useDebounce } from '../../hooks';
import { isStepInMap } from './helpers';

import styles from './index.module.scss';

export const CreatePlaceBehaviors = {
    SHOW: 'show',
    HIDE: 'hide',
    HIDE_ON_LOAD: 'hideOnLoad',
};
export const SearchTypes = {
    STEP: 'step',
    LOCATION: 'location',
    PLACE: 'place',
    ADDRESS: 'address',
    AREA: 'area',
};

const SearchSteps = ({
    searchValue,
    onCancel,
    className,
    onClickStep = noop,
    onClickPlace = noop,
    onClickCreatePlace = noop,
    onClickArea: onClickAreaProp = noop,
    searchTypes = Object.values(SearchTypes),
    displayLoading = true,
    onLoadingStart = noop,
    onLoadingEnd = noop,
    searchValueDebounce: propSearchValueDebounce,
    createPlaceBehavior = CreatePlaceBehaviors.SHOW,
    showActionButtons,
    sortByCategories = true,
    position = { lat: 0, lng: 0 },
    ghostStep = null,
    isUseSearchForStepCreation = false,
    searchLimitInMeters,
}) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
    const [searchResults, setSearchResults] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingResult, setLoadingResult] = useState(null);
    const internalSearchDebounce = useDebounce(searchValue, 250);
    const searchDebounce = propSearchValueDebounce || internalSearchDebounce;
    const searchBlockRef = useRef(null);
    const hideCreatePlaceOnLoading = createPlaceBehavior === CreatePlaceBehaviors.HIDE_ON_LOAD;
    const hideCreatePlace = createPlaceBehavior === CreatePlaceBehaviors.HIDE;
    const shouldShowCreatePlace = !hideCreatePlace && (!hideCreatePlaceOnLoading || (hideCreatePlaceOnLoading && searchDebounce && !loading));
    const eNums = {
        categoryNames: {
            steps: 'Places on map',
            places: 'Places',
            areas: 'Areas and addresses',
        },
        iconTypes: {
            steps: iconType.blueStep,
            places: iconType.step,
            areas: iconType.globe,
        },
        actionButtonTypes: {
            steps: actionButtons.ARROW,
            places: actionButtons.PLUS,
            areas: actionButtons.PLUS,
        },
        resultTitle: {
            steps: result => result.title,
            places: result => result?.title || result?.description,
            areas: result => result.description?.split(', ')[0] || result.title?.split(', ')[0],
        },
        resultSubTitle: {
            steps: result => result.geocode_address?.formatted_address || result.address || result.secondary_title,
            places: result => result.geocode_address?.formatted_address || result.address || result.secondary_title,
            areas: result => result.description?.split(', ')[1] || result.title?.split(', ')[1],
        },
        resultClickAction: {
            steps: result => {
                onClickStep(result);
            },
            places: result => {
                setLoadingResult(result.place_id);
                onClickPlace(result);
            },
            areas: result => {
                onClickArea(result);
            },
        },
        areaTypeZooms: {
            area: 10,
            address: 18,
        },
    };
    
    useEffect(() => {
        const handleClickOutside = event => {
            const clickedElementIsInput = event.target.tagName.toUpperCase() === 'INPUT';
            if (searchBlockRef.current && !searchBlockRef.current.contains(event.target) && !clickedElementIsInput) {
                onCancel && onCancel();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onCancel]);
    
    useEffect(() => {
        if (!(searchDebounce?.trim() && mapCenter?.bounds)) {
            setSearchResults();
            return;
        }
        
        (async () => {
            let results;
            setLoading(true);
            onLoadingStart();
            
            const queryParams = {
                map_id: mapId,
                lat: position?.lat || mapCenter?.position?.lat,
                lon: position?.lon || mapCenter?.position?.lng,
            };
            if (isUseSearchForStepCreation) {
                results = await dispatch(
                    stepActions.searchStepsStepCreation({
                        ...queryParams,
                        search_term: searchValue,
                    })
                );
            } else {
                results = await dispatch(
                    stepActions.searchStepsByValue({
                        ...queryParams,
                        term: searchValue,
                        data_type: searchTypes,
                        include_archived: true,
                        max_radius: searchLimitInMeters ? searchLimitInMeters : undefined,
                    })
                );
            }
            
            if (results.error) {
                setSearchResults();
                setLoading(false);
                onLoadingEnd();
                console.error(results.error);
                return;
            }
            
            setSearchResults(sortResultsByType(results?.data?.results));
            onLoadingEnd(results?.data?.results?.length);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebounce, mapCenter]);
    
    const sortResultsByType = results => {
        if (isUseSearchForStepCreation) {
            const places = [];
            const stepsInMap = [];
            results.forEach(result => (isStepInMap(result) ? stepsInMap.push(result) : places.push(result)));
            return {
                places,
                steps: stepsInMap.map(locationObj => ({
                    ...locationObj.step_in_map,
                    result_type: 'step',
                })),
            };
        }
        
        const placeDisallowedTypes = ['street_address', 'route', 'locality', 'country'];
        const foundedSteps = results.filter(val => val['result_type'] === 'step');
        const foundedPlaces = results
            .filter(val => val['result_type'] === 'place')
            ?.filter(val => !placeDisallowedTypes.some(item => val.types?.includes(item)));
        const foundedLocations = results.filter(val => val['result_type'] === 'location' && !val.is_there_step);
        const foundedArea = results.filter(val => val['result_type'] === 'area');
        const foundedAddress = results.filter(val => val['result_type'] === 'address');
        
        const areas = [];
        
        // FIXME: concat is not mutating the original array
        foundedArea.concat(foundedAddress).filter(function (item) {
            const i = areas.findIndex(x => x.place_id === item.place_id);
            if (i <= -1) {
                areas.push(item);
            }
            return null;
        });
        
        const places = foundedPlaces.concat(foundedLocations);
        
        if (foundedSteps.concat(places, areas).length < 1) {
            return;
        }
        
        return {
            steps: foundedSteps,
            places: places,
            areas,
        };
    };
    
    const getResultTitle = (category, result) => {
        return eNums.resultTitle[category](result);
    };
    
    const getResultSubTitle = (category, result) => {
        return eNums.resultSubTitle[category](result);
    };
    
    const onClickArea = async area => {
        const mapCenterPosition = mapCenter?.position;
        
        setLoadingResult(area.place_id || area._id);
        
        let location;
        let resultType;
        if (!area.location) {
            const response = await getPlaceCoordinates(area);
            location = response.location;
            resultType = response.result_type;
        } else {
            location = area.location;
            resultType = area.result_type;
        }
        
        setLoadingResult(null);
        // TODO: Display toast when already in position
        if (checkIfAlreadyInPosition(mapCenterPosition, location)) {
            return;
        }
        setLoading(true);
        dispatch(
            mapActions.setMapCenter(mapId, {
                position: [area.lat || location.lat, area.lon || location.lon || location.lng],
                zoom: eNums.areaTypeZooms[resultType] || 18,
            })
        );
        dispatch(mapActions.setGhostStep(mapId, ghostStep));
        onClickAreaProp(area);
    };
    
    const onClickResult = (category, result) => {
        return eNums.resultClickAction[category](result);
    };
    
    if (loading) {
        if (displayLoading) {
            return (
                <div ref={searchBlockRef} className={classNames(styles.block, className)}>
                    <div className={styles.loading}>
                        <img alt='' src='/assets/img/loadingSpinner.svg'/>
                        Loading
                    </div>
                </div>
            );
        }
        
        return null;
    }
    
    if (!searchResults) {
        return null;
    }
    
    return (
        <div ref={searchBlockRef} className={classNames(styles.block, className)}>
            <div className={styles.categories}>
                {Object.keys(searchResults)
                    .filter(result => {
                        return searchResults[result]?.length > 0;
                    })
                    .map((category, index) => {
                        const categoryResults = searchResults[category];
                        return (
                            <div key={`${category}-${index}`} className={styles.category}
                                 style={!sortByCategories ? { borderTop: 'none' } : null}>
                                {sortByCategories && <h4 className={styles.title}>{eNums.categoryNames[category]}</h4>}
                                {categoryResults.map((result, idx) => (
                                    <SearchResult
                                        idx={idx}
                                        key={result._id}
                                        type={result.result_type}
                                        archived={result?.archived}
                                        className={classNames(styles.searchResult, {
                                            [styles.stepResult]: category === 'steps',
                                        })}
                                        iconSrc={eNums.iconTypes[category]}
                                        actionButton={showActionButtons && eNums.actionButtonTypes[category]}
                                        content={getResultTitle(category, result)}
                                        subContent={getResultSubTitle(category, result)}
                                        postsAmount={category === 'steps' ? result['posts_count'] || 1 : null}
                                        isLoading={loadingResult === (result.place_id || result._id)}
                                        onClick={() => {
                                            onClickResult(category, result);
                                        }}
                                        showActionButton={showActionButtons}
                                    />
                                ))}
                            </div>
                        );
                    })}
            </div>
            {searchValue?.trim() && shouldShowCreatePlace && (
                <div
                    className={classNames(styles.createNew, {
                        [styles.createNewTopBorder]: !(!searchResults && !loading),
                    })}
                >
                    <FormattedMessage
                        id='searchSteps.create_new_step'
                        values={{
                            searchValue,
                            searchLink: (
                                <button onClick={() => onClickCreatePlace(searchValue)}>
                                    <FormattedMessage id='searchSteps.create_new_step_link' values={{ searchValue }}/>
                                </button>
                            ),
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default SearchSteps;
