import React from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import Outcome from '../Outcome';
import copiesPrefix from '../../../../copies.json';
import { Check, X } from 'phosphor-react';

const copies = copiesPrefix.mapUpdates;

const EditedTagsCard = ({ className, onHandled, outcome, handlerManager, managerPreview, tagsAdded, tagsRemoved }) => {
    const user = useSelector(userSelectors.user);
    const maps = useSelector(userSelectors.maps);
    const selectedMapId = useSelector(userSelectors.selectedMapId);
    const selectedMap = maps?.filter(({ _id }) => _id === selectedMapId)[0];
    const managers = selectedMap?.managers;
    const hasHandlerManager = handlerManager && handlerManager !== user.id;
    const managerInfomation = managers?.filter(({ _id }) => _id === handlerManager)[0];

    const renderCta = () => {
        if (outcome === 'thanks' || hasHandlerManager) {
            return <Outcome text={copies.thanked_by} managerPreview={managerPreview || managerInfomation} />;
        }
        return (
            <CustomButton onClick={() => onHandled && onHandled('thanks')} singleClick clicked={outcome !== 'unhandled'} type={ButtonType.PRIMARY}>
                {outcome === 'thanks' ? copies.sent : copies.send_thanks}
            </CustomButton>
        );
    };

    return (
        <div className={classNames([styles.card, className])}>
            <div className={styles.tagsBlock}>
                {tagsAdded?.length > 0 && (
                    <>
                        <span className={styles.label}>{copies.added}:</span>
                        <div className={styles.tags}>
                            {tagsAdded.map(tag => (
                                <div className={styles.tag}>
                                    <Check className={styles.icon} color='#02C77C' size={16} />
                                    <span>{tag.name}</span>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {tagsRemoved?.length > 0 && (
                    <>
                        <span className={styles.label}>{copies.removed}:</span>
                        <div className={styles.tags}>
                            {tagsRemoved.map(tag => (
                                <div className={styles.tag}>
                                    <X className={styles.icon} color='#FF7262' size={14} />
                                    <span style={{ color: '#FF7262' }}>{tag.name}</span>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.userReaction}>{renderCta()}</div>
        </div>
    );
};

export default EditedTagsCard;
