import { useEffect } from 'react';
import styles from './index.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { postActions, postSelectors } from '../../../../redux/posts';
import { Avatar, AvatarSizesNames } from '../../Avatar';
import { generateImageUrl } from '../../../../helpers';
import { X } from 'phosphor-react';

const ClapsModal = ({ postId, onClose }) => {
    const dispatch = useDispatch();
    const claps = useSelector(postSelectors.getClaps(postId)) || { list_claps: [], total_results: 0, cursor: null };

    const getMoreClaps = () => dispatch(postActions.getClaps(postId, claps.cursor));

    const getUsersImage = image =>
        generateImageUrl({
            reason: 'avatar',
            imageId: image,
            width: AvatarSizesNames.MEDIUM,
            height: AvatarSizesNames.MEDIUM,
        });

    useEffect(() => {
        getMoreClaps();
    }, []);

    return (
        <div className={styles.container}>
            <header>
                <span>Claps</span>
                <X className={styles.cancel} onClick={onClose} />
            </header>

            <span className={styles.devider} />

            <div id='clapsContainer' className={styles.clapsContainer}>
                <InfiniteScroll
                    dataLength={claps?.total_results}
                    next={getMoreClaps}
                    hasMore={!!claps?.cursor}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget='clapsContainer'
                >
                    {claps.list_claps.map(clap => (
                        <div className={styles.userContainer}>
                            <Avatar size={AvatarSizesNames.MEDIUM} src={getUsersImage(clap.image)} />
                            <span>{clap?.display_name}</span>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default ClapsModal;
