import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const BasicInput = ({ onKeyDown, className, onChange, value, type, placeholder, error, errorText, onClick }) => {
    return (
        <div className={styles.inputBasic}>
            <input
                onClick={onClick}
                type={type}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                className={classNames(styles.input, { [styles.inputError]: error }, className)}
                onChange={onChange}
                value={value}
            />
            {error && errorText && <span>{errorText}</span>}
            {error && <img className={styles.errorIcon} src={'/assets/img/steps3LoginLayout/iconError.svg'} alt={'errorIcon'} />}
        </div>
    );
};

export default BasicInput;
