import React from 'react';

const IconPluse = ({ stroke }) => (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10.9932 4.16663V15.8333' stroke={stroke} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M5.15991 10H16.8266' stroke={stroke} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
);

export default IconPluse;
