import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import styles from './index.module.scss';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import IconEditAccordion from '../../../../components/common/icons/IconEditAccordion';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import classNames from 'classnames';
import { mapActions } from '../../../../redux/map';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { userActionActions, userActionSelectors } from '../../../../redux/userAction';
import { ToastDurations, ToastContext, CHANGES_SAVED_TEXT } from '../../../../context/ToastContext';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.mapSettings.sections.nameAndDescription;

const MapNameAndDescription = ({ map }) => {
    const dispatch = useDispatch();
    const maps = useSelector(userSelectors.maps);
    const goto = useSelector(userActionSelectors.goto);
    const [name, setName] = useState(map?.name);
    const [desc, setDesc] = useState(map?.description);
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const accordionRef = useRef(null);
    const [error, setError] = useState({ value: false, text: '' });
    const [disabled, setDisable] = useState(true);
    const [loader, setLoader] = useState(false);
    const { setToast } = useContext(ToastContext);

    useEffect(() => {
        if (!goto) return;
        if (goto == 'description') {
            setOpen(true);
        }
        dispatch(userActionActions.goto(null));
    }, [goto]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    useEffect(() => {
        setName(map?.name);
        setDesc(map?.description);
    }, [map]);

    const onChangeFirstInput = useCallback(
        e => {
            if (e.target.value !== map.name) {
                if (maps.some(item => item._id !== map._id && item.name === e.target.value.trim())) {
                    setError({
                        value: true,
                        text: copies.fields.mapName.error_exists,
                    });
                    setLoader(false);
                    setDisable(true);
                } else {
                    setDisable(false);
                    setError({ value: false, text: '' });
                }
            } else if (desc !== map.description) {
                setDisable(false);
            } else {
                setDisable(true);
            }
            setName(e.target.value);
        },
        [map, desc]
    );

    const onChangeSecondInput = useCallback(
        e => {
            if (e.target.value !== map.description) {
                setDisable(false);
            } else if (name !== map.name) {
                setDisable(false);
            } else {
                setDisable(true);
            }
            setDesc(e.target.value);
        },
        [map, name]
    );

    const onCancel = () => {
        accordionRef.current.click();
        setName(map.name);
        setDesc(map.description);
        setDisable(true);
        setError({ value: false, text: '' });
    };

    const onSave = () => {
        setLoader(true);
        mixpanel.track(mixpanelEvents.ADD_MAP_DESCRIPTION, {
            [mixpanelProperties.FROM_WHERE]: 'map_settings',
            [mixpanelProperties.ACTION]: map?.description.length === 0 ? 'add' : 'modify',
        });
        if (name.trim()?.length === 0) {
            setError({
                value: true,
                text: copies.fields.mapName.error_required,
            });
            setLoader(false);
        } else {
            const data = {
                description: desc.trim(),
                name: name.trim(),
            };
            dispatch(mapActions.updateMap(map?._id, data)).then(({ data }) => {
                if (data) {
                    setLoader(false);
                    setDisable(true);
                    setError({ value: false, text: '' });
                    accordionRef.current.click();
                    setToast({
                        text: CHANGES_SAVED_TEXT,
                        timeout: ToastDurations.SHORT,
                    });
                }
            });
        }
    };

    const onKeyDown = useCallback(
        e => {
            if (name.length <= 0 && e.keyCode === 32) {
                e.preventDefault();
                setName('');
            }
        },
        [name]
    );

    return (
        <div
            onMouseOver={() => !open && setHover(true)}
            onMouseLeave={() => !open && setHover(false)}
            className={classNames(styles.accordionContainer, {
                [styles.hovered]: hover,
                [styles.active]: open,
            })}
        >
            <Accordion
                onChange={() => {
                    setHover(false);
                    setOpen(!open);
                }}
                className={styles.accordionBlock}
                expanded={open}
            >
                <AccordionSummary
                    ref={accordionRef}
                    className={classNames(styles.accordionContent)}
                    expandIcon={open ? null : <IconEditAccordion />}
                    aria-controls='panel-mapNameAndDescription'
                    id='panel-mapNameAndDescription'
                >
                    <Typography className={styles.accordionHeading}>{copies.title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                    <Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <label className={styles.mapName}>{copies.fields.mapName.title}</label>
                            <label className={styles.span}>{name?.length}/30</label>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <input
                                onKeyDown={e => onKeyDown(e)}
                                placeholder={copies.fields.mapName.placeholder}
                                onChange={e => onChangeFirstInput(e)}
                                className={styles.input}
                                maxLength='30'
                                defaultValue={name}
                                value={name}
                            />
                            {error.value && <img className={styles.errorImg} src='/assets/img/Icon-alert-error.svg' alt='' />}
                        </div>
                        {error.value && <span className={classNames(styles.error)}>{error?.text}</span>}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20,
                            }}
                        >
                            <label className={styles.mapDescription}>{copies.fields.description.title}</label>
                            <label className={styles.span}>{desc?.length}/200</label>
                        </div>
                        <textarea
                            placeholder={copies.fields.description.placeholder}
                            onInput={e => onChangeSecondInput(e)}
                            className={styles.textarea}
                            maxLength='200'
                            value={desc}
                        />
                    </Typography>
                    <div className={styles.accordionFooter}>
                        <CustomButton className={styles.cancelBtn} onClick={() => onCancel()} type={ButtonType.TERTIARY_BLACK}>
                            {copies.cancel_button}
                        </CustomButton>
                        <CustomButton
                            className={styles.saveBtn}
                            disabled={disabled}
                            onClick={() => onSave()}
                            type={ButtonType.PRIMARY}
                            text={loader ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={40} height={40} /> : copies.save_button}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default MapNameAndDescription;
