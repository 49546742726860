import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const ProgressBar = ({ progress = 0, outOf = 100, className }) => {
    const progressPercent = Math.round((progress / outOf) * 100);
    const maxProgressPercent = Math.min(progressPercent, 100);

    return (
        <div className={classNames(styles.block, className)}>
            <div className={styles.progress} style={{ width: `${maxProgressPercent}%` }}></div>
        </div>
    );
};

export default ProgressBar;

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    outOf: PropTypes.number,
};
