import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Star as StarIcon } from 'phosphor-react';

export const starType = {
    EMPTY: '#02C77B',
    HOVERED: '#7EE0BB',
    SELECTED: '#02C77C',
};

const Star = ({ onClick, onMouseEnter, fill = starType.EMPTY, size = 22 }) => (
    <StarIcon
        weight={fill === starType.EMPTY ? 'thin' : 'fill'}
        className={classNames(styles.block, { [styles.cursor]: onMouseEnter })}
        size={size}
        color={fill}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
    />
);

export default Star;
