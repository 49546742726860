import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { mapActions } from '../../../redux/map';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';
import { userActions, userSelectors } from '../../../redux/user';
import { REQUEST_NOTIFICATIONS } from '../../../config/constants';

const useStyles = makeStyles(() => ({
    error: {
        padding: '0 9px',
        paddingRight: '16px',
        color: '#ff7457!important',
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    },
    errorBlock: {
        margin: '0!important',
    },
    normal: {
        padding: '0 9px',
        paddingRight: '13px',
        color: 'gray!important',
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    },
    icon: {
        borderRadius: 5,
        width: 16,
        height: 16,
        border: '1px solid #aeb1b6',
        backgroundColor: '#ffffff',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: 'none',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'none',
        },
    },
    checkedIcon: {
        border: '1px solid #aeb1b6',
        backgroundColor: '#aeb1b6',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: 'none',
        },
    },
}));

const AddTag = ({
    categoryName,
    tags,
    mapId,
    categoryId,
    handleAddingNewTag,
    step,
    changeSelectedTagsArray,
    handleUpdateTest,
    id,
    afterAddPost,
    setLoaderRequest,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector(userSelectors.user);
    const [error, setError] = useState(false);
    const [sended, setSend] = useState(false);
    const [value, setValue] = useState('');

    const sendAddTagRequest = useCallback(
        e => {
            const tagNameFounded = tags.some(item => item.name === e.target.value);
            if (!tagNameFounded) {
                setSend(true);
                if (afterAddPost) {
                    setLoaderRequest(true);
                }
                dispatch(
                    mapActions.addMapTags(mapId, {
                        tags: [{ name: e.target.value, category_id: categoryId }],
                    })
                );
                dispatch(
                    mapActions.addNewTagToStep(
                        step?._id,
                        mapId,
                        {
                            tags: [
                                {
                                    name: e.target.value,
                                    category_id: categoryId,
                                },
                            ],
                        },
                        categoryName
                    )
                ).then(({ data, error }) => {
                    if (data) {
                        handleAddingNewTag(false);
                        setError(false);
                        setSend(false);
                        changeSelectedTagsArray(data?.tags[0]?._id);
                        dispatch(mapActions.setGuideTaskComplete(mapId, 'MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS'));
                        dispatch(mapActions.addTagToStep(step?._id, mapId, [data?.tags[0]?._id], true)).then(() => {
                            setLoaderRequest(false);
                            if (!afterAddPost) {
                                dispatch(
                                    userActions.setMessageRequest({
                                        text: REQUEST_NOTIFICATIONS.TAGS_SUCCESS,
                                        status: 'success',
                                    })
                                );
                                setTimeout(() => {
                                    dispatch(userActions.setMessageRequest());
                                }, 2500);
                            }
                        });

                        mixpanel.track(mixpanelEvents.ADD_TAG, {
                            [mixpanelProperties.ADD_TO]: 'step',
                            [mixpanelProperties.FROM_WHERE]: 'step page',
                            [mixpanelProperties.HOW]: 'tag button',
                            [mixpanelProperties.IS_STEP_CREATED_BY_SAME_USER]: user.name,
                            [mixpanelProperties.IS_FIRST_ON_MAP]: tags.length > 0 ? false : true,
                        });
                    }
                });
            } else {
                setError(true);
            }
        },
        [afterAddPost]
    );

    const handleBlur = useCallback(
        e => {
            if (e.target.value?.length >= 1 && !sended) {
                sendAddTagRequest(e);
            } else {
                handleAddingNewTag(false);
                setError(false);
            }
        },
        [sended, error]
    );

    const handleKeyPress = e => {
        if (e.keyCode === 13 && e.target.value?.length >= 1 && !sended) {
            e.preventDefault();
            sendAddTagRequest(e);
        }
    };

    const handleChangeValue = useCallback(
        e => {
            setValue(e.target.value);
            if (afterAddPost && handleUpdateTest) {
                handleUpdateTest(id, e.target.value);
            }
            const tagNameFounded = tags.some(item => item.name === e.target.value);
            if (tagNameFounded) {
                setError(true);
            } else {
                setError(false);
            }
        },
        [id, afterAddPost, handleUpdateTest, tags]
    );

    return (
        <Grid
            style={{
                position: 'relative',
                alignItems: 'self-start',
                marginBottom: '16px',
            }}
            container
            item
            xs={6}
        >
            <FormControlLabel
                className={error && classes.errorBlock}
                control={
                    error ? (
                        <img className={styles.errorImg} onClick={() => setValue('')} src='/assets/img/removeTag.png' alt='' />
                    ) : (
                        <Checkbox
                            checked
                            className={error ? classes.error : classes.normal}
                            checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
                            icon={<span className={classes.icon} />}
                            color='#aeb1b6'
                        />
                    )
                }
                label={
                    <input
                        onKeyDown={e => handleKeyPress(e)}
                        onBlur={e => handleBlur(e)}
                        autoFocus
                        onChange={e => handleChangeValue(e)}
                        className={classNames(styles.input)}
                        placeholder={'Add tag name'}
                        type='text'
                        value={value}
                    />
                }
            />
            {error && <span className={styles.error}>Tag already exists.</span>}
        </Grid>
    );
};

export default AddTag;
