import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { memberActions, memberSelectors } from '../../redux/member';
import { userActions } from '../../redux/user';
import { mapActions } from '../../redux/map';
import classNames from 'classnames';
import MemberBlock from './components/MemberBlock';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchInput from '../../components/common/inputs/SearchInput';
import CustomButton, { ButtonSize, ButtonType } from '../../components/common/buttons/CustomButton';
import IconSortBy from '../../components/common/icons/IconSortBy';
import { ClickAwayListener } from '@material-ui/core';
import IconShare from '../../components/common/icons/IconShare';
import ModalsDeleteMember from '../../components/common/modals/ModalsDeleteMember';
import { REQUEST_NOTIFICATIONS } from '../../config/constants';
import RequestMessage from '../../components/common/RequestMessage';
import ModalsChangePermissionMember from '../../components/common/modals/ModalsChangePermissionMember';
import IconInvite from '../../components/common/icons/IconInvite';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { convertNumberToThousandSepartor } from '../../helpers';
import copiesPrefix from '../../copies.json';
import { getStorage } from '../../helpers';
import { useOutletContext } from 'react-router';
import { UserTypes } from '../../constants';

let timer = null;
const copies = copiesPrefix.members;

const MEMBERS_INVITE_KEY = 'membersInvite';

let scrollTimeoutId = null;

const Members = () => {
    const dispatch = useDispatch();
    const { randomMapToken, sharePopup, setSharePopup, selectedMap: map } = useOutletContext();
    const { _id: mapId } = map || {};
    const [searching, setSearching] = useState(false);
    const totalMembersCount = useSelector(memberSelectors.totalMembersCountSelector);
    const [disabled, setDisable] = useState(true);
    const [opened, setOpened] = useState(false);
    const [valueOrder, setOrderBy] = useState('desc');
    const [sortBy, setSortBy] = useState('joined_to_map_at');
    const [membersList, setMembersList] = useState({
        members: [],
        cursor: null,
        total: 0,
    });
    const [adminsList, setAdminsList] = useState({
        admins: [],
        cursor: null,
        total: 0,
    });
    const [loader, setLoader] = useState(false);
    const [selectedMember, setSelectedMember] = useState({});
    const [removePopup, setRemovePopup] = useState(false);
    const [changePopup, setChangePopup] = useState(false);
    const [totalUserCount, setTotalUserCount] = useState(null);
    const [storageInvite, setStorageInvite] = useState(getStorage(MEMBERS_INVITE_KEY, []));
    const [show, setShow] = useState(true);
    const [isScrolling, setIsScrolling] = useState(false);
    const isUserHasMemberRole = useMemo(() => {
        if (!selectedMember || !Object.values(selectedMember) || !selectedMember.role) {
            return false;
        }
        return Object.values(selectedMember).length > 0 && Object.values(selectedMember.role)[0]?.role === UserTypes.MEMBER;
    }, [selectedMember]);

    useEffect(() => {
        setStorageInvite(getStorage(MEMBERS_INVITE_KEY, []));
    }, [show]);

    useEffect(() => {
        (async () => {
            setLoader(true);
            const result1 = await dispatch(
                memberActions.getMembers({
                    mapId: mapId,
                    withoutManagers: true,
                    withPointsInformation: true,
                })
            );
            const result2 = await dispatch(memberActions.getAdmins({ mapId: mapId, withoutMembers: true }));
            setMembersList({
                members: result1?.data?.users,
                cursor: result1?.data?.cursor,
                total: result1?.data?.total_results,
            });
            setAdminsList({
                admins: result2?.data?.users,
                cursor: result2?.data?.cursor,
                total: result2?.data?.total_results,
            });
            setTotalUserCount(result1?.data?.total_results + result2?.data?.total_results);
            setLoader(false);
            const find = storageInvite?.find(value => value === mapId);
            if (find) setShow(false);
            else setShow(true);
        })();
    }, [mapId]);

    useEffect(() => {
        if (totalMembersCount === 2) {
            dispatch(mapActions.fetchSingleMap(mapId));
        }
    }, [totalMembersCount]);

    const handleSuccessPermissionMember = useCallback(() => {
        dispatch(
            memberActions.setPermissionsMembers({
                mapId: mapId,
                data: selectedMember?.role,
            })
        ).then((data, error) => {
            if (data) {
                const memberList = membersList?.members;
                const adminList = adminsList?.admins;
                const permission = selectedMember?.role;
                const { role: map_role } = Object?.values(permission)?.[0];
                const ids = Object.keys(permission);

                if (selectedMember?.member?.map_role === 'MEMBER') {
                    adminList.push({ ...selectedMember?.member, map_role });
                    setAdminsList({
                        admins: adminList,
                        cursor: adminsList.cursor,
                        total: adminsList.total + 1,
                    });
                    const updateMemberList = memberList.filter(member => ids[0] !== member._id);
                    setMembersList({
                        members: updateMemberList,
                        cursor: membersList.cursor,
                        total: membersList.total - 1,
                    });
                } else {
                    memberList.unshift({ ...selectedMember?.member, map_role });
                    setMembersList({
                        members: memberList,
                        cursor: membersList.cursor,
                        total: membersList.total + 1,
                    });
                    const updateAdminList = adminList.filter(admin => ids[0] !== admin._id);
                    setAdminsList({
                        admins: updateAdminList,
                        cursor: adminsList.cursor,
                        total: adminsList.total - 1,
                    });
                }
            }
        });
        setSelectedMember({});
    }, [dispatch, selectedMember, membersList, adminsList, mapId]);

    const handlerSharePopup = useCallback(async () => {
        mixpanel.track(mixpanelEvents.SHARE_SHOW, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: map?.name,
            [mixpanelProperties.FROM_WHERE]: 'members',
            [mixpanelProperties.SHARE_WHAT]: 'map',
        });
        randomMapToken();
        setSharePopup(!sharePopup);
    }, [dispatch, map]);

    const handleInput = useCallback(
        e => {
            const text = e.target.value;
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (text.length >= 1) {
                    dispatch(memberActions.getMembers({ mapId: mapId, searchTerm: text })).then(({ data, error }) => {
                        if (data) {
                            setMembersList({
                                members: data?.users,
                                cursor: data?.cursor,
                                total: data?.total_results,
                            });
                            setSearching(true);
                        } else if (error) {
                            setSearching(false);
                        }
                    });
                } else {
                    dispatch(memberActions.getMembers({ mapId: mapId, searchTerm: '' })).then(({ data, error }) => {
                        if (data) {
                            setMembersList({
                                members: data?.users,
                                cursor: data?.cursor,
                                total: data?.total_results,
                            });
                            setSearching(false);
                        } else if (error) {
                            setSearching(false);
                        }
                    });
                }
            }, 500);
        },
        [dispatch, mapId]
    );

    const handleRemoveId = useCallback(
        item => {
            dispatch(memberActions.removeMembers({ mapId: mapId, data: [item?._id] })).then(({ data }) => {
                if (data) {
                    const newMembersCount = totalUserCount - 1;
                    if (item?.map_role === 'MEMBER') {
                        let newMemberList = membersList?.members;
                        newMemberList = newMemberList.filter(member => member._id !== item?._id);
                        setMembersList({
                            ...membersList,
                            members: newMemberList,
                            cursor: membersList?.cursor,
                            total: membersList?.total - 1,
                        });
                        setTotalUserCount(newMembersCount);
                    } else {
                        let newAdminList = adminsList?.admins;
                        newAdminList = newAdminList.filter(admin => admin._id !== item?._id);
                        setAdminsList({
                            ...adminsList,
                            admins: newAdminList,
                            cursor: adminsList?.cursor,
                            total: adminsList?.total - 1,
                        });
                        setTotalUserCount(newMembersCount);
                    }
                    dispatch(
                        userActions.setMessageRequest({
                            text: copies.user_removed,
                            status: 'success',
                            img: REQUEST_NOTIFICATIONS.IMG_SUCCESS,
                        })
                    );
                    dispatch(
                        mapActions.updateMemberCount({
                            mapId,
                            count: newMembersCount,
                        })
                    );
                } else {
                    dispatch(
                        userActions.setMessageRequest({
                            text: copies.something_went_wrong,
                            status: 'error',
                            img: REQUEST_NOTIFICATIONS.IMG_ERROR,
                        })
                    );
                }
                setSelectedMember({});
            });
        },
        [dispatch, mapId, membersList, adminsList, totalUserCount]
    );

    const noDataResults = useMemo(
        () => (
            <>
                <div className={styles.noResultsContainerMember}>
                    <div className={styles.noResultsContainerCenter}>
                        <p className={styles.noResultsTitle}>{searching ? copies.no_results : copies.emptyState.title}</p>
                        <p className={styles.noResultsText}>{searching ? copies.no_results_description : copies.emptyState.description}</p>
                        {!searching && (
                            <CustomButton
                                onClick={() => handlerSharePopup()}
                                size={ButtonSize.MEDIUM}
                                type={ButtonType.PRIMARY_BLUE}
                                text={
                                    <>
                                        <IconShare />
                                        {copies.share_map}
                                    </>
                                }
                            />
                        )}
                    </div>
                </div>
            </>
        ),
        [searching]
    );

    const handleSort = useCallback(
        (sort, order) => {
            switch (sort) {
                case 'display_name':
                    dispatch(
                        memberActions.getMembers({
                            mapId: mapId,
                            sortBy: sort,
                            orderBy: order,
                        })
                    ).then(({ data }) => {
                        setMembersList({});
                        if (data) {
                            setMembersList({
                                members: data?.users,
                                cursor: data?.cursor,
                                total: data?.total_results,
                            });
                            setSortBy(sort);
                            setOrderBy(order);
                            setOpened(false);
                        }
                    });
                    break;
                case 'joined_to_map_at':
                    dispatch(
                        memberActions.getMembers({
                            mapId: mapId,
                            sortBy: sort,
                            orderBy: order,
                        })
                    ).then(({ data }) => {
                        setMembersList({});
                        if (data) {
                            setMembersList({
                                members: data?.users,
                                cursor: data?.cursor,
                                total: data?.total_results,
                            });
                            setSortBy(sort);
                            setOrderBy(order);
                            setOpened(false);
                        }
                    });
                    break;
                default:
                    return null;
            }
        },
        [dispatch, mapId]
    );

    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    const loadNextPage = debounce(async () => {
        const result = await dispatch(
            memberActions.getMembers({
                mapId: mapId,
                cursor: membersList?.cursor,
                sortBy,
                orderBy: valueOrder,
            })
        );
        setMembersList({
            members: membersList?.members?.concat(result?.data?.users),
            cursor: result?.data?.cursor,
            total: membersList.total,
        });
        if (adminsList?.cursor) {
            const result2 = await dispatch(
                memberActions.getAdmins({
                    mapId: mapId,
                    cursor: adminsList?.cursor,
                })
            );
            setAdminsList({
                admins: adminsList?.admins?.concat(result2?.data?.users),
                cursor: result2?.data?.cursor,
                total: adminsList.total,
            });
        }
    });

    useEffect(() => {
        if (membersList?.members?.length === 0 && membersList?.cursor) {
            loadNextPage();
        }
    }, [membersList?.members]);

    const closeInviteBlock = useCallback(() => {
        const array = storageInvite === null ? [] : [...storageInvite];
        if (storageInvite) {
            const find = storageInvite?.find(value => value === mapId);
            if (!find) {
                array.push(`${mapId}`);
                localStorage.setItem(MEMBERS_INVITE_KEY, JSON.stringify(array));
                setShow(false);
            }
        } else {
            array.push(`${mapId}`);
            localStorage.setItem(MEMBERS_INVITE_KEY, JSON.stringify(array));
            setShow(false);
        }
    }, [localStorage, storageInvite]);

    return (
        <div className={styles.container}>
            {!loader ? (
                <div id={'membersList'} className={classNames(styles.block)}>
                    {totalUserCount < 5 && membersList?.total >= 1 && show && (
                        <div className={styles.inviteBlock}>
                            <div onClick={() => closeInviteBlock()} className={styles.closeBlock}>
                                <img src='/assets/img/icon_cancel_blue.svg' />
                            </div>
                            <div className={styles.inviteBlockLeft}>
                                <p className={styles.inviteBlockTitle}>{copies.inviteBlock.title}</p>
                                <p className={styles.inviteBlockText}>{copies.inviteBlock.description}</p>
                                <CustomButton
                                    onClick={() => handlerSharePopup()}
                                    size={'mediumSize'}
                                    type={ButtonType.PRIMARY_BLUE}
                                    text={
                                        <>
                                            <IconShare />
                                            {copies.share_map}
                                        </>
                                    }
                                />
                            </div>
                            <div className={styles.inviteBlockRight}>
                                <IconInvite />
                            </div>
                        </div>
                    )}
                    {adminsList?.total > 0 && (
                        <InfiniteScroll
                            dataLength={adminsList?.admins?.length}
                            next={loadNextPage}
                            hasMore={!!adminsList?.cursor}
                            className={styles.infiniteScroll}
                            scrollableTarget='membersList'
                            onScroll={() => {
                                if (scrollTimeoutId) clearTimeout(scrollTimeoutId);
                                setIsScrolling(true);

                                scrollTimeoutId = setTimeout(() => {
                                    setIsScrolling(false);
                                }, 500);
                            }}
                        >
                            <div className={styles.adminsBlock}>
                                <p data-testid='total-admins' style={{ marginBottom: '20px' }} className={styles.titleName}>
                                    {copies.admins}
                                    <span> • {adminsList?.total}</span>
                                </p>
                                {adminsList?.admins?.map(item => (
                                    <MemberBlock
                                        key={item?._id}
                                        setSelectedMember={setSelectedMember}
                                        setRemovePopup={setRemovePopup}
                                        setChangePopup={setChangePopup}
                                        member={item}
                                        isScrolling={isScrolling}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                    <InfiniteScroll
                        dataLength={membersList?.members?.length}
                        next={loadNextPage}
                        hasMore={!!membersList?.cursor}
                        loader={<h4>Loading...</h4>}
                        className={styles.infiniteScroll}
                        scrollableTarget='membersList'
                    >
                        <div className={styles.usersBlock}>
                            <div className={styles.dFlexUserBlock}>
                                <p className={styles.titleName} data-testid='total-members'>
                                    {copies.members}
                                    <span>
                                        {membersList?.total > 0 &&
                                            ` • ${convertNumberToThousandSepartor({
                                                numberToConvert: membersList?.total,
                                            })}`}
                                    </span>
                                </p>
                                <div className={styles.rightBlock}>
                                    <div
                                        onClick={() => {
                                            setDisable(false);
                                        }}
                                        className={classNames(styles.searchBlock)}
                                    >
                                        <SearchInput
                                            disabled={disabled}
                                            onBlur={() => setDisable(true)}
                                            className={classNames(styles.searchBar, {
                                                [styles.searchBarOpen]: searching || !disabled,
                                            })}
                                            onChange={e => handleInput(e)}
                                        />
                                    </div>
                                    <ClickAwayListener onClickAway={() => setOpened(false)}>
                                        <div className={styles.membersSort}>
                                            <CustomButton
                                                className={classNames({
                                                    [styles.openDropDown]: opened,
                                                })}
                                                onClick={() => setOpened(!opened)}
                                                size={'smallSize'}
                                                type={ButtonType.TERTIARY_BLACK}
                                                text={
                                                    <>
                                                        <IconSortBy />
                                                        {copies.sort_by}
                                                    </>
                                                }
                                            />
                                            {opened && (
                                                <div className={styles.dropDownContent}>
                                                    <span
                                                        onClick={() => handleSort('joined_to_map_at', 'desc')}
                                                        className={classNames(styles.sortText, {
                                                            [styles.sortActive]: sortBy === 'joined_to_map_at' && valueOrder === 'desc',
                                                        })}
                                                    >
                                                        {sortBy === 'joined_to_map_at' && valueOrder === 'desc' && <img src='/assets/img/check.svg' alt='' />}
                                                        {copies.sort.joined_asc}
                                                    </span>
                                                    <span
                                                        onClick={() => handleSort('joined_to_map_at', 'asc')}
                                                        className={classNames(styles.sortText, {
                                                            [styles.sortActive]: sortBy === 'joined_to_map_at' && valueOrder === 'asc',
                                                        })}
                                                    >
                                                        {sortBy === 'joined_to_map_at' && valueOrder === 'asc' && <img src='/assets/img/check.svg' alt='' />}
                                                        {copies.sort.joined_desc}
                                                    </span>
                                                    <span
                                                        onClick={() => handleSort('display_name', 'asc')}
                                                        className={classNames(styles.sortText, {
                                                            [styles.sortActive]: sortBy === 'display_name' && valueOrder === 'asc',
                                                        })}
                                                    >
                                                        {sortBy === 'display_name' && valueOrder === 'asc' && <img src='/assets/img/check.svg' alt='' />}
                                                        {copies.sort.name_asc}
                                                    </span>
                                                    <span
                                                        onClick={() => handleSort('display_name', 'desc')}
                                                        className={classNames(styles.sortText, {
                                                            [styles.sortActive]: sortBy === 'display_name' && valueOrder === 'desc',
                                                        })}
                                                    >
                                                        {sortBy === 'display_name' && valueOrder === 'desc' && <img src='/assets/img/check.svg' alt='' />}
                                                        {copies.sort.name_desc}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </ClickAwayListener>
                                </div>
                            </div>
                            {membersList?.members?.length > 0
                                ? membersList?.members?.map(item => (
                                      <MemberBlock
                                          key={item?._id}
                                          setSelectedMember={setSelectedMember}
                                          setRemovePopup={setRemovePopup}
                                          setChangePopup={setChangePopup}
                                          member={item}
                                          isScrolling={isScrolling}
                                      />
                                  ))
                                : noDataResults}
                        </div>
                    </InfiniteScroll>
                </div>
            ) : (
                <div className={classNames(styles.block)}>
                    <div className={styles.adminsBlock}>
                        <div
                            className={classNames('animate')}
                            style={{
                                width: '90px',
                                height: '25px',
                                marginBottom: '20px',
                            }}
                        />
                        <div
                            style={{
                                padding: '12px 24px',
                                width: '725px',
                                border: 'solid 1px #ddd',
                                borderBottom: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}></div>
                        </div>
                        <div
                            style={{
                                padding: '12px 24px',
                                width: '725px',
                                border: 'solid 1px #ddd',
                                borderBottom: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                padding: '12px 24px',
                                width: '725px',
                                border: 'solid 1px #ddd',
                                marginBottom: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.usersBlock}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                className={classNames('animate')}
                                style={{
                                    width: '90px',
                                    height: '25px',
                                    marginBottom: '20px',
                                }}
                            />
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '90px',
                                        height: '25px',
                                        marginRight: '30px',
                                    }}
                                />
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '90px',
                                        height: '25px',
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                padding: '12px 24px',
                                width: '725px',
                                border: 'solid 1px #ddd',
                                borderBottom: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                padding: '12px 24px',
                                width: '725px',
                                border: 'solid 1px #ddd',
                                borderBottom: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                padding: '12px 24px',
                                width: '725px',
                                border: 'solid 1px #ddd',
                                borderBottom: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                padding: '12px 24px',
                                width: '725px',
                                border: 'solid 1px #ddd',
                                marginBottom: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames('animate')}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <RequestMessage />
            {removePopup && (
                <ModalsDeleteMember
                    textFirst={
                        <>
                            {copies.remove_member_message[0]}
                            <br />"<b>{selectedMember?.display_name}</b>"{copies.remove_member_message[1]}
                        </>
                    }
                    onCancel={() => setRemovePopup(false)}
                    onRemove={() => {
                        handleRemoveId(selectedMember);
                        setRemovePopup(false);
                    }}
                />
            )}
            {changePopup && (
                <ModalsChangePermissionMember
                    displayName={selectedMember?.member?.display_name}
                    role={isUserHasMemberRole ? UserTypes.MEMBER : UserTypes.ADMIN}
                    onCancel={() => setChangePopup(false)}
                    onSuccess={() => {
                        handleSuccessPermissionMember();
                        setChangePopup(false);
                    }}
                />
            )}
        </div>
    );
};
export default Members;
