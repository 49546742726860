import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { convertNumberToThousandSepartor } from '../../../../helpers';
import { userSelectors } from '../../../../redux/user';
import { mapSelectors } from '../../../../redux/map';
import SortSteps from '../../../../components/SortSteps';
import { useOutletContext } from 'react-router';

const StepsFilters = ({ isSideListOpen = true, sortedBy, onSort }) => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const steps = useSelector(mapSelectors.steps(mapId));
    const totalSteps = useSelector(mapSelectors.map(mapId))?.steps_count;
    const stepsList = useSelector(mapSelectors.map(mapId))?.currentList;
    const { setFitBoundsBySteps } = useOutletContext();

    return (
        <div className={styles.details}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div className={styles.totalSteps}>
                    <span className={styles.showing}>
                        {`Showing ${convertNumberToThousandSepartor({
                            numberToConvert: stepsList?.total ?? 0,
                        })} / ${convertNumberToThousandSepartor({
                            numberToConvert: totalSteps,
                        })}`}
                    </span>
                    <span
                        onClick={() => {
                            setFitBoundsBySteps(Object.values(steps));
                        }}
                        className={styles.all}
                    >
                        Show all
                    </span>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <SortSteps disabled={totalSteps !== 0} isSideListOpen={isSideListOpen} sortedBy={sortedBy} setSorted={onSort} sortStepsBy={() => {}} />
            </div>
        </div>
    );
};

export default StepsFilters;
