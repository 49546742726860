import { error, success } from '@redux-requests/core';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { get, uniq } from 'lodash';
import stepReducer from '../step2/reducer';
import settingReducer from '../mapSettings/reducer';
import insightsReducer from '../mapInsights/reducer';
import {
    ADD_NEW_TAG_TO_STEP,
    APPROVE_PENDING_POST,
    CHANGE_INCLUDES_COMPONENT,
    CHANGE_PERMISSIONS_MAP,
    CHANGE_SECRET_MAP,
    CHANGE_STEP_TYPE,
    CREATE_DEEP_LINK,
    CREATE_POST,
    DELETE_IMPORT_STATE,
    DELETE_POST_IMAGE,
    DELETE_STEP_ON_MAP,
    EDIT_STEP_LOCATION,
    FETCH_SINGLE_MAP,
    GET_ALL_STEPS_PREVIEW_DATA,
    GET_FULL_STEP_DATA,
    GET_INSIGHTS,
    GET_STEP_CREATOR_DATA,
    GET_STORY_SHARING_IMAGE,
    GET_STORY_SHARING_IMAGE_STEP,
    GUIDE_TASKS,
    NEW_MAP,
    RESET_ACTIVITIES_COUNT,
    RESET_PENDING_COUNT,
    SET_ALL_STEPS_MAP_CENTER,
    SET_IMPORT_STATE,
    SET_IS_STEPS_LIST_CACHED,
    SET_MAP_CENTER,
    SET_PENDGING_POST_CACHE,
    SET_STEP_GHOST,
    SET_STEPS_LIST_ANCHOR,
    SET_TO_COMPONENTS,
    SET_TO_GENERAL,
    SET_ZOOM_END,
    UPDATE_FLAG,
    UPDATE_MEMBER_COUNT,
    UPDATE_STEP_INFO,
    UPDATE_STEPS_FROM_FETCH,
    UPDATE_STEPS_IN_LIST,
} from './types';
import { arrToObj, sendEventNotification } from '../../helpers';
import { SET_SELECTED_MAP_ID } from '../user/types';
import { ARCHIVE_STEP } from '../step/types';
import { CREATE_POSTS_IN_POST, DELETE_POST, EDIT_POST } from '../post/types';

export const defaultStepList = {
    steps: [],
    cursor: null,
    total: 0,
    anchor: 0,
    isSaveStepsListCache: false,
};

const findIfSelected = ({ stepId, stepIds = [], isDeleteAll, excludeStepsIds = [] }) => {
    if (isDeleteAll) return excludeStepsIds.includes(stepId);
    return !stepIds.includes(stepId);
};

const initialState = map => ({
    ...map,
    mapImage: '',
    stepImage: '',
    steps: {},
    incomplete_imports: arrToObj(map.incomplete_imports || [], '_id'),
    pendingPostsCache: {},
    count_comments: {},
    // steps_list: [],
    tags_object: [],
    tags_categories: [],
    center: null,
    ghostStep: null,
    currentList: defaultStepList,
    // MapSetting start
    toComponents: false,
    toGeneral: false,
    zoomEnd: true,
    // MapSetting end
    mapData: {},
    tag_objects: [],
    guide_task: {},
    unhandled_activities: 0,
    members: JSON.parse(localStorage.getItem('members_count') || '{}')[map._id] || 0,
    mapPosition: {},
    insights: {},
    settings: {},
    stepIds: [],
});

const GUIDE_TASK_TYPES = {
    MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS: 'add_steps',
    MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS: 'add_tags',
    MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_IMAGE: 'add_image',
    MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_DESCRIPTION: 'add_description',
    MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP: 'share',
};

const nestedReducers = (state, action) => {
    const stepId = action.stepId || action.meta?.stepId;
    let newState = {};
    if (stepId) {
        const stepState = stepReducer(state.steps[stepId], action, state);
        if (stepState !== state.steps[stepId]) {
            newState.steps = { [stepId]: stepState };
        }
    }
    const insightState = insightsReducer(state.insights, action);
    const settingState = settingReducer(state, action);
    if (newState.steps) {
        newState.steps = { ...state.steps, ...newState.steps };
    }
    if (insightState !== state.insights) {
        newState = { ...state, ...insightState };
    }
    if (settingState !== state) {
        newState = { ...state, ...settingState };
    }
    return newState;
};

const reducer =
    map =>
        (state = initialState(map), action) => {
            if (action.mapId !== state._id && action.meta?.mapId !== state._id) {
                return state;
            }
            const nestedState = nestedReducers(state, action);
            if (Object.keys(nestedState).length !== 0) {
                state = { ...state, ...nestedState };
            }
            
            switch (action.type) {
                case SET_MAP_CENTER: {
                    const currentLat = state.center?.position?.lat;
                    const currentLng = state.center?.position?.lng;
                    const newLat = action.center.position[0] || action.center.position.lat;
                    const newLng = action.center.position[1] || action.center.position.lng || action.center.position.lon;
                    const newState = {
                        position: { lat: newLat, lng: newLng },
                        isMapInteraction: action.isMapInteraction,
                    };
                    
                    const isZoomSame = state.center?.zoom === action.center.zoom;
                    const isPositionSame = currentLat?.toFixed(7) === newLat?.toFixed(7) && currentLng?.toFixed(7) === newLng?.toFixed(7);
                    
                    if (isZoomSame && isPositionSame) {
                        // Map did not change but someone called this action.
                        // we want to preserve any center state information in that case,
                        // to prevent bugs like https://steps.fibery.io/Bugs/Bugs/Edit-location-1299
                        return {
                            ...state,
                            center: {
                                ...state.center,
                                ...action.center,
                                ...newState,
                            },
                        };
                    }
                    
                    return {
                        ...state,
                        center: {
                            ...action.center,
                            ...newState,
                        },
                    };
                }
                
                case success(GET_INSIGHTS):
                    return {
                        ...state,
                        insights: action.response.data,
                    };
                case UPDATE_STEPS_IN_LIST:
                    return {
                        ...state,
                        currentList: { ...state.currentList, ...action.updatedList },
                    };
                case SET_ALL_STEPS_MAP_CENTER:
                    return {
                        ...state,
                        allStepsMapCenter: action.center,
                    };
                case SET_STEPS_LIST_ANCHOR: {
                    return {
                        ...state,
                        currentList: { ...state.currentList, anchor: action.payload.anchorPosition },
                    };
                }
                case SET_IS_STEPS_LIST_CACHED: {
                    return {
                        ...state,
                        currentList: { ...state.currentList, isSaveStepsListCache: action.payload.isSaveStepsListCache },
                    };
                }
                
                case SET_STEP_GHOST:
                    return { ...state, ghostStep: action.ghostStep };
                
                case CHANGE_STEP_TYPE: {
                    const { stepId, stepType } = action.meta;
                    // TODO: update indications
                    const updatedStepListSteps = state.currentList.steps.map(step => (step._id === stepId ? { ...step, preview_type: stepType } : step));
                    return {
                        ...state,
                        currentList: {
                            ...state.currentList,
                            steps: updatedStepListSteps,
                        },
                    };
                }
                case RESET_ACTIVITIES_COUNT:
                    sendEventNotification({
                        data: { map_id: action.mapId },
                        eventName: 'RECENT_ACTIVITIES_PAGE_OPEN',
                    });
                    return { ...state, new_recent_activities_count: 0 };
                case RESET_PENDING_COUNT:
                    sendEventNotification({
                        data: { map_id: action.mapId },
                        eventName: 'PENDING_POSTS_PAGE_OPEN',
                    });
                    return { ...state, new_pending_posts_count: 0 };
                case SET_PENDGING_POST_CACHE:
                    return {
                        ...state,
                        pendingPostsCache: {
                            ...state.pendingPostsCache,
                            [action.pendingPost._id]: action.pendingPost,
                        },
                    };
                case SET_IMPORT_STATE: {
                    return {
                        ...state,
                        incomplete_imports: {
                            ...state.incomplete_imports,
                            [action.importState._id]: action.importState,
                        },
                    };
                }
                
                case DELETE_IMPORT_STATE: {
                    const incompleteImportStates = { ...state.incomplete_imports };
                    delete incompleteImportStates[action.importStateId];
                    return { ...state, incomplete_imports: incompleteImportStates };
                }
                case success(SET_SELECTED_MAP_ID):
                    return {
                        ...state,
                        name: action.response.data.name,
                        tags_object: action.response.data.tags_object,
                        tags_categories: action.response.data.tags_categories,
                    };
                
                case success(APPROVE_PENDING_POST):
                    return {
                        ...state,
                    };
                
                case UPDATE_MEMBER_COUNT: {
                    const { count } = action.payload;
                    const { mapId } = action.meta;
                    
                    try {
                        const savedMemberCount = JSON.parse(localStorage.getItem('members_count') || '{}');
                        localStorage.setItem(
                            'members_count',
                            JSON.stringify({
                                ...savedMemberCount,
                                [mapId]: count,
                            }),
                        );
                        
                        return {
                            ...state,
                            members: count || savedMemberCount[mapId] || 0,
                        };
                    } catch (error) {
                        console.error(error);
                    }
                    
                    return {
                        ...state,
                        members: count || 0,
                    };
                }
                
                case success(FETCH_SINGLE_MAP): {
                    return {
                        ...state,
                        tag_objects: action.response.data.tag_objects,
                        components: action.response.data.components,
                        name: action.response.data.name,
                        tags: action.response.data.tags,
                        tags_object: action.response.data.tags_object,
                        tags_categories: action.response.data.tags_categories,
                        image: action.response.data.image,
                        mapData: action.response.data,
                        flags: action.response.data.flags,
                        guide_task: action.response.data.flags.MAP_MANAGER_JOURNEY_TASKS,
                        unhandled_activities: action.response.data.unhandled_recent_activities_count,
                    };
                }
                
                case success(GET_ALL_STEPS_PREVIEW_DATA): {
                    let newSteps = action.response.data.steps;
                    const status = action.meta.requestAction?.request?.params?.status;
                    const stepIds = [];
                    newSteps = newSteps.map(step => {
                        stepIds.push(step._id);
                        const existingStep = state.steps[step._id];
                        if (existingStep && !existingStep.isShortData) return { ...existingStep, ...step, isShortData: false };
                        return { ...step, isShortData: true };
                    });
                    
                    const steps = { ...state.steps, ...arrToObj(newSteps, '_id') };
                    const uniqueStepIds = uniq([...state.stepIds, ...stepIds]);
                    
                    return {
                        ...state,
                        steps_count: status === 'archived' ? state.steps_count : action.response.data.total_results,
                        steps: steps,
                        stepIds: uniqueStepIds,
                    };
                }
                
                case DELETE_STEP_ON_MAP: {
                    const oldStepsOnMap = state.steps;
                    const removeStep = Object.values(oldStepsOnMap).filter(({ _id }) => !action.payload.includes(_id));
                    return {
                        ...state,
                        steps: arrToObj(removeStep, '_id'),
                    };
                }
                
                case success(GET_FULL_STEP_DATA):
                    return {
                        ...state,
                        postsList: action?.response?.data.posts_list?.posts,
                        steps: {
                            ...state.steps,
                            [action.meta.stepId]: { ...state.steps[action.meta.stepId], ...action.response.data.step, isShortData: false },
                        },
                        count_comments: {
                            ...state.posts,
                            [action.meta.stepId]: action?.response?.data.posts_list?.posts,
                        },
                    };
                case success(GET_STEP_CREATOR_DATA): {
                    const stepId = action.meta.stepId;
                    const userPreview = action.response.data?.user_preview;
                    return {
                        ...state,
                        steps: {
                            ...state.steps,
                            [stepId]: {
                                ...state.steps[stepId],
                                user_preview: {
                                    ...state.steps[stepId]?.user_preview,
                                    ...userPreview,
                                },
                            },
                        },
                    };
                }
                
                case success(CREATE_POSTS_IN_POST): {
                    if (!action.response.data.post.step_id) {
                        return state;
                    }
                    
                    return {
                        ...state,
                        steps: {
                            ...state.steps,
                            [action.response.data.post.step_id]: {
                                ...state.steps[action.response.data.post.step_id],
                                posts_count: state.steps[action.response.data.post.step_id]?.posts_count + 1,
                            },
                        },
                    };
                }
                
                case success(CREATE_POST): {
                    const post = action.response?.data?.post;
                    const isExistingStep = !action.response?.data?.step;
                    const stepId = action.response?.data?.step?._id || post?.step_id;
                    
                    if (!action.response.data.step && action.meta.stepId) {
                        const initialPostsList = {
                            postsListIds: [],
                            total: 0,
                            cursor: null,
                        };
                        const previousPostsList = state.steps[stepId].postsList || initialPostsList;
                        return {
                            ...state,
                            steps: {
                                ...state.steps,
                                [action.meta.stepId]: {
                                    ...state.steps[action.meta.stepId],
                                    posts_count: state.steps[action.meta.stepId].posts_count + 1,
                                    postsList: {
                                        ...previousPostsList,
                                        postsListIds: [post._id, ...previousPostsList.postsListIds],
                                        total: previousPostsList.total + 1,
                                    },
                                },
                            },
                        };
                    }
                    
                    if (isExistingStep) {
                        return state;
                    }
                    
                    return {
                        ...state,
                        steps_count: state.steps_count + 1,
                        steps: {
                            ...state.steps,
                            [stepId]: action.response.data.step,
                        },
                    };
                }
                
                case error(DELETE_POST): {
                    return {
                        ...state,
                    };
                }
                
                case success(EDIT_POST): {
                    const oldSecondaries = Object.values(state.steps).find(({ _id }) => _id === action.meta.stepId)?.secondaries;
                    const text = action.response.data.post.text;
                    const newFiles = action.response.data.post.attachments;
                    const postId = action.meta.postId;
                    
                    if (!action.meta.stepId) {
                        return state;
                    }
                    
                    const stepPost = oldSecondaries.find(item => item._id === postId);
                    oldSecondaries.map(item => {
                        if (item._id === postId) {
                            return {
                                ...stepPost,
                                text,
                                attachments: newFiles,
                            };
                        }
                        return item;
                    });
                    
                    return {
                        ...state,
                        steps: {
                            ...state.steps,
                            [action.meta.stepId]: {
                                ...state.steps[action.meta.stepId],
                                secondaries: oldSecondaries,
                            },
                        },
                    };
                }
                
                // MapSettings start
                case success(NEW_MAP): {
                    return {
                        ...state,
                        ...action.response.data.map,
                        flags: state.flags,
                        tag_objects: action.response.data.map?.tag_objects || [],
                        image: action.response.data.map.image,
                    };
                }
                case CHANGE_SECRET_MAP: {
                    const isSecret = action.payload.is_secret;
                    return {
                        ...state,
                        is_secret: isSecret,
                    };
                }
                case CHANGE_PERMISSIONS_MAP: {
                    const toEnable = action.payload.to_enable;
                    return {
                        ...state,
                        gates_statuses: {
                            ALLOW_EDIT: toEnable,
                        },
                    };
                }
                
                case SET_TO_COMPONENTS:
                    return {
                        ...state,
                        toComponents: action.payload.value,
                    };
                case SET_TO_GENERAL:
                    return {
                        ...state,
                        toGeneral: action.payload.value,
                    };
                
                case error(GET_STORY_SHARING_IMAGE): {
                    return state;
                }
                
                case success(GET_STORY_SHARING_IMAGE): {
                    return {
                        ...state,
                        mapImage: action.response.data,
                    };
                }
                case success(GET_STORY_SHARING_IMAGE_STEP): {
                    return {
                        ...state,
                        stepImage: action.response.data,
                    };
                }
                case success(CREATE_DEEP_LINK):
                    return {
                        ...state,
                        newDeepLink: action.response.data.link,
                    };
                // MapSettings end
                
                case success(CHANGE_INCLUDES_COMPONENT): {
                    const componentType = get(action, 'meta.componentType');
                    const data = get(action, 'meta.data');
                    const updatedComponents = state?.components?.map(component => {
                        if (component?.id === componentType) {
                            return {
                                ...component,
                                ...data,
                            };
                        } else return component;
                    });
                    
                    return {
                        ...state,
                        components: updatedComponents,
                    };
                }
                
                case success(ADD_NEW_TAG_TO_STEP): {
                    let newTags = action.response.data.tags;
                    const setStepTag = (tag, status) => {
                        if (tag) {
                            // eslint-disable-next-line no-param-reassign
                            tag.is_selected = status;
                            tag.category = action.meta.categoryName;
                        }
                        return tag;
                    };
                    newTags.forEach(newTag => {
                        return setStepTag(newTag, true);
                    });
                    
                    return {
                        ...state,
                        tag_objects: state.tag_objects.concat(...newTags),
                    };
                }
                
                case ARCHIVE_STEP: {
                    const stepIds = action.meta.stepId || [];
                    const excludeStepsIds = action.meta.excludeStepsIds;
                    const isDeleteAll = action.meta.deleteAll;
                    const stepsCount = state.steps_count;
                    const oldSteps = state.steps;
                    const currentListSteps = state.currentList.steps;
                    const newCount = isDeleteAll ? excludeStepsIds?.length : stepsCount - stepIds?.length;
                    
                    const steps = Object.values(oldSteps).filter(({ _id }) => findIfSelected({
                        stepId: _id,
                        stepIds,
                        isDeleteAll,
                        excludeStepsIds,
                    }));
                    
                    const updatedSteps = Object.values(oldSteps).map(item => {
                        let stepId;
                        if (isDeleteAll) {
                            stepId = excludeStepsIds.find(e => e !== item._id);
                        } else {
                            stepId = stepIds.find(e => e === item._id);
                        }
                        if (item._id === stepId) {
                            return { ...item, archived: true };
                        }
                        
                        return item;
                    });
                    
                    const newStepsList = currentListSteps.filter(({ _id }) => !stepIds.includes(_id));
                    
                    return {
                        ...state,
                        steps: newCount > 0 ? arrToObj(action.meta.route === 'mapUpdates' ? updatedSteps : steps, '_id') : {},
                        currentList: {
                            ...state.currentList,
                            steps: newStepsList,
                        },
                    };
                }
                
                case success(ARCHIVE_STEP): {
                    const stepIds = action.meta.stepId || [];
                    const excludeStepsIds = action.meta.excludeStepsIds;
                    const isDeleteAll = action.meta.deleteAll;
                    const stepsCount = state.steps_count;
                    const newCount = isDeleteAll ? excludeStepsIds?.length : stepsCount - stepIds?.length;
                    return {
                        ...state,
                        steps_count: newCount,
                    };
                }
                case success(SET_ZOOM_END): {
                    return {
                        ...state,
                        zoomEnd: action.payload,
                    };
                }
                
                case DELETE_POST_IMAGE: {
                    if (!action.meta.stepId) {
                        return state;
                    }
                    const postId = action.payload.postId;
                    return {
                        ...state,
                        steps: {
                            ...state.steps,
                            [action.payload.stepId]: {
                                ...state.steps[action.payload.stepId],
                                posts_attachments_list: {
                                    posts_attachments: state.steps[action.payload.stepId].posts_attachments_list.posts_attachments.filter(
                                        item => !postId.includes(item.attachment.post_id),
                                    ),
                                },
                            },
                        },
                    };
                }
                
                case GUIDE_TASKS: {
                    const completedTask = action?.payload.value;
                    const stateGuideTasks = { ...state.guide_task };
                    const guideTasksStringsArray = Object.getOwnPropertyNames(GUIDE_TASK_TYPES);
                    const completedTasksArray = guideTasksStringsArray.filter(taskName => stateGuideTasks[taskName] === true);
                    if (guideTasksStringsArray.findIndex(taskName => taskName === completedTask) > -1 && !stateGuideTasks[completedTask]) {
                        completedTasksArray.push(completedTask);
                        const completedTasksNames = completedTasksArray.map(task => GUIDE_TASK_TYPES[task]);
                        mixpanel.track(mixpanelEvents.PAR_COMPLETE_TASK, {
                            [mixpanelProperties.TASK]: GUIDE_TASK_TYPES[completedTask],
                            [mixpanelProperties.NUM_COMPLETED_TASKS]: completedTasksArray.length,
                            [mixpanelProperties.COMPLETED_TASKS]: completedTasksNames,
                        });
                        stateGuideTasks[completedTask] = true;
                    }
                    return {
                        ...state,
                        guide_task: stateGuideTasks,
                    };
                }
                case success(UPDATE_STEP_INFO): {
                    const newStep = action.response.data.step;
                    const title = action.meta.title;
                    const stepId = action.meta.stepId;
                    
                    return {
                        ...state,
                        steps: {
                            ...state.steps,
                            [stepId]: {
                                ...state.steps[stepId],
                                title: title || state.steps[stepId].title,
                                place_id: title ? null : state.steps[stepId].place_id,
                                hpaw: newStep.hpaw,
                            },
                        },
                    };
                }
                case EDIT_STEP_LOCATION: {
                    const updatedStepId = action.meta.stepId;
                    const updatedStepTitle = action.meta.title;
                    const updatedCurrentListSteps = state.currentList.steps.map(step => {
                        if (step._id !== updatedStepId) return step;
                        return { ...step, title: updatedStepTitle };
                    });
                    return {
                        ...state,
                        currentList: { ...state.currentList, steps: updatedCurrentListSteps },
                    };
                }
                case UPDATE_STEPS_FROM_FETCH: {
                    const mapSteps = { ...state.steps };
                    const updatedStepsArr = [...action.updatedStepsArr];
                    updatedStepsArr.forEach(step => {
                        mapSteps[step._id] = { ...mapSteps[step._id], ...step };
                    });
                    return {
                        ...state,
                        steps: mapSteps,
                    };
                }
                case UPDATE_FLAG: {
                    const { flagGroup, flag, setTo } = action.meta;
                    return {
                        ...state,
                        flags: {
                            ...state.flags,
                            [flagGroup]: {
                                ...state.flags[flagGroup],
                                [flag]: setTo,
                            },
                        },
                    };
                }
                default:
                    return state;
            }
        };

export default reducer;
