import { YearSelection } from '@material-ui/pickers/views/Year/YearView';
import { StepTypes } from '../../constants';
import {
    GET_ALL_STEPS_PREVIEW_DATA,
    GET_FULL_STEP_DATA, //should to be deleted and replaced with GET_STEP_BY_ID
    GET_STEP_CREATOR_DATA,
    FETCH_SINGLE_MAP,
    SET_MAP_CENTER,
    SET_STEP_GHOST,
    CREATE_MAP,
    NEW_MAP,
    CREATE_POST,
    SET_MAP_COVER_IMAGE,
    RESET_ACTIVITIES_COUNT,
    RESET_PENDING_COUNT,
    RESTORE_STEP,
    SET_PENDGING_POST_CACHE,
    EDIT_STEP_LOCATION,
    GET_INSIGHTS,
    SET_ALL_STEPS_MAP_CENTER,
    // MapSetting start
    CHANGE_SECRET_MAP,
    CHANGE_PERMISSIONS_MAP,
    CHANGE_INCLUDES_COMPONENT,
    RESET_MAP_COMPONENTS_DATA,
    SET_TAG_COMPONENT,
    SET_DATA_CATEGORIES,
    DELETE_MAP_TAGS,
    ADD_MAP_TAGS,
    ADD_MAP_CATEGORIES,
    SET_TO_COMPONENTS,
    SET_TO_GENERAL,
    APPROVE_PENDING_POST,
    CHANGE_GATES_STATUSES,
    // MapSetting end
    LIKE_STEP,
    ADD_TAG_TO_STEP,
    DELETE_TAG_FROM_STEP,
    EDIT_STEP_RATING,
    ADD_NEW_TAG_TO_STEP,
    SET_ZOOM_END,
    DELETE_STEP_ON_MAP,
    DELETE_POST_IMAGE,
    BULK_ADD_TAGS,
    GUIDE_TASKS,
    SET_IMPORT_STATE,
    DELETE_IMPORT_STATE,
    DELETE_TAGS,
    ADD_NEW_CATEGORY,
    ADD_NEW_CATEGORY_WITH_TAGS,
    EDIT_CATEGORY_NAME,
    EDIT_CATEGORY_PRIORITY,
    DELETE_CATEGORIES,
    GET_STORY_SHARING_IMAGE,
    GET_STORY_SHARING_IMAGE_STEP,
    ADD_NEW_TAGS_TO_MAP,
    MOVE_TAG_TO_CATEGORY,
    UPDATE_STEP_INFO,
    CREATE_DEEP_LINK,
    CREATE_ANNOUNCEMENT_POSTS,
    EDIT_TAG_NAME,
    UPDATE_MEMBER_COUNT,
    CHANGE_STEP_TYPE,
    CLEAR_STEP_AND_MAP_IMAGES,
    UPDATE_STEPS_IN_LIST,
    UPDATE_STEPS_FROM_FETCH,
    SET_STEPS_LIST_ANCHOR,
    SET_IS_STEPS_LIST_CACHED,
    UPDATE_FLAG,
    HANDLE_ACTIVITY,
} from './types';

export const createDeepLink = (type, mapId, stepId, inProductShareMethod) => {
    const data = {
        map_id: mapId,
        type,
        step_id: stepId,
    };
    
    return {
        type: CREATE_DEEP_LINK,
        request: {
            method: 'POST',
            url: `/branch-links`,
            data: {
                reason: 'map_manager_shared_item',
                link_type: type, // ["map", "step"]
                client_type: 'map_manager',
                data,
                in_product_share_method: inProductShareMethod,
            },
        },
        meta: {
            mapId,
            thunk: true,
        },
    };
};

export const likeStep = (mapId, stepId, action, likesCount) => ({
    type: LIKE_STEP,
    request: {
        method: 'POST',
        url: `/steps3/steps/${stepId}/like`,
        data: {
            action,
        },
    },
    meta: {
        mapId,
        stepId,
        isLiked: action,
        likesCount,
        thunk: true,
    },
});

export const fetchSingleMap = mapId => ({
    type: FETCH_SINGLE_MAP,
    request: {
        method: 'GET',
        url: `/steps3/maps/${mapId}?includeFlags=true&with_unhandled_recent_activities_count=true`,
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const getInsights = mapId => ({
    type: GET_INSIGHTS,
    request: {
        method: 'GET',
        url: `/steps3/insights?map_id=${mapId}`,
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const getAllStepsPreviewData = (mapId, status) => ({
    type: GET_ALL_STEPS_PREVIEW_DATA,
    request: {
        method: 'GET',
        url: `/steps3/maps/${mapId}/steps`,
        params: {
            status,
        },
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const updateStepsFromFetch = (mapId, updatedStepsArr) => ({
    type: UPDATE_STEPS_FROM_FETCH,
    mapId,
    updatedStepsArr,
});

export const getFullStepData = (mapId, stepId) => ({
    type: GET_FULL_STEP_DATA,
    request: {
        method: 'GET',
        url: `/steps3/steps/${stepId}?with_secondaries=true`,
    },
    meta: {
        mapId,
        stepId,
        thunk: true,
    },
});

export const getStepCreatorData = (mapId, stepId, userId) => ({
    type: GET_STEP_CREATOR_DATA,
    request: {
        method: 'GET',
        url: `/steps3/users/${userId}/preview`,
    },
    meta: {
        mapId,
        stepId,
        userId,
        thunk: true,
    },
});

export const editStepLocation = (mapId, { stepId, lat, lon, title, place_id, location_id, address }) => ({
    type: EDIT_STEP_LOCATION,
    request: {
        method: 'PUT',
        url: `/step/${stepId}`,
        data: {
            lat,
            lon,
            title,
            location_id: location_id || undefined,
            place_id: place_id || undefined,
            address: address || undefined,
        },
    },
    meta: {
        mapId,
        stepId,
        title,
        thunk: true,
    },
});

export const updateStepsList = (mapId, updatedList) => ({
    type: UPDATE_STEPS_IN_LIST,
    mapId,
    updatedList,
});

export const resetActivitiesCount = mapId => ({
    type: RESET_ACTIVITIES_COUNT,
    mapId,
});

export const handleActivity = (activityId, outcome) => ({
    type: HANDLE_ACTIVITY,
    request: {
        method: 'POST',
        url: `/steps3/recentactivities/${activityId}/handle`,
        data: {
            response: outcome,
        },
    },
    meta: {
        activityId,
        thunk: true,
    },
});

export const setAllStepsMapCenter = (mapId, center) => ({
    type: SET_ALL_STEPS_MAP_CENTER,
    center,
    mapId,
});

export const resetPendingCount = mapId => ({
    type: RESET_PENDING_COUNT,
    mapId,
});

export const setMapCenter = (mapId, position, isMapInteraction) => ({
    type: SET_MAP_CENTER,
    mapId,
    center: position,
    isMapInteraction,
});

export const deleteStepOnMap = (mapId, stepId) => ({
    type: DELETE_STEP_ON_MAP,
    mapId,
    payload: stepId,
});

export const setPedningPostCache = (mapId, pendingPost) => ({
    type: SET_PENDGING_POST_CACHE,
    mapId,
    pendingPost,
});

export const setGhostStep = (mapId, ghostStep) => ({
    type: SET_STEP_GHOST,
    mapId,
    ghostStep,
});

export const createMap = data => ({
    type: CREATE_MAP,
    request: {
        method: 'POST',
        url: '/map2',
        data,
    },
    meta: {
        thunk: true,
    },
});

export const restoreStep = (mapId, stepId) => ({
    type: RESTORE_STEP,
    request: {
        method: 'PUT',
        url: `/restorestep/${stepId}`,
    },
    meta: {
        thunk: true,
        stepId,
        mapId,
    },
});

export const updateMap = (mapId, data) => ({
    type: NEW_MAP,
    request: {
        method: 'PUT',
        url: `/map/${mapId}`,
        data,
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const createStepPost = (mapId, {
    lat, lon, title, text, textArray, attachments, placeId, context, locationId,
    stepId,
    shouldBePinned,
}) => ({
    type: CREATE_POST,
    request: {
        method: 'POST',
        url: '/steps3/posts',
        data: {
            location_parameters: {
                lat: lat,
                lon: lon,
                title: title,
                place_id: placeId || undefined,
            },
            map_id: mapId,
            text_array: textArray || [
                {
                    text,
                    text_type: 'text',
                },
            ],
            should_be_pinned: shouldBePinned,
            step_id: stepId || undefined,
            location_id: locationId || undefined,
            attachments: attachments,
            client_type: 'map_manager',
            context: context,
        },
    },
    meta: {
        stepId,
        mapId,
        thunk: true,
        attachments,
    },
});

export const approvePendingPost = (pendingId, mapId, {
    lat,
    lon,
    textArray,
    text,
    tags,
    title,
    attachments,
    placeId,
    locationId,
    stepId,
}) => {
    return {
        type: APPROVE_PENDING_POST,
        request: {
            method: 'POST',
            url: `/steps3/pendingposts/${pendingId}/approve`,
            data: {
                location_parameters: {
                    lat: lat,
                    lon: lon,
                    title: title,
                    place_id: placeId || undefined,
                },
                text_array: textArray || [
                    {
                        text,
                        text_type: 'text',
                    },
                ],
                tags_ids: tags,
                step_id: stepId || undefined,
                location_id: locationId || undefined,
                attachments: attachments,
                client_type: 'map_manager',
            },
        },
        meta: {
            mapId,
            thunk: true,
        },
    };
};

export const changeStepType = (mapId, stepId, stepType, isTypeAlreadyExists) => ({
    type: CHANGE_STEP_TYPE,
    request: {
        method: 'PATCH',
        url: `/steps3/steps/${stepId}/preview`,
        data: {
            id: stepId,
            // TODO: add indications
            preview_type: isTypeAlreadyExists ? StepTypes.REGULAR : stepType,
        },
    },
    meta: {
        mapId,
        stepId,
        stepType,
        thunk: true,
    },
});

export const setMapCoverImage = (mapId, blobUrl) => ({
    type: SET_MAP_COVER_IMAGE,
    mapId,
    blobUrl,
});

export const addTagToStep = (stepId, mapId, tagId, status, tags) => ({
    type: ADD_TAG_TO_STEP,
    request: {
        method: 'POST',
        url: `/step/${stepId}/tag/${mapId}`,
        data: {
            status,
            tags: tagId,
            is_new_schema: true,
        },
    },
    meta: {
        mapId,
        stepId,
        tags: tags,
        tagsIds: tagId,
        thunk: true,
    },
});

export const deleteTagFromStep = (stepId, mapId, tagId, status, afterAddPost) => ({
    type: DELETE_TAG_FROM_STEP,
    request: {
        method: 'DELETE',
        url: `/step/${stepId}/tag/${mapId}`,
        data: {
            status,
            tags: tagId,
            is_new_schema: true,
        },
    },
    meta: {
        afterAddPost,
        stepId,
        mapId,
        tags: tagId,
        thunk: true,
    },
});

export const editStepRating = (stepId, data, mapId) => ({
    type: EDIT_STEP_RATING,
    request: {
        method: 'POST',
        url: `/steps3/steps/${stepId}/rate`,
        data,
    },
    meta: {
        mapId,
        thunk: true,
        stepId,
        newRatingComponent: data,
    },
});

export const addNewTagToStep = (stepId, mapId, data, categoryName) => ({
    type: ADD_NEW_TAG_TO_STEP,
    request: {
        method: 'POST',
        url: `/map/${mapId}/tags`,
        data,
    },
    meta: {
        mapId,
        stepId,
        categoryName: categoryName,
        thunk: true,
    },
});

export const setZoomEnd = (mapId, value) => ({
    type: SET_ZOOM_END,
    payload: value,
    meta: { mapId },
});

export const deletePostImage = (mapId, data) => ({
    type: DELETE_POST_IMAGE,
    payload: data,
    meta: { mapId },
});

// MapSettings start

export const changeSecretMap = (mapId, data) => ({
    type: CHANGE_SECRET_MAP,
    mapId,
    payload: {
        is_secret: data?.is_secret,
    },
});

export const changePermissionsMap = (mapId, toEnable) => ({
    type: CHANGE_PERMISSIONS_MAP,
    mapId,
    payload: {
        to_enable: toEnable,
    },
});

export const changeIncludesComponent = (mapId, componentType, data) => ({
    type: CHANGE_INCLUDES_COMPONENT,
    request: {
        method: 'PUT',
        url: `/map/${mapId}/component/${componentType}`,
        data,
    },
    meta: {
        mapId,
        thunk: true,
        data,
        componentType,
    },
});

export const changeGatesStatuses = (mapId, permissions_gates) => ({
    type: CHANGE_GATES_STATUSES,
    request: {
        method: 'PUT',
        url: `/map/${mapId}/permissions`,
        data: {
            gates_statuses: permissions_gates,
            //     gates_statuses: [{
            //         type: 'ALLOW_STEPS_SUGGESTIONS',
            //         to_enable: value,
            //     },
            //     ...(value ? [{
            //         type: 'ALLOW_EDIT',
            //         to_enable: false
            //     }] : [])
            //     ]
        },
    },
    meta: {
        mapId,
        permissions_gates,
        thunk: true,
    },
});

// export const editCategoryName = (mapId, categoryId, data) => ({
//     type: EDIT_CATEGORY_NAME,
//     request: {
//         method: 'PUT',
//         url: `/map/${mapId}/tagCategory/${categoryId}`,
//         data,
//     },
//     meta: {
//         mapId,
//         thunk: true,
//     },
// });

export const addMapTags = (mapId, data) => ({
    type: ADD_MAP_TAGS,
    request: {
        method: 'POST',
        url: `/map/${mapId}/tags`,
        data,
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const addMapCategories = (mapId, data) => ({
    type: ADD_MAP_CATEGORIES,
    request: {
        method: 'POST',
        url: `/map/${mapId}/tagCategories`,
        data,
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const setToComponents = (mapId, value) => ({
    type: SET_TO_COMPONENTS,
    payload: { value },
    meta: { mapId },
});

export const setToGeneral = (mapId, value) => ({
    type: SET_TO_GENERAL,
    payload: { value },
    meta: { mapId },
});
// MapSettings end

// Step component
//
export const addNewTagToMap = (mapId, { tags }) => ({
    type: ADD_NEW_TAGS_TO_MAP,
    request: {
        method: 'POST',
        url: `/map/${mapId}/tags`,
        data: {
            tags,
        },
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const setImportState = (mapId, importState) => ({
    type: SET_IMPORT_STATE,
    mapId,
    importState,
});

export const deleteImportState = (mapId, importStateId) => ({
    type: DELETE_IMPORT_STATE,
    mapId,
    importStateId,
});

export const deleteTags = (mapId, tagIds) => ({
    type: DELETE_TAGS,
    request: {
        method: 'DELETE',
        url: `/map/${mapId}/tags`,
        data: {
            tag_ids: tagIds,
        },
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const editTags = (mapId, tagId, tagName, categoryId) => ({
    type: EDIT_TAG_NAME,
    request: {
        method: 'PUT',
        url: `/map/${mapId}/tag/${tagId}`,
        data: {
            name: tagName,
            tag_id: tagId,
            category_id: categoryId,
        },
    },
    meta: {
        mapId,
        tagId,
        thunk: true,
    },
});

export const moveToCategory = (mapId, categoryId, tagId) => ({
    type: MOVE_TAG_TO_CATEGORY,
    request: {
        method: 'PUT',
        url: `/map/${mapId}/tag/${tagId}`,
        data: {
            tag_id: tagId,
            category_id: categoryId,
        },
    },
    meta: {
        mapId,
        tagId,
        thunk: true,
    },
});

export const addNewCategories = (mapId, categories) => ({
    type: ADD_NEW_CATEGORY,
    request: {
        method: 'POST',
        url: `/map/${mapId}/tagCategories`,
        data: {
            categories: categories,
        },
    },
    meta: {
        mapId,
        categories,
        thunk: true,
    },
});

export const addNewCategoryWithTags = (mapId, categoriesAndTags = []) => ({
    type: ADD_NEW_CATEGORY_WITH_TAGS,
    request: {
        method: 'POST',
        url: `/map/${mapId}/tagCategories`,
        data: {
            categories: categoriesAndTags,
        },
    },
    meta: {
        mapId,
        categoriesAndTags,
        thunk: true,
    },
});

export const editCategoryName = (mapId, category_id, name) => ({
    type: EDIT_CATEGORY_NAME,
    request: {
        method: 'PUT',
        url: `/map/${mapId}/tagCategory/${category_id}`,
        data: {
            name,
            category_id,
        },
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const editCategoryPriority = (mapId, category_id, category_priority) => ({
    type: EDIT_CATEGORY_PRIORITY,
    request: {
        method: 'PUT',
        url: `/map/${mapId}/tagCategory/${category_id}`,
        data: {
            priority: category_priority,
            category_id,
        },
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const deleteCategories = (mapId, category_ids) => ({
    type: DELETE_CATEGORIES,
    request: {
        method: 'DELETE',
        url: `/map/${mapId}/tagCategories`,
        data: {
            // FIXME:
            // name,
            category_ids,
        },
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const bulkAddTags = (mapId, stepIds = [], tagsToAdd, tagsToRemove, tags, setToAll, firstCursor) => ({
    type: BULK_ADD_TAGS,
    request: {
        method: 'POST',
        url: `/steps3/bulk-set-tags`,
        data: {
            map_id: mapId,
            step_ids: stepIds,
            tags_to_add: tagsToAdd,
            tags_to_remove: tagsToRemove,
            set_to_all: setToAll,
            first_cursor: firstCursor,
        },
    },
    meta: {
        mapId,
        tags,
        tagsToAdd,
        tagsToRemove,
        stepsIds: stepIds,
        setToAll,
        thunk: true,
    },
});

export const getStorySharingImage = (type, mapId) => ({
    type: GET_STORY_SHARING_IMAGE,
    request: {
        method: 'GET',
        url: `/story-sharing-images/${type}/${mapId}?reason=mm_instagram_share_preview&with_logo=false`,
        responseType: 'arraybuffer',
    },
    meta: {
        type,
        mapId,
        thunk: false,
    },
});

export const getStorySharingImageStep = (type, mapId, stepId) => ({
    type: GET_STORY_SHARING_IMAGE_STEP,
    request: {
        method: 'GET',
        url: `/story-sharing-images/${type}/${stepId}?reason=mm_instagram_share_preview&with_logo=false`,
        responseType: 'arraybuffer',
    },
    meta: {
        type,
        stepId,
        mapId,
        thunk: false,
    },
});

export const setGuideTaskComplete = (mapId, value) => ({
    type: GUIDE_TASKS,
    payload: { value },
    meta: { mapId },
});

export const updateStep = (mapId, stepId, data, newAttachments, type, googleStep) => ({
    type: UPDATE_STEP_INFO,
    request: {
        method: 'PUT',
        url: `/step/${stepId}`,
        data,
    },
    meta: {
        googleStep,
        type,
        stepId,
        title: data?.title,
        newAttachments,
        thunk: true,
        mapId,
    },
});

export const createAnnouncementPost = (mapId, { text, attachments, stepId, shouldBePinned }) => ({
    // FIXME:
    // type: CREATE_ANNOUNCEMENT_POSTS,
    type: CREATE_POST,
    request: {
        method: 'POST',
        url: '/steps3/posts',
        data: {
            map_id: mapId,
            text_array: [
                {
                    text,
                    text_type: 'text',
                },
            ],
            should_be_pinned: shouldBePinned,
            step_id: stepId || undefined,
            // FIXME:
            // location_id: locationId || undefined,
            attachments: attachments,
            client_type: 'map_manager',
        },
    },
    meta: {
        stepId,
        mapId,
        thunk: true,
    },
});

export const updateMemberCount = ({ count, mapId }) => {
    return {
        type: UPDATE_MEMBER_COUNT,
        payload: { count },
        meta: { mapId },
    };
};

export const clearStepAndMapImages = mapId => {
    return {
        type: CLEAR_STEP_AND_MAP_IMAGES,
        meta: { mapId },
    };
};

export const setStepsListAnchor = (mapId, { anchorPosition }) => ({
    type: SET_STEPS_LIST_ANCHOR,
    payload: { anchorPosition },
    mapId,
});

export const setIsSaveStepsListCache = (mapId, { isSaveStepsListCache }) => ({
    type: SET_IS_STEPS_LIST_CACHED,
    payload: { isSaveStepsListCache },
    mapId,
});

export const toggleFlag = ({ mapId, flagGroup, flag, setTo }) => ({
    type: UPDATE_FLAG,
    request: {
        method: 'POST',
        url: `/events`,
        data: {
            event_name: 'TOGGLE_FLAGS',
            data: {
                map_id: mapId,
                flag_group: flagGroup,
                flag,
                set_to: setTo,
            },
        },
    },
    meta: {
        mapId,
        flagGroup,
        flag,
        setTo,
        thunk: true,
    },
});
