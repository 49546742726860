import classNames from 'classnames';
import { IconLoader } from '../common/icons';
import { TagTypes } from '../../constants';

import styles from './EditorStyles.module.scss';

const MentionSuggestionEntry = props => {
    const { mention, theme, ...parentProps } = props;
    const tagListType = mention.type;

    if (mention.isLoading) {
        return (
            <span className={classNames('w-360 h-244', styles.loaderEntry)}>
                <IconLoader size={20} className={styles.loader} />
            </span>
        );
    }

    if (tagListType === TagTypes.STEP_TAG) {
        return (
            <div {...parentProps}>
                <div className={theme?.mentionSuggestionEntryContainer}>
                    <div className={theme?.mentionSuggestionsEntryContainerRight}>
                        <div className={theme?.mentionSuggestionsEntryTitle}>{mention.title}</div>
                    </div>
                </div>
            </div>
        );
    }

    if (tagListType === TagTypes.PLACE_TAG) {
        return (
            <div {...parentProps}>
                <div className={theme?.mentionSuggestionEntryContainer}>
                    <div className={theme?.mentionSuggestionsEntryContainerRight}>
                        <div className={theme?.mentionSuggestionsEntryTitle}>{mention.title}</div>
                        <div className={theme?.mentionSuggestionsEntrySubtitle}>{mention.address}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div {...parentProps}>
            <div className={theme?.mentionSuggestionsEntryContainer}>
                <div className={theme?.mentionSuggestionsEntryContainerLeft}>
                    <img src={mention.avatar} alt={mention.avatar} className={theme?.mentionSuggestionsEntryAvatar} role='presentation' />
                </div>
                <div className={theme?.mentionSuggestionsEntryContainerRight}>
                    <div className={theme?.mentionSuggestionsEntryTitle}>{mention.title}</div>
                </div>
            </div>
        </div>
    );
};

export default MentionSuggestionEntry;
