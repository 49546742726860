import { PlatformTypes } from '../../constants';
import {
    CHANGE_PIN_POST,
    DELETE_POST,
    GET_POSTS,
    GET_UPLOAD_POST_IMG_LINK,
    GET_POST,
    GET_COMMENTS,
    CHANGE_CLAP_POST,
    GET_COMMENTS_IN_POST,
    CREATE_POSTS_IN_POST,
    SHOW_TEXT,
    EDIT_POST,
    POST_ANNOUNCEMENT,
    SEEN_ANNOUNCEMENT,
    CREATE_FEED_POST,
} from './types';

export const getPosts = (stepId, cursor) => ({
    type: GET_POSTS,
    request: {
        method: 'GET',
        url: `/steps3/posts?cursor=${cursor}`,
    },
    meta: {
        stepId,
        thunk: true,
    },
});

export const deleteStepPost = (mapId, stepId, postId, click) => ({
    type: DELETE_POST,
    request: {
        method: 'DELETE',
        url: `/steps3/posts/${postId}`,
    },
    meta: {
        mapId,
        stepId,
        postId,
        thunk: true,
        click,
    },
});

export const getStepPost = postId => ({
    type: GET_POST,
    request: {
        method: 'GET',
        url: `/steps3/posts/${postId}`,
    },
    meta: {
        postId,
        thunk: true,
    },
});

export const getCommentsInPost = cursor => ({
    type: GET_COMMENTS_IN_POST,
    request: {
        method: 'GET',
        url: `/steps3/posts?cursor=${cursor}`,
    },
    meta: {
        cursor,
        thunk: true,
    },
});

export const getComments = cursor => ({
    type: GET_COMMENTS,
    request: {
        method: 'GET',
        url: `/steps3/posts?cursor=${cursor}`,
    },
    meta: {
        cursor,
        thunk: true,
    },
});

export const createFeedPost = ({ mapId, parentPostId, postId, textArray, attachments, context }) => {
    return {
        type: CREATE_FEED_POST,
        request: {
            method: 'POST',
            url: `/steps3/feed-posts`,
            data: {
                client_type: PlatformTypes.MAP_MANAGER,
                map_id: mapId,
                parent_post_id: parentPostId,
                post_id: postId,
                text_array: textArray,
                attachments,
                context: context,
            },
        },
        meta: {
            mapId,
            thunk: true,
        },
    };
};

export const createPostsInPost = (data, postStepId, clickReplyPage, mapId) => ({
    type: CREATE_POSTS_IN_POST,
    request: {
        method: 'POST',
        url: `/steps3/posts`,
        data,
    },
    meta: {
        mapId,
        click: clickReplyPage,
        stepId: postStepId,
        postId: data?.parent_post_id,
        thunk: true,
    },
});

export const changePinPost = (stepId, postId, action) => ({
    type: CHANGE_PIN_POST,
    request: {
        method: 'POST',
        url: `/steps3/posts/${postId}/pin`,
        data: {
            action,
        },
    },
    meta: {
        stepId,
        postId,
        action: action === 1,
        thunk: true,
    },
});

export const getUploadPostImgLink = () => ({
    type: GET_UPLOAD_POST_IMG_LINK,
    request: {
        method: 'GET',
        url: '/uploadpostimage',
    },
    meta: {
        thunk: true,
    },
});

export const changeClapPost = (stepId, postId, action, click) => ({
    type: CHANGE_CLAP_POST,
    request: {
        method: 'POST',
        url: `/steps3/posts/${postId}/clap`,
        data: {
            action,
        },
    },
    meta: {
        stepId,
        click,
        postId,
        action: action === 1,
        thunk: true,
    },
});

export const callRequestAgain = ({ type, method, url, data, meta }) => {
    return {
        type: type,
        request: {
            method: method,
            url: url,
            data: data,
        },
        meta: meta,
    };
};

export const getShowTextId = id => ({
    type: SHOW_TEXT,
    payload: id,
});

export const editPost = (id, data) => ({
    type: EDIT_POST,
    request: {
        method: 'PUT',
        url: `/steps3/posts/${id}`,
        data,
    },
    meta: {
        mapId: data?.mapId,
        postId: id,
        stepId: data?.stepId,
        thunk: true,
    },
});

export const postAnnouncement = ({ textArray = [], mapId, attachments, postId } = {}) => ({
    type: POST_ANNOUNCEMENT,
    request: {
        method: 'POST',
        url: `/steps3/announcement`,
        data: {
            client_type: PlatformTypes.MAP_MANAGER,
            map_id: mapId,
            post_id: postId,
            text_array: textArray,
            attachments,
        },
    },
});

export const seenAnnouncement = ({ userId, postId }) => ({
    type: SEEN_ANNOUNCEMENT,
    payload: {
        userId,
        postId,
    },
});
