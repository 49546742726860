import { IMAGE_PREVIEW } from './types';

const initialState = {
    imagePreviewShow: false,
    imagePreviewArray: [],
    imagePreviewIndex: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case IMAGE_PREVIEW:
            return {
                ...state,
                imagePreviewShow: action.payload.imagePreviewShow,
                imagePreviewArray: [...action.payload.imagePreviewArray],
                imagePreviewIndex: action.payload.imagePreviewIndex,
                imagePreviewStepId: action.payload.stepId,
            };
        default:
            return state;
    }
};

export default reducer;
