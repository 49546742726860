export const flattenObject = ob => {
    const toReturn = {};

    for (const i in ob) {
        // eslint-disable-next-line no-prototype-builtins
        if (!ob.hasOwnProperty(i)) {
            continue;
        }

        if (typeof ob[i] === 'object') {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
                // eslint-disable-next-line no-prototype-builtins
                if (!flatObject.hasOwnProperty(x)) {
                    continue;
                }
                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
};
