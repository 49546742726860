import React from 'react';
import styles from './index.module.scss';
import BaseModal, { ModalSizes } from '../../../modalSysyem/BaseModal';
import CustomButton, { ButtonType } from '../../common/buttons/CustomButton';

const ConfirmationModal = ({ title, isOpen, description, withCancelButton, ctaTitle, ctaType = ButtonType.PRIMARY_BLUE, onClose, onConfirm }) => {
    return (
        <BaseModal className={styles.container} isActive={isOpen} size={ModalSizes.SMALL} onClose={onClose}>
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
            <div className={styles.buttons}>
                <CustomButton className={styles.cta} type={ctaType} onClick={onConfirm}>
                    {ctaTitle}
                </CustomButton>
                {withCancelButton && (
                    <CustomButton className={styles.cancelButton} type={ButtonType.TRANSPARANT} onClick={onClose}>
                        Cancel
                    </CustomButton>
                )}
            </div>
        </BaseModal>
    );
};

export default ConfirmationModal;
