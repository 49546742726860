import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import IconRecommended from '../../common/icons/IconHighlight';
import Tooltip, { TooltipDirections, TooltipColors } from '../../common/Tooltips/Tooltip';
import copies from '../../../copies.json';

const highlightCopies = copies.highlight;

const RecommendStepButton = ({ isRecommended, handleRecommendClicked }) => {
    const buttonPropsByStatus = isRecommended
        ? { fillColor: '#FFCD4A', tooltipText: highlightCopies.tooltip.highlighted }
        : { fillColor: '#FFF', tooltipText: highlightCopies.tooltip.regular };
    const { fillColor, tooltipText } = buttonPropsByStatus;

    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className={classNames(styles.block, {
                [styles.selected]: isRecommended,
                [styles.regular]: !isRecommended,
            })}
        >
            <IconRecommended stroke='#3A3C3F' fill={fillColor} onClick={handleRecommendClicked} />
            {showTooltip && (
                <Tooltip
                    description={tooltipText}
                    className={styles.tooltip}
                    bgColor={TooltipColors.BLACK}
                    direction={TooltipDirections.DOWN}
                    withFooter={false}
                />
            )}
        </div>
    );
};

export default RecommendStepButton;