import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';

const Tag = ({ fromWhere, handleSelectTagClick, onClick, tag, idx, checked, canDelete, classname, id }) => {
    const handleClick = () => {
        if (canDelete) {
            mixpanel.track(mixpanelEvents.FILTER_REMOVE, {
                [mixpanelProperties.FROM_WHERE]: fromWhere,
            });
            onClick(tag);
        } else {
            if (checked) {
                mixpanel.track(mixpanelEvents.FILTER_REMOVE, {
                    [mixpanelProperties.FROM_WHERE]: fromWhere,
                });
            }
            handleSelectTagClick(tag);
        }
    };

    return (
        <div
            id={id}
            key={idx}
            onClick={handleClick}
            className={classNames(styles.block, classname, {
                [styles.selected]: checked,
            })}
        >
            {tag?.name}
            {canDelete && <img className={styles.delete} src='/assets/img/icoDeleteGray.png' alt='' />}
        </div>
    );
};

export default Tag;
