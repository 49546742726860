import { noop } from 'lodash';
import { useContext } from 'react';
import { ToastContext, ToastTypes } from '../context';

const useToast = () => {
  const { toast, setToast } = useContext(ToastContext);
  return {
    toast,
    setToast: ({ id, values, onFinish = noop, withIcon = true }) => {
      setToast({ id, values, onFinish, withIcon });
    },
    setErrorToast: ({ id, values, onFinish = noop, withIcon = true, color = 'red' }) => {
      setToast({ id, values, type: ToastTypes.ERROR, onFinish, withIcon, color });
    },
  };
};

export default useToast;
