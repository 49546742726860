import React from 'react';
import styles from './index.module.scss';
import AppleSignin from 'react-apple-signin-auth';
import config from '../../../../config';

const AppleLoginButton = ({ handleSocialLogin, handleSocialLoginFailure, text, fireMixpanelEvent }) => {
    return (
        <div>
            <AppleSignin
                authOptions={{
                    scope: 'email name',
                    nonce: 'nonce',
                    state: 'state',
                    usePopup: true,
                    clientId: config.social.appleClientId,
                    redirectURI: `${window.location.origin}/login`,
                }}
                noDefaultStyle
                render={renderProps => (
                    <button
                        onClick={e => {
                            fireMixpanelEvent();
                            renderProps.onClick(e);
                        }}
                        className={styles.appleButton}
                    >
                        <img className={styles.appleIcon} src='/assets/img/steps3LoginLayout/Apple.svg' alt=''/>
                        {text}
                    </button>
                )}
                onSuccess={user => {
                    console.log(user);
                    handleSocialLogin(user, 'apple');
                }}
                onError={err => {
                    console.log(err);
                    handleSocialLoginFailure(err);
                }}
            />
        </div>
    );
};

export default AppleLoginButton;
