import React, { useState, useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import { mapSelectors, mapActions } from '../../../../redux/map';
import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import { X, Plus } from 'phosphor-react';
import copiesPrefix from '../../../../copies.json';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import RatingInput from './RatingInput';
import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

const copies = copiesPrefix.ratings.settingsModal;
const emptyInputDefaultValue = { text: '', isNewRating: true };
const newCategoryInputDefaultValue = {
    isOpen: false,
    value: '',
};

const ModalsRatingSettings = ({ isActive, onClickPrev, step }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const ratingComponent = useMemo(() => map?.components?.find(component => component.type === 'rating'), [map]);
    const [ratings, setRatings] = useState(ratingComponent?.rating_subcomponents);
    const [addNewCategoryInput, setAddNewCategoryInput] = useState(newCategoryInputDefaultValue);
    const [ratingExists, setRatingExists] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        setRatings(ratingComponent?.rating_subcomponents);
    }, [ratingComponent]);

    const resetAndCloseModal = () => {
        onClickPrev();
        updateNewCategoryState(newCategoryInputDefaultValue);
        setRatings(ratingComponent.rating_subcomponents);
    };

    const removeRating = index => {
        // @TODO - Update with popUp
        setRatings(prevRatings => {
            const ratingsCopy = cloneDeep(prevRatings);
            ratingsCopy[index].delete = true;
            return ratingsCopy;
        });
    };

    const onClickDiscard = (index, rating) => {
        mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_RATING_CATEGORY_DELETED, {
            [mixpanelProperties.STEP_ID]: step?._id,
            [mixpanelProperties.STEP_NAME]: step?.title,
            [mixpanelProperties.CATEGORY]: rating?.text,
        });
        const isAddRatingInput = rating.isNewRating && !rating.added;
        if (isAddRatingInput) {
            updateNewCategoryState(newCategoryInputDefaultValue);
            return;
        }
        removeRating(index);
    };

    const onInputChange = (index, value, newRatingInput) => {
        if (newRatingInput) {
            updateNewCategoryState({ value });
        }

        if (!Number.isInteger(index)) return;
        setRatings(prevRatings => {
            const ratingsCopy = cloneDeep(prevRatings);
            ratingsCopy[index].text = value;
            return ratingsCopy;
        });
    };

    const addNewRating = rating => {
        setRatings(prevRatings => [...prevRatings, rating]);
        updateNewCategoryState(newCategoryInputDefaultValue);
    };

    const renderInput = (rating, index, newRatingInput) => {
        if (rating.delete) return null;
        return (
            <RatingInput
                className={styles.ratingInput}
                rating={rating}
                autoFocus={rating.isNewRating && !rating.added}
                onClickDiscard={() => onClickDiscard(index, rating)}
                onChange={value => onInputChange(index, value, newRatingInput)}
                addNewRating={addNewRating}
                isRatingExists={inputValue => checkIfRatingExists(inputValue, index)}
            />
        );
    };

    const onClickSave = async () => {
        setSaveLoading(true);
        const withoutDeletedRatings = ratings.filter(rating => !rating.delete);
        if (addNewCategoryInput.value) {
            withoutDeletedRatings.push({
                text: addNewCategoryInput.value,
                isNewRating: true,
                added: true,
            });
        }
        dispatch(
            mapActions.changeIncludesComponent(mapId, 'rating', {
                rating_subcomponents: withoutDeletedRatings.map(rating => {
                    if (rating.isNewRating) {
                        rating.id = nanoid(11);
                    }
                    return {
                        id: rating.id,
                        text: rating.text,
                        type: 'score1to5',
                    };
                }),
            })
        ).then(() => {
            for (const cat of withoutDeletedRatings) {
                if (cat.isNewRating) {
                    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_RATING_SETTINGS_CATEGORY_ADDED, {
                        [mixpanelProperties.STEP_ID]: step?._id,
                        [mixpanelProperties.STEP_NAME]: step?.title,
                        [mixpanelProperties.CATEGORY]: cat.text,
                    });
                }
            }
            setSaveLoading(false);
            updateNewCategoryState({ value: '' });
            onClickPrev();
        });
    };

    const checkIfRatingExists = (inputValue, ratingIndex) =>
        ratings.some((existingRating, index) => {
            const isExists = existingRating.text.toLowerCase() === inputValue.toLowerCase();
            const isSameInput = index === ratingIndex;
            setRatingExists(isExists && !isSameInput);
            return isExists && !isSameInput;
        });

    const isUpdated = useMemo(() => {
        const ratingsSubComponents = ratingComponent?.rating_subcomponents;
        const existingRatings = ratings?.filter(rating => !rating.delete);
        const isRatingAdded = existingRatings?.length !== ratingsSubComponents?.length;
        const isRatingChanged = existingRatings?.some((rating, index) => rating?.text !== ratingsSubComponents[index]?.text);

        return !ratingExists && (isRatingAdded || isRatingChanged || addNewCategoryInput.value);
    }, [ratings, ratingComponent, addNewCategoryInput, ratingExists]);

    const updateNewCategoryState = obj => {
        setAddNewCategoryInput(prevState => ({ ...prevState, ...obj }));
    };

    return (
        <BaseModal className={styles.modal} size={ModalSizes.MEDIUM} isActive={isActive} onClose={resetAndCloseModal}>
            <X className={styles.xIcon} size={16} color='#212B37' onClick={resetAndCloseModal} />
            <div className={styles.header}>
                <h2 className={styles.title}>{copies.title}</h2>
                <span className={styles.subTitle}>{copies.subTitle}</span>
            </div>
            <div className={styles.content}>
                {ratings?.map((rating, index) => renderInput(rating, index))}
                {addNewCategoryInput.isOpen && renderInput(emptyInputDefaultValue, undefined, true)}
                <span
                    className={styles.addCategoryButton}
                    onClick={() => {
                        mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_RATING_CATEGORY_CLICKED, {
                            [mixpanelProperties.STEP_ID]: step?._id,
                            [mixpanelProperties.STEP_NAME]: step?.title,
                        });
                        updateNewCategoryState({ isOpen: true });
                    }}
                >
                    <Plus className={styles.plus} weight='bold' size={14} color='#2171EC' /> {copies.addCategoryButton}
                </span>
            </div>
            <div className={styles.footer}>
                <CustomButton className={styles.saveButton} type={ButtonType.PRIMARY} onClick={onClickSave} loader={isSaveLoading} disabled={!isUpdated}>
                    {copies.save}
                </CustomButton>
            </div>
        </BaseModal>
    );
};

export default ModalsRatingSettings;
