import React from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import { ClickAwayListener } from '@material-ui/core';

const ModalsDeleteMember = ({ textFirst, onCancel, onRemove }) => {
    return (
        <div className={styles.container} role='alertdialog'>
            <div className={styles.wrapper}>
                <ClickAwayListener onClickAway={onCancel}>
                    <div className={styles.panel}>
                        {textFirst && (
                            <span aria-describedby='delete-member-description' className={styles.textFirst}>
                                {textFirst}
                            </span>
                        )}
                        <div className={styles.buttons}>
                            <CustomButton className={styles.cancel} onClick={onCancel} type={ButtonType.SECONDARY_BLACK}>
                                Cancel
                            </CustomButton>
                            <CustomButton className={styles.discard} onClick={onRemove} type={ButtonType.DANGER}>
                                Remove
                            </CustomButton>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default ModalsDeleteMember;
