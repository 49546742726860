import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import styles from './index.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import { mapSelectors } from '../../../../redux/map';
import { postSelectors } from '../../../../redux/post';
import { stepActions } from '../../../../redux/step2';
import { Post } from '../../../PostStructure';
import { LoadingBlock, DiscoveredBlock } from '../../../Feed/LoadingPostsBlocks';

let scrollTimeoutId = null;

const Posts = ({ className, stepId, onClickPost, scrollTarget = '', onPinPostChange = noop, usernameHeadlineOnly = false }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const step = useSelector(mapSelectors.steps(mapId))[stepId];
    const postIds = step.postsList?.postsListIds || [];
    const cursor = useSelector(postSelectors.cursor);
    const [isScrolling, setIsScrolling] = useState();
    const scrollRef = useRef();

    const loadMorePosts = () => {
        dispatch(stepActions.getPosts(mapId, stepId, cursor));
    };

    return (
        <div>
            <InfiniteScroll
                dataLength={postIds.length}
                scrollableTarget={scrollTarget}
                next={loadMorePosts}
                hasMore={!!cursor}
                className={classNames([styles.infiniteFeedPosts, className])}
                endMessage={<DiscoveredBlock user={step.creator_preview} className={styles.discoverBlock} createdAt={step.created_at} />}
                loader={<LoadingBlock />}
                ref={scrollRef}
                onScroll={() => {
                    if (scrollTimeoutId) clearTimeout(scrollTimeoutId);
                    setIsScrolling(true);

                    scrollTimeoutId = setTimeout(() => {
                        setIsScrolling(false);
                    }, 500);
                }}
            >
                {postIds.map(postId => {
                    return (
                        <Post
                            usernameHeadlineOnly
                            isPinnable
                            onPinPostChange={onPinPostChange}
                            key={postId}
                            className={styles.post}
                            onClick={() => onClickPost(postId)}
                            postId={postId}
                            headerSettings={{
                                viewOnMap: false,
                                showSecondaryImage: false,
                                creatorTitle: true,
                                mentionsStepId: stepId,
                            }}
                            allowComment={false}
                            fromWhere='step_profile'
                            isScrolling={isScrolling}
                        />
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default Posts;
