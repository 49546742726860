import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { mapSelectors, mapActions } from '../../../redux/map';
import { userSelectors } from '../../../redux/user';
import Routes from '../../../config/routes';
import styles from './index.module.scss';
import { CaretRight } from 'phosphor-react';
import ProgressBar from '../../common/ProgressBar';
import copiesPrefix from '../../../copies.json';
import useStagesProgress from './useStagesProgress';
import { PopperTooltip } from '../../common/Tooltips';
import MapStagesIndicationTooltip from './MapStagesIndicationTooltip';
import mixpanel, { mixpanelEvents } from '../../../helpers/mixpanel';

const copies = copiesPrefix.mapStages;

export const TooltipTypes = {
    READY_TO_LEVEL_UP: 'MAP_STAGE_ONBOARDING_COMPLETE',
    ALOMOST_THERE: 'MAP_STAGE_75_PERCENT_COMPLETE',
};

const MapStagesIndication = () => {
    const navigate = useNavigate();
    const { pathname, state: prevUrlState } = useLocation();
    const dispatch = useDispatch();
    const [isSeenTooltip, setIsSeenTooltip] = useState(false);
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const isOpen = pathname.includes(Routes.MAP_STAGES);
    const { total, progress } = useStagesProgress();
    const mapTooltips = useMemo(() => map?.flags?.TOOLTIP, [map]);
    const progressPercent = useMemo(() => Math.round((progress / total) * 100), [progress, total]);
    const [tooltip, setTooltip] = useState('');

    useEffect(() => {
        if (!mapTooltips) {
            return;
        }

        const tooltip = initialTooltipType();
        setTooltip(tooltip);
    }, [mapTooltips]);

    const initialTooltipType = () => {
        if (100 > progressPercent && progressPercent >= 75) {
            return TooltipTypes.ALOMOST_THERE;
        }
        if (mapTooltips[TooltipTypes.READY_TO_LEVEL_UP]) return TooltipTypes.READY_TO_LEVEL_UP;
        return '';
    };

    const navigateBack = () => {
        const isPrevUrlIncludesMap = prevUrlState?.url.includes(Routes.MAP);
        const isPrevUrlIncludesFeed = prevUrlState?.url.includes(Routes.FEED);
        if (isPrevUrlIncludesMap && !isPrevUrlIncludesFeed) {
            navigate(-1);
        } else {
            navigate(`/${Routes.MAP}`);
        }
    };

    const tooltipCtas = {
        [TooltipTypes.READY_TO_LEVEL_UP]: () => unsetTooltip(TooltipTypes.READY_TO_LEVEL_UP),
        [TooltipTypes.ALOMOST_THERE]: () => unsetTooltip(TooltipTypes.ALOMOST_THERE),
    };

    const onClickTooltip = ({ moveToMapStages }) => {
        setTooltip('');
        tooltipCtas[tooltip]();
        moveToMapStages && openMapStages();
        setIsSeenTooltip(true);
    };

    const openMapStages = () => {
        navigate(`${Routes.MAP}/${Routes.MAP_STAGES}`, {
            state: {
                url: pathname,
                context: 'menu_click',
            },
        });
    };
    const onClickMapStages = () => {
        mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_MENU_CLICKED);

        if (isOpen) {
            navigateBack();
            return;
        }

        setTooltip(false);
        openMapStages();
    };

    const unsetTooltip = flagName => {
        dispatch(
            mapActions.toggleFlag({
                mapId,
                flagGroup: 'TOOLTIP',
                flag: flagName,
                setTo: false,
            })
        );
    };

    return (
        <PopperTooltip
            placement='right'
            triggeredBy='always'
            isActive={!isSeenTooltip && tooltip}
            tooltip={<MapStagesIndicationTooltip onClick={onClickTooltip} type={tooltip} />}
            tooltipClass={styles.tooltip}
            className={styles.tooltipWrapper}
            withArrow={false}
        >
            <div className={classNames(styles.block, { [styles.selected]: isOpen })} onClick={onClickMapStages}>
                <div className={styles.content}>
                    {map.map_level > 0 && <img className={styles.advancedNotation} alt='' src='/assets/img/mapStagesIcon.svg' />}
                    <span className={styles.title}>{copies.title}</span>
                    <CaretRight size={12} weight='bold' color={isOpen ? '#515B67' : '#212B37'} />
                </div>
                {map.map_level === 1 && (
                    <ProgressBar className={classNames(styles.progressBar, { [styles.progressBarDark]: !isOpen })} outOf={total} progress={progress} />
                )}
            </div>
        </PopperTooltip>
    );
};

export default MapStagesIndication;
