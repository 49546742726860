import {
    GET_FEED_POSTS,
    UPDATE_FEED,
    ADD_FEED_POST,
    UPDATE_FEED_POST,
    DELETE_FEED_POST,
    ADD_ANNOUNCEMENT,
    SET_IS_CONFIRMED_ANNOUNCEMENT_POPOVER,
    RESET_FEED,
    SET_FEED_ANCHOR,
} from './constants';

export const getFeedPosts = ({ mapId, cursor, lat = 0, lon = 0, onlyAnnouncementPosts = false, isQuiet }) => ({
    type: GET_FEED_POSTS,
    request: {
        method: 'GET',
        url: '/steps3/posts',
        params: {
            cursor,
            map_id: mapId,
            near_lat: lat,
            near_lon: lon,
            only_announcement_posts: onlyAnnouncementPosts,
            is_map_feed: !onlyAnnouncementPosts,
            limit: 10,
        },
    },
    meta: {
        cursor,
        thunk: true,
        isQuiet,
    },
});

export const updateFeed = ({ posts }) => ({
    type: UPDATE_FEED,
    payload: { posts },
});

export const addFeedPost = ({ post }) => ({
    type: ADD_FEED_POST,
    payload: { post },
});

export const updateFeedPost = ({ post }) => ({
    type: UPDATE_FEED_POST,
    payload: { post },
});

export const deleteFeedPost = ({ postId }) => ({
    type: DELETE_FEED_POST,
    payload: { postId },
});

export const addAnnouncement = ({ announcement }) => ({
    type: ADD_ANNOUNCEMENT,
    payload: { announcement },
});

export const setFeedAnchor = ({ anchorPosition }) => ({
    type: SET_FEED_ANCHOR,
    payload: { anchorPosition },
});

export const resetFeed = () => ({
    type: RESET_FEED,
});

export const setIsConfirmedAnnouncementPopover = ({ isConfirmed }) => ({
    type: SET_IS_CONFIRMED_ANNOUNCEMENT_POPOVER,
    payload: { isConfirmed },
});
