import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CircularProgressbar as Circular, buildStyles } from 'react-circular-progressbar';
import { Check } from 'phosphor-react';
import styles from './index.module.scss';

const CircularProgressBar = ({ progress = 0, outOf = 100, className, size = 16 }) => {
    const progressPercent = Math.round((progress / outOf) * 100);
    const sizePx = size + 'px';
    const isCompleted = progressPercent >= 100;

    const renderCompletedState = () => <Check color='#fff' size={10} weight='bold' className={styles.check} />;

    const renderProgressState = () => (
        <Circular
            value={progressPercent}
            strokeWidth={16}
            styles={buildStyles({
                pathColor: '#02C77C',
                trailColor: '#DCE2E9',
                strokeLinecap: 'butt',
            })}
        />
    );

    return (
        <div className={classNames(styles.block, { [styles.completed]: isCompleted }, className)} style={{ height: sizePx, width: sizePx }}>
            {isCompleted ? renderCompletedState() : renderProgressState()}
        </div>
    );
};

export default CircularProgressBar;

CircularProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    outOf: PropTypes.number,
};
