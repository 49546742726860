import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './stepGhostSuggestToAdd.module.scss';
import Avatar from '@material-ui/core/Avatar';
import { generateImageUrl } from '../../../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../../../redux/user';
import { mapActions } from '../../../../../redux/map';
import copiesPrefix from '../../../../../copies.json';

const copies = copiesPrefix.post;

const StepGhost = ({ location, text, files, hover, mapId, stepId }) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.user);
    const [hoveredStep, setHoverStep] = useState(null);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (stepId) {
            setLoader(true);
            setHoverStep(null);
            dispatch(mapActions.getFullStepData(mapId, stepId)).then(({ data }) => {
                setHoverStep(data?.step);
                setLoader(false);
            });
        } else if (hover) {
            setLoader(false);
        }
    }, [stepId]);
    return (
        <>
            {!hoveredStep && loader ? (
                <div className={styles.previewStep}>
                    <div className={classNames([styles.title, 'animate'])} style={{ width: '100%', height: '30px' }} />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={classNames([styles.img, 'animate'])} style={{ minWidth: '32px', height: '32px' }} />
                        <div className={classNames([styles.tags, 'animate'])} style={{ width: '100%', height: '20px' }} />
                    </div>
                    <div className={classNames([styles.componentNamePreview, 'animate'])} style={{ width: '100%', height: '30px' }} />
                    <div className={classNames([styles.postInfo, 'animate'])} style={{ width: '88%', height: '80px' }} />
                </div>
            ) : (
                <div className={styles.previewStep}>
                    <p className={styles.title}>{location?.title || location?.description || hoveredStep?.title}</p>
                    <div className={styles.info}>
                        <p className={styles.userInfo}>
                            <Avatar
                                alt={hoveredStep ? hoveredStep.secondaries[0]?.creator_preview?.display_name : user.name}
                                src={generateImageUrl({
                                    imageId: hoveredStep ? hoveredStep.secondaries[0]?.creator_preview?.image : user?.image,
                                    width: 32,
                                    height: 32,
                                    reason: 'show_user_image',
                                })}
                                className={styles.avatar}
                            />
                            <span>{hoveredStep ? hoveredStep.secondaries[0]?.creator_preview?.display_name : user?.name}</span>
                        </p>
                        <p className={styles.discovered}>{copies.discovered_place}</p>
                        {text?.length > 0 && <p className={styles.text}>{text}</p>}
                        {hoveredStep?.secondaries[0]?.text.length > 0 && <p className={styles.text}>{hoveredStep?.secondaries[0]?.text}</p>}
                        {files?.length > 0 && (
                            <div style={{ position: 'relative' }}>
                                {files?.length > 1 && (
                                    <div className={styles.imagesCount}>
                                        <img src='/assets/img/steps3Images/Vector.svg' alt='' />
                                        <span>+{files?.length - 1}</span>
                                    </div>
                                )}
                                <img
                                    className={styles.image}
                                    src={generateImageUrl({
                                        imageId: files[0]?.attachment?.image_id,
                                        width: 224,
                                        height: 150,
                                        reason: 'step_view_image',
                                    })}
                                    alt=''
                                />
                            </div>
                        )}
                        {hoveredStep?.secondaries[0]?.attachments?.length > 0 && (
                            <div style={{ position: 'relative' }}>
                                {hoveredStep?.secondaries[0]?.attachments?.length > 1 && (
                                    <div className={styles.imagesCount}>
                                        <img src='/assets/img/steps3Images/Vector.svg' alt='' />
                                        <span>+{hoveredStep?.secondaries[0]?.attachments?.length - 1}</span>
                                    </div>
                                )}
                                <img
                                    className={styles.image}
                                    src={generateImageUrl({
                                        imageId: hoveredStep?.secondaries[0]?.attachments[0]?.attachment?.image_id,
                                        width: 224,
                                        height: 150,
                                        reason: 'step_view_image',
                                    })}
                                    alt=''
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default StepGhost;
