import classNames from 'classnames';

import styles from './Skeleton.module.scss';

const PageSkeleton = () => {
    return (
        <div className={styles.post}>
            <div className={styles.header}>
                <span className={classNames(styles.image, 'animate')} />
                <div className={styles.user}>
                    <span className={classNames(styles.name, 'animate')} />
                    <span className={classNames(styles.details, 'animate')} />
                </div>
                <span className={classNames(styles.button, 'animate')} />
            </div>
            <span className={classNames(styles.text, 'animate')} />
            <span className={classNames(styles.carousel, 'animate')} />
        </div>
    );
};

export default PageSkeleton;
