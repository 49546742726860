import React from 'react';
import CustomButton, { ButtonSize, ButtonType } from '../common/buttons/CustomButton';
import copies from '../../copies.json';

import styles from './index.module.scss';

const EmptyStepList = ({ onClickAddPost }) => {
    return (
        <div className={styles.content}>
            <p>{copies.mapManager.emptyState.no_steps}</p>
            <p className={styles.bold}>{copies.mapManager.emptyState.create_label}</p>
            <div className={styles.buttonBlock}>
                <CustomButton
                    className={styles.button}
                    onClick={onClickAddPost}
                    size={ButtonSize.MEDIUM}
                    type={ButtonType.SECONDARY_BLUE}
                    text={copies.mapManager.emptyState.create_button}
                />
            </div>
            <img src={'/assets/img/emptyMap.svg'} alt={''} />
        </div>
    );
};

export default EmptyStepList;
