import React, { useEffect, useState, useContext } from 'react';
import styles from './index.module.scss';
import copiesPrefix from '../../../copies.json';
import CustomButton, { ButtonType } from '../../../components/common/buttons/CustomButton';
import SearchInput from '../../../components/common/inputs/SearchInput2';
import { PaperPlaneTilt } from 'phosphor-react';
import { validateEmail, sendMMEmailLink } from '../../../helpers';
import { ClickAwayListener } from '@material-ui/core';
import { ToastContext, ToastDurations } from '../../../context/ToastContext';

const copies = copiesPrefix.onboarding.mobileInitialScreen;

const GetMailLink = ({ onClickPrev }) => {
    const { setToast } = useContext(ToastContext);
    const [sending, setSending] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    
    useEffect(() => {
        setIsValidEmail(validateEmail(inputValue));
    }, [inputValue]);
    
    const onSend = async () => {
        setSending(true);
        await sendMMEmailLink(inputValue);
        onClickPrev();
        setToast({ text: copies.toast, timout: ToastDurations.LONG, withIcon: false });
    };
    
    const onInputChange = e => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
    };
    
    return (
        <ClickAwayListener onClickAway={onClickPrev}>
            <div className={styles.getMailLinkBlock}>
                <span className={styles.notch} onTouchStart={onClickPrev}/>
                <h3 className={styles.title}>{copies.sendLinkExtended}</h3>
                <p className={styles.description}>{copies.getLinkDescription}</p>
                <SearchInput autoFocus onChange={onInputChange} value={inputValue} placeholder={copies.inputPlaceHolder}/>
                <div className={styles.footer}>
                    <CustomButton className={styles.backButton} type={ButtonType.GHOST2} onClick={onClickPrev}>
                        {copies.back}
                    </CustomButton>
                    <CustomButton className={styles.sendButton} type={ButtonType.PRIMARY} onClick={onSend} disabled={!isValidEmail} loader={sending}>
                        <PaperPlaneTilt className={styles.planeIcon} size={16} weight='fill' color={isValidEmail ? '#fff' : '#ABB4BF'}/> {copies.send}
                    </CustomButton>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default GetMailLink;
