import React, { useCallback, useEffect, useState } from 'react';
import { noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions, userSelectors } from '../../redux/user';
import MapName from './MapName';
import MapCreationFinish from './MapCreationFinish';
import ROUTES from '../../config/routes';
import ModalsImportSteps from '../../components/common/modals/ModalsImportSteps';
import ImagePreview from '../../components/common/ImagePreview';
import { modalsActions, modalsSelectors } from '../../redux/modals';
import Loader from '../../components/Loader';
import { onboardActions } from '../../redux/onboard';
import InstagramImport from './InstagramImport';

import styles from './index.module.scss';

const Onboard = ({ updateMaps, maps, onLogout = noop, selectedMap, containerChange = noop, setIsLoading = noop }) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.user);
    const imagePreviewShow = useSelector(modalsSelectors.imagePreviewShow);
    const imagePreviewArray = useSelector(modalsSelectors.imagePreviewArray);
    const imagePreviewIndex = useSelector(modalsSelectors.imagePreviewIndex);
    const navigate = useNavigate();
    const [openPopup, setPopup] = useState(false);
    const [page, setPage] = useState(0);
    const [mapName, setMapName] = useState('');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [importSteps, setImportSteps] = useState(false);
    
    useEffect(() => {
        setIsLoading?.(false);
        localStorage.removeItem('importMode');
        if (localStorage.getItem('is-instagram-failed')) setPage(1);
        dispatch(onboardActions.setOnboardMapCenter(null));
    }, []);
    
    const handleImportSteps = () => {
        navigate('/' + ROUTES.MAP);
        containerChange('steps');
        setImportSteps(false);
    };
    
    const handleSetPopup = value => {
        setPopup(value);
    };
    
    const handleClickBack = value => {
        setPage(value);
    };
    
    const handleClickNext = value => {
        setMapName(mapName.trim());
        setPage(value);
    };
    const toggleImagePreview = ({ imagePreviewShow, imagePreviewArray, imagePreviewIndex }) => {
        dispatch(
            modalsActions.toggleImagePreview({ imagePreviewShow: imagePreviewShow, imagePreviewArray: imagePreviewArray, imagePreviewIndex: imagePreviewIndex })
        );
    };
    
    const handleNavigation = () => {
        const afterPage = localStorage.getItem('page');
        if (afterPage === 'login' && page === 0) {
            dispatch(userActions.setUserAuth(0));
            navigate(ROUTES.LOGIN);
            localStorage.setItem('page', null);
            localStorage.setItem('at', null);
            localStorage.setItem('rt', null);
        } else if (afterPage === 'register' && page === 0) {
            dispatch(userActions.setUserAuth(0));
            navigate(ROUTES.SIGN_UP);
            localStorage.setItem('page', null);
            localStorage.setItem('at', null);
            localStorage.setItem('rt', null);
        } else if (page - 1 < 0) {
            dispatch(userActions.setUserAuth(0));
            navigate(ROUTES.SIGN_UP);
            localStorage.setItem('page', null);
            localStorage.setItem('at', null);
            localStorage.setItem('rt', null);
        } else {
            handleClickBack(page - 1);
        }
    };
    
    useEffect(() => {
        window.addEventListener('popstate', handleNavigation, false);
        
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };
    }, []);
    
    const backToDefaultPage = useCallback(
        e => {
            if (maps?.length === 0) {
                e.preventDefault();
                sessionStorage.clear();
                const tooltipArray = localStorage.getItem('tooltipStorage') && localStorage.getItem('tooltipStorage').split(',');
                localStorage.setItem('tooltipStorage', tooltipArray);
                onLogout();
                localStorage.removeItem('at');
                navigate(ROUTES.LOGIN);
            } else {
                localStorage.setItem('page', null);
                navigate(ROUTES.MAP);
            }
        },
        [dispatch, maps]
    );
    
    return (
        <>
            <div className={styles.container}>
                <>
                    {(page === 0 || page === 1) && (
                        <MapName
                            page={page}
                            mapName={mapName}
                            setMapName={setMapName}
                            maps={maps}
                            user={user}
                            handleSetPopup={handleSetPopup}
                            handleClickNext={handleClickNext}
                            handleClickBack={handleClickBack}
                            containerChange={containerChange}
                            onLogout={onLogout}
                            setType={value => setType(value)}
                            setImportSteps={value => setImportSteps(value)}
                            backToDefaultPage={backToDefaultPage}
                        />
                    )}
                    {(page === 2 || page === 3 || page === 4) && (
                        <MapCreationFinish
                            type={type}
                            mapName={mapName}
                            updateMaps={updateMaps}
                            toggleImagePreview={toggleImagePreview}
                            user={user}
                            page={page}
                            handleClickNext={handleClickNext}
                            handleClickBack={handleClickBack}
                            containerChange={containerChange}
                            setLoading={setLoading}
                            importSteps={importSteps}
                            setImportSteps={value => setImportSteps(value)}
                            handleImportSteps={handleImportSteps}
                            selectedMap={selectedMap}
                            backToDefaultPage={backToDefaultPage}
                        />
                    )}
                    {/* TODO: Disabled because of a bug in the import (server) that is not resolved yet */}
                    {/*{page === 5 && (*/}
                    {/*    <InstagramImport*/}
                    {/*        mapName={mapName}*/}
                    {/*        selectedMap={selectedMap}*/}
                    {/*        onClickPrev={() => {*/}
                    {/*            setPage(1);*/}
                    {/*        }}*/}
                    {/*        user={user}*/}
                    {/*        backToDefaultPage={backToDefaultPage}*/}
                    {/*    />*/}
                    {/*)}*/}
                </>
            </div>
            {openPopup && <ModalsImportSteps handleClose={() => handleSetPopup(false)}/>}
            {imagePreviewShow && (
                <ImagePreview
                    imagePreviewShow={imagePreviewShow}
                    onClose={() => toggleImagePreview({ imagePreviewShow: false, imagePreviewArray: [] })}
                    imagePreviewArray={imagePreviewArray}
                    imagePreviewIndex={imagePreviewIndex}
                />
            )}
            {loading && <Loader/>}
        </>
    );
};
export default Onboard;
