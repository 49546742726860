export const Types = {
    IMAGE: 'IMAGE',
    GOOGLE_IMAGE: 'GOOGLE_IMAGE',
    INSTAGRAM_IMAGE: 'INSTAGRAM_IMAGE',
    TEXT: 'text',
};

export const ImageReasons = {
    POST: 'map_manager_Post',
    AVATAR: 'avatar',
};
