import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
// import { sendMessagePromoteToSupport } from '../../../store/step/actions';

const PromoteMapPopup = ({ mapId, setPromotePopup, setSendSuccessPopup }) => {
    const dispatch = useDispatch();
    const [valuePromote, setValuePromote] = useState('');

    const handleClose = useCallback(() => {
        setPromotePopup(false);
    }, [dispatch, mapId]);

    const handlePromoteText = useCallback(
        e => {
            setValuePromote(e.target.value);
        },
        [dispatch, mapId]
    );

    const handleSend = useCallback(() => {
        if (valuePromote.length > 0) {
            // dispatch(sendMessagePromoteToSupport(mapId, 'promote_map', valuePromote));
            setPromotePopup(false);
            setSendSuccessPopup(true);
            handleClose();
        }
    }, [dispatch, handleClose, mapId, valuePromote]);

    return (
        <>
            <div className={styles.container} data-testid='welcome-container'>
                <div className={styles.wrapper}>
                    <div className={styles.panel}>
                        <div className={styles.box}>
                            <img src='/assets/img/ic-close.svg' onClick={handleClose} className={styles.img} alt='' />
                            <div className={styles.textCont}>
                                <p className={styles.title}>Promote your map</p>
                                <p className={styles.boldTitle}>How can we help you? We have an awesome team who is ready to help you grow your map!</p>
                                <p className={styles.textBold}>Please tell us what you want to do and we’ll get back to you ASAP</p>
                            </div>
                            <textarea onChange={handlePromoteText} className={styles.textArea} placeholder='Type here' />
                            <button className={styles.btn} onClick={handleSend}>
                                <span className={styles.btnTxt}>Send</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PromoteMapPopup;
