import React from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.stepInfo;

const ModalsGoogleDataRemove = ({ onCancel, onUpdate }) => {
    return (
        <div className={styles.modals}>
            <div className={styles.block}>
                <div className={styles.header}>
                    <img src='/assets/img/warning.svg' alt='' />
                </div>
                <p className={styles.text}>{copies.rename_step_warning}</p>
                <div className={styles.footer}>
                    <CustomButton className={styles.button} onClick={onCancel} type={ButtonType.TERTIARY}>
                        {copies.cancel}
                    </CustomButton>
                    <CustomButton className={styles.button} type={ButtonType.PRIMARY} onClick={onUpdate}>
                        {copies.confirm}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default ModalsGoogleDataRemove;
