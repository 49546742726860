import React from 'react';
import { Close } from '@material-ui/icons';

import styles from './index.module.scss';

const GetAppModal = ({ setGetAppModal }) => {
    const closePopup = () => {
        setGetAppModal(false);
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.panel}>
                    <button className={styles.cancelBtn} onClick={closePopup}>
                        <Close/>
                    </button>
                    <div className={styles.header}>Get the STEPS app</div>
                    <span className={styles.title}>We sent to your email a link to download the STEPS app</span>
                    <div className={styles.blockOr}>
                        <hr className={styles.hr}/>
                        <span>or</span>
                        <hr className={styles.hr}/>
                    </div>
                    <span className={styles.mapTitle}>You can also search “Atly Map” in your phones app store.</span>
                    <p className={styles.storeImg}>
                        <img src='/assets/img/appleStore.svg' alt=''/>
                        <img className={styles.playMarketIco} src='/assets/img/playMarket.svg' alt=''/>
                    </p>
                    <button className={styles.continueBtn} onClick={closePopup}>
                        <span className={styles.contBtnSpan}>Got it!</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GetAppModal;
