import Lottie from 'react-lottie';
import classNames from 'classnames';
import loaderAnimation from '../../../lottie/loaderBig.json';

import styles from './icons.module.scss';

const IconLoader = ({ size = 20, className, color }) => {
    return (
        <span
            className={classNames({
                [styles.grey]: true,
            })}
        >
            <Lottie
                width={size}
                height={size}
                color={color}
                options={{
                    animationData: loaderAnimation,
                    rendererSettings: {
                        className,
                    },
                }}
            />
        </span>
    );
};

export default IconLoader;
