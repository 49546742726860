import { noop } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import { getUploadImageInfo, uploadImageToS3 } from '../../helpers';

export const uploadImages = async (files, { onUploadSuccess = noop, onLoadFilesStart = noop, onLoadFilesEnd = noop } = {}) => {
    const filesWithIds = [...files].map(file => ({ file, uuid: uuidv1() }));
    onLoadFilesStart(filesWithIds);

    const imagesUpload = filesWithIds.map(
        ({ file, uuid }) =>
            new Promise((resolve, reject) => {
                return getUploadImageInfo(file)
                    .then(info => {
                        return uploadImageToS3({
                            uploadUrl: info.url,
                            file,
                            onUploadedFile: file => {
                                const payload = {
                                    file,
                                uuid,
                                    imageId: info.image_id,
                                    url: info.url,
                                };
                                onUploadSuccess(payload);
                                resolve(payload);
                            },
                        })
                            .then(results => {
                                return results;
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(error => console.error(error));
            })
    );

    return Promise.all(imagesUpload).then(results => {
        onLoadFilesEnd(results);
    });
};
