import classNames from 'classnames';

const LoadingAnimation = () => {
    return (
        <div
            style={{
                maxWidth: '570px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                margin: 'auto',
            }}
        >
            <div style={{ overflowY: 'hidden', flex: '1 0 1px' }}>
                <div
                    style={{
                        maxWidth: '537px',
                        padding: '24px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        marginBottom: '16px',
                        marginTop: '24px',
                        backgroundColor: 'white',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '15px',
                       
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    marginRight: '16px',
                                }}
                                className={classNames('animate')}
                            />
                            <div style={{ width: '350px', height: '20px' }} className={classNames('animate')} />
                        </div>
                        <div style={{ width: '24px', height: '24px', borderRadius: '50%' }} className={classNames('animate')} />
                    </div>
                    <div style={{ width: '100%', height: '100px', marginBottom: '16px' }} className={classNames('animate')} />
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}
                    >
                        <div style={{ width: '20px', height: '20px' }} className={classNames('animate')} />
                        <div style={{ width: '300px', height: '40px' }} className={classNames('animate')} />
                    </div>
                </div>
                <div
                    style={{
                        maxWidth: '537px',
                        padding: '24px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        marginBottom: '16px',
                        backgroundColor: 'white',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '15px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    marginRight: '16px',
                                }}
                                className={classNames('animate')}
                            />
                            <div style={{ width: '350px', height: '20px' }} className={classNames('animate')} />
                        </div>
                        <div style={{ width: '24px', height: '24px', borderRadius: '50%' }} className={classNames('animate')} />
                    </div>
                    <div style={{ width: '100%', height: '100px', marginBottom: '16px' }} className={classNames('animate')} />
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}
                    >
                        <div style={{ width: '20px', height: '20px' }} className={classNames('animate')} />
                        <div style={{ width: '300px', height: '40px' }} className={classNames('animate')} />
                    </div>
                </div>
                <div
                    style={{
                        maxWidth: '537px',
                        padding: '24px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        marginBottom: '16px',
                        backgroundColor: 'white',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '15px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    marginRight: '16px',
                                }}
                                className={classNames('animate')}
                            />
                            <div style={{ width: '350px', height: '20px' }} className={classNames('animate')} />
                        </div>
                        <div style={{ width: '24px', height: '24px', borderRadius: '50%' }} className={classNames('animate')} />
                    </div>
                    <div style={{ width: '100%', height: '100px', marginBottom: '16px' }} className={classNames('animate')} />
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}
                    >
                        <div style={{ width: '20px', height: '20px' }} className={classNames('animate')} />
                        <div style={{ width: '300px', height: '40px' }} className={classNames('animate')} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingAnimation;
