import React, { useRef } from 'react';
import styles from './index.module.scss';
import { mixPanelSortClick } from '../../helpers';
import Skeleton from './Skeleton';
import Row from './Row';
import Header from './Header';
import LoadingRow from './LoadingRow';
import { noop } from 'lodash';

const PIXELS_BEFORE_REACH_BOTTOM = 150;

const Table = ({
    columns,
    data,
    onClickRow = noop,
    onSelectRow = noop,
    onClickTags = noop,
    onClickSelectAll = noop,
    onClickPosts = noop,
    selectAllMode,
    sortBy,
    orderBy,
    changeSort = noop,
    onScrollToBottom = noop,
    isLoading,
}) => {
    const tableContainerRef = useRef(null);

    const handleScroll = () => {
        const tableElement = tableContainerRef.current;
        const currentScrollPosition = Math.round(tableElement.scrollHeight - tableElement.offsetHeight);
        const isPassedScroll = Math.round(tableElement.scrollTop) >= currentScrollPosition - PIXELS_BEFORE_REACH_BOTTOM;
        if (isPassedScroll) onScrollToBottom();
    };

    const scrollToTableTop = () => {
        tableContainerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const onClickHeader = column => {
        scrollToTableTop();
        changeSort(column.selector);
        mixPanelSortClick(column.title, orderBy);
    };

    return (
      <div className={styles.container} ref={tableContainerRef} onScroll={e => handleScroll(e)}>
          <table className={styles.table}>
              {data.length > 0 ? (
                <>
                    <thead className={styles.headers}>
                    <tr>
                        {columns.map((column, columnIndex) => (
                          <Header
                            {...column}
                            onClick={column.sortAble ? () => onClickHeader(column) : null}
                            onClickSelectAll={columnIndex === 0 && onClickSelectAll}
                            selectMode={selectAllMode}
                            orderBy={sortBy === column.selector ? orderBy : null}
                          />
                        ))}
                    </tr>
                    </thead>
                    <tbody className={styles.rows}>
                    {data.map(row => (
                      <Row
                        key={row.id}
                        step={row}
                        onClickCheckBox={onSelectRow}
                        onClickRow={onClickRow}
                        onClickTags={onClickTags}
                        onClickPosts={onClickPosts} />
                    ))}
                    {isLoading && <LoadingRow numberOfTableCulumns={columns.length} />}
                    </tbody>
                </>
              ) : (
                <div className={styles.skeleton}>
                    <Skeleton />
                </div>
              )}
          </table>
      </div>
    );
};

export default Table;
