import { useState, useMemo, useEffect } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

import styles from './PopperTooltip.module.scss';

let isSeenTooltip = false;

export const PopperTooltip = ({
    tooltip,
    isClicked,
    offsetTop = 10,
    placement = 'top',
    triggeredBy = 'hover',
    tooltipClass,
    children,
    isActive,
    className,
    onTooltipSeen = noop,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const triggeredByHover = useMemo(() => triggeredBy === 'hover', [triggeredBy]);
    const triggeredByClick = useMemo(() => triggeredBy === 'click', [triggeredBy]);
    const alwaysTriggred = useMemo(() => triggeredBy === 'always', [triggeredBy]);
    const [isHidden, setIsHidden] = useState(!isActive);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                    padding: 0,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, offsetTop],
                },
            },
        ],
    });
    
    useEffect(() => {
        if (isSeenTooltip || !tooltip || !isActive || isHidden) {
            return;
        }
        
        onTooltipSeen();
        isSeenTooltip = true;
    }, [tooltip, isActive, isHidden]);
    
    useEffect(() => {
        if (alwaysTriggred) {
            setIsHidden(!isActive);
        } else if (triggeredByHover) {
            setIsHidden(!isHovered);
        } else if (triggeredByClick) {
            setIsHidden(!isClicked);
        }
    }, [isActive, isHovered]);
    
    if (!tooltip) {
        return children;
    }
    
    return (
        <span
            className={classNames('flex-center', styles.minSize)}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={classNames('flex-center', styles.tooltip, className)} ref={setReferenceElement}>
                {children}
            </div>
            <div
                ref={setPopperElement}
                style={popperStyles.popper}
                className={classNames(
                    {
                        [styles.popperTooltip]: true,
                        [styles.disablePointerEvents]: triggeredByHover,
                        [styles.isHidden]: isHidden,
                    },
                    tooltipClass
                )}
                {...attributes.popper}
            >
                {tooltip}
                <div ref={setArrowElement} data-popper-arrow style={{ ...popperStyles.arrow }}/>
            </div>
        </span>
    );
};

export default PopperTooltip;
