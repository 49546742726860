import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';
import { mapActions, mapSelectors } from '../../redux/map';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import IconStep from '../common/icons/IconStep';
import classNames from 'classnames';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';

import styles from './index.module.scss';
import { useNavigate } from 'react-router';
import Routes from '../../config/routes';

const copies = copiesPrefix.guideTask;

const StyledSlider = styled(Slider)`
    .slick-dots li.slick-active button:before {
        color: #2171ec;
        opacity: 1;
        font-size: 7px;
        width: 10px;
        height: 10px;
    }
    .slick-dots li button {
        width: 10px;
        height: 10px;
    }
    .slick-dots li button:before {
        font-size: 7px;
        width: 10px;
        height: 10px;
    }
    .slick-dots {
        top: -10px;
        left: -8px;
        z-index: 1;
        width: auto;
    }
    .slick-dots li {
        width: 0 !important;
    }
    .slick-dots li button:before {
        opacity: 1;
        color: #f0f0f0;
    }
`;

const BorderLinearProgress = withStyles(() => ({
    root: {
        width: '100%',
        height: 10,
        borderRadius: 8,
    },
    colorPrimary: {
        backgroundColor: '#f0f0f0',
    },
    bar: {
        borderRadius: 8,
        backgroundColor: '#599afe',
    },
}))(LinearProgress);

const GuideTask = ({ percent, mapId, onClickAddStep, onClickSharePopup }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const map = useSelector(mapSelectors.map(mapId));
    const steps = useSelector(mapSelectors.steps(mapId));
    const guideTaskCompleted = useSelector(mapSelectors.guideTaskCompleted(mapId));
    const [bodyBlock, setBodyBlock] = useState(true);
    const [activeSliderStartName, setActiveSliderStartName] = useState('');
    const [activeSliderNumber, setActiveSliderNumber] = useState(null);
    const [activeSliderStartNumber, setActiveSliderStartNumber] = useState(null);
    const [stepPercent, setPercent] = useState(0);
    const slickSlider = useRef();

    useEffect(() => {
        if (guideTaskCompleted && slickSlider.current) {
            switch (true) {
                case !guideTaskCompleted.MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS:
                    slickSlider.current.slickGoTo(0);
                    setActiveSliderStartName('add_steps');
                    setActiveSliderStartNumber(1);
                    break;

                case !guideTaskCompleted.MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP:
                    slickSlider.current.slickGoTo(4);
                    setActiveSliderStartName('share');
                    setActiveSliderStartNumber(2);
                    break;

                default:
                    break;
            }
        }
    }, [guideTaskCompleted, bodyBlock]);

    const slidesCopiesPrefix = copies.slides;
    const arrayObject = [
        {
            title: slidesCopiesPrefix.step.title,
            text: slidesCopiesPrefix.step.text,
            button_text: slidesCopiesPrefix.step.button_text,
            type: 'MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS',
            completed: guideTaskCompleted.MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS,
            local_type: 'taskStep',
        },
        {
            title: slidesCopiesPrefix.member.title,
            text: slidesCopiesPrefix.member.text,
            button_text: slidesCopiesPrefix.member.button_text,
            local_type: 'taskMember',
            type: 'MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP',
            completed: guideTaskCompleted.MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP,
        },
    ];

    const stepPercentGenerate = useCallback(() => {
        switch (map?.steps_count) {
            case 1: {
                setPercent(4);
                break;
            }
            case 2: {
                setPercent(8);
                break;
            }
            case 3: {
                setPercent(12);
                break;
            }
            case 4: {
                setPercent(16);
                break;
            }
            case 5: {
                setPercent(0);
                break;
            }
        }
    }, [map?.steps_count]);

    useEffect(() => {
        stepPercentGenerate();
        if (Object.values(steps)?.filter(el => !el.archived).length > 4 && !guideTaskCompleted?.MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS) {
            dispatch(mapActions.setGuideTaskComplete(map?._id, 'MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS'));
        }
    }, [map?.steps_count, Object.values(steps)]);

    useEffect(() => {
        if (map?.members_count > 1 && !guideTaskCompleted?.MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP && localStorage.getItem('clickShare')) {
            dispatch(mapActions.setGuideTaskComplete(map?._id, 'MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP'));
            localStorage.removeItem('clickShare');
        }
    }, [map?.members_count]);

    const ImgArrowLeft = ({ onClick }) => {
        return (
            <div onClick={onClick} className={styles.arrowLeftTaskGuide}>
                <ArrowBackIos />
            </div>
        );
    };

    const ImgArrowRight = ({ onClick }) => {
        return (
            <div onClick={onClick} className={styles.arrowRightTaskGuide}>
                <ArrowForwardIos />
            </div>
        );
    };

    const handleClick = type => {
        switch (type) {
            case 'MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS':
                return navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`);

            case 'MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP':
                mixpanel.track(mixpanelEvents.SHARE_SHOW, {
                    [mixpanelProperties.FROM_WHERE]: 'parallel',
                    [mixpanelProperties.SHARE_ELEMENT]: 'map',
                });
                return onClickSharePopup();

            default:
                return;
        }
    };

    const handleBodyBlock = bool => {
        if (bool) {
            mixpanel.track(mixpanelEvents.PAR_COLLAPSE_CLICK, {
                [mixpanelProperties.TASK]: activeSliderStartName,
                [mixpanelProperties.NUM_COMPLETED_TASKS]: (percent / 100) * 2,
                [mixpanelProperties.TASK_NUM]: activeSliderStartNumber,
            });
        } else {
            mixpanel.track(mixpanelEvents.PAR_EXPAND_CLICK, {
                [mixpanelProperties.TASK]: activeSliderStartName,
                [mixpanelProperties.NUM_COMPLETED_TASKS]: (percent / 100) * 2,
                [mixpanelProperties.TASK_NUM]: activeSliderNumber + 1,
            });
        }
        setBodyBlock(bool);
    };

    const renderSlider = useMemo(() => {
        const SLIDER_SETTINGS = {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <ImgArrowLeft />,
            nextArrow: <ImgArrowRight />,
            beforeChange: (current, next) => setActiveSliderNumber(next),
        };
        return (
            <StyledSlider ref={slickSlider} {...SLIDER_SETTINGS}>
                {arrayObject.map((el, index) => (
                    <div key={index} className={styles.taskWrapper}>
                        <div className={styles.taskBlock}>
                            <p
                                className={classNames(styles.titleTask, {
                                    [styles.titleTaskDone]: el?.completed,
                                })}
                            >
                                {el.title}
                                <span
                                    className={classNames(styles.taskBlockDone, {
                                        [styles.taskBlockDoneShow]: el?.completed,
                                    })}
                                >
                                    <img src={'/assets/img/steps3Images/doneTaskGuide.png'} alt={''} />
                                    {copies.done}
                                </span>
                            </p>
                            <p className={styles.textTask}>{el.text}</p>
                            {el.type === 'MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS' && (
                                <div className={styles.iconStep}>
                                    {Object.values(steps).filter(el => !el.archived).length > 4 ? (
                                        ['', '', '', '', ''].map(e => <IconStep key={e._id} stroke={'#2171ec'} />)
                                    ) : (
                                        <>
                                            {Object.values(steps)
                                                .filter(el => !el.archived)
                                                .map(e => (
                                                    <IconStep key={e._id} stroke={'#2171ec'} />
                                                ))}
                                            {new Array(5 - Object.values(steps).filter(el => !el.archived)?.length).fill('').map((e, i) => (
                                                <IconStep key={i} stroke={'#DDD'} />
                                            ))}
                                        </>
                                    )}
                                    <span className={styles.iconStepCount}>
                                        {Object.values(steps).filter(el => !el.archived).length > 1
                                            ? 5
                                            : Object.values(steps).filter(el => !el.archived).length}
                                        /5 {copies.slides.step.added}
                                    </span>
                                </div>
                            )}
                            {el.tags && (
                                <div className={styles.tagsStep}>
                                    <p className={styles.textExample}>{copies.slides.tag.examples}:</p>
                                    <div className={styles.lineTagExample}>
                                        {el.tags.map(e => (
                                            <div className={styles.tagExample}>{e.name}</div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className={classNames(styles.taskButton)}>
                                <button onClick={() => handleClick(el.type)} className={styles.buttonTask}>
                                    {el.button_text}
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </StyledSlider>
        );
    }, [arrayObject, steps, map, activeSliderNumber]);

    return (
        <>
            {map?.flags?.MAP_MANAGER_JOURNEY_TASKS && (
                <div className={styles.guideBlock}>
                    {bodyBlock && <div className={styles.guideBody}>{renderSlider}</div>}
                    <div className={styles.guideFooter}>
                        <div className={styles.dFlexText}>
                            <p className={styles.text}>{copies.footer} </p>
                            <span
                                onClick={() => handleBodyBlock(!bodyBlock)}
                                className={classNames(styles.closeArrow, {
                                    [styles.closeArrowRtlNew]: bodyBlock,
                                })}
                            >
                                <ArrowForwardIos className={!bodyBlock ? styles.closeArrowRtl : styles.openArrowRtl} />
                            </span>
                        </div>
                        <div className={styles.dFlexLine}>
                            <span className={styles.percent}>{stepPercent + percent}%</span>
                            <BorderLinearProgress variant='determinate' value={stepPercent + percent} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default GuideTask;
