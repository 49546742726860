import React from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import { usePortal } from '../../hooks';
import { X } from 'phosphor-react';

import styles from './index.module.scss';

export const ModalSizes = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const BaseModal = ({
    children,
    isActive,
    onClose = noop,
    size = ModalSizes.SMALL,
    wraperClassName,
    className,
    disableClickAway = false,
    withCloseIcon = false,
}) => {
    // FIXME: usePortal/React.Portal is causing blank screen when combined with
    // usetiful/other elements. Investigate and fix. In the meanwhile,
    // just rendering the div has no UI implications.

    // const Portal = usePortal('#modal-root');

    if (!isActive) {
        return null;
    }

    return (
        // <Portal>
        <div className={classNames(styles.modals, wraperClassName)}>
            <ClickAwayListener onClickAway={disableClickAway ? noop : onClose}>
                <div className={classNames(styles.modalContent, styles[size], className)}>
                    {withCloseIcon && <X className={styles.cancel} onClick={onClose} size={15} color='#2171EC' weight='bold' />}
                    {children}
                </div>
            </ClickAwayListener>
        </div>
        // </Portal>
    );
};

BaseModal.propTypes = {
    isActive: propTypes.bool.isRequired,
    onClose: propTypes.func,
};

export default BaseModal;
