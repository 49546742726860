export const setSource = (map, sourceId, stepsData) => {
    const geojson = geoJsonConverter(stepsData || []);
    if (map.getSource(sourceId)) map.getSource(sourceId).setData(geojson);
    else
        map.on('styledata', () => {
            if (!map.getSource(sourceId)) map.addSource(sourceId, { type: 'geojson', data: geojson });
        });
};

const geoJsonConverter = steps => {
    return {
        type: 'FeatureCollection',
        features: steps.map(step => ({
            type: 'Feature',
            properties: {
                ...step,
            },
            geometry: {
                type: 'Point',
                coordinates: [step.lon, step.lat],
            },
        })),
    };
};
