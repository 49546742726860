import { useMemo } from 'react';
import { MapPin } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton, { ButtonSize, ButtonType } from '../../common/buttons/CustomButton';
import { DropdownMenu, MenuSize } from '../../common/DropdownMenu';
import { AnnouncementTag, PostHeadline } from '../../common/PostHeadline';
import { mapActions } from '../../../redux/map';
import { PencilSimple, Trash, PushPin } from 'phosphor-react';
import { userType as userTypes } from '../../../helpers/constants';
import styles from './index.module.scss';
import { postSelectors } from '../../../redux/posts';
import { userSelectors } from '../../../redux/user';
import { noop } from 'lodash';
import { PostTypes } from '../../../constants';
import classNames from 'classnames';

const PostHeader = ({
    postId,
    settings = {},
    onClickEdit = noop,
    onDeleteClicked = noop,
    onPinPostClick = noop,
    onClickTitle = noop,
    isPinnable = false,
    className,
    currentUserId,
    isScrolling,
    usernameHeadlineOnly = false,
}) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const currentUser = useSelector(userSelectors.user);
    const { viewOnMap, showSecondaryImage, mentionsStepId } = settings;
    const post = useSelector(postSelectors.postById(postId));
    const {
        title,
        address,
        userName,
        userId,
        userType,
        createdAt,
        postImage,
        isAnnouncement,
        isPinned,
        creatorAvatarImage,
        coordinates,
        mentions = [],
        type: postType,
        stepTitle,
    } = post;

    const adminOptions = [
        {
            title: 'Delete post',
            onClick: onDeleteClicked,
            icon: <Trash size={20} />,
        },
    ];

    const ownPostOption = [
        // TODO: Disabled atm as we cannot migrate post to announcement without
        // losing comments/replies

        // !isPinned && {
        //     title: 'Make announcement',
        //     onClick: async () => {
        //         await dispatch(
        //             postActions.postAnnouncement({
        //                 mapId,
        //                 textArray: body,
        //                 attachments: imagesId.map(id => ({
        //                     type: 'IMAGE',
        //                     image_id: id,
        //                 })),
        //             })
        //         );
        //         await dispatch(postActions.deleteStepPost(mapId, null, postId));
        //         await fetchFeed({ isUpdate: true, quiet: false });
        //     },
        //     icon: <Megaphone size={20} />,
        // },
        isPinnable && {
            title: isPinned ? 'Unpin post' : 'Pin post',
            onClick: onPinPostClick,
            icon: <PushPin size={20} />,
        },
        {
            title: 'Edit post',
            onClick: onClickEdit,
            icon: <PencilSimple size={20} />,
        },
    ];
    const dropDownOptions = currentUser.id === userId ? [...ownPostOption, ...adminOptions] : [...adminOptions];

    const renderViewOnMapButton = () => {
        if (!coordinates) {
            return null;
        }

        return (
            <CustomButton
                onClick={() => {
                    if (!coordinates.length) {
                        return;
                    }

                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: {
                                lat: coordinates[1],
                                lng: coordinates[0],
                            },
                            zoom: 18,
                        })
                    );
                }}
                size={ButtonSize.SMALL}
                type={ButtonType.SECONDARY_BLUE}
                className={styles.viewOnMapButton}
            >
                <span className={styles.addStepButton}>
                    <MapPin weight='fill' color='#2171EC' width={15} height={15} />
                    <span>View on map</span>
                </span>
            </CustomButton>
        );
    };

    const renderMentionsText = (mentionsCount, isMentionedInPostBody) => {
        if (isMentionedInPostBody) return <span>a post</span>;
        if (mentionsCount > 1) return <span className={styles.bold}>{mentionsCount} comments</span>;
        return <span>a comment</span>;
    };

    const renderStepName = () => {
        if (postType === PostTypes.FEED || !stepTitle) return;
        return (
            <span>
                on <span className={styles.bold}>{stepTitle}</span>
            </span>
        );
    };

    const renderMentionsCount = useMemo(() => {
        const stepMentionObject = mentions.find(mention => mention.step_id === mentionsStepId);
        const mentionsCount = stepMentionObject?.mentions_count;
        const isMentionedInPostBody = stepMentionObject?.is_mentioned_in_current_post;
        const shouldntRenderMentions = !stepMentionObject || (postType === PostTypes.FEED && (mentionsCount === 0 || isMentionedInPostBody));
        if (shouldntRenderMentions) return;

        return (
            <span className={styles.mentions}>
                <span className={styles.bold}>{stepMentionObject.step_title}</span> was mentioned in {renderMentionsText(mentionsCount, isMentionedInPostBody)}{' '}
                {renderStepName()}
            </span>
        );
    }, [mentions, mentionsStepId]);

    const avatarImg = () => {
        if (userType === userTypes.STEPS_BOT) return '/assets/img/steps3Images/iconBOT.svg';
        return creatorAvatarImage;
    };

    return (
        <div className={classNames(styles.container, className)}>
            {renderMentionsCount}
            <div className={styles.header}>
                <PostHeadline
                    userName={userName}
                    userType={userType}
                    createdAt={createdAt}
                    isPinned={isPinnable && isPinned}
                    title={isAnnouncement || usernameHeadlineOnly ? userName : title || userName}
                    isTitleUserName={isAnnouncement || usernameHeadlineOnly || !title}
                    mainImage={avatarImg()}
                    secondaryImage={showSecondaryImage && postImage}
                    address={!usernameHeadlineOnly && address}
                    className={styles.userBadge}
                    badge={isAnnouncement && <AnnouncementTag />}
                    onClickTitle={viewOnMap ? onClickTitle : noop}
                    isStep={!!coordinates}
                    userId={userId}
                    postId={postId}
                    isScrolling={isScrolling}
                />
                <div className={styles.rightSection}>
                    {viewOnMap && renderViewOnMapButton()}
                    <DropdownMenu menuSize={MenuSize.NatiSize} options={dropDownOptions} menuClassName={styles.postDropdown} />
                </div>
            </div>
        </div>
    );
};

export default PostHeader;
