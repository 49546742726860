import { useMemo } from 'react';
import { noop } from 'lodash';
import { ArrowLeft } from '../../common/icons';
import { FormattedMessage } from 'react-intl';

const EditStepDetailsHeader = ({ onPrevious = noop, isAddPlaceTitle = false, title = '' }) => {
    const titleId = useMemo(() => {
        if (isAddPlaceTitle && title) {
            return 'addStep.create_place_for';
        }

        if (isAddPlaceTitle) {
            return 'addStep.title';
        }

        return 'addStep.editTitle';
    }, [title, isAddPlaceTitle]);
    return (
        <div className='flex items-center justify-start border-0 border-solid border-b border-gray-200'>
            <span data-testid='back-button' className='flex mx-5'>
                <ArrowLeft size='20' color='#2171EC' onClick={onPrevious}/>
            </span>
            <h3 className='font-bold text-black text-base'>
                <FormattedMessage id={titleId} values={{ placeName: title }}/>
            </h3>
        </div>
    );
};

export default EditStepDetailsHeader;