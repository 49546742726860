import { useState, useEffect } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import CustomButton, { ButtonSize, ButtonType } from '../common/buttons/CustomButton';
import Switch from '../common/buttons/Switch';
import { TextArea } from '../common/inputs';
import { CloseButton } from '../common/buttons';
import useWelcomeMessage from './useWelcomeMessage';
import copies from '../../copies.json';
import { userSelectors } from '../../redux/user';
import WelcomeMessageFirstTimeModal from './WelcomeMessageFirstTimeModal';
import { isUserSeenWelcomeMessage } from './helpers';

import styles from './WelcomeMessage.module.scss';

const copiesPrefix = copies.welcomeMessage.settings;

const WelcomeMessage = ({ isSlide = true, isOpen, onClose = noop, onSuccess = noop }) => {
    const userId = useSelector(userSelectors.user)?.id;
    const [isToggleChanged, setIsToggleChanged] = useState(false);
    const [isBodyChanged, setIsBodyChanged] = useState(false);
    const [isSavePending, setIsSavePending] = useState(false);
    const [isSeenFirstTimeModal, setIsSeenFirstTimeModal] = useState(isUserSeenWelcomeMessage(userId));
    const { updateWelcomeMessage, welcomeMessage, body, setBody, sendAutomatically, setSendAutomatically } = useWelcomeMessage();

    useEffect(() => {
        setIsSeenFirstTimeModal(isUserSeenWelcomeMessage(userId));
    }, [isOpen, userId]);

    if (!userId || !isOpen) {
        return null;
    }

    if (!isSeenFirstTimeModal) {
        return (
            <WelcomeMessageFirstTimeModal
                isOpen
                userId={userId}
                onCancel={onClose}
                onConfirm={() => {
                    setIsSeenFirstTimeModal(true);
                }}
            />
        );
    }

    return (
        <div
            className={classNames(styles.settingsWrapper, {
                [styles.isOpen]: isOpen,
                [styles.isSlide]: isSlide,
            })}
        >
            <div className={styles.header}>
                <CloseButton
                    onClick={() => {
                        setBody(welcomeMessage.body);
                        setSendAutomatically(welcomeMessage.send_automatically);
                        onClose();
                    }}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.body}>
                    <div className={styles.content}>
                        <h1>{copiesPrefix.title}</h1>
                        <p>{copiesPrefix.description}</p>
                    </div>
                    <TextArea
                        isActive
                        maxLength={120}
                        placeholder={copiesPrefix.input_placeholder}
                        onChange={body => {
                            setBody(body);
                            setIsBodyChanged(body !== welcomeMessage.body);
                        }}
                        className={styles.input}
                        value={welcomeMessage.body}
                    />
                    <Switch
                        className={styles.switch}
                        text={copiesPrefix.auto_add_toggle}
                        onChange={status => {
                            setSendAutomatically(status);
                            setIsToggleChanged(status !== welcomeMessage.send_automatically);
                        }}
                        isSwitchOn={sendAutomatically}
                    />
                </div>
                <div className={styles.footer}>
                    <CustomButton
                        onClick={async () => {
                            setIsSavePending(true);
                            const data = await updateWelcomeMessage({
                                body,
                                sendAutomatically,
                            });
                            setIsSavePending(false);
                            onSuccess(data);
                        }}
                        size={ButtonSize.LARGE}
                        type={ButtonType.PRIMARY_BLUE}
                        className={styles.saveButton}
                        disabled={isSavePending || (!isToggleChanged && !isBodyChanged)}
                    >
                        {copiesPrefix.save_button}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default WelcomeMessage;
