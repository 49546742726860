import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useLocation } from 'react-router';
import { X } from 'phosphor-react';
import { userSelectors } from '../../redux/user';
import { mapSelectors } from '../../redux/map';
import Level from './Level';
import HighlightsSlider from './HighlightsSlider';
import copiesPrefix from '../../copies.json';
import Routes from '../../config/routes';
import { convertJourneysToArray } from './helpers';
import { levelOneTasks, finishedJourney } from './contants';
import ModalsAddDescription from '../../components/common/modals/ModalsAddDescription';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';

import styles from './index.module.scss';

const copies = copiesPrefix.mapStages;

const MapStages = () => {
    const navigate = useNavigate();
    const { state: prevUrlState } = useLocation();
    const { setSharePopup } = useOutletContext() || {};
    const [openedTaskDescriptionPopup, setOpenedTaskDescriptionPopup] = useState(false);
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId)) || {};
    const flags = map?.flags?.MAP_MANAGER_JOURNEY_TASKS;
    const mapLevel = map?.map_level;
    const journeys = convertJourneysToArray(finishedJourney);
    const reachedAdvancedLevel = mapLevel > 1;
    const levelsData = useMemo(
        () => ({
            ADD_20_STEPS_TO_MAP: {
                realProgress: map?.steps_count,
                isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_ADDED_20_STEPS,
                cta: () => {
                    mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_CTA_CLICKED, {
                        [mixpanelProperties.CTA_NAME]: 'add_steps',
                    });
                    navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`);
                },
            },
            GET_25_MEMBERS: {
                realProgress: map?.members_count,
                isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_25_MEMBER_JOINED_MAP,
                cta: () => {
                    mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_CTA_CLICKED, {
                        [mixpanelProperties.CTA_NAME]: 'share_map',
                    });
                    setSharePopup(true);
                },
            },
            ADD_DESCRIPTION_TO_MAP: {
                realProgress: map?.description ? 1 : 0,
                isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_DESCRIPTION,
                cta: () => {
                    mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_CTA_CLICKED, {
                        [mixpanelProperties.CTA_NAME]: 'add_description',
                    });
                    setOpenedTaskDescriptionPopup(true);
                },
            },
            ADD_3_TAGS_TO_MAP: {
                realProgress: Object.keys(map?.tag_objects || {}).length,
                isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_3_ADDED_TAGS,
                cta: () => {
                    mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_CTA_CLICKED, {
                        [mixpanelProperties.CTA_NAME]: 'add_tags',
                    });
                    navigate(`/${Routes.TAGS}`);
                },
            },
        }),
        [map, flags]
    );

    const onClickTryInsights = () => {
        navigate(`/${Routes.INSIGHTS}`);
    };

    const moveToAddAnnnouncement = () => {
        navigate(`/${Routes.MAP}/${Routes.FEED}`, {
            state: {
                triggerAddAnnouncment: true,
            },
        });
    };

    const isReachedAdvancedLevel = () => {
        const totalJourneys = journeys.length;
        const completedJourneys = journeys.reduce((prev, journey) => {
            const isCompletedNow = levelsData[journey.name]?.realProgress >= journey.total;
            const isCompleted = levelsData[journey.name]?.isAlreadyCompleted || isCompletedNow;
            return isCompleted ? prev + 1 : prev;
        }, 0);
        return completedJourneys === totalJourneys;
    };

    const onClose = () => {
        const isPrevUrlIncludesMap = prevUrlState?.url.includes(Routes.MAP);
        const isPrevUrlIncludesFeed = prevUrlState?.url.includes(Routes.FEED);
        if (isPrevUrlIncludesMap && !isPrevUrlIncludesFeed) {
            navigate(-1);
        } else {
            navigate(`/${Routes.MAP}`);
        }
    };

    useEffect(() => {
        mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_SHOWED, {
            [mixpanelProperties.OPEN_SOURCE]: prevUrlState?.context || 'deep_link',
        });
    }, []);

    return (
        <div className={styles.block}>
            <X className={styles.xIcon} onClick={onClose} size={16} color='#212B37' />
            <div className={styles.header}>
                <div className={styles.titleWrapper}>
                    <h2 className={styles.title}>{copies.title}</h2>
                    <img className={styles.icon} alt='' src='/assets/img/mapStagesIcon.svg' />
                </div>
                <p className={styles.description}>{copies.description}</p>
            </div>
            <div className={styles.content}>
                <HighlightsSlider reachedLevel={reachedAdvancedLevel} onClickTryInsights={onClickTryInsights} onClickTryAnnouncement={moveToAddAnnnouncement} />
                <h3 className={styles.levelsTitle}>{copies.levelsTitle}</h3>
                {journeys.map(task => {
                    const isAlreadyCompleted = levelsData[task.name]?.isAlreadyCompleted;
                    return (
                        <Level
                            title={levelOneTasks[task.name].title}
                            totalTasks={task.total}
                            completedTasks={isAlreadyCompleted ? task.total : levelsData[task.name].realProgress}
                            cta={{ text: levelOneTasks[task.name].cta, onClick: levelsData[task.name].cta }}
                        />
                    );
                })}
                {isReachedAdvancedLevel() && <span className={styles.blueText}>{copies.reachedAdvancedLevel}</span>}
            </div>
            {openedTaskDescriptionPopup && <ModalsAddDescription mapId={mapId} onClose={() => setOpenedTaskDescriptionPopup(false)} />}
        </div>
    );
};

export default MapStages;
