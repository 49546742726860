import React from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import { ClickAwayListener } from '@material-ui/core';
import classNames from 'classnames';

const ModalsDelete = ({ textFirst, textSecond, onCancel, onDecline, className }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.wrapper}>
                <ClickAwayListener onClickAway={onCancel}>
                    <div className={styles.panel}>
                        <span className={styles.textFirst} dangerouslySetInnerHTML={{ __html: textFirst }} />
                        <span className={styles.textSecond}>{textSecond}</span>
                        <div className={styles.buttons}>
                            <CustomButton className={styles.cancel} onClick={onCancel} type={ButtonType.SECONDARY_BLACK}>
                                Cancel
                            </CustomButton>
                            <CustomButton className={styles.discard} onClick={onDecline} type={ButtonType.DANGER}>
                                Delete
                            </CustomButton>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default ModalsDelete;
