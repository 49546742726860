import React from 'react';

const IconPhone = ({ stroke }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' viewBox='0 0 23 22'>
        <g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
            <g stroke={stroke} strokeWidth='2'>
                <g>
                    <path
                        d='M21 14.92v3c.002.563-.245 1.101-.68 1.482-.436.38-1.02.569-1.609.518-3.231-.334-6.335-1.386-9.061-3.07-2.537-1.535-4.688-3.584-6.3-6C1.575 8.241.47 5.271.125 2.18.073 1.62.269 1.066.666.652 1.065.237 1.627 0 2.217 0h3.15c1.054-.01 1.952.726 2.1 1.72.132.96.379 1.903.734 2.81.289.73.104 1.555-.472 2.11L6.395 7.91c1.494 2.504 3.67 4.576 6.3 6l1.333-1.27c.583-.549 1.448-.725 2.216-.45.952.339 1.942.573 2.95.7 1.056.142 1.832 1.015 1.806 2.03z'
                        transform='translate(-338 -293) translate(339 294)'
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default IconPhone;
