import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const SearchSteps = ({ className, onChange, value, placeholder, onClickCancel }) => {
    return (
        <div className={classNames([styles.inputBlock, className])}>
            <input
                // type={type}
                // onKeyDown={onKeyDown}
                placeholder={placeholder}
                className={styles.input}
                onChange={onChange}
                value={value}
            />
            <div className={styles.iconBox}>
                <img src='/assets/img/icon_cancel.svg' className={styles.icon} onClick={onClickCancel} />
            </div>
        </div>
    );
};

export default SearchSteps;
