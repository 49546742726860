import React from 'react';
import styles from './index.module.scss';
import { generateImageUrl } from '../../../helpers';

const FeatureCard = ({ imageId, title, description, backgroundColor, badgeColor, badge }) => {
    return (
        <div className={styles.cardContainer}>
            <div className={styles.top} style={{ backgroundColor: backgroundColor }}>
                <span className={styles.label} style={{ backgroundColor: badgeColor }}>
                    {badge || 'NEW FEATURE'}
                </span>
                <img className={styles.image} src={generateImageUrl({ imageId, height: 200, width: 200, reason: 'mm_whats_new_image' })} />
            </div>
            <div className={styles.footer}>
                <span className={styles.title}>{title}</span>
                <span className={styles.description}>{description}</span>
            </div>
        </div>
    );
};

export default FeatureCard;
