import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { TaggableItem } from '../../../models';
import { noop } from 'lodash';
import { postActions } from '../../../redux/posts';
import config from './../../../config/index';

const getPostTextArray = (postId = '') => {
    if (!postId) {
        console.error('Missing required params', { postId });
        return;
    }
    return axios
        .get(`${config.domain.address}/steps3/posts/${postId}`)
        .then(res => {
            const textArray = res?.data?.post?.text_array || [];
            return textArray;
        })
        .catch(error => {
            console.error(error);
            return [];
        });
};

export const useTags = () => {
    const dispatch = useDispatch();
    const getTags = async (searchTerm = '', mapId = '', tagListType = '') => {
        const results = await dispatch(postActions.getTags({ searchTerm, mapId, tagListType }));
        const taggableItems = results?.data?.taggable_items || [];
        return taggableItems.map(item => new TaggableItem(item));
    };
    return { getTags };
};

export const useGetTags = (searchTerm = '', mapId = '', tagListType = '') => {
    const dispatch = useDispatch();
    return useQuery(
        ['get-tags'],
        async () => {
            const results = await dispatch(postActions.getTags({ searchTerm, mapId, tagListType }));
            const taggableItems = results?.data?.taggable_items || [];
            return taggableItems.map(item => new TaggableItem(item));
        },
        {
            enabled: !!mapId && !!tagListType && searchTerm.length > 0,
            refetchOnWindowFocus: false,
        }
    );
};

export const useEditTags = ({ onSuccess = noop, onError = noop } = {}) => {
    const dispatch = useDispatch();
    return useMutation(
        async ({ textArray = [], postId = null }) => {
            const results = await dispatch(postActions.editTags(postId, textArray));

            if (results.error) {
                console.log('error', results.error);
                throw new Error(results.error);
            }

            return results.data;
        },
        {
            onSuccess,
            onError,
        }
    );
};

export const usePostTextArray = (postId = '') => {
    return useQuery(['post'], () => getPostTextArray(postId));
};
