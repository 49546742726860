import classNames from 'classnames';
import { IconCheck } from '../../common/icons';
import copies from '../../../copies.json';

import styles from './LoadingPostsBlocks.module.scss';

const UpToDateBlock = () => {
    return (
        <div className={styles.block}>
            <span className={classNames(styles.icon, styles.check)}>
                <IconCheck width={10} height={6} strokeWidth={4} />
            </span>
            <span>{copies?.feed?.blocks?.upToDate}</span>
        </div>
    );
};

export default UpToDateBlock;
