import React from 'react';

const IconMoreSettingsCategories = ({ stroke }) => (
    <svg width='4' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='2' cy='8' r='1' fill='#666A70' stroke='#666A70' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round' />
        <circle cx='2' cy='2' r='1' fill='#666A70' stroke='#666A70' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round' />
        <circle cx='2' cy='14' r='1' fill='#666A70' stroke='#666A70' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
);

export default IconMoreSettingsCategories;
