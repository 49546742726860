import { noop } from "lodash";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

export const EditButton = ({ onClick = noop, isHovered }) => {
  return (
      <span
          className={classNames("absolute text-blue-600 right-3 cursor-pointer", {
            "hidden": !isHovered,
          })}
          onClick={onClick}>
            <FormattedMessage id='stepInfo.edit'/>
        </span>
  );
}