import { useState } from 'react';
import Menu from './Menu';

import styles from './DropdownMenu.module.scss';

const MenuItem = ({ title, items = [], icon, onClick = () => {} }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const subMenuProps =
        items.length > 0
            ? {
                  onMouseEnter: () => setIsSubMenuOpen(true),
                  onMouseLeave: () => setIsSubMenuOpen(false),
              }
            : {};

    return (
        <div
            className={styles.menuItem}
            onClick={event => {
                event.stopPropagation();
                onClick(event);
            }}
            {...subMenuProps}
        >
            {icon && <span className={styles.icon}>{icon}</span>}
            <div className={styles.contentWrapper}>
                <span className={styles.text}>{title}</span>
                {items.length > 0 && (
                    <div className={'flex-center'}>
                        <span>
                            <img src={'../assets/img/moreMenu.svg'} alt={title} />
                        </span>
                        {isSubMenuOpen && <Menu items={items} className={styles.subMenuItem} />}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MenuItem;
