import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../components/common/buttons/CustomButton';
import GetMailLink from './GetMailLink';
import { createDeepLink } from '../../helpers';
import copiesPrefix from '../../copies.json';
import Logo from "../../components/common/Logo";

const copies = copiesPrefix.onboarding.mobileInitialScreen;

const MobileInitialScreen = () => {
    const [sendMailOpen, setSendMailOpen] = useState(false);
    const [storeLink, setLink] = useState('');
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setStoreLink();
    }, []);
    
    const setStoreLink = async () => {
        const link = await createDeepLink(null, {
            reason: 'open_store_from_mobile_onboarding',
            link_type: 'create_map_screen_store',
            isAutls: true,
        });
        setLink(link);
    };
    
    return (
        <div className={classNames(styles.block, { [styles.noPointerEvents]: sendMailOpen })}>
            <div className={styles.upperSection}>
                <div className={styles.header}>
                    <Logo type={Logo.Types.WHITE} size={Logo.Sizes.SMALL} isLink/>
                </div>
                <img className={styles.image} alt='' src='/assets/img/mobileOnboarding.png'/>
            </div>
            <div className={styles.bottomSection}>
                <h3 className={styles.title}>{copies.slogan}</h3>
                <p className={styles.description}>{copies.description}</p>
                <a href={storeLink} target={'_parent'} rel='noopener noreferrer' className={styles.buttonWrapper}>
                    <CustomButton className={styles.getStepsButton} type={ButtonType.PRIMARY}>
                        {copies.getSteps}
                    </CustomButton>
                </a>
                <div className={styles.divider}>
                    <span className={styles.line}/>
                    <span className={styles.text}>Or</span>
                </div>
                <span className={styles.description}>{copies.visitMM}</span>
                <CustomButton
                    className={styles.sendLinkButton}
                    type={ButtonType.SECONDARY_BLACK}
                    onClick={() => setSendMailOpen(true)}>
                    {copies.sendLink}
                </CustomButton>
            </div>
            {sendMailOpen && (
                <>
                    <div className={styles.blur}/>
                    <GetMailLink
                        onClickPrev={() => {
                            setSendMailOpen(false);
                            window.scrollTo(0, 0);
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default MobileInitialScreen;
