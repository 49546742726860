import styles from './index.module.scss';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import { useRef, useState } from 'react';
import { X } from 'phosphor-react';
import { useEffect } from 'react';
import { noop } from 'lodash';

const placeholder = 'Enter a name';

const AddNamePopup = ({ onClick, defaultName = '', isLoading = false, withCancelButton = false, onCancel = noop }) => {
    const [namePicked, setNamePicked] = useState(defaultName);
    const isDisabled = !namePicked.length;
    const inputRef = useRef();

    useEffect(() => inputRef.current.focus(), []);

    const onChange = e => {
        const value = e.target.value;
        setNamePicked(value);
    };

    return (
        <div className={styles.container}>
            <input placeholder={placeholder} className={styles.input} onChange={onChange} defaultValue={namePicked} ref={inputRef} />
            <CustomButton
                loader={isLoading}
                text='Next'
                size={ButtonSize.SMALL}
                type={ButtonType.PRIMARY}
                disabled={isDisabled}
                onClick={() => onClick(namePicked)}
            />
            {withCancelButton && (
                <span className={styles.cancelButton}>
                    <X size={20} color='#2171EC' weight='bold' onClick={onCancel} alt='close-popup' className='pointer'/>
                </span>
            )}
        </div>
    );
};

export default AddNamePopup;
