import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import styles from './index.module.scss';
import { FormattedMessage } from 'react-intl';
import { UploadSimple } from 'phosphor-react';
import { IconClap } from '../../common/icons';
import IconComment from '../../common/icons/IconComment';
import classNames from 'classnames';
import { postActions } from '../../../redux/posts';
import { useDispatch } from 'react-redux';
import ImageCarousel from '../../../lib/ImageCarousel';
import { createDeepLink, copyToClipboardFunc, generateImageUrl } from '../../../helpers';
import { ShareTypes } from '../../../constants';
import { ToastContext, LINK_COPIED_TEXT } from '../../../context/ToastContext';
import ClapsModal from '../../common/modals/ClapsModal';
import { useModal } from '../../../hooks';
import { noop } from 'lodash';
import BaseModal, { ModalSizes } from '../../../modalSysyem/BaseModal';
import { clapPostEvent, sharePostEvent } from '../mixpanel';
import { modalsActions } from '../../../redux/modals';
import { TextArrayBody } from '../../common';
import { Linkify } from '../../common';

const createdByType = 'CREATED_BY';

const PostBody = ({
    isClapped,
    clapsCount,
    mapId,
    commentsCount,
    stepId,
    id,
    body,
    type,
    userId,
    attachments,
    attachmentsList,
    fullSizeImages,
    creator,
    title,
    fromWhere,
    className,
    onPostClick = noop,
    onTagPlace = noop,
    onTagError = noop,
    onTagClick = noop,
}) => {
    const dispatch = useDispatch();
    const { setToast } = useContext(ToastContext);
    const { modalOpen, setModalOpen } = useModal(false);
    const [showMore, setShowMore] = useState(true);
    const contentRef = useRef(null);

    useEffect(() => {
        hasOwerflow();
    }, []);

    const hasOwerflow = () => {
        setShowMore(contentRef.current?.scrollHeight > contentRef.current?.clientHeight);
    };

    const sharePost = async () => {
        setToast({ text: LINK_COPIED_TEXT });
        const shareLink = await createDeepLink(mapId, {
            link_type: 'post',
            inProductShareMethod: ShareTypes.POST_PROFILE,
            data: { post_id: id, step_id: stepId },
            reason: 'share-post',
        });
        sharePostEvent({ type: 'post', fromWhere });
        copyToClipboardFunc(shareLink);
    };

    const onImageClick = index => {
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: true,
                imagePreviewArray: attachmentsList,
                imagePreviewIndex: index,
            })
        );
    };

    return (
        <div className={classNames(styles.container, className)}>
            <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => {
                    return (
                        <a target='blank' href={decoratedHref} key={key} style={{ color: '#2171EC' }}>
                            {decoratedText}
                        </a>
                    );
                }}
            >
                <TextArrayBody
                    ref={contentRef}
                    onTagPlace={onTagPlace}
                    onTagClick={onTagClick}
                    onTagError={onTagError}
                    body={body}
                    postId={id}
                    className={classNames({ [styles.paragraph]: true, [styles.content]: showMore })}
                >
                    {type === createdByType ? (
                        <div className={classNames({ [styles.discoverd]: type === createdByType })}>
                            <FormattedMessage id='post.discovered_place' />
                        </div>
                    ) : null}
                </TextArrayBody>
                {showMore && (
                    <span className={classNames({ [styles.paragraph]: true, [styles.showMore]: true })} onClick={() => setShowMore(false)}>
                        show more
                    </span>
                )}
            </Linkify>
            {attachments?.length > 0 && (
                <div className='m-t-8'>
                    <ImageCarousel
                        classNameDiv={styles.imgBlock}
                        classNameImage={styles.imgSlide}
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            width: 504,
                            height: 325,
                        }}
                        images={attachmentsList.map(({ attachment }) =>
                            generateImageUrl({ imageId: attachment.image_id, reason: 'mm_image_carousel', height: 650, width: 1008 })
                        )}
                        onImageClick={onImageClick}
                    />
                </div>
            )}
            <div className={styles.numbersPanel}>
                <span onClick={() => setModalOpen(true)} data-cy='claps-count' className={classNames({ [styles.hide]: !clapsCount })}>
                    {clapsCount} claps
                </span>
                <span data-cy='comments-count' onClick={onPostClick} style={{ cursor: 'pointer' }} className={classNames({ [styles.hide]: !commentsCount })}>
                    {commentsCount} comments
                </span>
            </div>
            <div className={styles.engagements}>
                <div
                    data-cy='post-clap-button'
                    onClick={event => {
                        event.stopPropagation();
                        dispatch(
                            postActions.clapPost({
                                stepId: stepId,
                                postId: id,
                                newClapStatus: Number(!isClapped),
                                mapId: mapId,
                            })
                        );
                        clapPostEvent({
                            id,
                            fromWhere,
                            text: body,
                            userId,
                            attachments,
                            creatorPreview: creator,
                            stepId,
                            stepTitle: title,
                        });
                    }}
                >
                    <IconClap width={36} height={36} isClapped={isClapped} />
                </div>
                <div data-cy='post-comment-button' onClick={onPostClick}>
                    <IconComment size={24} />
                </div>
                <div data-cy='post-share-button' onClick={sharePost}>
                    <UploadSimple size={30} />
                </div>
            </div>
            <BaseModal isActive={modalOpen} onClose={() => setModalOpen(false)} size={ModalSizes.MEDIUM}>
                <ClapsModal postId={id} onClose={() => setModalOpen(false)} />
            </BaseModal>
        </div>
    );
};

export default PostBody;
