import React from 'react';

const IconWebSite = ({ stroke }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
        <g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
            <g stroke={stroke} strokeWidth='2'>
                <g transform='translate(-338 -647) translate(339 648)'>
                    <circle cx='10.5' cy='10.5' r='10.5' />
                    <path d='M0 10.5L21 10.5M10.5 0c2.626 2.875 4.119 6.607 4.2 10.5-.081 3.893-1.574 7.625-4.2 10.5-2.626-2.875-4.119-6.607-4.2-10.5.081-3.893 1.574-7.625 4.2-10.5h0z' />
                </g>
            </g>
        </g>
    </svg>
);

export default IconWebSite;
