import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { searchByValue } from '../../../../helpers';
import { useModal } from '../../../../hooks';
import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import SearchResult, { actionButtons, iconType } from '../../../common/buttons/SearchResult';
import OrDevider from '../../../common/OrDevider';
import { SearchTypes } from '../../../SearchSteps2';
import styles from './index.module.scss';
import copiesPrefix from '../../../../copies.json';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

const SEARCH_RADIUS = 500;
const copies = copiesPrefix.addStep;

const PickPlaceSuggestionsModal = ({
    placeName,
    mapId,
    mapName,
    position,
    onBack,
    onDone,
    address,
    startedLoading = noop,
    finisedLoading = noop,
    isPhotoLocation = false,
}) => {
    const { modalOpen, setModalOpen } = useModal(false);
    const [places, setPlaces] = useState([]);

    const lat = position?.lat;
    const lon = position?.lon;

    useEffect(() => {
        (async () => {
            startedLoading();
            const resultsFromSearch = await searchPlaces();
            const firstThreePlaces = resultsFromSearch.slice(0, 3);
            finisedLoading();

            if (!firstThreePlaces.length) {
                createNewPlace();
            } else {
                setPlaces(firstThreePlaces);
                setModalOpen(true);
            }
        })();
        // on mount effect, so:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchPlaces = async () => {
        try {
            const params = {
                map_id: mapId,
                lat,
                lon,
                term: placeName,
                data_type: [SearchTypes.PLACE, SearchTypes.LOCATION],
                max_radius: SEARCH_RADIUS,
            };
            return await searchByValue(params);
        } catch (e) {
            console.error('getting places from drop a pin failed', e);
        }
    };

    const createNewPlace = () => {
        setModalOpen(false);
        onDone({ lat, lon, title: placeName, position, secondary_title: address });
    };

    const newPlaceTitle = `Create new place for ${placeName}`;

    return (
        <BaseModal
            isActive={modalOpen}
            size={ModalSizes.MEDIUM}
            withCloseIcon
            className={styles.modal}
            onClose={() => {
                setModalOpen(false);
                onBack();
            }}
        >
            <div className={styles.container}>
                <span className={styles.didYouMean}>{copies.did_you_mean}</span>
                {places.map((place, idx) => (
                    <SearchResult
                        idx={idx}
                        key={place?._id}
                        type={place?.result_type}
                        archived={place?.archived}
                        iconSrc={iconType.step}
                        actionButton={actionButtons.GRAY_ARROW}
                        showActionButton
                        content={place?.title}
                        subContent={place?.geocode_address?.formatted_address || place?.address || place?.secondary_title}
                        className={classNames(styles.searchResult, { [styles.stepResult]: place?.category === 'steps' })}
                        onClick={() => {
                            mixpanel.track(mixpanelEvents.STEP_CREATION_DID_YOU_MEAN_SELECTED, {
                                [mixpanelProperties.MAP_ID]: mapId,
                                [mixpanelProperties.MAP_NAME]: mapName,
                                [mixpanelProperties.IS_NEW_PLACE]: false,
                                [mixpanelProperties.FLOW]: isPhotoLocation ? 'location_from_photo' : 'drop_pin',
                            });
                            setModalOpen(false);
                            onDone(place);
                        }}
                    />
                ))}
                <OrDevider />
                <SearchResult
                    iconSrc={iconType.step}
                    type={'step'}
                    showActionButton
                    actionButton={actionButtons.GRAY_ARROW}
                    className={classNames(styles.searchResult)}
                    content={newPlaceTitle}
                    subContent={address}
                    onClick={createNewPlace}
                />
            </div>
        </BaseModal>
    );
};

export default PickPlaceSuggestionsModal;
