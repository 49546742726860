import React, { useCallback, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { mapActions } from '../../../redux/map';

const useStyles = makeStyles(() => ({
    label: {
        color: '#d3d3d3!important',
        borderRadius: '10px',
        marginBottom: '20px',
        alignItems: 'self-start',
        '& .MuiTypography-root': {
            color: '#666a70!important',
            wordBreak: 'break-all',
            fontFamily: 'Roboto!important',
        },
    },
    blue: {
        color: '#2171ec!important',
        borderRadius: '10px',
        marginBottom: '20px',
        alignItems: 'self-start',
        '& .MuiTypography-root': {
            color: '#2171ec!important',
            wordBreak: 'break-all',
        },
    },
    red: {
        color: '#ff7262!important',
        borderRadius: '10px',
        marginBottom: '20px',
        alignItems: 'self-start',
        '& .MuiTypography-root': {
            color: '#ff7262!important',
            wordBreak: 'break-all',
        },
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    },
    active: {
        marginBottom: '16px',
        alignItems: 'self-start',
        color: '#3a3c3f!important',
        wordBreak: 'break-word',
        '& .MuiTypography-body1': {
            color: '#3a3c3f',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: 'Roboto-Medium',
        },
    },
    default: {
        display: 'flex',
        marginBottom: '16px',
        marginLeft: '0px',
        alignItems: 'center',
        color: '#3a3c3f!important',
        wordBreak: 'break-all',
        cursor: 'default',
        '& img': {
            marginRight: '8px',
        },
        '& .MuiTypography-body1': {
            color: '#aeb1b6',
            lineHeight: 'normal',
            fontSize: '15px',
        },
    },
    gray: {
        display: 'flex',
        marginBottom: '16px',
        marginLeft: '0px',
        alignItems: 'center',
        color: '#666a70!important',
        wordBreak: 'break-all',
        cursor: 'default',
        '& img': {
            marginRight: '8px',
        },
        '& .MuiTypography-body1': {
            color: '#666a70',
            lineHeight: 'normal',
            fontSize: '15px',
        },
    },
    icon: {
        borderRadius: 5,
        width: 16,
        height: 16,
        border: '1px solid #666a70',
        backgroundColor: '#ffffff',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#f0f0f0',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'none',
        },
    },
    checkedIcon: {
        border: '1px solid #02c77c',
        backgroundColor: '#02c77c',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#02c77c!important',
        },
    },
}));

const Tag = ({ handleChangedTags, handleSelectClick, mapId, stepId, tag, checked, suggestionsTags, tagToAdd, tagToRemove, handled, afterAddPost }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(checked);

    const GreenCheckbox = withStyles({
        root: {
            color: '#aeb1b6',
            padding: '0 9px',
            paddingRight: '16px',
        },
        checked: {
            '& .MuiSvgIcon-root': {
                fill: '#02c77c',
            },
            '& :hover': {
                fill: '#02c77c',
            },
        },
    })(props => (
        <Checkbox
            disableRipple
            className={classes.root}
            checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            color='#02c77c'
            {...props}
        />
    ));

    const handleCheckboxChange = useCallback(
        (event, tag) => {
            setSelected(s => {
                handleSelectClick(tag._id, !s);
                return !s;
            });
            if (!afterAddPost) {
                if (event.target.checked) {
                    dispatch(mapActions.addTagToStep(stepId, mapId, [tag?._id], event.target.checked));
                } else {
                    dispatch(mapActions.deleteTagFromStep(stepId, mapId, [tag?._id], event.target.checked, afterAddPost));
                }
            }
            handleChangedTags(true);
        },
        [afterAddPost]
    );

    return (
        <Grid item xs={6}>
            {suggestionsTags ? (
                !handled ? (
                    <FormControlLabel
                        checked={selected}
                        className={classNames(classes.default, {
                            [classes.blue]: selected && tagToAdd,
                            [classes.red]: !selected && tagToRemove,
                        })}
                        control={
                            tagToAdd ? (
                                <img src='/assets/img/steps3Images/blueV.svg' />
                            ) : tagToRemove ? (
                                <img src='/assets/img/steps3Images/redMinus.svg' />
                            ) : selected ? (
                                <img src='/assets/img/steps3Images/grayV.svg' />
                            ) : (
                                <img src='/assets/img/steps3Images/grayMinus.svg' />
                            )
                        }
                        label={tag?.name}
                    />
                ) : (
                    <FormControlLabel
                        checked={selected}
                        className={classNames(classes.gray)}
                        control={
                            tagToAdd ? (
                                <img src='/assets/img/steps3Images/greenV.svg' />
                            ) : tagToRemove ? (
                                <img src='/assets/img/steps3Images/grayMinus.svg' />
                            ) : selected ? (
                                <img src='/assets/img/steps3Images/greenV.svg' />
                            ) : (
                                <img src='/assets/img/steps3Images/grayMinus.svg' />
                            )
                        }
                        label={tag?.name}
                    />
                )
            ) : (
                <FormControlLabel
                    checked={selected}
                    className={classNames(classes.active, {
                        [classes.label]: !selected,
                    })}
                    control={<GreenCheckbox onChange={e => handleCheckboxChange(e, tag)} name={tag?.name} />}
                    label={tag?.name}
                />
            )}
        </Grid>
    );
};

export default Tag;
