import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

export const socialPostInfoTypes = {
    LIKES: { title: 'Likes', src: '/assets/img/social-info-likes.svg' },
    POSTS: { title: 'Posts', src: '/assets/img/social-info-posts.svg' },
    SAVES: { title: 'Saves', src: '/assets/img/social-info-saves.svg' },
    VIEWS: { title: 'Views', src: '/assets/img/social-info-views.svg' },
};

const SocialPostInfo = ({ type, number, className }) => {
    return (
        <div className={classNames([styles.block, className])}>
            <img src={type?.src} />
            <h3>
                <span>{number}</span>
                {type?.title}
            </h3>
        </div>
    );
};

export default SocialPostInfo;
