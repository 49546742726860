import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Triangle = ({ size = 3, color = '#D1E3FF', className, style }) => {
    const pxSize = `${size}px`;
    return <span className={classNames(styles.triangle, className)} style={{ borderTopColor: color, borderWidth: pxSize, ...style }} />;
};

Triangle.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

export default Triangle;
