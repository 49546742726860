const WELCOME_MESSAGE_FIRST_TIME_USERS_KEY = 'welcomeMessageSeenByUsers';

const getWelcomeMessageSeenByUsers = () => {
    try {
        return JSON.parse(localStorage.getItem(WELCOME_MESSAGE_FIRST_TIME_USERS_KEY)) || {};
    } catch (error) {
        return {};
    }
};

export const isUserSeenWelcomeMessage = userId => {
    try {
        return getWelcomeMessageSeenByUsers()[userId];
    } catch (error) {
        console.info(error);
        return false;
    }
};

export const setIsUserSeenWelcomeMessage = userId => {
    const users = getWelcomeMessageSeenByUsers();

    localStorage.setItem(
        WELCOME_MESSAGE_FIRST_TIME_USERS_KEY,
        JSON.stringify({
            ...users,
            [userId]: true,
        })
    );
};
