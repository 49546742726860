import { default as ReactLinkify } from 'react-linkify';

const Linkify = ({ children }) => {
    return (
        <ReactLinkify
            componentDecorator={(decoratedHref, decoratedText, key) => {
                return (
                    <a target='_blank' href={decoratedHref} key={key} style={{ color: '#2171EC' }} rel='noreferrer'>
                        {decoratedText}
                    </a>
                );
            }}
        >
            {children}
        </ReactLinkify>
    );
};

export default Linkify;
