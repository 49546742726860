import { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { TagsContext } from '../../../../../context';
import Tag from '../../../../../components/FilterTags/Tag';

const TagsFilter = () => {
    const { selectedTags, setSelectedTags, isFiltered } = useContext(TagsContext);

    return (
        <div data-cy='tags-filter'>
            {selectedTags?.length > 0 && isFiltered && (
                <Grid container item xs={12} style={{ marginLeft: '18px' }}>
                    {selectedTags?.map(tag => (
                        <Tag
                            key={tag._id}
                            onClick={tag => {
                                const newSelectedTags = selectedTags?.find(item => item._id === tag._id)
                                    ? selectedTags.filter(selectedTag => selectedTag._id !== tag._id)
                                    : [...selectedTags, tag];
                                setSelectedTags(newSelectedTags);
                            }}
                            fromWhere={'map_list'}
                            canDelete={true}
                            tag={tag}
                        />
                    ))}
                </Grid>
            )}
        </div>
    );
};

export default TagsFilter;
