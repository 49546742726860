import { useState } from 'react';
import { noop } from 'lodash';
import AddName from '../../../../lib/map/popups/AddName';
import AddLocation from '../../../../lib/map/popups/AddLocation/AddLocation';
import PickPlaceSuggestionsModal from '../PickPlaceSuggestionsModal';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

export const DropPinFlows = {
    PICK_LOCATION: 'PICK_LOCATION',
    PICK_NAME: 'PICK_NAME',
    PICK_PLACE: 'PICK_PLACE',
};

const DropPinFlow = ({ address, mapId, mapName, position, file, fromFlow = 'dropPin', onCancel = noop, onClick = noop, onDone = noop }) => {
    const [dropPinFlow, setDropPinFlow] = useState({ flowName: DropPinFlows.PICK_LOCATION, params: {} });
    const [isRenderModal, setIsRenderModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [placeName, setPlaceName] = useState('');

    const withCancelButton = fromFlow === 'dropPin';

    const addLocationClicked = () => {
        // on click next
        if (file) {
            mixpanel.track(mixpanelEvents.STEP_CREATION_PHOTO_LOCATION_PLACE_SELECTED_NEXT, {
                [mixpanelProperties.MAP_ID]: mapId,
                [mixpanelProperties.MAP_NAME]: mapName,
                [mixpanelProperties.FLOW]: 'location_from_photo',
            });
        } else {
            mixpanel.track(mixpanelEvents.MM_STEP_CREATION_DROP_PIN_PLACE_SELECTED_NEXT, {
                [mixpanelProperties.MAP_ID]: mapId,
                [mixpanelProperties.MAP_NAME]: mapName,
                [mixpanelProperties.FLOW]: 'drop_pin',
            });
        }

        setDropPinFlow({ flowName: DropPinFlows.PICK_NAME });
        onClick();
    };

    const onNamePicked = placeName => {
        mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_NAME_NAME_ADDED, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: mapName,
            [mixpanelProperties.FLOW]: 'drop_pin',
        });
        setIsRenderModal(true);
        setPlaceName(placeName);
    };

    const dropAPinSteps = {
        [DropPinFlows.PICK_LOCATION]: () => (
            <AddLocation address={address} onCancel={onCancel} onClick={addLocationClicked} file={file} withCancelButton={withCancelButton} />
        ),
        [DropPinFlows.PICK_NAME]: params => (
            <AddName
                onBack={() => setDropPinFlow({ flowName: DropPinFlows.PICK_LOCATION })}
                onClick={onNamePicked}
                {...params}
                isLoading={isLoading}
                withCancelButton={withCancelButton}
                onCancel={onCancel}
            />
        ),
    };

    const { flowName, params } = dropPinFlow;
    return (
        <>
            {dropAPinSteps[flowName](params)}
            {isRenderModal && (
                <PickPlaceSuggestionsModal
                    mapId={mapId}
                    mapName={mapName}
                    position={position}
                    onDone={params => {
                        setIsRenderModal(false);
                        onDone(params);
                    }}
                    isPhotoLocation={Boolean(file)}
                    onBack={() => {
                        setIsRenderModal(false);
                        setDropPinFlow({ flowName: DropPinFlows.PICK_NAME, params: { defaultName: placeName } });
                    }}
                    address={address}
                    startedLoading={() => setIsLoading(true)}
                    finisedLoading={() => {
                        setIsLoading(false);
                        setIsRenderModal(true);
                    }}
                    placeName={placeName}
                />
            )}
        </>
    );
};

export default DropPinFlow;
