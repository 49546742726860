import React from 'react';

const Terms = () => (
    <>
        <p>
            Welcome to http://www.steps.me/ (together with its sub domains, Materials ,Marks and services, the “<strong>Site</strong>
            ”). Please read the following Terms of Use carefully before using this Site so that you are aware of your legal rights and obligations with respect
            to Steps Solutions Ltd. and any of its affiliates (&quot;
            <strong>Steps</strong>
            &quot;,&quot;
            <strong>we</strong>
            &quot;, &quot;
            <strong>our</strong>
            &quot; or &quot;
            <strong>us</strong>
            &quot;). By accessing or using the Site, you expressly acknowledge and agree that you are entering a legal agreement with us and have understood and
            agreed to comply with, and be legally bound by, these Terms of Use, together with the Privacy Policy (the &quot;
            <strong>Terms</strong>
            &quot;).You hereby waive any applicable rights to require an original (non-electronic)signature or delivery or retention of non-electronic records,
            to the extent not prohibited under applicable law. If you do not agree to be bound by these Terms please do not access or use the Site.
        </p>
        <p>
            <strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Background. </strong>
            The Site is intended to provide information about the platform and the services of Steps.
        </p>
        <p>
            <strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Modification. </strong>
            We reserve the right, at our discretion, to change these Terms at any time. Such change will be effective ten (10) days following posting of the
            revised Terms on the Site, and your continued use of the Site thereafter means that you accept those changes.
        </p>
        <p>
            <strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ability to Accept Terms.</strong> The Site is only intended for individuals aged thirteen
            (13) years or older. If you are between the age of thirteen (13) and eighteen (18) years then, prior to visiting or using the Site, you must first
            review these Terms with your parent or guardian to make sure that you and your parent or guardian understand these terms andconditions and agree to
            them.
        </p>
        <p>
            <strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Site Access.</strong> For such time as these Terms are in effect, we hereby grant you
            permission to visit and use the Site provided that you comply with these Terms and applicable law.
        </p>
        <p>
            <strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Restrictions.</strong> You shall not: (i)copy, distribute or modify any part of the Site
            without our prior written authorization; (ii) use, modify, create derivative works of, transfer (by sale,resale, license, sub license, download or
            otherwise), reproduce, distribute,display or disclose Materials (defined below), except as expressly authorized herein; (iii) disrupt servers or
            networks connected to the Site; (iv) use or launch any automated system (including without limitation, &quot;robots&quot;and &quot;spiders&quot;) to
            access the Site; and/or (v) circumvent, disable or otherwise interfere with security-related features of the Site or features that prevent or
            restrict use or copying of any Material or that enforce limitations on use of the Site.
        </p>
        <p>
            <strong>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Payments to Steps.</strong> Except as expressly set forth in the Terms, your general
            right to access and use the Site is currently for free,but Steps may in the future charge a fee for certain access or usage. You will not be charged
            for any such access or use of the Site unless you first agree to such charges, but please be aware that any failure to pay applicable charges may
            result in you not having access to some or all of the Site.
        </p>
        <p>
            <strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Intellectual Property Rights.</strong>
        </p>
        <p>
            7.1. Materials and Marks. The (i) content on the Site, including without limitation, the text, documents, articles,brochures, descriptions,
            products, software, graphics, photos, sounds, videos,interactive features, and services (collectively, the &quot;
            <strong>Materials</strong>
            &quot;); and (ii) the trademarks, service marks and logos contained therein(“
            <strong>Marks</strong>
            ”, and together with the Materials, the “<strong>Content</strong>
            ”), are the property of Steps and/or its licensors and may be protected by applicable copyright or other intellectual property laws and treaties.
            “Steps”, the Steps logo are Marks of Steps or its affiliates. All other Marks used on the Site are the trademarks, service marks, or logos of their
            respective owners. We reserve all rights not expressly granted in and to the Site and the Materials.{' '}
        </p>
        <p>
            7.2. Use of Content. Content on the Site is provided to you for your information and personal use only and may not be used, modified, copied,
            distributed, transmitted, broadcast, displayed, sold,licensed, de-compiled, or otherwise exploited for any other purposes whatsoever without our
            prior written consent. If you download or print a copy of the Content you must retain all copyright and other proprietary notices contained therein.{' '}
        </p>
        <p>
            <strong>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information Description. </strong>
            We attempt to be as accurate as possible. However, we cannot and do not warrant that the Materials available on the Site is accurate, complete,
            reliable,current, or error-free. We reserve the right to make changes in or to the Materials,or any part thereof, in our sole judgment, without the
            requirement of giving any notice prior to or after making such changes to the Materials. Your use of the Materials, or any part thereof, is made
            solely at your own risk and responsibility.
        </p>
        <p>
            <strong>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Links to Third Party Websites.</strong>
        </p>
        <p>
            9.1. The Site may contain links, and may enable you to post content, to third party websites that are not owned or controlled by Steps (&quot;
            <strong>Third Party Websites</strong>
            &quot;).We are not affiliated with, have no control over, and assume no responsibility for the content, privacy policies, or practices of, any third
            party websites. You:(i) are solely responsible and liable for your use of and linking to third party websites and any content that you may send or
            post to a third party website; and (ii) expressly release Steps from any and all liability arising from your use of any third party website.
            Accordingly, we encourage you to read the terms and conditions and privacy policy of each third party website that you may choose to visit.
        </p>
        <p>
            9.2. Steps permits you to link to the Site provided that: (i) you link to but do not replicate any page on this Site; (ii) the hyperlink text shall
            accurately describe the Materials as it appears on the Site; (iii) you shall not misrepresent your relationship with Steps or present any false
            information about Steps and shall not imply in any way that we are endorsing any services or products, unless we have given you our express prior
            consent; (iv) you shall not link from a Third Party Website which prohibits linking to third parties; (v) such Third party Website does not contain
            content that (a) is offensive or controversial (both at our discretion), or (b) infringes any intellectual property, privacy rights, or other rights
            of any person or entity; and/or (vi) you, and your website, comply with these Terms and applicable law.{' '}
        </p>
        <p>
            <strong>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Privacy.</strong> We will use any personal information that we may collect or obtain in
            connection with the Site in accordance with our privacy policy which is available at https://steps.me/privacy-policy/ (“
            <strong>Privacy Policy</strong>
            ”). You agree that we may use personal information that you provide or make available to us in accordance with the Privacy Policy.
        </p>
        <p>
            <strong>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Warranty Disclaimers.</strong>
        </p>
        <p>
            11.1. This section applies whether or not the services provided under the Site are for payment. Applicable law may not allow the exclusion of
            certain warranties, so to that extent certain exclusions set forth herein may not apply.{' '}
        </p>
        <p>
            11.2. THE SITE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED.
            STEPS HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY,TITLE, FITNESS FOR A
            PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR FROM A COURSE OF DEALING OR USAGE OF TRADE. STEPS DOES NOT GUARANTEE THAT THE
            SITE WILL BE FREE OF BUGS, SECURITY BREACHES, OR VIRUS ATTACKS. THE SITE MAY OCCASIONALLY BE UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR
            OTHER REASONS. YOU AGREE THAT STEPS WILL NOT BE HELD RESPONSIBLE OR LIABLE FOR THIRD PARTY CONTENT, YOUR DATA, YOUR PERSONAL INFORMATION, AND ANY
            CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT MAY RESULT FROM TECHNICAL PROBLEMS OF THE INTERNET,SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF
            OUR OR OTHER SERVERS. WE DO NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT, MATERIALS, PRODUCT, OR SERVICE THAT IS FEATURED OR ADVERTISED ON THE SITE
            BY A THIRD PARTY.
        </p>
        <p>
            11.3. YOUR RELIANCE ON, OR USE OF, OR INTERACTION WITH ANY SITE USER OR OWNER, IS AT YOUR SOLE RISK. IF YOU HAVE A DISPUTE WITH ANY SITE USER OR
            OWNER IN CONNECTION WITH THE SITE, YOU AGREE THAT STEPS IS NOT LIABLE FOR ANY CLAIMS OR DAMAGES ARISING OUT OF OR CONNECTED WITH SUCH A DISPUTE.
            STEPS RESERVES THE RIGHT, BUT HAS NO OBLIGATION, TO MONITOR ANY SUCH DISPUTE.
        </p>
        <p>
            11.4. EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, STEPS DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR
            IMPLIED, AS TO THE SECURITY OF ANY INFORMATION YOU MAY PROVIDE OR ACTIVITIES YOU ENGAGE IN DURING THE COURSE OF YOUR USE OF THE SITE.
        </p>
        <p>
            <strong>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limitation of Liability.</strong>
        </p>
        <p>
            12.1. TO THE FULLEST EXTENT PERMISSIBLE BY LAW,STEPS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL,CONSEQUENTIAL, PUNITIVE OR
            INCIDENTAL DAMAGES OF ANY KIND, OR FOR ANY LOSS OF DATA, REVENUE, PROFITS OR REPUTATION, ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR
            INABILITY TO USE, THE SITE, EVEN IF STEPS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
        </p>
        <p>
            12.2. IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF STEPS FOR ANY DAMAGES AND LOSSES ARISING UNDER OR IN CONNECTION WITH THESE TERMS OR THAT
            RESULT FROM YOUR USE OF, OR INABILITY TO USE, THE SITE SHALL NOT IN ANY CIRCUMSTANCE EXCEED THE TOTAL AMOUNT OF FEES, IF ANY, ACTUALLY PAID BY YOU
            TO STEPS FOR USING THE SITE WITHIN THE THREE (3) MONTHS PRECEDING THE DATE OF BRINGING THE CLAIM.{' '}
        </p>
        <p>
            <strong>13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Indemnity.</strong> You agree to defend, indemnify and hold harmless Steps and our
            affiliates, and our respective officers, directors, employees and agents,from and against any and all claims, damages, obligations, losses,
            liabilities,costs and expenses (including but not limited to attorney&#x27;s fees) arising from:(i) your use of, or inability to use, the Site; (ii)
            your violation of any third party right, including without limitation any intellectual property or privacy right; (iii) your interaction with any
            Site user; and (iv) your violation of these Terms.
        </p>
        <p>
            <strong>14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Term and Termination. </strong>
            These Terms are effective until terminated by Steps or you. Steps, in its sole discretion, has the right to terminate these Terms and/or your access
            to the Site, or any part thereof, immediately at any time and with or without cause (including, without any limitation, for a breach of these
            Terms). Steps shall not be liable to you or any third party for termination of the Site, or any part thereof.If you object to any term or condition
            of these Terms, or any subsequent modifications thereto, or become dissatisfied with the Site in any way, your only recourse is to immediately
            discontinue use of the Site. Upon termination of these Terms, you shall cease all use of the Site.
            <strong> </strong>
            This Section 15 and Sections 8 (Intellectual Property Rights), 11 (Privacy), 12 (Warranty Disclaimers), 13 (Limitation of Liability), 14
            (Indemnity), and 16 (Independent Contractors) to 19 (General) shall survive termination of these Terms.
        </p>
        <p>
            <strong>15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Independent Contractors.</strong> You and Steps are independent contractors. Nothing in
            these Terms creates a partnership, joint venture, agency, or employment relationship between you and Steps. You must not under any circumstances
            make,or undertake, any warranties, representations, commitments or obligations on behalf of Steps.
        </p>
        <p>
            <strong>16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Assignment. </strong>
            These Terms, and any rights and licenses granted here under, may not be transferred or assigned by you but may be assigned by Steps without
            restriction or notification to you. Any prohibited assignment shall be null and void.
        </p>
        <p>
            <strong>17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Governing Law. </strong>
            Steps reserves the right to discontinue or modify any aspect of the Site at any time. These Terms and the relationship between you and Steps shall
            be governed by and construed in accordance with the laws of the State of Israel, without regard to its principles of conflict of laws.
        </p>
        <p>
            <strong>18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; General.</strong> These Terms shall constitute the entire agreement between you and
            Steps concerning the Site. If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision
            shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. No waiver of any term of
            these Terms shall be deemed a further or continuing waiver of such term or any other term, and a party&#x27;s failure to assert any right or
            provision under these Terms shall not constitute a waiver of such right or provision. YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING
            OUT OF OR RELATED TO THE SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
            BARRED.
        </p>
        <p>
            <strong>Last updated: 30 January 2019</strong>
        </p>
    </>
);

export default Terms;
