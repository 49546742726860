import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { arrToObj, getImageSrcFromFile, isStepTypeOf } from '../../../../../helpers';
import { mapSelectors, mapActions } from '../../../../../redux/map';
import { userSelectors } from '../../../../../redux/user';
import ImageCarousel from '../../../../../lib/ImageCarousel';
import Like from '../../../../../components/common/Like';
import RecommendStepButton from '../../../../../components/HighlightStep/HighlightStepButton';
import { StepTypes } from '../../../../../constants';
import { userActionActions } from '../../../../../redux/userAction';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../../helpers/mixpanel';
import Skeleton from './Skeleton';
import { StepBadge } from '../../../../../components/common';

import copiesPrefix from '../../../../../copies.json';
import styles from './index.module.scss';

const copies = copiesPrefix.post;

const StepElement = ({ step, onClickElement, onMouseEnter, onMouseLeave, onClickAddTags = noop, onClickAddPost = noop }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const stepCaching = useSelector(mapSelectors.steps(mapId))[step?._id];
    const fetchedOrCachedStep = stepCaching?.isShortData ? step : stepCaching;
    const tagsPreview = arrToObj(fetchedOrCachedStep?.components || [], 'type').selected_tags?.tags_preview;
    const [liked, setLiked] = useState(step?.is_liked_by_user);

    const isHighlighted = useMemo(() => {
        return isStepTypeOf(StepTypes.RECOMMENDED, stepCaching);
    }, [stepCaching?.indications]);

    const IMAGE_CAROUSEL_WIDTH = 320;
    const IMAGE_CAROUSEL_HEIGHT = 240;

    const generateImagesUrl = () =>
        step?.posts_attachments_list?.posts_attachments.map(image =>
            getImageSrcFromFile({
                image,
                isGenerateInFixedSize: true,
                width: IMAGE_CAROUSEL_WIDTH,
                height: IMAGE_CAROUSEL_HEIGHT,
                reason: 'map_manager_StepElement',
            })
        );
    const generatedImagesUrl = useMemo(generateImagesUrl, [step?.posts_attachments_list?.posts_attachments]);

    const onClickLikeStep = like => {
        setLiked(like);
        dispatch(mapActions.likeStep(mapId, step._id, like ? 1 : 0, step.likes_count || 0));
    };

    const handleRecommendClicked = useCallback(() => {
        const isAlreadyRecommended = isStepTypeOf(StepTypes.RECOMMENDED, step);
        mixpanel.track(mixpanelEvents.STEP_HIGHLIGHT, {
            [mixpanelProperties.FROM_WHERE]: 'step_page',
            [mixpanelProperties.HIGHLIGHT_TYPE]: isAlreadyRecommended ? StepTypes.REGULAR : StepTypes.RECOMMENDED,
        });
        dispatch(mapActions.changeStepType(mapId, step._id, StepTypes.RECOMMENDED, isAlreadyRecommended));
    }, [step]);

    useEffect(() => {
        setLiked(stepCaching?.is_liked_by_user || step?.is_liked_by_user);
    }, [step, stepCaching]);

    return (
        <>
            {!step ? (
                <Skeleton />
            ) : (
                <div className={styles.container} onClick={onClickElement} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <div className={styles.imageBox}>
                        {step?.posts_attachments_list?.posts_attachments.length === 0 ? (
                            <div
                                className={styles.noImageBlock}
                                onClick={() => {
                                    onClickAddPost && onClickAddPost(true);
                                    dispatch(
                                        userActionActions.setSelectedPlaceToAdd({
                                            title: step?.title,
                                            position: [step?.lat, step?.lon],
                                            type: 'step',
                                            stepId: step?._id,
                                        })
                                    );
                                    mixpanel.track(mixpanelEvents.ADD_STEP_OPEN, {
                                        [mixpanelProperties.FROM_WHERE]: 'step_list',
                                    });
                                    localStorage.setItem('fromWhereAddPost', 'step_list');
                                }}
                            >
                                <div className={styles.ico}>
                                    <img src='/assets/img/steps3Images/addPhoto.svg' alt='' />
                                    <span>{copies.add_photo}</span>
                                </div>
                            </div>
                        ) : (
                            <ImageCarousel
                                settings={{
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    lazyLoad: true,
                                }}
                                images={generatedImagesUrl}
                                classNameDiv={styles.imgBlock}
                                classNameImage={styles.imgSlide}
                                onImageClick={onClickElement}
                            />
                        )}
                    </div>
                    <div className={styles.content}>
                        <div className={styles.titleContainer}>
                            <div className={styles.title}> {step.title}</div>
                            <StepBadge businessStatus={fetchedOrCachedStep?.hpaw?.business_status} stepIndications={fetchedOrCachedStep?.indications} />
                        </div>
                        <div className={styles.tags}>
                            {tagsPreview?.length === 0 ? (
                                <span
                                    className={styles.noTags}
                                    onClick={e => {
                                        e.stopPropagation();
                                        onClickAddTags();
                                    }}
                                >
                                    {copies.add_tags}
                                </span>
                            ) : (
                                <span className={styles.tag}>
                                    {tagsPreview?.map((tag, index) => `${tag.name} ${index !== tagsPreview.length - 1 ? '• ' : ''}`)}
                                </span>
                            )}
                        </div>
                        <div className={styles.interactions}>
                            <div className='flex'>
                                <RecommendStepButton
                                    handleRecommendClicked={e => {
                                        e.stopPropagation();
                                        handleRecommendClicked();
                                    }}
                                    isRecommended={isHighlighted}
                                />
                                <Like
                                    isLiked={liked}
                                    onClickLike={e => {
                                        e.stopPropagation();
                                        onClickLikeStep(!liked);
                                    }}
                                    likesCount={stepCaching?.likes_count || step.likes_count || 0}
                                />
                            </div>
                            <div className={styles.details}>
                                <span>{step.direct_posts_count || step.posts_count} posts</span>
                                <span>{step.views_count} views</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default StepElement;
