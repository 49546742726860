import { GET_MEMBERS, REMOVE_MEMBERS, SET_PERMISSIONS_MEMBERS, DEEP_LINK, GET_MANAGERS, GET_MAP_FOLLOWER_DETAILS } from './types';

export const getMapFollowerDetails = ({ mapId, userId }) => ({
    type: GET_MAP_FOLLOWER_DETAILS,
    request: {
        method: 'GET',
        url: `/steps3/map/${mapId}/followers/${userId}`,
        params: {
            with_points_information: true,
        },
    },
    meta: {
        thunk: true,
    },
});

export const getMembers = ({ mapId, cursor, searchTerm, sortBy, orderBy }) => ({
    type: GET_MEMBERS,
    request: {
        method: 'GET',
        url: `/map/${mapId}/followers`,
        params: {
            cursor,
            with_points_information: true,
            without_managers: true,
            search_term: searchTerm,
            sort_by: sortBy,
            order_by: orderBy,
        },
    },
    meta: {
        thunk: true,
    },
});

export const getAdmins = ({ mapId, cursor, searchTerm }) => ({
    type: GET_MANAGERS,
    request: {
        method: 'GET',
        url: `/map/${mapId}/followers`,
        params: {
            cursor,
            without_members: true,
            search_term: searchTerm,
            sort_by: 'joined_to_map_at',
            order_by: 'asc',
        },
    },
    meta: {
        thunk: true,
    },
});

export const removeMembers = ({ mapId, data }) => ({
    type: REMOVE_MEMBERS,
    request: {
        method: 'DELETE',
        url: `/map/${mapId}/followers`,
        data: {
            users_ids: data,
        },
    },
    meta: {
        mapId,
        thunk: true,
    },
});

export const setPermissionsMembers = ({ mapId, data }) => ({
    type: SET_PERMISSIONS_MEMBERS,
    request: {
        method: 'PUT',
        url: `/map/${mapId}/followers`,
        data: {
            followers_fields_to_update: data,
        },
    },
    meta: {
        thunk: true,
    },
});

export const setDeepLink = data => ({
    type: DEEP_LINK,
    payload: { data },
});
