import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import IconCheck from '../../../../components/common/icons/IconCheck';
import classNames from 'classnames';
import ModalsDelete from '../../../../components/common/modals/ModalsDelete';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import { mapActions, mapSelectors } from '../../../../redux/map';
import { nanoid } from 'nanoid';
import CategoryRating from '../../../../components/CategoryRating';
import FlipMove from 'react-flip-move';
import copiesPrefix from '../../../../copies.json';

import styles from './index.module.scss';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

const copies = copiesPrefix.ratings;

const Rating = () => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const ratingComponent = useMemo(() => map?.components?.find(component => component.type === 'rating'), [map]);
    const [categories, setCategories] = useState(ratingComponent?.rating_subcomponents || []);
    const [removedId, setRemovedId] = useState(null);
    const [addActive, setAddActive] = useState(false);
    const [del, setDel] = useState(false);
    const [disable, setDisable] = useState(true);
    const [text, setText] = useState(false);
    const [error, setError] = useState(false);
    const [errorDuplicate, setErrorDuplicate] = useState(false);
    const inputRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(mapActions.fetchSingleMap(mapId)).then(({ data }) => {
            if (data) {
                const ratingComponent = data?.components?.find(component => component.type === 'rating');
                setCategories(ratingComponent?.rating_subcomponents);
            }
        });
    }, [mapId]);

    useEffect(() => {
        if (addActive) {
            inputRef.current?.focus();
        }
    }, [addActive]);

    const addCategory = () => {
        const id = nanoid(11);
        if (inputRef.current?.value?.trim().length !== 0) {
            setCategories([...categories, { id, text: inputRef.current?.value, type: 'score1to5' }]);
            dispatch(
                mapActions.changeIncludesComponent(mapId, 'rating', {
                    rating_subcomponents: [
                        ...categories,
                        {
                            id,
                            text: inputRef.current?.value,
                            type: 'score1to5',
                        },
                    ],
                })
            );
            setAddActive(false);
            setDisable(true);
            setError(false);
            setErrorDuplicate(false);
            mixpanel.track(mixpanelEvents.MM_SETTINGS_RATINGS_ADDED, {
                [mixpanelProperties.RATING_TITLE]: inputRef.current?.value,
            });
            inputRef.current.value = '';
        } else {
            inputRef.current.value = '';
            setError(true);
            setDisable(true);
        }
    };
    const handleAdd = () => {
        setError(false);
        setErrorDuplicate(false);
        setAddActive(!addActive);
    };

    const keyUp = useCallback(
        key => {
            if (inputRef.current?.value?.length > 0 && key.keyCode === 13) {
                if (!disable) {
                    addCategory();
                }
            }
        },
        [inputRef.current?.value]
    );

    const keyDown = useCallback(
        key => {
            if (inputRef.current?.value?.length <= 0 && key.keyCode === 32) {
                key.preventDefault();
                inputRef.current.value = '';
                setDisable(true);
            }
        },
        [inputRef.current?.value]
    );

    const onCancel = () => {
        setDel(false);
    };

    const handleDelete = (text, id) => {
        setRemovedId(id);
        setText(text);
        setDel(true);
    };

    const handleRemove = useCallback(() => {
        dispatch(
            mapActions.changeIncludesComponent(mapId, 'rating', {
                rating_subcomponents: categories.filter(item => item?.id !== removedId),
            })
        ).then(({ data }) => {
            if (data) {
                setCategories(categories.filter(item => item?.id !== removedId));
            }
        });
        onCancel();
    }, [removedId]);

    const onChange = () => {
        setError(false);
        const res = categories.find(item => item.text === inputRef.current?.value);
        if (!res) {
            setErrorDuplicate(false);
            setDisable(true);
            if (inputRef.current?.value?.length > 0) {
                setDisable(false);
            } else {
                setDisable(true);
            }
        } else {
            setErrorDuplicate(true);
            setDisable(true);
        }
    };

    const onClickAway = () => {
        if (inputRef.current?.value.length > 0) {
            if (inputRef.current?.value?.trim().length !== 0) {
                if (!disable) {
                    addCategory();
                }
            } else {
                inputRef.current.value = '';
                setError(true);
                setDisable(true);
            }
        } else {
            setAddActive(false);
            setDisable(true);
        }
    };

    const handleUp = idx => {
        if (idx !== 0) {
            [categories[idx - 1], categories[idx]] = [categories[idx], categories[idx - 1]];
            dispatch(
                mapActions.changeIncludesComponent(mapId, 'rating', {
                    rating_subcomponents: [...categories],
                })
            );
            setCategories([...categories]);
        }
    };
    const handleDown = idx => {
        if (idx !== categories.length - 1) {
            [categories[idx + 1], categories[idx]] = [categories[idx], categories[idx + 1]];
            dispatch(
                mapActions.changeIncludesComponent(mapId, 'rating', {
                    rating_subcomponents: [...categories],
                })
            );
            setCategories([...categories]);
        }
    };

    return (
        <>
            <div className={styles.exampleBlock}>
                <div className={styles.headerTitle}>{copies.title}</div>
                <div className={styles.exampleText} dangerouslySetInnerHTML={{ __html: copies.description }} />
                <img className={styles.img} src='/assets/img/steps3Images/exampleRatings.svg' />
            </div>
            <div className={styles.categories}>
                <FlipMove enterAnimation='none' leaveAnimation='none'>
                    {categories.map((item, idx) => (
                        <CategoryRating
                            key={item?.id}
                            categoriesNumber={categories.length}
                            item={item}
                            idx={idx}
                            handleUp={handleUp}
                            handleDown={handleDown}
                            handleDelete={handleDelete}
                            {...item}
                        />
                    ))}
                </FlipMove>
                {addActive ? (
                    <ClickAwayListener onClickAway={() => onClickAway()}>
                        <div className={styles.buttonGroup}>
                            <div className={styles.disableInput}>
                                <input
                                    className={styles.input}
                                    ref={inputRef}
                                    onChange={onChange}
                                    onKeyUp={key => keyUp(key)}
                                    onKeyDown={key => keyDown(key)}
                                    placeholder={copies.category_name_placeholder}
                                />
                                <button
                                    disabled={disable}
                                    className={classNames(styles.iconCheck, { [styles.enable]: !disable }, { [styles.disable]: disable })}
                                    onClick={addCategory}
                                >
                                    <IconCheck color='#ffffff' width={11} height={7} />
                                </button>
                            </div>
                            {error && <div className={styles.error}>Category name can`t be empty!</div>}
                            {errorDuplicate && <div className={styles.error}>{copies.category_exists}</div>}
                        </div>
                    </ClickAwayListener>
                ) : (
                    <div className={styles.buttonGroup} onClick={() => handleAdd()}>
                        <div>
                            <span>+ {copies.add}</span>
                        </div>
                    </div>
                )}
            </div>
            {del && (
                <ModalsDelete
                    textFirst={`${copies.deleteModal.title} <b>${text}</b> ${copies.deleteModal.title_category}`}
                    textSecond={copies.deleteModal.description}
                    onCancel={onCancel}
                    onDecline={handleRemove}
                />
            )}
        </>
    );
};

export default Rating;
