import { useContext, useEffect } from 'react';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useGetTags } from '../hooks';
import { CircleNotch } from '../../../components/common/icons';
import { TagListTypes } from '../../../constants';
import { ToastContext } from '../../../context';

import styles from '../TagLocationInPost.module.scss';

export const Loader = () => {
    return (
        <span className={styles.tagLocationCta}>
            <CircleNotch color='#2171ec' size={20} weight='bold'>
                <animateTransform attributeName='transform' type='rotate' dur='1s' to='360 0 0' repeatCount='indefinite' />
            </CircleNotch>
            <FormattedMessage id='tagLocationInPost.tag_location' />
        </span>
    );
};

const LocationSuggestionPopover = ({ searchTerm = '', mapId = '', onClick = noop }) => {
    const { setToast } = useContext(ToastContext);
    const { data: locations, isFetching, isFetched } = useGetTags(searchTerm, mapId, TagListTypes.LOCATIONS);

    useEffect(() => {
        if (isFetched && locations.length === 0) {
            setToast({
                id: 'post.tag_location_no_results',
                withIcon: false,
            });
        }
    }, [isFetched]);

    if (isFetching) {
        return <Loader />;
    }

    if (!locations?.length) {
        return null;
    }

    return (
        <ul className={styles.locationSuggestionPopup}>
            {locations.map(({ id, name, type, address }) => (
                <li key={id} onClick={() => onClick({ id, type, name })}>
                    <h1>{name}</h1>
                    <h2>{address}</h2>
                </li>
            ))}
        </ul>
    );
};

export default LocationSuggestionPopover;
