import React, { useEffect, useState, useContext, useRef } from 'react';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import Input, { DiscardButtonTypes } from '../../../inputs/SearchInput2';
import copiesPrefix from '../../../../../copies.json';
import { ToastContext, ToastTypes } from '../../../../../context';
import { useDebounce } from '../../../../../hooks';
import ModalsDelete from '../../ModalsDelete';
import styles from '../index.module.scss';

const copies = copiesPrefix.ratings.settingsModal.input;

const RatingInput = ({ className, rating, autoFocus, onClickDiscard, addNewRating, onChange: onInputChangeProp, isRatingExists }) => {
    const { setToast } = useContext(ToastContext);
    const [inputValue, setInputValue] = useState(rating.text);
    const [isExist, setExist] = useState(false);
    const [deleteCategoryModalIsOpen, setDeleteCategoryModalIsOpen] = useState(false);
    const isExistDebounced = useDebounce(isExist, 500);
    const inputRef = useRef(null);
    
    useEffect(() => {
        if (!inputValue) return;
        setExist(isRatingExists(inputValue, rating));
    }, [inputValue]);
    
    useEffect(() => {
        if (isExistDebounced) {
            triggerExistingCategoryToast();
        }
    }, [isExistDebounced]);
    
    const triggerExistingCategoryToast = () => {
        setToast({
            type: ToastTypes.ERROR,
            text: copies.existingCategoryToastText,
        });
    };
    
    const onInputChange = e => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
        onInputChangeProp(inputValue);
    };
    
    const onClickAway = () => {
        if (!rating.isNewRating || rating.added) return;
        if (!inputValue) {
            deleteCategory();
            return;
        }
        
        if (!isExist) {
            addNewRating({ text: inputValue, isNewRating: true, added: true });
        } else {
            inputRef.current.focus();
            triggerExistingCategoryToast();
        }
    };
    
    const deleteCategory = () => {
        if (rating.id) {
            setDeleteCategoryModalIsOpen(true);
        } else {
            onClickDiscard();
        }
    };
    
    return (
        <>
            <ClickAwayListener onClickAway={onClickAway}>
                <Input
                    className={classNames(className, { [styles.ratingInputError]: isExistDebounced })}
                    value={inputValue}
                    onChange={onInputChange}
                    placeholder={copies.placeholder}
                    withSearchIcon={false}
                    onClickDiscard={deleteCategory}
                    discardButtonType={DiscardButtonTypes.TRASH}
                    autoFocus={autoFocus}
                    onClickEnter={onClickAway}
                    ref={inputRef}
                />
            </ClickAwayListener>
            {deleteCategoryModalIsOpen && (
                <ModalsDelete
                    className={styles.deleteRatingModal}
                    onCancel={() => setDeleteCategoryModalIsOpen(false)}
                    onDecline={onClickDiscard}
                    textFirst={`${copies.deleteWarning} <b>"${inputValue}"</b> category ?
                    `}
                />
            )}
        </>
    );
};

export default RatingInput;
