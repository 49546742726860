import classNames from 'classnames';
import styles from './index.module.scss';

const Skeleton = ({ viewOnMap }) => (
    <div className={styles.block}>
        <div className={styles.header}>
            {viewOnMap && (
                <div className={styles.viewOnMap}>
                    <span className={classNames(styles.button, 'animate')} />
                    <span className={classNames(styles.ctaButton, 'animate')} />
                </div>
            )}
            <div className={styles.topSection}>
                {!viewOnMap && <span className={classNames(styles.button, 'animate')} />}
                <span className={classNames(styles.title, 'animate')} />
                <span className={classNames(styles.discovered, 'animate')} />

                <div className={styles.buttons}>
                    <span className={classNames(styles.button, 'animate')} />
                    <span className={classNames(styles.button, 'animate')} />
                    <span className={classNames(styles.button, 'animate')} />
                </div>
            </div>
            <div className={classNames(styles.bottomSection, { [styles.noLeftMargin]: viewOnMap })}>
                <span className={classNames(styles.image, 'animate')} />
                <span className={classNames(styles.discovered, 'animate')} />
                <span className={classNames(styles.name, 'animate')} />
            </div>
        </div>
        <div className={styles.images}>
            <span className={classNames(styles.image, 'animate')} />
            <span className={classNames(styles.image, styles.noBorderRight, 'animate')} />
        </div>
        <div className={styles.engagements}>
            <span className={classNames(styles.like, 'animate')} />
            <span className={classNames(styles.count, 'animate')} />
            <span className={classNames(styles.count, styles.postsCount, 'animate')} />
            <span className={classNames(styles.label, 'animate')} />
            <span className={classNames(styles.count, styles.viewsCount, 'animate')} />
            <span className={classNames(styles.label, 'animate')} />
        </div>
        <div className={styles.tags}>
            <span className={classNames(styles.circle, 'animate')} />
            <span className={classNames(styles.label, 'animate')} />
            <span className={classNames(styles.circle, 'animate')} />
            <span className={classNames(styles.label, 'animate')} />
            <span className={classNames(styles.circle, 'animate')} />
            <span className={classNames(styles.label, 'animate')} />
            <span className={classNames(styles.edit, 'animate')} />
        </div>
        <div className={styles.ratings}>
            <span className={classNames(styles.star, 'animate')} />
            <span className={classNames(styles.star, 'animate')} />
            <span className={classNames(styles.star, 'animate')} />
            <span className={classNames(styles.star, 'animate')} />
            <span className={classNames(styles.star, 'animate')} />
            <span className={classNames(styles.count, 'animate')} />
            <span className={classNames(styles.edit, 'animate')} />
        </div>
        <div className={styles.addPostButton}>
            <span className={classNames(styles.image, 'animate')} />
            <span className={classNames(styles.input, 'animate')} />
        </div>
        <div className={styles.posts}>
            <div className={styles.post}>
                <div className={styles.header}>
                    <span className={classNames(styles.image, 'animate')} />
                    <div className={styles.user}>
                        <span className={classNames(styles.name, 'animate')} />
                        <span className={classNames(styles.details, 'animate')} />
                    </div>
                    <span className={classNames(styles.button, 'animate')} />
                </div>
                <span className={classNames(styles.text, 'animate')} />
                <span className={classNames(styles.carousel, 'animate')} />
            </div>
            <div className={styles.post}>
                <div className={styles.header}>
                    <span className={classNames(styles.image, 'animate')} />
                    <div className={styles.user}>
                        <span className={classNames(styles.name, 'animate')} />
                        <span className={classNames(styles.details, 'animate')} />
                    </div>
                    <span className={classNames(styles.button, 'animate')} />
                </div>
                <span className={classNames(styles.text, 'animate')} />
                <span className={classNames(styles.carousel, 'animate')} />
            </div>
        </div>
    </div>
);

export default Skeleton;
