import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import BlueButton, { ButtonType } from '../../buttons/CustomButton';
import { ClickAwayListener } from '@material-ui/core';
import classNames from 'classnames';
import { sendMessagePromoteToSupport } from '../../../../helpers';
import loaderAnimation from '../../../../lottie/btnLoader.json';
import Lottie from 'react-lottie';
import CustomButton from '../../buttons/CustomButton';

const ModalsImportSteps = ({ handleClose }) => {
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);
    const [loader, setLoader] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    const handleMessageToSupport = useCallback(async () => {
        setLoader(true);
        await sendMessagePromoteToSupport({
            type: 'import_steps',
            message: message,
        }).then(() => {
            setLoader(false);
            setSent(true);
            setTimeout(() => {
                handleClose();
            }, 1000);
        });
    }, [message]);
    return (
        <div className={styles.modals}>
            <ClickAwayListener onClickAway={() => handleClose()}>
                <div className={styles.block}>
                    <div className={styles.modalsHeader}>
                        <div onClick={() => handleClose()} className={styles.closeBlock}>
                            <img src='/assets/img/icon_cancel_blue.svg' />
                        </div>
                    </div>
                    <div className={styles.modalsContent}>
                        <p className={styles.text}>
                            We are working to add more <br /> options soon!
                        </p>
                        <p className={styles.secondText}>Where is your content located?</p>
                        <input
                            onChange={e => setMessage(e.target.value)}
                            autoFocus
                            placeholder={'Add name or web address here'}
                            className={styles.input}
                            type='text'
                        />
                        <CustomButton
                            size={'mediumSize'}
                            className={classNames({ [styles.sent]: sent }, styles.sendBtn)}
                            onClick={handleMessageToSupport}
                            type={ButtonType.PRIMARY_BLUE}
                            singleClick={false}
                            disabled={message?.length === 0}
                            text={
                                loader ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={40} height={20} /> : sent ? 'Sent!' : 'Send'
                            }
                        />
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default ModalsImportSteps;
