import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import MainMenu from '../../components/MainMenu';
import { ErrorBoundary } from 'react-error-boundary';
import LogRocket from 'logrocket';
import { addMapReducer } from '../../redux/store';
import { userActions, userSelectors } from '../../redux/user';
import { postActions } from '../../redux/post';
import ROUTES from '../../config/routes';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import Loader from '../Loader';
import { getManagerMaps } from '../../helpers';
import ShareMapPopUp from '../../components/ShareMapPopUp';
import PromoteMapPopup from '../../components/PromoteMapPopup';
import GetAppModal from '../../components/GetAppModal';
import { mapActions, mapSelectors } from '../../redux/map';
import { memberActions } from '../../redux/member';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import Onboard from '../../containers/Onboard';
import { stepActions } from '../../redux/step';
import Import from '../../containers/Onboard/Import';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/circleLoader.json';
import { userActionSelectors } from '../../redux/userAction';
import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react';
import config from '../../config';
import { TagsContextProvider } from '../../context';
import copiesPrefix from '../../copies.json';
import Toggle from '../../components/common/buttons/Toggle';
import { routeTabs } from '../../containers/StepsManager/constants';
import AdvancedLevelPopUp from '../../components/MapStages/AdvancedLevelPopUp';
import Logo from "../../components/common/Logo";
import { SITE_URL } from "../../constants";

const copies = copiesPrefix.mapManager;

const factory = SplitSdk({
  core: {
    authorizationKey: config.splitio.authorizationKey,
    key: localStorage.getItem('userId') || 'user',
  },
});

const DAY_IN_MILISEC = 86400000;

const MapManager = ({ setIsLoading: setIsLoadingProp, onLogout }) => {
  const maps = useSelector(userSelectors.maps);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedMapId = useSelector(userSelectors.selectedMapId);
  const { step_id: stepId, post_id: postId, map_id: mapId } = useQuery();
  const [initialData, setInitialData] = useState({
    mapId: selectedMapId,
  });
  const [selectedMap, setSelectedMap] = useState(null);
  const stepsAmount = Object.values(useSelector(mapSelectors.steps(selectedMap?._id))).length;
  const [isLoading, setIsLoading] = useState(true);
  const isAuth = useSelector(userSelectors.isAuth);
  const user = useSelector(userSelectors.user);
  const [sharePopup, setSharePopup] = useState(false);
  const [sharePrompt, setSharePrompt] = useState(false);
  const [promotePopup, setPromotePopup] = useState(false);
  const [sendSuccessPopup, setSendSuccessPopup] = useState(false);
  const [randomTokenMap, setRandomTokenMap] = useState('');
  const [getAppModal, setGetAppModal] = useState(false);
  const [forceOpenAddPostComponent, setForceOpenAddPostComponent] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [importView, setImportView] = useState(null);
  const [importedFrom, setImportedFrom] = useState(null);
  const [bulkLoading, setBulkLoader] = useState(false);
  const goto = useSelector(userActionSelectors.goto);
  const [hoverOnStep, setHoverStep] = useState(false);
  const [showStepOnMap, setShowStepOnMap] = useState(null);
  const [isParallel, setIsParallel] = useState(false);
  const [activeTabSteps, setActiveTabSteps] = useState();
  const [pendingStep, setPendingStep] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  
  useEffect(() => {
    if (!goto) return;
    switch (goto) {
      case 'add_step':
        navigate('/' + ROUTES.MAP);
        break;
      case 'tags':
        navigate('/' + ROUTES.TAGS);
        break;
      case 'rating':
      case 'image':
      case 'description':
        navigate('/' + ROUTES.MAP_SETTING);
        break;
      
      default:
        break;
    }
  }, [goto]);
  
  useEffect(() => {
    if (pendingStep || selectedStep) {
      navigate('/' + ROUTES.MAP);
    }
  }, [pendingStep, selectedStep]);
  
  useEffect(() => {
    if (!stepsAmount || stepsAmount === 0) {
      return;
    }
    
    // TODO: Unit test!!!
    const stepsInMountKey = `prompt${selectedMap._id}Count`;
    const lastTimeSeenPromptKey = `prompt${selectedMap._id}Seen`;
    const stepsInMapCount = localStorage.getItem(stepsInMountKey);
    const lastTimeSeenPrompt = localStorage.getItem(lastTimeSeenPromptKey);
    const isSeenMessageToday = Date.now() - Number(lastTimeSeenPrompt) < DAY_IN_MILISEC;
    
    if (stepsInMapCount && isSeenMessageToday) {
      return;
    }
    
    if (stepsAmount >= 100 && Number(stepsInMapCount) < 100) {
      localStorage.setItem(lastTimeSeenPromptKey, Date.now());
      localStorage.setItem(stepsInMountKey, 100);
      
      setSharePrompt({
        title: copies.popups['100_steps'].title,
        subTitle: copies.popups['100_steps'].subTitle,
      });
    } else if (stepsAmount >= 30 && Number(stepsInMapCount) < 30) {
      localStorage.setItem(lastTimeSeenPromptKey, Date.now());
      localStorage.setItem(stepsInMountKey, 30);
      
      setSharePrompt({
        title: copies.popups['30_steps'].title,
        subTitle: copies.popups['30_steps'].subTitle,
      });
    } else if (stepsAmount >= 5 && Number(stepsInMapCount) < 5) {
      localStorage.setItem(lastTimeSeenPromptKey, Date.now());
      localStorage.setItem(stepsInMountKey, 5);
      
      setSharePrompt({
        title: copies.popups['5_steps'].title,
        subTitle: copies.popups['5_steps'].subTitle,
      });
    }
  }, [selectedMap, stepsAmount]);
  
  useEffect(() => sharePrompt && localStorage.setItem('prompt', Date.now()), [sharePrompt]);
  
  useEffect(() => {
    if (importView) localStorage.setItem('importMode', importView);
    else {
      if (localStorage.getItem('importMode') != 'Instagram-success') localStorage.removeItem('importMode');
    }
  }, [importView]);
  
  useEffect(() => {
    (async () => {
      let maps = [];
      if (isAuth !== 1) return;
      try {
        await dispatch(userActions.resolveIP());
      } catch (error) {
        console.error(error);
      }
      
      try {
        await dispatch(userActions.getUserData());
      } catch (error) {
        console.error(error);
      }
      
      try {
        maps = await getManagerMaps();
      } catch (error) {
        console.error(error);
      }
      
      let d = JSON.parse(localStorage.getItem('dataSessionStart'));
      if (maps.length > 0) {
        mixpanel.track(mixpanelEvents.SESSION_START, d?.data_parsed);
        const mapsOrdered = maps?.sort((a, b) => b.members_count - a.members_count);
        await fetchQueryParams(maps).then(async () => {
          await dispatch(
              userActions.setMaps(
                  mapsOrdered?.map(map => ({
                    ...map,
                    tags_object: map?.tags_object || map?.tag_objects,
                  }))
              )
          );
          
          addMapReducer(mapsOrdered);
          setIsLoadingProp(false);
          setIsLoading(false);
        });
        localStorage.setItem('isOb', false);
      } else if (maps?.length === 0) {
        navigate(ROUTES.ONBOARD);
        setIsLoadingProp(false);
        setIsLoading(false);
        localStorage.setItem('isOb', true);
      }
    })();
  }, [isAuth]);
  
  useEffect(() => {
    dispatch(userActions.setMaps(maps));
    
    if (maps?.length > 0 && !selectedMap) {
      const recentMapId = localStorage.getItem('recent_mapId');
      const initialMapId = recentMapId || initialData.mapId;
      
      if (initialMapId) {
        const map = maps.find(map => map._id === initialMapId);
        if (map) setSelectedMap(map);
        else setSelectedMap(maps[0]);
      } else {
        setSelectedMap(maps[0]);
      }
    }
  }, [maps]);
  
  const removeNavigationParams = (pathname, reason) => {
    if (reason) {
      mixpanel.track(mixpanelEvents.ROUTING_ERROR, {
        [mixpanelProperties.REASON]: reason,
      });
    }
    navigate(
        {
          pathname: pathname,
          search: ``,
        },
        { replace: true }
    );
  };
  
  const checkMapPermission = (mapId, userMaps) => {
    if (!(mapId && userMaps)) {
      removeNavigationParams(ROUTES.MAP, 'not_valid_url');
      return false;
    }
    const userHasMapPermission = userMaps.some(map => map._id === mapId);
    if (!userHasMapPermission) {
      removeNavigationParams(ROUTES.MAP, 'no_permissions');
    }
    return userHasMapPermission;
  };
  
  const fetchQueryParams = userMaps => {
    return new Promise(resolve => {
      const fetchStep = (stepId, postObj = null) => {
        const recentMapId = localStorage.getItem('recent_mapId');
        dispatch(mapActions.getFullStepData(recentMapId, stepId)).then(({ data }) => {
          if (!data) {
            removeNavigationParams(ROUTES.MAP, 'not_valid_url');
            resolve();
          }
          const stepObj = data.step;
          if (checkMapPermission(stepObj.map_id, userMaps)) {
            setInitialData({
              post: postObj,
              step: stepObj,
              mapId: stepObj.map_id,
            });
          }
          resolve();
        });
      };
      
      if (!(mapId || stepId || postId)) resolve();
      if (postId) {
        dispatch(postActions.getStepPost(postId)).then(({ data }) => {
          if (!data) {
            removeNavigationParams(ROUTES.MAP, 'not_valid_url');
            resolve();
          }
          const postObj = data.post;
          fetchStep(postObj.step_id, postObj);
        });
      }
      if (stepId) fetchStep(stepId);
      if (mapId && checkMapPermission(mapId, userMaps)) {
        setInitialData({ mapId: mapId });
        resolve();
      }
    });
  };
  
  const updateMaps = data => {
    setSelectedMap(data[data.length - 1]);
  };
  
  const createLink = useCallback(async () => {
    if ((user?.id || user?._id) !== null && selectedMap) {
      let link = '';
      try {
        // FIXME:
        // eslint-disable-next-line
        link = await createDeepLink('link', 'Copy', selectedMap?._id, user?.id || user?._id);
      } catch (e) {
        link = `${SITE_URL}/m/${selectedMap?._id}`;
      }
      dispatch(memberActions.setDeepLink(link));
    }
  }, [selectedMap, user]);
  
  useEffect(() => {
    (async () => {
      const ob = localStorage.getItem('ob');
      const recentMapId = localStorage.getItem('recent_mapId');
      const stateSelectedMapId = selectedMap?._id;
      const initialDataMapId = initialData.mapId;
      const selectedMapId = ob ? recentMapId : (stateSelectedMapId || initialDataMapId);
      localStorage.removeItem('ob');
      
      if (!selectedMapId) {
        return;
      }
      
      localStorage.setItem('recent_mapId', String(selectedMapId));
      await dispatch(userActions.setSelectedMapId(selectedMapId));
      dispatch(mapActions.fetchSingleMap(selectedMapId));
      await createLink();
      registerMixPanelProps();
    })();
  }, [selectedMap]);
  
  useEffect(() => {
    if (!selectedMap || !user) return;
    LogRocket.identify(user.id, {
      name: user.name,
    });
    registerMixPanelProps();
  }, [user]);
  
  const registerMixPanelProps = () => {
    if (!selectedMap || !user) return;
    mixpanel.register({
      [mixpanelProperties.MAP_ID]: selectedMap._id,
      [mixpanelProperties.MAP_NAME]: selectedMap.name,
      [mixpanelProperties.USER_ID]: user.id,
      [mixpanelProperties.USER_NAME]: user.name,
    });
  };
  
  useEffect(() => {
    if (nextLocation) {
      containerChange(nextLocation);
    }
  }, [nextLocation]);
  
  const containerChange = useCallback(
      page => {
        dispatch(stepActions.setChangeLocation({}));
        navigate(page);
        setNextLocation(null);
        localStorage.removeItem('unhandled');
      },
      [navigate]
  );
  
  const randomMapToken = () => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 20; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
    setRandomTokenMap(text);
  };
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
  };
  
  const onClickShare = () => {
    createLink();
    setSharePopup(true);
  };
  
  const onStepsManagerCleanUp = () => {
    dispatch(mapActions.setGhostStep(selectedMap?._id, null));
    setPendingStep(null);
    setSelectedStep(null);
  };
  
  const navigateToAddStep = () => navigate(`/${ROUTES.MAP}/${ROUTES.ADD_STEP}`);
  
  const onAddStepClicked = useCallback(flowStepId => {
    mixpanel.track(mixpanelEvents.ADD_STEP_OPEN, {
      [mixpanelProperties.FROM_WHERE]: 'list_button',
    });
    
    if (!pathname.includes(ROUTES.MAP)) localStorage.setItem('fromWhereAddPost', 'list_button');
    navigateToAddStep();
  }, []);
  
  const onViewChanged = useCallback(({ value, route }) => {
    navigate(route);
  }, []);
  
  const outletContext = {
    toggleBulkLoader: setBulkLoader,
    randomMapToken,
    setRandomTokenMap: setRandomTokenMap,
    sharePopup,
    setSharePopup,
    selectedMap,
    setSelectedMap,
    selectedMapId: selectedMap?._id || initialData.mapId,
    pendingStep,
    selectedStep,
    importedStepsFrom: importedFrom,
    onClickShare,
    showStepOnMap,
    setShowStepOnMap,
    forceOpenAddPostComponent,
    setForceOpenAddPostComponent,
    activeTabSteps,
    setActiveTabSteps,
    onStepsManagerCleanUp,
    navigateToAddStep,
    onClickImportFrom: setImportView,
  };
  
  return (
      <ErrorBoundary fallback={<span></span>}>
        <SplitFactory factory={factory}>
          {(importView === 'Kml' || importView === 'Excel' || importView === 'Instagram') && (
              <div className={styles.blockLogo}>
                <div onClick={() => setImportView(null)} className={styles.logoName}>
                  <Logo size={Logo.Sizes.SMALL}/>
                </div>
              </div>
          )}
          {/* TODO: Disabled because of a bug in the import (server) that is not resolved yet */}
          {/*{importView === 'Instagram' && (*/}
          {/*    <InstagramImport*/}
          {/*        isImportMode={true}*/}
          {/*        onClickPrev={() => {*/}
          {/*            setImportedFrom({ src: importView });*/}
          {/*            setImportView(null);*/}
          {/*        }}*/}
          {/*    />*/}
          {/*)}*/}
          {(importView === 'Google' || importView === 'Excel') && (
              <Import
                  startImportForImportPage
                  mapId={selectedMap?._id}
                  updateMaps={e => {
                    mixpanel.track(mixpanelEvents.IMPORT_STEPS_FINISH, {
                      [mixpanelProperties.ADD_STEPS]: importView,
                    });
                    dispatch(mapActions.getAllStepsPreviewData(selectedMap?._id, 'active'));
                    setImportedFrom({ src: importView });
                    setImportView(null);
                    dispatch(mapActions.setMapCenter(selectedMap?._id, null));
                    navigate('/' + ROUTES.MAP);
                  }}
                  setImportSteps={() => {}}
                  handleClickBack={() => {
                    setImportView(null);
                  }}
                  handleImportSteps={e => {}}
                  type={importView === 'Google' ? 'GOOGLE' : 'EXCEL'}
                  mapName={selectedMap?.name}
                  setIsImport={() => {}}
                  setAddedStepsCount={() => {}}
                  setWaitingStepsCount={() => {}}
              />
          )}
          {isLoading ? (
              <Loader/>
          ) : matchPath(ROUTES.ONBOARD, pathname) ? (
              <div>
                <Onboard onLogout={onLogout} updateMaps={updateMaps} maps={maps} selectedMap={selectedMap} containerChange={containerChange}/>
              </div>
          ) : (
              <>
                {bulkLoading && (
                    <div className={styles.loader}>
                      <div>
                        <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={100} height={100}/>
                      </div>
                    </div>
                )}
                {hoverOnStep && <div className={styles.hoverStepOnListView}/>}
                <div className={styles.container}>
                  <MainMenu
                      setActiveTabSteps={setActiveTabSteps}
                      maps={maps}
                      onClickMap={map => {
                        setSelectedMap(map);
                        dispatch(userActions.setSelectedMapId(map._id));
                        navigate(ROUTES.MAP);
                      }}
                      containerChange={containerChange}
                      mapId={selectedMap?._id || initialData.mapId}
                      className={styles.sideBar}
                      onLogout={onLogout}
                      setSharePopup={setSharePopup}
                      onClickShare={() => {
                        createLink();
                      }}
                  />
                  <div className={styles.content}>
                    <TagsContextProvider>
                      <Outlet context={outletContext}/>
                    </TagsContextProvider>
                    {(pathname.includes(ROUTES.MAP) || pathname.includes(ROUTES.LIST)) && (
                        <div className={styles.buttonPanel}>
                          <Toggle
                              leftSection={{ text: 'Map view', value: routeTabs.map, route: ROUTES.MAP }}
                              rightSection={{ text: 'List', value: routeTabs.list, route: ROUTES.LIST }}
                              onChange={onViewChanged}
                              className={styles.viewToggle}
                              selected={pathname.includes(ROUTES.MAP) ? routeTabs.map : routeTabs.list}
                          />
                          <div className={styles.shareButton} onClick={() => onClickShare?.()}>
                            <img src='/assets/img/shareTopbarBlue.svg' alt=''/>
                            <span>{copies.share}</span>
                          </div>
                        </div>
                    )}
                  </div>
                  
                  {getAppModal && <GetAppModal setGetAppModal={setGetAppModal}/>}
                  {(sharePopup || sharePrompt) && (
                      <ShareMapPopUp
                          prompt={sharePrompt}
                          selectedMap={selectedMap}
                          setSharePopup={sharePrompt ? setSharePrompt : setSharePopup}
                          from={'menu'}
                          shareWhat={'map'}
                          fromWhere={'top-right-button'}
                          setIsParallel={setIsParallel}
                          isParallel={sharePopup === 'parallel'}
                      />
                  )}
                  {promotePopup && (
                      <PromoteMapPopup mapId={selectedMap?._id} setPromotePopup={setPromotePopup} setSendSuccessPopup={setSendSuccessPopup}/>
                  )}
                </div>
              </>
          )}
          <AdvancedLevelPopUp/>
        </SplitFactory>
      </ErrorBoundary>
  );
};

export default MapManager;
