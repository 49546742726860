import React from 'react';
import { noop } from 'lodash';
import styles from './index.module.scss';
import classNames from 'classnames';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { ArrowRight, Plus, CaretRight } from 'phosphor-react';

export const iconType = {
    globe: '/assets/img/icon_globe_search.svg',
    step: '/assets/img/icon_step_search.svg',
    blueStep: '/assets/img/searchBlueStepPin.svg',
};

export const actionButtons = {
    ARROW: <ArrowRight className={styles.actionButton} size='18' color='#2171EC' />,
    GRAY_ARROW: <CaretRight size='15' color='#110e0e' weight='bold' />,
    PLUS: <Plus className={styles.actionButton} size='15' color='#666A70' weight='bold' />,
};

// resultType can be step/location
const SearchResult = ({
    className,
    iconSrc,
    actionButton,
    content,
    subContent,
    postsAmount,
    onClick = noop,
    onClickAddPost = noop,
    archived,
    isLoading,
    type,
    isImportMode,
    idx,
    showAddButton = true,
}) => {
    const renderRightSection = () => {
        if (actionButton) return <div onClick={onClick}>{actionButton}</div>;
        if (archived) return <span className={styles.archivedPost}>Archived</span>;
        if (postsAmount === 0 || postsAmount === '0') return <span className={styles.addPost}>Add to map</span>;
        return `${postsAmount} posts`;
    };

    return (
        <div
            className={classNames([styles.block, className])}
            onClick={() => {
                const order = idx + 1;
                const mixPanelResultClickProps = {
                    [mixpanelProperties.RESULT_TYPE]: type,
                    [mixpanelProperties.RESULT_ORDER]: order.toString(),
                };

                if (onClickAddPost) {
                    mixPanelResultClickProps[mixpanelProperties.FROM_WHERE] = isImportMode ? 'imported' : 'step_page';
                }

                mixpanel.track(mixpanelEvents.MAP_SEARCH_RESULT_CLICK, mixPanelResultClickProps);

                onClick();
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className={styles.logo} src={iconSrc} alt='' />
                <span className={styles.content}>
                    <span data-testid='search-result-content' className={classNames(styles.text, { [styles.blueText]: type === 'step' })}>
                        {content}
                    </span>
                    <span className={classNames(styles.subContent)}>{subContent ? ` ${subContent}` : ''}</span>
                </span>
            </div>
            {isLoading ? (
                <img className={styles.loading} alt='' src='/assets/img/loadingSpinner.svg' />
            ) : (
                ((postsAmount && showAddButton) || actionButton) && (
                    <span
                        onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            (postsAmount === 0 || postsAmount === '0') && onClickAddPost();
                            mixpanel.track(mixpanelEvents.ADD_STEP_OPEN, {
                                [mixpanelProperties.FROM_WHERE]: 'search_result',
                            });
                        }}
                        className={postsAmount === 0 || postsAmount === '0' ? styles.zeroPosts : styles.posts}
                    >
                        {renderRightSection()}
                    </span>
                )
            )}
        </div>
    );
};

export default SearchResult;
