import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import LoginInput from '../../components/common/inputs/LoginInput';
import { PLATFORM_TYPE } from '../../config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/user';
import { userActionActions, userActionSelectors } from '../../redux/userAction';
import ROUTES from '../../config/routes';
import CustomButton, { ButtonType } from '../../components/common/buttons/CustomButton';
import copiesPrefix from '../../copies.json';
import Logo from '../../components/common/Logo';

import styles from './index.module.scss';

const copies = copiesPrefix.resetPassword;

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [isError, setError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [sendForgotEmail, setSendForgotEmail] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const adrPattern = /[0-9a-z_-]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
    const expiredToken = useSelector(userActionSelectors.expiredToken);
    
    const handleEmailChange = e => {
        if (email.length > 0) {
            setError(false);
        }
        setEmail(e.target.value);
    };
    
    const handleForgotPassword = useCallback(
        (e, sendAgain) => {
            setLoader(true);
            setError(false);
            if (email === '' || adrPattern.test(email) === false) {
                setLoader(false);
                setError(true);
                e.preventDefault();
                e.stopPropagation();
            } else {
                setError(false);
                const platform = PLATFORM_TYPE.MAP_MANAGER;
                const data = JSON.stringify({ email, platform });
                dispatch(userActions.forgotPassword(data)).then(res => {
                    if (res) {
                        if (sendAgain) {
                            dispatch(
                                userActions.setMessageRequest({
                                    text: 'Sent you an email again',
                                    status: 'success',
                                })
                            );
                        }
                        dispatch(userActionActions.handleExpiredForgotToken(false));
                        setSendForgotEmail(true);
                        setLoader(false);
                    }
                });
            }
        },
        [email]
    );
    
    const renderTitle = () => {
        if (sendForgotEmail) return copies.checkEmail.title;
        if (expiredToken) return copies.expired_title;
        return copies.title;
    };
    
    return (
        <div className={styles.forgotPasswordBlock}>
            <div className="absolute top-4 left-4 md:top-10 md:left-10">
                <Logo/>
            </div>
            <div className={styles.textCenter}>
                <div className={styles.titleText}>{renderTitle()}</div>
                {!sendForgotEmail ? (
                    <>
                        <div className={styles.forgotPasswordText}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: copies.description,
                                }}
                            />
                        </div>
                        <div className={styles.forgotEmailBlock}>
                            <LoginInput
                                onClick={() => setError(false)}
                                type='email'
                                setState={setEmail}
                                onChange={handleEmailChange}
                                value={email}
                                errorText={copies.email_input_error}
                                error={isError}
                                placeholder={copies.email_input_placeholder}
                                onKeyDown={null}
                            />
                        </div>
                        <div className={styles.loginBtnCont}>
                            <CustomButton
                                size={'largeSize'}
                                onClick={e => handleForgotPassword(e, false)}
                                type={ButtonType.PRIMARY_BLUE}
                                text={copies.reset_button_text}
                                disabled={email === '' || adrPattern.test(email) === false || loader}
                                loader={loader}
                            />
                        </div>
                    </>
                ) : (
                    <div className={styles.forgotPasswordText}>
                        <p>{copies.checkEmail.description}</p>
                        <p style={{ marginTop: '10px' }}>
                            {copies.checkEmail.didnt_get_email}{' '}
                            <span
                                onClick={e => {
                                    handleForgotPassword(e, true);
                                }}
                                className={styles.sendAgain}
                            >
                                {copies.checkEmail.send_again_button}
                            </span>
                        </p>
                    </div>
                )}
                <div className={styles.backBlock}>
                    <CustomButton size={'largeSize'} onClick={() => navigate(ROUTES.LOGIN)} type={ButtonType.TERTIARY_BLUE} text={copies.back_button_text}/>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
