import { buttonCta } from "../contants"

const slidesData = [
    {
        title: 'Announcements',
        description: 'Announcements stay pinned to the top of your map’s feed, and map members receive a push notification about them.',
        backgroundColor: '#EBE9F7',
        imageSrc: '/assets/img/mapStagesUnlockAnnouncemnts.png',
        cta: buttonCta.ANNOUNCEMENT,
    },
    {
        title: 'Real-time insights',
        description: 'Get stats about your map’s performance and community engagement, like number of new members, places and more.',
        backgroundColor: '#FFE5E2',
        imageSrc: '/assets/img/mapStagesUnlockInsights.png',
        imageStyle: { marginTop: '12px' },
        titleStyle: { marginTop: '-18px' },
        cta: buttonCta.INSIGHTS,
    },
    {
        title: 'More exposure',
        description: 'Advanced Level maps appear in more search results, increasing exposure & helping your community grow faster.',
        backgroundColor: '#FFF1DE',
        imageSrc: '/assets/img/mapStagesUnlockSearch.png',
    },

]

export default slidesData