import { success } from '@redux-requests/core';
import { ADD_ANNOUNCEMENT, GET_FEED_POSTS } from '../feed/constants';
import {
    GET_FULL_STEP_DATA,
    GET_STEP_BY_ID,
    GET_POSTS,
    GET_POST,
    CLAP_POST,
    ADD_COMMENT,
    PIN_POST,
    ADD_REPLY,
    EDIT_COMMENT,
    EDIT_REPLY,
    DELETE_REPLY,
    DELETE_COMMENT,
    CLAP_REPLY,
    CLAP_COMMENT,
    GET_POST_COMMENTS,
    GET_CLAPS,
    DELETE_POST,
    EDIT_POST,
    OPEN_MORE_REPLIES,
    CLEAR_COMMENTS_CACHE,
    EDIT_TAGS,
    DELETE_IMAGE,
} from './types';
import { CREATE_POST } from '../map/types';
import { Post, Comment } from '../../models';
import { ImagesSize } from '../../constants';
import { get, uniqBy, orderBy } from 'lodash';
import { PostTypes } from '../../constants';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';

const POST_IMAGE_WIDTH = 500;
const POST_IMAGE_HEIGHT = 325;

const initalState = {};

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case success(GET_FULL_STEP_DATA): {
            const steps = get(action, 'response.data.steps', []);
            const posts = get(action, 'response.data.posts_list.posts', []);

            const normalized = {
                ...state,
            };

            steps.forEach(step => {
                normalized[step._id] = new Post(step, POST_IMAGE_WIDTH, POST_IMAGE_HEIGHT);
            });

            posts.forEach(post => {
                normalized[post._id] = new Post(post, POST_IMAGE_WIDTH, POST_IMAGE_HEIGHT);
            });

            return normalized;
        }

        case success(GET_STEP_BY_ID): {
            const posts = get(action, 'response.data.posts_list.posts', []);
            const normalized = {
                ...state,
            };

            posts.forEach(post => {
                normalized[post._id] = new Post(post, POST_IMAGE_WIDTH, POST_IMAGE_HEIGHT);
            });

            return normalized;
        }

        case success(GET_FEED_POSTS):
        case success(GET_POSTS): {
            const posts = get(action, 'response.data.posts', []);
            const normalized = {
                ...state,
            };

            posts.forEach(post => {
                normalized[post._id] = new Post(post, POST_IMAGE_WIDTH, POST_IMAGE_HEIGHT);
            });

            return normalized;
        }

        case success(GET_POST): {
            const { post, comments_list } = action.response?.data;

            if (!post) {
                return state;
            }

            //get old cached comments, and add to them
            const newPost = new Post(post, POST_IMAGE_WIDTH, POST_IMAGE_HEIGHT, comments_list);
            const oldComments = state[post?._id]?.comments || [];
            const newComments = newPost?.comments || [];

            const joinedComments = uniqBy([...newComments, ...oldComments], 'id');
            newPost.comments = orderBy(joinedComments, 'createdAt', 'desc');

            return {
                ...state,
                [post?._id]: newPost,
            };
        }

        case success(OPEN_MORE_REPLIES): {
            const { repliesOpened, commentId, postId } = action.meta;
            const { posts, cursor } = action.response.data;

            const currentComment = state[postId].comments.find(comment => comment.id === commentId);

            currentComment.repliesOpened = repliesOpened;
            currentComment.repliesCursor = cursor;
            const newReplies = posts.map(reply => new Comment(reply));
            currentComment.replies = [...currentComment.replies, ...newReplies];

            const updatedComments = state[postId].comments.map(comment => (comment.id === commentId ? currentComment : comment));

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                },
            };
        }
        case CLEAR_COMMENTS_CACHE: {
            const { postId } = action.payload;

            const comments = state[postId].comments;
            if (!comments.length) return state;

            const [lastComment] = comments;
            const newComments = [lastComment];

            const newPost = { ...state[postId], comments: newComments, repliesCursor: null };

            return {
                ...state,
                [postId]: newPost,
            };
        }
        case PIN_POST: {
            const { newPinStatus, postId } = action.meta;

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    is_pinned: newPinStatus,
                },
            };
        }

        case DELETE_IMAGE: {
            const { imageId, postId } = action.meta;
            if (!state[postId])
                return {
                    ...state,
                };
            let attachments = state[postId].attachmentsList;
            attachments = attachments.filter(attachment => attachment.attachment.id != imageId);
            if (!attachments?.length && !state[postId]?.text_array?.length) {
                const newState = { ...state };
                delete newState[postId];
                return newState;
            }
            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    attachmentsList: attachments,
                },
            };
        }

        case CLAP_POST: {
            // For immidiate clap
            const { newClapStatus, postId } = action.meta;
            const oldClapCount = state[postId]?.clapsCount;
            const currentClapsCount = newClapStatus ? oldClapCount + 1 : oldClapCount - 1;

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    isClapped: !!newClapStatus,
                    clapsCount: currentClapsCount,
                },
            };
        }
        case CLAP_COMMENT: {
            const { postId, newClapStatus, commentId } = action.meta;

            const updatedComments = state[postId].comments.map(comment => {
                if (comment.id === commentId) {
                    const oldClapCount = comment.clapsCount;
                    const newClapsCount = newClapStatus ? oldClapCount + 1 : oldClapCount - 1;
                    return { ...comment, isClapped: !!newClapStatus, clapsCount: newClapsCount };
                }
                return comment;
            });

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                },
            };
        }

        case success(CLAP_REPLY): {
            const { replyId, commentId, newClapStatus, postId } = action.meta;

            const comment = state[postId].comments.find(comment => comment.id === commentId);
            const updatedReplies = comment.replies.map(reply => {
                if (reply.id === replyId) {
                    const oldClapCount = reply.clapsCount;
                    const newClapsCount = newClapStatus ? oldClapCount + 1 : oldClapCount - 1;
                    reply.isClapped = !!newClapStatus;
                    reply.clapsCount = newClapsCount;
                }
                return reply;
            });

            const updatedComments = state[postId].comments.map(comment => (comment.id === commentId ? { ...comment, replies: updatedReplies } : comment));

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                },
            };
        }

        case success(GET_POST_COMMENTS): {
            const { postId } = action.meta;

            const oldComments = state[postId].comments;
            const newComments = action.response?.data?.posts.map(comment => new Comment(comment));

            const joinedComments = uniqBy([...newComments, ...oldComments], 'id');
            const updatedComments = orderBy(joinedComments, 'createdAt', 'desc');

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    commentsCursor: action.response?.data?.cursor,
                    comments: updatedComments,
                },
            };
        }

        case ADD_COMMENT: {
            const { postId, userName, userImageId, createdAt, replaceCurrent } = action.meta;
            const comment = action.request.data;

            const newComment = new Comment({ ...comment, userName, userImageId, createdAt, attachments: [], temp: true });
            const newComments = replaceCurrent ? [newComment] : [newComment, ...state[postId].comments];

            for (const textObj of comment.text_array) {
                if (textObj.text_type != 'text')
                    mixpanel.track(mixpanelEvents.POST_COMMENT_TAGGED, {
                        [mixpanelProperties.TAG_TYPE]: textObj.text_type,
                        [mixpanelProperties.TAG]: textObj.text,
                        [mixpanelProperties.TAG_ID]: textObj.user_id || textObj.place_id || textObj.step_id,
                        [mixpanelProperties.PARENT_POST_ID]: comment.parent_post_id,
                    });
            }

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    totalComments: state[postId].totalComments + 1,
                    comments: newComments,
                },
            };
        }

        case success(ADD_COMMENT): {
            const { postId, createdAt, userName } = action.meta;
            const { post } = action.response?.data;

            // deleting the temp comment
            const commentsWithoutTemp = state[postId].comments.filter(comment => !(comment.createdAt === createdAt && comment.userName === userName));
            const comments = [new Comment(post), ...commentsWithoutTemp];

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments,
                },
            };
        }

        case ADD_REPLY: {
            const { postId, commentId, userName, userImageId, createdAt } = action.meta;
            const reply = action.request.data;
            const postComments = state[postId].comments;

            const replies = postComments.find(comment => comment.id === commentId).replies;
            const newReplies = [...replies, new Comment({ ...reply, userName, userImageId, createdAt, attachments: [], temp: true })];

            const updatedComments = postComments.map(comment => (comment.id === commentId ? { ...comment, replies: newReplies } : comment));
            for (const textObj of reply.text_array) {
                if (textObj.text_type != 'text')
                    mixpanel.track(mixpanelEvents.POST_COMMENT_TAGGED, {
                        [mixpanelProperties.TAG_TYPE]: textObj.text_type,
                        [mixpanelProperties.TAG]: textObj.text,
                        [mixpanelProperties.TAG_ID]: textObj.user_id || textObj.place_id || textObj.step_id,
                        [mixpanelProperties.PARENT_POST_ID]: postId,
                    });
            }
            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                },
            };
        }

        case success(ADD_REPLY): {
            const { postId, commentId, userName, createdAt } = action.meta;
            const postComments = state[postId].comments;
            const replies = postComments.find(comment => comment.id === commentId).replies;

            //deleting the temp reply
            const commentsWithoutTemp = replies.filter(reply => !(reply.createdAt === createdAt && reply.userName === userName));
            const newReplies = [...commentsWithoutTemp, new Comment(action.response?.data?.post)];
            const updatedComments = postComments.map(comment => (comment.id === commentId ? { ...comment, replies: newReplies } : comment));

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                },
            };
        }

        case success(EDIT_COMMENT): {
            const { postId, commentId } = action.meta;
            const post = state[postId];
            const newCommentTextArray = action.response?.data?.post.text_array;

            return {
                ...state,
                [postId]: {
                    ...post,
                    comments: post.comments.map(comment => {
                        if (comment.id === commentId) {
                            return {
                                ...comment,
                                textArray: newCommentTextArray,
                            };
                        }
                        return comment;
                    }),
                },
            };
        }

        case success(EDIT_REPLY): {
            const { replyId, commentId, postId } = action.meta;
            const postComments = state[postId].comments;
            const replies = postComments.find(comment => comment.id === commentId).replies;
            const newReplies = replies.map(reply => (reply.id === replyId ? new Comment(action.response.data.post) : reply));
            const updatedComments = postComments.map(comment => (comment.id === commentId ? { ...comment, replies: newReplies } : comment));

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                },
            };
        }

        case success(DELETE_COMMENT): {
            const { postId, commentId } = action.meta;
            const postComments = state[postId].comments;
            const updatedComments = postComments.filter(comment => comment.id !== commentId);

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                    totalComments: state[postId].totalComments - 1,
                },
            };
        }

        case success(DELETE_REPLY): {
            const { commentId, replyId, postId } = action.meta;
            //const { id: postId } = action?.response?.data?.post.screen_chain.find(chain => chain.type === 'POST');

            const postComments = state[postId].comments;
            const replies = postComments.find(comment => comment.id === commentId).replies;
            const newReplies = replies.filter(reply => reply.id !== replyId);

            const updatedComments = postComments.map(comment => (comment.id === commentId ? { ...comment, replies: newReplies } : comment));

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    comments: updatedComments,
                },
            };
        }

        case ADD_ANNOUNCEMENT: {
            const { announcement } = action.payload;
            const previousAnnouncement = Object.values(state).filter(post => post.type === PostTypes.ANNOUNCEMENT && post.isPinned)[0];

            return {
                ...state,
                [previousAnnouncement?.id]: {
                    ...state[previousAnnouncement?.id],
                    isPinned: false,
                },
                [announcement._id]: new Post(announcement),
            };
        }
        case success(GET_CLAPS): {
            const { postId } = action.meta;

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    claps: action.response.data.claps,
                },
            };
        }

        case success(CREATE_POST): {
            const post = action.response?.data?.post;
            return {
                ...state,
                [post._id]: new Post(post, ImagesSize.POST.width, ImagesSize.POST.height),
            };
        }

        case DELETE_POST: {
            const { postId } = action.meta;

            const newState = { ...state };
            delete newState[postId];

            return newState;
        }

        case success(EDIT_TAGS): {
            const { postId, textArray } = action.meta;

            return {
                ...state,
                [postId]: {
                    ...state[postId],
                    text_array: textArray,
                    textArray,
                },
            };
        }

        case success(EDIT_POST): {
            const { postId } = action.meta;
            const currentPost = state[postId];
            const newPost = new Post(action.response?.data?.post, ImagesSize.POST.width, ImagesSize.POST.height);

            return {
                ...state,
                [postId]: {
                    ...currentPost,
                    body: newPost.body,
                    attachments: newPost.attachments,
                    fullSizeImages: newPost.fullSizeImages,
                    imagesId: newPost.imagesId,
                    postImage: newPost.postImage,
                    type: newPost.type,
                },
            };
        }

        default:
            return state;
    }
};

export default reducer;
