import React from 'react';

const IconShare = () => (
    <svg width='21' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
            d='M15.167 6.86c1.288 0 2.333-1.085 2.333-2.424 0-1.338-1.045-2.423-2.333-2.423-1.289 0-2.334 1.085-2.334 2.423 0 1.339 1.045 2.423 2.334 2.423zM5.833 12.513c1.289 0 2.334-1.085 2.334-2.423S7.122 7.667 5.833 7.667C4.545 7.667 3.5 8.752 3.5 10.09s1.045 2.423 2.333 2.423zM15.167 18.167c1.288 0 2.333-1.085 2.333-2.423s-1.045-2.423-2.333-2.423c-1.289 0-2.334 1.085-2.334 2.423s1.045 2.423 2.334 2.423zM7.848 11.31l5.312 3.214M13.152 5.656 7.848 8.871'
            stroke='#fff'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
		/>
	</svg>
);

export default IconShare;
