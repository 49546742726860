export const step = state => state.step.step;
export const title = state => state.step.title;
export const likesCount = state => state.step.likesCount;
export const postsCount = state => state.step.postsCount;
export const viewsCount = state => state.step.viewsCount;
export const address = state => state.step.hpaw?.address;
export const hours = state => state.step.hpaw?.hours;
export const phone = state => state.step.hpaw?.phone;
export const website = state => state.step.hpaw?.website;
export const tags = state => state.step.tags;
export const ratings = state => state.step.ratings;
export const tagsPreview = state => state.step.tagsPreview;
export const ratingsPreview = state => state.step.ratingsPreview;
export const boundsSteps = state => state.step.boundsSteps;
export const filteredSteps = state => state.step.filteredSteps;
export const amount = state => state.step.amount;
export const restoreId = state => state.step.restoreId;
export const archivedId = state => state.step.archivedId;
export const statusSteps = state => state.step.statusSteps;
export const changeLocations = state => state.step.changeLocations;
export const changeLocationId = state => state.step.changeLocationId;
export const changeLocationShow = state => state.step.changeLocationShow;
export const stepPendingCount = state => state.step.stepPendingCount;
