import { useContext, useMemo } from 'react';
import classNames from 'classnames';
import * as timeago from 'timeago.js';
import { capitalize } from 'lodash';
import styles from './PostSecondaryHeadline.module.scss';
import { UserTypes } from '../PostHeadline';
import { UserTagPopover } from '../../common';
import { PopupContext } from '../../../context';

const localeFunc = (_number, index) => {
    return [
        ['now', 'now'],
        ['%sm', '%sm'],
        ['1m', '1m'],
        ['%sm', '%sm'],
        ['1h', '1h'],
        ['%sh', '%sh'],
        ['1d', '1d'],
        ['%sd', '%sd'],
        ['1w', '1w'],
        ['%sw', '%sw'],
        ['1m', '1m'],
        ['%sm', '%sm'],
        ['1y', '1y'],
        ['%sy', '%sy'],
    ][index];
};

timeago.register('short-locale', localeFunc);

const PostSecondaryHeadline = ({ userName, userType, createdAt, badge = null, isPinned = false, shouldShowUsername = true, userId, postId, isScrolling }) => {
    const { activePopupId, setActivePopupId } = useContext(PopupContext);

    const timeAgo = useMemo(() => {
        try {
            return timeago.format(createdAt, 'short-locale');
        } catch (error) {
            console.error(error);
            return '';
        }
    }, [createdAt]);

    // TODO: confusing function
    const renderUsernameOrBadge = () => {
        if (badge) {
            return badge;
        }

        if (!shouldShowUsername) {
            return;
        }

        if (!userName) {
            return '';
        }

        return (
            <UserTagPopover isActive={activePopupId === postId && userName !== 'Steps Bot'} userId={userId} key={postId} isScrolling={isScrolling}>
                <span
                    className={styles.username}
                    onClick={e => {
                        e.stopPropagation();
                        setActivePopupId(postId);
                    }}
                >
                    {userName} •
                </span>
            </UserTagPopover>
        );
    };

    const renderUserType = () => {
        if (!userType || userType !== UserTypes.ADMIN) return '';
        return <span> {capitalize(userType)} •</span>;
    };

    return (
        <div className={classNames('full-width flex center-items', styles.userInfo)}>
            {renderUsernameOrBadge()}
            {renderUserType()}
            {createdAt ? <span>{timeAgo}</span> : ''}
            {isPinned ? <span>• Pinned</span> : ''}
        </div>
    );
};

export default PostSecondaryHeadline;
