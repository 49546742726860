import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { modalsActions, modalsSelectors } from '../../redux/modals';
import { postActions } from '../../redux/post';
import { userSelectors } from '../../redux/user';
import InfiniteScroll from 'react-infinite-scroll-component';
import StepProfile from '../../components/StepProfile';
import ArchivedStepPopup from '../../components/ArchivedStepPopup';
import { mapActions, mapSelectors } from '../../redux/map';
import { userActions } from '../../redux/user';
import { REQUEST_NOTIFICATIONS } from '../../config/constants';
import RequestMessage from '../../components/common/RequestMessage';
import classNames from 'classnames';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { stepActions } from '../../redux/step';
import SuggestionsTags from '../../components/SuggestionsTags';
import ModalsSelectTags from '../../components/common/modals/ModalsSelectTags';
import ModalsChangeLocation from '../../components/common/modals/ModalsChangeLocation';
import ImagePreview from '../../components/common/ImagePreview';
import { REMOVED_UPDATE, ToastContext, ToastDurations, UNDO } from '../../context/ToastContext';
import LoadingAnimation from './components/LoadingAnimation';
import { isUserSeenWelcomeMessage, setIsUserSeenWelcomeMessage } from '../../components/WelcomeMessage/welcomeMessageService';
import copiesPrefix from '../../copies.json';
import WelcomeMessage, { useWelcomeMessage } from '../../components/WelcomeMessage';
import { PostPage } from '../../components/PostStructure/PostPage';
import { getRecentActivities, handleRecentActivitie, arrToObj, mapTagObjects } from '../../helpers';
import { PostTypes, StepStatuses } from '../../constants';
import {
    StepClosedCard,
    ReportStepCard,
    ReportPostCard,
    PendingStepCard,
    JoinCard,
    SuggestTagsCard,
    RatedCard,
    UserPostedCard,
    PendingPin,
} from './components';
import { CardsTypes, Outcomes } from './constants';
import { Filters } from './components/Filters';
import { CreateNewPostModal } from '../../components/Feed/FeedPost';
import { FormattedMessage } from "react-intl";

export const activityTypes = {
    [CardsTypes.STEP_DISCOVERED]: 'Added step',
    [CardsTypes.USER_POSTED]: 'USER_POSTED',
    [CardsTypes.USER_SUGGESTED_STEP]: 'Pending steps',
    [CardsTypes.USER_JOINED_MAP]: 'Joined',
    [CardsTypes.USER_RATED]: 'Rated',
    [CardsTypes.TAGS_EDITED]: 'Edited tags',
    [CardsTypes.USER_SUGGESTED_TAGS]: 'Pending tags',
    [CardsTypes.REPORT_STEP]: 'Reported step',
    [CardsTypes.REPORT_POST]: 'Reported post',
    [CardsTypes.BUSINESS_STATUS_CHANGED]: 'Info changes',
};

const ActivityOutcomes = {
    WELCOME: 'welcome',
};

const copies = copiesPrefix.recentActivities;

let scrollTimeoutId = null;

const RecentActivities = () => {
    const dispatch = useDispatch();
    const { body: welcomeMessageBody } = useWelcomeMessage();
    const { setToast } = useContext(ToastContext);
    const [isUnhandledOnly, setIsUnhandledOnly] = useState(true);
    const [filteredActivityTypes, setFilteredActivityTypes] = useState([]);
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const user = useSelector(userSelectors.user);
    const [stepActivitySlider, setStepActivitySlider] = useState(null);
    const [activitiesList, setActivitiesList] = useState({
        activities: [],
        cursor: null,
        total: 0,
    });
    const [archivePopupData, setArchivePopup] = useState({ open: false });
    const [replyPopupData, setReplyPopup] = useState({ open: false });
    const [dataRequest, setDataRequest] = useState({});
    const [loader, setLoader] = useState(true);
    const [suggestionsTags, setSuggestionsTags] = useState(null);
    const [deletedPost, setDeletePost] = useState(false);
    const [isSelectTagsModalOpen, setIsSelectTagsModalOpen] = useState({
        show: false,
        disableDone: true,
    });
    const tag_objects = useSelector(mapSelectors.tag_objects(mapId));
    let tagsObject = useMemo(() => {
        if (Object.values(tag_objects).length > 0) {
            return mapTagObjects(tag_objects, map?.tags_categories);
        }
    }, [tag_objects, map]);
    const [selectedModalTags, setSelectedModalTags] = useState({
        tags: [],
        stepId: null,
    });
    const [showOnMap, setShowOnMap] = useState({ show: false, data: null });
    const [openPost, setOpenPost] = useState(null);
    const [removedUpdatesIds, setRemovedUpdatedIds] = useState([]);
    const [tempHandledActivities, setTempHandledActivities] = useState(0);
    const mapUnhandledActivities = useSelector(mapSelectors.unhandledActivities(mapId));
    const unhandledActivities = mapUnhandledActivities - (tempHandledActivities + removedUpdatesIds.length);
    const [isWelcomeMessageSettingsSliderOpen, setIsWelcomeMessageSettingsSliderOpen] = useState(false);
    const imagePreviewShow = useSelector(modalsSelectors.imagePreviewShow);
    const imagePreviewArray = useSelector(modalsSelectors.imagePreviewArray);
    const imagePreviewIndex = useSelector(modalsSelectors.imagePreviewIndex);
    const activitiesArray = Object.values(activitiesList.activities);
    const activitiesLength =
        filteredActivityTypes.length > 0 ? activitiesArray.filter(activity => filteredActivityTypes.includes(activity.type)).length : activitiesArray.length;
    // refs for accessing states in cleanup function
    const activitiesListRef = useRef();
    const handledCardTempRef = useRef();
    const [postPageIdToOpen, setPostPageIdToOpen] = useState('');
    
    const [isScrolling, setIsScrolling] = useState(false);
    const scrollRef = useRef();
    
    useEffect(() => {
        activitiesListRef.current = activitiesList;
    }, [activitiesList]);
    
    useEffect(() => {
        handledCardTempRef.current = tempHandledActivities;
    }, [tempHandledActivities]);
    
    useEffect(() => {
        return () => {
            const handleCardTempCount = handledCardTempRef.current;
            mixpanel.track(mixpanelEvents.UPDATES_CLOSE, {
                [mixpanelProperties.NUM_UNHANDLED_ACTIVITIES]: activitiesListRef.current.totalUnhandled - handleCardTempCount,
                [mixpanelProperties.NUM_HANDLED_ACTIVITIES]: handleCardTempCount,
            });
        };
    }, []);
    
    useEffect(() => {
        async function fetchActivities() {
            if (!mapId) return;
            setLoader(true);
            const response = await getRecentActivities(mapId, {
                cursor: null,
                isOnlyUnhandled: isUnhandledOnly,
                filteredTypes: filteredActivityTypes,
            });
            setActivitiesList({
                activities: arrToObj(response.recent_activities, '_id'),
                cursor: response.cursor,
                total: response.total_results,
                totalUnhandled: response.total_unhandled || null,
            });
            mixpanel.track(mixpanelEvents.UPDATES_OPEN, {
                [mixpanelProperties.NUM_UNHANDLED_ACTIVITIES]: response?.total_unhandled,
                [mixpanelProperties.IS_NUMBER_INDICATION]: String(map?.new_recent_activities_count) > 0,
            });
            localStorage.setItem('unhandled', response?.total_unhandled);
            dispatch(mapActions.resetActivitiesCount(mapId));
            setLoader(false);
            setStepActivitySlider(null);
        }
        
        fetchActivities();
    }, [mapId, isUnhandledOnly, filteredActivityTypes, map?.new_recent_activities_count, dispatch]);
    
    const toggleImagePreview = ({ imagePreviewShow, imagePreviewArray, imagePreviewIndex }) => {
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: imagePreviewShow,
                imagePreviewArray: imagePreviewArray,
                imagePreviewIndex: imagePreviewIndex,
            })
        );
    };
    
    const loadNextPage = async () => {
        const response = await getRecentActivities(mapId, {
            cursor: activitiesList.cursor,
            isOnlyUnhandled: !isUnhandledOnly,
            filteredTypes: filteredActivityTypes,
        });
        setActivitiesList({
            ...activitiesList,
            activities: {
                ...activitiesList.activities,
                ...arrToObj(response.recent_activities, '_id'),
            },
            cursor: response.cursor,
            total: response.total_results,
        });
    };
    
    const handleArchive = async (value, activityId) => {
        if (value) {
            const newList = { ...activitiesList };
            setDeletePost(true);
            if (!newList.activities[activityId].handled) {
                await handleRecentActivitie(mapId, activityId, 'delete_activity');
            }
            newList.activities[activityId].handled = true;
            newList.activities[activityId].outcome = 'delete_activity';
            setActivitiesList(newList);
            setDeletePost(false);
        }
    };
    
    const handleRemoveUpdate = updateId => {
        setRemovedUpdatedIds(state => [...state, updateId]);
        handleRecentActivitie(mapId, updateId, 'delete_activity');
        setToast({
            text: REMOVED_UPDATE,
            timeout: ToastDurations.LONG,
            cta: {
                text: UNDO,
                onClick: () => {
                    setToast(false);
                    setRemovedUpdatedIds(state => state.filter(removedUpdateId => removedUpdateId !== updateId));
                    handleRecentActivitie(mapId, updateId, 'undo_deletion');
                },
            },
        });
    };
    
    const handleClose = useCallback(() => {
        if (!deletedPost) {
            setArchivePopup({ open: false });
        }
    }, [deletedPost]);
    
    const changeSelectedTags = useCallback(
        (item = null, tagsToDelete = [], tagsToAdd = []) => {
            dispatch(mapActions.bulkAddTags(map?._id, [selectedModalTags?.stepId], tagsToAdd, tagsToDelete, item));
        },
        [map?._id, selectedModalTags?.stepId]
    );
    
    const handleFilterClick = activityType => {
        if (filteredActivityTypes.includes(activityType)) {
            setFilteredActivityTypes(state => state.filter(type => type !== activityType));
        } else {
            setFilteredActivityTypes(state => [...state, activityType]);
        }
    };
    
    const handleActivityClick = async (outcome, activity) => {
        setTempHandledActivities(prevCount => prevCount + 1);
        const response = await dispatch(mapActions.handleActivity(activity._id, outcome));
        
        const newList = { ...activitiesList };
        newList.activities[activity._id] = {
            ...newList.activities[activity._id],
            ...response.data?.recent_activity,
        };
        setActivitiesList(newList);
        
        return response;
    };
    
    const sendWelcome = async (activity, outcome) => {
        const { type, step_id, text_array, _id, full_post, user_preview, type_specific_info } = activity;
        
        mixpanel.track(mixpanelEvents.SERVER_UPDATED_HANDLED, {
            [mixpanelProperties.UPDATE_TYPE]: 'send welcome',
        });
        mixpanel.track(mixpanelEvents.WELCOME_MESSAGE_MAP_UPDATES_SENT, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: map?.name,
        });
        
        if (outcome === 'approve' && type === 'USER_SUGGESTED_STEP') {
            setToast({
                text: copies.recentActivities?.added,
            });
        }
        
        if (outcome === 'delete') {
            let isLastPost = false;
            dispatch(stepActions.getStep(step_id)).then(({ data }) => {
                if (data) {
                    if (data.posts_list.total_results === 1) {
                        isLastPost = true;
                    }
                }
                setArchivePopup({
                    open: true,
                    stepId: text_array.find(item => item?.text_type === 'step_tag')?.step_id,
                    stepTitle: text_array.find(item => item?.text_type === 'step_tag')?.text,
                    activityId: _id,
                    isDeletePost: type === 'REPORT_POST',
                    lastPost: isLastPost,
                });
            });
        } else if (outcome === 'reply') {
            setReplyPopup({
                open: true,
                postStepId: text_array.find(item => item?.text_type === 'step_tag')?.step_id,
                parentPostId: type_specific_info?.post_id,
                answerToUser: user_preview,
            });
        } else if (outcome === 'clap') {
            handleRecentActivitie(mapId, _id, outcome);
            const newList = { ...activitiesList };
            newList.activities[_id].handled = true;
            newList.activities[_id].outcome = outcome;
            newList.activities[_id].full_post.is_clapped_by_user = !full_post?.is_clapped_by_user;
            setActivitiesList(newList);
        } else {
            const data = await handleRecentActivitie(mapId, _id, outcome);
            const newList = { ...activitiesList };
            newList.activities[_id] = {
                ...newList.activities[_id],
                ...data.recent_activity,
            };
            setActivitiesList(newList);
        }
    };
    
    const stepId =
        stepActivitySlider?.full_post?.step_id ||
        stepActivitySlider?.text_array?.find(item => item?.text_type === 'step_tag')?.step_id ||
        stepActivitySlider?.step_id ||
        stepActivitySlider?.type_specific_info?.step_id;
    
    const trackOpenWelcomeMessageSettings = activityId => {
        mixpanel.track(mixpanelEvents.WELCOME_MESSAGE_MAP_UPDATES_EDIT_CLICK, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: map?.name,
            [mixpanelProperties.UPDATE_ID]: activityId,
        });
    };
    
    const isActivityValid = activity => {
        const isStepDeactivated = activity.full_post && activity.full_post.step_deactivation;
        const isPostActive = activity.full_post ? activity.full_post.active : true;
        const isActivityFiltered =
            (!filteredActivityTypes.length || filteredActivityTypes.includes(activity.type)) && !removedUpdatesIds.includes(activity._id);
        
        const isActivityValid = !isActivityFiltered || isStepDeactivated || !isPostActive;
        return isActivityValid;
    };
    
    const renderCards = () => {
        return Object.values(activitiesList.activities).map(activity => {
            if (isActivityValid(activity)) {
                return null;
            }
            
            const props = {
                isOpenActivitiesView: isUnhandledOnly,
                createdAt: activity.created_at,
                textArray: activity.text_array,
                avatarImgId: activity.thumbnail_image?.image_id || activity.user_preview?.image,
                outcome: activity.outcome,
                handlerManager: activity.handler_manager,
                managerPreview: activity.manager_preview,
                userPreview: activity.user_preview,
                isHandled: activity.handled,
                message: activity.type_specific_info?.report_message,
                onViewOnMapClick: () => setShowOnMap({ show: true, data: activity }),
                onDisregard: () => handleRemoveUpdate(activity._id),
                onCardClick: isTag => {
                    if (isTag) {
                        setStepActivitySlider(activity);
                    }
                },
                onTagClick: () => {
                    setStepActivitySlider(activity);
                },
            };
            
            const isOperational = activity.type_specific_info?.business_status === StepStatuses.OPERATIONAL;
            
            switch (activity.type) {
                case CardsTypes.STEP_DISCOVERED:
                    return (
                        <UserPostedCard
                            {...props}
                            type='step'
                            postBody={activity.full_post?.text_array}
                            postAttachments={activity.full_post?.attachments}
                            onConfirm={() => handleActivityClick(Outcomes.DISREGARD, activity)}
                            onRemove={() => handleActivityClick(Outcomes.DELETE, activity)}
                        />
                    );
                
                case CardsTypes.USER_POSTED:
                    return (
                        <UserPostedCard
                            {...props}
                            type='post'
                            post={activity.full_post}
                            postBody={activity.full_post?.text_array}
                            postAttachments={activity.full_post?.attachments}
                            isClapped={activity.full_post?.is_clapped_by_user}
                            isReplied={activity.type_specific_info?.is_replied}
                            onClap={async callback => {
                                await handleActivityClick(Outcomes.CLAP, activity);
                                callback();
                            }}
                            onReply={async callback => {
                                await dispatch(
                                    userActions.setMessageRequest({
                                        status: 'success',
                                        text: REQUEST_NOTIFICATIONS.REPLY_SUCCESS,
                                        img: REQUEST_NOTIFICATIONS.IMG_SUCCESS,
                                    })
                                );
                                
                                await handleActivityClick(Outcomes.REPLY, activity);
                                callback();
                            }}
                        />
                    );
                
                case CardsTypes.USER_JOINED_MAP:
                    return (
                        <JoinCard
                            {...props}
                            isAutoMessage={activity.type_specific_info?.automatic_welcome}
                            onEdit={() => {
                                trackOpenWelcomeMessageSettings(activity._id);
                                setStepActivitySlider(null);
                                setIsWelcomeMessageSettingsSliderOpen(true);
                            }}
                            onWelcome={() => {
                                if (!isUserSeenWelcomeMessage(user.id)) {
                                    setIsUserSeenWelcomeMessage(user.id);
                                    return;
                                }
                                mixpanel.track(mixpanelEvents.WELCOME_MESSAGE_SEND_AUTOMATICALLY, {
                                    [mixpanelProperties.MAP_ID]: mapId,
                                    [mixpanelProperties.MAP_NAME]: map?.name,
                                    [mixpanelProperties.MESSAGE_TEXT]: welcomeMessageBody,
                                    [mixpanelProperties.IS_CUSTOM]: !!welcomeMessageBody,
                                });
                                sendWelcome(activity, ActivityOutcomes.WELCOME);
                            }}
                        />
                    );
                
                case CardsTypes.USER_RATED:
                    return (
                        <RatedCard
                            {...props}
                            onSendThanks={() => {
                                handleActivityClick(Outcomes.THANKS, activity);
                            }}
                        />
                    );
                
                case CardsTypes.USER_SUGGESTED_TAGS:
                    return (
                        <SuggestTagsCard
                            {...props}
                            type='suggest'
                            tagsToAdd={activity.type_specific_info?.tags_to_add}
                            tagsToRemove={activity.type_specific_info?.tags_to_remove}
                            onDecline={() => {
                                handleActivityClick(Outcomes.DECLINE, activity);
                            }}
                            onApprove={() => {
                                handleActivityClick(Outcomes.APPROVE, activity);
                            }}
                        />
                    );
                
                case CardsTypes.TAGS_EDITED:
                    return (
                        <SuggestTagsCard
                            {...props}
                            type='edit'
                            tagsToAdd={activity.type_specific_info?.tags_added}
                            tagsToRemove={activity.type_specific_info?.tags_removed}
                            onDecline={() => {
                                handleActivityClick(Outcomes.DELETE, activity);
                            }}
                            onApprove={() => {
                                handleActivityClick(Outcomes.IGNORE, activity);
                            }}
                        />
                    );
                
                case CardsTypes.USER_SUGGESTED_STEP:
                    return (
                        <PendingStepCard
                            {...props}
                            onDecline={() => {
                                handleActivityClick(Outcomes.DECLINE, activity);
                            }}
                            onAddToMap={() => {
                                handleActivityClick(Outcomes.APPROVE, activity);
                            }}
                            postBody={activity.suggested_step_preview?.text_array}
                            postAttachments={activity.suggested_step_preview?.attachments}
                        />
                    );
                
                case CardsTypes.REPORT_STEP:
                    return (
                        <ReportStepCard
                            {...props}
                            onRemove={() => {
                                handleActivityClick(Outcomes.DELETE, activity);
                            }}
                            onKeep={() => {
                                handleActivityClick(Outcomes.DISREGARD, activity);
                            }}
                        />
                    );
                
                case CardsTypes.REPORT_POST:
                    return (
                        <ReportPostCard
                            {...props}
                            post={activity.post}
                            onDelete={() => handleActivityClick(Outcomes.DELETE, activity)}
                            onKeep={() => handleActivityClick(Outcomes.DISREGARD, activity)}
                        />
                    );
                
                case CardsTypes.BUSINESS_STATUS_CHANGED: {
                    if (isOperational) {
                        return null;
                    }
                    
                    return (
                        <StepClosedCard
                            {...props}
                            stepId={activity.full_post?.step_id}
                            stepName={activity.full_post?.step_title}
                            isPermanent={activity.type_specific_info?.business_status === StepStatuses.PERMANENTLY_CLOSED}
                            onCardClick={() => setStepActivitySlider(activity)}
                            onKeep={async callback => {
                                const data = await handleActivityClick('disregard', activity);
                                callback(data);
                            }}
                            onDelete={async callback => {
                                const data = await handleActivityClick('delete', activity);
                                callback(data);
                            }}
                        />
                    );
                }
                
                default:
                    alert('Unknown card type');
                    return null;
            }
        });
    };
    
    const renderContent = () => {
        if (loader) {
            return <LoadingAnimation/>;
        }
        
        const filteredActivities = Object.values(activitiesList.activities)?.filter(activity => {
            if (!activity.type_specific_info) {
                return true;
            }
            
            return activity.type_specific_info.business_status !== StepStatuses.OPERATIONAL;
        });
        
        if (filteredActivities.length === 0) {
            return (
                <div className={styles.emptyBlock}>
                    <div className={styles.emptyContent}>
                        <img src='/assets/img/steps3Images/emptyRecent.png' alt=''/>
                        <p>{isUnhandledOnly && filteredActivityTypes.length === 0
                            ? <FormattedMessage id="recentActivities.no_unresolved"/>
                            : <FormattedMessage id="recentActivities.no_updates"/>}</p>
                    </div>
                </div>
            );
        }
        
        return (
            <div
                id='activitiesCardList'
                className={classNames(styles.activityCards, {
                    [styles.openStepProfile]: stepActivitySlider,
                })}
            >
                <InfiniteScroll
                    className={styles.infiniteScroll}
                    dataLength={activitiesLength}
                    next={loadNextPage}
                    hasMore={!!activitiesList.cursor}
                    loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
                    scrollableTarget='activitiesCardList'
                    ref={scrollRef}
                    onScroll={() => {
                        if (scrollTimeoutId) clearTimeout(scrollTimeoutId);
                        setIsScrolling(true);
                        
                        scrollTimeoutId = setTimeout(() => {
                            setIsScrolling(false);
                        }, 500);
                    }}
                >
                    {renderCards()}
                </InfiniteScroll>
            </div>
        );
    };

    return (
        <>
            <div className={classNames(styles.container, { [styles.isLoading]: loader })}>
                <div className={stepActivitySlider || suggestionsTags ? styles.sliderOpen : styles.sliderClose}>
                    <Filters
                        filteredActivityTypes={filteredActivityTypes}
                        unhandledActivities={unhandledActivities}
                        handleFilterClick={handleFilterClick}
                        setIsUnhandledOnly={setIsUnhandledOnly}
                        isUnhandledOnly={isUnhandledOnly}
                    />
                    {renderContent()}
                    <RequestMessage setDataRequest={setDataRequest} dataRequest={dataRequest}/>
                </div>
                <div>
                    {stepActivitySlider && (
                        <div className={styles.stepProfileContainer}>
                            {postPageIdToOpen ? (
                                <PostPage key={postPageIdToOpen} postId={postPageIdToOpen} onBack={() => setPostPageIdToOpen('')} className={styles.postPage}/>
                            ) : (
                                <StepProfile
                                    key={stepId}
                                    stepId={stepId}
                                    onBack={() => setStepActivitySlider(null)}
                                    onClickPost={postId => setPostPageIdToOpen(postId)}
                                    viewOnMap
                                />
                            )}
                        </div>
                    )}
                    {suggestionsTags && (
                        <div className={styles.slider} style={{ display: 'block' }}>
                            <SuggestionsTags
                                stepId={suggestionsTags?.type_specific_info?.step_id}
                                mapId={mapId}
                                tagsToAdd={suggestionsTags?.type_specific_info?.tags_to_add}
                                tagsToRemove={suggestionsTags?.type_specific_info?.tags_to_remove}
                                handled={suggestionsTags?.handled}
                                openedBlock={!!suggestionsTags}
                                onClickPrev={() => setSuggestionsTags(null)}
                                outcome={suggestionsTags?.outcome}
                                onHandled={async outcome => {
                                    const newList = { ...activitiesList };
                                    if (!newList.activities[suggestionsTags._id].handled) {
                                        handleRecentActivitie(mapId, suggestionsTags._id, outcome);
                                    }
                                    newList.activities[suggestionsTags._id].handled = true;
                                    newList.activities[suggestionsTags._id].outcome = outcome;
                                    setActivitiesList(newList);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {archivePopupData?.open && (
                <ArchivedStepPopup
                    deletedPost={deletedPost}
                    fromRecentActivities
                    handleBack={() => handleClose()}
                    mapId={mapId}
                    stepId={archivePopupData?.stepId}
                    stepTitle={archivePopupData?.stepTitle}
                    handleArchivePopup={value => setArchivePopup(value)}
                    onClickArchive={value => handleArchive(value, archivePopupData?.activityId)}
                    isDeletePost={archivePopupData?.isDeletePost}
                    isLastPost={archivePopupData?.lastPost}
                />
            )}
            {isSelectTagsModalOpen.show && (
                <ModalsSelectTags
                    selectedModalTags={selectedModalTags}
                    isSelectTagsModalOpen={isSelectTagsModalOpen}
                    pendingSelectedTags={selectedModalTags.tags}
                    setPendingSelectedTags={(item, tagsToDelete, tagsToAdd) => {
                        changeSelectedTags(item, tagsToDelete, tagsToAdd);
                    }}
                    map={map}
                    tagsObject={tagsObject}
                    onCancel={() => {
                        setIsSelectTagsModalOpen({ show: false, disableDone: false });
                    }}
                />
            )}
            {showOnMap?.show && (
                <ModalsChangeLocation
                    infoLocation={showOnMap?.data}
                    pinIcon={PendingPin}
                    onCancel={() => {
                        setShowOnMap({ show: false, data: null });
                    }}
                />
            )}
            <ImagePreview/>
            <WelcomeMessage
                isOpen={isWelcomeMessageSettingsSliderOpen}
                onSuccess={() => setIsWelcomeMessageSettingsSliderOpen(false)}
                onClose={() => setIsWelcomeMessageSettingsSliderOpen(false)}
            />
        </>
    );
};

export default RecentActivities;
