const IconError = ({ color = '#eb634c', width = 15, height = 15 }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.37 11.06' width={width} height={height}>
            <g>
                <g>
                    <path
                        d='M6.19 4.44v2.19M5.43.94.62 9.25a.87.87 0 0 0 .32 1.19.83.83 0 0 0 .44.12H11a.87.87 0 0 0 .87-.87.83.83 0 0 0-.12-.44L6.94.94A.87.87 0 0 0 5.75.62a.82.82 0 0 0-.32.32Z'
                        style={{
                            fill: 'none',
                            stroke: color,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                        }}
                    />
                    <path
                        d='M6.19 9.25a.66.66 0 0 0 0-1.31.66.66 0 1 0 0 1.31Z'
                        style={{
                            fill: color,
                        }}
                    />
                </g>
            </g>
        </svg>
    );
};

export default IconError;
