import React, { useContext } from 'react';
import { PaginationSliderContext } from '../index';
import { noop } from 'lodash';

export const directions = {
    NEXT: 'right',
    PREV: 'left',
};

const CustomNavigationButton = ({ direction = directions.NEXT, className, children, onClick = noop }) => {
    const { prevRef, nextRef } = useContext(PaginationSliderContext);

    return (
        <div className={className} onClick={onClick} ref={direction === directions.NEXT ? nextRef : prevRef}>
            {children}
        </div>
    );
};

export default CustomNavigationButton;
