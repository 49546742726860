import styles from './index.module.scss';
import DropZone from '../../../../../components/DropZone';
import DragAndDrop from '../../../../../components/DragAndDrop';
import ImageLoader from '../../../../../components/common/ImageLoader';
import { Grid, TextareaAutosize } from '@material-ui/core';
import PostSlider from '../../../../../components/PostSlider';
import { getImageSrcFromFile } from '../../../../../helpers';
import { useMemo } from 'react';
import ImageCarousel from '../../../../../lib/ImageCarousel';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents, mixpanelProperties } from '../../../../../helpers/mixpanel';
import { isOnboarding } from '../../../../../helpers';

const TITLE = 'Share something about this place';

const StepDescription = ({ onLoadFiles, handleTextChange, text, imageLoading, files, removeFile }) => {
    const IMAGE_CAROSUEL_HEIGHT = 150;
    const IMAGE_CAROSUEL_WIDTH = 170;

    const generateNewImagesUrl = () =>
        files.map(image =>
            getImageSrcFromFile({ image, reason: 'map_manager_MapCreationFinished', height: IMAGE_CAROSUEL_HEIGHT, width: IMAGE_CAROSUEL_WIDTH })
        );
    const generatedImages = useMemo(generateNewImagesUrl, [files]);

    return (
        <div className={styles.container}>
            <p className={styles.title}>{TITLE}</p>
            <DragAndDrop onDrop={files => onLoadFiles(files, 'drop')}>
                <div className={styles.textareaBlock}>
                    <TextareaAutosize
                        onChange={handleTextChange}
                        value={text}
                        id='description'
                        className={styles.textarea}
                        autoFocus
                        name=''
                        cols='30'
                        minRows={5.5}
                        placeholder={`What makes this place worth checking out?`}
                    />
                    {imageLoading ? (
                        <ImageLoader className={styles.imageLoader} />
                    ) : (
                        files?.length > 0 && (
                            <ImageCarousel
                                classNameDiv={styles.imgBlock}
                                classNameImage={styles.imgSlide}
                                addDeleteIcon
                                onDeleteClick={removeFile}
                                images={generatedImages}
                                settings={{ slidesToShow: 2, slidesToScroll: 1 }}
                            />
                        )
                    )}
                    <DropZone
                        onboarding={true}
                        text={'Add image'}
                        onLoadFiles={files => onLoadFiles(files, 'upload')}
                        onClick={() =>
                            mixpanel.track(mixpanelEvents.MM_OB_MANUAL_STEP_ADD_IMAGE_CLICKED, {
                                [mixpanelProperties.IS_OB]: isOnboarding(),
                            })
                        }
                    />
                </div>
            </DragAndDrop>
        </div>
    );
};

export default StepDescription;
