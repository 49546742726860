import styles from './index.module.scss';
import React, { useCallback, useContext, useState } from 'react';
import { Avatar, ClickAwayListener, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import MoreButton from '../../../../components/common/buttons/MoreButton';
import { generateImageUrl } from '../../../../helpers';
import * as timeago from 'timeago.js';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import mixpanel, { mixpanelEvents } from '../../../../helpers/mixpanel';
import copiesPrefix from '../../../../copies.json';
import { UserTagPopover } from '../../../../components/common';
import { PopupContext } from '../../../../context';

const copies = copiesPrefix.members;

const useStyles = makeStyles(theme => ({
    root: {
        width: 150 + theme.spacing(3) * 2,
        marginLeft: '20px',
        marginRight: '10px',
        display: 'flex',
    },
    tooltipBlock: {
        boxShadow: '0 0 1.688rem 0 rgba(10, 29, 69, 0.08)',
        padding: '20px',
        width: '248px',
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        // margin: '8px 0 0 0',
    },
    tooltipDisplay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px 0',
        '&:first-child': {
            paddingTop: '0px',
        },
        '&:last-child': {
            paddingBottom: '0px',
        },
    },
    tooltipDisplayBlock: {
        display: 'flex',
        alignItems: 'center',
    },
    img: {
        marginRight: '12px',
    },
    tooltipText: {
        fontFamily: 'Roboto-Regular,sans-serif',
        fontSize: '15px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '-0.41px',
        color: '#3a3c3f',
    },
    tooltipLabel: {
        fontFamily: 'Roboto-Bold,sans-serif',
        fontSize: '15px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '-0.41px',
        color: '#3a3c3f',
    },
}));

const MemberBlock = ({ setRemovePopup, setSelectedMember, setChangePopup, member, isScrolling }) => {
    const [dropDown, setDropDown] = useState(false);
    const classes = useStyles();
    const user = useSelector(userSelectors.user);
    const { activePopupId, setActivePopupId } = useContext(PopupContext);

    const handleChangeRole = useCallback(role => {
        const oneUser = {
            [member?._id]: { role: role },
        };
        setSelectedMember({ member, role: oneUser });
        setChangePopup(true);
    }, []);

    const iconContributor = type => {
        switch (type) {
            case 'STEPS':
                return '/assets/img/steps3Images/stepIcon.svg';
            case 'POSTS':
                return '/assets/img/steps3Images/postIcon.svg';
            case 'IMAGES':
                return '/assets/img/steps3Images/imageIcon.svg';
            case 'RATINGS':
                return '/assets/img/steps3Images/starIcon.svg';
            case 'CLAPS':
                return '/assets/img/steps3Images/clapIcon.svg';
            case 'TAGS':
                return '/assets/img/steps3Images/tagIcon.svg';
            default:
                return '';
        }
    };

    return (
        <div className={styles.memberBlock} role='listitem'>
            <div className={styles.memberLeftBlock}>
                <Avatar
                    alt={member?.display_name}
                    src={generateImageUrl({
                        imageId: member?.image,
                        width: 48,
                        height: 48,
                        reason: 'member_image',
                    })}
                    className={styles.avatar}
                />
                <div className={styles.memberInfo}>
                    <UserTagPopover isActive={activePopupId === member?._id} userId={member?._id} isScrolling={isScrolling} key={member?._id}>
                        <span className={classNames(styles.memberName, 'pointer')} onClick={() => setActivePopupId(member?._id)}>
                            {member?.display_name}
                        </span>
                    </UserTagPopover>
                    <div className={styles.dFlex}>
                        {member?.is_contributor && member?.points_details?.length > 0 && member?.map_role === 'MEMBER' ? (
                            <Tooltip
                                TransitionComponent={Fade}
                                placement='bottom-start'
                                classes={{ tooltip: classes.tooltipBlock }}
                                title={
                                    <>
                                        {member?.points_details.map(point => (
                                            <div className={classes.tooltipDisplay}>
                                                <div className={classes.tooltipDisplayBlock}>
                                                    <img className={classes.img} src={iconContributor(point?.icon)} alt={''} />
                                                    <span className={classes.tooltipText}>{point?.text === 'Images' ? 'Photos' : point?.text}</span>
                                                </div>
                                                <label className={classes.tooltipLabel}>{point?.count}</label>
                                            </div>
                                        ))}
                                    </>
                                }
                            >
                                <div className={classNames(styles.memberLabel, member?.is_contributor && styles.memberContributor)}>
                                    {copies.labels.contributor}
                                </div>
                            </Tooltip>
                        ) : (
                            member?.map_role !== 'MEMBER' && <div className={classNames(styles.memberLabel, styles.memberAdmin)}>{copies.labels.admin}</div>
                        )}
                        {member?.map_role === 'MEMBER' && (
                            <span className={styles.memberJoin}>
                                {' '}
                                {copies.joined_about}
                                {timeago.format(member?.joined_to_map_at)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.memberRightBlock}>
                {member?.map_role !== 'ADMIN' && user?.id !== member?._id && (
                    <ClickAwayListener onClickAway={() => setDropDown(false)}>
                        <div className={styles.dropDownAction} onClick={() => setDropDown(!dropDown)}>
                            <MoreButton opened={dropDown} />
                            {dropDown && (
                                <ul className={styles.ulClassParent}>
                                    <li className={styles.parent}>
                                        <a className={styles.text}>{copies.permission}</a>
                                        <span className={styles.expand}>
                                            <img src={'../assets/img/moreMenu.svg'} />
                                        </span>
                                        <ul className={classNames(styles.child, styles.ulClassChildren)}>
                                            <li onClick={member?.map_role === 'MEMBER' ? () => handleChangeRole('MANAGER') : () => {}}>
                                                {member?.map_role !== 'MEMBER' && <img className={styles.iconCheck} src='/assets/img/check.svg' alt='' />}{' '}
                                                <a
                                                    className={classNames(styles.text, {
                                                        [styles.textActive]: member?.map_role !== 'MEMBER',
                                                    })}
                                                >
                                                    {copies.admin}
                                                </a>
                                            </li>
                                            <li onClick={member?.map_role !== 'MEMBER' ? () => handleChangeRole('MEMBER') : () => {}}>
                                                {member?.map_role === 'MEMBER' && <img className={styles.iconCheck} src='/assets/img/check.svg' alt='' />}{' '}
                                                <a
                                                    className={classNames(styles.text, {
                                                        [styles.textActive]: member?.map_role === 'MEMBER',
                                                    })}
                                                >
                                                    {copies.member}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        onClick={() => {
                                            setSelectedMember(member);
                                            setRemovePopup(true);
                                        }}
                                        className={styles.parent}
                                    >
                                        <a className={classNames(styles.text, styles.textDelete)}>{copies.remove_from_map}</a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </ClickAwayListener>
                )}
            </div>
        </div>
    );
};
export default MemberBlock;
