import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../index.module.scss';
import queryString from 'query-string';
import config from '../../../../config';
import { useDebounce } from '../../../../hooks';
import { userSelectors } from '../../../../redux/user';
import SearchResult, { iconType } from '../../../../components/common/buttons/SearchResult';
import SearchInput from '../../../../components/common/inputs/SearchInput';
import { onboardActions } from '../../../../redux/onboard';
import copies from '../../../../copies.json';

const onboardingCopies = copies?.onboarding;
const GLOBE_TYPES = ['street_address', 'route', 'locality', 'country'];

const SearchBox = ({ location, setLocation }) => {
    const dispatch = useDispatch();
    let [searchValue, setSearchValue] = useState(location?.title || '');
    const searchDebounce = useDebounce(searchValue, 250);
    let [placeResults, setPlaceResults] = useState([]);
    const [isOpen, setOpen] = useState(false);
    let [locationsResults, setLocationResults] = useState([]);
    const userLocation = useSelector(userSelectors.locationSelector);

    useEffect(() => {
        (async () => {
            if (searchDebounce === '') {
                setPlaceResults([]);
                setLocationResults([]);
                setOpen(false);
            }
        })();
    }, [searchDebounce]);

    const handleOnChange = useCallback(
        async e => {
            setSearchValue(e.target.value);
            if (e.target.value.length > 0) {
                setTimeout(async () => {
                    const accessToken = localStorage.getItem('at');
                    const query = queryString.stringify({
                        data_type: ['location', 'place'],
                        term: e.target.value,
                        lat: userLocation?.lat,
                        lon: userLocation?.lng,
                    });
                    let response = await fetch(`${config.domain.address}/steps3/search?${query}`, {
                        headers: {
                            Authorization: accessToken,
                            app_version: config.version.path,
                            app_platform: 'map_manager',
                        },
                    });
                    response = await response.json();
                    const foundedPlaces = response.results.filter(val => val['result_type'] === 'place');
                    const foundedLocations = response.results.filter(val => val['result_type'] === 'location');
                    const place = foundedPlaces?.filter(val => !GLOBE_TYPES.some(item => val?.types?.includes(item)));
                    const location = foundedLocations?.filter(val => !GLOBE_TYPES.some(item => val?.types?.includes(item)));

                    setPlaceResults(place);
                    setLocationResults(location);
                    if ((place?.length > 0 || location?.length > 0) && e.target.value.length > 0) {
                        setOpen(true);
                    }
                }, 250);
            }
            if (e.target.value.length === 0) {
                setOpen(false);
                return false;
            }
        },
        [userLocation?.lat, userLocation?.lng]
    );

    const onClickingLocation = location => {
        setLocation(location);
        dispatch(
            onboardActions.setOnboardMapCenter({
                position: [location?.lat || location?.location?.lat, location?.lon || location?.location?.lng],
                zoom: 18,
            })
        );
        setSearchValue(location?.title || location?.location?.description);
        setOpen(false);
    };
    const getPlaceCoordinates = async place => {
        const accessToken = localStorage.getItem('at');
        let response = await fetch(`${config.domain.address}/steps3/search/placeidtocoordinate?place_id=${place['place_id']}`, {
            headers: {
                Authorization: accessToken,
                app_version: config.version.path,
                app_platform: 'map_manager',
            },
        });
        response = await response.json();
        place.location = response.geometry.location;
        return place;
    };
    const onClickingPlace = useCallback(
        async place => {
            await getPlaceCoordinates(place);
            dispatch(onboardActions.setOnboardMapCenter({ position: place.location, zoom: 18 }));
            setLocation(place);
            setOpen(false);
            setSearchValue(place?.description);
        },
        [dispatch, setLocation]
    );

    const handleFocus = useCallback(() => {
        if (searchDebounce?.length > 0) {
            setOpen(true);
        }
    }, [searchDebounce]);

    const handleBlur = useCallback(() => {
        if (searchDebounce?.length > 0) {
            setTimeout(() => {
                setOpen(false);
            }, 500);
        }
    }, [searchDebounce]);

    return (
        <>
            <SearchInput
                hiddenIcon
                autoFocus
                className={styles.inputSearch}
                onChange={e => handleOnChange(e)}
                onFocus={() => handleFocus()}
                onBlur={() => handleBlur()}
                value={searchValue}
                placeholder={onboardingCopies?.giveStepName}
            />
            {(locationsResults?.length > 0 || placeResults?.length > 0) && isOpen && (
                <div className={styles.content}>
                    {locationsResults.map(
                        (result, idx) =>
                            !result.is_there_step && (
                                <SearchResult
                                    idx={idx}
                                    type={'steps_place'}
                                    className={styles.searchResult}
                                    key={result._id}
                                    iconSrc={iconType.step}
                                    content={result.title}
                                    subContent={result.geocode_address?.formatted_address || result.secondary_title}
                                    onClick={() => {
                                        onClickingLocation(result);
                                    }}
                                />
                            )
                    )}
                    {placeResults.map((result, idx) => (
                        <SearchResult
                            idx={idx}
                            type={'google_place'}
                            className={styles.searchResult}
                            key={result['place_id']}
                            iconSrc={GLOBE_TYPES.includes(result.types[0]) ? iconType.globe : iconType.step}
                            content={result.description.split(', ')[0]}
                            subContent={result.description?.split(', ')[1] || result.title?.split(', ')[1]}
                            onClick={() => {
                                onClickingPlace(result);
                            }}
                        />
                    ))}
                </div>
            )}
        </>
    );
};
export default SearchBox;
