export const map = mapId => state => state[mapId] || {};
export const mapSelector = mapId => state => state[mapId] ? state[mapId].mapData : {};
export const steps = mapId => state => state[mapId] ? state[mapId].steps : {};
export const posts = mapId => state => state[mapId] ? state[mapId].posts : {};
export const mapName = mapId => state => state[mapId] ? state[mapId].name : '';
export const mapTagsCategories = mapId => state => state[mapId] ? state[mapId].tags_categories : [];
export const mapTags = mapId => state => state[mapId] ? state[mapId].tag_objects : [];
export const stepsList = mapId => state => state[mapId] ? state[mapId].steps_list : [];
export const stepsListAnchor = mapId => state => state[mapId] ? state[mapId].steps_list.anchor : [];
export const mapCenter = mapId => state => state[mapId] ? state[mapId].center : null;
export const ghostStep = mapId => state => state[mapId] ? state[mapId].ghostStep : null;
export const toComponentsSelector = mapId => state => state[mapId] ? state[mapId].toComponents : false;
export const toGeneralSelector = mapId => state => state[mapId] ? state[mapId].toGeneral : false;
export const zoomEnd = mapId => state => state[mapId] ? state[mapId].zoomEnd : false;
export const tag_objects = mapId => state => state[mapId] ? state[mapId].tag_objects : [];
export const selectedTags = mapId => state => state[mapId] ? state[mapId].selected_tags : [];
export const guideTaskCompleted = mapId => state => state[mapId] ? state[mapId].guide_task : {};
export const getPendingPostsCache = mapId => state => state[mapId] ? state[mapId].pendingPostsCache : {};
export const getIncompleteImports = mapId => state => state[mapId] ? state[mapId].incomplete_imports : {};
export const generateDeepLink = mapId => state => state[mapId] ? state[mapId].newDeepLink : {};
export const insights = mapId => state => state[mapId] ? state[mapId].insights : {};
export const unhandledActivities = mapId => state => state[mapId] ? state[mapId].unhandled_activities : 0;
export const stepIds = mapId => state => state[mapId] ? state[mapId].stepIds : [];
export const allStepsMapCenter = mapId => state => state[mapId] ? state[mapId].allStepsMapCenter : null;
export const localFilteredStepsSelector = ({ map: { steps } }) =>
    steps?.filter(step => {
        // if (selectedFilters?.tags?.length > 0) {
        //     if (!selectedFilters?.tags.some((tag) => step?.selected_tags_ids?.includes(tag.tag_id))) return false;
        // }

        return true;
    });
export const userRole = (mapId, userId) => state => state[mapId] ? state[mapId].managers?.find(manager => manager._id === userId)?.map_role : '';
