import styles from './LoadingPostsBlocks.module.scss';
import copies from '../../../copies.json';

const NoMorePostsBlock = () => {
    return (
        <div className={styles.block}>
            <span>{copies?.feed?.blocks?.noMorePosts}</span>
        </div>
    );
};

export default NoMorePostsBlock;
