import React from 'react';
import styles from './index.module.scss';
import copiesPrefix from '../../../../../../copies.json';
import CustomButton, { ButtonType, ButtonSize } from '../../../../../../components/common/buttons/CustomButton';

const copies = copiesPrefix.mapManager.emptyState;

const EmptyMap = ({ onClickAddStep }) => (
    <div className={styles.block}>
        <span className={styles.text}>{copies.no_steps}</span>
        <span className={styles.boldText}>{copies.create_label}</span>
        <CustomButton type={ButtonType.SECONDARY} size={ButtonSize.MEDIUM} text={copies.add_first_step} onClick={onClickAddStep} />
        <img className={styles.emptyMapImg} alt='' src='/assets/img/emptyMap.svg' />
    </div>
);

export default EmptyMap;
