import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Outcome from '../Outcome';
import { noop } from 'lodash';
import { BaseCard } from '../BaseCard';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import { Outcomes } from '../../constants';
import copiesPrefix from '../../../../copies.json';

import styles from './index.module.scss';

const copies = copiesPrefix.mapUpdates;

const SuggestTagsCard = ({
    userPreview = {},
    textArray = [],
    createdAt = 0,
    outcome,
    tagsToAdd = [],
    tagsToRemove = [],
    managerPreview,
    onDisregard = noop,
    onDecline = noop,
    onApprove = noop,
    type = 'suggest',
    managerInfomation,
    onTagClick = noop,
    ...rest
}) => {
    return (
        <BaseCard
            textArray={textArray}
            avatarImgId={userPreview.image}
            createdAt={createdAt}
            onClose={onDisregard}
            onTagClick={onTagClick}
            {...rest}
            shouldRenderFooter={outcome !== Outcomes.UNHANDLED}
            footer={
                <Outcome text={outcome === Outcomes.DECLINE ? copies.declined_by : copies.approved_by} managerPreview={managerPreview || managerInfomation} />
            }
            buttons={[
                <CustomButton onClick={onDecline} size={ButtonSize.MEDIUM} type={ButtonType.SECONDARY} singleClick clicked={outcome === 'decline'}>
                    <FormattedMessage id={type === 'suggest' ? 'mapUpdates.decline' : 'mapUpdates.remove_changes'} />
                </CustomButton>,
                <CustomButton onClick={onApprove} size={ButtonSize.MEDIUM} type={ButtonType.PRIMARY} singleClick clicked={outcome === 'approve'}>
                    <FormattedMessage id='mapUpdates.approve' />
                </CustomButton>,
            ]}
        >
            <div className={styles.tagsBlock}>
                <Tags tags={tagsToAdd} type='add' />
                <Tags tags={tagsToRemove} type='remove' />
            </div>
            <div className='hr' />
        </BaseCard>
    );
};

const Tags = ({ tags = [], type = 'add' }) => {
    if (!tags.length) return null;

    return (
        <div className='flex align-start m-b-8'>
            <span className={styles.label}>
                <FormattedMessage id={`mapUpdates.${type}`} />
            </span>
            <div className='flex flex-wrap flex-gap-8'>
                {tags.map(tag => (
                    <div
                        className={classNames(styles.tag, {
                            [styles.remove]: type === 'remove',
                        })}
                    >
                        {tag.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SuggestTagsCard;
