import { useCallback } from "react";
import { PlaceInfoInput } from "./PlaceInfoInput";
import Routes from "../../../config/routes";
import { AddPlaceSteps } from "../../AddStep";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { mapActions, mapSelectors } from '../../../redux/map';
import { userSelectors } from '../../../redux/user';


export const PlaceInfoAddress = ({ stepId, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsMapClean } = useOutletContext();
  const mapId = useSelector(userSelectors.selectedMapId);
  const step = useSelector(mapSelectors.steps(mapId))[stepId];
  const onEdit = useCallback(() => {
    dispatch(
        mapActions.setMapCenter(mapId, {
          position: [step.lat, step.lon || step.lng],
          zoom: 15,
        })
    );
    
    navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`, {
      state: {
        flowStepId: AddPlaceSteps.EDIT_STEP_DETAILS,
        params: {
          title: step.title,
          nameOfPlace: step.hpaw?.address,
          address: step.hpaw?.address,
          fromWhere: 'new_location',
          step,
        },
      },
    });
    
    setIsMapClean(true);
  }, [dispatch, navigate, setIsMapClean, mapId, step]);
  
  return <PlaceInfoInput {...rest} onEdit={onEdit}/>;
};
