import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LogRocket from 'logrocket';
import { QueryParamProvider } from 'use-query-params';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ModalProvider, PopupContextProvider, ToastContextProvider } from './context';
import en from './copies.json';
import config from './config';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/store';
import { flattenObject } from './helpers';
import App from './App';
import { DropdownMenuProvider } from './components/common/DropdownMenu/DropdownMenuContext';
import { TextSelectionProvider } from './features/tag-location-in-post';
import { TextInputProvider } from './components/TextInput/TextInputContext';
import ReactGA from 'react-ga4';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import './index.scss';
import { HelmetProvider } from 'react-helmet-async';

LogRocket.init(config.logrocket.key);
ReactGA.initialize('G-H6SK18754S');

const basename = process.env.PUBLIC_URL ?? '/';
const messages = flattenObject(en);
const queryClient = new QueryClient();

const app = (
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <TextSelectionProvider>
                    <ToastContextProvider>
                        <PopupContextProvider>
                            <DropdownMenuProvider>
                                <BrowserRouter basename={basename}>
                                    <QueryParamProvider>
                                        <TextInputProvider>
                                            <ModalProvider>
                                                <HelmetProvider>
                                                    <App />
                                                </HelmetProvider>
                                            </ModalProvider>
                                            <div id="modal-root" />
                                            <div id="dropdown-menu-root" />
                                            <div id="user-tag-popover-portal" />
                                        </TextInputProvider>
                                    </QueryParamProvider>
                                </BrowserRouter>
                            </DropdownMenuProvider>
                        </PopupContextProvider>
                    </ToastContextProvider>
                </TextSelectionProvider>
            </QueryClientProvider>
        </Provider>
    </IntlProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
