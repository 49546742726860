import React, { useEffect, useState, useContext, useMemo } from 'react';
import { noop } from 'lodash';
import styles from './index.module.scss';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions } from '../../../../redux/map';
import { userSelectors } from '../../../../redux/user';
import ROUTES from '../../../../config/routes';
import { ToastContext, RATINGS_SAVED } from '../../../../context/ToastContext';
import mixpanel, { mixpanelProperties, mixpanelEvents } from '../../../../helpers/mixpanel';
import { X } from 'phosphor-react';
import { arrToObj } from '../../../../helpers';
import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import StarsRating from '../../Ratings';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import copiesPrefix from '../../../../copies.json';
import context from '../../../../constants/context';

const copies = copiesPrefix.ratings.modal;

const RatingModal = ({ onClickPrev = noop, step }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setToast } = useContext(ToastContext);
    const mapId = useSelector(userSelectors.selectedMapId);
    const [newRatings, setNewRatings] = useState({});
    const [loading, setLoading] = useState(false);
    const ratingsComponent = arrToObj(step?.components || [], 'type').rating;
    const ratingsSubComponents = ratingsComponent?.rating_subcomponents;
    const ratings = ratingsSubComponents?.filter(item => item?.type === 'score1to5') || [];

    useEffect(() => {
        sendMixPanelEvent(mixpanelEvents.STEP_RATE_ADD_OPEN);
    }, []);

    const onClickDone = async () => {
        setLoading(true);
        const newRatingsIds = Object.keys(newRatings);
        const updatedRatings = newRatingsIds.map(ratingId => {
            const ratingNewValue = newRatings[ratingId];
            if (ratingNewValue) {
                return {
                    rating_subcomponent_id: ratingId,
                    value: ratingNewValue,
                };
            }
        });
        sendMixPanelEvent(mixpanelEvents.STEPS_RATE_SAVE);
        await dispatch(
            mapActions.editStepRating(
                step._id,
                {
                    map_id: mapId,
                    rating_component_id: ratingsComponent.id,
                    subcomponents: updatedRatings,
                    context: context.STEP_PROFILE,
                },
                mapId
            )
        );

        setLoading(false);
        onClickPrev();
        setToast({ text: RATINGS_SAVED });
    };

    const updateRating = (rating, updatedRating) => {
        const isRatingUpdated = updatedRating !== rating.self;
        setNewRatings(ratings => ({
            ...ratings,
            [rating.id]: isRatingUpdated ? updatedRating : undefined,
        }));
    };

    const loadingSpinnerOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    const isRatingsUpdated = useMemo(() => {
        const newRatingsArr = Object.values(newRatings);
        return newRatingsArr.some(rating => rating);
    }, [newRatings]);

    const sendMixPanelEvent = event => {
        mixpanel.track(event, {
            [mixpanelProperties.STEP_TITLE]: step?.title,
            [mixpanelProperties.STEP_ID]: step?._id,
        });
    };
    return (
        <BaseModal className={styles.modal} size={ModalSizes.MEDIUM} isActive onClose={onClickPrev}>
            <div className={styles.top}>
                <h3 className={styles.title}>{copies.title}</h3>
                <X className={styles.xButton} onClick={onClickPrev} size={16} color='#666A70' />
            </div>
            <div className={styles.ratings}>
                {ratings.map(rating => {
                    return (
                        <div className={styles.rating}>
                            <h4 className={styles.title}>{rating.text}</h4>
                            <StarsRating
                                selectedStarts={parseInt(newRatings[rating._id] || rating?.self)}
                                updateRating={updatedRating => updateRating(rating, updatedRating)}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={styles.footer}>
                <button className={styles.settingsButton} onClick={() => navigate('/' + ROUTES.MAP_SETTINGS_RATINGS)}>
                    {copies.ratingSettings}
                </button>
                <CustomButton
                    className={styles.doneButton}
                    disabled={!isRatingsUpdated}
                    onClick={onClickDone}
                    size={'smallSize'}
                    type={ButtonType.PRIMARY_BLUE}
                    text={loading ? <Lottie options={loadingSpinnerOptions} isStopped={false} isPaused={false} width={40} height={40} /> : copies.done}
                />
            </div>
        </BaseModal>
    );
};

export default RatingModal;
