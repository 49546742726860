import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';


export const sharePostEvent = ({type, fromWhere}) => {
    mixpanel.track(mixpanelEvents.SHARE_LINK_COPY, {
        [mixpanelProperties.TYPE]: type,
        [mixpanelProperties.FROM_WHERE]: fromWhere,
    });
}

export const clapPostEvent = ({id, fromWhere, text, attachments, creatorPreview, stepTitle, stepId, userId}) => {
    const contentType = attachments?.length > 0 && text?.length > 0
    ? 'both'
    : text?.length > 0
    ? 'text'
    : attachments?.length > 0
    ? 'image'
    : 'none'
    
    mixpanel.track(mixpanelEvents.POST_PIN, {
        [mixpanelProperties.POST_ID]: id,
        [mixpanelProperties.FROM_WHERE]: fromWhere,
        [mixpanelProperties.POST_CONTENT]: contentType, 
        [mixpanelProperties.IS_SAME_USER_AS_PARENT]: userId === creatorPreview._id ? 'yes' : 'no',
        [mixpanelProperties.STEP_TITLE]: stepTitle,
        [mixpanelProperties.STEP_ID]: stepId,
    });
} 