export const GET_ALL_STEPS_PREVIEW_DATA = 'GET_ALL_STEPS_PREVIEW_DATA';
export const GET_FULL_STEP_DATA = 'GET_FULL_STEP_DATA';
export const GET_STEP_CREATOR_DATA = 'GET_STEP_CREATOR_DATA';
export const FETCH_SINGLE_MAP = 'FETCH_SINGLE_MAP';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_STEP_GHOST = 'SET_STEP_GHOST';
export const CREATE_MAP = 'CREATE_MAP';
export const NEW_MAP = 'NEW_MAP';
export const CREATE_POST = 'CREATE_POST';
export const SET_MAP_COVER_IMAGE = 'SET_MAP_COVER_IMAGE';
export const RESET_ACTIVITIES_COUNT = 'RESET_ACTIVITIES_COUNT';
export const RESTORE_STEP = 'RESTORE_STEP';
export const RESET_PENDING_COUNT = 'RESET_PENDING_COUNT';
export const APPROVE_PENDING_POST = 'APPROVE_PENDING_POST';
export const ADD_NEW_TAGS_TO_MAP = 'ADD_NEW_TAGS_TO_MAP';
export const DELETE_TAGS = 'DELETE_TAGS';
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const ADD_NEW_CATEGORY_WITH_TAGS = 'ADD_NEW_CATEGORY_WITH_TAGS';
export const EDIT_CATEGORY_PRIORITY = 'EDIT_CATEGORY_PRIORITY';
export const SET_PENDGING_POST_CACHE = 'SET_PENDGING_POST_CACHE';
export const BULK_ADD_TAGS = 'BULK_ADD_TAGS';
export const GET_STORY_SHARING_IMAGE = 'GET_STORY_SHARING_IMAGE';
export const MOVE_TAG_TO_CATEGORY = 'MOVE_TAG_TO_CATEGORY';
export const EDIT_STEP_LOCATION = 'EDIT_STEP_LOCATION';
export const GET_STORY_SHARING_IMAGE_STEP = 'GET_STORY_SHARING_IMAGE_STEP';
export const SET_ALL_STEPS_MAP_CENTER = 'SET_ALL_STEPS_MAP_CENTER';
// MapSetting start
export const CHANGE_SECRET_MAP = 'CHANGE_SECRET_MAP';
export const CHANGE_PERMISSIONS_MAP = 'CHANGE_PERMISSIONS_MAP';
export const CHANGE_INCLUDES_COMPONENT = 'CHANGE_INCLUDES_COMPONENT';
export const RESET_MAP_COMPONENTS_DATA = 'RESET_MAP_COMPONENTS_DATA';
export const SET_TAG_COMPONENT = 'SET_TAG_COMPONENT';
export const SET_DATA_CATEGORIES = 'SET_DATA_CATEGORIES';
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const DELETE_MAP_TAGS = 'DELETE_MAP_TAGS';
export const EDIT_CATEGORY_NAME = 'EDIT_CATEGORY_NAME';
export const ADD_MAP_TAGS = 'ADD_MAP_TAGS';
export const ADD_MAP_CATEGORIES = 'ADD_MAP_CATEGORIES';
export const SET_TO_COMPONENTS = 'SET_TO_COMPONENTS';
export const SET_TO_GENERAL = 'SET_TO_GENERAL';
export const LIKE_STEP = 'LIKE_STEP';
// MapSetting end
export const ADD_TAG_TO_STEP = 'ADD_TAG_TO_STEP';
export const DELETE_TAG_FROM_STEP = 'DELETE_TAG_FROM_STEP';
export const EDIT_STEP_RATING = 'EDIT_STEP_RATING';
export const EDIT_TAG_NAME = 'EDIT_TAG_NAME';
export const ADD_NEW_TAG_TO_STEP = 'ADD_NEW_TAG_TO_STEP';
export const SET_ZOOM_END = 'SET_ZOOM_END';
export const DELETE_POST_IMAGE = 'DELETE_POST_IMAGE';
export const DELETE_STEP_ON_MAP = 'DELETE_STEP_ON_MAP';
export const GUIDE_TASKS = 'GUIDE_TASKS';
export const DELETE_IMPORT_STATE = 'DELETE_IMPORT_STATE';
export const SET_IMPORT_STATE = 'SET_IMPORT_STATE';
export const CHANGE_GATES_STATUSES = 'CHANGE_GATES_STATUSES';
export const UPDATE_STEP_INFO = 'UPDATE_STEP_INFO';
export const CREATE_ANNOUNCEMENT_POSTS = 'CREATE_ANNOUNCEMENT_POSTS';
export const CREATE_DEEP_LINK = 'CREATE_DEEP_LINK';
export const GET_INSIGHTS = 'GET_INSIGHTS';
export const UPDATE_MEMBER_COUNT = 'UPDATE_MEMBER_COUNT';
export const CHANGE_STEP_TYPE = 'CHANGE_STEP_TYPE';

export const CLEAR_STEP_AND_MAP_IMAGES = 'CLEAR_STEP_AND_MAP_IMAGES';
export const UPDATE_STEPS_IN_LIST = 'UPDATE_STEPS_IN_LIST';
export const UPDATE_STEPS_FROM_FETCH = 'UPDATE_STEPS_FROM_FETCH';

export const SET_STEPS_LIST_ANCHOR = 'SET_STEPS_LIST_ANCHOR';
export const SET_IS_STEPS_LIST_CACHED = 'SET_IS_STEPS_LIST_CACHED';
export const UPDATE_FLAG = 'UPDATE_FLAG';
export const HANDLE_ACTIVITY = 'HANDLE_ACTIVITY';
