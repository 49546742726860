import React from 'react';
import styles from './index.module.scss';
import Tag from './Tag';

const TagsBlock = ({ item, selectedTags, idx, handleSelectTag }) => {
    return (
        <>
            {item.tags.length > 0 && (
                <div key={idx} className={styles.tagBlock}>
                    <p className={styles.titleCategory}>{item.category}</p>
                    {item.tags.map((tag, index) => (
                        <Tag
                            key={tag.name}
                            checked={selectedTags.some(item => item.name === tag.name)}
                            selectedTags={selectedTags}
                            handleSelectTag={handleSelectTag}
                            tag={tag}
                            idx={index}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default TagsBlock;
