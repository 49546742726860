import { useState } from 'react';
import { MEMBER_TAG_SYMBOL, LOCATION_TAG_SYMBOL } from './constants';

const useTextInput = () => {
    const [mentionTriggers, setMentionTriggers] = useState([]);

    return {
        mentionTriggers,
        tagMember: () => {
            setMentionTriggers(mentionTriggers.concat(MEMBER_TAG_SYMBOL));
        },
        tagLocation: () => {
            setMentionTriggers(mentionTriggers.concat(LOCATION_TAG_SYMBOL));
        },
    };
};

export default useTextInput;
