import React from 'react';
import styles from './task.module.scss';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import copies from '../../copies.json';
import { FormattedMessage } from "react-intl";

const taskCopies = copies.insights.tasks.noTags;

const TagsTask = ({ onClickHandle, done }) => {
    return (
        <div className={styles.container}>
            {done && <div className={styles.doneContainer}/>}
            <div className={styles.header}>
                <span className={styles.title}>
                    <FormattedMessage id="insights.tasks.noTags.title"/>
                    {done && (
                        <span className={styles.done}>
                            <img src='/assets/img/steps3Images/insights/done.svg'/>
                            Done!
                        </span>
                    )}
                </span>
                <span className={styles.description}>{taskCopies.description}</span>
            </div>
            <div className={styles.footer}>
                <div>
                    {!done && (
                        <CustomButton onClick={onClickHandle} className={styles.handleButton} type={ButtonType.PRIMARY_BLUE}>
                            {taskCopies.CTA}
                        </CustomButton>
                    )}
                </div>
                <div className={styles.exampleTags}>
                    <span className={styles.exampleTagTitle}>{taskCopies.footerSubtitle}</span>
                    <div>
                        {taskCopies.examples.map(example => (
                            <span className={styles.exampleTag}>{example}</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TagsTask;
