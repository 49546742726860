import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import Outcome from '../Outcome';
import copies from '../../../../copies.json';
import { IconInfo } from '../../../../components/common/icons';
import { Tooltip, TooltipDirections, TooltipColors } from '../../../../components/common/Tooltips';
import { BaseCard } from '../BaseCard';
import { noop } from 'lodash';
import { Outcomes } from '../../constants';

import styles from './index.module.scss';

export const AutoWelcomeMessage = () => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
        <div className='flex'>
            <span className='flex'>
                <i>{copies.mapUpdates.auto_welcome_message}</i>
                <span className={styles.infoIcon} onMouseOver={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
                    <IconInfo />
                </span>
            </span>
            {isTooltipOpen && (
                <Tooltip
                    bgColor={TooltipColors.BLACK}
                    withFooter={false}
                    className={styles.tooltip}
                    title={copies.mapUpdates.auto_message_tooltip}
                    direction={window.screen.width < 1450 ? TooltipDirections.LEFT : TooltipDirections.RIGHT}
                />
            )}
        </div>
    );
};

const JoinCard = ({
    textArray = [],
    userPreview = {},
    createdAt = 0,
    outcome,
    handlerManager,
    managerPreview,
    isAutoMessage = false,
    onDisregard = noop,
    onWelcome = noop,
    onEdit = noop,
    onTagClick = noop,
    ...rest
}) => {
    // TODO: use callback
    const user = useSelector(userSelectors.user);
    const maps = useSelector(userSelectors.maps);
    const selectedMapId = useSelector(userSelectors.selectedMapId);
    const selectedMap = maps?.filter(({ _id }) => _id === selectedMapId)[0];
    const managers = selectedMap?.managers;
    const hasHandlerManager = handlerManager && handlerManager !== user.id;
    const managerInfomation = managers?.filter(({ _id }) => _id === handlerManager)[0];

    return (
        <BaseCard
            avatarImgId={userPreview.image}
            textArray={textArray}
            createdAt={createdAt}
            onClose={onDisregard}
            onTagClick={onTagClick}
            shouldRenderFooter={outcome === 'welcome' || hasHandlerManager}
            {...rest}
            footer={
                <Outcome text={isAutoMessage ? <AutoWelcomeMessage /> : copies.mapUpdates.welcomed_by} managerPreview={managerPreview || managerInfomation} />
            }
            buttons={[
                <CustomButton type={ButtonType.SECONDARY} size={ButtonSize.MEDIUM} onClick={onEdit} clicked={outcome !== Outcomes.UNHANDLED}>
                    <FormattedMessage id='mapUpdates.edit' />
                </CustomButton>,
                <CustomButton singleClick size={ButtonSize.MEDIUM} type={ButtonType.PRIMARY} onClick={onWelcome} clicked={outcome !== Outcomes.UNHANDLED}>
                    <FormattedMessage id='mapUpdates.send_welcome' />
                </CustomButton>,
            ]}
        />
    );
};

export default JoinCard;
