import React from 'react';
import { ArrowRight } from 'phosphor-react';
import styles from './index.module.scss';
import classNames from 'classnames';

export const IconTypes = {
    PIN: 'pin',
    IMAGE: 'photo',
    IMPORT: 'import',
};

const Option = ({ icon, text, onClick, isActive, className }) => {
    return (
        <div className={classNames(styles.option, className)} onClick={onClick}>
            <div className={classNames(styles.content, { [styles.active]: isActive })}>
                <span className={styles.text}>
                    {icon}
                    {text}
                </span>
                <ArrowRight className={styles.arrow} size='20' color='#2171EC' weight='bold' />
            </div>
        </div>
    );
};

export default Option;
