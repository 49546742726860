
import DropPinViewExitButton from './DropPinViewExitButton';
import styles from './DropPinView.module.scss';

const EmptySideBar = ({onClickExit}) => {
    return (
        <>
            <div className={styles.dropPinView}>
                <DropPinViewExitButton
                    onClick={onClickExit}
                />
            </div>
        </>
    );
};

export default EmptySideBar;
