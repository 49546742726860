import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { MapPin, MapUnpin } from '../../../components/common/icons';

import styles from '../TagLocationInPost.module.scss';

export const TagButton = ({ onClick = noop }) => {
    return (
        <span className={styles.tagLocationCta} onClick={onClick}>
            <MapPin size={20} color='#2171ec' />
            <FormattedMessage id='tagLocationInPost.tag_location' />
        </span>
    );
};

export const UntagButton = ({ onClick = noop }) => {
    return (
        <span className={styles.tagLocationCta} onClick={onClick}>
            <MapUnpin size={20} color='#2171ec' />
            <FormattedMessage id={'tagLocationInPost.untag_location'} />
        </span>
    );
};
