import React, { useState, useRef, forwardRef } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import styles from './index.module.scss';
import { MagnifyingGlass as Search } from 'phosphor-react';
import DiscardButton from './DiscardButton';

const SearchInput = (
    {
        className,
        value: initValue,
        position,
        id,
        placeholder,
        autoFocus,
        disabled = false,
        onChange,
        onFocus,
        onBlur,
        onClickDiscard,
        onClickEnter = noop,
        discardButtonType,
        loading,
        withSearchIcon = true,
        autocomplete = true,
    },
    propRef
) => {
    const [isDiscardButtonVisible, setDiscardButtonVisible] = useState(false);
    const [isFocus, setFocus] = useState(false);
    const inputRef = useRef();
    const actualRefToUse = propRef || inputRef;
    const value = position
        ? Object.values(position)
            .map(item => parseFloat(item).toFixed(6))
            .join(', ')
        : initValue;

    const focusInput = () => {
        actualRefToUse.current?.focus();
    };

    const onKeyDown = e => {
        if (e.key === 'Enter') {
            onClickEnter();
        }
    };

    return (
        <div onClick={focusInput} className={classNames(styles.block, { [styles.focused]: isFocus }, className)}>
            {withSearchIcon && <Search className={styles.searchIcon} color='#3A3C3F' size={16} onClick={focusInput}/>}
            <input
                id={id}
                data-testid={id}
                ref={actualRefToUse}
                value={value}
                placeholder={placeholder || 'Search'}
                autoFocus={autoFocus}
                disabled={disabled}
                onChange={event => {
                    if (onClickDiscard && !loading) {
                        setDiscardButtonVisible(event.target.value.trim() !== '');
                    }

                    onChange(event);
                }}
                onFocus={() => {
                    setFocus(true);
                    onFocus && onFocus();
                }}
                onBlur={() => {
                    setFocus(false);
                    onBlur && onBlur();
                }}
                autoComplete={autocomplete.toString()}
                spellCheck='false'
                onKeyDown={onKeyDown}
                tabIndex='0'
            />
            {!loading && isDiscardButtonVisible &&
                <DiscardButton discardButtonType={discardButtonType} onClickDiscard={(params) => {
                    setDiscardButtonVisible(false);
                    actualRefToUse.current.value = '';
                    onClickDiscard(params);
                }}/>}
            {loading && <img className={styles.loading} alt='' src='/assets/img/loadingSpinner.svg'/>}
        </div>
    );
};

export default forwardRef(SearchInput);
export { default as FilterButton } from './FilterButton';
export { DiscardButtonTypes } from './DiscardButton';
