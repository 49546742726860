import { useEffect, useState, useRef } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { useDebounce, useClickAway } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { MagnifyingGlass, X } from '../../common/icons';
import { stepActions } from '../../../redux/step';
import { userSelectors } from '../../../redux/user';
import { AddIcon, LoadingSpinner, LocationIcon } from '.';
import mixpanel, { mixpanelEvents, mixpanelProperties } from "../../../helpers/mixpanel";

const SearchMapStepsByPosition = ({ position, onSearchResultClick = noop, onCancel = noop, defaultValue = '', fromWhere = '' }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const searchResultsRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(defaultValue);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const mapId = useSelector(userSelectors.selectedMapId);
    
    useClickAway(searchResultsRef, () => {
        setIsOpen(false);
    });
    
    useDebounce(async () => {
        if (!isOpen) {
            return;
        }
        
        setDebouncedSearchValue(searchValue);
    }, 250, [searchValue]);
    
    useEffect(() => {
        if (!debouncedSearchValue) {
            setSearchResults([]);
            return;
        }
        
        (async () => {
            setIsLoading(true);
            const { data } = await dispatch(
                stepActions.searchStepsStepCreation({
                    map_id: mapId,
                    lat: position?.lat,
                    lon: position?.lon,
                    search_term: searchValue,
                }),
            );
            
            setIsLoading(false);
            setSearchResults(data?.results || []);
        })();
    }, [debouncedSearchValue]);
    
    return (
        <div className='mx-8 my-6'>
            <h2 className='font-medium mb-1 text-black text-xs ml-2'>
                <FormattedMessage id='addStep.location' defaultMessage='Location'/>
            </h2>
            <div className='relative flex items-center'>
                <MagnifyingGlass className='absolute ml-4' size={16} color='#3A3C3F'/>
                <input
                    type='text'
                    className='full-width bg-gray-100 py-2 px-10 text-xs placeholder-gray-700 text-gray-700 rounded border-transition'
                    placeholder={intl.formatMessage({ id: 'addStep.where_place_located' })}
                    value={searchValue}
                    onFocus={() => setIsOpen(true)}
                    onChange={event => {
                        setIsOpen(true);
                        setSearchValue(event.target.value);
                    }}/>
                {isLoading &&
                    <LoadingSpinner className='absolute right-4 w-4'/>}
                {!isLoading && searchValue &&
                    <span
                        data-testid='discard-button'
                        className='absolute right-4'>
                        <X
    
                            color='#B5B7BB'
                            onClick={() => {
                                setSearchValue('');
                                setSearchResults([]);
                                onCancel();
                            }}/>
                    </span>}
            </div>
            {isOpen && <div
                className='w-full shadow-xl rounded-md max-h-64 overflow-y-auto overflow-x-hidden'
                ref={searchResultsRef}>
                {searchResults.map((result, index) => {
                    const isStepInMap = result.result_type === 'location' && result.is_step_in_map;
                    
                    return (
                        <div
                            data-testid='search-result-content'
                            key={`${result.id}-${result.result_type}-${result.is_step_in_map}-${result.place_id}-${result._id}-${result.title}`}
                            className='flex py-4 justify-around items-center cursor-pointer hover:bg-gray-100'
                            onClick={() => {
                                setSearchValue(result.title);
                                setIsOpen(false);
                                onSearchResultClick(result);
                                
                                mixpanel.track(mixpanelEvents.MAP_SEARCH_RESULT_CLICK, {
                                    [mixpanelProperties.RESULT_TYPE]: result?.type,
                                    [mixpanelProperties.RESULT_ORDER]: (index + 1).toString(),
                                });
                                mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_NAME_NAME_ADDED, {
                                    [mixpanelProperties.FLOW]: result?.type === 'area' ? 'new_location' : fromWhere,
                                    [mixpanelProperties.IS_GOOGLE_LOCATION]: result?.type === 'place',
                                });
                            }}>
                            <div className='flex items-center w-full overflow-hidden'>
                                <LocationIcon
                                    type={isStepInMap ? 'blueStep' : 'step'}
                                    className='mx-5 w-4'/>
                                <div
                                    className='w-5/6 text-ellipsis overflow-hidden whitespace-nowrap'>
                                    <h5 className={classNames('text-sm m-0 font-bold text-ellipsis overflow-hidden whitespace-nowrap', {
                                        'text-blue-600': isStepInMap,
                                    })}>{result.title}</h5>
                                    <span className='text-xs'>
                                        {result.address || result.secondary_title}
                                    </span>
                                </div>
                            </div>
                            <AddIcon
                                size={18}
                                className='mx-4'
                                type={isStepInMap ? 'arrow' : 'plus'}
                                color={isStepInMap ? '#2171ec' : '#666a70'}/>
                        </div>
                    );
                })}
            </div>}
        </div>
    );
};

export default SearchMapStepsByPosition;