const IconComment = ({ size = 16, color = '#3A3C3F' }) => (
    <svg style={{ paddingTop: '3px' }} width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M9.6929 12.3034L8.5359 14.2317C8.48037 14.3243 8.40183 14.4009 8.30791 14.4541C8.21399 14.5072 8.1079 14.5352 7.99997 14.5352C7.89204 14.5352 7.78595 14.5072 7.69203 14.4541C7.59811 14.4009 7.51957 14.3243 7.46404 14.2317L6.30703 12.3034C6.2515 12.2109 6.17296 12.1343 6.07904 12.0811C5.98512 12.0279 5.87903 12 5.7711 12H1.12497C0.959209 12 0.800238 11.9341 0.683028 11.8169C0.565817 11.6997 0.499969 11.5407 0.499969 11.375V1.375C0.499969 1.20924 0.565817 1.05027 0.683028 0.933058C0.800238 0.815848 0.959209 0.75 1.12497 0.75H14.875C15.0407 0.75 15.1997 0.815848 15.3169 0.933058C15.4341 1.05027 15.5 1.20924 15.5 1.375V11.375C15.5 11.5408 15.4341 11.6997 15.3169 11.8169C15.1997 11.9342 15.0407 12 14.875 12L10.2288 12C10.1209 12 10.0148 12.0279 9.9209 12.0811C9.82698 12.1343 9.74843 12.2109 9.6929 12.3034V12.3034Z'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default IconComment;
