import {
    OPEN_STEP_PROFILE,
    SET_SELECTED_PLACE_TO_ADD,
    SET_CURRENT_PAGE,
    SET_TEMP_PENDING_ID,
    ADD_PENDING_CHANGED_LOCATION,
    SET_OPEN_TAGS,
    EXPIRED_FORGOT_TOKEN,
    GO_TO,
} from './types';

const initialState = {
    selectedPlaceToAdd: null,
    openStepId: null,
    currentPage: 'steps-mapView',
    tempPendingId: null,
    pendingChangedLocations: {},
    openTags: false,
    expiredToken: false,
    goto: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPEN_TAGS: {
            return { ...state, openTags: action.openTags };
        }
        case SET_SELECTED_PLACE_TO_ADD: {
            return { ...state, selectedPlaceToAdd: action.selectedPlace };
        }
        case ADD_PENDING_CHANGED_LOCATION: {
            return {
                ...state,
                pendingChangedLocations: {
                    ...state.pendingChangedLocations,
                    [state.tempPendingId]: action.location,
                },
            };
        }
        case SET_TEMP_PENDING_ID: {
            return { ...state, tempPendingId: action.pendingId };
        }
        case OPEN_STEP_PROFILE: {
            return { ...state, openStepId: action.payload };
        }
        case SET_CURRENT_PAGE: {
            return { ...state, currentPage: action.currentPage };
        }
        case EXPIRED_FORGOT_TOKEN: {
            return { ...state, expiredToken: action.expiredToken };
        }
        case GO_TO: {
            return { ...state, goto: action.where };
        }
        default:
            return state;
    }
};

export default reducer;
