import React from 'react';
import styles from './task.module.scss';
import { useSelector } from 'react-redux';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import classNames from 'classnames';
import StepIcon from '../common/icons/StepIcon';
import { mapSelectors } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import copies from '../../copies.json';
import { FormattedMessage } from "react-intl";

const taskCopies = copies.insights.tasks.FiveSteps;

const FiveStepsTask = ({ onClickHandle, done }) => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const steps = useSelector(mapSelectors.steps(mapId));
    const stepsAmount = Object.values(steps).length;
    const MAX_STEPS_RENDERED = 5;
    
    return (
        <div className={classNames(styles.container)}>
            {done && <div className={styles.doneContainer}/>}
            <div className={styles.header}>
                <span className={styles.title}>
                    {taskCopies.title}
                    {done && (
                        <span className={styles.done}>
                            <img src='/assets/img/steps3Images/insights/done.svg'/>
                            {copies.insights.tasks.done}
                        </span>
                    )}
                </span>
                <span className={styles.description}>
                    <FormattedMessage id="insights.tasks.FiveSteps.description"/>
                </span>
            </div>
            <div className={styles.footer}>
                <div>
                    {!done && (
                        <CustomButton onClick={onClickHandle} className={styles.handleButton} type={ButtonType.PRIMARY_BLUE}>
                            <FormattedMessage id="insights.tasks.FiveSteps.CTA"/>
                        </CustomButton>
                    )}
                </div>
                <div className={styles.addedSteps}>
                    {[...Array(MAX_STEPS_RENDERED).keys()].map(index => (
                        <StepIcon width={'18'} height={'22'} stroke={index < stepsAmount ? '#2171EC' : '#DDDDDD'}/>
                    ))}
                    <span>
                        {stepsAmount > MAX_STEPS_RENDERED ? MAX_STEPS_RENDERED : stepsAmount}/{MAX_STEPS_RENDERED} {taskCopies.added}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FiveStepsTask;
