import React, { useRef, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { userSelectors } from '../../../redux/user';
import { mapActions } from '../../../redux/map';
import IconX from '../../common/icons/IconX';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import copiesPrefix from '../../../copies.json';

const copies = copiesPrefix.tags;

const useStyles = makeStyles(() => ({
    tooltipBlock: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        color: '#fff',
        fontSize: '15px',
        maxWidth: '210px',
        fontWeight: 'normal',
        padding: '8px 16px',
        borderRadius: '4px',
        backgroundColor: '#3a3c3f',
        '& .MuiTooltip-arrow:before': {
            backgroundColor: '#3a3c3f',
        },
    },
}));

const NewCategoryInput = ({ onClose, className }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const inputRef = useRef();
    const mapId = useSelector(userSelectors.selectedMapId);
    const tagsCategories = useSelector(state => state[mapId].tags_categories);
    const [errorDuplicate, setErrorDuplicate] = useState(false);
    const [disable, setDisable] = useState(true);

    const onChange = () => {
        const categoryName = inputRef.current?.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');

        const res = tagsCategories.find(
            item =>
                item.name
                    .replace(/^\s+|\s+$/g, '')
                    .replace(/\s+/g, ' ')
                    .toLowerCase() === categoryName.toLowerCase()
        );
        if (!res) {
            setErrorDuplicate(false);
            setDisable(true);
            if (categoryName.length > 0) {
                setDisable(false);
            } else {
                setDisable(true);
            }
        } else {
            setErrorDuplicate(true);
            setDisable(true);
        }
    };

    const handleKeyPressOnCategoryInput = e => {
        if (e.charCode === 13 && e.target.value.length !== 0 && !disable) {
            handleAddNewCategory();
        }
    };

    const handleAddNewCategory = () => {
        setDisable(true);
        let name = inputRef.current?.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');
        if (name === '') {
            return handleUnsavedCategory();
        }
        const newCat = {
            _id: inputRef.current?.value,
            priority: 0,
            name: name,
        };

        dispatch(mapActions.addNewCategories(mapId, [newCat])).then(response => {
            dispatch(mapActions.editCategoryName(mapId, response.data.categories[0]._id, newCat.name));
            // This is a hack to make sure that the server will allocate the rest of the categories an ascending priority
            dispatch(mapActions.editCategoryPriority(mapId, response.data.categories[0]._id, 0)).then(() => {
                // Get all ccategories sorted by priority from the BackEnd
                dispatch(mapActions.fetchSingleMap(mapId));
            });
        });
        onClose();
    };

    const handleUnsavedCategory = () => {
        onClose();
    };

    return (
        <div className={classNames(styles.addNewCategoryContainer, className)}>
            <div className={classNames({ [styles.enableInput]: !disable }, { [styles.disableInput]: disable })}>
                <input
                    autoFocus
                    className={styles.input}
                    ref={inputRef}
                    onChange={onChange}
                    onKeyPress={e => handleKeyPressOnCategoryInput(e)}
                    placeholder={copies.category_name_placeholder}
                />

                <button className={styles.firstButton} onClick={handleUnsavedCategory}>
                    <IconX color={disable ? '#B5B7BB' : '#777a80'} />
                </button>
                <Tooltip
                    disableHoverListener={!errorDuplicate}
                    TransitionComponent={Zoom}
                    placement='top-center'
                    arrow
                    classes={{ tooltip: classes.tooltipBlock }}
                    title={copies.category_exists}
                >
                    <button
                        className={classNames({ [styles.enable]: !disable }, { [styles.disable]: disable })}
                        onClick={!disable ? () => handleAddNewCategory(inputRef) : () => {}}
                        style={{ border: 'none' }}
                    >
                        <img style={{ marginTop: '4px' }} src='/assets/img/iconCheckWhite.svg' alt='' />
                    </button>
                </Tooltip>
            </div>
        </div>
    );
};

export default NewCategoryInput;
