import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

export const CanonicalHelmet = () => {
    const [canonicalUrl, setCanonicalUrl] = useState('');
    
    useEffect(() => {
        const host = window.location.host;
        if (host.includes('mapmanager')) {
            setCanonicalUrl('https://mapmanager.atly.com');
        } else if (host.includes('devdashboard')) {
            setCanonicalUrl('https://devdashboard.atly.com');
        }
    }, []);
    
    console.log({ canonicalUrl });
    
    return (
        <Helmet prioritizeSeoTags>
            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    );
};