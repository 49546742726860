export const paginationBulletsPosition = {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
};

export const optionsDefaultValue = {
    paginationPosition: paginationBulletsPosition.CENTER,
    loop: false,
    simulateTouch: false
}
