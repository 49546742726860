import React, { useLayoutEffect } from 'react';
import lottie from 'lottie-web';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './index.module.scss';

const ListViewLoader = () => {
    useLayoutEffect(() => {
        const animationData = {
            v: '5.7.0',
            fr: 29.9700012207031,
            ip: 0,
            op: 21.0000008553475,
            w: 1008,
            h: 921,
            nm: 'loading feed 1',
            ddd: 0,
            assets: [],
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Layer 125',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [49, 33, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [26, 6],
                                                [-26, 6],
                                                [-32, 0],
                                                [-32, 0],
                                                [-26, -6],
                                                [26, -6],
                                                [32, 0],
                                                [32, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Layer 124',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [274, 33, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [26, 6],
                                                [-26, 6],
                                                [-32, 0],
                                                [-32, 0],
                                                [-26, -6],
                                                [26, -6],
                                                [32, 0],
                                                [32, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Layer 123',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [455, 33, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [26, 6],
                                                [-26, 6],
                                                [-32, 0],
                                                [-32, 0],
                                                [-26, -6],
                                                [26, -6],
                                                [32, 0],
                                                [32, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Layer 122',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [659, 33, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [26, 6],
                                                [-26, 6],
                                                [-32, 0],
                                                [-32, 0],
                                                [-26, -6],
                                                [26, -6],
                                                [32, 0],
                                                [32, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Layer 121',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 33, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Layer 120',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 872.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Layer 119',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 872.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Layer 118',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 881.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Layer 117',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 860.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Layer 116',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 882.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Layer 115',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 861.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Layer 114',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 883.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 13,
                    ty: 4,
                    nm: 'Layer 113',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 862.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 14,
                    ty: 4,
                    nm: 'Layer 112',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 872.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 15,
                    ty: 4,
                    nm: 'Layer 111',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 872.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 16,
                    ty: 4,
                    nm: 'Layer 110',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 800.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 17,
                    ty: 4,
                    nm: 'Layer 109',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 800.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 18,
                    ty: 4,
                    nm: 'Layer 108',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 809.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 19,
                    ty: 4,
                    nm: 'Layer 107',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 788.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 20,
                    ty: 4,
                    nm: 'Layer 106',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 810.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 21,
                    ty: 4,
                    nm: 'Layer 105',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 789.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 22,
                    ty: 4,
                    nm: 'Layer 104',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 811.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 23,
                    ty: 4,
                    nm: 'Layer 103',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 790.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 24,
                    ty: 4,
                    nm: 'Layer 102',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 800.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 25,
                    ty: 4,
                    nm: 'Layer 101',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 800.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 26,
                    ty: 4,
                    nm: 'Layer 100',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 728.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 27,
                    ty: 4,
                    nm: 'Layer 99',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 728.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 28,
                    ty: 4,
                    nm: 'Layer 98',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 737.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 29,
                    ty: 4,
                    nm: 'Layer 97',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 716.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 30,
                    ty: 4,
                    nm: 'Layer 96',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 738.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 31,
                    ty: 4,
                    nm: 'Layer 95',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 717.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 32,
                    ty: 4,
                    nm: 'Layer 94',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 739.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 33,
                    ty: 4,
                    nm: 'Layer 93',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 718.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 34,
                    ty: 4,
                    nm: 'Layer 92',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 728.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 35,
                    ty: 4,
                    nm: 'Layer 91',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 728.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 36,
                    ty: 4,
                    nm: 'Layer 90',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 656.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 37,
                    ty: 4,
                    nm: 'Layer 89',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 656.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 38,
                    ty: 4,
                    nm: 'Layer 88',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 665.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 39,
                    ty: 4,
                    nm: 'Layer 87',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 644.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 40,
                    ty: 4,
                    nm: 'Layer 86',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 666.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 41,
                    ty: 4,
                    nm: 'Layer 85',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 645.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 42,
                    ty: 4,
                    nm: 'Layer 84',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 667.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 43,
                    ty: 4,
                    nm: 'Layer 83',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 646.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 44,
                    ty: 4,
                    nm: 'Layer 82',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 656.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 45,
                    ty: 4,
                    nm: 'Layer 81',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 656.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 46,
                    ty: 4,
                    nm: 'Layer 80',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 584.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 47,
                    ty: 4,
                    nm: 'Layer 79',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 584.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 48,
                    ty: 4,
                    nm: 'Layer 78',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 593.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 49,
                    ty: 4,
                    nm: 'Layer 77',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 572.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 50,
                    ty: 4,
                    nm: 'Layer 76',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 594.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 51,
                    ty: 4,
                    nm: 'Layer 75',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 573.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 52,
                    ty: 4,
                    nm: 'Layer 74',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 595.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 53,
                    ty: 4,
                    nm: 'Layer 73',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 574.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 54,
                    ty: 4,
                    nm: 'Layer 72',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 584.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 55,
                    ty: 4,
                    nm: 'Layer 71',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 584.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 56,
                    ty: 4,
                    nm: 'Layer 70',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 512.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 57,
                    ty: 4,
                    nm: 'Layer 69',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 512.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 58,
                    ty: 4,
                    nm: 'Layer 68',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 521.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 59,
                    ty: 4,
                    nm: 'Layer 67',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 500.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 60,
                    ty: 4,
                    nm: 'Layer 66',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 522.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 61,
                    ty: 4,
                    nm: 'Layer 65',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 501.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 62,
                    ty: 4,
                    nm: 'Layer 64',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 523.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 63,
                    ty: 4,
                    nm: 'Layer 63',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 502.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 64,
                    ty: 4,
                    nm: 'Layer 62',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 512.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 65,
                    ty: 4,
                    nm: 'Layer 61',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 512.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 66,
                    ty: 4,
                    nm: 'Layer 60',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 440.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 67,
                    ty: 4,
                    nm: 'Layer 59',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 440.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 68,
                    ty: 4,
                    nm: 'Layer 58',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 449.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 69,
                    ty: 4,
                    nm: 'Layer 57',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 428.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 70,
                    ty: 4,
                    nm: 'Layer 56',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 450.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 71,
                    ty: 4,
                    nm: 'Layer 55',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 429.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 72,
                    ty: 4,
                    nm: 'Layer 54',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 451.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 73,
                    ty: 4,
                    nm: 'Layer 53',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 430.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 74,
                    ty: 4,
                    nm: 'Layer 52',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 440.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 75,
                    ty: 4,
                    nm: 'Layer 51',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 440.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 76,
                    ty: 4,
                    nm: 'Layer 50',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 368.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 77,
                    ty: 4,
                    nm: 'Layer 49',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 368.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 78,
                    ty: 4,
                    nm: 'Layer 48',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 377.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 79,
                    ty: 4,
                    nm: 'Layer 47',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 356.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 80,
                    ty: 4,
                    nm: 'Layer 46',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 378.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 81,
                    ty: 4,
                    nm: 'Layer 45',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 357.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 82,
                    ty: 4,
                    nm: 'Layer 44',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 379.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 83,
                    ty: 4,
                    nm: 'Layer 43',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 358.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 84,
                    ty: 4,
                    nm: 'Layer 42',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 368.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 85,
                    ty: 4,
                    nm: 'Layer 41',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 368.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 86,
                    ty: 4,
                    nm: 'Layer 40',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 296.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 87,
                    ty: 4,
                    nm: 'Layer 39',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 296.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 88,
                    ty: 4,
                    nm: 'Layer 38',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 305.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 89,
                    ty: 4,
                    nm: 'Layer 37',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 284.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 90,
                    ty: 4,
                    nm: 'Layer 36',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 306.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 91,
                    ty: 4,
                    nm: 'Layer 35',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 285.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 92,
                    ty: 4,
                    nm: 'Layer 34',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 307.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 93,
                    ty: 4,
                    nm: 'Layer 33',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 286.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 94,
                    ty: 4,
                    nm: 'Layer 32',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 296.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 95,
                    ty: 4,
                    nm: 'Layer 31',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 296.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 96,
                    ty: 4,
                    nm: 'Layer 30',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 224.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 97,
                    ty: 4,
                    nm: 'Layer 29',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 224.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 98,
                    ty: 4,
                    nm: 'Layer 28',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 233.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 99,
                    ty: 4,
                    nm: 'Layer 27',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 212.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 100,
                    ty: 4,
                    nm: 'Layer 26',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 234.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 101,
                    ty: 4,
                    nm: 'Layer 25',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 213.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 102,
                    ty: 4,
                    nm: 'Layer 24',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 235.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 103,
                    ty: 4,
                    nm: 'Layer 23',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 214.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 104,
                    ty: 4,
                    nm: 'Layer 22',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 224.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 105,
                    ty: 4,
                    nm: 'Layer 21',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 224.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 106,
                    ty: 4,
                    nm: 'Layer 20',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 152.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 107,
                    ty: 4,
                    nm: 'Layer 19',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 152.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 108,
                    ty: 4,
                    nm: 'Layer 18',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 161.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 109,
                    ty: 4,
                    nm: 'Layer 17',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 140.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 110,
                    ty: 4,
                    nm: 'Layer 16',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 162.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 111,
                    ty: 4,
                    nm: 'Layer 15',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 141.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 112,
                    ty: 4,
                    nm: 'Layer 14',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 163.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 113,
                    ty: 4,
                    nm: 'Layer 13',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 142.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 114,
                    ty: 4,
                    nm: 'Layer 12',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 152.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 115,
                    ty: 4,
                    nm: 'Layer 11',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 152.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 116,
                    ty: 4,
                    nm: 'Layer 10',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [912.5, 80.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -8.56],
                                                [8.56, 0],
                                                [0, 8.56],
                                                [-8.56, 0],
                                            ],
                                            o: [
                                                [0, 8.56],
                                                [-8.56, 0],
                                                [0, -8.56],
                                                [8.56, 0],
                                            ],
                                            v: [
                                                [15.5, 0],
                                                [0, 15.5],
                                                [-15.5, 0],
                                                [0, -15.5],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 117,
                    ty: 4,
                    nm: 'Layer 9',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [835.5, 80.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.314, 0],
                                                [0, 0],
                                                [0, -3.314],
                                                [0, 0],
                                                [3.314, 0],
                                                [0, 0],
                                                [0, 3.314],
                                            ],
                                            v: [
                                                [18.5, 6],
                                                [-18.5, 6],
                                                [-24.5, 0],
                                                [-24.5, 0],
                                                [-18.5, -6],
                                                [18.5, -6],
                                                [24.5, 0],
                                                [24.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 118,
                    ty: 4,
                    nm: 'Layer 8',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [681.5, 89.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 119,
                    ty: 4,
                    nm: 'Layer 7',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [702, 68.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 120,
                    ty: 4,
                    nm: 'Layer 6',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [476.5, 90.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 121,
                    ty: 4,
                    nm: 'Layer 5',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [497, 69.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 122,
                    ty: 4,
                    nm: 'Layer 4',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [298.5, 91.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [47, 8.5],
                                                [-47, 8.5],
                                                [-55.5, 0],
                                                [-55.5, 0],
                                                [-47, -8.5],
                                                [47, -8.5],
                                                [55.5, 0],
                                                [55.5, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 123,
                    ty: 4,
                    nm: 'Layer 3',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [319, 70.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [67.5, 8.5],
                                                [-67.5, 8.5],
                                                [-76, 0],
                                                [-76, 0],
                                                [-67.5, -8.5],
                                                [67.5, -8.5],
                                                [76, 0],
                                                [76, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 124,
                    ty: 4,
                    nm: 'Layer 2',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [132, 80.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.694, 0],
                                                [0, 0],
                                                [0, -4.694],
                                                [0, 0],
                                                [4.694, 0],
                                                [0, 0],
                                                [0, 4.694],
                                            ],
                                            v: [
                                                [36.5, 8.5],
                                                [-36.5, 8.5],
                                                [-45, 0],
                                                [-45, 0],
                                                [-36.5, -8.5],
                                                [36.5, -8.5],
                                                [45, 0],
                                                [45, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 125,
                    ty: 4,
                    nm: 'Layer 1',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [47, 80.875, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-4.965, 0],
                                                [0, 0],
                                                [0, -4.965],
                                                [0, 0],
                                                [4.965, 0],
                                                [0, 0],
                                                [0, 4.965],
                                            ],
                                            v: [
                                                [21.009, 22.5],
                                                [-21.009, 22.5],
                                                [-30, 13.509],
                                                [-30, -13.509],
                                                [-21.009, -22.5],
                                                [21.009, -22.5],
                                                [30, -13.509],
                                                [30, 13.509],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 8, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [0, 1, 1, 1, 0.25, 0.659, 0.659, 0.659, 0.501, 0.319, 0.319, 0.319, 0.75, 0.659, 0.659, 0.659, 1, 1, 1, 1],
                                            ix: 9,
                                        },
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-270, 0],
                                                to: [73.833, 0],
                                                ti: [-73.833, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [173, 0],
                                            },
                                        ],
                                        ix: 5,
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-169, 0],
                                                to: [98.5, 0],
                                                ti: [-98.5, 0],
                                            },
                                            {
                                                t: 20.0000008146167,
                                                s: [422, 0],
                                            },
                                        ],
                                        ix: 6,
                                    },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 126,
                    ty: 4,
                    nm: 'Layer 125 :M',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [472.5, 461.188, 0], ix: 2 },
                        a: { a: 0, k: [472.5, 461.188, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [26, 6],
                                                        [-26, 6],
                                                        [-32, 0],
                                                        [-32, 0],
                                                        [-26, -6],
                                                        [26, -6],
                                                        [32, 0],
                                                        [32, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [49, 33], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 125',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [26, 6],
                                                        [-26, 6],
                                                        [-32, 0],
                                                        [-32, 0],
                                                        [-26, -6],
                                                        [26, -6],
                                                        [32, 0],
                                                        [32, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [274, 33], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 124',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [26, 6],
                                                        [-26, 6],
                                                        [-32, 0],
                                                        [-32, 0],
                                                        [-26, -6],
                                                        [26, -6],
                                                        [32, 0],
                                                        [32, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [455, 33], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 123',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 3,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [26, 6],
                                                        [-26, 6],
                                                        [-32, 0],
                                                        [-32, 0],
                                                        [-26, -6],
                                                        [26, -6],
                                                        [32, 0],
                                                        [32, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [659, 33], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 122',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 4,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 33], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 121',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 5,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 872.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 120',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 6,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 872.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 119',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 7,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 881.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 118',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 8,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 860.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 117',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 9,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 882.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 116',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 10,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 861.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 115',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 11,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 883.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 114',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 12,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 862.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 113',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 13,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 872.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 112',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 14,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 872.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 111',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 15,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 800.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 110',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 16,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 800.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 109',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 17,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 809.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 108',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 18,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 788.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 107',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 19,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 810.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 106',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 20,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 789.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 105',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 21,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 811.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 104',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 22,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 790.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 103',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 23,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 800.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 102',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 24,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 800.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 101',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 25,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 728.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 100',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 26,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 728.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 99',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 27,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 737.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 98',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 28,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 716.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 97',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 29,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 738.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 96',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 30,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 717.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 95',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 31,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 739.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 94',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 32,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 718.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 93',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 33,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 728.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 92',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 34,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 728.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 91',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 35,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 656.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 90',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 36,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 656.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 89',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 37,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 665.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 88',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 38,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 644.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 87',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 39,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 666.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 86',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 40,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 645.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 85',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 41,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 667.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 84',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 42,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 646.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 83',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 43,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 656.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 82',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 44,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 656.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 81',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 45,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 584.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 80',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 46,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 584.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 79',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 47,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 593.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 78',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 48,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 572.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 77',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 49,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 594.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 76',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 50,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 573.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 75',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 51,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 595.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 74',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 52,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 574.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 73',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 53,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 584.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 72',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 54,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 584.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 71',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 55,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 512.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 70',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 56,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 512.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 69',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 57,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 521.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 68',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 58,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 500.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 67',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 59,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 522.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 66',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 60,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 501.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 65',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 61,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 523.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 64',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 62,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 502.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 63',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 63,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 512.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 62',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 64,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 512.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 61',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 65,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 440.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 60',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 66,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 440.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 59',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 67,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 449.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 58',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 68,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 428.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 57',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 69,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 450.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 56',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 70,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 429.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 55',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 71,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 451.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 54',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 72,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 430.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 53',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 73,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 440.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 52',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 74,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 440.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 51',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 75,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 368.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 50',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 76,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 368.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 49',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 77,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 377.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 48',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 78,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 356.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 47',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 79,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 378.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 46',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 80,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 357.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 45',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 81,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 379.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 44',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 82,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 358.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 43',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 83,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 368.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 42',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 84,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 368.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 41',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 85,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 296.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 40',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 86,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 296.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 39',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 87,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 305.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 38',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 88,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 284.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 37',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 89,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 306.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 36',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 90,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 285.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 35',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 91,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 307.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 34',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 92,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 286.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 33',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 93,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 296.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 32',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 94,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 296.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 31',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 95,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 224.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 30',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 96,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 224.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 29',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 97,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 233.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 28',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 98,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 212.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 27',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 99,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 234.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 26',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 100,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 213.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 25',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 101,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 235.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 24',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 102,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 214.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 23',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 103,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 224.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 22',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 104,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 224.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 21',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 105,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 152.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 20',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 106,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 152.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 19',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 107,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 161.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 18',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 108,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 140.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 17',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 109,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 162.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 16',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 110,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 141.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 15',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 111,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 163.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 14',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 112,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 142.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 13',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 113,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 152.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 12',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 114,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 152.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 11',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 115,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                    ],
                                                    o: [
                                                        [0, 8.56],
                                                        [-8.56, 0],
                                                        [0, -8.56],
                                                        [8.56, 0],
                                                    ],
                                                    v: [
                                                        [15.5, 0],
                                                        [0, 15.5],
                                                        [-15.5, 0],
                                                        [0, -15.5],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [912.5, 80.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 10',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 116,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-3.314, 0],
                                                        [0, 0],
                                                        [0, -3.314],
                                                        [0, 0],
                                                        [3.314, 0],
                                                        [0, 0],
                                                        [0, 3.314],
                                                    ],
                                                    v: [
                                                        [18.5, 6],
                                                        [-18.5, 6],
                                                        [-24.5, 0],
                                                        [-24.5, 0],
                                                        [-18.5, -6],
                                                        [18.5, -6],
                                                        [24.5, 0],
                                                        [24.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [835.5, 80.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 9',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 117,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [681.5, 89.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 8',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 118,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [702, 68.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 7',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 119,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [476.5, 90.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 6',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 120,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [497, 69.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 5',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 121,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [47, 8.5],
                                                        [-47, 8.5],
                                                        [-55.5, 0],
                                                        [-55.5, 0],
                                                        [-47, -8.5],
                                                        [47, -8.5],
                                                        [55.5, 0],
                                                        [55.5, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [298.5, 91.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 4',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 122,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [67.5, 8.5],
                                                        [-67.5, 8.5],
                                                        [-76, 0],
                                                        [-76, 0],
                                                        [-67.5, -8.5],
                                                        [67.5, -8.5],
                                                        [76, 0],
                                                        [76, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.882352948189, 0.89411765337, 0.92549020052, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [319, 70.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 3',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 123,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.694, 0],
                                                        [0, 0],
                                                        [0, -4.694],
                                                        [0, 0],
                                                        [4.694, 0],
                                                        [0, 0],
                                                        [0, 4.694],
                                                    ],
                                                    v: [
                                                        [36.5, 8.5],
                                                        [-36.5, 8.5],
                                                        [-45, 0],
                                                        [-45, 0],
                                                        [-36.5, -8.5],
                                                        [36.5, -8.5],
                                                        [45, 0],
                                                        [45, 0],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.639215707779, 0.654901981354, 0.694117665291, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [132, 80.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 2',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 124,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'gr',
                                    it: [
                                        {
                                            ind: 0,
                                            ty: 'sh',
                                            ix: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [-4.965, 0],
                                                        [0, 0],
                                                        [0, -4.965],
                                                        [0, 0],
                                                        [4.965, 0],
                                                        [0, 0],
                                                        [0, 4.965],
                                                    ],
                                                    v: [
                                                        [21.009, 22.5],
                                                        [-21.009, 22.5],
                                                        [-30, 13.509],
                                                        [-30, -13.509],
                                                        [-21.009, -22.5],
                                                        [21.009, -22.5],
                                                        [30, -13.509],
                                                        [30, 13.509],
                                                    ],
                                                    c: true,
                                                },
                                                ix: 2,
                                            },
                                            nm: 'Path 1',
                                            mn: 'ADBE Vector Shape - Group',
                                            hd: false,
                                        },
                                        {
                                            ty: 'fl',
                                            c: {
                                                a: 0,
                                                k: [0.764705896378, 0.780392169952, 0.827450990677, 1],
                                                ix: 4,
                                            },
                                            o: { a: 0, k: 100, ix: 5 },
                                            r: 1,
                                            bm: 0,
                                            nm: 'Fill 1',
                                            mn: 'ADBE Vector Graphic - Fill',
                                            hd: false,
                                        },
                                        {
                                            ty: 'tr',
                                            p: { a: 0, k: [0, 0], ix: 2 },
                                            a: { a: 0, k: [0, 0], ix: 1 },
                                            s: { a: 0, k: [100, 100], ix: 3 },
                                            r: { a: 0, k: 0, ix: 6 },
                                            o: { a: 0, k: 100, ix: 7 },
                                            sk: { a: 0, k: 0, ix: 4 },
                                            sa: { a: 0, k: 0, ix: 5 },
                                            nm: 'Transform',
                                        },
                                    ],
                                    nm: 'Group 1',
                                    np: 2,
                                    cix: 2,
                                    bm: 0,
                                    ix: 1,
                                    mn: 'ADBE Vector Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [47, 80.875], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Layer 1',
                            np: 1,
                            cix: 2,
                            bm: 0,
                            ix: 125,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 900.000036657751,
                    st: 0,
                    bm: 0,
                },
            ],
            markers: [],
        };
        const params = {
            container: document.getElementById('lottieListViewStep'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData,
        };
        lottie.loadAnimation(params);
    }, []);

    return (
        <div className={styles.lottieBlockAbsolute}>
            {/* <div className={styles.lottie} id='lottieListViewStep' /> */}
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>
                            <Skeleton animation='wave' className={styles.firstHeaderText} variant='text' />
                        </th>
                        <th />
                        <th>
                            <Skeleton animation='wave' className={styles.headerText} variant='text' />
                        </th>
                        <th>
                            <Skeleton animation='wave' className={styles.headerText} variant='text' />
                        </th>
                        <th>
                            <Skeleton animation='wave' className={styles.headerText} variant='text' />
                        </th>
                        <th>
                            <Skeleton animation='wave' className={styles.headerText} variant='text' />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <Skeleton className={styles.skeleton} animation='wave' height={77} width={57} />
                            </div>
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.titleBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton animation='wave' className={styles.textBlock} variant='text' />
                            <Skeleton animation='wave' className={styles.shortTextBlock} variant='text' />
                        </td>
                        <td>
                            <Skeleton className={styles.statusBlock} animation='wave' variant='text' />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ListViewLoader;
