import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { noop } from 'lodash';
import config from '../../config';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/circleLoader.json';
import { mapSelectors, mapActions } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import SearchInput from '../common/inputs/BoxInput';
import SearchResult, { iconType } from '../common/buttons/SearchResult';
import { useDebounce } from '../../hooks';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import { userActionActions as userAction, userActionActions } from '../../redux/userAction';
import { stepActions, stepSelectors } from '../../redux/step';
import ConfirmModal from '../ExitModal';
import copiesPrefix from '../../copies.json';
import useSearchCoordinates from './../../hooks/useSearchCoordinates';
import { CoordinatesSearchResults } from '../CoordinatesSearchResults';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import Routes from '../../config/routes';

const copies = copiesPrefix.addLocation.createStep;

let isSearchResultProcessed = false;

const CreatePlace = ({ isChangingLocation = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        state: { placeName, instantCreateStep },
    } = location;
    const [placeNameState, setPlaceNameState] = useState(placeName || '');
    const [locationSearch, setLocationSearch] = useState('');
    const coordinates = useSearchCoordinates(locationSearch);
    let [placeResults, setPlaceResults] = useState([]);
    let [addressResults, setAddressResults] = useState([]);
    const locationSearchDebounce = useDebounce(locationSearch, 750);
    const mapId = useSelector(userSelectors.selectedMapId);
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
    const ghostStep = useSelector(mapSelectors.ghostStep(mapId));
    const GLOBE_TYPES = ['street_address', 'route', 'locality', 'country'];
    const changeLocationId = useSelector(stepSelectors.changeLocationId);
    const changeLocationShow = useSelector(stepSelectors.changeLocationShow);
    const [openDropdown, setDropdown] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [isCreatingStep, setCreatingStep] = useState(false);
    const { setIsMapClean, setDisableClickOnMap, pendingStep: pendingPost, step = null, createStep = noop, isGooglePlace = false } = useOutletContext();

    useEffect(() => {
        if (!ghostStep) setLocationSearch('');
    }, [ghostStep]);

    useEffect(() => {
        if (locationSearch === '') setPlaceResults([]);
        setAddressResults([]);
    }, [locationSearch]);

    const onClickPrev = () => {
        setDisableClickOnMap(true);
        dispatch(mapActions.setGhostStep(mapId, null));
        navigate(-1);
    };

    const onClickDone = place => {
        mixpanel.track(mixpanelEvents.ADD_LOCATION_DONE, {
            [mixpanelProperties.FROM_WHERE]: pendingPost ? 'imported' : 'step_page',
            [mixpanelProperties.RESULT_TYPE]: 'pin_map',
        });
        if (pendingPost) {
            const tempPendingPosts = { ...pendingPost };
            tempPendingPosts.lat = place.position.lat;
            tempPendingPosts.lon = place.position.lon || place.position.lng;
            tempPendingPosts.location_name = place.title;
            tempPendingPosts.is_location_specific = true;
            dispatch(mapActions.setPedningPostCache(mapId, tempPendingPosts));
            dispatch(mapActions.setGhostStep(mapId, null));
            navigate('/' + Routes.MAP);
            setIsMapClean(false);
            setDisableClickOnMap(true);
        } else if (step._id) {
            dispatch(
                mapActions.editStepLocation(mapId, {
                    stepId: step._id,
                    title: place.title,
                    lat: place.position.lat,
                    lon: place.position.lon || place.position.lng,
                })
            );
            dispatch(mapActions.setGhostStep(mapId, null));
            navigate('/' + Routes.MAP);
            setIsMapClean(false);
            setDisableClickOnMap(true);
        } else if (instantCreateStep) {
            createStep({
                lat: place.position.lat || place.position[0],
                lon: place.position.lon || place.position.lng || place.position[1],
                title: place.title,
            });
        } else {
            localStorage.setItem('createNewPlace', 'yes');
            setDisableClickOnMap(true);
            dispatch(userActionActions.setSelectedPlaceToAdd(place));
            navigate(-2);
        }
    };

    useEffect(() => {
        (async () => {
            if (locationSearchDebounce === '') {
                return;
            }

            const accessToken = localStorage.getItem('at');
            const queryParams = {
                lat: mapCenter?.position?.lat,
                lon: mapCenter?.position?.lng,
                map_id: mapId,
                term: locationSearchDebounce,
                data_type: ['place', 'address'],
            };
            const query = queryString.stringify(queryParams);
            let response = await fetch(`${config.domain.address}/steps3/search?${query}`, {
                headers: {
                    Authorization: accessToken,
                    app_version: config.version.path,
                    app_platform: 'map_manager',
                },
            });
            response = await response.json();

            if (response?.results?.length > 0) {
                const foundedPlaces = response.results
                    .filter(val => val['result_type'] === 'place')
                    ?.filter(val => !GLOBE_TYPES.some(item => val?.types?.includes(item)));
                const foundedAddress = response.results.filter(val => val['result_type'] === 'address');

                if (foundedPlaces.length > 0 || foundedAddress.length > 0) {
                    setDropdown(true);
                }

                setPlaceResults(foundedPlaces);
                setAddressResults(foundedAddress);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationSearchDebounce]);

    useEffect(() => {
        if (coordinates.lat && coordinates.lon) {
            setDropdown(true);
            setAddressResults([]);
        }
    }, [coordinates.lat, coordinates.lon]);

    const getPlaceCoordinates = async place => {
        const accessToken = localStorage.getItem('at');
        let response = await fetch(`${config.domain.address}/steps3/search/placeidtocoordinate?place_id=${place['place_id']}`, {
            headers: {
                Authorization: accessToken,
                app_version: config.version.path,
                app_platform: 'map_manager',
            },
        });
        response = await response.json();
        place.location = response.geometry.location;
        return place;
    };

    const onClickingAddress = async place => {
        await getPlaceCoordinates(place);
        dispatch(
            mapActions.setGhostStep(mapId, {
                position: place.location,
                title: place?.title || place?.description.split(', ')[0],
                subTitle: place.terms ? place.terms[1]?.value : '',
                suggestToAdd: false,
                isOpenTooltip: true,
                isStickToMap: false,
            })
        );
        dispatch(
            mapActions.setMapCenter(mapId, {
                position: place.location,
                zoom: 18,
            })
        );
        setDropdown(false);
    };

    const onClickingPlace = location => {
        dispatch(
            mapActions.setGhostStep(mapId, {
                position: [location?.lat || location?.location.lat, location?.lon || location?.location.lng],
                title: location?.title || location?.split(', ')[0],
                subTitle: location.terms ? location.terms[1]?.value : '',
                suggestToAdd: false,
                isOpenTooltip: true,
                isStickToMap: false,
            })
        );
        dispatch(
            mapActions.setMapCenter(mapId, {
                position: [location?.lat || location?.location.lat, location?.lon || location?.location.lng],
                zoom: 18,
            })
        );
        setDropdown(false);
    };

    const selectChangeLocation = ({ title, position, type }) => {
        const result = {
            title,
            position,
            description: title,
            location: position,
            type,
            id: changeLocationId,
        };
        dispatch(stepActions.setChangeLocation({ data: result }));
        dispatch(userActionActions.addPendingChangedLocation(result));
        dispatch(userAction.setCurrentPage('steps-pendingView'));
    };

    return (
        <>
            {modalData && (
                <ConfirmModal
                    tertiaryText={'Cancel'}
                    textDanger={'Continue'}
                    text='Are you sure you want to change this location?'
                    subText={
                        'Changing the location will remove information about this place that was obtained from Google (ex: website, telephone number, photos, etc.)'
                    }
                    onCancel={() => setModalData(false)}
                    onDiscard={
                        changeLocationShow
                            ? () =>
                                  selectChangeLocation({
                                      title: placeNameState,
                                      position: ghostStep.position,
                                      type: 'place',
                                  })
                            : () => {
                                  onClickDone &&
                                      onClickDone({
                                          title: placeNameState,
                                          position: ghostStep.position,
                                          type: 'place',
                                      });
                                  localStorage.setItem('createNewPlace', '');
                              }
                    }
                />
            )}
            <div className={styles.container}>
                <div className={styles.top}>
                    <img data-cy='back-button' className={styles.previous} src='/assets/img/icon-arrow-left.svg' onClick={onClickPrev} alt='back button' />
                    <span className={styles.title}>{copies.title}</span>
                </div>
                <div className={styles.boxes}>
                    <div className={styles.box}>
                        <span className={styles.subTitle}>{copies.fields.name.title}</span>
                        <SearchInput
                            className={styles.inputBox}
                            placeholder={copies.fields.name.placeholder}
                            onChange={e => setPlaceNameState(e.target.value)}
                            value={placeNameState}
                            onClickCancel={() => setPlaceNameState('')}
                        />
                    </div>
                    <div className={styles.box}>
                        <span className={styles.subTitle}>{copies.fields.location.title}</span>
                        <SearchInput
                            className={styles.inputBox}
                            placeholder={copies.fields.location.placeholder}
                            onChange={e => setLocationSearch(!ghostStep ? e.target.value : '')}
                            value={ghostStep?.title || locationSearch}
                            onClickCancel={() => {
                                if (ghostStep) {
                                    dispatch(mapActions.setGhostStep(mapId, null));
                                } else {
                                    setLocationSearch('');
                                    setDropdown(false);
                                }
                            }}
                        />
                        {openDropdown && (
                            <div className={styles.dropdown}>
                                <CoordinatesSearchResults longitute={coordinates.lon} latitude={coordinates.lat} onClick={() => setDropdown(false)} />
                                {addressResults?.map((result, idx) => (
                                    <SearchResult
                                        idx={idx}
                                        type={'street_num'}
                                        key={result['place_id']}
                                        iconSrc={iconType.globe}
                                        content={result.description?.split(', ')[0] || result.title?.split(', ')[0]}
                                        subContent={result.description?.split(', ')[1] || result.title?.split(', ')[1]}
                                        onClick={() => onClickingAddress(result)}
                                    />
                                ))}
                                {placeResults?.map((result, idx) => (
                                    <SearchResult
                                        idx={idx}
                                        type={'google_place'}
                                        key={result._id}
                                        iconSrc={iconType.step}
                                        content={result?.title || result?.description}
                                        subContent={result.terms ? result.terms[1]?.value : ''}
                                        onClick={async () => {
                                            if (isSearchResultProcessed) return;
                                            isSearchResultProcessed = true;
                                            if (!result?.location) {
                                                await getPlaceCoordinates(result);
                                            }
                                            onClickingPlace(result);
                                            isSearchResultProcessed = false;
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {(addressResults?.length === 0 || placeResults?.length === 0 || ghostStep) && (
                    <div className={styles.footer}>
                        <CustomButton
                            size={'largeSize'}
                            type={ButtonType.PRIMARY_BLUE}
                            disabled={!(placeNameState !== '' && ghostStep)}
                            onClick={() =>
                                isChangingLocation && isGooglePlace
                                    ? setModalData(true)
                                    : changeLocationShow
                                    ? selectChangeLocation({
                                          title: placeNameState,
                                          position: ghostStep.position,
                                          type: 'place',
                                      })
                                    : (function () {
                                          onClickDone &&
                                              onClickDone({
                                                  title: placeNameState,
                                                  position: ghostStep.position,
                                                  type: 'place',
                                              });
                                          instantCreateStep && setCreatingStep(true);
                                          localStorage.setItem('createNewPlace', '');
                                      })()
                            }
                            text={
                                instantCreateStep && isCreatingStep ? (
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: loaderAnimation,
                                        }}
                                        isStopped={false}
                                        isPaused={false}
                                        width={40}
                                        height={40}
                                    />
                                ) : (
                                    copies.done_button
                                )
                            }
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CreatePlace;
