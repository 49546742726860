import React from 'react';

const Policy = () => (
    <table border='0' align='center' width='800'>
        <tr>
            <td width='50%'>
                <p>
                    <strong>STEPS</strong> <strong>PRIVACY POLICY </strong>
                </p>
                <p>
                    <em>
                        Last Updated: August 1<span>8</span>, 2019
                    </em>
                </p>
                <p>
                    This privacy policy (&ldquo;
                    <strong>Privacy Policy</strong>
                    &rdquo;) governs how we, Steps Solutions Ltd. (together, &ldquo;
                    <strong>STEPS</strong>
                    &rdquo; &ldquo;
                    <strong>we</strong>
                    &rdquo;, &ldquo;
                    <strong>our</strong>
                    &rdquo; or &ldquo;
                    <strong>us</strong>
                    &rdquo;) use, collect and store Personal Data we collect or receive from or about you (&ldquo;
                    <strong>User</strong>
                    &rdquo;, &ldquo;
                    <strong>you</strong>
                    &rdquo;) such as in the following use cases:
                </p>
                <ul>
                    <li>
                        When you make use of the our App STEPS ("
                        <strong>STEPS</strong>
                        ")
                        <ol>
                            <li>When you create an account</li>
                            <li>When you create content and post it (&ldquo;Step&rdquo;) in our App</li>
                            <li>When you make a comment in a Step</li>
                            <li>When you add content to an existing Step</li>
                            <li>When you make a search of Steps in STEPS&rsquo; App</li>
                            <li>When you share content in our App and/or in social media channels</li>
                            <li>When you create a Map in our App STEPS (e.g., Public Map, Restricted Map, Community Map, Secret Map)</li>
                            <li>When you create content and post it in a specific Map in our App STEPS</li>
                        </ol>
                    </li>
                    <li>
                        When you browse or visit our website,
                        <a href='https://www.steps.me/'>https://www.steps.me/</a> (&ldquo;
                        <strong>Website</strong>
                        &rdquo;);
                    </li>
                    <li>
                        When you make use of, or interact with, our Website
                        <ol>
                            <li>When you subscribe to the email list / newsletters / blog</li>
                            <li>When you contact us (e.g. customer support)</li>
                        </ol>
                    </li>
                    <li>When you exchange business cards with us</li>
                    <li>When we use the Personal Data of our service providers</li>
                    <li>When we use the Personal Data of our customers</li>
                    <li>When you interact with us on our social media profiles (e.g., Facebook, Instagram, Twitter, LinkedIn)</li>
                </ul>
                <p>
                    We greatly respect your privacy, which is why we make every effort to provide a platform that would live up to the highest of user privacy
                    standards. Please read this Privacy Policy carefully, so you can fully understand our practices in relation to Personal Data. &ldquo;
                    <strong>Personal Data</strong>
                    &rdquo; means any information that can be used, alone or together with other data, to uniquely identify any living human being. Please note
                    that this is a master privacy policy and some of its provisions only apply to individuals in certain jurisdictions. For example, the legal
                    basis in the table below is only relevant for GDPR-protected individuals.
                </p>
                <p>
                    <strong>
                        <u>Table of contents</u>
                    </strong>
                    :
                </p>
                <ol>
                    <li>What information we collect, why we collect it, and how it is used</li>
                    <li>How we protect and store your Personal Data</li>
                    <li>How we share your Personal Data</li>
                    <li>Additional information regarding transfers of Personal Data</li>
                    <li>Your rights</li>
                    <li>Use by children</li>
                    <li>Public information about your activity on the services</li>
                    <li>How can I delete my account?</li>
                    <li>Links to and interaction with third party product</li>
                    <li>Log files</li>
                    <li>Cookies and other tracking technologies</li>
                    <li>Use of Analytics Tools</li>
                    <li>California privacy rights</li>
                    <li>Our California do not track notice</li>
                    <li>Deletion of content from California residents</li>
                    <li>How to contact us</li>
                </ol>
                <p>&nbsp;</p>
                <p>
                    This Privacy Policy may be updated from time to time and therefore we ask you to check back periodically for the latest version of the
                    Privacy Policy, as indicated below. &nbsp;If there will be any significant changes made to the use of your Personal Data in a manner
                    different from that stated at the time of collection, we will notify you by posting a notice on our Website or by other means.
                </p>
                <ol>
                    <li>
                        <strong> </strong>
                        <strong>WHAT INFORMATION WE COLLECT, WHY WE COLLECT IT, AND HOW IT IS USED</strong>
                    </li>
                </ol>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <table border='1' width='122%'>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>
                                    <strong>Data we collect</strong>
                                </p>
                            </td>
                            <td width='20%'>
                                <p>
                                    <strong>Why is the data collected and for what purposes?</strong>
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>
                                    <strong>Legal basis (GDPR only)</strong>
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='15%'>
                                <p>
                                    <strong>Third parties with whom we share your data</strong>
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>
                                    <strong>Consequences of not providing the data</strong>
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you make use of our App STEPS ("STEPS")</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you create an account</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Google or Facebook log-in</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Phone number</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Birth date</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Profile image</p>
                                <p>
                                    &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information if you choose to upload, sync and/or import it from
                                    your device&nbsp;(e.g., pictures)
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To create an account</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To improve the App</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To allow people to see each other</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To connect with friends in the context of the App</p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. to create an account)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Amazon (Cloud)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot create an account</p>
                                <p>&nbsp;</p>
                                <p>Cannot improve the App</p>
                                <p>&nbsp;</p>
                                <p>Cannot allow people to see each other</p>
                                <p>&nbsp;</p>
                                <p>Cannot connect with friends in the context of the App</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you create content and post it (&ldquo;Step&rdquo;) in the App </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Password</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Photo / Pictures</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Camera details</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Phone number</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IP Address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Location of a point of publishing the content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information in or about the content you provide (like metadata)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Value, subject, description, comments, messages</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hashtags</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Feedback and rating (such as, like)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to create content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To perform the service</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To publish your content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To help you and other users to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To view and/or interact with your and other users content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To give you accurate search results</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To personalize and improve our services</p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. to enable you to find points of interest)</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td rowSpan='2' width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Amazon (Cloud)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot enable you to create content</p>
                                <p>&nbsp;</p>
                                <p>Cannot perform the service</p>
                                <p>&nbsp;</p>
                                <p>Cannot publish your content</p>
                                <p>&nbsp;</p>
                                <p>Cannot help you and other users to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot enable you to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot give you accurate search results</p>
                                <p>&nbsp;</p>
                                <p>Cannot personalize and improve the services</p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Geolocation</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To post your Step</p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>Important note: To create a Step you need to be in the place at the moment of creation </strong>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Consent</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot enable you to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot post your Step</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you make a comment in a Step </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Content of the comment</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IP Address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Region</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Device model and details</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To process and sharing your comments</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to comment points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To personalize and improve our services</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. publish your reviews of Steps)</p>
                            </td>
                            <td rowSpan='2' width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>Cannot process and publish your comments</p>
                                <p>&nbsp;</p>
                                <p>Cannot enable you to comment points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot personalize and improve our services</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Geolocation</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To post your comments in the Step</p>
                            </td>
                            <td width='19%'>
                                <p>Consent</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot enable you to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot post your comments in the Step</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you add content to an existing Step </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td rowSpan='2' width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hashtags</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Photos</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Content of the comment (description, title name, value, rating)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IP Address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Region</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Device model and details</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td rowSpan='2' width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To process and sharing your comments</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to comment points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To personalize and improve our services</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td rowSpan='3' width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                            </td>
                            <td rowSpan='2' width='19%'>
                                <p>Cannot process and publish your comments</p>
                                <p>&nbsp;</p>
                                <p>Cannot enable you to comment points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot personalize and improve our services</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td width='19%'>
                                <p>Legitimate interest (e.g. publish your reviews of Steps)</p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Geolocation</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To post your content in an existing Step</p>
                            </td>
                            <td width='19%'>
                                <p>Consent</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot enable you to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot post your content in an existing Step</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you make a search of Steps in STEPS&rsquo; App </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IP Address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Region</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Distinct ID</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Clicked element ID</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Keyword</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply us</p>
                                <table border='1' width='399'>
                                    <tbody>
                                        <tr>
                                            <td width='399'>
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='399'>
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find points of interest in your next searches.</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To understand user preferences and to improve the services</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To help you and other users to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To view and/or interact with your and other users content</p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. to enable you to do find points of interest in Steps App)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Google Analytics (Analyze user usage)</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot enable you to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot understand user preferences and to improve the services</p>
                                <p>&nbsp;</p>
                                <p>Cannot help you and other users to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot enable you to find points of interest</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you share content in our App and/or in social media channels</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IP Address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Region</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Device model and details</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Social media channel used to share the content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To process your request&nbsp;</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To share with friend a Step</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To share by social media channels a Step</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To understand user preferences and to improve the services</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To help you and other users to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To view and/or interact with your and other users content</p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. to process your request)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>Cannot your request</p>
                                <p>&nbsp;</p>
                                <p>Cannot share with a friend a Step</p>
                                <p>
                                    <br /> Cannot share by social media channels a Step
                                </p>
                                <p>&nbsp;</p>
                                <p>Cannot understand user preferences and to improve the services</p>
                                <p>&nbsp;</p>
                                <p>Cannot help you and other users to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot enable you to find points of interest</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you create a Map in our App STEPS (e.g., Public Map, Restricted Map, Community Map, Secret Map)</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User name (as the admin name)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Name of the map and the brief definition of the Map</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IP Address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Device model and details</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Permissions settings</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Admin users and co-admin users</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To create a Map in our App STEPS&nbsp;</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To administrate the Map</p>
                                <p>
                                    &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Depending on the type of Map that the administrator decides to
                                    create, to share the Map with the users of the App and allow them to create content
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To understand user preferences and to improve the services</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To help you and other users to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To view and/or interact with your and other users content</p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. to process your request)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>&nbsp;</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>Cannot create a Map in our App STEPS</p>
                                <p>&nbsp;</p>
                                <p>Cannot administrate the Map</p>
                                <p>
                                    <br /> Cannot share by social media channels a Step
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    Depending on the type of Map that the administrator decides to create, cannot share the Map with the users of the App and
                                    allow them to create content
                                </p>
                                <p>&nbsp;</p>
                                <p>Cannot understand user preferences and cannot improve the services</p>
                                <p>&nbsp;</p>
                                <p>Cannot help you and other users to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot view and/or interact with your and other users content</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you create content and post it in a specific Map in our App STEPS</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Photo / Pictures</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Camera details</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IP Address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Location of a point of publishing the content</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information in or about the content you provide (like metadata)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Value, subject, description, comments, messages</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hashtags</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Feedback and rating (such as, like)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to create content in a Map</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To perform the service</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To publish your content in a Map</p>
                                <p>
                                    &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To help you and other users to find points of interest in specific
                                    Maps
                                </p>
                                <p>
                                    &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To view and/or interact with your and other users content in the
                                    Maps
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find specific Maps</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To give you accurate search results</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To personalize and improve our services</p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. to enable you to create content in a Map)</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td rowSpan='2' width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Amazon (Cloud)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Google Analytics (Analyze user usage)</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot enable you to create content in a Map</p>
                                <p>&nbsp;</p>
                                <p>Cannot perform the service</p>
                                <p>&nbsp;</p>
                                <p>Cannot publish your content in a Map</p>
                                <p>&nbsp;</p>
                                <p>Cannot help you and other users to find points of interest&nbsp; in specific Maps</p>
                                <p>&nbsp;</p>
                                <p>Cannot enable you to find specific Maps</p>
                                <p>&nbsp;</p>
                                <p>Cannot enable you to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot give you accurate search results</p>
                                <p>&nbsp;</p>
                                <p>Cannot personalize and improve the services</p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Geolocation</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enable you to find points of interest</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To post your Step</p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>Important note: To create a Step you need to be in the place at the moment of creation </strong>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Consent</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot enable you to find points of interest</p>
                                <p>&nbsp;</p>
                                <p>Cannot post your Step</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you make use of, or interact with our Website</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you browse or visit our website</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>Cookies, Log files and Analytic tools</p>
                                <p>&nbsp;</p>
                                <p>Read more in our cookie policy</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>For user preferences and to improve the services</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Consent</p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. essential cookies)</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>&nbsp;</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>Cannot collect and store the information</p>
                                <p>&nbsp;</p>
                                <p>Cannot use or access some parts of the website</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you subscribe to the email list / newsletters / blog</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To add you to our mailing list</p>
                                <p>&nbsp;</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To send marketing communications</p>
                            </td>
                            <td width='19%'>
                                <p>Consent</p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. send you more information about Steps)</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                            <td width='19%'>
                                <p>Cannot add you to our mailing list</p>
                                <p>&nbsp;</p>
                                <p>Cannot send you marketing communications&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you contact us (e.g. customer support)&nbsp; </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Comment</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To answer questions</p>
                                <p>&nbsp;</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To provide you support</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (e.g. respond to a query sent by you)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot assist you and respond your query</p>
                                <p>&nbsp;</p>
                                <p>Cannot provide support</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you exchange business cards with us</em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Company name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To establish a business connection</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract (e.g. showing you certain products and features that you
                                    have shown an interest in).
                                </p>
                                <p>&nbsp;</p>
                                <p>Legitimate interest (certain B2B marketing communications)</p>
                            </td>
                            <td width='15%'>
                                <p>None</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot establish a business connection</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When we use the Personal Data of our service providers and suppliers </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Company name and number</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Phone number</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To contact our service providers</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To perform the applicable agreement</p>
                                <p>
                                    <strong>e</strong>
                                </p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>
                                    Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at
                                    the request of the data subject prior to entering into a contract
                                </p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>Legitimate interest (e.g. perform the contract, send contract-related communications)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot communicate with you</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>Cannot perform the applicable agreement</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When we use the Personal Data of our customer </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Company name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Phone number</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Providing our services</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Performing the agreement</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Processing is necessary for the performance of a contract to which our customer is a party.</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>Compliance with a legal obligation (e.g. tax laws, bookkeeping laws, etc.).</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>Legitimate interest (e.g. send you contract-related communications)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot provide the services</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>Cannot communicate with you</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>Cannot perform the agreement</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='5' width='100%'>
                                <p>
                                    <strong>
                                        <em>When you interact with us on our social media profiles (e.g., Facebook, Instagram, Twitter, LinkedIn) </em>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width='25%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email address</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Company name</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any other data you decide to provide/supply</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>
                                    <em>&nbsp;</em>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='20%'>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Establishing a business connection</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Consent</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>Legitimate interest (e.g. send you more information about Steps)</p>
                            </td>
                            <td width='15%'>
                                <p>
                                    3<sup>rd</sup> party platforms such as for the following purposes:
                                </p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mixpanel (Analyze user usage)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Appsflyer (Online campaign tool)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Firebase (Push notifications)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fabric (beta testing and bugs analysis)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pointzi (in-app wizards)</p>
                                <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cognito (Authentication tool)</p>
                                <p>&nbsp;</p>
                            </td>
                            <td width='19%'>
                                <p>Cannot establish a business connection</p>
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p>
                    Finally, please note that some of the abovementioned Personal Data will be used for fraud detection and prevention, and for security
                    purposes.
                </p>
                <ol start='2'>
                    <li>
                        <strong> </strong>
                        <strong>PERIOD OF STORAGE OF COLLECTED INFORMATION </strong>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <p>
                    The information we collect will be stored until we no longer need the information and proactively delete it or you send a valid deletion
                    request. Please note that we may retain it for a longer or shorter period in accordance with data retention laws.
                </p>
                <p>&nbsp;</p>
                <p>
                    This depends on the cookie in question. Some cookies (e.g. essential cookies) cannot be disabled. You may also control and delete these
                    cookies through your browser settings. Read more in our cookie policy.
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ol start='3'>
                    <li>
                        <strong> </strong>
                        <strong>HOW WE PROTECT AND STORE YOUR INFORMATION</strong>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <ul>
                    <li>
                        <u>Security</u>. We have implemented appropriate technical, organizational and security measures designed to reduce the risk of
                        accidental destruction or loss, or the unauthorized disclosure or access to such information appropriate to the nature of the
                        information concerned. However, please note that we cannot guarantee that the information will not be exposed as a result of
                        unauthorized penetration to our servers. As the security of information depends in part on the security of the computer, device or
                        network you use to communicate with us and the security you use to protect your user IDs and passwords, please make sure to take
                        appropriate measures to protect this information.
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li>
                        <u>Retention of your Personal Data</u>. In addition to the retention periods mentioned in Section 1 above, in some circumstances we may
                        store your Personal Data for longer periods of time, for example (i) where we are required to do so in accordance with legal,
                        regulatory, tax or accounting&nbsp;requirements, or (ii) for us to have an accurate record of your dealings with us in the event of any
                        complaints or challenges, or (iii) if we reasonably believe there is a prospect of litigation relating to your Personal Data or
                        dealings.
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ol start='4'>
                    <li>
                        <strong> </strong>
                        <strong>HOW YOUR PERSONAL INFORMATION IS SHARED </strong>
                    </li>
                </ol>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <p>4.1 We share your information as follows:</p>
                <p>In addition to the recipients described in Section 1, we may share your information as follows:</p>
                <ul>
                    <li>
                        To the extent necessary, with regulators, to comply with all applicable laws, regulations and rules, and requests of law enforcement,
                        regulatory and other governmental agencies or if required to do so by court order;
                    </li>
                    <li>
                        If, in the future, we sell or transfer some or all of our business or assets to a third party, we will (to the minimum extent required)
                        disclose information to a potential or actual third party purchaser of our business or assets. In the event that we are acquired by or
                        merged with a third party entity, or in the event of bankruptcy or a comparable event, we reserve the right to transfer or assign
                        Personal Data in connection with the foregoing events.
                    </li>
                    <li>
                        Where you have provided your consent to us sharing the Personal Data (e.g., where you provide us with marketing consents or opt-in to
                        optional additional services or functionality); and
                    </li>
                    <li>
                        Where we receive requests for information from law enforcement or regulators, we carefully validate these requests before any Personal
                        Data is disclosed.
                    </li>
                    <li>
                        We share the following information with other users and the general public of STEPS: The content that you post or submit it is set to be
                        viewable by the users and general public of our App and geolocation. This content may be viewed and shared by the public.
                    </li>
                </ul>
                <p>4.2 You may share your information as follows:</p>
                <p>You may share a Step:</p>
                <ul>
                    <li>With your family and friends using Third Party Services, and/or</li>
                    <li>With users and the general public of STEPS App.</li>
                </ul>
                <ol start='5'>
                    <li>
                        <strong> </strong>
                        <strong>ADDITIONAL INFORMATION REGARDING TRANSFERS OF PERSONAL INFORMATION </strong>
                    </li>
                </ol>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <p>
                    <u>Storage</u>: Amazon (AWS) Ireland.
                </p>
                <p>
                    <u>&nbsp;</u>
                </p>
                <p>
                    <u>Access from Israel</u>: It is covered by Israel&rsquo;s Adequacy Decision and involves no data transfer issue. You can read more about
                    this here:{' '}
                    <a href='https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en/'>
                        https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en/
                    </a>
                    <span>&nbsp; &nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>External transfers</u>:
                </p>
                <p>&nbsp;</p>
                <ul>
                    <li>
                        Where we transfer your Personal Data outside of EU/EEA, for example to third parties who help provide our products and services, we will
                        obtain contractual commitments from them to protect your Personal Data.
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li>
                        Some of these assurances are well recognized certification schemes like the EU - US Privacy Shield for the protection of Personal Data
                        transferred from within the EU to the United States; or
                    </li>
                </ul>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <ul>
                    <li>
                        Where we receive requests for information from law enforcement or regulators, we carefully validate these requests before any Personal
                        Data is disclosed.
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ol start='6'>
                    <li>
                        <strong> </strong>
                        <strong>YOUR RIGHTS </strong>
                    </li>
                </ol>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <p>
                    The following rights (which may be subject to certain exemptions or derogations), shall apply to certain individuals (some of which only
                    apply to individuals protected by the GDPR):
                </p>
                <ul>
                    <li>
                        You have a right to access information held about you. Your right of access is normally be exercised free of charge, however we reserve
                        the right to charge an appropriate administrative fee where permitted by applicable law;
                    </li>
                    <li>
                        You have the right to request that we amend any Personal Data we hold that it is inaccurate or misleading
                        <span>.</span>
                    </li>
                    <li>
                        You have the right to request the erasure of the Personal Data that relates to you. Please note that there may be circumstances in which
                        we are required to retain your data, for example for the establishment, exercise or defense of legal claims;
                    </li>
                    <li>
                        The right to object to or to request restriction of the processing. However, there may be circumstances in which we are legally entitled
                        to refuse your request;
                    </li>
                    <li>
                        The right to data portability. This means that you may have the right to receive your Personal Data in a structured, commonly used and
                        machine-readable format, and that you have the right to transmit that data to another controller;
                    </li>
                    <li>You have the right to object to profiling;</li>
                    <li>
                        You have a right to lodge a complaint with your local data protection supervisory authority (i.e.,&nbsp;your place of habitual
                        residence, place or work or place of alleged infringement) at any time. We ask that you please attempt to resolve any issues with us
                        before you contact your local supervisory authority
                    </li>
                    <li>
                        The right to withdraw your consent. Please note that t there may be circumstances in which we are entitled to continue processing your
                        data, in particular if the processing is required to meet our legal and regulatory obligations.
                    </li>
                    <li>
                        You also have a right to request details of the basis on which your Personal Data is transferred outside the European Economic Area, but
                        you acknowledge that data transfer agreements may need to be partially redacted for reasons of commercial confidentiality
                        <span>.</span>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    You can exercise your rights by contacting us at info@steps.me. Subject to legal and other permissible considerations, we will make every
                    reasonable effort to honor your request promptly or inform you if we require further information in order to fulfil your request. When
                    processing your request, we may ask you for additional information to confirm your identity and for security purposes, before disclosing the
                    Personal Data requested to you. We reserve the right to charge a fee where permitted by law, for instance if your request is manifestly
                    unfounded or excessive.
                </p>
                <p>
                    In the event that your request would adversely affect the rights and freedoms of others (for example, would impact the duty of
                    confidentiality we owe to others) or if we are legally entitled to deal with your request in a different way than initial requested, we will
                    address your request to the maximum extent possible, all in accordance with applicable law.
                </p>
                <ol start='7'>
                    <li>
                        <strong> </strong>
                        <strong>USE BY CHILDREN </strong>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <p>
                    We do not offer our products or services for use by children under the age of thirteen (13) years. We do not intend to collect Personal Data
                    from anyone we know to be under thirteen (13) years. If you believe that we might have any such information, please contact us at
                    info@steps.me. If you are under thirteen (13), you may not use the App, or provide any information to the Website and/or App without
                    involvement of a parent or a guardian. &nbsp;For the purposes of the GDPR, we do not intend to
                    <em>offer information society</em> services
                    <em>directly to children</em>. Regardless, in the event that we become aware that you provide Personal Data in violation of applicable
                    privacy laws, we reserve the right to delete it.
                </p>
                <p>&nbsp;</p>
                <ol start='8'>
                    <li>
                        <strong> </strong>
                        <strong>PUBLIC INFORMATION ABOUT YOUR ACTIVITY ON THE SERVICES </strong>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <p>
                    Some of your activity on and through the Services is public by default. This may include, but is not limited to, content you have posted
                    publicly on the Site or otherwise through the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                    Registered users may have some of this information associated with their Accounts. Unregistered users will not have this association, but
                    information concerning their use of the Services (such as what pages they have visited) may be tracked anonymously through the use of
                    cookies and stored by us.
                </p>
                <p>&nbsp;</p>
                <p>
                    Please also remember that if you choose to provide Personal Information using certain public features of the Services, then that information
                    is governed by the privacy settings of those particular features and may be publicly available. Individuals reading such information may use
                    or disclose it to other individuals or entities without our control and without your knowledge, and search engines may index that
                    information. We therefore urge you to think carefully about including any specific information you may deem private in content that you
                    create or information that you submit through the Services.
                </p>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <ol start='9'>
                    <li>
                        <strong> </strong>
                        <strong>HOW CAN I DELETE MY ACCOUNT?</strong>
                    </li>
                </ol>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <p>
                    Should you ever decide to delete your Account, you may do so by emailing info@steps.me. If you terminate your Account, any association
                    between your Account and information we store will no longer be accessible through your Account. However, given the nature of sharing on the
                    Services, any public activity on your Account prior to deletion will remain stored on our servers and will remain accessible to the public.
                </p>
                <p>&nbsp;</p>
                <ol start='10'>
                    <li>
                        <strong> </strong>
                        <strong>LOG FILES </strong>
                        <strong />
                    </li>
                </ol>
                <p>&nbsp;</p>
                <p>
                    We may make use of log files. The information inside the log files includes internet protocol (IP) addresses, type of browser, Internet
                    Service Provider (ISP), date/time stamp, referring/exit pages, clicked pages and any other information your browser may send to us. We may
                    use such information to analyze trends, administer the Site, track users&rsquo; movement around the Site, and gather demographic
                    information.
                </p>
                <ol start='11'>
                    <li>
                        <strong> </strong>
                        <strong>COOKIES AND OTHER TRACKING TECHNOLOGIES</strong>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <p>
                    Our Site may utilize &ldquo;cookies&rdquo;, anonymous identifiers and other tracking technologies in order to for us to provide our Site and
                    present you with information that is customized for you. A &ldquo;cookie&rdquo; is a small text file that may be used, for example, to
                    collect information about activity on the Site. Certain cookies and other technologies may serve to recall Personal Information, such as an
                    IP address, previously indicated by a user. Most browsers allow you to control cookies, including whether or not to accept them and how to
                    remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser.
                </p>
                <ol start='12'>
                    <li>
                        <strong> </strong>
                        <strong>ANALYTIC TOOLS</strong>
                    </li>
                </ol>
                <ul>
                    <li>
                        <strong>Google Analytics</strong>. The Website may use a tool called &ldquo;
                        <strong>Google Analytics</strong>
                        &rdquo; to collect information about use of the Website. Google Analytics collects information such as how often users visit this
                        Website, what pages they visit when they do so, and what other websites they used prior to coming to this Website. We use the
                        information we get from Google Analytics to maintain and improve the Website and our products. We do not combine the information
                        collected through the use of Google Analytics with personally identifiable information. Google&rsquo;s ability to use and share
                        information collected by Google Analytics about your visits to this Website is restricted by the Google Analytics Terms of Service,
                        available at <a href='http://www.google.com/analytics/terms/us.html/'>http://www.google.com/analytics/terms/us.html/</a>, and the Google
                        Privacy Policy, available at&nbsp;
                        <a href='http://www.google.com/policies/privacy/'>http://www.google.com/policies/privacy/</a>. You may learn more about how Google
                        collects and processes data specifically in connection with Google Analytics at{' '}
                        <a href='http://www.google.com/policies/privacy/partners/'>http://www.google.com/policies/privacy/partners/</a>. You may prevent your
                        data from being used by Google Analytics by downloading and installing the Google Analytics Opt-out Browser Add-on, available at{' '}
                        <a href='https://tools.google.com/dlpage/gaoptout/'>https://tools.google.com/dlpage/gaoptout/</a>.
                    </li>
                    <li>
                        <strong>Firebase Analytics</strong>. We also use a similar tool called &ldquo;
                        <strong>Google Analytics for Firebase</strong>
                        &rdquo;. By enabling this tool, we enable the collection of data about App Users, including via&nbsp;
                        <a href='https://www.google.com/policies/technologies/ads/'>identifiers for mobile devices</a>
                        (including Android Advertising ID and Advertising Identifier for iOS), cookies and similar technologies. We use the information we get
                        from Google Analytics for Firebase to maintain and improve our App(s). We do not facilitate the merging of personally-identifiable
                        information with non-personally identifiable information unless we have robust notice of, and your prior affirmative (i.e., opt-in)
                        consent to, that merger. Finally, please note that Google Analytics for Firebase&rsquo;s terms (available at{' '}
                        <a href='https://firebase.google.com/terms/'>https://firebase.google.com/terms/</a>) shall also apply.
                    </li>
                    <li>
                        <strong>
                            <u>Mixpanel</u>
                        </strong>
                        . We collect personally identifiable information such as your email address and your user activity through the use of Mixpanel.
                        Mixpanel&rsquo;s ability to use and share information is governed by the Mixpanel Terms of Use, available at&nbsp;
                        <a href='https://mixpanel.com/terms/'>https://mixpanel.com/terms/</a>
                        <u>,</u>
                        and the Mixpanel Privacy Policy, available at&nbsp;
                        <a href='http://www.cooladata.com/privacy/'>https://mixpanel.com/privacy/</a>. You can opt-out of Mixpanel&rsquo;s services by clicking
                        on the following link: https://mixpanel.com/optout/. If you get a new computer, install a new browser, erase or otherwise alter your
                        browser&rsquo;s cookie file (including upgrading certain browsers) You may also clear the Mixpanel opt-out cookie.
                    </li>
                    <li>
                        <strong>
                            <u>AppsFlyer</u>
                        </strong>
                        . We use a tool called &ldquo;AppsFlyer&rdquo;, a mobile attribution &amp; marketing analytics platform, to understand the use of our
                        Service. AppsFlyer is exposed to the following data: (i) unique identifiers and technical data, such as IP address, User agent, IDFA
                        (Identifier For Advertisers) or Android ID (in Android devices); (ii) technical data regarding your operating system, device attributes
                        and settings, applications, advertising opt-out signals, Google Advertiser ID, in-app events, device motion parameters and carrier. The
                        use of this data allows us to analyze our campaigns and performance, as well as your habits and characteristics. For example, the data
                        AppsFlyer receives includes downloads, impressions, clicks and installations of their mobile applications, mobile device use and data
                        regarding in-app events. AppsFlyer&rsquo;s terms of use (available at{' '}
                        <a href='https://www.appsflyer.com/terms-of-use/'>https://www.appsflyer.com/terms-of-use/</a>) and privacy policy (available at{' '}
                        <a href='https://www.appsflyer.com/privacy-policy/'>https://www.appsflyer.com/privacy-policy/</a>) also apply to the use of AppsFlyer.
                    </li>
                    <li>
                        <strong>
                            <u>AppSee and other third party technologies</u>
                        </strong>
                        . We use Appsee&nbsp;
                        <a href='https://www.appsee.com/legal/terms'>https://www.appsee.com/legal/terms</a>
                        and other third parties to collect and analyze data from our Services.&nbsp;
                        <strong>&nbsp;</strong>
                    </li>
                    <li>
                        <strong>
                            <u> </u>
                        </strong>
                        The Website uses Hotjar in order to better understand our users&rsquo; needs and to optimize this service and experience. Hotjar is a
                        technology service that helps us better understand our users experience (e.g. how much time they spend on which pages, which links they
                        choose to click, what users do and don&rsquo;t like, etc.) and this enables us to build and maintain our service with user feedback.
                        Hotjar uses cookies and other technologies to collect data on our users&rsquo; behavior and their devices (in particular device's IP
                        address (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information,
                        geographic location (country only), preferred language used to display our Website). Hotjar stores this information in a pseudonymized
                        user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match it with further data on an
                        individual user. For further details, please see Hotjar&rsquo;s privacy policy at https://www.hotjar.com/legal/policies/privacy. You can
                        opt-out to the creation of a user profile, Hotjar&rsquo;s storing of data about your usage of our Website and Hotjar&rsquo;s use of
                        tracking cookies on other websites on this link https://www.hotjar.com/legal/compliance/opt-out.
                    </li>
                    <li>
                        <strong>Facebook Pixels and SDKs</strong>. We use Facebook pixels or SDKs, which are tools that provide help to website owners and
                        publishers, developers, advertisers, business partners (and their customers) and others integrate, use and exchange information with
                        Facebook, as such the collection and use of information for ad targeting. Please note that third parties, including Facebook, use
                        cookies, web beacons, and other storage technologies to collect or receive information from your websites and elsewhere on the internet
                        and use that information to provide measurement services and target ads. Facebook&rsquo;s ability to use and share information is
                        governed by the Facebook Tools Terms, available at:{' '}
                        <a href='https://www.facebook.com/legal/technology_terms/'>https://www.facebook.com/legal/technology_terms/</a>
                        <a href='https://www.facebook.com/legal/technology_terms/'>. </a>
                        You can prevent your data from being used by Facebook Pixels and SDKs by exercising your choice through these mechanisms:
                        <a href='http://www.aboutads.info/'>http://www.aboutads.info/</a> <a href='http://www.aboutads.info/'>c</a>
                        hoices o<a href='http://www.youronlinechoices.eu/'>r </a>
                        <a href='http://www.youronlinechoices.eu/'>http://www.youronlinechoices.eu/</a>
                        <a href='http://www.youronlinechoices.eu/'>.</a>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li>
                        <strong>Google Signals</strong>. The Website uses a tool called &ldquo;
                        <strong>Google Signals</strong>
                        &rdquo; to collect information about use of the Website. When we activate Google Signals, some existing Google Analytics features are
                        updated to also include aggregated data from Google users who have turned on &ldquo;Ads Personalization&rdquo; (Ads Personalization
                        available at https://support.google.com/ads/answer/2662856/). Audiences that we create in Google Analytics and publish to Google Ads and
                        other Google Marketing Platform advertising products can serve ads in cross device-eligible remarketing campaigns to Google users who
                        have turned on Ads Personalization. Google Analytics collects additional information about users who have turned on Ads Personalization,
                        base across device types and on aggregated data from users who have turned on Ads Personalization. The data is user based rather than
                        session based. The Cross Device reports include only aggregated data. No data for individual users is ever exposed. You can modify your
                        interests, choose whether your Personal Data is used to make ads more relevant to you, and turn on or off certain advertising services
                        in the Ads Personalization link above.
                    </li>
                </ul>
                <ol start='13'>
                    <li>
                        <strong> </strong>
                        <strong>CALIFORNIA PRIVACY RIGHTS</strong>
                    </li>
                </ol>
                <p>
                    California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo; law, permits our subscribers who are California
                    residents to request and obtain from us once a year, free of charge, information about the personal information (if any) we disclosed to
                    third parties for direct marketing purposes in the preceding calendar year. If applicable, this information would include a list of the
                    categories of personal information that was shared and the names and addresses of all third parties with which we shared information in the
                    immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in
                    writing to our privacy officer as listed below.
                </p>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <ol start='14'>
                    <li>
                        <strong> </strong>
                        <strong>OUR CALIFORNIA DO NOT TRACK NOTICE</strong>
                    </li>
                </ol>
                <p>
                    <em>&nbsp;</em>
                </p>
                <p>
                    We do not track consumers over time and across third party websites and therefore do not respond to Do Not Track signals. We do not allow
                    third parties to collect personally identifiable information about an individual consumer&rsquo;s online activities over time and across
                    different web sites when a consumer uses the Services.
                </p>
                <p>&nbsp;</p>
                <ol start='15'>
                    <li>
                        <strong> </strong>
                        <strong>DELETION OF CONTENT FROM CALIFORNIA RESIDENTS</strong>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <p>
                    If you are a California resident under the age of 18 and a registered user, California Business and Professions Code Section 22581 permits
                    you to remove content or Personal Information you have publicly posted. To remove, please send an email to info@steps.me. Please be aware
                    that after removal you will not be able to restore removed content. In addition, such removal does not ensure complete or comprehensive
                    removal of the content or Personal Information you have posted and that there may be circumstances in which the law does not require us to
                    enable removal of content.
                </p>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <ol start='16'>
                    <li>
                        <strong> </strong>
                        <strong>CONTACT US</strong>
                    </li>
                </ol>
                <p>
                    <strong>&nbsp;</strong>
                </p>
                <p>
                    If you have any questions, concerns or complaints regarding our compliance with this notice and the data protection laws, or if you wish to
                    exercise your rights, we encourage you to first contact us at info@steps.me.
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
            </td>
        </tr>
    </table>
);

export default Policy;
