import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import Slider from 'react-slick';
import CatagoryTask from './CategoryTask';
import InviteTask from './InviteTask';
import MoreStepsTask from './MoreStepsTask';
import MarkStep from './MarkStep';
import ShareTask from './ShareTask';
import FiveStepsTask from './FiveStepsTask';
import TagsTask from './TagsTask';
import MapImageTask from './MapImageTask';
import MapDescriptionTask from './MapDescriptionTask';
import ReactLoading from 'react-loading';
import FirstMemberTask from './FirstMemberTask';
import { KeyboardReturnOutlined } from '@material-ui/icons';
import copies from '../../copies.json';
import { userActionActions } from '../../redux/userAction';
import { noop } from 'lodash';

let sliderSettings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '70px',
    slidesToShow: 1,
    speed: 500,
};
const GuideTasksRoll = ({
    statusFlags,
    onClickAddStep = noop,
    onClickAddTags = noop,
    onClickAddImage = noop,
    onClickAddDescription = noop,
    onClickShare = noop,
}) => {
    const sliderRef = useRef(null);
    
    useEffect(() => {
        if (!statusFlags) return;
        const tasks = [
            statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS,
            statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS,
            statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_IMAGE,
            statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_DESCRIPTION,
            statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP,
            statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_30_STEPS,
        ];
        const indexOfUnresolvedTask = tasks.indexOf(false);
        sliderRef.current.slickGoTo(indexOfUnresolvedTask === -1 ?
            tasks.length - 1 :
            indexOfUnresolvedTask);
    }, [statusFlags]);
    
    const addStep = () => {
        onClickAddStep();
    };
    const addTags = () => {
        onClickAddTags();
    };
    const addImage = () => {
        onClickAddImage();
    };
    const addDescription = () => {
        onClickAddDescription();
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span className={styles.title}>{copies.insights.tasks.title}</span>
                <div style={{ display: 'flex' }}>
                    <div onClick={() => sliderRef.current.slickPrev()} className={styles.slideArrowButton}>
                        <img className={styles.rotate} src='/assets/img/steps3Images/insights/arrow.svg'/>
                    </div>
                    <div onClick={() => sliderRef.current.slickNext()} className={styles.slideArrowButton}>
                        <img src='/assets/img/steps3Images/insights/arrow.svg'/>
                    </div>
                </div>
            </div>
            {!statusFlags ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center',
                    }}
                >
                    <ReactLoading type='spokes' color='#B5B5B5' height={60} width={28}/>
                </div>
            ) : (
                <Slider ref={sliderRef} {...sliderSettings}>
                    <div>
                        <div className={styles.task}>
                            <FiveStepsTask done={statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS} onClickHandle={addStep}/>
                        </div>
                    </div>
                    <div>
                        <div className={styles.task}>
                            <TagsTask done={statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS} onClickHandle={addTags}/>
                        </div>
                    </div>
                    <div>
                        <div className={styles.task}>
                            <MapImageTask done={statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_IMAGE} onClickHandle={addImage}/>
                        </div>
                    </div>
                    <div>
                        <div className={styles.task}>
                            <MapDescriptionTask
                                done={statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_DESCRIPTION}
                                onClickHandle={addDescription}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.task}>
                            <FirstMemberTask
                                done={statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP}
                                onClickHandle={onClickShare}
                            />
                        </div>
                    </div>
                    {/* <div>
                        <div className={styles.task}>
                            <CatagoryTask onClickHandle={addTags} />
                        </div>
                    </div> */}
                    <div>
                        <div className={styles.task}>
                            <ShareTask onClickHandle={onClickShare}/>
                        </div>
                    </div>
                    <div>
                        <div className={styles.task}>
                            <MoreStepsTask done={statusFlags?.MAP_MANAGER_JOURNEY_TASKS?.MAP_MANAGER_JOURNEY_TASKS_ADDED_30_STEPS} onClickHandle={addStep}/>
                        </div>
                    </div>
                    <div>
                        <div className={styles.task}>
                            <MarkStep onClickHandle={addStep}/>
                        </div>
                    </div>
                    <div>
                        <div className={styles.task}>
                            <InviteTask onClickHandle={onClickShare}/>
                        </div>
                    </div>
                </Slider>
            )}
        </div>
    );
};

export default GuideTasksRoll;
