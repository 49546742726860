import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from '../../api';
import { signTokens, clearStorageOnLogout } from '../../helpers';

export const createMap = createAsyncThunk('user/createMap', async data => UserApi.createMap(data));

export const editUserData = createAsyncThunk('user/createMap', async ({ firstName, lastName }) => {
    await UserApi.editUserData({ firstName, lastName });
    return { first_name: firstName, last_name: lastName };
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', UserApi.forgotPassword);
export const getUserData = createAsyncThunk('user/getUserData', async () => {
    const response = await UserApi.getUserData();
    const { user } = response.data;
    const { first_name, last_name, display_name, image, _id, following, has_logged_in_from_mobile } = user;
    return {
        id: _id,
        name: user.display_name,
        first_name,
        last_name,
        display_name,
        image,
        following,
        has_logged_in_from_mobile,
    };
});

export const login = createAsyncThunk('user/login', async ({ token, email, password, provider, distinctId, name, first_name, last_name }) => {
    try {
        const response = await UserApi.login({ token, email, password, provider, distinctId, name, first_name, last_name });
        const {
            data: { user, accessToken, refreshToken },
        } = response;
        const { first_name: userFirstName, last_name: userLastName, display_name, image, _id, following, has_logged_in_from_mobile } = user;

        if (accessToken) {
            signTokens({ accessToken, refreshToken });
        }

        return {
            id: _id,
            name: user.display_name,
            userFirstName,
            userLastName,
            display_name,
            image,
            following,
            has_logged_in_from_mobile,
        };
    } catch (ex) {
        clearStorageOnLogout();
    }
});

export const registration = createAsyncThunk('user/registration', async ({ email, password, firstName, lastName, distinctId }) => {
    try {
        const response = await UserApi.registration({ email, password, firstName, lastName, distinctId });
        const { user, accessToken, refreshToken } = response.data;
        const { first_name, last_name, display_name, image, _id, following, has_logged_in_from_mobile } = user;

        if (accessToken) {
            signTokens({ accessToken, refreshToken });
        }

        return {
            id: _id,
            name: user.display_name,
            first_name,
            last_name,
            display_name,
            image,
            following,
            has_logged_in_from_mobile,
        };
    } catch (ex) {
        clearStorageOnLogout();
    }
});

export const resetPassword = createAsyncThunk('user/resetPassword', UserApi.resetPassword);
export const resolveIP = createAsyncThunk('user/resolveIp', UserApi.resolveIp);
