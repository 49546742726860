import { PlaceInfoInput } from "./PlaceInfoInput";
import { Linkify } from '../../common';

export const PlaceInfoWebsite = ({ value, ...rest }) => {
  return (
      <PlaceInfoInput
          {...rest}
          value={value}
          link={<Linkify>{value}</Linkify>}/>
  );
}