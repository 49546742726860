import { X } from 'phosphor-react';
import styles from './DropPinView.module.scss';

const DropPinViewExitButton = ({ onClick }) => {
    return (
        <span className={styles.exitDropPinView}>
            <X size={18} cursor='pointer' onClick={onClick} color='#2171EC' />
        </span>
    );
};

export default DropPinViewExitButton;
