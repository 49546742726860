import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import ModalsLoginPage from '../common/modals/ModalsLoginPage';
import copies from '../../copies.json';

const ContactUs = () => {
    const dispatch = useDispatch();
    const [terms, setTerms] = useState(false);
    const [policy, setPolicy] = useState(false);
    const [openModal, setModalOpen] = useState(false);

    const handlerClickPolicy = useCallback(() => {
        setTerms(false);
        setPolicy(true);
        setModalOpen(true);
    }, [dispatch]);

    const handlerClickTerms = useCallback(() => {
        setTerms(true);
        setPolicy(false);
        setModalOpen(true);
    }, [dispatch]);

    const handleClick = useCallback(() => {
        setModalOpen(false);
    }, []);
    return (
        <>
            <div className={styles.container}>
                <div className={styles.textInfo}>
                    {copies.termsOfUse.text_a}
                    <span onClick={handlerClickTerms}>{copies.termsOfUse.terms_of_use_link}</span>
                    {copies.termsOfUse.text_b}
                    <span onClick={handlerClickPolicy}>{copies.termsOfUse.privacy_policy_link}</span>.
                </div>
            </div>
            {openModal && <ModalsLoginPage handleClick={handleClick} terms={terms} policy={policy} />}
        </>
    );
};
export default ContactUs;
