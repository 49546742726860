import React, { createContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Navigation, Pagination, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ArrowButton from './ArrowButton';
import CustomNavigationButton from './CustomNavigationButton';
import { optionsDefaultValue } from './constants';

import styles from './index.module.scss';

if (process.env.NODE_ENV !== 'test') {
    require('swiper/css');
    require('swiper/css/pagination');
    require('swiper/css/effect-fade');
    require('swiper/css/navigation');
    require('swiper/css/scrollbar');
}

export const PaginationSliderContext = createContext({});

const PaginationSlider = ({ slides, options = optionsDefaultValue, children, onSlideChange = noop }) => {
    const { Provider } = PaginationSliderContext;
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const { paginationPosition } = options;
    const swiperOption = { ...optionsDefaultValue, ...options };

    const memoizedContextValue = useMemo(() => {
        return {
            prevRef,
            nextRef,
        };
    }, [prevRef, nextRef]);

    if (process.env.NODE_ENV === 'test') {
        return (
            <Provider value={memoizedContextValue}>
                <div className={classNames(styles.block, styles[`bulletsPosition-${paginationPosition}`])}>
                    <div onSlideChange={slide => onSlideChange(slide.realIndex)}>
                        {slides.map(slide => (
                            <span>{slide}</span>
                        ))}
                        {children}
                    </div>
                </div>
            </Provider>
        );
    }

    return (
        <Provider value={memoizedContextValue}>
            <div className={classNames(styles.block, styles[`bulletsPosition-${paginationPosition}`])}>
                <Swiper
                    modules={[Navigation, Pagination, A11y, EffectFade]}
                    onBeforeInit={swiper => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                    }}
                    speed={400}
                    pagination={{ clickable: false }}
                    onSlideChange={slide => onSlideChange(slide.realIndex)}
                    {...swiperOption}
                >
                    {slides.map(slide => (
                        <SwiperSlide>{slide}</SwiperSlide>
                    ))}
                    {children}
                </Swiper>
            </div>
        </Provider>
    );
};

PaginationSlider.Arrow = ArrowButton;
PaginationSlider.CustomNavigationButton = CustomNavigationButton;

export default PaginationSlider;

export { directions as arrowDirection } from './ArrowButton';
export { paginationBulletsPosition } from './constants';
