import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import Map from '../../../../lib/map/Base';
import MapLocation from './MapLocation';
import { stepActions } from '../../../../redux/step2';

import styles from './index.module.scss';
import { userSelectors } from '../../../../redux/user';

const ModalsChangeLocation = ({ stepId: initStepId, pinIcon, infoLocation, infoLocationNew, files, onCancel, onClickChangeLocation }) => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const [data, setData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const stepId = infoLocation?.type_specific_info?.step_id || initStepId;

            if (!stepId) {
                return;
            }

            const response = await dispatch(stepActions.getStepById(mapId, stepId));
            setData(response?.data);
        })();
    }, []);

    const renderMap = () => {
        if (!data) {
            return null;
        }

        if (infoLocationNew) {
            return <MapLocation files={files} infoLocation={infoLocation} infoLocationNew={infoLocationNew} />;
        }

        return (
            <Map
                zoom={15}
                mapId='temp'
                className={styles.map}
                // TODO: for the new pending pin. However, probably not in the scope of this task
                // selectedStepId={data.step?.id}
                steps={[data.step]}
                center={[data.step?.lon, data.step?.lat]}
                disableAnimation
                disableTemporaryByClicking
                disableOpeningStepPreview
                disableEasing
                withLoader
            />
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <ClickAwayListener onClickAway={onCancel}>
                    <div className={styles.panel}>
                        <div className={styles.panelHead}>
                            <img className={styles.img} onClick={onCancel} src='/assets/img/icon_cancel_blue.svg' alt='cancel' />
                            <span className={styles.changeTitle}>
                                {infoLocationNew && Object.keys(infoLocationNew).length > 0
                                    ? infoLocationNew?.title
                                    : infoLocation?.location_name || infoLocation?.instagram_location_name || infoLocation?.title}
                            </span>
                            {onClickChangeLocation && (
                                <span className={styles.changeLocation}>
                                    <span onClick={onClickChangeLocation}>Change Location</span>
                                </span>
                            )}
                        </div>
                        <div className={styles.panelBody}>{renderMap()}</div>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default ModalsChangeLocation;
