import { useState, useLayoutEffect } from 'react';
import { ScreenBreakpoints } from '../constants/breakpoints';

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    const [visualViewportSize, setVisualViewportSize] = useState([0, 0]);

    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
            setVisualViewportSize([
                window.visualViewport.width,
                window.visualViewport.height,
            ]);
        };

        window.addEventListener('resize', updateSize);
        window.visualViewport.addEventListener('resize', updateSize);
        updateSize();

        return () => {
            window.removeEventListener('resize', updateSize);
            window.visualViewport.removeEventListener('resize', updateSize);
        };
    }, []);

    return { size, visualViewportSize, isMobile: size < ScreenBreakpoints.MOBILE };
};

export default useWindowSize;
