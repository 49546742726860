export const removeWhitespace = str => {
    if (!str) {
        return str;
    }

    return str.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');
};

export const convertTextArrayToString = textArray =>
    textArray
        .filter(item => item.text_type === 'text')
        .map(item => item.text)
        .join(' ');

export const intToShortString = (num, digits) => {
    function truncateToDecimals(num, dig) {
        const calcDec = Math.pow(10, dig);
        return Math.trunc(num * calcDec) / calcDec;
    }
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item
        ? truncateToDecimals(num / item.value, digits)
              .toFixed(digits)
              .replace(rx, '$1') + item.symbol
        : '0';
};
