import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stepActions } from '../../../../redux/step2';
import { userSelectors } from '../../../../redux/user';
import { mapActions, mapSelectors } from '../../../../redux/map';
import { AddPlaceSteps } from '../../../AddStep';
import AddStepExtended from '../../../../lib/map/popups/AddStepExtended';
import { ModalContext } from '../../../../context/ModalContext/ModalContext';
import AddStepToMapModal from './AddStepToMapModal';
import { noop } from 'lodash';
import { generatePath, useNavigate } from 'react-router';
import Routes from '../../../../config/routes';

const useAddStep = () => {
  const dispatch = useDispatch();
  const mapId = useSelector(userSelectors.selectedMapId);
  const mapName = useSelector(mapSelectors.mapName(mapId));
  const { setModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const navigateToStepProfile = stepId => {
    const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId });
    navigate(url);
  };

  const triggerAddStepFlow = ({ stepPreview, position }) => {
    const ghostStepProps = {
      type: 'place',
      id: stepPreview?.place_id,
      _id: stepPreview?.place_id,
      title: stepPreview?.title,
      address: stepPreview?.address,
      position,
    };

    dispatch(
        mapActions.setGhostStep(mapId, {
          ...ghostStepProps,
          customPopup: ({ onConfirm = noop }) => (
              <AddStepExtended
                  title={stepPreview?.title}
                  images={stepPreview?.images}
                  address={stepPreview?.address}
                  onClickAddStep={() => {
                    dispatch(mapActions.setGhostStep(mapId, ghostStepProps));
                    navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`, { state: { flowStepId: AddPlaceSteps.ADD_STEP_FORM } });
                    onConfirm();
                  }}
              />
          ),
        })
    );
    dispatch(
        mapActions.setMapCenter(mapId, {
          position,
          zoom: 10,
        })
    );
  };

  const setStepToAdd = async ({ placeId, stepId: initStepId, shouldShowAddStepModal = false }) => {
    if (initStepId) {
      navigateToStepProfile(initStepId);
      return;
    }

    if (!placeId) {
      console.error('placeId is undefined', { placeId });
      return;
    }

    const results = await dispatch(
        stepActions.stepCreationPreview({
          mapId,
          placeId,
        })
    );

    const stepPreview = results?.data?.place;
    const stepId = results?.data?.step?._id;
    const position = [stepPreview?.lat, stepPreview?.lon];

    if (stepId) {
      navigateToStepProfile(stepId);
      return;
    }

    if (position[0] && position[1]) {
      if (shouldShowAddStepModal) {
        setModal(
            <AddStepToMapModal
                isOpen
                mapId={mapId}
                mapName={mapName}
                placeId={stepPreview.place_id}
                placeName={stepPreview.title}
                onConfirm={() => {
                  triggerAddStepFlow({ stepPreview, position });
                  setModal(null);
                }}
                onClose={() => setModal(null)}
            />
        );
      } else {
        triggerAddStepFlow({ stepPreview, position });
      }
    }
  };

  return { setStepToAdd };
};

export default useAddStep;
