import React from 'react';
import { noop } from 'lodash';
import styles from './index.module.scss';
import classNames from 'classnames';

const Switch = ({ className, text, onChange = noop, isSwitchOn }) => {
    return (
        <div className={className}>
            <label className={styles.switch} style={{ display: 'flex', alignItems: 'center' }}>
                <input onChange={e => onChange(!isSwitchOn)} type='checkbox' checked={isSwitchOn} />
                <span className={styles.sliderBox} />
            </label>
            <span
                className={classNames(styles.text, {
                    [styles.textActive]: isSwitchOn,
                })}
            >
                {text}
            </span>
        </div>
    );
};

export default Switch;
