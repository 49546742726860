import React from 'react';

const IconEditAccordion = () => (
    <svg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M9.9 17.6h7.7M13.708 3.819a1.574 1.574 0 0 1 1.156-.519c.215 0 .428.046.626.135.199.089.38.22.531.384.152.164.272.36.354.575a1.9 1.9 0 0 1 0 1.356 1.79 1.79 0 0 1-.354.575l-9.637 10.44L3.3 17.6l.771-3.34 9.637-10.441z'
            stroke='#666A70'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default IconEditAccordion;
