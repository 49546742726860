import { useEffect, useState, useMemo } from 'react';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import PostSlider from '../../../../components/PostSlider';
import { TextArrayBody } from '../../../../components/common';
import { BaseCard } from '../BaseCard';
import { Outcomes } from '../../constants';

import styles from './index.module.scss';
import BaseModal from '../../../../modalSysyem/BaseModal';
import Outcome from '../Outcome';
import CreateNewPostModal from './../../../../components/Feed/FeedPost/CreateNewPostModal/CreateNewPostModal';
import { PostTypes } from '../../../../constants';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';

// TODO: split to post/step, or rethink the components structure
const UserPostedCard = ({
    outcome,
    avatarImageId = '',
    createdAt = 0,
    textArray = [],
    message = '',
    postBody = [],
    postAttachments = [],
    type = 'step',
    // type = step
    onRemove = noop,
    onConfirm = noop,
    // type = post
    onClap = noop,
    onReply = noop,
    onDisregard = noop,
    onTagClick = noop,
    isClapped: initIsClapped = false,
    isReplied: initIsReplied = false,
    isOpenActivitiesView = true,
    managerPreview,
    handlerManager,
    post = {},
    userPreview = {},
    ...rest
}) => {
    const currentUser = useSelector(userSelectors.user);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isReplied, setIsReplied] = useState(initIsReplied);
    const [isClapped, setIsClapped] = useState(initIsClapped);
    const [outcomeMessage, setOutcomeMessage] = useState('');
    const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const isHandled = outcome !== Outcomes.UNHANDLED;
    const handler = useMemo(() => {
        if (managerPreview) return managerPreview;
        if (handlerManager) return handlerManager;
        if (isClapped && isReplied) {
            return {
                display_name: currentUser.display_name,
                image: currentUser.image,
            };
        }

        return {};
    }, [currentUser, handlerManager, isClapped, isReplied, managerPreview]);

    useEffect(() => {
        if (type === 'step' && outcome === Outcomes.APPROVE) {
            setOutcomeMessage(<FormattedMessage id='mapUpdates.approved' />);
        }

        if (type === 'step' && outcome === Outcomes.DECLINE) {
            setOutcomeMessage(<FormattedMessage id='mapUpdates.declined' />);
        }

        if (type === 'post' && outcome === Outcomes.REPLY) {
            setOutcomeMessage(<FormattedMessage id='mapUpdates.replied' />);
        }

        if (type === 'post' && outcome === Outcomes.CLAP) {
            setOutcomeMessage(<FormattedMessage id='mapUpdates.clapped_by' />);
        }
    }, [outcome, type]);

    const shouldRenderFooter = useMemo(() => {
        if (type === 'step') {
            // Open view should only show disabled state
            return isOpenActivitiesView ? false : isHandled;
        }

        return isHandled || (isClapped && isReplied);
    }, [isClapped, isHandled, isReplied, type, isOpenActivitiesView]);

    return (
        <>
            <BaseCard
                avatarImgId={avatarImageId}
                createdAt={createdAt}
                textArray={textArray}
                message={message}
                onClose={onDisregard}
                onTagClick={onTagClick}
                shouldRenderFooter={shouldRenderFooter}
                {...rest}
                footer={<Outcome text={outcomeMessage} managerPreview={handler} />}
                buttons={[
                    <CustomButton
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.MEDIUM}
                        clicked={isHandled || isDisabled || isReplied}
                        onClick={() => {
                            if (type === 'step') {
                                setIsConfirmationModalOpen(true);
                            } else {
                                setIsReplyModalOpen(true);
                            }
                        }}
                    >
                        <FormattedMessage id={type === 'step' ? 'mapUpdates.remove_step' : 'mapUpdates.reply'} />
                    </CustomButton>,
                    <CustomButton
                        singleClick
                        size={ButtonSize.MEDIUM}
                        type={ButtonType.PRIMARY}
                        clicked={isHandled || (type === 'post' && isClapped) || isDisabled}
                        onClick={() => {
                            if (type === 'step') {
                                setIsDisabled(true);
                                onConfirm();
                            } else {
                                onClap(() => {
                                    setIsClapped(true);
                                    if (isReplied) {
                                        setOutcomeMessage(<FormattedMessage id='mapUpdates.clapped_by' />);
                                    }
                                });
                            }
                        }}
                    >
                        <FormattedMessage id={type === 'step' ? 'mapUpdates.ok' : 'mapUpdates.clap'} />
                    </CustomButton>,
                ]}
            >
                <TextArrayBody className='m-b-8' body={postBody} onTagClick={onTagClick} isTaggingDisabled />
                {postAttachments.length > 0 && (
                    <PostSlider
                        width={504}
                        height={504}
                        slidesToShow={1}
                        slidesToScroll={1}
                        dots={false}
                        classNamesPostImg={styles.postImg}
                        images={postAttachments}
                    />
                )}
            </BaseCard>
            <RemoveConfirmationModal
                isOpen={isConfirmationModalOpen}
                stepName={textArray[textArray.length - 1]?.text}
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={() => {
                    setIsDisabled(true);
                    setIsConfirmationModalOpen(false);
                    onRemove();
                }}
            />
            <CreateNewPostModal
                postType={PostTypes.POST}
                isOpen={isReplyModalOpen}
                postId={post?.parent_post_id || post?._id}
                stepId={post?.step_id}
                onClose={() => setIsReplyModalOpen(false)}
                onSuccess={() => {
                    setIsReplyModalOpen(false);
                    onReply(() => {
                        setIsReplied(true);
                        if (isClapped) {
                            setOutcomeMessage(<FormattedMessage id='mapUpdates.replied' />);
                        }
                    });
                }}
            />
        </>
    );
};

const RemoveConfirmationModal = ({ isOpen, onClose, onConfirm, stepName }) => {
    return (
        <BaseModal isActive={isOpen} onClose={onClose}>
            <div className='subtitle-bold m-b-8'>
                <FormattedMessage id='mapUpdates.remove_step_title' />
            </div>
            <p>
                <FormattedMessage
                    id='mapUpdates.remove_step_description'
                    values={{
                        stepName,
                    }}
                />
            </p>
            <div className='flex space-between'>
                <CustomButton type={ButtonType.GHOST_BLACK} size={ButtonSize.MEDIUM} onClick={onClose}>
                    <FormattedMessage id='mapUpdates.cancel' />
                </CustomButton>
                <CustomButton type={ButtonType.PRIMARY} size={ButtonSize.MEDIUM} onClick={onConfirm}>
                    <FormattedMessage id='mapUpdates.archive_step' />
                </CustomButton>
            </div>
        </BaseModal>
    );
};

export default UserPostedCard;
