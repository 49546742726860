import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { postActions } from '../../../redux/posts';
import { Post, ShowCommentsOptions } from '..';
import BackArrowButton from '../../common/buttons/BackArrowButton';
import { PageSkeleton } from '../../common/Skeletons';

import styles from './PostPage.module.scss';
import { useNavigate, useParams } from 'react-router';
import classNames from 'classnames';

const PostPage = ({postId: id, onBack: onBackProp, className}) => {
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const postId = params.postId || id
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            await dispatch(postActions.getPost(postId));
            setIsLoading(false);
        })();

        return () => dispatch(postActions.clearCommentsCache({ postId }));
    }, []);

    const onBack = () => {
        if(onBackProp) onBackProp();
        else navigate(-1);
    }

    if (isLoading) {
        return <PageSkeleton />;
    }

    return (
        <div className={classNames(styles.postPage, className)}>
            <div className={styles.header}>
                <span className={styles.icon}>
                    <BackArrowButton onClick={onBack} />
                </span>
                <span className={styles.title}>Post</span>
            </div>
            <Post
                postId={postId}
                commentsSettings={{
                    isShowAll: ShowCommentsOptions.ALL,
                }}
                repliesSettings={{
                    isShowAll: ShowCommentsOptions.PARTIAL,
                    numberOfLastCommentsToShow: 3,
                    numberToLoad: 3,
                }}
                showReplies
                inputClassName={styles.inputClassName}
                fromWhere='post_page'
                onBack={onBack}
                className={styles.post}
                isInPostPage={true}
            />
        </div>
    );
};

export default PostPage;
