import React from 'react';

const IconTag = ({ color, colorFill, colorStroke, colorCircle }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill={colorFill || 'none'} viewBox='0 0 24 24'>
        <path
            stroke={colorStroke || color}
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.5'
            d='M19.159 13.175l-5.975 5.975c-.155.155-.339.278-.541.362-.203.084-.42.127-.639.127-.218 0-.435-.043-.638-.127-.202-.084-.386-.207-.54-.362L3.666 12V3.667H12l7.159 7.158c.31.312.484.735.484 1.175 0 .44-.174.863-.484 1.175v0z'
        />
        <path stroke={colorCircle || color} stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7.833 7.833h.01' />
    </svg>
);

export default IconTag;
