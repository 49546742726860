import { Tooltip, Zoom } from '@material-ui/core';
import { useState } from 'react';

const WhatIsAStepTooltip = ({ children, title = 'What is a step?', onClick = () => {} }) => {
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [hoverTooltip, setHoverTooltip] = useState(false);

    const toggleTooltip = () => setTooltipOpen(prevState => !prevState);

    return (
        <Tooltip
            disableHoverListener={isTooltipOpen}
            TransitionComponent={Zoom}
            placement='top'
            arrow
            title={title}
            onClick={() => {
                setHoverTooltip(false);
                toggleTooltip();
            }}
            open={hoverTooltip}
        >
            <div>
                {isTooltipOpen && children}
                <img
                    width={20}
                    height={20}
                    style={{ cursor: 'pointer', paddingTop: '3px' }}
                    onMouseOver={() => !isTooltipOpen && setHoverTooltip(true)}
                    onMouseLeave={() => !isTooltipOpen && setHoverTooltip(false)}
                    onClick={onClick}
                    src={isTooltipOpen ? '/assets/img/steps3Images/help-circle-active.svg' : '/assets/img/steps3Images/help-circle.svg'}
                    alt=''
                />
            </div>
        </Tooltip>
    );
};

export default WhatIsAStepTooltip;
