export default {
    FEED_VIEW: 'FEED_VIEW',
    STEP_PROFILE: 'STEP_PROFILE',
    MAP_VIEW: 'MAP_VIEW',
    MAP_PROFILE: 'MAP_PROFILE',
    POST_PROFILE: 'POST_PROFILE',
    CARD_VIEW: 'CARD_VIEW',
    VISIT_STATUS_PROFILE: 'VISIT_STATUS_PROFILE',
    MAP_UPDATES_VIEW: 'MAP_UPDATES_VIEW',
    MAP_SETTINGS_PAGE: 'MAP_SETTINGS_PAGE',
    LAUNCH_SCREEN: 'LAUNCH_SCREEN',
    ENLARGED_PHOTO: 'ENLARGED_PHOTO',
};
