import SearchBox from '../../../CreateStepGoogleMap/SearchBox';
import styles from './index.module.scss';

const TITLE = 'Add a place to your map';

const PickStep = ({ location, setLocation }) => {
    return (
        <div className={styles.answerInput}>
            <p className={styles.title}>{TITLE}</p>
            <SearchBox location={location} setLocation={setLocation}/>
        </div>
    );
};

export default PickStep;
