import React from 'react';
import styles from './index.module.scss';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/loading.json';
import Logo from "../../components/common/Logo";

const Loader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };
    return (
        <div className={styles.loaderContent}>
            <div className={styles.loader}>
                <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={400} height={400}/>
            </div>
            <span className={styles.logo}>
                <Logo/>
            </span>
        </div>
    );
};

export default Loader;
