import React from 'react';
import styles from './index.module.scss';
import { IconLoader } from '../../../../../../../components/common/icons';

const LoadingRow = ({ numberOfTableCulumns }) => (
    <tr className={styles.block}>
        <td colspan={numberOfTableCulumns}>
            <IconLoader />
        </td>
    </tr>
);

export default LoadingRow;
