import { X } from 'phosphor-react';

import styles from './CloseButton.module.scss';

const CloseButton = ({ onClick, alt }) => {
    return (
        <div onClick={onClick} className={styles.closeIcon} aria-label={alt} role='button'>
            <X color='#2171EC' size={24} />
        </div>
    );
};

export default CloseButton;
