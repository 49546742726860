import React from "react";
import noop from "lodash";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { mapSelectors } from "../../../../../../../redux/map";
import { generateImageUrl } from "../../../../../../../helpers";
import CheckBox from "../../../../../../../components/common/Checkbox";
import { format as formatTime } from "timeago.js";
import classNames from "classnames";

const TAGS_LIMIT = 2;

const Row = ({ step: propStep, onClickRow = noop, onClickCheckBox = noop, onClickTags = noop, onClickPosts = noop }) => {
  const cachedStep = useSelector(mapSelectors.steps(propStep?.map_id))[propStep?._id];
  const step = !cachedStep || cachedStep?.isShortData ? propStep : cachedStep;
  const stepImgId = step?.posts_attachments_list?.posts_attachments[0]?.attachment?.image_id;
  const isRowSelected = propStep?.isSelected;
  const tagsObject = step?.components?.find(component => component.id === "selected_tags")?.tags;
  
  const checkBoxClick = e => {
    e.stopPropagation();
    onClickCheckBox(step._id, isRowSelected);
  };
  
  const tagsClick = e => {
    e.stopPropagation();
    onClickTags(step);
  };
  
  const postsClick = e => {
    e.stopPropagation();
    onClickPosts(step);
  };
  
  const renderStepName = () => {
    return (
      <div className={styles.stepName}>
        <CheckBox
          onClick={e => {
            checkBoxClick(e);
          }}
          className={styles.checkBox}
          checked={isRowSelected}
        />
        <div className={classNames(styles.imgContainer, { [styles.emptyImg]: !stepImgId })}>
          <img
            className={styles.stepImg}
            src={
              stepImgId
                ? generateImageUrl({
                  imageId: stepImgId,
                  width: 64,
                  height: 64,
                  reason: "lv_step_image"
                })
                : "/assets/img/map-pin.svg"
            }
            alt=""
          />
        </div>
        <span className={styles.title}>{step.title}</span>
      </div>
    );
  };
  
  const renderLastEdit = () => {
    return <span className={styles.lastEdit}>{formatTime(step.last_edited)}</span>;
  };
  
  const renderTags = () => {
    const tagsToDisplay = tagsObject.slice(0, TAGS_LIMIT);
    const tagNamesToDisplay = tagsToDisplay.map(tag => tag.name);
    const showMoreCount = tagsObject.length - TAGS_LIMIT;
    
    return (
      <div className={styles.tags} onClick={tagsClick}>
        {tagNamesToDisplay.map(tagName => (
          <span className={styles.tag}>{tagName}</span>
        ))}
        {showMoreCount > 0 && <span className={styles.tag}>+{showMoreCount}</span>}
      </div>
    );
  };
  
  const renderPosts = () => {
    return (
      <div onClick={e => postsClick(e)} className={styles.posts}>
        <span className={styles.postsCount}>{step.posts_count}</span>
      </div>
    );
  };
  
  const renderLikes = () => {
    return step.likes_count;
  };
  
  const renderViews = () => {
    return step.views_count;
  };
  
  const cellsToRender = [renderStepName, renderLastEdit, renderTags, renderPosts, renderLikes, renderViews];
  
  return (
    <tr className={classNames(styles.row, { [styles.selectedRow]: isRowSelected })} onClick={() => onClickRow(step)}>
      {cellsToRender.map(cellRenderFunction => (
        <td className={styles.cell}>{cellRenderFunction()}</td>
      ))}
    </tr>
  );
};

export default Row;
