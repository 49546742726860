import { SET_ONBOARD_MAP_CENTER, IMPORT_STEPS, CHECK_IMPORT_STEPS, IMPORT_PREVIEW_STEPS } from './types';

export const setOnboardMapCenter = position => ({
    type: SET_ONBOARD_MAP_CENTER,
    center: position,
});

export const importSteps = data => ({
    type: IMPORT_STEPS,
    request: {
        method: 'POST',
        url: '/steps3/imports',
        data,
    },
    meta: {
        thunk: true,
    },
});

export const importPreviewSteps = importFileId => ({
    type: IMPORT_PREVIEW_STEPS,
    request: {
        method: 'GET',
        url: `/steps3/import-preview?import_type=EXCEL&import_file_id=${importFileId}`,
    },
    meta: {
        thunk: true,
    },
});

export const checkImportSteps = importId => ({
    type: CHECK_IMPORT_STEPS,
    request: {
        method: 'GET',
        url: `/steps3/imports/${importId}`,
    },
    meta: {
        thunk: true,
    },
});
