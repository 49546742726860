import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { mapActions, mapSelectors } from '../../redux/map';
import { filterStepsByTags } from '../../helpers';
import StepsMap from '../../lib/map/Base';
import copiesPrefix from '../../copies.json';
import { DropPinViewContext } from '../../components/AddStep/components/DropPinView';

const copies = copiesPrefix.addLocation;

let disableClickOnMapTemp = false;

const Map = ({
    mapId,
    hiddenSteps,
    disableClickOnMap,
    onClickStepPreview,
    boldStepId,
    onConfirmLocation,
    onAddStep,
    zoomPanelClassName,
    fitBoundsBySteps,
    selectedTags = [],
    steps: initSteps = [],
}) => {
    const dispatch = useDispatch();
    const mapName = useSelector(mapSelectors.mapName(mapId));
    const allMapSteps = useSelector(mapSelectors.steps(mapId));
    const allMapStepsValues = useMemo(() => Object.values(allMapSteps), [allMapSteps]);
    const [steps, setSteps] = useState(initSteps);
    const temporaryStep = useSelector(mapSelectors.ghostStep(mapId));
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
    const [flyTo, setFlyTo] = useState([34.8, 32]);
    const [fitBoundsByStepsState, setFitBoundsByStepsState] = useState(fitBoundsBySteps || []);
    const { isDropPinView } = useContext(DropPinViewContext);

    // states to sync the map with react state
    const [positionClicked, setPositionClicked] = useState();
    const [deleteTempStepClicked, setDeleteTempStepClicked] = useState();

    useEffect(() => {
        if (!allMapStepsValues.length) {
            return;
        }

        if (selectedTags?.length === 0) {
            setSteps(allMapStepsValues);
        } else {
            setSteps(filterStepsByTags(selectedTags, allMapStepsValues));
        }
    }, [allMapStepsValues, selectedTags]);

    useEffect(() => {
        setFitBoundsByStepsState(fitBoundsBySteps || []);
    }, [fitBoundsBySteps]);

    useEffect(() => {
        dispatch(
            mapActions.setGhostStep(mapId, {
                position: positionClicked,
                title: isDropPinView ? '' : 'selected location',
                suggestToAdd: true,
                isOpenTooltip: false,
                isStickToMap: true,
            })
        );
    }, [positionClicked]);

    useEffect(() => {
        if (!temporaryStep?.isStickToMap || deleteTempStepClicked.trig) dispatch(mapActions.setGhostStep(mapId, null));
    }, [deleteTempStepClicked]);

    useEffect(() => {
        dispatch(mapActions.getAllStepsPreviewData(mapId, 'active')).then(e => {
            if (!mapCenter && e.data && e.data.steps) {
                setFitBoundsByStepsState(e.data.steps);
            } else {
                setFlyTo({
                    position: [
                        mapCenter?.position?.lon || mapCenter?.position?.lng || mapCenter?.position?.[1] || 34.8,
                        mapCenter?.position?.lat || mapCenter?.position?.[0] || 32,
                    ],
                    zoom: mapCenter?.zoom || 2,
                    disableAnimation: true,
                });
            }
        });
    }, [mapId]);

    useEffect(() => {
        disableClickOnMapTemp = disableClickOnMap;
    }, [disableClickOnMap]);

    const setMapCenter = center => {
        dispatch(mapActions.setMapCenter(mapId, center, true));
    };

    useEffect(() => {
        if (mapCenter && !mapCenter.isMapInteraction) {
            setFlyTo({
                position: [
                    mapCenter?.position?.lon || mapCenter?.position?.lng || mapCenter?.position?.[1] || 34.8,
                    mapCenter?.position?.lat || mapCenter?.position?.[0] || 32,
                ],
                zoom: mapCenter?.zoom || 2,
            });
        }
    }, [mapCenter]);

    const deleteTemporaryStep = () => {
        setDeleteTempStepClicked({ trig: true });
    };

    const renderDropPinMessage = () => {
        if (isDropPinView || disableClickOnMap || temporaryStep) {
            return null;
        }

        return (
            <div className={styles.centerBlock}>
                <span>{copies.drop_pin}</span>
            </div>
        );
    };

    return (
        <>
            {renderDropPinMessage()}
            <StepsMap
                mapId={mapId}
                mapName={mapName}
                zoom={flyTo.zoom || 2}
                center={flyTo.position}
                steps={hiddenSteps ? [] : steps}
                allowOverlap={false}
                onBoundsChange={setMapCenter}
                temporaryStep={temporaryStep}
                onClickMap={e => {
                    !disableClickOnMapTemp && !e.isPopupExist && setPositionClicked(e.lngLat);
                }}
                selectedStepId={boldStepId}
                onClickStepPreview={onClickStepPreview}
                disableTemporaryByClicking={true}
                onConfirmLocation={onConfirmLocation}
                onAddStep={onAddStep}
                onCancelPopup={() => deleteTemporaryStep()}
                zoomPanelClassName={zoomPanelClassName}
                fitBoundsBySteps={fitBoundsByStepsState}
                disableDragRotate
                disableAnimation={flyTo.disableAnimation}
                onChangeCenterOfAllSteps={position => dispatch(mapActions.setAllStepsMapCenter(mapId, position))}
            />
        </>
    );
};

export default Map;
