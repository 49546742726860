import mixpanel from "mixpanel-browser";
import config from "../config";

mixpanel.init(config.mixpanel.token);

export const mixpanelEvents = {
  GETTING_STARTED: "mm_getting_started",
  GETTING_STARTED_LETS_START_CLICK: "mm_getting_started_lets_start_click",
  GETTING_STARTED_GET_THE_APP_CLICK: "mm_getting_started_get_the_app_click",
  LOG_IN_PREVIEW: "mm_log_in",
  SIGN_UP_PREVIEW: "mm_sign_up_showed",
  DOWNLOAD_FROM_STORE_CLICK: "mm_download_from_store_click",
  SIGN_UP_LOG_IN_CLICKED: "mm_sign_up_log_in_clicked",
  LOG_IN_SIGN_UP_CLICKED: "mm_log_in_sign_up_clicked",
  LOG_IN_MANUAL_CLICK: "mm_log_in_manual_click",
  LOG_IN_FB_CLICK: "mm_log_in_fb_click",
  LOG_IN_GOOGLE_CLICK: "mm_log_in_google_click",
  LOG_IN_APPLE_CLICK: "mm_log_in_apple_click",
  SIGN_UP_MANUAL_CLICK: "mm_sign_up_manual_click",
  SIGN_UP_FB_CLICK: "mm_sign_up_fb_click",
  SIGN_UP_GOOGLE_CLICK: "mm_sign_up_google_click",
  SIGN_UP_APPLE_CLICK: "mm_sign_up_apple_click",
  OB_BACK_CLICK: "mm_ob_back_click",
  SESSION_START: "mm_session_start",
  SESSION_END: "mm_session_end",
  SHARE_LINK_COPY: "mm_share_link_copy",
  SHARE_SHOW: "mm_map_shareClicked",
  CREATE_MAP_START: "mm_create_map_start",
  POST_VIEW: "mm_post_view",
  MAP_SEARCH_RESULT_CLICK: "mm_map_search_result_click",
  MAP_SEARCH_OPEN: "mm_map_search_open",
  STEP_PAGE_OPEN: "mm_step_page_open",
  POST_PROFILE_OPEN: "mm_post_profile_open",
  MAP_SORT_LIST: "mm_map_sort_list",
  MAP_LIST_POST_SLIDER_ARROW_CLICK: "mm_map_list_post_slider_arrow_click",
  STEPS_LIST_CLOSE: "mm_steps_list_close",
  STEP_LIKE: "mm_step_like",
  FULLSCREEN_PHOTO_MORE_OPTIONS_CLICKED: "mm_stepScreen_photo_moreOptions_clicked",
  FULLSCREEN_PHOTO_SET_PHOTO_AS_DEFAULT_CLICKED: "mm_stepScreen_photo_setPhotoAsDefault_clicked",
  FULLSCREEN_PHOTO_DELETE_PHOTO_CLICKED: "mm_stepScreen_photo_deletePhoto_clicked",
  FULLSCREEN_PHOTO_DEFAULT_PHOTO_MENU_PHOTO_SET_AS_DEFAULT: "mm_stepScreen_photo_defaultPhotoMenu_photoSetAsDefault",
  FULLSCREEN_PHOTO_DEFAULT_PHOTO_MENU_CANCELLED: "mm_stepScreen_photo_defaultPhotoMenu_cancelled",
  FULLSCREEN_PHOTO_DELETE_PHOTO_MENU_PHOTO_DELETED: "mm_stepScreen_photo_deletePhotoMenu_photoDeleted",
  FULLSCREEN_PHOTO_DELETE_PHOTO_MENU_CANCELLED: "mm_stepScreen_photo_deletePhotoMenu_cancelled",
  STEP_TAGS_EDIT_OPEN: "mm_step_tags_edit_open",
  STEP_RATE_ADD_OPEN: "mm_step_rate_add_open",
  STEP_RATE_SAVE: "mm_step_rate_save",
  STEP_RATING_VIEW: "mm_step_rating_view",
  STEP_INFO_EDIT: "mm_step_info_edit",
  STEP_ARCHIVE: "mm_step_archive",
  ADD_STEP_OPEN: "mm_add_step_open",
  FILTER_OPEN: "mm_filter_open",
  FILTER_CLOSE: "mm_filter_close",
  FILTER_REMOVE: "mm_filter_remove",
  POST_PIN: "mm_post_pin",
  POST_CLAP: "mm_post_clap",
  POST_REPLY_OPEN: "mm_post_reply_open",
  STEPS_RATE_SAVE: "mm_steps_rate_save",
  ADD_STEP_FINISH: "mm_add_step_finish",
  STEP_TAG_ADD_FINISH: "mm_step_tag_add_finish",
  MAP_TAG_ADD_NEW: "mm_map_tag_add_new",
  ADD_POST_PICK_PLACE_DONE: "mm_add_post_pick_place_done",
  DOWNLOAD_APP_SHOW: "mm_download_app_show",
  DOWNLOAD_APP_DONE: "mm_download_app_done",

  OB_PAGE_OPEN: "mm_ob_page_open",
  OB_ADD_STEPS_CHOOSE: "mm_ob_add_steps_choose",
  OB_MANUAL_CONTENT_ADD: "mm_ob_manual_content_add",
  OB_INSTAGRAM_FINISH: "mm_ob_instagram_finish",
  OB_FINISH: "mm_ob_finish",
  PENDING_ADD_TO_MAP: "mm_pending_add_to_map",
  PENDING_DECLINE: "mm_pending_decline",
  CHANGE_LOCATION_CLICK: "change_location_click",
  VIEW_ON_MAP_CLICK: "view_on_map_click",
  TAGS_POPUP_OPEN: "mm_tags_popup_open",
  UPDATES_OPEN: "mm_updates_open",
  UPDATES_CLOSE: "mm_updates_close",
  ADD_LOCATION_DONE: "mm_add_step_pick_place_done",
  PAR_SHOW: "mm_par_show",
  PAR_COLLAPSE_CLICK: "mm_par_collapse_click",
  PAR_EXPAND_CLICK: "mm_par_expand_click",
  PAR_COMPLETE_TASK: "mm_par_complete_task",
  PAR_ADD_IMAGE_CLICK: "mm_add_map_image_click",
  PAR_ADD_IMAGE_SUBMIT: "mm_add_map_image_submit",
  TOOLTIP_SHOW: "mm_tooltip_show",
  TOOLTIP_GOT_IT_CLICK: "mm_tooltip_got_it_click",
  SERVER_UPDATED_HANDLED: "server_updated_handled",

  MAP_SETTINGS_SCREEN_SHOW: "mm_map_settings_screen_show",
  ADD_MAP_DESCRIPTION: "mm_add_map_description",
  MODIFY_MAP_PERMISSIONS: "mm_modify_map_permissions",
  MODIFY_MAP_KEYWORDS: "mm_modify_map_keywords",

  LV_SHOW: "mm_lv_show",
  LV_BULK_ARCHIVE_CLICK: "mm_lv_bulk_archive_click",
  LV_BULK_ADD_TAGS_CLICK: "mm_lv_bulk_add_tags_click",
  LV_BULK_CANCEL_CLICK: "mm_lv_bulk_cancel_click",
  LV_BULK_ARCHIVE_SUBMIT: "mm_lv_bulk_archive_submit",
  LV_TAG_FILTER_CLICK: "mm_lv_tag_filter_click",
  LV_SEARCH_FILTER: "mm_lv_search_filter",
  LV_SORT_CLICK: "mm_lv_sort_click",
  // Step Component events
  STEP_COMPONENTS_SCREEN_SHOW: "mm_step_components_screen_show",
  COMPONENTS_TAGS_3_DOTS: "mm_components_tags_3_dots",
  ADD_TAG: "mm_add_tag",
  TAGS_TEMPLATES_SCREEN_SHOW: "mm_tags_templates_screen_show",

  // Share Pop up events
  SHARE_CLICK: "mm_map_shareClicked",
  SAHRE_INSTAGRAM_CHECK_PHONE_CLICK: "mm_share_instagram_check_phone_click",
  SHARE_EMBED_SHOW: "mm_share_embed_show",
  SHARE_EMBED_CLICK: "mm_share_embed_click",
  MEMBERS_OPEN: "mm_members_open",
  STEPS_MAIN_SHOW: "mm_steps_main_show",
  STEP_PAGE_SHOW: "mm_step_page_show",
  IMPORT_STEPS_START: "mm_import_steps_start",
  IMPORT_STEPS_FINISH: "mm_import_steps_finish",
  ADD_POST_OPEN: "mm_add_post_open",
  ADD_IMAGE_UPLOAD: "mm_map_post_imageUploaded",
  ADD_POST_FINISH: "mm_stepCreation_stepAdded",
  ADD_REPLY_FINISH: "mm_post_reply_finished",
  ADD_POST_ADD_TEXT: "mm_map_post_textAdded",
  ROUTING_ERROR: "mm_routing_error",
  STEP_HIGHLIGHT: "mm_step_highlight",
  ADD_POST_VIEW: "app_post_view",
  MAP_INSIGHTS_OPEN: "mm_map_insights_open",
  MAP_INSIGHTS_HOVER: "mm_map_insights_hover",

  // Add Step events
  MM_STEP_CREATION_ADD_STEP_DROP_PIN_CLICKED: "mm_stepCreation_addStep_dropPinClicked",
  MM_STEP_CREATION_ADD_STEP_PHOTO_LOCATION_CLICKED: "mm_stepCreation_addStep_photoLocationClicked",
  MM_STEP_CREATION_ADD_STEP_SEARCH_EXISTING_STEP_CHOOSEN: "mm_stepCreation_addStep_search_existingStepChoosen",
  MM_STEP_CREATION_ADD_STEP_LOCATION_CHOOSEN: "mm_stepCreation_search_locationChoosen",
  MM_STEP_CREATION_ADD_STEP_SEARCH_ADDRESS_CHOOSEN: "mm_stepCreation_search_addressChoosen",
  MM_STEP_CREATION_ADD_STEP_SEARCH_NEW_PLACE_CHOOSEN: "mm_stepCreation_search_newPlaceChoosen",
  MM_STEP_CREATION_ADD_STEP_ADD_CONTENT_UPLOAD_PHOTO_CLICKED: "mm_stepCreation_addContent_uploadPhotoClicked",
  MM_STEP_CREATION_ADD_STEP_ADD_CONTENT_PHOTO_UPLOADED: "mm_stepCreation_addStep_addContent_photoUploaded",
  MM_STEP_CREATION_ADD_STEP_ADD_CONTENT_PHOTO_REMOVED: "mm_stepCreation_addStep_addCotent_photoRemoved",
  MM_STEP_CREATION_STEP_ADDED: "mm_stepCreation_stepAdded",
  MM_STEP_CREATION_CREATE_NEW_PLACE_LOCATION_ADDED: "mm_stepCreation_createNewPlace_locationAdded",
  MM_STEP_CREATION_DROP_PIN_PLACE_SELECTED_NEXT: "mm_stepCreation_dropPin_placeSelectedNext",
  MM_STEP_CREATION_DROP_PIN_PLACE_SELECTED_CANCEL: "mm_stepCreation_dropPin_placeSelectedCancel",
  MM_STEP_CREATION_ADD_NAME_NAME_ADDED: "mm_stepCreation_addName_nameAdded",
  MM_STEP_CREATION_OPEN_COMPUTER_FILES_PHOTO_ADDED: "mm_stepCreation_openComputerFiles_PhotoAdded",
  MM_STEP_CREATION_SEARCH_LOCATION_CHOOSEN: "mm_stepCreation_search_locationChoosen",
  MM_STEP_CREATION_SEARCH_ADDRESS_CHOOSEN: "mm_stepCreation_search_addressChoosen",

  TAG_CLICK: "app_post_addTagClicked",

  TAG_ADDED_TO_POST: "mm_post_tagAdded",
  POST_TAG_POPUP_SHOWED: "mm_post_tagPopupShowed",

  TAG_CLICKED: "mm_post_tagClicked",
  POST_POPUP_SHOW: "mm_feed_post_popup_showed",
  POST_POPUP_ACTION_CLICKED: "mm_feed_post_popup_actionClicked",
  FEED_POST_TAGGED: "mm_feed_post_tagged",
  POST_COMMENT_TAGGED: "mm_post_comment_tagged",
  ADD_STEP_TAGGED: "mm_add_step_tagged",
  MM_OB_LETS_ADD_STEPS_LET_US_KNOW_BUTTON: "mm_ob_letsAddSteps_letUsKnowButtonClicked",
  MM_OB_GOOGLE_KML_FILE_UPLOADED: "mm_ob_google_kmlFileUploaded",
  MM_OB_GOOGLE_YOUTUBE_VIDEO_WATCHED: "mm_ob_google_youtubeVideoWatched",
  MM_OB_GOOGLE_BACK_BUTTON_CLICKED: "mm_ob_google_backButtonClicked",
  MM_OB_EXCEL_FILE_UPLOADED: "mm_ob_excel_FileUploaded",
  MM_OB_EXCEL_TEMPLATE_DOWNLOADED: "mm_ob_excel_templateDownloaded",
  MM_OB_INSTAGRAM_CONNECT_TO_INSTAGRAM_CLICKED: "mm_ob_instagram_connectToInstagramClicked",
  MM_OB_INSTAGRAM_STEP_ADDED: "mm_ob_instagram_stepAdded",
  MM_OB_INSTAGRAM_STEP_SKIPPED: "mm_ob_instagram_stepSkipped",
  MM_OB_MANUAL_STEP_NEXT_CLICKED: "mm_ob_manualStep_nextClicked",
  MM_OB_MANUAL_STEP_ADD_IMAGE_CLICKED: "mm_ob_manualStep_AddImageClicked",
  MM_OB_MANUAL_STEP_IMAGE_ADDED: "mm_ob_manualStep_ImageAdded",
  MM_OB_LETS_CREATE_YOUR_MAP_NEXT_BUTTON_CLICKED: "mm_ob_letsCreateYourMap_nextButtonClicked",
  MM_OB_LETS_CREATE_YOUR_MAP_BACK_BUTTON_CLICKED: "mm_ob_letsCreateYourMap_backButtonClicked",
  MM_OB_LETS_CREATE_YOUR_MAP_STEPS_LOGO_CLICKED: "mm_ob_letsCreateYourMap_stepsLogoClicked",

  WELCOME_MESSAGE_SETTINGS_MESSAGE_SAVED: "mm_settings_welcomeMessage_messageSaved",
  WELCOME_MESSAGE_MAP_UPDATES_MESSAGE_SAVED: "mm_mapUpdates_messsageSaved",
  WELCOME_MESSAGE_SETTINGS_SEND_AUTOMATICALLY: "mm_settings_welcomeMessage_sendAutomaticallySet",
  WELCOME_MESSAGE_MAP_UPDATES_SEND_AUTOMATICALLY: "mm_mapUpdates_sendAutomaticallySet",
  WELCOME_MESSAGE_MAP_UPDATES_EDIT_CLICK: "mm_mapUpdates_editWelcomeMessageClicked",
  WELCOME_MESSAGE_MAP_UPDATES_SENT: "mm_mapUpdates_welcomeSent",

  MM_FEED_POST_POSTED: "mm_feed_post_posted",
  MM_POST_COMMENT_FINISHED: "mm_comment_sent",

  MM_SETTINGS_RATING_UP: "mm_settings_ratings_ratingUp",
  MM_SETTINGS_RATING_DOWN: "mm_settings_ratings_ratingDown",
  MM_SETTINGS_RATING_DELETED: "mm_settings_ratings_ratingDeleted",
  MM_SETTINGS_RATINGS_ADDED: "mm_settings_ratings_ratingAdded",

  STEP_ARCHIVED: "mm_mapUpdates_stepArchived",
  STEP_KEPT: "mm_mapUpdates_stepKept",

  MM_STEP_CREATION_RATING_SETTINGS_CLICKED: "mm_stepCreation_insights_ratingsSettingsClicked",
  MM_STEP_CREATION_RATED: "mm_stepCreation_insights_rated",
  MM_STEP_CREATION_TAG_CHOOSEN: "mm_stepCreation_insights_tagChoosen",
  MM_STEP_CREATION_ADD_CATEGORY_CLICKED: "mm_stepCreation_insights_addCategoryClicked",
  MM_STEP_CREATION_ADD_TAGS_CLICKED: "mm_stepCreation_insights_addTagsClicked",
  MM_STEP_CREATION_ADD_RATING_CATEGORY_CLICKED: "mm_stepCreation_insights_ratingsSettings_addRatingCategoryClicked",
  MM_STEP_CREATION_ADD_RATING_CATEGORY_DELETED: "mm_stepCreation_insights_ratingsSettings_categoryDeleted",
  MM_STEP_CREATION_RATING_SETTINGS_CATEGORY_ADDED: "mm_stepCreation_insights_ratingsSettings_categoryAdded",

  STEP_CREATION_SHOWED: "mm_stepCreation_showed",
  STEP_CREATION_IMPORT_CLICKED: "mm_stepCreation_addStep_importClicked",
  STEP_CREATION_SEARCH_RESULT_CLICKED: "mm_stepCreation_addStep_searchResultClicked",
  STEP_CREATION_NEW_LOCATION_NEXT_CLICKED: "mm_stepCreation_newLocation_nextClicked",
  STEP_CREATION_DID_YOU_MEAN_SELECTED: "mm_stepCreation_didYouMean_optionSelected",
  STEP_CREATION_PHOTO_LOCATION_PLACE_SELECTED_NEXT: "mm_stepCreation_photoLocation_placeSelectedNext",

  LEVEL_PROGRESS_MENU_CLICKED: "mm_levelProgress_menuClicked",
  LEVEL_PROGRESS_SHOWED: "mm_levelProgress_showed",
  LEVEL_PROGRESS_CTA_CLICKED: "mm_levelProgress_ctaClicked",
  LEVEL_PROGRESS_TOOLTIP_SHOWED: "mm_levelProgress_tooltipShowed",
  LEVEL_PROGRESS_COMPLETE_POPUP_SHOWED: "mm_levelProgress_completePopupShowed",

  ASK_AROUND_CLICKED: "web_askAround_clicked",
  ASK_AROUND_COPY_LINK_CLICKED: "web_askAround_copyLink_clicked",
  ASK_AROUND_SHOWED: "web_askAround_showed"
};

export const mixpanelProperties = {
  MAP_NAME: "map_name",
  MAP_ID: "map_id",
  USER_NAME: "user_name",
  USER_ID: "user_id",
  NUM_POST_ADDED: "num_post_added",
  NUM_STEP_ADDED: "num_step_added",
  TYPE: "type", // types on docs
  SHARE_ELEMENT: "share_element",
  FROM_WHERE: "from_where", // types on docs
  ACTION: "action",
  ACTION_TYPE: "actionType",
  PHOTO_ID: "photo_id",
  WHERE: "where", // types on docs
  IS_NEW_POST: "is_new_post",
  STEP_TITLE: "step_title",
  STEP_ID: "step_id",
  POST_ID: "post_id",
  SORT_BY: "sort_by", // types in docs
  HAS_INFO: "has_info", // types in docs
  INFO_TYPE: "info_type", // types in docs
  HOW: "how", // types in docs
  STORE: "store", //google store / app store
  NUMBER_OF_FILTERS: "number_of_filters",
  POST_CONTENT: "post_content",
  IS_SAME_USER_AS_PARENT: "is_same_user_as_parent",
  FROM_WHERE_ADD_POST: "from_where_add_post", // types in docs
  POST_ON: "post_on", // types in docs
  RESULT_TYPE: "result_type", // types on docs
  RESULT_ORDER: "result_order", // the number of result in the order (first result = 1, second result = 2)
  RESULT_NUM: "result_num", // the number of result in the order (first result = 1, second result = 2)
  NUM_OF_IMAGES: "num_of_images",
  IS_CREATE_NEW_PLACE: "is_create_new_place",
  NUM_OF_TAGS_ADD: "num_of_tags_add",
  IS_STEP_CREATED_BY_SAME_USER: "is_step_created_by_same_user",
  IS_FIRST_ON_MAP: "is_first_on_map",
  SUGGESTION: "suggestion",
  PAGE: "page", // types on docs
  ADD_STEPS: "add_steps",
  FIRST_MAP: "first_map",
  SKIP: "skip", // num
  ADD: "add", // num
  NO_LOCATION_BEFORE: "no_location_before",
  IS_PIN_ON_MAP: "is_pin_on_map",
  IS_SUGGESTION: "is_suggestion",
  CONTENT_EDIT: "content_edit", // types on docs
  LOCATION: "location", // types in docs
  IS_TAG_ADDED: "is_tag_added",
  IS_NEW_TAGS_CREATED: "is_new_tags_created",
  TAGS_SELECTED: "tags_selected",
  IS_NUMBER_INDICATION: "is_number_indication", // (true, false)
  NUM_UNHANDLED_ACTIVITIES: "num_unhandled_activities", // (number)
  NUM_HANDLED_ACTIVITIES: "num_handled_activities", // (number)
  TASK: "task", // types on docs
  NUM_COMPLETED_TASKS: "num_completed_tasks",
  COMPLETED_TASKS: "completed_tasks",
  TASK_NUM: "task_num",
  TIP: "tip", // types on docs
  CAN_FIND: "can_find",
  CAN_ADD: "can_add",
  KEYWORD_ADDED: "keyword_added",
  KEYWORD_DELETED: "keyword_deleted",
  NUM_SELECTED: "num_selected",
  WITH_FILTER: "with_filter",
  ORDER: "order", // type of column sorting
  UPDATE_TYPE: "update_type",
  TEXT: "text",
  IMAGE: "image",
  // Step Components props
  SCREEN: "screen", // values => tags/ rating, announcement
  ACT: "act", // values => rename, move to category, delete
  ADD_TO: "add_to", // value =>  map, step
  SHARE_WHAT: "share_what",
  SHARE_VIA: "share_via",
  BUTTON: "BUTTON",
  STEP_NAME: "step_name",
  SHARE: "share",
  REMOVED_USER_ID: "removed_user_id",
  TAB: "tab",
  REASON: "reason",
  HIGHLIGHT_TYPE: "highlight_type",

  FLOW: "flow",
  HAS_COORIDNATES: "has_coordinates",
  COORDINATES: "coordinates",
  IS_GOOGLE_LOCATION: "is_google_location",
  ADDRESS: "address",
  TAG: "tag",
  TAG_TYPE: "tag_type",
  PLATFORM: "platform",
  USER_TYPE_STATUS: "user_type_status",
  USER_STATUS: "USER_STATUS",
  HAS_LOCATION_TAGGED: "has_location_tagged",
  HAS_MEMBER_TAGGED: "has_member_tagged",
  LOCATION_TAGGED_COUNT: "location_tagged_count",
  MEMBER_TAGGED_COUNT: "member_tagged_count",
  TAG_ID: "tag_id",
  PARENT_POST_ID: "parent_post_id",
  IS_OB: "is_ob",
  MESSAGE_TEXT: "message_text",
  ON_OR_OFF: "on_or_off",
  IS_CUSTOM: "is_custom",
  UPDATE_ID: "update_id",
  CONTEXT: "context",
  RATING_TITLE: "rating_title",
  CATEGORY: "category",
  RATING: "rating",
  URL: "url",
  IS_NEW_PLACE: "is_new_place",
  OPEN_SOURCE: "open_source",
  CTA_NAME: "cta_name",
  TOOLTIP_TYPE: "tooltip_type",
  SOURCE_PLATFORM: "source_platform",
  CHARACTER_COUNT: "character_count",
  FLOW_TYPE: "flow_type"
};

export default mixpanel;
