export const GET_STEP_BY_ID = 'GET_STEP_BY_ID';
export const LIKE_STEP = 'LIKE_STEP';
export const EDIT_STEP_RATING = 'EDIT_STEP_RATING';
export const EDIT_STEP_LOCATION = 'EDIT_STEP_LOCATION';
export const RESTORE_STEP = 'RESTORE_STEP';
export const CHANGE_STEP_TYPE = 'CHANGE_STEP_TYPE';
export const DELETE_TAG_FROM_STEP = 'DELETE_TAG_FROM_STEP';
export const GET_FULL_STEP_DATA = 'GET_FULL_STEP_DATA';
export const ADD_NEW_TAG_TO_STEP = 'ADD_NEW_TAG_TO_STEP';
export const ADD_TAG_TO_STEP = 'ADD_TAG_TO_STEP';
export const GET_POSTS = 'GET_POSTS';
export const ADD_TAGS = 'ADD_TAGS';
export const CREATE_POST = 'CREATE_POST';
export const CREATE_STEP_FROM_PLACE = 'CREATE_STEP_FROM_PLACE';
export const UPDATE_STEP = 'UPDATE_STEP';
export const DELETE_IMAGE = 'DELETE_IMAGE';
