import React, { useState, useEffect, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Outlet, useOutletContext, generatePath, createSearchParams } from 'react-router-dom';
import Map from '../Map';
import { stepActions } from '../../redux/step';
import { mapActions, mapSelectors } from '../../redux/map';
import ModalsDeletePost from '../../components/common/modals/ModalsDeletePost';
import { postActions } from '../../redux/post';
import ModalsCreatePost from '../../components/common/modals/ModalsCreatePost';
import { userSelectors } from '../../redux/user';
import { useDebounce, useUnmount } from '../../hooks';
import FilterTags from '../../components/FilterTags';
import { userActionActions as userAction, userActionActions, userActionSelectors } from '../../redux/userAction';
import mixpanel, { mixpanelProperties, mixpanelEvents } from '../../helpers/mixpanel';
import { modalsSelectors } from '../../redux/modals';
import ImagePreview from '../../components/common/ImagePreview';
import ModalsEditPost from '../../components/common/modals/ModalsEditPost';
import classNames from 'classnames';
import GuideTask from '../../components/GuideTask';
import ModalsAddImage from '../../components/common/modals/ModalsAddImage';
import ModalsAddDescription from '../../components/common/modals/ModalsAddDescription';
import Routes from '../../config/routes';
import { TabsView, routeTabs } from './constants';
import { DropPinViewProvider } from '../../components/AddStep/components/DropPinView';
import WhatsNew from '../../components/modals/WhatsNew';
import { feedActions } from '../../redux/feed';
import { defaultStepList } from '../../redux/map/reducer';

const TYPE_TASKS = ['MAP_MANAGER_JOURNEY_TASKS_ADDED_FIVE_STEPS', 'MAP_MANAGER_JOURNEY_TASKS_ONE_MEMBER_JOINED_MAP'];

const StepsManager = () => {
    const {
        selectedStep,
        pendingStep,
        selectedMapId,
        setSharePopup,
        setBulkLoader,
        importedStepsFrom,
        showStepOnMap,
        setShowStepOnMap,
        forceOpenAddPostComponent,
        setForceOpenAddPostComponent,
        activeTabSteps,
        setActiveTabSteps,
        onStepsManagerCleanUp,
        navigateToAddStep,
        onClickImportFrom,
    } = useOutletContext();
    useUnmount(onStepsManagerCleanUp);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [openedDeletePostPopup, setOpenPostPopup] = useState(false);
    const [openedTaskImagePopup, setOpenedTaskImagePopup] = useState(false);
    const [openedTaskDescriptionPopup, setOpenedTaskDescriptionPopup] = useState(false);
    const [openedEditPostPopup, setOpenEditPostPopup] = useState(false);
    const [isSideListOpen, setIsSideListOpen] = useState(true);
    const [archiveData, setArchiveData] = useState(null);
    const [boldStepId, setBoldStepId] = useState(null);
    const boldStepDebounce = useDebounce(boldStepId, 100);
    const map = useSelector(mapSelectors.map(selectedMapId));
    const [showModals, setShowModals] = useState(false);
    const [isMapClean, setIsMapClean] = useState(false);
    const user = useSelector(userSelectors.user);
    const [selectedTags, setSelectedTags] = useState([]);
    const isFiltered = selectedTags.length > 0;
    const [clickReplyPage, setClickReplyPage] = useState(null);
    const [pageClick, setPageClick] = useState(null);
    const goto = useSelector(userActionSelectors.goto);
    const [dataRequest, setDataRequest] = useState({});
    const [disableClickOnMap, setDisableClickOnMap] = useState(true);
    const [deletePostImage, setDeletePostImage] = useState(null);
    const [addImage, setAddImage] = useState(false);
    const [editPostData, setEditPostData] = useState(null);
    const initialRoute = location.pathname?.replace('/', '');
    const [baseRoute] = initialRoute.split('/');
    const [selectedTab, setSelectedTab] = useState(routeTabs[baseRoute]);
    const [tab, setTab] = useState(routeTabs[baseRoute]);
    const [updatedStepId, setUpdatedStepId] = useState(null);
    const [trigStepPreviewClick, setTrigStepPreviewClick] = useState(null);
    const mapId = useSelector(userSelectors.selectedMapId);
    const guideTaskCompleted = useSelector(mapSelectors.guideTaskCompleted(mapId));
    const steps = useSelector(mapSelectors.steps(mapId));
    const stepsList = useSelector(mapSelectors.map(mapId))?.currentList;
    const filteredArrLength = guideTaskCompleted && TYPE_TASKS.filter(e => guideTaskCompleted[e] == true).length;
    const percent = (filteredArrLength * 100) / 2;
    const [secondSliderPages, setSecondSliderPages] = useState([]);
    const [trigConfirmLocation, setTrigConfirmLocation] = useState(null);
    const [trigAddStep, setTrigAddStep] = useState(null);
    const [pendingPostSelected, setPendingPostSelected] = useState(null);
    const whatsNew = useSelector(userSelectors.whatsNew);
    const [fitBoundsBySteps, setFitBoundsBySteps] = useState([]);
    const [show, setShow] = useState(false);
    const [openedTagsFilter, setOpenedTagsFilter] = useState(false);

    useEffect(() => {
        if (!goto) return;
        switch (goto) {
            case 'add_step':
                setTimeout(() => {
                    navigateToAddStep();
                }, [100]);
                break;

            default:
                break;
        }
        dispatch(userActionActions.goto(null));
    }, [goto]);

    useEffect(() => {
        if (trigStepPreviewClick) {
            navigateToStepProfile({ step: steps[trigStepPreviewClick.stepId], openTags: trigStepPreviewClick.area === 'tags', fromWhere: 'map_preview' });
            setIsSideListOpen(true);
        }
    }, [trigStepPreviewClick]);

    useEffect(() => {
        const ghostStep = trigConfirmLocation;
        if (!ghostStep) {
            return;
        }

        (async () => {
            if (pendingPostSelected) {
                const tempPendingPosts = { ...pendingPostSelected };
                tempPendingPosts.lat = ghostStep.position.lat;
                tempPendingPosts.lon = ghostStep.position.lon || ghostStep.position.lng;
                tempPendingPosts.location_name = ghostStep.title;
                tempPendingPosts.is_location_specific = true;
                tempPendingPosts.locationType = ghostStep.type;
                tempPendingPosts.locationId = ghostStep._id;
                await dispatch(mapActions.setPedningPostCache(mapId, tempPendingPosts));
                await dispatch(mapActions.setGhostStep(mapId, null));
                setPendingPostSelected(null);
                setIsMapClean(false);
                setDisableClickOnMap(true);
                navigate(`/${Routes.IMPORTED}`);
            } else if (ghostStep.changeLocationStepId) {
                await dispatch(
                    mapActions.editStepLocation(mapId, {
                        stepId: ghostStep.changeLocationStepId,
                        lat: ghostStep.position.lat,
                        lon: ghostStep.position.lon || ghostStep.position.lng,
                        title: ghostStep.title,
                        place_id: ghostStep.type === 'place' ? ghostStep._id : null,
                        location_id: ghostStep.type === 'location' ? ghostStep._id : null,
                    })
                );
                await dispatch(mapActions.setGhostStep(mapId, null));
                setIsMapClean(false);
                setDisableClickOnMap(true);
                navigate('/' + Routes.MAP);
            }
        })();
    }, [trigConfirmLocation]);

    useEffect(() => {
        if (!trigAddStep) return;
        const ghostStep = trigAddStep;
        const ghostStepPosition = ghostStep.position;
        const resultType = ghostStep.type;
        createStep({
            lat: ghostStepPosition.lat,
            lon: ghostStepPosition.lon,
            title: ghostStep.title,
            placeId: resultType === 'place' ? ghostStep._id : null,
            locationId: resultType === 'location' ? ghostStep._id : null,
        });
        setTrigAddStep(null);
    }, [trigAddStep]);

    useEffect(() => {
        if (tab === TabsView.MAP) {
            dispatch(userAction.setCurrentPage('steps-mapView'));
            handleSetFrom('map-view');
        }
        setActiveTabSteps(false);
    }, [activeTabSteps]);

    useEffect(() => {
        if (showStepOnMap) {
            handleSetFrom('map-view');
            setTimeout(() => {
                navigateToStepProfile({ step: showStepOnMap });
                setShowStepOnMap(null);
            }, 300);
            dispatch(
                mapActions.setMapCenter(mapId, {
                    position: [showStepOnMap.lat, showStepOnMap.lon],
                    zoom: 18,
                })
            );
            dispatch(stepActions.getStep(showStepOnMap._id));
        }
    }, [showStepOnMap]);

    useEffect(() => {
        clearFilteredTag();
        setIsMapClean(false);
        setSelectedTab(tab);
        setFitBoundsBySteps([]);

        // reset steps list cache
        dispatch(mapActions.updateStepsList(mapId, defaultStepList));
        dispatch(feedActions.resetFeed());
    }, [map?._id]);

    useEffect(() => {
        return () => {
            dispatch(mapActions.updateStepsList(mapId, defaultStepList));
        };
    }, []);

    useEffect(() => {
        localStorage.removeItem('is-instagram-failed');
        if (localStorage.getItem('importMode') == 'Instagram-success') {
            setSelectedTab(TabsView.IMPORT);
            localStorage.removeItem('importMode');
        }
    }, []);

    useEffect(() => {
        if (importedStepsFrom && (importedStepsFrom.src == 'Google' || importedStepsFrom.src == 'Excel')) setSelectedTab(routeTabs.map);
    }, [importedStepsFrom]);

    useEffect(() => {
        pendingPostSelected && setSecondSliderPages([]);
        setIsMapClean(false);
        pendingPostSelected && dispatch(mapActions.setMapCenter(mapId, null));
        setDisableClickOnMap(true);
        dispatch(mapActions.setGhostStep(mapId, null));
        setSecondSliderPages([]);
        selectedTab !== TabsView.MAP && dispatch(stepActions.changeStatusSteps('active'));
        setTab(selectedTab);
        setActiveTabSteps(false);
    }, [selectedTab]);

    const pushSecondSliderPage = page => {
        setSecondSliderPages(state => [...state, page]);
    };

    const handleDeletePostPopup = (value, data, click) => {
        setArchiveData(data);
        setPageClick(click);
        setOpenPostPopup(value);
    };

    const handleSetFrom = e => {
        if (['filter-tags', 'search-steps', 'add-post'].includes(e)) setIsSideListOpen(true);
    };

    const handleSelectClick = useCallback(
        tagData => {
            const newSelectedTags = selectedTags?.find(item => item._id === tagData._id)
                ? selectedTags.filter(tag => tag._id !== tagData._id)
                : [...selectedTags, tagData];
            setSelectedTags(newSelectedTags);
        },
        [selectedTags, isFiltered, map]
    );

    const handlePostPageClick = postId => {
        if (!postId) navigate(-1);
        dispatch(postActions.getStepPost(postId));
    };

    useEffect(() => {
        if (selectedStep) navigateToStepProfile({ step: selectedStep });
    }, [selectedStep]);

    const clearFilteredTag = () => {
        setSelectedTags([]);
    };

    const handleFilter = tags => {
        setSelectedTags(tags);
    };

    const navigateToStepProfile = ({ step, openTags, fromWhere, viewOnMap, editLocation = true }) => {
        mixpanel.track(mixpanelEvents.STEP_PAGE_OPEN, {
            [mixpanelProperties.STEP_TITLE]: step?.title,
            [mixpanelProperties.FROM_WHERE]: fromWhere,
        });
        const stepId = step?._id || step?.step_id || step;
        setBoldStepId(stepId);
        const queryParams = createSearchParams({ openTags, viewOnMap, editLocation });
        const pathname = generatePath(Routes.STEP_PROFILE, { stepId });
        navigate({ pathname, search: queryParams.toString() });
    };

    useEffect(() => {
        if (forceOpenAddPostComponent) {
            navigateToAddStep();
            setForceOpenAddPostComponent(false);
        }
    }, [forceOpenAddPostComponent]);

    const createStep = async ({ lat, lon, title, placeId, locationId }) => {
        const { data } = await dispatch(mapActions.createStepPost(mapId, { lat, lon, title, placeId, locationId }));
        const stepId = data.step?._id;
        const existingStepId = data.post?.step_id;
        let step = data.step;

        mixpanel.track(mixpanelEvents.ADD_POST_FINISH, {
            [mixpanelProperties.FROM_WHERE]: 'search',
            [mixpanelProperties.STEP_ID]: stepId || existingStepId,
            [mixpanelProperties.IMAGE]: false,
            [mixpanelProperties.TEXT]: false,
        });

        dispatch(mapActions.setGhostStep(mapId, null));
        setDisableClickOnMap(true);

        if (!data || !step) {
            const response = await dispatch(mapActions.getFullStepData(mapId, existingStepId));
            if (response?.data?.step) {
                step = response?.data?.step;
            }
        }

        step && navigate(generatePath(Routes.STEP_PROFILE, { stepId: step._id }));
    };

    const updateStepPostsCount = (stepId, deleteCount, countComments) => {
        const data = {
            stepId,
            delete: deleteCount,
            count_comments: countComments,
        };
        if (!deleteCount) {
            delete data.delete;
        }

        const step = stepsList?.steps?.find(elem => elem?._id === data?.stepId);
        if (step) {
            if (data.delete) {
                step.posts_count = step.posts_count - 1 - data?.count_comments;
            } else {
                step.posts_count = step.posts_count + 1;
            }
            dispatch(mapActions.updateStepsList(mapId, stepsList));
        }
    };

    const onClickFilterButton = () => {
        setOpenedTagsFilter(true);
        mixpanel.track(mixpanelEvents.FILTER_OPEN, {
            [mixpanelProperties.FROM_WHERE]: 'list',
        });
    };

    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setShow(true);
            }, 3000);
        }
        if (
            map?.new_pending_posts_count > 0 &&
            map?.flags?.MAP_MANAGER_TOOLTIPS?.TOOLTIP_REVIEW_PENDING &&
            (localStorage.getItem('tooltipStorage').length !== undefined && localStorage.getItem('tooltipStorage').split(',').includes('stepPending')
                ? false
                : true) &&
            show
        ) {
            mixpanel.track(mixpanelEvents.TOOLTIP_SHOW, {
                [mixpanelProperties.TIP]: 'pending_review',
            });
        }
    }, [show, map?.new_pending_posts_count]);

    useEffect(() => {
        if (!pendingStep) return;
        setPendingPostSelected(pendingStep);
        handleSetFrom('map-view');
        navigate(`/${Routes.MAP}/${Routes.LOCATION}`, { state: { pendingPost: pendingStep } });
        setIsMapClean(true);
        if (pendingStep.lat && (pendingStep.lon || pendingStep.lng))
            dispatch(
                mapActions.setMapCenter(mapId, {
                    position: [pendingStep.lat, pendingStep.lon || pendingStep.lng],
                    zoom: 13,
                })
            );
    }, [pendingStep]);

    const triggerAddPostModal = (step, options = {}) => {
        const { triggerAddImage } = options;
        setShowModals(true);
        if (triggerAddImage) setAddImage(true);
        dispatch(
            userActionActions.setSelectedPlaceToAdd({
                title: step?.title || step?.location_title,
                position: [step?.lat, step?.lon],
                type: 'step',
                stepId: step?._id || step?.step_id,
            })
        );
    };

    const outletContext = {
        setIsMapClean,
        setShowModals,
        setAddImage,
        setBoldStepId,
        setDisableClickOnMap,
        setPendingPostSelected,
        setFitBoundsBySteps,
        updateSelectedTags: handleSelectClick,
        clearFilteredTag,
        onClickFilterButton,
        createStep,
        handleFilter,
        navigateToAddStep,
        navigateToStepProfile,
        triggerAddPostModal,
        setBulkLoader,
        importedStepsFrom,
        pendingStep,
        step: {},
        setTrigConfirmLocation,
        onClickImportFrom,
        setSharePopup,
    };

    return (
        <>
            {!!whatsNew && <WhatsNew />}
            <div className={styles.containerBlock}>
                <div className={styles.container}>
                    <DropPinViewProvider>
                        <div className={styles.sideContainer}>
                            <div className={classNames(styles.slider, styles.sliderPaddingTop)}>
                                <Outlet context={outletContext} />
                            </div>
                        </div>
                        <div className={isSideListOpen ? styles.mapViewMini : styles.mapViewFull}>
                            <Map
                                mapId={mapId}
                                hiddenSteps={isMapClean}
                                disableClickOnMap={disableClickOnMap}
                                boldStepId={boldStepDebounce}
                                selectedTags={selectedTags}
                                onClickStepPreview={({ step, area, triggerAddImage }) => {
                                    const stepId = step._id;
                                    const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId });
                                    navigate(url);
                                    if (triggerAddImage) {
                                        setShowModals(true);
                                        setAddImage(true);
                                        dispatch(
                                            userActionActions.setSelectedPlaceToAdd({
                                                title: step?.title || step?.location_title,
                                                position: [step?.lat || step?.lat, step?.lon || step?.lon],
                                                type: 'step',
                                                stepId: stepId || step?.step_id,
                                            })
                                        );
                                    }
                                }}
                                onConfirmLocation={ghostStep => setTrigConfirmLocation(ghostStep)}
                                onAddStep={(ghostStep, isDropPinView) => {
                                    if (isDropPinView) {
                                        return;
                                    }
                                    setTrigAddStep(ghostStep);
                                }}
                                zoomPanelClassName={
                                    percent < 100 && guideTaskCompleted && !pendingPostSelected && disableClickOnMap && !isMapClean
                                        ? styles.zoomPanelWithGuideTasks
                                        : styles.zoomPanel
                                }
                                fitBoundsBySteps={fitBoundsBySteps}
                                isFiltered={isFiltered}
                            />
                        </div>
                    </DropPinViewProvider>
                </div>
                {showModals && (
                    <ErrorBoundary fallback={<span />}>
                        <ModalsCreatePost
                            updateStepPostsCount={updateStepPostsCount}
                            setDataRequest={setDataRequest}
                            addImage={addImage}
                            setUpdatedStepId={setUpdatedStepId}
                            onCancel={() => {
                                setAddImage(false);
                                setShowModals(false);
                                setClickReplyPage(null);
                                setIsMapClean(false);
                                dispatch(userActionActions.setSelectedPlaceToAdd(null));
                                dispatch(mapActions.setGhostStep(mapId, null));
                            }}
                            onClose={() => setShowModals(false)}
                            user={user}
                            clickReplyPage={clickReplyPage}
                            mapId={mapId}
                            listView={tab === TabsView.LIST}
                        />
                    </ErrorBoundary>
                )}
                {openedEditPostPopup && (
                    <ModalsEditPost
                        addImage={addImage}
                        onCancel={() => {
                            setOpenEditPostPopup(false);
                            setEditPostData(null);
                        }}
                        stepId={editPostData?.step_id}
                        // TODO: can you reply to a post? cuz we've got comments now
                        post={editPostData}
                        clickReplyPage={clickReplyPage}
                    />
                )}
                {openedTagsFilter && (
                    <FilterTags
                        isFiltered={isFiltered}
                        clearFilteredTag={clearFilteredTag}
                        onClickPrev={() => setOpenedTagsFilter(false)}
                        selectedTags={selectedTags}
                        // TODO: these two might be redundant as there's a TagContextProvider
                        // which contains the same informaton and is actually used in StepsList
                        handleFilter={handleFilter}
                        handleSelectClick={handleSelectClick}
                    />
                )}
                {openedDeletePostPopup && (
                    <ModalsDeletePost
                        isLastPost={true}
                        isDeletePost={false}
                        updateStepPostsCount={updateStepPostsCount}
                        setDeletePostImage={setDeletePostImage}
                        handlePostPageClick={handlePostPageClick}
                        pageClick={pageClick}
                        onClickPrev={() => navigate(-1)}
                        archiveData={archiveData}
                        handleDeletePostPopup={handleDeletePostPopup}
                    />
                )}
                <ImagePreview />
                {openedTaskImagePopup && <ModalsAddImage percent={percent} mapId={map?._id} onClose={() => setOpenedTaskImagePopup(false)} />}
                {openedTaskDescriptionPopup && <ModalsAddDescription percent={percent} mapId={map?._id} onClose={() => setOpenedTaskDescriptionPopup(false)} />}
                {map.map_level === 0 && percent < 100 && guideTaskCompleted && !pendingPostSelected && disableClickOnMap && !isMapClean && (
                    <div className={styles.absoluteGuideBlock}>
                        <GuideTask
                            percent={percent}
                            mapId={mapId}
                            onClickAddStep={() => {
                                localStorage.setItem('fromWhereAddPost', 'parallel_guide');
                                handleSetFrom('map-view');
                                navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`);
                            }}
                            onClickSharePopup={() => {
                                localStorage.setItem('clickShare', 'click');
                                mixpanel.track(mixpanelEvents.SHARE_LINK_COPY, {
                                    [mixpanelProperties.TYPE]: 'map',
                                    [mixpanelProperties.FROM_WHERE]: 'parallel_guide',
                                });
                                setSharePopup('parallel');
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default StepsManager;
