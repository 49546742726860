import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { mapSelectors } from '../../redux/map';
import styles from './index.module.scss';
import { mapActions } from '../../redux/map';
import TagCategory from '../TagCategory';
import TagSearchResultList from '../TagSearchResultList';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import IconPluse from '../common/icons/IconPluse';
import classNames from 'classnames';
import IconX from '../common/icons/IconX';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoTags from '../NoTags';
import TagsTemplate from '../TagsTemplate';
import { mapTagObjects } from '../../helpers';
import Loader from '../Loader';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/grayCircleAnimation.json';
import mixpanel, { mixpanelEvents } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';

const copies = copiesPrefix.tags;

const useStyles = makeStyles(() => ({
    tooltipBlock: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        color: '#fff',
        fontSize: '15px',
        maxWidth: '210px',
        fontWeight: 'normal',
        padding: '8px 16px',
        borderRadius: '4px',
        backgroundColor: '#3a3c3f',
        '& .MuiTooltip-arrow:before': {
            backgroundColor: '#3a3c3f',
        },
    },
}));

const TagSystem = () => {
    const [toggleAddCategory, setToggleAddCategory] = useState(false);
    const [toggleSearchCategory, setToggleSearchCategory] = useState(false);
    const [toggleAddSearchResultToCategory, setToggleAddSearchResultToCategory] = useState(false);
    const [addActive, setAddActive] = useState(false);
    const [disable, setDisable] = useState(true);
    const [error, setError] = useState(false);
    const [errorDuplicate, setErrorDuplicate] = useState(false);
    const [loader, setLoader] = useState(true);
    const [addCategoryDropDownIsOpen, setAddCategoryDropDownIsOpen] = useState(false);
    const [tagsTemplateIsOpen, setTagsTemplateIsOpen] = useState(false);

    // selectors
    const inputRef = useRef();
    const mapId = useSelector(state => state.user.selectedMapId);
    const obj_tags = useSelector(mapSelectors.mapTags(mapId));
    const tagsCategories = useSelector(state => state[mapId].tags_categories);
    const [search, setSearch] = useState('');
    const [localCategories, setLocalCategories] = useState(tagsCategories);
    const dispatch = useDispatch();
    const classes = useStyles();
    const divRef = useRef(null);

    useEffect(() => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 500);
    }, [mapId]);

    useEffect(() => {
        mapTagObjects(obj_tags, tagsCategories);
        tagsCategories.sort((a, b) => {
            return b.priority - a.priority;
        });
        setLocalCategories(tagsCategories);
    }, [search, divRef, errorDuplicate, tagsCategories, localCategories, toggleAddCategory, toggleSearchCategory, toggleAddSearchResultToCategory, obj_tags]);

    useEffect(() => {
        setSearch('');
        setToggleAddCategory(false);
        setToggleAddSearchResultToCategory(false);
    }, [mapId]);

    useEffect(() => {
        if (toggleAddCategory) {
            inputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [toggleAddCategory]);

    const handleAddNewCategoryButton = e => {
        if (!toggleAddCategory) {
            setToggleAddCategory(true);
        } else if (toggleAddCategory) {
            inputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    // Setting UI for new category input
    const onChange = () => {
        const categoryName = inputRef.current?.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');

        const res = tagsCategories.find(
            item =>
                item.name
                    .replace(/^\s+|\s+$/g, '')
                    .replace(/\s+/g, ' ')
                    .toLowerCase() === categoryName.toLowerCase()
        );
        if (!res) {
            setError(false);
            setErrorDuplicate(false);
            setDisable(true);
            if (categoryName.length > 0) {
                setDisable(false);
            } else {
                setDisable(true);
            }
        } else {
            setErrorDuplicate(true);
            setDisable(true);
        }
    };

    const handleBlur = e => {
        if (e.target.value !== '') {
            setToggleSearchCategory(true);
        } else {
            setToggleSearchCategory(false);
        }
    };

    const handleAddNewCategory = () => {
        setAddActive(false);
        setDisable(true);
        let name = inputRef.current?.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');
        if (name === '') {
            return handleUnsavedCategory();
        }
        const newCat = {
            _id: inputRef.current?.value,
            priority: 0,
            name: name,
        };

        dispatch(mapActions.addNewCategories(mapId, [newCat])).then(response => {
            dispatch(mapActions.editCategoryName(mapId, response.data.categories[0]._id, newCat.name));
            // This is a hack to make sure that the server will allocate the rest of the categories an ascending priority
            dispatch(mapActions.editCategoryPriority(mapId, response.data.categories[0]._id, 0)).then(() => {
                // Get all ccategories sorted by priority from the BackEnd
                dispatch(mapActions.fetchSingleMap(mapId));
            });
        });
        setToggleAddCategory(false);
    };

    const handleUnsavedCategory = () => {
        setToggleAddCategory(false);
    };

    const handleKeyPressOnCategoryInput = e => {
        if (e.charCode === 13 && e.target.value.length !== 0 && !disable) {
            handleAddNewCategory();
        }
    };

    const onClickAway = () => {
        if (inputRef.current?.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ').length > 0) {
            if (inputRef.current?.value?.trim().length !== 0) {
                if (!disable) {
                    handleAddNewCategory();
                }
            } else {
                inputRef.current.value = '';
                setError(true);
                setDisable(true);
            }
        } else {
            setToggleAddCategory(false);
            setAddActive(false);
            setDisable(true);
        }
    };

    const handleSingleCategoryAdding = category => {
        const newTag = {
            category_priority: category.priority,
            name: search.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '),
            category_id: category._id,
        };
        let isTagExist = obj_tags.find(t => {
            return t.name.toLowerCase() === newTag.name.toLowerCase() && t.category_id === newTag.category_id;
        });
        if (isTagExist === undefined) {
            dispatch(mapActions.addNewTagToMap(mapId, { tags: [newTag] }));
            setToggleAddSearchResultToCategory(false);
            setSearch('');
        }
    };

    let filteredCategories = [];
    let filteredTags = [];
    let categoryIds = [];
    let ans = false;
    if (search !== '') {
        filteredCategories = [...localCategories];
        filteredTags = obj_tags;
        const reg = new RegExp(search, 'i');
        filteredTags = obj_tags.filter(tag => reg.test(tag.name));
        filteredTags.map(t => {
            if (t.name === search) {
                categoryIds.push(t.category_id);
                ans = true;
            }
        });

        if (categoryIds.length > 0) {
            var index;
            categoryIds.map(cId => {
                index = filteredCategories.findIndex(c => {
                    return c._id === cId;
                });
                if (index !== -1) filteredCategories.splice(index, 1);
            });
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    return (
        <div className={styles.categoriesContainer}>
            <div className={styles.searchCategory}>
                <CustomButton
                    onClick={() => setAddCategoryDropDownIsOpen(prevState => !prevState)}
                    size={'smallSize'}
                    type={ButtonType.SECONDARY_BLUE}
                    text={
                        <>
                            <IconPluse stroke={'#2171ec'} />
                            {copies.add_category}
                            <img src='/assets/img/arrowDownBlue.svg' className={styles.arrow} alt='' />
                        </>
                    }
                ></CustomButton>
                {addCategoryDropDownIsOpen && (
                    <ClickAwayListener onClickAway={() => setAddCategoryDropDownIsOpen(false)}>
                        <ul className={styles.ulClassParrent}>
                            <li
                                onClick={() => {
                                    setAddCategoryDropDownIsOpen(false);
                                    handleAddNewCategoryButton();
                                }}
                                className={styles.parent}
                            >
                                <a className={styles.text}>
                                    <img alt='' src='/assets/img/newCategory.svg' />
                                    {copies.new_category}
                                </a>
                            </li>
                            <li
                                onClick={() => {
                                    setAddCategoryDropDownIsOpen(false);
                                    setTagsTemplateIsOpen(true);
                                    mixpanel.track(mixpanelEvents.TAGS_TEMPLATES_SCREEN_SHOW);
                                }}
                                className={styles.parent}
                            >
                                <a className={classNames(styles.text)}>
                                    <img alt='' src='/assets/img/tagTemplates.svg' />
                                    {copies.choose_from_template}
                                </a>
                            </li>
                        </ul>
                    </ClickAwayListener>
                )}
                {/* English Hebrew Digits */}
                {/* onChange={(e) => { setSearch(e.target.value.replace(/[^a-zA-Z0-\9u0590-\u05FF]/g, '')) }} */}
                {/* eNGLISH, hEBREW, DIGITS AND SPACES */}
                {/* onChange={(e) => { setSearch(e.target.value.replace(/[^\\a-zA-Z0-\9u0590-\u05FF-,.-]/g, '')) }} */}

                <div className={styles.searchBlock}>
                    <img className={styles.searchIcon} alt={''} src={'/assets/img/steps3Images/iconSearchForTags.svg'} />
                    {search && <img className={styles.xIcon} alt={''} src={'/assets/img/xIcon.svg'} onClick={() => setSearch('')} />}
                    <input
                        onChange={e => {
                            setSearch(e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''));
                        }}
                        onBlur={e => handleBlur(e)}
                        value={search}
                        placeholder={copies.search_placeholder}
                        className={classNames(styles.inputSearchTags, {
                            [styles.onFocus]: search.length > 0,
                        })}
                        pattern={/[^<>'\"/;`%*$]+$/}
                    />
                </div>
            </div>
            {loader ? (
                <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={120} />
            ) : localCategories.length > 0 ? (
                <TagCategory
                    mapId={mapId}
                    categories={localCategories}
                    isFiltered={search.replace(/\s/g, '') !== ''}
                    tags={search !== '' ? filteredTags : obj_tags}
                />
            ) : (
                <NoTags
                    onClickTagTemplates={() => {
                        setAddCategoryDropDownIsOpen(false);
                        setTagsTemplateIsOpen(true);
                    }}
                />
            )}
            {
                // Check if use wants to add 'Category'
                toggleAddCategory && (
                    <ClickAwayListener onClickAway={() => onClickAway()}>
                        <div className={styles.addNewCategoryContainer}>
                            <div className={classNames({ [styles.enableInput]: !disable }, { [styles.disableInput]: disable })}>
                                <input
                                    autoFocus
                                    className={styles.input}
                                    ref={inputRef}
                                    onChange={onChange}
                                    onKeyPress={e => handleKeyPressOnCategoryInput(e)}
                                    placeholder={copies.category_name_placeholder}
                                />

                                <button className={styles.firstButton} onClick={handleUnsavedCategory}>
                                    <IconX color={disable ? '#B5B7BB' : '#777a80'} />
                                </button>
                                <Tooltip
                                    disableHoverListener={!errorDuplicate}
                                    TransitionComponent={Zoom}
                                    placement='top-center'
                                    arrow
                                    classes={{ tooltip: classes.tooltipBlock }}
                                    title={copies.category_exists}
                                >
                                    <button
                                        className={classNames({ [styles.enable]: !disable }, { [styles.disable]: disable })}
                                        onClick={!disable ? () => handleAddNewCategory(inputRef) : () => {}}
                                        style={{ border: 'none' }}
                                    >
                                        <img style={{ marginTop: '4px' }} src='/assets/img/iconCheckWhite.svg' alt='' />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </ClickAwayListener>
                )
            }
            {
                // Once the user search for a string.
                // this REGEX is used to detect empty strings with spaces only
                search.replace(/\s/g, '') !== '' && (localCategories.length && !ans) > 0 && (
                    <div className={styles.createNewTagForCategory}>
                        <button
                            className={styles.createNewTagButton}
                            style={{
                                backgroundColor: toggleAddSearchResultToCategory ? '#2171ec' : '#fff',
                                color: toggleAddSearchResultToCategory ? '#ffffff' : '#666a70',
                                border: toggleAddSearchResultToCategory ? '1px solid #2171ec' : 'solid 1px #666a70',
                            }}
                            onClick={() => {
                                // In case of 1 category, submit the search result as a new category,
                                // else display the list TagSearchResultList
                                if (localCategories.length === 1) {
                                    handleSingleCategoryAdding(localCategories[0]);
                                    setToggleAddSearchResultToCategory(false);
                                } else {
                                    setToggleAddSearchResultToCategory(!toggleAddSearchResultToCategory);
                                }
                            }}
                        >
                            + Create new tag '{search}'
                        </button>
                        {toggleAddSearchResultToCategory && localCategories.length > 1 && (
                            <TagSearchResultList
                                isFirstTagForMap={obj_tags.length > 0 ? false : true}
                                categories={filteredCategories}
                                searchInput={search}
                                setSearch={setSearch}
                                toggleAddSearchResultToCategory={toggleAddSearchResultToCategory}
                                setToggleAddSearchResultToCategory={setToggleAddSearchResultToCategory}
                            />
                        )}
                    </div>
                )
            }
            {tagsTemplateIsOpen && <TagsTemplate onCancel={() => setTagsTemplateIsOpen(false)} mapId={mapId} />}
        </div>
    );
};

export default TagSystem;
