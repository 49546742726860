import { flattenDeep } from 'lodash';
import { TextArrayTextItem, TextArrayLocationItem, TextArrayUserItem } from '../../../models';
import { TagTypes } from '../../../constants';
import { TextArrayItemTypes } from '../constants';

const TAG_CLASS = 'tag';
const ACTION_ELEMENT_HEIGHT = 32;

export const createLocationTagSpan = ({ id, type, name } = {}) => {
    const span = document.createElement('span');
    if (!id) {
        return span;
    }
    span.classList.add(TAG_CLASS);
    id && span.setAttribute('id', id);
    id && span.setAttribute('data-id', id);
    type && span.setAttribute('data-type', type);
    name && span.setAttribute('data-name', name);
    return span;
};

const getAttribute = (node, name) => {
    let attribute;
    try {
        attribute = node.querySelector(`[${name}]`).getAttribute(name);
    } catch (err) {
        attribute = node.getAttribute(name);
    }
    return attribute;
};

export const mapNodes = htmlNodes => {
    const textArray = [];
    const nodes = htmlNodes?.childNodes || htmlNodes;
    for (const node of nodes) {
        // TODO: replace instead of insert to simplify this
        const hasChildrenNodes = node.childNodes?.length > 0 && Array.from(node.childNodes).filter(node => node.nodeName !== '#text').length > 0;
        const isTextNode = node.nodeName === '#text';
        const tagName = getAttribute(isTextNode ? node.parentElement : node, 'data-name');
        const tagType = getAttribute(isTextNode ? node.parentElement : node, 'data-type');
        const tagId = getAttribute(isTextNode ? node.parentElement : node, 'data-id');
        const userId = getAttribute(isTextNode ? node.parentElement : node, 'data-userid');
        const isBreakline = node.nodeName === 'BR';

        const text = node.textContent;
        if (node.nodeName === '#text') {
            textArray.push(new TextArrayTextItem(text));
            continue;
        }
        if (hasChildrenNodes) {
            textArray.push(mapNodes(node.childNodes));
            continue;
        }
        if (isBreakline) {
            textArray.push(new TextArrayTextItem('\r\n'));
            continue;
        }
        if (!tagType) {
            // text node
            textArray.push(new TextArrayTextItem(text));
            continue;
        }
        switch (tagType) {
            case TagTypes.PLACE_TAG:
            case TagTypes.STEP_TAG:
                textArray.push(
                    new TextArrayLocationItem(text, {
                        id: tagId,
                        type: tagType,
                        name: tagName,
                    })
                );
                break;
            case TagTypes.USER_TAG:
                textArray.push(new TextArrayUserItem(text, { userId }));
                break;
            default:
                textArray.push(new TextArrayTextItem(text));
        }
    }
    return textArray;
};

export const htmlToTextArray = htmlNodes => {
    const textArray = flattenDeep(mapNodes(htmlNodes));
    return textArray;
};

export const calcActionPosition = (selectionBoundingRect, container = document.body) => {
    if (!selectionBoundingRect) {
        console.warn('selectionBoundingRect is not defined');
        return {};
    }
    const containerRect = container.getBoundingClientRect();
    const containerHeight = containerRect.height;
    const isOverflowBottom = selectionBoundingRect.bottom + ACTION_ELEMENT_HEIGHT > containerHeight;
    const top = isOverflowBottom ? selectionBoundingRect.top - ACTION_ELEMENT_HEIGHT : selectionBoundingRect.bottom;
    const left = selectionBoundingRect.right;
    return { top, left };
};

const isTag = node => node?.classList?.contains(TAG_CLASS);

export const getLocationTagIdsInSelectionRange = range => {
    if (!range) {
        return [];
    }

    try {
        const isOverlap = range.endContainer.textContent === '\r\n';
        const container = isOverlap ? 'startContainer' : 'commonAncestorContainer';
        const isSelectionTextNode = range[container].nodeName === '#text';
        const selectionCommonAncestorContainer = isSelectionTextNode ? range[container]?.parentElement : range[container];
        const isSelectionItselfTag = selectionCommonAncestorContainer?.classList?.contains(TAG_CLASS);

        if (isSelectionItselfTag) {
            // assuming there won't be tag elements within tag elements
            return [
                {
                    id: selectionCommonAncestorContainer.getAttribute('data-id'),
                    type: selectionCommonAncestorContainer.getAttribute('data-type'),
                },
            ];
        }

        const tags = selectionCommonAncestorContainer?.querySelectorAll(`.${TAG_CLASS}`);

        return Array.from(tags).map(tag => ({
            id: tag.getAttribute('data-id'),
            type: tag.getAttribute('data-type'),
        }));
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const getCaretBoundingClientRect = (anchorNode, focusNode, range) => {
    if (!anchorNode && !focusNode && !range) {
        return null;
    }
    let selectionBoundingRect = null;
    // FIXME: there's still a small bug when 'triple clicking' text, the offset is wrong a bit
    if (focusNode.nodeName === '#text' && anchorNode.nodeName === '#text') {
        const dummySpan = document.createElement('span');
        const dummyRange = range.cloneRange();
        dummyRange.collapse(false);
        dummyRange.insertNode(dummySpan);
        selectionBoundingRect = dummySpan.getBoundingClientRect();
        dummySpan.remove();
    } else if (focusNode.nodeName === '#text') {
        selectionBoundingRect = focusNode?.parentElement?.getBoundingClientRect();
    } else if (anchorNode.nodeName === '#text') {
        selectionBoundingRect = anchorNode?.parentElement?.getBoundingClientRect();
    }
    return selectionBoundingRect;
};

export const getSelectionData = () => {
    const selection = document.getSelection();
    const selectionValue = selection.toString();

    if (!selection || !selectionValue) {
        return {};
    }

    const range = selection?.getRangeAt(0);
    const selectionContents = range?.cloneContents();
    const breaklineEndRegexp = new RegExp(`(\\r\\n|\\n|\\r)$`, 'g');
    const hasBreaklineAtEnd = breaklineEndRegexp.test(selectionValue);
    const valueWithoutBreakline = selectionValue.replace(breaklineEndRegexp, '');
    const value = hasBreaklineAtEnd ? valueWithoutBreakline : selectionValue;

    const data = {
        range,
        selectionContents,
        value,
        anchorNode: selection.anchorNode,
        focusNode: selection.focusNode,
        anchorOffset: selection.anchorOffset,
        focusOffset: selection.focusOffset,
        isCollapsed: selection.isCollapsed,
    };
    return data;
};

export const insertLocationTagToWrapper = (range, locationTag, wrapperId, onError) => {
    const oldTextElement = document.getElementById(wrapperId).cloneNode(true);
    const backup = range.cloneContents(true);
    try {
        const contents = range.extractContents();
        locationTag.appendChild(contents);
        range.deleteContents();
        range.insertNode(locationTag);
        // UNSAFE after this, the next state update that will change body will break the app.
        // therefore I wrapped this component with boundary
        // TODO: check it works and find a way to fix the unsafe html issue
        // TODO: this might not be an issue anymore since we don't alter body/textArray directly anymore
    } catch (error) {
        console.error(error);
        document.getElementById(wrapperId).innerHTML = oldTextElement.innerHTML;
        onError(error);
        return;
    }

    return { backup, oldTextElement, locationTag };
};

export const removeBreaklines = text => {
    return text.replace(/(\r\n|\n|\r)/gm, '');
};

// TODO: isPostTextArrayContentChanged
export const isPostContentChanged = ({ textArray = [], newTextArray = [], onError }) => {
    const originalTextArrayText = textArray.map(({ text }) => text).join('');
    const newTextArrayText = newTextArray.map(({ text }) => text).join('');
    if (originalTextArrayText !== newTextArrayText) {
        console.error('text array content changed', {
            originalTextArrayText,
            newTextArrayText,
            textArray,
            newTextArray,
        });
        onError();
        return true;
    }
    return false;
};

// returns true if the element or one of its parents has the class classname
export const hasParentId = (element, id) => {
    if (!element) {
        return false;
    }
    if (element.nodeName === '#text') {
        return element.parentNode && hasParentId(element.parentNode, id);
    }
    return Boolean(element.closest && element.closest(`#${id}`));
};

export const mergeAdjacentTextArrayTextItems = textArray => {
    return textArray.reduce((acc, item) => {
        if (item.text_type === 'text' && acc.length && acc[acc.length - 1].text_type === 'text') {
            acc[acc.length - 1].text += item.text;
        } else {
            acc.push(item);
        }
        return acc;
    }, []);
};

export const removeTag = (tagId, onError) => {
    let tag;
    try {
        tag = document.querySelector(`[data-id='${tagId}'`);
    } catch (err) {
        console.error('error finding tag', { err });
    }
    if (!tag) tag = document.getElementById(tagId);
    if (!tag) {
        console.error('tag not found', { tagId });
        onError();
        return;
    }
    tag.classList.remove('tag');
    tag.removeAttribute('data-id');
    tag.removeAttribute('data-name');
    tag.setAttribute('data-type', TagTypes.TEXT);
};
