import React from 'react';

const IconUploadImage = () => (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M15.833 2.5H4.167c-.92 0-1.667.746-1.667 1.667v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V4.167c0-.92-.746-1.667-1.667-1.667z'
            stroke='#2171EC'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M7.083 8.334a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5zM17.5 12.5l-4.167-4.166L4.167 17.5'
            stroke='#2171EC'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default IconUploadImage;
