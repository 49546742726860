import React, { useEffect, useState, useMemo } from 'react';
import { Browser } from 'leaflet';
import { MapContainer, TileLayer, useMapEvents, useMap, ZoomControl } from 'react-leaflet';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../../config/index';
import 'leaflet/dist/leaflet.css';
import './mapStyle.scss';
import DivIcon from './components/DivIcon';
import PixiOverlay from './pixiOverlay';
import { useDebounce } from '../../../hooks';
import { userSelectors } from '../../../redux/user';
import { onboardActions, onboardSelectors } from '../../../redux/onboard';
import StepGhost from './components/StepGhost';
import Grid from '@material-ui/core/Grid';
import styles from '../CreateStepGoogleMap/index.module.scss';
import { mapActions, mapSelectors } from '../../../redux/map';

let allowClick = true;

const stepIcon = {
    selectedStep: {
        key: 'selectedStepIcon',
        src: '/assets/img/selected-no-shadows.svg',
    },
    ghostStep: {
        key: 'ghostStepIcon',
        src: '/assets/img/ghost-step-marker.svg',
    },
    step: {
        key: 'stepIcon',
        src: '/assets/img/step-marker.svg',
    },
};

const NORTH_ATLANTIC_OCEAN = [35.812552881832474, -42.09390241400597];

const convertToPixiMarkers = ({ steps = [], ghost }) => {
    const GHOST_MARKER = 'ghost_marker';
    const markers = steps?.map(step => ({
        id: step?._id,
        position: [step.lat || step.location.lat, step.lon || step.location.lng],
        onClick: () => {},
        iconId: stepIcon.selectedStep.key,
        customIcon: stepIcon.selectedStep.src,
    }));
    if (ghost)
        markers.push({
            id: GHOST_MARKER,
            position: ghost.position,
            iconId: ghost.isStick ? stepIcon.selectedStep.key : stepIcon.ghostStep.key,
            customIcon: ghost.isStick ? stepIcon.selectedStep.src : stepIcon.ghostStep.src,
            onClick: () => {},
        });
    return markers;
};

const OnboardMap = ({ location, files, text, page, mapName, locations, mapId, isImport, startPosition }) => {
    const dispatch = useDispatch();
    const [markers, setMarkers] = useState([]);
    const [steps, setSteps] = useState({});
    const [renderTrigger, setRenderTrigger] = useState({ trig: true });
    const renderTriggerDebounce = useDebounce(renderTrigger, 50);
    const userLocation = useSelector(userSelectors.locationSelector);
    const [hoverStep, setHoverStep] = useState(false);
    const [hoveredStep, setHoveredStep] = useState(null);
    let steps1 = useSelector(mapSelectors.steps(mapId));
    const hasCoordinates = useMemo(() => userLocation?.lat && userLocation?.lng, [userLocation]);
    const mapCenter = useMemo(
        () => (hasCoordinates ? [userLocation?.lat, userLocation?.lng] : NORTH_ATLANTIC_OCEAN),
        [hasCoordinates, userLocation?.lat, userLocation?.lng]
    );

    useEffect(() => {
        if (steps1 && Object.values(steps1).length !== 0) setSteps(steps1);
    }, [Object.values(steps1)?.length]);

    useEffect(() => {
        if (locations && Object.values(locations).length !== 0) setSteps(locations);
    }, [Object.values(locations)?.length]);

    const initMapComponent = () => {
        dispatch(mapActions.getAllStepsPreviewData(mapId, 'active'));
        setMarkers([]);
    };

    useEffect(() => initMapComponent(), [mapId]);
    useEffect(() => renderMarkers(), [renderTriggerDebounce]);
    useEffect(() => {
        setRenderTrigger({ ...renderTrigger });
    }, [location, page, steps, isImport, hoverStep, hoveredStep]);

    const renderMarkers = () => {
        let stepsList = Object.values(steps);
        if (stepsList?.length > 0) {
            setMarkers(
                convertToPixiMarkers({
                    steps: stepsList,
                    ghost: null,
                    onClick: step => {
                        setHoveredStep(step);
                    },
                })
            );
        }
        if (location) {
            setMarkers(
                convertToPixiMarkers({
                    steps: location && [location],
                    ghost: location
                        ? {
                              position: [location?.lat || location?.location?.lat, location?.location?.lng || location?.lon],
                              isStick: page === 4,
                          }
                        : null,
                })
            );
        }
    };

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            {mapName.length > 0 && (
                <Grid direction='row' justify='center' item xs={12} className={styles.mapHeader} container>
                    <Grid item xs={12}>
                        <span className={styles.mapName}>{mapName}</span>
                    </Grid>
                </Grid>
            )}
            <MapContainer
                center={mapCenter}
                zoom={hasCoordinates ? 10 : 1}
                zoomControl={false}
                preferCanvas
                doubleClickZoom={false}
                minZoom={2}
                maxZoom={20}
                maxBounds={[
                    [85, 180],
                    [85, -180],
                    [-85, -180],
                    [-85, 180],
                ]}
                maxBoundsViscosity={1}
            >
                <TileLayer
                    maxZoom={20}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={`https://api.mapbox.com/styles/v1/${config.mapbox.user_name}/${config.mapbox.style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${config.mapbox.access_token}`}
                />
                <ZoomControl zoomInText={'+'} zoomOutText={'&#x2212'} position={'bottomright'} />
                {hoveredStep && (
                    <DivIcon
                        className=''
                        key={`previewStep`}
                        position={(hoveredStep && [hoveredStep?.position?.[0], hoveredStep?.position?.[1]]) || [0, 0]}
                        iconAnchor={[0, 0]}
                        iconSize={[0, 0]}
                        zIndexOffset={600}
                    >
                        <StepGhost
                            hover={hoverStep}
                            location={location}
                            files={files}
                            text={text}
                            title={hoveredStep?.tooltip}
                            page={page}
                            mapId={mapId}
                            stepId={hoveredStep?.id}
                        />
                    </DivIcon>
                )}
                {location && (
                    <DivIcon
                        className=''
                        key={`StepGhost`}
                        position={(location && [location?.lat || location?.location?.lat, location?.location?.lng || location?.lon]) || [0, 0]}
                        iconAnchor={[0, 0]}
                        iconSize={[0, 0]}
                        zIndexOffset={600}
                    >
                        {hoverStep && page === 4 ? (
                            <StepGhost
                                hover={hoverStep}
                                location={location}
                                files={files}
                                text={text}
                                title={location?.title || location?.description}
                                page={page}
                            />
                        ) : (
                            page !== 4 && (
                                <StepGhost
                                    hover={hoverStep}
                                    location={location}
                                    files={files}
                                    text={text}
                                    title={location?.title || location?.description}
                                    page={page}
                                />
                            )
                        )}
                    </DivIcon>
                )}
                <PixiOverlay
                    isImported={isImport}
                    handleHoverStep={id => {
                        if (id) {
                            setHoverStep(true);
                            if (isImport) {
                                setHoveredStep(id);
                            }
                        } else {
                            setHoverStep(false);
                            if (isImport) {
                                setHoveredStep(null);
                            }
                        }
                    }}
                    markers={markers}
                />
                <MapEvents
                    onClickMap={() => {
                        setHoveredStep(null);
                        setHoverStep(false);
                    }}
                    mapId={mapId}
                    steps={steps}
                    startPosition={startPosition}
                />
            </MapContainer>
        </div>
    );
};

const MapEvents = ({ steps, startPosition, onClickMap }) => {
    const mapRef = useMap();
    const dispatch = useDispatch();
    const onboardMapCanter = useSelector(onboardSelectors.onboardMapCenter);
    const [mapCenterState, setMapCenterState] = useState(null);
    const mapCenterDebounce = useDebounce(mapCenterState, 750);

    useEffect(() => {
        startPosition && mapRef.setView(startPosition);
    }, [startPosition]);

    useEffect(() => {
        mapRef.invalidateSize();
    }, []);

    useEffect(() => {
        const latslons = Object.values(steps).map(val => [val?.lat || val?.location?.lat, val?.lon || val?.location?.lng]);
        if (latslons?.length > 0) mapRef.fitBounds(latslons);
        if (!onboardMapCanter || (onboardMapCanter && onboardMapCanter.init)) {
            if (latslons?.length > 0) {
                setTimeout(() => {
                    let zoom = mapRef.getZoom();
                    if (zoom > 16) zoom = 16;
                    else if (zoom < 4) zoom = 4;
                    dispatch(
                        onboardActions.setOnboardMapCenter({
                            position: mapRef.getCenter(),
                            zoom: zoom,
                            bounds: mapRef.getBounds(),
                        })
                    );
                }, 350);
            }
        }
    }, [steps]);

    useEffect(() => {
        if (!onboardMapCanter) return;
        if (!onboardMapCanter.bounds) {
            dispatch(
                onboardActions.setOnboardMapCenter({
                    ...onboardMapCanter,
                    bounds: mapRef.getBounds(),
                })
            );
            return;
        }
        const lat = onboardMapCanter.position.lat || onboardMapCanter.position[0];
        const lon = onboardMapCanter.position.lng || onboardMapCanter.position.lon || onboardMapCanter.position[1];
        const northEast = mapRef.getBounds().getNorthEast();
        const southWest = mapRef.getBounds().getSouthWest();
        const quarterLat = (northEast.lat - southWest.lat) / 4;
        const quarterLon = (northEast.lng - southWest.lng) / 4;
        if (
            mapRef.getZoom() !== onboardMapCanter.zoom ||
            lat < southWest.lat + quarterLat ||
            lat > northEast.lat - quarterLat ||
            lon < southWest.lng + quarterLon ||
            lon > northEast.lng - quarterLon
        )
            mapRef.flyTo(onboardMapCanter.position, onboardMapCanter.zoom, {
                duration: 2,
            });
    }, [onboardMapCanter]);

    useEffect(() => {
        if (mapCenterDebounce)
            dispatch(
                onboardActions.setOnboardMapCenter({
                    position: mapRef.getCenter(),
                    zoom: mapRef.getZoom(),
                    bounds: mapRef.getBounds(),
                })
            );
    }, [mapCenterDebounce]);

    useMapEvents({
        click(e) {
            if (Browser.safari) {
                if (!allowClick) return;
                allowClick = false;
                setTimeout(() => (allowClick = true), 200); // handle safari issue (bug on leaflet)
            }
            if (onClickMap) {
                onClickMap(e);
            }
        },
        zoomend() {
            setMapCenterState({
                position: mapRef.getCenter(),
                zoom: mapRef.getZoom(),
                bounds: mapRef.getBounds(),
            });
        },
        dragend() {
            setMapCenterState({
                position: mapRef.getCenter(),
                zoom: mapRef.getZoom(),
                bounds: mapRef.getBounds(),
            });
        },
    });

    return <></>;
};

export default OnboardMap;
