import { Fragment, useMemo } from 'react';
import { noop } from 'lodash';
import * as timeago from 'timeago.js';
import Avatar from '@material-ui/core/Avatar';
import { generateImageUrl } from '../../../../helpers';
import { TextArrayBody } from '../../../../components/common';
import { X as XIcon } from '../../../../components/common/icons';

import styles from './BaseCard.module.scss';
import { TextArray } from '../../../../models';

const BaseCard = ({
    avatarImgId = '',
    message = '',
    createdAt = 0,
    footer = null,
    children,
    textArray = [],
    buttons = [],
    onClick = noop,
    onClose = noop,
    onTagClick = noop,
    onCardClick = noop,
    shouldRenderFooter = false,
}) => {
    const body = useMemo(() => {
        return textArray.map(item => {
            return new TextArray(item);
        });
    }, [textArray]);

    const avatarSrc = useMemo(() => {
        return generateImageUrl({ imageId: avatarImgId, reason: 'recent_activity_avatar' });
    }, [avatarImgId]);

    return (
        <div
            className={styles.card}
            onClick={() => {
                const hasStepTag = body.some(item => item.type === 'step_tag');

                onClick();
                onCardClick(hasStepTag);
            }}
            data-testid='recent-activities-base-card'
        >
            <XIcon
                data-testid='base-card-close-icon'
                className={styles.closeIcon}
                onClick={event => {
                    event.stopPropagation();
                    onClose();
                }}
                size={20}
            />
            <div className={styles.body}>
                <div className='flex'>
                    <Avatar className='m-r-8' src={avatarSrc} />
                    <div className={styles.headline}>
                        <span data-testid='recent-activities-base-card-text' className={styles.text}>
                            <TextArrayBody body={body} onTagClick={onTagClick} isTaggingDisabled />
                        </span>
                        {createdAt > 0 ? <span className={styles.createdAt}>{timeago.format(createdAt, 'my-locale')}</span> : null}
                    </div>
                </div>
                {message && <div className={styles.reason}>{message}</div>}
            </div>
            <div className='hr' />
            {children && <div className={styles.content}>{children}</div>}
            <div className={shouldRenderFooter ? styles.userReaction : styles.buttons} data-testid='user-reaction'>
                {shouldRenderFooter
                    ? footer
                    : buttons.map((button, index) => {
                          return <Fragment key={index}>{button}</Fragment>;
                      })}
            </div>
        </div>
    );
};

export default BaseCard;
