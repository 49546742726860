import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import SearchInput from '../common/inputs/SearchInput2';

import styles from './EditStepDetails.module.scss';

const EditNameOfPlace = ({
                             stepName = '',
                             onChange = noop,
                             onBlur = noop,
                             isDisabled = false,
                             isVisible,
                             className
                         }) => {
    const intl = useIntl();
    const [value, setValue] = useState(stepName);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={className}>
            <h2 className={styles.label}>
                <FormattedMessage id='addStep.name_of_place' defaultMessage='Name of place'/>
            </h2>
            <SearchInput
                withSearchIcon={false}
                value={value}
                className='full-width bg-gray-100 text-xs placeholder-gray-700 rounded border-transition'
                placeholder={intl.formatMessage({ id: 'addStep.whats_the_place_name' })}
                onBlur={onBlur}
                autocomplete={false}
                disabled={isDisabled}
                onChange={event => {
                    setValue(event.target.value);
                    onChange(event.target.value);
                }}
            />
        </div>
    );
};

export default EditNameOfPlace;
