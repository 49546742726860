import { useEffect } from 'react';
import { noop } from 'lodash';
import { X } from 'phosphor-react';
import CustomButton, { ButtonType, ButtonSize } from '../../../common/buttons/CustomButton';
import styles from './index.module.scss';
import { TooltipTypes } from '../index';
import copiesPrefix from '../../../../copies.json';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

const tooltipsSeen = [];
const mapStagesCopy = copiesPrefix.mapStages;

const MapStagesIndicationTooltip = ({ onClick = noop, type }) => {
    const copies = type === TooltipTypes.READY_TO_LEVEL_UP ? mapStagesCopy.readyToLevelAppTooltip : mapStagesCopy.almostThereTooltip;

    useEffect(() => {
        if (!type || tooltipsSeen.includes(type)) return;

        mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_TOOLTIP_SHOWED, {
            [mixpanelProperties.TOOLTIP_TYPE]: type === TooltipTypes.READY_TO_LEVEL_UP ? 'second_session' : 'complete_process',
        });

        tooltipsSeen.push(type);
    }, [type]);

    if (!type) return null;

    return (
        <div className={styles.block}>
            <div className={styles.arrow} />
            <X size={18} color='#fff' className={styles.xIcon} onClick={onClick} />
            <h1 className={styles.title}>{copies.title}</h1>
            <p className={styles.description}>{copies.description}</p>
            <div className={styles.footer}>
                <CustomButton
                    className={styles.ctaButton}
                    onClick={() => onClick({ moveToMapStages: true })}
                    type={ButtonType.PRIMARY_ON_BLUE}
                    size={ButtonSize.MEDIUM}
                >
                    {copies.cta}
                </CustomButton>
            </div>
        </div>
    );
};

export default MapStagesIndicationTooltip;
