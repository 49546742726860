import React from 'react';
import styles from './index.module.scss';
import Avatar from '@material-ui/core/Avatar';
import { generateImageUrl } from '../../../../helpers';

const Outcome = ({ text, managerPreview }) => {
    return (
        <p className={styles.managerInfo}>
            {text}
            {managerPreview?.display_name && (
                <>
                    <Avatar
                        style={{
                            marginRight: '8px',
                            marginLeft: '8px',
                            width: '20px',
                            height: '20px',
                        }}
                        src={generateImageUrl({
                            imageId: managerPreview?.image,
                            width: 20,
                            height: 20,
                            reason: 'recent_activity_avatar',
                        })}
                    />
                    <span>{managerPreview?.display_name}</span>
                </>
            )}
        </p>
    );
};

export default Outcome;
