import classNames from 'classnames';

const Skeleton = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div
                style={{
                    padding: '24px',
                    borderBottom: '0.0625rem solid #dddddd',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginBottom: '30px',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className={classNames('animate')} style={{ width: '40px', height: '40px' }} />
                    <div style={{ display: 'flex' }}>
                        <div
                            className={classNames('animate')}
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '14px',
                            }}
                        />
                        <div
                            className={classNames('animate')}
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '14px',
                            }}
                        />
                        <div
                            className={classNames('animate')}
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '14px',
                            }}
                        />
                        <div
                            className={classNames('animate')}
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '14px',
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: '99%', height: '40px' }} className={classNames('animate')} />
            </div>
            <div style={{ overflowY: 'hidden', flex: '1 0 1px' }}>
                <div style={{ padding: '24px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '15px',
                        }}
                    >
                        <div style={{ width: '60px', height: '30px' }} className={classNames('animate')} />
                        <div style={{ width: '60px', height: '30px' }} className={classNames('animate')} />
                    </div>
                    <div style={{ width: '100%', height: '70px' }} className={classNames('animate')} />
                </div>
                <div
                    style={{
                        padding: '24px',
                        borderBottom: '0.0625rem solid #dddddd',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '15px',
                        }}
                    >
                        <div style={{ width: '60px', height: '30px' }} className={classNames('animate')} />
                        <div style={{ width: '60px', height: '30px' }} className={classNames('animate')} />
                    </div>
                    <div style={{ width: '100%', height: '70px' }} className={classNames('animate')} />
                </div>
                <div
                    style={{
                        padding: '15px 24px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '0.0625rem solid #dddddd',
                    }}
                >
                    <div style={{ width: '80px', height: '23px' }} className={classNames('animate')} />
                    <div style={{ width: '80px', height: '23px' }} className={classNames('animate')} />
                    <div style={{ width: '80px', height: '23px' }} className={classNames('animate')} />
                    <div style={{ width: '80px', height: '23px' }} className={classNames('animate')} />
                </div>
                <div
                    style={{
                        padding: '1.125rem 1.5625rem 0.875rem 1.5625rem',
                        display: 'flex',
                        borderBottom: '0.0625rem solid #dddddd',
                    }}
                >
                    <div
                        style={{
                            width: '45px',
                            height: '40px',
                            borderRadius: '50%',
                        }}
                        className={classNames('animate')}
                    />
                    <div
                        style={{
                            width: '100%',
                            height: '40px',
                            marginLeft: '18px',
                            borderRadius: '20px',
                        }}
                        className={classNames('animate')}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '24px',
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <div
                            className={classNames('animate')}
                            style={{
                                width: '56px',
                                height: '56px',
                                borderRadius: '50%',
                            }}
                        />
                        <div style={{ marginLeft: '10px' }}>
                            <div
                                className={classNames('animate')}
                                style={{
                                    width: '300px',
                                    height: '19px',
                                    marginBottom: '6px',
                                }}
                            />
                            <div
                                className={classNames('animate')}
                                style={{
                                    width: '100px',
                                    height: '13px',
                                }}
                            />
                        </div>
                    </div>
                    <div className={classNames('animate')} style={{ width: '10px', height: '25px' }} />
                </div>
                <div style={{ padding: '0 24px' }}>
                    <div className={classNames('animate')} style={{ width: '100%', height: '38px' }} />
                </div>
                <div
                    className={classNames('animate')}
                    style={{
                        height: '346px',
                        width: '100%',
                        marginTop: '15px',
                    }}
                />
                <div
                    className={classNames('animate')}
                    style={{
                        width: '200px',
                        height: '10px',
                        margin: '5px auto 0 auto',
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '0.0625rem solid #dddddd',
                        padding: '24px',
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <div
                            className={classNames('animate')}
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '55px',
                            }}
                        />
                        <div className={classNames('animate')} style={{ width: '40px', height: '40px' }} />
                    </div>
                    <div className={classNames('animate')} style={{ width: '40px', height: '40px' }} />
                </div>
            </div>
        </div>
    );
};

export default Skeleton;
