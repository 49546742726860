export const TabsView = {
    IMPORT: 'Import',
    MAP: 'MapView',
    STEPS: 'Steps Components',
    LIST: 'ListView',
};

export const routeTabs = {
    steps: 'MapView',
    list: 'list',
    imported: 'Import',
    map: 'map'
};
