import { useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from './TextArea.module.scss';
import { TextInput } from '../../../TextInput';

export const LimiterPosition = {
    TOP: 'top',
    BOTTOM: 'bottom',
    INLINE: 'inline',
    NONE: 'none',
};

const TextArea = ({
    className,
    value: initValue = '',
    placeholder = '',
    limitLabel = '',
    onChange = _value => {},
    maxLength = 200,
    withOutline = true,
    limiterPosition = LimiterPosition.NONE,
    inputClassName,
    isActive = false,
    ...rest
}) => {
    const [value, setValue] = useState(initValue.slice(0, maxLength));

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    const renderLimiter = () => {
        return (
            <div
                className={classNames(styles.limiter, {
                    [styles.inline]: limiterPosition === LimiterPosition.INLINE,
                })}
            >
                <label className={styles.mapDescription}>{limitLabel} </label>
                <label className={styles.span}>
                    {value.length}/{String(maxLength)}
                </label>
            </div>
        );
    };

    if (!isActive) {
        return null;
    }

    return (
        <div className={classNames(styles.textArea, { [styles.outline]: withOutline }, className)}>
            {limiterPosition === LimiterPosition.TOP && renderLimiter()}
            <TextInput
                {...rest}
                id='text-area'
                className={inputClassName}
                placeholder={placeholder}
                onChange={(value, textArray) => {
                    if (value.length > maxLength) {
                        return;
                    }

                    setValue(value);
                    onChange(value, textArray);
                }}
                maxLength={maxLength}
                data={value}
            />
            {[LimiterPosition.BOTTOM, LimiterPosition.INLINE].includes(limiterPosition) && renderLimiter()}
        </div>
    );
};

export default TextArea;
