const Routes = {
  ENTRY: '/*',
  STEPS: '/steps',
  DASHBOARD: '/',
  
  LOGIN: '/login',
  LOGIN_MANUAL: '/login/manual',
  LOGIN_TOKEN: '/login/token/:token',
  SIGN_UP: '/login/sign-up',
  REGISTER: '/login/register',
  FORGOT_PASSWORD: '/login/forgot-password',
  CHECK_MAIL: '/login/forgot-password/check-mail',
  RESET_PASSWORD: '/login/passwordrecovery',
  LOGIN_APPLE: '/apple-login',
  
  MAP_SETTING: 'settings',
  MAP_SETTINGS_RATINGS: 'settings/ratings',
  MEMBERS: 'settings/members',
  MAP_SETTINGS_GENERAL: 'settings/general',
  MAP_SETTINGS_TAGS: 'settings/tags',
  
  ONBOARD: '/onboard',
  AUTH: '/auth',
  UPDATES: 'updates',
  FEEDBACK: 'feedback', // TODO: deprecated
  INSIGHTS: 'insights',
  IMPORTED: 'imported',
  LIST: 'list',
  GETTING_STARTED: '/getting-started',
  TAGS: 'tags',
  MAP: 'map',
  FEED: 'feed',
  STEP_PROFILE: 'step/:stepId',
  POST_PAGE: 'post/:postId',
  SEARCH_STEPS: '/search',
  ADD_STEP: 'addStep',
  LOCATION: 'location',
  EDIT_LOCATION: 'editLocaion',
  NEW_PLACE: 'newPlace',
  CREATE_PLACE: 'createPlace',
  MAP_STAGES: 'tasks',
  ASK_AROUND: 'ask-around',
};

export default Routes;
