import React from 'react';

const IconX = ({ color }) => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M11 6L10.9993 10.9993L6 11' stroke={color || '#B5B7BB'} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M5 5L11 11' stroke={color || '#B5B7BB'} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path
            d='M3 10V16.2222C3 16.4285 3.08194 16.6263 3.22781 16.7722C3.37367 16.9181 3.5715 17 3.77778 17H16.2222C16.4285 17 16.6263 16.9181 16.7722 16.7722C16.9181 16.6263 17 16.4285 17 16.2222V3.77778C17 3.5715 16.9181 3.37367 16.7722 3.22781C16.6263 3.08194 16.4285 3 16.2222 3H10'
            stroke={color || '#B5B7BB'}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default IconX;
