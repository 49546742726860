import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const BackArrowButton = ({ onClick, className }) => {
    return (
        <div onClick={onClick} data-testid='back-arrow' className={classNames(styles.closeBlock, className)}>
            <img src='/assets/img/arrow-left.svg' />
        </div>
    );
};

export default BackArrowButton;
