import React from 'react';
import styles from './index.module.scss';

const AdvancedLevelPopUpSlide = ({ slide }) => {
    const { imageSrc, title, description, backgroundColor, titleSize = 17 } = slide;

    return (
        <div className={styles.block}>
            <div className={styles.imgWrapper} style={{ backgroundColor: backgroundColor }}>
                <img className={styles.image} src={imageSrc} alt='' />
            </div>
            <div className={styles.content}>
                <h3 className={styles.title} style={{ fontSize: `${titleSize}px` }}>
                    {title}
                </h3>
                <p className={styles.description}>{description}</p>
            </div>
        </div>
    );
};

export default AdvancedLevelPopUpSlide;
