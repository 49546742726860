import { useCallback, useRef, useState, useEffect, useContext, useMemo } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Chats, Copy, PencilSimple, Trash } from 'phosphor-react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import CommentsSection from '../CommentsSection';
import { Avatar, CurrentUserAvatar, AvatarSizesNames } from '../../common/Avatar';
import { PostSecondaryHeadline } from '../../common/PostSecondaryHeadline';
import Input, { LINE_HEIGHT } from '../../common/inputs/Input';
import { DropdownMenu, MenuSize } from '../../common/DropdownMenu';
import { userSelectors } from '../../../redux/user';
import { feedActions } from '../../../redux/feed';
import { postActions } from '../../../redux/post';
import { postActions as postsActions } from '../../../redux/posts';
import { copyToClipboard } from '../../../helpers';
import ModalDelete from '../../common/modals/ModalDelete';
import copies from '../../../copies.json';
import { useModal } from '../../../hooks';
import { generateImageUrl } from '../../../helpers/index';
import ClapIndication from './ClapIndication';
import { ToastContext, TEXT_COPIED } from '../../../context/ToastContext';
import { Linkify } from '../../common';
import { modalsActions } from '../../../redux/modals';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';
import { TextArrayBody } from '../../common/TextArrayBody';
import { TagTypes } from '../../../constants';

const Comment = ({ comment = {}, showReplies = true, repliesSettings, isReply = false, relatedCommentId = '', fromWhere, isScrolling = false }) => {
    const key = isReply ? 'reply' : 'comment';
    const postCopies = copies.commentSection[key];
    const { setToast } = useContext(ToastContext);
    const {
        id,
        stepId,
        mapId,
        parentPostId,
        content,
        clapsCount,
        isClapped,
        createdAt,
        userName,
        userId,
        creatorId,
        userType,
        userImageId,
        screensChain,
        attachments,
        fullSizeImages,
        repliesOpened,
        repliesCursor,
        temp,
        images,
        isFeedPost,
        textArray,
    } = comment;
    const { _id: postId } = screensChain?.find(chain => chain.type === 'POST') ?? '';
    const dispatch = useDispatch();
    const currentUser = useSelector(userSelectors.user);
    const inputRef = useRef();
    const commentRef = useRef();
    const [inputToggled, setInputToggled] = useState(false);
    const [editInputToggled, setEditInputToggled] = useState(false);
    const [editInputStartingRows, setEditInputStartingRows] = useState(1);
    const [replyToPlaceholder, setReplyToPlaceholder] = useState('');
    const { modalOpen, setModalOpen } = useModal(false);
    
    useEffect(() => {
        if (inputToggled) {
            // prevents a placeholder from being shown for a brief moment when the input is focused
            setTimeout(() => {
                setReplyToPlaceholder(`Reply to ${userName}...`);
            }, 100);
        }
    }, [inputToggled]);
    
    const isReplyingMyself = useMemo(() => {
        return currentUser.id === creatorId;
    }, [currentUser, creatorId]);
    
    useEffect(() => {
        // toggle input off when escape key is pressed
        const handleKeyDown = event => {
            if (event.key === 'Escape') {
                setEditInputToggled(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    
    // when comment ref initialized take comment rows
    useEffect(() => {
        if (!commentRef || !commentRef.current) return;
        setEditInputStartingRows(Math.floor(commentRef.current.rows / LINE_HEIGHT));
    }, [commentRef]);
    
    useEffect(() => {
        if (!inputToggled || !inputRef.current) return;
        inputRef.current.focus();
    }, [inputToggled]);
    
    const replyOnComment = async ({ textArray, files, value }) => {
        if ((!textArray.length && !files.length) || !value.trim()) {
            return;
        }
        
        const hasLocation = Boolean(textArray.find(({ text_type }) => text_type === 'step_tag'));
        const hasMemberTagged = Boolean(textArray.find(({ text_type }) => text_type === 'user_tag'));
        const locationTaggedCount = hasLocation ? textArray.filter(({ text_type }) => text_type === 'step_tag').length : 0;
        const memberTaggedCount = hasLocation ? textArray.filter(({ text_type }) => text_type === 'user_tag').length : 0;
        
        const parentPost = isReply ? relatedCommentId : id;
        
        const reply = {
            parent_post_id: parentPost,
            client_type: 'map_manager',
            text_array: textArray,
            attachments: files.map(image => ({
                type: 'IMAGE',
                image_id: image.imageId,
            })),
        };
        
        if (isFeedPost) {
            await dispatch(
                postActions.createFeedPost({
                    mapId,
                    parentPostId: id,
                    textArray,
                    attachments: files.map(image => ({
                        type: 'IMAGE',
                        image_id: image.imageId,
                    })),
                })
            );
            dispatch(feedActions.getFeedPosts({ mapId }));
        } else {
            dispatch(
                postsActions.replyOnComment({
                    data: reply,
                    mapId,
                    stepId,
                    postId: postId,
                    commentId: parentPost,
                    userName: currentUser.name,
                    userImageId: currentUser.image,
                    createdAt: new Date().getTime(),
                })
            );
        }
        
        mixpanel.track(mixpanelEvents.ADD_REPLY_FINISH, {
            [mixpanelProperties.FROM_WHERE]: fromWhere,
            [mixpanelProperties.STEP_ID]: stepId,
            [mixpanelProperties.IMAGE]: files.length > 0,
            [mixpanelProperties.TEXT]: value.length > 0,
            [mixpanelProperties.HAS_LOCATION_TAGGED]: hasLocation,
            [mixpanelProperties.HAS_MEMBER_TAGGED]: hasMemberTagged,
            [mixpanelProperties.LOCATION_TAGGED_COUNT]: locationTaggedCount,
            [mixpanelProperties.MEMBER_TAGGED_COUNT]: memberTaggedCount,
        });
        
        setInputToggled(false);
    };
    
    const editReply = ({ textArray, files }) => {
        if (!textArray.length) return;
        
        const updatedReply = {
            id,
            parent_post_id: parentPostId,
            text_array: textArray,
            attachments: files.map(image => ({
                type: 'IMAGE',
                image_id: image.imageId,
            })),
            client_type: 'map_manager',
        };
        
        if (isReply) {
            dispatch(postsActions.editReply(id, postId, parentPostId, updatedReply));
        } else {
            dispatch(postsActions.editComment(id, parentPostId, updatedReply));
        }
    };
    
    const deleteComment = () => {
        isReply
            ? dispatch(postsActions.deleteReply(mapId, stepId, postId, parentPostId, id))
            : dispatch(postsActions.deleteComment(mapId, stepId, parentPostId, id));
    };
    
    const clapOnComment = () => {
        isReply
            ? dispatch(postsActions.clapReply(postId, parentPostId, id, +!isClapped))
            : dispatch(postsActions.clapComment(mapId, stepId, parentPostId, id, +!isClapped));
    };
    
    const ownCommentOptions = [
        {
            title: 'Edit',
            icon: <PencilSimple/>,
            onClick: () => {
                setEditInputToggled(!editInputToggled);
            },
        },
    ];
    
    const baseOptions = [
        { title: 'Reply', icon: <Chats/>, onClick: () => setInputToggled(!inputToggled) },
        {
            title: 'Copy',
            icon: <Copy/>,
            onClick: () => {
                setToast({ text: TEXT_COPIED });
                copyToClipboard(content);
            },
        },
    ];
    
    const deleteOption = { title: 'Delete', icon: <Trash/>, onClick: () => setModalOpen(true) };
    
    const avatarSize = isReply ? AvatarSizesNames.SMALL : AvatarSizesNames.MEDIUM;
    
    const onImageClick = useCallback(() => {
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: true,
                imagePreviewArray: [images?.[0]],
                imagePreviewIndex: 0,
            })
        );
    }, [fullSizeImages]);
    const isCommentCreator = currentUser.id === creatorId;
    
    return (
        <div className={styles.container}>
            <Avatar
                className={styles.commentAvatar}
                size={avatarSize}
                alt={userName}
                src={
                    currentUser.userImageTemp ||
                    generateImageUrl({
                        imageId: userImageId,
                        width: avatarSize,
                        height: avatarSize,
                        reason: 'show_user_image',
                    })
                }
            />
            
            <div className={styles.contentContainer}>
                <div className={styles.commentContainer}>
                    <div className={styles.comment}>
                        {!editInputToggled ? (
                            <div
                                className={classNames(styles.commentBody, {
                                    [styles.replyBody]: isReply,
                                })}
                            >
                                <PostSecondaryHeadline
                                    createdAt={createdAt}
                                    userName={userName}
                                    userType={userType}
                                    postId={id}
                                    userId={creatorId}
                                    isScrolling={isScrolling}
                                />
                                <Linkify>
                                    <p className={styles.commentContent} ref={commentRef}>
                                        <TextArrayBody body={textArray} id={`${postId}-${id}`} postId={id} isTaggingDisabled/>
                                    </p>
                                </Linkify>
                                <DropdownMenu
                                    menuSize={MenuSize.NatiSize}
                                    options={isCommentCreator
                                        ? [...baseOptions, ...ownCommentOptions, deleteOption]
                                        : [...baseOptions, deleteOption]}
                                    className={styles.dropDown}
                                />
                            </div>
                        ) : (
                            <div className={styles.editBody}>
                                <Input
                                    id={`post-${id}-reply`}
                                    isFocus
                                    className={styles.replyInput}
                                    placeholder='reply here'
                                    withFilePicker
                                    onEnter={({ textArray, files }) => {
                                        editReply({ textArray, files });
                                        setEditInputToggled(false);
                                    }}
                                    defaultValue={textArray}
                                    onEsc={() => setEditInputToggled(false)}
                                    startingInputRows={editInputStartingRows}
                                />
                                <span className={styles.cancelEdit}>
                                    Press esc to{' '}
                                    <span className={styles.cancelButton} onClick={() => setEditInputToggled(false)}>
                                        cancel
                                    </span>
                                </span>
                            </div>
                        )}
                        {!!attachments?.length && <img src={attachments?.[0]} className={styles.imageInComment} onClick={onImageClick}/>}
                        {!temp && !editInputToggled && (
                            <div className={styles.engagements}>
                                <div>
                                    <span
                                        onClick={clapOnComment}
                                        className={classNames({
                                            [styles.clapCta]: true,
                                            [styles.clapped]: comment.isClapped,
                                        })}
                                    >
                                        Clap
                                    </span>
                                    <span onClick={() => setInputToggled(!inputToggled)} style={{ marginRight: '16px' }}>
                                        Reply
                                    </span>
                                </div>
                                {comment.isClapped && <ClapIndication clapsCount={clapsCount}/>}
                            </div>
                        )}
                    </div>
                    {inputToggled && (
                        <div className={styles.replyInputContainer}>
                            <CurrentUserAvatar size={AvatarSizesNames.SMALL}/>
                            <Input
                                isFocus
                                id={`post-${id}-reply-to`}
                                className={styles.replyInput}
                                placeholder={replyToPlaceholder}
                                tag={isReplyingMyself ? {} : { text: userName, user_id: creatorId, text_type: TagTypes.USER_TAG }}
                                withFilePicker
                                onEnter={replyOnComment}
                            />
                        </div>
                    )}
                </div>
                {comment?.replies?.length > 0 && showReplies && (
                    <CommentsSection
                        postId={postId}
                        comments={comment?.replies}
                        totalComments={comment?.totalReplies}
                        repliesSettings={{
                            ...repliesSettings,
                            numberOfLastCommentsToShow:
                                repliesOpened > repliesSettings.numberOfLastCommentsToShow ? repliesOpened : repliesSettings.numberOfLastCommentsToShow,
                        }}
                        showReplies={showReplies}
                        isReply
                        repliesCursor={repliesCursor}
                        relatedCommentId={id}
                        commentId={comment.id}
                    />
                )}
            </div>
            <ModalDelete
                header={postCopies?.header}
                content={postCopies?.content}
                okContent={postCopies?.delete}
                cancelContent={postCopies?.cancel}
                showModal={modalOpen}
                onCancel={() => setModalOpen(false)}
                onDelete={deleteComment}
            />
        </div>
    );
};

export default Comment;

Comment.propTypes = {
    comment: PropTypes.object,
    showReplies: PropTypes.bool,
    repliesSettings: PropTypes.exact({
        numberOfLastCommentsToShow: PropTypes.number,
        numberToLoad: PropTypes.number,
    }),
};
