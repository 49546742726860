import { useEffect, useMemo, useRef, useState } from 'react';
import { Plus, X } from 'phosphor-react';
import { noop } from 'lodash';
import classNames from 'classnames';
import Rating from '../common/Ratings';
import Tags from '../FilterTags/Tags';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../redux/user';
import { mapActions, mapSelectors } from '../../redux/map';
import { mapTagObjects } from '../../helpers';
import Routes from '../../config/routes';
import CustomButton, { ButtonSize, ButtonType } from '../common/buttons/CustomButton';
import copiesPrefix from '../../copies.json';
import styles from './index.module.scss';
import ModalsRatingSettings from '../common/modals/ModalsRatingSettings';
import NewCategoryInput from './NewCategoryInput';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';

const copies = copiesPrefix.addStep.tagsAndRatings;

export const TagsAndRatings = ({ onClose = noop, onDone = noop, step }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapId = useSelector(userSelectors.selectedMapId);
  const map = useSelector(mapSelectors.map(mapId));
  const [ratingsModalOpen, setRatingsModalOpen] = useState(false);
  const [addCategoryIsOpen, setAddCategoryIsOpen] = useState(false);
  const ratingsComponent = map?.components?.find(({ id }) => id === 'rating');
  const ratings = useMemo(() => ratingsComponent?.rating_subcomponents.filter(item => item?.type === 'score1to5') || [], [ratingsComponent]);
  const tagsObject = useMemo(() => mapTagObjects(map?.tag_objects, map?.tags_categories), [map]);
  
  // for making box shadow only where there is a scroll
  const ratingsAndTagsDiv = useRef();
  const [isShowBoxShadow, setIsShowBoxShadow] = useState(false);
  
  const [stepRatings, setStepRatings] = useState(ratings.map(rating => ({ ...rating, value: 0 })));
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setStepRatings(ratings.map(rating => ({ ...rating, value: 0 })));
  }, [ratings]);
  
  const handleSelectedTag = tag => {
    const withoutPicked = tags.filter(pickedTag => pickedTag._id !== tag._id);
    const ifDeleted = withoutPicked.length !== tags.length;
    if (ifDeleted) {
      setTags(withoutPicked);
    } else {
      mixpanel.track(mixpanelEvents.MM_STEP_CREATION_TAG_CHOOSEN, {
        [mixpanelProperties.STEP_NAME]: step?.title,
        [mixpanelProperties.STEP_ID]: step?._id,
        [mixpanelProperties.TAG]: tag?.name,
        [mixpanelProperties.CATEGORY]: tag?.category,
      });
      setTags([...tags, tag]);
    }
  };
  
  const onFinish = async () => {
    const selectedTagsIds = [];
    const actionsToDispatch = [];
    tags.forEach(tag => selectedTagsIds.push(tag._id));
    
    setIsLoading(true);
    
    const updatedRatings = stepRatings.filter(stepRating => stepRating.value).map(({ id, value }) => ({ rating_subcomponent_id: id, value }));
    
    if (selectedTagsIds.length) actionsToDispatch.push(mapActions.addTagToStep(step?._id, mapId, selectedTagsIds, true));
    if (updatedRatings.length)
      actionsToDispatch.push(
        mapActions.editStepRating(
          step._id,
          {
            map_id: mapId,
            rating_component_id: ratingsComponent.id,
            subcomponents: updatedRatings,
          },
          mapId
        )
      );
    
    await Promise.allSettled(actionsToDispatch.map(action => dispatch(action)));
    
    setIsLoading(false);
    onDone();
  };
  
  const updateRatings = (newRating, rating) => {
    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_RATED, {
      [mixpanelProperties.STEP_NAME]: step?.title,
      [mixpanelProperties.STEP_ID]: step?._id,
      [mixpanelProperties.RATING_TITLE]: rating?.text,
      [mixpanelProperties.RATING]: newRating,
    });
    const updatedRatings = stepRatings.map(item => {
      if (item.id === rating.id) item.value === newRating ? (item.value = 0) : (item.value = newRating);
      return item;
    });
    setStepRatings(updatedRatings);
  };
  
  useEffect(() => {
    if (ratingsAndTagsDiv.current.clientHeight < ratingsAndTagsDiv.current.scrollHeight) setIsShowBoxShadow(true);
  }, [ratingsAndTagsDiv]);
  
  const renderRatings = () => {
    if (stepRatings.length === 0) return <span className={styles.empty}>{copies.ratings_empty_state_message}</span>;
    return stepRatings?.map(rating => (
      <div>
        <span className={styles.ratingTitle}>{rating.text}</span>
        <Rating
          updateRating={newRatings => updateRatings(newRatings, rating)}
          starSize={33}
          className={styles.ratingBlock}
          selectedStarts={rating.value}
        />
      </div>
    ));
  };
  
  const toggleRatingModal = () => {
    if (!ratingsModalOpen) {
      mixpanel.track(mixpanelEvents.MM_STEP_CREATION_RATING_SETTINGS_CLICKED, {
        [mixpanelProperties.STEP_NAME]: step?.title,
        [mixpanelProperties.STEP_ID]: step?._id,
      });
    }
    setRatingsModalOpen(open => !open);
  };
  
  const moveToTagsSettings = () => {
    navigate(`/${Routes.TAGS}`);
  };
  
  const toggleAddCategory = () => {
    // focus on addCategoryInput
    setAddCategoryIsOpen(true);
  };
  
  const renderAddCategoryButton = () => {
    return (
      <div className={styles.addNewCategory} onClick={toggleAddCategory}>
        <Plus className={styles.plus} color='#2171EC' weight='bold' size={15}/>
        {copies.add_category}
      </div>
    );
  };
  
  return (
    <div className={styles.container}>
      <X className={styles.cancel} onClick={onClose} size={15} color='#2171EC' weight='bold'/>
      <div className={styles.viewHeader}>
        <span className={styles.stepTitle}>{step.title}</span>
        <span>{copies.secondary_header}</span>
      </div>
      <div className={styles.ratingAndTags} ref={ratingsAndTagsDiv}>
        <div className={styles.section}>
          <div className={styles.headerContainer}>
            <span className={classNames(styles.tagsTitle, styles.cardHeader)}>{copies.tags}</span>
            {!Object.values(tagsObject)?.length && (
              <span className={styles.settings} onClick={moveToTagsSettings}>
                {copies.tags_settings}
              </span>
            )}
          </div>
          
          {Object.values(tagsObject)?.length ? (
            Object.values(tagsObject).map((item, idx) => (
              <Tags
                idx={idx}
                category={item}
                selectedTags={tags}
                handleSelectTagClick={handleSelectedTag}
                titleClassName={styles.tagTitle}
                key={item.category_id}
                addTagInput={true}
                step={step}
              />
            ))
          ) : (
            <span className={styles.empty}>{copies.tags_empty_state_message}</span>
          )}
          {addCategoryIsOpen && <NewCategoryInput onClose={() => setAddCategoryIsOpen(false)}/>}
          {/* @TODO: AddNewCategoryInput
                    {renderAddCategoryButton()} */}
        </div>
      </div>
      <div
        className={classNames(styles.footer, {
          [styles.boxShadowForScrollableDiv]: isShowBoxShadow,
        })}
      >
        <CustomButton
          type={ButtonType.PRIMARY_BLUE}
          size={ButtonSize.MEDIUM}
          text='Done'
          className={styles.doneBtn}
          loader={isLoading}
          onClick={onFinish}
        />
      </div>
      <ModalsRatingSettings isActive={ratingsModalOpen} onClickPrev={toggleRatingModal} step={step}/>
    </div>
  );
};

export default TagsAndRatings;
