import { useState } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { userSelectors } from '../../../../redux/user';
import { mapSelectors } from '../../../../redux/map';
import DeleteButton from './DeleteButton';
import KeepButton from './KeepButton';
import { BaseCard } from '../BaseCard';
import { Outcomes } from '../../constants';
import Outcome from '../Outcome';

const StepClosedCard = ({
    stepId,
    stepName,
    textArray = [],
    avatarImgId = null,
    isPermanent = false,
    onKeep = noop,
    onDelete = noop,
    onCardClick = noop,
    onDisregard = noop,
    onTagClick = noop,
    createdAt = 0,
    handlerManager = '',
    managerPreview = {},
    outcome = '',
    isHandled = false,
    ...rest
}) => {
    const [data, setData] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const user = useSelector(userSelectors.user);
    const mapId = useSelector(userSelectors.selectedMapId);
    const maps = useSelector(userSelectors.maps);
    const mapName = useSelector(mapSelectors.mapName(mapId));
    const selectedMapId = useSelector(userSelectors.selectedMapId);
    const selectedMap = maps?.filter(({ _id }) => _id === selectedMapId)[0];
    const managers = selectedMap?.managers;
    const managerInfomation = managers?.filter(({ _id }) => _id === handlerManager)[0];

    const params = {
        stepId,
        stepName,
        mapId,
        mapName,
        clicked: isDisabled,
    };

    const handleKeep = () => {
        setIsDisabled(true);
        onKeep(response => {
            if (response.data?.result) {
                setData(response.data);
            } else {
                setIsDisabled(false);
            }
        });
    };

    const handleDelete = () => {
        setIsDisabled(true);
        onDelete(response => {
            if (response.data?.result) {
                setData(response.data);
            } else {
                setIsDisabled(false);
            }
        });
    };

    return (
        <BaseCard
            onClick={onCardClick}
            avatarImgId={avatarImgId}
            textArray={textArray}
            createdAt={createdAt}
            onClose={onDisregard}
            onTagClick={onTagClick}
            shouldRenderFooter={isHandled || Object.keys(data).length > 0}
            {...rest}
            footer={
                <Outcome
                    text={
                        (outcome || data.result) === Outcomes.DISREGARD ? (
                            <FormattedMessage id='mapUpdates.kept_by' />
                        ) : (
                            <FormattedMessage id='mapUpdates.removed_by' />
                        )
                    }
                    managerPreview={
                        (managerPreview && Object.keys(managerPreview).length > 0) ||
                        managerInfomation ||
                        (Object.keys(data).length > 0 && {
                            image: user.image,
                            display_name: user.display_name,
                        })
                    }
                />
            }
            buttons={
                isPermanent
                    ? [<KeepButton onClick={handleKeep} {...params} />, <DeleteButton isPrimary onClick={handleDelete} {...params} />]
                    : [<DeleteButton onClick={handleDelete} {...params} />, <KeepButton isPrimary onClick={handleKeep} {...params} />]
            }
        />
    );
};

export default StepClosedCard;
