import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Sliders } from 'phosphor-react';

const FilterButton = ({ className, onClick = noop, selectedFilters }) => {
    return (
        <div className={classNames(styles.block, className)} onClick={onClick}>
            {selectedFilters > 0 && <span className={styles.count}>{selectedFilters}</span>}
            <Sliders className={styles.filterIcon} />
        </div>
    );
};

FilterButton.propTypes = {
    selectedFilters: PropTypes.number,
};

export default FilterButton;
