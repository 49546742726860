import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { noop } from 'lodash';
import styles from './index.module.scss';
import DragAndDrop from '../DragAndDrop';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import DropZone from '../DropZone';
import { useDispatch, useSelector } from 'react-redux';
import { postActions } from '../../redux/post';
import { generateImageUrl, getImageSrcFromFile, getUploadImageInfo, uploadImageToS3 } from '../../helpers';
import { userActions, userSelectors } from '../../redux/user';
import { REQUEST_NOTIFICATIONS } from '../../config/constants';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { userActionSelectors } from '../../redux/userAction';
import { mapActions, mapSelectors } from '../../redux/map';
import ImageLoader from '../common/ImageLoader';
// import { Prompt } from "react-router";
import { TextareaAutosize } from '@material-ui/core';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import ImageCarousel from '../../lib/ImageCarousel';
import copiesPrefix from '../../copies.json';
import { TextInput } from '../TextInput';
import context from '../../constants/context';

const copies = copiesPrefix.createPost;

const handleBeforeUnload = e => {
    e.preventDefault();
    const message = 'Are you sure you want to leave? All provided data will be lost.';
    e.returnValue = message;
    return message;
};

const CreatePost = ({
    text,
    setText,
    files,
    setFiles,
    setDataRequest,
    onCancel,
    onReply,
    clickReplyPage,
    user,
    postStepId,
    postId,
    answerToUser,
    mapId,
    addImage,
    listView,
    setUpdatedStepId,
    fromRecentActivities,
    updateStepPostsCount,
    onReplySuccess = noop,
}) => {
    const dispatch = useDispatch();
    const userImageTemp = useSelector(userSelectors.userImageTemp);
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
    const ghostStep = useSelector(mapSelectors.ghostStep(mapId));
    const selectedPlace = useSelector(userActionSelectors.selectedPlaceToAdd);
    const [textArray, setTextArray] = useState([]);
    const [disable, setDisable] = useState(false);
    const [types, setType] = useState('');
    const [imageLoading, setImageLoader] = useState(false);
    const [isFormIncomplete, setIsFormIncomplete] = useState(false);

    const IMAGE_CAROUSEL_HEIGHT = 139;
    const IMAGE_CAROUSEL_WIDTH = 215;

    useEffect(() => {
        if (text.length > 0 || files.length > 0) {
            setIsFormIncomplete(true);
        } else {
            setIsFormIncomplete(false);
        }
    }, [text, files]);

    useEffect(() => {
        if (text.length > 0 || files.length > 0) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [text, files]);

    useEffect(() => {
        if (!selectedPlace) {
            ghostStep && ghostStep.title !== 'selected location' && dispatch(mapActions.setGhostStep(mapId, null));
            return;
        }

        dispatch(
            mapActions.setGhostStep(mapId, {
                position: selectedPlace?.position,
                title: selectedPlace?.title,
                suggestToAdd: false,
                isOpenTooltip: true,
                isStickToMap: true,
            })
        );
        dispatch(
            mapActions.setMapCenter(mapId, {
                ...mapCenter,
                position: selectedPlace?.position,
            })
        );
    }, [selectedPlace?.position, selectedPlace?.title, mapId]);

    const generateNewImagesUrl = () =>
        files.map(image =>
            getImageSrcFromFile({
                image,
                isGenerateInFixedSize: true,
                width: IMAGE_CAROUSEL_WIDTH,
                height: IMAGE_CAROUSEL_HEIGHT,
                reason: 'map_manager_CreatePost',
            })
        );
    const generatedSizedImages = useMemo(generateNewImagesUrl, [files]);
    const removeFile = idx => {
        setFiles([...files.slice(0, idx), ...files.slice(idx + 1)]);
    };

    const onLoadFiles = async (files, type) => {
        setType(type);
        setImageLoader(true);
        await Promise.all(
            Object.values(files).map(
                file =>
                    new Promise((resolve, reject) =>
                        getUploadImageInfo()
                            .then(info =>
                                uploadImageToS3({
                                    uploadUrl: info.url,
                                    file,
                                    onUploadedFile: () => {
                                        setFiles(state =>
                                            state.concat({
                                                file: file,
                                                imageId: info['image_id'],
                                            })
                                        );
                                        resolve();
                                    },
                                })
                            )
                            .catch(reject)
                    )
            )
        );
        setImageLoader(false);
    };

    const handleAddPostClick = useCallback(() => {
        setDisable(true);
        onReply && onReply();
        onCancel();
        if (clickReplyPage) {
            dispatch(
                postActions.createPostsInPost(
                    {
                        parent_post_id: postId,
                        client_type: 'map_manager',
                        text_array: textArray.length ? textArray : [{ text, text_type: 'text' }],
                        attachments: files.map(image => ({
                            type: 'IMAGE',
                            image_id: image.imageId,
                        })),
                    },
                    postStepId,
                    clickReplyPage,
                    mapId
                )
            ).then(({ data, error }) => {
                if (data) {
                    updateStepPostsCount && updateStepPostsCount(postStepId);
                    if (fromRecentActivities) {
                        dispatch(postActions.getStepPost(postId));
                    }
                    if (text)
                        mixpanel.track(
                            mixpanelEvents.ADD_POST_ADD_TEXT,
                            {
                                [mixpanelProperties.STEP_TITLE]: data?.post?.location_title,
                            },
                            {
                                [mixpanelProperties.STEP_ID]: data?.post?.step_id,
                            },
                            {
                                [mixpanelProperties.FROM_WHERE_ADD_POST]: localStorage.getItem('fromWhereAddPost'),
                            },
                            { [mixpanelProperties.POST_ID]: data?.post?._id },
                            { [mixpanelProperties.POST_ON]: 'post' }
                        );
                    mixpanel.track(mixpanelEvents[clickReplyPage ? 'ADD_REPLY_FINISH' : 'ADD_POST_FINISH'], {
                        [mixpanelProperties.FROM_WHERE]: listView ? 'list_view' : 'step_page',
                        [mixpanelProperties.STEP_ID]: data?.post?.step_id,
                        [mixpanelProperties.IMAGE]: files.length > 0,
                        [mixpanelProperties.TEXT]: text.length > 0,
                    });
                    dispatch(
                        userActions.setMessageRequest({
                            text: REQUEST_NOTIFICATIONS.REPLY_SUCCESS,
                            status: 'success',
                            img: REQUEST_NOTIFICATIONS.IMG_SUCCESS,
                        })
                    );
                    setTimeout(() => {
                        dispatch(userActions.setMessageRequest());
                    }, 2500);
                    onReplySuccess(data?.post);
                } else if (error) {
                    dispatch(
                        userActions.setMessageRequest({
                            text: REQUEST_NOTIFICATIONS.REPLY_ERROR,
                            status: 'error',
                            img: REQUEST_NOTIFICATIONS.IMG_ERROR,
                        })
                    );
                    setDataRequest &&
                        setDataRequest({
                            type: 'CREATE_POSTS_IN_POST',
                            method: 'POST',
                            url: '/steps3/posts',
                            data: {
                                parent_post_id: postId,
                                client_type: 'map_manager',
                                text_array: textArray.length ? textArray : [{ text, text_type: 'text' }],
                                attachments: files.map(image => ({
                                    type: 'IMAGE',
                                    image_id: image.imageId,
                                })),
                            },
                            meta: {
                                click: clickReplyPage,
                                stepId: postStepId,
                                postId: postId,
                                thunk: true,
                            },
                            text: {
                                success: REQUEST_NOTIFICATIONS.REPLY_SUCCESS,
                                error: REQUEST_NOTIFICATIONS.REPLY_ERROR,
                            },
                        });
                }
            });
        } else {
            if (!selectedPlace) {
                // FIXME:
                // eslint-disable-next-line no-undef
                onClickSelectLocation && onClickSelectLocation();
                return;
            }
            // setIsAddingPost(true);
            dispatch(
                mapActions.createStepPost(mapId, {
                    lat: selectedPlace?.position.lat || selectedPlace?.position[0],
                    lon: selectedPlace?.position.lon || selectedPlace?.position.lng || selectedPlace?.position[1],
                    title: selectedPlace?.title,
                    text,
                    textArray,
                    context: context.STEP_PROFILE,
                    attachments: files.map(image => ({
                        type: 'IMAGE',
                        image_id: image.imageId,
                    })),
                    stepId: selectedPlace?.stepId,
                    locationId: selectedPlace?.locationId,
                    placeId: selectedPlace?.placeId,
                })
            ).then(({ data, error }) => {
                const hasLocation = Boolean(textArray.find(({ text_type }) => text_type === 'step_tag'));
                const hasMemberTagged = Boolean(textArray.find(({ text_type }) => text_type === 'user_tag'));
                const locationTaggedCount = hasLocation ? textArray.filter(({ text_type }) => text_type === 'step_tag').length : 0;
                const memberTaggedCount = hasLocation ? textArray.filter(({ text_type }) => text_type === 'user_tag').length : 0;

                if (data) {
                    setUpdatedStepId && setUpdatedStepId(selectedPlace?.stepId);
                    if (text)
                        mixpanel.track(
                            mixpanelEvents.ADD_POST_ADD_TEXT,
                            {
                                [mixpanelProperties.STEP_TITLE]: data?.post?.location_title,
                            },
                            {
                                [mixpanelProperties.STEP_ID]: data?.post?.step_id,
                            },
                            {
                                [mixpanelProperties.FROM_WHERE_ADD_POST]: localStorage.getItem('fromWhereAddPost'),
                            },
                            { [mixpanelProperties.POST_ID]: data?.post?._id },
                            {
                                [mixpanelProperties.POST_ON]: selectedPlace?.type === 'location' || selectedPlace?.type === 'place' ? 'place' : 'step',
                            }
                        );
                    mixpanel.track(mixpanelEvents[clickReplyPage ? mixpanelEvents.ADD_REPLY_FINISH : mixpanelEvents.ADD_POST_FINISH], {
                        [mixpanelProperties.FROM_WHERE]: listView ? 'list_view' : 'step_page',
                        [mixpanelProperties.STEP_ID]: data?.post?.step_id,
                        [mixpanelProperties.IMAGE]: files.length > 0,
                        [mixpanelProperties.TEXT]: (text || textArray).length > 0,
                        [mixpanelProperties.HAS_LOCATION_TAGGED]: hasLocation,
                        [mixpanelProperties.HAS_MEMBER_TAGGED]: hasMemberTagged,
                        [mixpanelProperties.LOCATION_TAGGED_COUNT]: locationTaggedCount,
                        [mixpanelProperties.MEMBER_TAGGED_COUNT]: memberTaggedCount,
                    });
                    selectedPlace?.stepId
                        ? dispatch(mapActions.setGhostStep(mapId, null))
                        : dispatch(
                              mapActions.setGhostStep(mapId, {
                                  ...ghostStep,
                                  type: 'newStep',
                                  isOpenTooltip: false,
                              })
                          );
                    // onPrevClick && onPrevClick();
                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            ...mapCenter,
                            change: true,
                        })
                    ); // rerender the steps list
                    dispatch(
                        userActions.setMessageRequest({
                            text: REQUEST_NOTIFICATIONS.POST_SUCCESS,
                            status: 'success',
                            img: REQUEST_NOTIFICATIONS.IMG_SUCCESS,
                        })
                    );
                    // onClickAddPost && onClickAddPost(data);
                    // setIsAddingPost(false);
                    // setAddedStep(data.step);
                    setTimeout(() => {
                        dispatch(userActions.setMessageRequest());
                    }, 2500);
                    setTimeout(() => dispatch(mapActions.setGhostStep(mapId, null)), 1300);
                    onReplySuccess(data?.post);
                } else if (error) {
                    dispatch(
                        userActions.setMessageRequest({
                            text: REQUEST_NOTIFICATIONS.POST_ERROR,
                            status: 'error',
                            img: REQUEST_NOTIFICATIONS.IMG_ERROR,
                        })
                    );
                    setDataRequest &&
                        setDataRequest({
                            type: 'CREATE_POST',
                            method: 'POST',
                            url: '/steps3/posts',
                            data: {
                                location_parameters: {
                                    lat: selectedPlace?.position.lat || selectedPlace?.position[0],
                                    lon: selectedPlace?.position.lon || selectedPlace?.position.lng || selectedPlace?.position[1],
                                    title: selectedPlace?.title,
                                },
                                map_id: mapId,
                                text_array: textArray.length ? textArray : [{ text, text_type: 'text' }],
                                attachments: files.map(image => ({
                                    type: 'IMAGE',
                                    image_id: image.imageId,
                                })),
                                stepId: selectedPlace?.stepId,
                                locationId: selectedPlace?.locationId,
                                placeId: selectedPlace?.placeId,
                                client_type: 'map_manager',
                            },
                            meta: {
                                thunk: true,
                            },
                            text: {
                                success: REQUEST_NOTIFICATIONS.POST_SUCCESS,
                                error: REQUEST_NOTIFICATIONS.POST_ERROR,
                            },
                        });
                }
            });
        }
    }, [text, postId, files, types, clickReplyPage, mapId, fromRecentActivities, updateStepPostsCount]);

    return (
        <>
            <DragAndDrop onDrop={files => onLoadFiles(files, 'drop')}>
                <Grid className={styles.userBlock} container alignItems='center' item xs={12}>
                    <div className={styles.userHeaderBlock}>
                        <Avatar
                            className={styles.avatar}
                            alt={user?.name}
                            src={
                                userImageTemp
                                    ? userImageTemp
                                    : generateImageUrl({
                                          imageId: user?.image,
                                          width: 31,
                                          height: 31,
                                          reason: 'show_user_image',
                                      })
                            }
                        />
                        <span className={styles.name}>{user?.first_name ? `${user?.first_name} ${user?.last_name}` : user?.name}</span>
                        <span className={styles.replying}>
                            {clickReplyPage ? (
                                <>
                                    <span className={styles.replyText}>•{copies.replying_to}</span>
                                    <span className={styles.boldReply}>@{answerToUser?.display_name}</span>
                                </>
                            ) : (
                                <>
                                    <span className={styles.arrow}>‣</span>
                                    <div className={styles.placeTitle}>{selectedPlace?.title}</div>
                                </>
                            )}
                        </span>
                    </div>
                </Grid>
                <Grid className={styles.textareaBlock} item xs={12}>
                    <div className={styles.content}>
                        <TextInput
                            id='create-post-textarea'
                            onChange={(value, textArray) => {
                                setText(value);
                                setTextArray(textArray);
                            }}
                            data={text}
                            className={styles.textarea}
                            autoFocus
                            cols='30'
                            rows={7}
                            placeholder={`${clickReplyPage ? copies.write_reply : listView ? copies.placeholder : copies.write_update}`}
                        />
                        {imageLoading ? (
                            <ImageLoader className={styles.imageLoader} />
                        ) : (
                            files?.length > 0 && (
                                <ImageCarousel
                                    classNameDiv={styles.imgBlock}
                                    classNameImage={styles.imgSlide}
                                    addDeleteIcon
                                    onDeleteClick={removeFile}
                                    settings={{
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                    }}
                                    images={generatedSizedImages}
                                />
                            )
                        )}
                    </div>
                    <Grid container item className={styles.footer}>
                        <DropZone
                            addImage={addImage}
                            onLoadFiles={files => onLoadFiles(files, 'upload')}
                            addOn={clickReplyPage ? 'reply' : 'post'}
                            fromWhere={listView ? 'list_view' : 'step_page'}
                            stepId={postStepId || selectedPlace?.stepId}
                        />
                        <CustomButton
                            onClick={text?.length !== 0 || files?.length !== 0 ? () => handleAddPostClick() : null}
                            size={'largeSize'}
                            disabled={(text?.length === 0 && files?.length === 0) || disable || imageLoading}
                            type={ButtonType.PRIMARY_BLUE}
                            text={clickReplyPage ? copies.reply : copies.post}
                        />
                    </Grid>
                </Grid>
            </DragAndDrop>
            {/* <Prompt when={isFormIncomplete} message={copies.changes_prompt} /> */}
        </>
    );
};

export default CreatePost;
