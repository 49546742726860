import * as React from 'react';
import { useMemo } from "react";
import { SITE_URL } from "../../constants";

const ATLY_RATIO = 0.30628272;

const Sizes = {
    SMALL: 0,
    MEDIUM: 1,
    LARGE: 2,
};

const Types = {
    WHITE: 0,
    BLUE: 1,
};

const AtlyLogo = ({
    size = Sizes.MEDIUM,
    type = Types.BLUE,
    isLink = true,
    ...rest
}) => {
    const color = useMemo(() => {
        switch (type) {
            case Types.WHITE:
                return "#FFFFFF";
            
            default:
            case Types.BLUE:
                return "#002551";
        }
    }, [type]);
    
    const width = useMemo(() => {
        switch (size) {
            case Sizes.SMALL:
                return 82;
            
            default:
            case Sizes.MEDIUM:
                return 128;
            
            case Sizes.LARGE:
                return 387;
        }
        
        return 129;
    }, [size]);
    
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 42 763.09 233.36"
            onClick={() => isLink && window.open(SITE_URL, '_self')}
            width={String(width)}
            height={width * ATLY_RATIO}
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.5385 42C23.97 42 0 65.97 0 95.5385V220.462C0 250.03 23.97 274 53.5385 274H178.462C208.03 274 232 250.03 232 220.462V95.5385C232 65.97 208.03 42 178.462 42H53.5385ZM65.1673 194.281C37.1693 166.283 37.1693 120.889 65.1673 92.8911C93.1654 64.8931 138.559 64.8931 166.557 92.8911C194.555 120.889 194.555 166.283 166.557 194.281L116.886 243.952C116.321 244.517 115.404 244.517 114.838 243.952L65.1673 194.281ZM80.0236 177.377C80.0236 178.513 80.9447 179.434 82.0808 179.434H115.87C135.668 179.434 151.717 163.385 151.717 143.587C151.717 123.79 135.668 107.741 115.87 107.741C96.0728 107.741 80.0237 123.79 80.0237 143.587L80.0236 177.377Z"
                fill={String(color)}
            />
            <path
                d="M393.76 105.728C382.104 95.56 366.976 89.36 349.616 89.36C309.44 89.36 279.432 119.616 279.432 160.04C279.432 200.464 309.44 230.968 349.616 230.968C366.728 230.968 381.608 225.016 393.016 214.848L396.24 227H424.512V93.08H397.48L393.76 105.728ZM352.592 196.744C331.512 196.744 316.136 180.872 316.136 160.04C316.136 138.96 331.512 123.336 352.592 123.336C373.672 123.336 389.048 138.96 389.048 160.04C389.048 181.12 373.672 196.744 352.592 196.744ZM520.099 194.76C506.707 194.76 499.267 187.32 499.267 174.424V122.344H535.723V93.328H498.771V58.36H491.827L439.003 114.408V122.344H463.059V179.384C463.059 209.144 481.163 227.248 510.675 227.248H536.467V195.008L520.099 194.76ZM597.397 227V44.968H561.189V227H597.397ZM691.174 175.664L655.462 93.328H616.03L672.822 216.832L672.326 217.824C664.142 238.16 659.678 242.872 642.07 242.872H629.67V275.36H644.55C674.558 275.36 688.694 261.72 704.814 225.264L763.094 93.08H724.654L691.174 175.664Z"
                fill={String(color)}
            />
        </svg>
    );
};

AtlyLogo.Sizes = Sizes;
AtlyLogo.Types = Types;

export default AtlyLogo;
