import React from 'react';

const IconStep = ({ stroke }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='24' viewBox='0 0 20 24'>
            <g fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'>
                <g stroke={stroke} stroke-width='1.5'>
                    <g>
                        <g transform='translate(-200 -273) translate(81 142) translate(120 132)'>
                            <path d='M9 22S0 16 0 9c0-4.97 4.03-9 9-9s9 4.03 9 9c0 7-9 13-9 13z' />
                            <circle cx='9' cy='9.353' r='3' />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default IconStep;
