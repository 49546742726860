import { FormattedMessage, useIntl } from "react-intl";
import React, { useState } from "react";
import classNames from "classnames";
import { EditButton } from "./EditButton";
import { noop } from "lodash";
import { IconClock } from "../../common/icons";
import { OpeningHours } from "./OpeningHours";

const ICON_COLOR = "#8F9DAE";
const ICON_SIZE = 24;

export const PlaceInfoOpeningHours = ({
  onChange = noop,
  onEdit = noop,
  value,
  placeholderId,
  isDisabled = false
}) => {
  const intl = useIntl();
  const [isEditView, setIsEditView] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  
  return (
      <div
          className={classNames("border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-200 py-4 px-6 flex items-start", {
            "bg-gray-100": isEditView,
          })}
          onMouseOver={() => !isDisabled && setIsHovered(true)}
          onMouseOut={() => !isDisabled && setIsHovered(false)}
      >
        <span className="w-6 mr-3 flex items-center justify-center">
            <IconClock stroke={ICON_COLOR} size={ICON_SIZE}/>
        </span>
        <div className="flex flex-col w-full">
          {isEditView && (
              <span className="text-gray-500">
                  <FormattedMessage id={value?.length > 0
                      ? 'stepInfo.hours'
                      : 'stepInfo.add_hours'}/>
              </span>
          )}
          {isEditView
              ? (
                  <textarea
                      autoFocus
                      disabled={isDisabled || !isEditView}
                      className="border-0 w-full text-sm bg-transparent outline-0"
                      onBlur={() => {
                        setIsHovered(false);
                        setIsEditView(false);
                      }}
                      onChange={e => onChange(e, type)}
                      value={value}
                      placeholder={intl.formatMessage({ id: placeholderId })}
                  />
              )
              : <OpeningHours>{value}</OpeningHours>}
        </div>
        {!isEditView &&
            <EditButton isHovered={isHovered} onClick={() => {
              onEdit();
              setIsEditView(true);
            }}/>}
      </div>
  )
};