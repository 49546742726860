import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { CircularProgress, ClickAwayListener, Grid } from '@material-ui/core';
import { mapTagObjects } from '../../helpers';
import IconPlus from '../common/icons/IconPlus';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, mapSelectors } from '../../redux/map';
import SearchTag from '../SearchTag';
import { nanoid } from 'nanoid';
import { arrToObj } from '../../helpers';
import CloseComponentButton from '../common/buttons/CloseComponentButton';
import { userActions, userSelectors } from '../../redux/user';
import { REQUEST_NOTIFICATIONS } from '../../config/constants';
import classNames from 'classnames';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { userActionSelectors } from '../../redux/userAction';
import Tag from '../EditTags/Tag';
import AddTag from '../EditTags/AddTag';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';

const transform = items => items.reduce((state, field) => Object.assign(state, { [field.id]: field }), {});

const SuggestionsTags = ({ loader, mapId, stepId, onClickPrev, openedBlock, tagsToRemove, tagsToAdd, outcome, onHandled, handled }) => {
    const dispatch = useDispatch();
    const step = useSelector(mapSelectors.steps(mapId))[stepId];
    const currentPage = useSelector(userActionSelectors.currentPage);
    const tags = useMemo(() => (step?.components && arrToObj(step?.components, 'type').selected_tags?.tags) || [], [step]);
    const defaultSelected = useMemo(() => tags.filter(item => item.is_selected).map(item => item._id), [tags?.length, tags]);
    const tagsObject = useMemo(() => mapTagObjects(tags), [tags?.length]);
    const [renderedTags, setTags] = useState({});

    useEffect(() => {
        if (stepId) {
            dispatch(mapActions.getFullStepData(mapId, stepId));
        }
    }, [stepId]);

    useEffect(() => {
        setTags(tagsObject);
    }, [tags, tagsObject, step, tagsToAdd, tagsToRemove]);

    const renderTags = useMemo(() => {
        return (
            <>
                {Object.values(renderedTags).map((category, idx) => (
                    <Fragment key={idx}>
                        <p className={styles.title}>{category.category}</p>
                        <Grid className={styles.tagsBlock} container>
                            {category.tags?.map((item, idx) => (
                                <Tag
                                    handled={handled}
                                    tagToAdd={tagsToAdd.some(tag => tag._id === item._id)}
                                    tagToRemove={tagsToRemove.some(tag => tag._id === item._id)}
                                    suggestionsTags
                                    checked={defaultSelected.indexOf(item._id) > -1}
                                    mapId={mapId}
                                    stepId={step?._id}
                                    tag={item}
                                    idx={idx}
                                />
                            ))}
                        </Grid>
                    </Fragment>
                ))}
            </>
        );
    }, [tagsObject, tags?.length, renderedTags, step, tagsToAdd, tagsToRemove?.length, defaultSelected?.length, handled]);
    return (
        <div
            className={classNames(styles.block, {
                [styles.blockOpen]: openedBlock,
                [styles.blockClosed]: !openedBlock,
            })}
        >
            {loader ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            padding: '0 16px 10px 16px',
                            borderBottom: '0.0625rem solid #dddddd',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '10px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className={classNames('animate')} style={{ width: '37px', height: '37px' }} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ width: '75px', height: '33px' }} className={classNames('animate')} />
                            <div className={classNames('animate')} style={{ width: '33px', height: '33px' }} />
                        </div>
                    </div>
                    <div style={{ overflowY: 'auto', flex: '1 0 1px' }}>
                        <div style={{ padding: '16px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '15px',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ width: '200px', height: '20px' }} className={classNames('animate')} />
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                        </div>
                        <div style={{ padding: '0 16px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '15px',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ width: '200px', height: '20px' }} className={classNames('animate')} />
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                        </div>
                        <div style={{ padding: '0 16px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '15px',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ width: '200px', height: '20px' }} className={classNames('animate')} />
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                        </div>
                        <div style={{ padding: '0 16px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '15px',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ width: '200px', height: '20px' }} className={classNames('animate')} />
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                                <div
                                    style={{
                                        flexGrow: '0',
                                        maxWidth: '45%',
                                        flexBasis: '50%',
                                        height: '20px',
                                        marginBottom: '10px',
                                    }}
                                    className={classNames('animate')}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: '25px 16px 0 16px',
                            width: '80%',
                            height: '30px',
                        }}
                        className={classNames('animate')}
                    />
                </div>
            ) : (
                <>
                    <div className={classNames(styles.wrapper)}>
                        <div>
                            <div className={styles.header}>
                                <div onClick={() => onClickPrev()} className={styles.closeBlock}>
                                    <img src='/assets/img/icon_cancel_blue.svg' />
                                </div>
                                <Grid container>
                                    <Grid
                                        style={{
                                            marginBottom: '16px',
                                            display: 'flex',
                                        }}
                                        item
                                        xs={handled ? 12 : 6}
                                        alignItems='center'
                                    >
                                        <span className={styles.title}>{handled ? 'Tags' : 'Tags Suggestions'}</span>
                                    </Grid>
                                    {!handled && (
                                        <Grid style={{ marginBottom: '16px' }} item xs={6}>
                                            <div className={styles.userReaction}>
                                                {(outcome === 'decline' || outcome === 'unhandled') && (
                                                    <CustomButton
                                                        onClick={() => onHandled && onHandled('decline')}
                                                        type={ButtonType.BLACK}
                                                        singleClick
                                                        clicked={outcome === 'decline'}
                                                    >
                                                        {outcome === 'decline' ? 'Removed' : 'Remove'}
                                                    </CustomButton>
                                                )}
                                                {(outcome === 'approve' || outcome === 'unhandled') && (
                                                    <CustomButton
                                                        onClick={() => onHandled && onHandled('approve')}
                                                        type={ButtonType.PRIMARY}
                                                        singleClick
                                                        clicked={outcome === 'approve'}
                                                    >
                                                        {outcome === 'approve' ? 'Kept' : 'Keep'}
                                                    </CustomButton>
                                                )}
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                                {!handled && (
                                    <div>
                                        <p className={styles.addTags}>
                                            <img src='/assets/img/steps3Images/blueV.svg' />
                                            {tagsToAdd?.length} tags to add
                                        </p>
                                        <p className={styles.removeTags}>
                                            <img src='/assets/img/steps3Images/redMinus.svg' />
                                            {tagsToRemove?.length} tags to remove
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className={styles.content}>
                                <div className={styles.container}>{renderTags}</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SuggestionsTags;
