import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { IconActiveCheck, IconLoader, WarningCircle } from './../../components/common/icons';
import { ToastTypes } from '.';
import CustomButton, { ButtonSize, ButtonType } from '../../components/common/buttons/CustomButton';

import styles from './index.module.scss';

const ICON_SIZE = 20;

const Icon = ({ type }) => {
  switch (type) {
    case ToastTypes.LOADING:
      return <IconLoader size={ICON_SIZE} className={styles.iconLoader}/>;
    
    case ToastTypes.ERROR:
      return <WarningCircle color='white' width={ICON_SIZE} height={ICON_SIZE}/>;
    
    default:
      return <IconActiveCheck color='white' size={ICON_SIZE}/>;
  }
};

const Toast = ({
  id,
  text,
  values = {},
  cta = {},
  secondaryCta = {},
  className,
  type,
  isOpen,
  withIcon = true,
  color = ''
}) => {
  if (!text && !id) {
    return null;
  }
  
  return (
    <div
      role={type === ToastTypes.ERROR ? 'alert' : 'status'}
      className={classNames(styles.requestInfo_position, className, {
        [styles.isOpen]: isOpen,
      })}
    >
      <div
        className={classNames(styles.requestInfo_block, {
          [styles.error]: type === ToastTypes.ERROR,
          [styles.loading]: type === ToastTypes.LOADING,
          [styles.blackBG]: color === 'black'
        })}
      >
        <div className={styles.requestInfo_text} style={{ marginRight: cta.text ? '100.5px' : 0 }}>
          <p className={styles.titleSuccess}>
            {withIcon && (
              <strong className={styles.toastIcon}>
                <Icon type={type}/>
              </strong>
            )}
            {id ? <FormattedMessage id={id} values={values}/> : text}
          </p>
        </div>
        <div className={styles.ctaWrapper}>
          {!!secondaryCta.text && (
            <CustomButton
              className={styles.button}
              type={ButtonType.SECONDARY_ON_BLACK}
              text={secondaryCta.text}
              onClick={secondaryCta.onClick}
              size={ButtonSize.MEDIUM}
            />
          )}
          {!!cta.text &&
            <CustomButton
              type={ButtonType.PRIMARY_ON_BLACK}
              text={cta.text}
              onClick={cta.onClick}
              size={ButtonSize.MEDIUM}/>}
        </div>
      </div>
    </div>
  );
};

export default Toast;
