import React from 'react';

const Icon = ({ width, height, stroke }) => {
    return (
        <svg width={width || '16'} height={height || '16'} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M11 3.5H14' stroke={stroke || '#212B37'} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            <path d='M12.5 2V5' stroke={stroke || '#212B37'} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            <path
                d='M1.93683 13.4994C2.55155 12.4354 3.43546 11.5519 4.49976 10.9376C5.56405 10.3234 6.77125 9.99999 8.00009 10C9.22892 10 10.4361 10.3234 11.5004 10.9377C12.5647 11.552 13.4486 12.4355 14.0633 13.4995'
                stroke={stroke || '#212B37'}
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M11.7532 7.38642C11.4859 8.10835 11.0157 8.73772 10.3993 9.19893C9.78293 9.66014 9.04648 9.93358 8.27845 9.98639C7.51042 10.0392 6.74347 9.86914 6.06974 9.49664C5.39602 9.12414 4.84417 8.56504 4.4805 7.88651C4.11683 7.20798 3.95679 6.43888 4.01963 5.6716C4.08246 4.90432 4.36549 4.1715 4.83471 3.56118C5.30393 2.95086 5.93939 2.48899 6.66474 2.23106C7.39009 1.97313 8.17449 1.93012 8.9237 2.10718'
                stroke={stroke || '#212B37'}
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
};

export default Icon;
