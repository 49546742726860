import React from 'react';
import styles from './index.module.scss';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/loaderAnimation.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
};
const Inline = () => (
    <div className={styles.spinner_edit}>
        <div className={styles.container_edit}>
            <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={120} />
        </div>
    </div>
);

const Loader = () => <Inline />;

export default Loader;
