import queryString from 'query-string';
import config from '../config';

const TWO_KM = 2000;

export const fetchLocationResults = async ({ mapId, lat, lon }) => {
    const accessToken = localStorage.getItem('at');
    const query = queryString.stringify({
        map_id: mapId,
        data_type: 'location',
        term: '',
        lat,
        lon,
        include_archived: true,
        max_radius: TWO_KM,
    });
    let response = await fetch(`${config.domain.address}/steps3/search?${query}`, {
        headers: {
            Authorization: accessToken,
            app_version: config.version.path,
            app_platform: 'map_manager',
        },
    });
    response = await response.json();
    return response.results.filter(val => val['result_type'] === 'location');
};
