import { noop } from 'lodash';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { FormattedMessage } from 'react-intl';

const KeepButton = ({ isPrimary, onClick = noop, stepId, stepName, mapId, mapName, ...rest }) => (
    <CustomButton
        onClick={() => {
            mixpanel.track(mixpanelEvents.STEP_KEPT, {
                [mixpanelProperties.STEP_ID]: stepId,
                [mixpanelProperties.STEP_NAME]: stepName,
                [mixpanelProperties.MAP_ID]: mapId,
                [mixpanelProperties.MAP_NAME]: mapName,
            });
            onClick();
        }}
        type={isPrimary ? ButtonType.PRIMARY : ButtonType.SECONDARY}
        size={ButtonSize.MEDIUM}
        {...rest}
    >
        <FormattedMessage id='recentActivities.stepClosed.cta_keep' />
    </CustomButton>
);

export default KeepButton;
