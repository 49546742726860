import { success, error } from '@redux-requests/core';
import {
    CHANGE_PIN_POST,
    DELETE_POST,
    GET_POSTS,
    GET_POST,
    CHANGE_CLAP_POST,
    CREATE_POSTS_IN_POST,
    GET_COMMENTS,
    GET_COMMENTS_ON_REPLY,
    SHOW_TEXT,
    EDIT_POST,
    POST_ANNOUNCEMENT,
    SEEN_ANNOUNCEMENT,
    CREATE_FEED_POST,
} from './types';
import mixpanel, { mixpanelEvents, mixpanelProperties } from './../../helpers/mixpanel';
import { CREATE_POST, GET_FULL_STEP_DATA, UPDATE_STEP_INFO } from '../map/types';

const initialState = {
    posts: [],
    cursor: null,
    parent_posts: [],
    post: {
        claps_count: null,
        comments_count: null,
        comments: [],
    },
    secondary_posts: [],
    show_text: {},
    notChangePosts: [],
    seenAnnouncement: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_TEXT: {
            return {
                ...state,
                show_text: action.payload ? { ...state.show_text, [action.payload]: true } : {},
            };
        }
        case success(GET_POST): {
            const oldParentPosts = state.parent_posts;
            const oldPost = state.post;
            let secondaryPosts;
            let secondaryPostsCursor;
            let parentPosts;
            if (action.response.data.post.parent_post_id) {
                secondaryPostsCursor = action?.response?.data?.comments_list?.cursor;
                secondaryPosts = action?.response?.data?.comments_list?.posts;
                const prependParent = (parentId, arr = []) => {
                    const item = [...oldParentPosts, oldPost].find(i => i._id === parentId);
                    if (item) arr.push(item);
                    if (item?.parent_post_id) return prependParent(item.parent_post_id, arr);
                    return arr;
                };
                parentPosts = prependParent(action.response.data.post.parent_post_id).reverse();
            } else {
                secondaryPostsCursor = action?.response?.data?.comments_list?.cursor;
                secondaryPosts = action?.response?.data?.comments_list?.posts;
                parentPosts = [];
            }

            return {
                ...state,
                post: action.response.data.post,
                secondary_posts: {
                    cursor: secondaryPostsCursor,
                    posts: secondaryPosts,
                },
                parent_posts: parentPosts,
            };
        }
        case error(GET_POST): {
            return {
                ...state,
            };
        }
        case success(CREATE_POST): {
            const stepId = action.meta?.stepId || action.response?.data?.step?._id || action.response?.data?.post?.step_id;
            let oldPosts = state.posts[stepId] || [];
            const pinnedPost = oldPosts.some(e => e.is_pinned);
            const botPost = oldPosts.some(e => e.type === 'STEPS_BOT');
            const isAnnouncement = oldPosts.some(e => e.type === 'ANNOUNCEMENT');
            const countBotPosts = oldPosts.filter(e => e.type === 'STEPS_BOT').length;
            const countPinnedPosts = oldPosts.filter(e => e?.is_pinned)?.length;
            const countAnnouncement = oldPosts.filter(e => e.type === 'ANNOUNCEMENT').length;

            if (isAnnouncement) {
                if (pinnedPost && botPost) {
                    oldPosts.splice(countPinnedPosts + countBotPosts + countAnnouncement, 0, action.response.data.post);
                } else if (pinnedPost) {
                    oldPosts.splice(countPinnedPosts + countAnnouncement, 0, action.response.data.post);
                } else if (botPost) {
                    oldPosts.splice(countBotPosts + countAnnouncement, 0, action.response.data.post);
                } else {
                    oldPosts.unshift(action.response.data.post);
                }
            } else {
                if (pinnedPost && botPost) {
                    oldPosts.splice(countPinnedPosts + countBotPosts, 0, action.response.data.post);
                } else if (pinnedPost) {
                    oldPosts.splice(countPinnedPosts, 0, action.response.data.post);
                } else if (botPost) {
                    oldPosts.splice(countBotPosts, 0, action.response.data.post);
                } else {
                    oldPosts.unshift(action.response.data.post);
                }
            }
            return {
                ...state,
                notChangePosts: {
                    ...state.posts,
                    [stepId]: oldPosts,
                },
                posts: {
                    ...state.posts,
                    [stepId]: oldPosts,
                },
            };
        }

        case CREATE_FEED_POST:
        case CREATE_POSTS_IN_POST: {
            const parentPostId = action.request.data.parent_post_id;
            const textArray = action.request.data.text_array;
            for (const textObj of textArray) {
                if (textObj.text_type !== 'text')
                    mixpanel.track(mixpanelEvents.POST_COMMENT_TAGGED, {
                        [mixpanelProperties.TAG_TYPE]: textObj.text_type,
                        [mixpanelProperties.TAG]: textObj.text,
                        [mixpanelProperties.TAG_ID]: textObj.user_id || textObj.place_id || textObj.step_id,
                        [mixpanelProperties.PARENT_POST_ID]: parentPostId,
                    });
            }
            return {
                ...state,
            };
        }

        case success(CREATE_FEED_POST):
        case success(CREATE_POSTS_IN_POST): {
            let newState = { ...state };
            let newPosts;
            let newPostsPost;
            let updatePost;
            let updatedPostsPost;
            const oldPosts = state.posts[action.meta.stepId] || [];
            const oldPost = state.post;
            const commentsPost = state.secondary_posts.posts;
            const parentCommentsPost = state.parent_posts;
            const newPost = action.response?.data?.post;
            const postId = action.meta.postId;
            const clickReplyPage = action.meta.click;

            const setPost = (post, count) => {
                if (post) {
                    return {
                        ...post,
                        comments_count: post.comments_count + count,
                    };
                }
                return post;
            };

            if (!action.meta.stepId) {
                if (!newPost) {
                    return state;
                }

                newState = {
                    ...newState,
                    post: {
                        ...newState.post,
                        comments_count: newState.post.comments_count + 1,
                        comments: [...(newState.post.comments || []), newPost],
                    },
                    posts: {
                        ...newState.posts,
                        [newPost?._id]: {
                            ...newState.posts[newPost?._id],
                            comments_count: newState.posts[newPost?._id]?.comments_count + 1,
                        },
                    },
                };
            }

            const stepPost = oldPosts.find(item => item._id === postId);
            newPosts = oldPosts.map(item => {
                if (item._id === postId) {
                    return setPost(stepPost, 1);
                }
                return item;
            });

            if (clickReplyPage === 'post_page_main') {
                updatePost = setPost(oldPost, 1);
            } else if (clickReplyPage === 'post-list-reply' || clickReplyPage === 'post_page_replays') {
                const postPost = commentsPost.find(item => item._id === postId);
                newPostsPost = commentsPost.map(item => {
                    if (item._id === postId) {
                        return setPost(postPost, 1);
                    }
                    return item;
                });
            } else {
                const postPost = parentCommentsPost.find(item => item._id === postId);
                updatedPostsPost = parentCommentsPost.map(item => {
                    if (item._id === postId) {
                        return setPost(postPost, 1);
                    }
                    return item;
                });
            }

            newState = {
                ...newState,
                post: updatePost || oldPost,
                posts: {
                    ...newState.posts,
                    [action.meta?.stepId || action.response.data?.step?._id]: newPosts || oldPosts,
                },
                secondary_posts: {
                    posts: newPostsPost || commentsPost,
                },
                parent_posts: updatedPostsPost || parentCommentsPost,
            };

            return newState;
        }

        case error(CREATE_POSTS_IN_POST): {
            return {
                ...state,
            };
        }
        case success(EDIT_POST): {
            const text = action.response.data.post.text;
            const newFiles = action.response.data.post.attachments;
            const postId = action.meta.postId;

            return {
                ...state,
                post: {
                    ...state.post,
                    text,
                    attachments: newFiles,
                },
                posts: {
                    ...state.posts,
                    [action.meta.stepId || action.response?.data?.step?._id]: state.posts[action.meta.stepId]?.map(item => {
                        if (item._id === postId) {
                            return {
                                ...item,
                                text,
                                attachments: newFiles,
                            };
                        }
                        return item;
                    }),
                },
                secondary_posts: {
                    ...state.secondary_posts,
                    posts: state.secondary_posts.posts?.map(item => {
                        if (item._id === postId) {
                            return {
                                ...item,
                                text,
                                attachments: newFiles,
                            };
                        }
                        return item;
                    }),
                },
                parent_posts: state.parent_posts?.map(item => {
                    if (item._id === postId) {
                        return {
                            ...item,
                            text,
                            attachments: newFiles,
                        };
                    }
                    return item;
                }),
            };
        }

        case success(GET_COMMENTS): {
            const oldCommentList = state.secondary_posts.posts;
            return {
                ...state,
                secondary_posts: {
                    posts: oldCommentList.concat(action?.response?.data?.posts),
                    cursor: action?.response?.data?.cursor,
                },
            };
        }
        case success(GET_COMMENTS_ON_REPLY): {
            const oldCommentListOnReply = state.posts_on_post_list.posts;
            return {
                ...state,
                posts_on_post_list: {
                    posts: oldCommentListOnReply.concat(action?.response?.data?.posts),
                    cursor: action?.response?.data?.cursor,
                },
            };
        }
        case success(GET_FULL_STEP_DATA): {
            return {
                ...state,
                notChangePosts: {
                    ...state.posts,
                    [action.meta.stepId]: action.response.data?.posts_list?.posts,
                },
                posts: {
                    ...state.posts,
                    [action.meta.stepId]: action.response.data?.posts_list?.posts,
                },
                cursor: action.response.data?.posts_list?.cursor,
            };
        }
        case error(GET_FULL_STEP_DATA): {
            return {
                ...state,
            };
        }

        case CHANGE_CLAP_POST: {
            const isClapped = action.request?.data?.action;
            return {
                ...state,
                post: {
                    ...state.post,
                    clapsCount: isClapped ? state.post?.clapsCount + 1 : state.post?.clapsCount - 1,
                },
            };
        }

        case success(CHANGE_CLAP_POST): {
            const oldPosts = state.posts[action.meta.stepId] || [];
            const oldPost = state.post;
            const oldComments = state.secondary_posts.posts;
            let newPosts;
            let newPost;
            let newPostPost;
            const postId = action.meta.postId;
            const click = action.meta.click;
            const newStatus = action.meta.action;
            const clapsCount = action.response.data.claps_count;

            const setPost = (post, status, clap_count) => {
                if (post) {
                    // eslint-disable-next-line no-param-reassign
                    post.is_clapped_by_user = status;
                    post.claps_count = clap_count;
                }
                return post;
            };

            if (!action.meta.stepId) {
                return {
                    ...state,
                    post: {
                        ...state.post,
                        claps_count: clapsCount,
                    },
                };
            }

            const stepPost = oldPosts.find(item => item._id === postId);
            newPosts = oldPosts.map(item => {
                if (item._id === postId) {
                    return setPost(stepPost, newStatus, clapsCount);
                }
                return item;
            });

            if (click === 'post_page_main') {
                newPost = setPost(oldPost, newStatus, clapsCount);
            } else if (click === 'post_page_replays') {
                const postPost = oldComments?.find(item => item?._id === postId);
                newPostPost = oldComments.map(item => {
                    if (item._id === postId) {
                        return setPost(postPost, newStatus, clapsCount);
                    }
                    return item;
                });
            }

            return {
                ...state,
                posts: { ...state.posts, [action.meta.stepId]: newPosts },
                post: newPost || oldPost,
                secondary_posts: {
                    posts: newPostPost || oldComments,
                },
            };
        }
        case error(CHANGE_CLAP_POST): {
            return {
                ...state,
            };
        }
        case success(CHANGE_PIN_POST): {
            const notChangePosts = state.notChangePosts[action.meta.stepId];
            const oldPosts = state.posts[action.meta.stepId];
            const oldPost = state.post;
            let newPosts;
            let newPost;
            const postId = action.meta.postId;
            const newStatus = action.meta.action;
            const setPost = (post, status) => {
                if (post) {
                    // eslint-disable-next-line no-param-reassign
                    post.is_pinned = status;
                }
                return post;
            };
            const stepPost = notChangePosts.find(item => item._id === postId);
            newPosts = notChangePosts.map(item => {
                if (item._id === postId) {
                    return setPost(stepPost, newStatus);
                }
                return item;
            });
            if (oldPost?._id === postId) {
                newPost = setPost(oldPost, newStatus);
            }
            const isAnnouncementPost = oldPosts.some(e => e.type === 'ANNOUNCEMENT');
            const announcementPost = oldPosts.filter(e => e.type === 'ANNOUNCEMENT');
            const onlyStepBot = notChangePosts.filter(el => el.type === 'STEPS_BOT');
            const postsWithPinnedWithoutStepBot = notChangePosts
                .filter(el => el.is_pinned && el.type !== 'STEPS_BOT')
                .sort((a, b) => a?.created_at - b?.created_at)
                .reverse();
            const postsWithoutPinnedAnnouncementStepBot = notChangePosts
                .filter(el => !el.is_pinned && el.type !== 'ANNOUNCEMENT' && el.type !== 'STEPS_BOT')
                .sort((a, b) => a?.created_at - b?.created_at)
                .reverse();
            if (stepPost?.is_pinned) {
                if (isAnnouncementPost) {
                    newPosts = [...announcementPost, ...postsWithPinnedWithoutStepBot, ...onlyStepBot, ...postsWithoutPinnedAnnouncementStepBot];
                } else {
                    newPosts = [...postsWithPinnedWithoutStepBot, ...onlyStepBot, ...postsWithoutPinnedAnnouncementStepBot];
                }
            } else {
                if (isAnnouncementPost) {
                    newPosts = [...announcementPost, ...postsWithPinnedWithoutStepBot, ...onlyStepBot, ...postsWithoutPinnedAnnouncementStepBot];
                } else {
                    newPosts = [...postsWithPinnedWithoutStepBot, ...onlyStepBot, ...postsWithoutPinnedAnnouncementStepBot];
                }
            }
            return {
                ...state,
                posts: { ...state.posts, [action.meta.stepId]: newPosts },
                post: newPost || oldPost,
            };
        }
        case error(CHANGE_PIN_POST): {
            return {
                ...state,
            };
        }
        case success(DELETE_POST): {
            const oldPosts = state.posts[action.meta.stepId] || [];
            const oldPost = state.post;
            const oldComments = state.secondary_posts.posts;
            const postId = action.meta.postId;
            const click = action.meta.click;
            let newPost;
            let newPosts;
            let newPostList;

            const setPost = (post, count) => {
                if (post) {
                    // eslint-disable-next-line no-param-reassign
                    post.comments_count = post.comments_count - count;
                }
                return post;
            };
            newPosts = oldPosts.filter(({ _id }) => postId !== _id);
            if (click === 'post-on-post-deleted') {
                newPostList = oldComments.filter(({ _id }) => postId !== _id);
                newPost = setPost(oldPost, 1);
            } else if (click === 'post-list-deleted') {
                newPostList = oldComments.filter(({ _id }) => postId !== _id);
                newPost = setPost(oldPost, 1);
            }
            return {
                ...state,
                posts: { ...state.posts, [action.meta.stepId]: newPosts },
                post: {
                    ...(newPost || oldPost),
                    claps_count: null,
                    comments_count: null,
                    comments: [],
                },
                secondary_posts: {
                    posts: newPostList || oldComments,
                },
            };
        }
        case error(DELETE_POST): {
            return {
                ...state,
            };
        }
        case success(GET_POSTS): {
            const oldPosts = state.posts[action.meta.stepId];
            const newPosts = oldPosts.concat(action.response.data.posts);
            return {
                ...state,
                notChangePosts: {
                    ...state.posts,
                    [action.meta.stepId]: newPosts,
                },
                posts: { ...state.posts, [action.meta.stepId]: newPosts },
                cursor: action.response.data.cursor,
            };
        }
        case error(GET_POSTS): {
            return {
                ...state,
            };
        }
        case success(UPDATE_STEP_INFO): {
            const type = action.meta.type;
            const isGoogle = action.meta.googleStep;
            const oldPosts = state.posts[action.meta.stepId];
            const updatedPosts = oldPosts?.filter(step => step?.type !== 'STEPS_BOT');
            if (type.some(item => item === 'title') && isGoogle) {
                return {
                    ...state,
                    posts: {
                        ...state.posts,
                        [action.meta.stepId]: updatedPosts,
                    },
                };
            } else {
                return {
                    ...state,
                    posts: state.posts,
                };
            }
        }

        case success(POST_ANNOUNCEMENT): {
            return state;
        }

        case SEEN_ANNOUNCEMENT: {
            const { userId, postId } = action.payload;

            return {
                ...state,
                seenAnnouncement: {
                    ...state.seenAnnouncement,
                    [userId]: (state.seenAnnouncement[userId] || []).concat(postId),
                },
            };
        }

        default:
            return state;
    }
};
export default reducer;
