import { useState, useContext, useEffect } from 'react';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { useErrorHandler } from 'react-error-boundary';
import { calcActionPosition, getLocationTagIdsInSelectionRange, getCaretBoundingClientRect, hasParentId, getSelectionData, removeBreaklines } from './utils';
import { TextSelectionContext } from './context';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { MixpanelTagContext, MixPanelTagTypes } from '../../constants';
import { useEditTags } from './hooks';
import { TagActions, TextArrayItemTypes } from './constants';
import { TagLocationPopover } from './components';
import { mapSelectors } from '../../redux/map';

import styles from './TagLocationInPost.module.scss';
import { PopupContext } from '../../context';

const MAX_LIMIT = 35;
const MIN_LIMIT = 3;

const track = ({ tag, type, postId, mapId, mapName }) => {
    mixpanel.track(mixpanelEvents.TAG_ADDED_TO_POST, {
        [mixpanelProperties.POST_ID]: postId,
        [mixpanelProperties.MAP_ID]: mapId,
        [mixpanelProperties.MAP_NAME]: mapName,
        [mixpanelProperties.TAG]: tag,
        [mixpanelProperties.TAG_TYPE]: MixPanelTagTypes[type],
        [mixpanelProperties.CONTEXT]: MixpanelTagContext.WHILE_POSTING,
    });
};

// TODO: tagging can popup outside of post if marking includes text outside of post
const TagLocationInPost = ({
    textArray = [],
    textId = '',
    postId = null,
    mapId = null,
    children,
    onError = noop,
    onTag = noop,
    onUntag = noop,
    isTaggingDisabled = false,
}) => {
    const [key, setKey] = useState(postId);
    const [body, setBody] = useState(textArray);
    const [tagIds, setTagIds] = useState([]);
    const [tagAction, setTagAction] = useState(null);
    const [tagLocationPopupPosition, setTagLocationPopupPosition] = useState(null);
    const { selectionData, setSelectionData } = useContext(TextSelectionContext);
    const { activePopupId, setActivePopupId } = useContext(PopupContext);
    const editTagMutation = useEditTags();
    const [lastRange, setLastRange] = useState(null);
    const mapName = useSelector(mapSelectors.mapName(mapId));
    const errorBoundaryHandler = useErrorHandler();

    const editPost = ({ textArray = [], onSuccess = noop }) => {
        const params = { textArray, postId };
        editTagMutation.mutate(params, {
            onSuccess: () => {
                setBody(textArray);
                resetComponent();
                setSelectionData({});
                onSuccess();
            },
            onError: error => {
                console.error(error);
                onError(error);
                resetComponent();
                setSelectionData({});
            },
        });
    };

    const resetComponent = () => {
        // essential because we mess with the DOM and we need to reset the component to avoid crashes
        setKey(key + 1);
    };

    const handleError = error => {
        console.error(error);
        onError(error);
        errorBoundaryHandler(error);
    };

    useEffect(() => {
        setBody(textArray);
    }, [textArray]);

    useEffect(() => {
        const { value = '', selectionContents, anchorNode, focusNode, isCollapsed, range } = getSelectionData();
        const currentElement = document.getElementById(textId);
        const isSelectionInElement = hasParentId(anchorNode, textId);
        const innerText = currentElement?.innerText || currentElement?.textContent || '';
        const isPassedLimit = value.length >= MAX_LIMIT || value.length < MIN_LIMIT;
        const isSelectionBiggerThanText = removeBreaklines(value).length > removeBreaklines(innerText).length;
        const tags = getLocationTagIdsInSelectionRange(range, textId);
        const selectionBoundingRect = getCaretBoundingClientRect(anchorNode, focusNode, range);
        const newPosition = selectionBoundingRect && calcActionPosition(selectionBoundingRect);
        const userTags = Array.from(selectionContents?.querySelectorAll('[data-type]') || []).filter(node => node.getAttribute('data-type') === 'user_tag');
        const isUserTag = userTags.length > 0;
        const hasOtherTags = tags.length > 0 && tags.filter(tag => ![TextArrayItemTypes.PLACE_TAG, TextArrayItemTypes.STEP_TAG].includes(tag.type)).length > 0;

        if (!value || !isSelectionInElement || hasOtherTags || isTaggingDisabled || isPassedLimit || isSelectionBiggerThanText || isCollapsed || isUserTag) {
            return;
        }

        setTagLocationPopupPosition(newPosition);
        setTagIds(tags.map(tag => tag.id));
        setLastRange(range);

        switch (tags.length) {
            case 0:
                setTagAction(TagActions.TAG);
                setActivePopupId(textId);
                break;

            case 1:
                setTagAction(TagActions.UNTAG);
                setActivePopupId(textId);
                break;

            default:
                setTagAction(null);
                setActivePopupId(null);
                break;
        }
    }, [selectionData]);

    return (
        <span className={styles.tagLocationInPost} key={key}>
            {children({
                body,
                id: textId,
                isTextSelected: Boolean(selectionData?.value),
            })}
            {tagAction !== null && activePopupId === textId && (
                <TagLocationPopover
                    postId={postId}
                    mapName={mapName}
                    textId={textId}
                    tagIds={tagIds}
                    onError={handleError}
                    action={tagAction}
                    textArray={textArray}
                    mapId={mapId}
                    lastRange={lastRange}
                    isActive
                    position={tagLocationPopupPosition}
                    selectedText={selectionData?.value}
                    onClose={() => setTagAction(null)}
                    onTag={async ({ textArray, location, tagText }) => {
                        setTagAction(null);
                        await editPost({
                            textArray,
                            tagText,
                            location,
                            onSuccess: () => {
                                track({ tag: location.name, type: location.type, postId, mapId, mapName });
                                onTag(location);
                            },
                        });
                    }}
                    onUntag={async ({ textArray }) => {
                        setTagAction(null);
                        await editPost({ textArray, onSuccess: onUntag });
                    }}
                />
            )}
        </span>
    );
};

export default TagLocationInPost;
