import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import styles from './index.module.scss';
import { blobUrl, generateImageUrl } from '../../helpers';
import MagicSliderDots from 'react-magic-slider-dots';
import { modalsActions } from '../../redux/modals';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

const PostSlider = ({
    width,
    height,
    slidesToShow,
    slidesToScroll,
    classNamesPadding,
    classNamesImg,
    classNamesPostImg,
    images,
    newImages,
    removeFile,
    classNameRemove,
    editPost,
    removePostImage,
    classNamesDeleteIcon,
    dots = true,
}) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const dispatch = useDispatch();
    const stopEventPropagation = e => {
        e?.stopPropagation();
        e?.nativeEvent?.stopImmediatePropagation();
    };

    const Image = ({ onClick, src, index, ...props }) => {
        return (
            <div onClick={onClick} key={index} className={classNamesPostImg} {...props}>
                <div className={classNamesPadding} style={{ margin: '0 10px 0 0' }}>
                    <img onClick={onClick} className={classNamesImg} src={src} alt='' />
                    <img
                        onClick={e => {
                            stopEventPropagation(e);
                            removeFile(index);
                        }}
                        className={
                            classNamesDeleteIcon
                                ? styles.ico_delete_img_pending
                                : classNames(styles.ico_delete_img, {
                                      [classNameRemove]: classNameRemove,
                                  })
                        }
                        src='/assets/img/deleteImgIco.svg'
                        alt=''
                    />
                </div>
            </div>
        );
    };

    const toggleImagePreview = ({ imagePreviewShow, imagePreviewArray, imagePreviewIndex }) => {
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: imagePreviewShow,
                imagePreviewArray: imagePreviewArray,
                imagePreviewIndex: imagePreviewIndex,
            })
        );
    };

    const ImgArrowLeft = ({ onClick, currentSlide }) =>
        currentSlide > 0 && (
            <img
                className={classNamesDeleteIcon ? styles.ImgArrowLeftPending : styles.ImgArrowLeft}
                src='../../assets/img/arrow-slider.svg'
                alt='left'
                onClick={onClick}
            />
        );

    const ImgArrowRight = ({ onClick, currentSlide, slideCount }) => {
        return (
            currentSlide < slideCount - (images?.length + (newImages?.length || 0) === 1 ? 1 : slidesToShow) && (
                <img className={styles.ImgArrowRight} src='../../assets/img/arrow-slider.svg' alt='right' onClick={onClick} />
            )
        );
    };

    const SLIDER_SETTINGS = {
        speed: 300,
        infinite: false,
        dots: dots && !editPost && images?.length > 0,
        appendDots: dots => {
            return (
                <MagicSliderDots
                    dots={dots}
                    numDotsToShow={!editPost ? (images?.length > 3 || newImages?.length >= 3 ? 4 : 3) : images?.length + (newImages?.length || 0) >= 3 ? 4 : 3}
                    dotWidth={20}
                />
            );
        },
        slidesToShow: !editPost
            ? images?.length > 0 || newImages?.length <= 1
                ? 1
                : slidesToShow
            : images?.length + (newImages?.length || 0) === 1
            ? 1
            : slidesToShow,
        slidesToScroll: images?.length + (newImages?.length || 0) > 0 ? 1 : slidesToScroll,
        prevArrow: <ImgArrowLeft />,
        nextArrow: <ImgArrowRight />,
        dotsClass: 'slick-dots',
        beforeChange: (_currentIndex, nextIndex) => setCurrentSlide(nextIndex),
    };

    return (
        <div>
            {images.length > 1 && (
                <div className={styles.currentSlide}>
                    {currentSlide + 1}/{images.length}
                </div>
            )}
            <Slider {...SLIDER_SETTINGS}>
                {images?.length > 0 &&
                    images?.map((img, index) => (
                        <div
                            onClick={() =>
                                toggleImagePreview &&
                                toggleImagePreview({
                                    imagePreviewShow: true,
                                    imagePreviewIndex: index,
                                    imagePreviewArray: images,
                                })
                            }
                            className={classNamesPostImg}
                        >
                            <img
                                className={classNamesImg}
                                src={generateImageUrl({
                                    imageId: img?.attachment?.image_id,
                                    width,
                                    height,
                                    reason: 'create_post_image',
                                })}
                                alt={''}
                            />
                            {editPost && (
                                <img
                                    onClick={e => {
                                        stopEventPropagation(e);
                                        removePostImage(index);
                                    }}
                                    className={classNames(styles.ico_delete_img, {
                                        [classNameRemove]: classNameRemove,
                                    })}
                                    src='/assets/img/deleteImgIco.svg'
                                    alt=''
                                />
                            )}
                        </div>
                    ))}
                {newImages?.length > 0 &&
                    newImages.map((value, index) => (
                        <Image
                            onClick={() =>
                                toggleImagePreview &&
                                toggleImagePreview({
                                    imagePreviewShow: true,
                                    imagePreviewIndex: index,
                                    imagePreviewArray: newImages,
                                })
                            }
                            index={index}
                            src={
                                value?.file
                                    ? blobUrl(value.file)
                                    : value?.attachment?.image_id
                                    ? generateImageUrl({
                                          imageId: value?.attachment?.image_id,
                                          width,
                                          height,
                                          reason: 'create_post_image',
                                      })
                                    : value?.attachment?.media_url
                                    ? value?.attachment?.media_url
                                    : generateImageUrl({
                                          imageId: value?.image_id ? value.image_id : value,
                                          width,
                                          height,
                                          reason: 'create_post_image',
                                      })
                            }
                        />
                    ))}
            </Slider>
        </div>
    );
};

export default PostSlider;
