import React, { useMemo, useState, useEffect, useRef } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';

import { EditorState, ContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';

import { TextArray } from '../../models';
import MentionSuggestionEntry from './MentionSuggestionEntry';
import { LOCATION_TAG_SYMBOL, MEMBER_TAG_SYMBOL, START_OF_HEADING_UNICODE } from './constants';
import {
    convertEditorStateToTextArray,
    colorLastMention,
    uncolorMentions,
    insertMentionTrigger,
    getEditorState,
    removeSuggestionsLoader,
    addSuggestionsLoader,
} from './utils';

import editorStyles from './EditorStyles.module.scss';
import mentionsStyles from './MentionTheme.module.scss';
import { useTags } from '../../features/tag-location-in-post';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../redux/user';

// TODO: [,] [double space] [esc] should stop tagging since we now allow whitespace
const mentionPluginSettings = {
    entityMutability: 'IMMUTABLE',
    theme: mentionsStyles,
    mentionTrigger: [MEMBER_TAG_SYMBOL, LOCATION_TAG_SYMBOL],
    supportWhitespace: true,
    popperOptions: {
        strategy: 'fixed',
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [12, 5],
                },
            },
        ],
    },
};

/**
 * @type {import('./TextInput').TextInput}
 *
 * @param {string} id for controlling styling of tags using observer
 * @param {object} tag will be inserted into the editor at the beginning of the inpu
 * @param {array|string} data text array or string that will be converted to editor state for rendering the input with initial text
 * @param {array} mentionTriggers array of triggers, e.g. ['@', '📍'], for controlling the behavior of the plugin and initiating tag search on user button input
 * @param {function} onChange is essential for the plugin to work, it is called when the editor state changes
 * @param {object} rest rest of the props that will be passed to the editor, should be an extension of input/textarea elements
 */
const TextInput = ({
    id,
    tag = {},
    data = [],
    mentionTriggers = [],
    deleteOnEnter = false,
    onChange = noop,
    onKeyDown = noop,
    disabled = false,
    className = '',
    postId,
    isFocus,
    ...rest
}) => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const editorRef = useRef(null);
    const [mentions, setMentions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [editorState, setEditorState] = useState(() => getEditorState(data, tag));
    const [isMentionPopoverOpen, setIsMentionPopoverOpen] = useState(false);
    const { getTags } = useTags();

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin(mentionPluginSettings);
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    useEffect(() => {
        if (!mentionTriggers.length) {
            return;
        }

        const lastTrigger = mentionTriggers.at(-1);
        const newEditorState = insertMentionTrigger(editorState, lastTrigger);
        setEditorState(newEditorState);

        setTimeout(async () => {
            editorRef.current && editorRef.current.focus();
        }, 100);
    }, [mentionTriggers]);

    useEffect(() => {
        if (isFocus) {
            // Ensures data & tags are populated correctly in this component,
            // otherwise cause the focus to be at the start of the input
            // TODO: actual sync of data & tags
            setTimeout(() => {
                editorRef.current?.focus();
            }, 0);
        }
    }, [isFocus]);

    useEffect(() => {
        const closeSuggestionsPopover = () => setIsMentionPopoverOpen(false);
        const editor = document.querySelector('[class*="EditorStyles_editor"]');

        if (editor) {
            editor.addEventListener('blur', closeSuggestionsPopover);
            editor.addEventListener('scroll', closeSuggestionsPopover);
        }

        return () => {
            if (editor) {
                editor.removeEventListener('blur', closeSuggestionsPopover);
                editor.removeEventListener('scroll', closeSuggestionsPopover);
            }
        };
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }

        const observer = new MutationObserver(() => {
            const isLoading = suggestions.find(suggesion => {
                return suggesion.isLoading;
            });
            const popover = document.querySelector('[class*=MentionTheme_mentionSuggestions]');
            // TODO: not working perfectly unpon typing the suggested name
            // TODO: it's probably because isLoading -> if it reaches here in the ms that
            // is loading is false, but suggestion popover is still open, this fails
            if (popover && !isLoading) {
                colorLastMention();
            } else {
                uncolorMentions();
            }
        });

        try {
            observer.observe(document.querySelector(`#${id}`), { childList: true });
        } catch (error) {
            console.error(error);
        }
    }, [editorRef.current]);

    return (
        <div className={classNames(editorStyles.editor, className)} id={id}>
            <Editor
                ref={editorRef}
                editorState={editorState}
                plugins={plugins}
                readOnly={disabled}
                onChange={newEditorState => {
                    const newTextArray = convertEditorStateToTextArray(newEditorState, postId);
                    const value = newEditorState.getCurrentContent().getPlainText(START_OF_HEADING_UNICODE);
                    const currentContent = editorState.getCurrentContent();
                    const newContent = newEditorState.getCurrentContent();

                    setEditorState(newEditorState);

                    if (currentContent === newContent) {
                        // TODO: occurs even when arrays are the same, and still this is
                        // expensive. debounce?
                        return;
                    }

                    onChange(value, newTextArray);
                }}
                handleReturn={(event, editorState) => {
                    const newTextArray = convertEditorStateToTextArray(editorState);
                    const newEditorState = EditorState.moveFocusToEnd(EditorState.push(editorState, ContentState.createFromText('')));
                    const value = editorState.getCurrentContent().getPlainText(START_OF_HEADING_UNICODE);

                    if (isMentionPopoverOpen) {
                        return;
                    }

                    onKeyDown(event, newTextArray, value);

                    if (deleteOnEnter && !event.shiftKey) {
                        setEditorState(newEditorState);
                        return 'handled';
                    }

                    onChange(value, newTextArray);
                    return 'not-handled';
                }}
                {...rest}
            />
            <MentionSuggestions
                open={isMentionPopoverOpen}
                onOpenChange={open => {
                    if (open) {
                        if (suggestions.length) {
                            removeSuggestionsLoader();
                        } else {
                            addSuggestionsLoader();
                        }
                    }
                    setIsMentionPopoverOpen(open);
                }}
                onSearchChange={async ({ value, trigger }) => {
                    setSuggestions([{ isLoading: true }]);
                    const suggestions = await getTags(value, mapId, trigger === LOCATION_TAG_SYMBOL ? 'LOCATIONS' : 'USERS');
                    setSuggestions(suggestions);
                }}
                suggestions={suggestions}
                entryComponent={props => <MentionSuggestionEntry {...props} />}
                onAddMention={mention => setMentions([...mentions, new TextArray(mention)])}
            />
        </div>
    );
};

export default TextInput;
