const pickPosts = (postsObject, postIds) => postIds.map(id => postsObject[id]);
export const postById = postId => state => {
    if (!postId || !state.posts[postId]) {
        return {};
    }

    return state.posts[postId];
};
export const postSecondaries = postId => state => state.posts[postId].secondary_posts || {};
export const getComments = postId => state => state.posts[postId].comments || [];
export const postParents = postId => state => state.posts[postId].parent_posts || {};
export const getClaps = postId => state => state.posts[postId].claps;
export const postsByIds = postIds => state => pickPosts(state.posts, postIds);
export const getPostsCount = state => Object.keys(state.posts).length;
