export const GET_POST = 'GET_POST';
export const CLAP_POST = 'CLAP_POST';
export const GET_POST_COMMENTS = 'GET_POST_COMMENTS';
export const CLAP_COMMENT = 'CLAP_COMMENT';
export const CLAP_REPLY = 'CLAP_REPLY';
export const PIN_POST = 'PIN_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_REPLY = 'ADD_REPLY';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_REPLY = 'DELETE_REPLY';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const EDIT_REPLY = 'EDIT_REPLY';
export const GET_CLAPS = 'GET_CLAPS';
export const DELETE_POST = 'DELETE_POST';
export const EDIT_POST = 'EDIT_POST';
export const OPEN_MORE_REPLIES = 'OPEN_MORE_REPLIES';
export const CLEAR_COMMENTS_CACHE = 'CLEAR_COMMENTS_CACHE';
export { GET_STEP_BY_ID, GET_FULL_STEP_DATA, GET_POSTS } from '../step2/types';
export { GET_FEED_POSTS } from '../feed/constants';
export const GET_TAGS = 'GET_TAGS';
export const EDIT_TAGS = 'EDIT_TAGS';
export const DELETE_IMAGE = 'DELETE_IMAGE';
