import { EditorState, ContentState, convertFromRaw, convertToRaw, Modifier } from 'draft-js';
import { TextArray } from '../../models';

export const getIndicesOf = (searchStr, str, caseSensitive) => {
    let tempStr = str;
    let tempSearchStr = searchStr;
    const searchStrLen = tempSearchStr.length;
    if (searchStrLen === 0) {
        return [];
    }
    let startIndex = 0;
    let index;
    const indices = [];
    if (!caseSensitive) {
        tempStr = tempStr.toLowerCase();
        tempSearchStr = tempSearchStr.toLowerCase();
    }

    // FIXME: this will match if the search string has multiple occurrences in the same string, which
    // is a bug. Need to find it based on Id instead
    while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
};

export const getEntityRanges = (text, mentionName, mentionKey) => {
    const indices = getIndicesOf(mentionName, text);
    if (indices.length > 0) {
        return indices.map(offset => ({
            key: mentionKey,
            length: mentionName.length,
            offset,
        }));
    }

    return null;
};

export const createMentionEntities = (text, tags = []) => {
    const rawContent = convertToRaw(ContentState.createFromText(text));
    const rawState = tags.map(tag => ({
        type: 'mention',
        mutability: 'IMMUTABLE',
        data: {
            mention: {
                id: tag.id,
                name: tag.text,
                text: tag.text,
                link: tag.link,
                text_type: tag.text_type,
            },
        },
    }));

    rawContent.entityMap = [...rawState];
    rawContent.blocks = rawContent.blocks.map(block => {
        const ranges = [];
        tags.forEach((tag, index) => {
            const entityRanges = getEntityRanges(block.text, tag.text, index);
            if (entityRanges) {
                ranges.push(...entityRanges);
            }
        });

        return { ...block, entityRanges: ranges };
    });

    return convertFromRaw(rawContent);
};

export const getEditorState = (textArray = [], tag = {}) => {
    if (typeof textArray === 'string') {
        textArray = [{ text: textArray, type: 'text' }];
    }

    let text = textArray.map(item => item.text).join('');
    const tags = textArray.filter(item => ['user_tag', 'step_tag', 'place_tag'].includes(item.text_type));
    if (Object.keys(tag).length > 0) {
        // in case of reply, we need to add the tag the user is replying to
        text = `${tag.text} ${text}`;
        tags.unshift(new TextArray({ ...tag, text_type: tag.text_type }));
    }

    const content = createMentionEntities(text, tags);
    return EditorState.createWithContent(content);
};

export const convertEditorStateToTextArray = (editorState, postId) => {
    const textArray = [];
    const content = editorState.getCurrentContent();
    const rawContent = convertToRaw(content);

    rawContent.blocks.forEach((block, index) => {
        const hasNextBlock = Boolean(rawContent.blocks[index + 1]);
        if (!block.entityRanges.length) {
            block.text && textArray.push(new TextArray({ text: block.text }));
        }

        block.entityRanges.forEach((entityRange, index) => {
            const entity = rawContent.entityMap[entityRange.key]?.data?.mention;

            if (index === 0 && entityRange.offset > 0) {
                textArray.push(new TextArray({ text: block.text.substring(0, entityRange.offset), type: 'text' }));
            }

            const text = block.text.substring(
                entityRange.offset + entityRange.length,
                block.entityRanges[index + 1] ? block.entityRanges[index + 1].offset : block.text.length
            );

            entity && textArray.push(new TextArray(entity, postId));
            text && textArray.push(new TextArray({ text }));
        });

        hasNextBlock && textArray.push(new TextArray({ text: '\r\n' }));
    });

    return textArray;
};

export const colorLastMention = () => {
    const allDataSpans = [...document.querySelectorAll('span[data-text="true"]')];
    allDataSpans.forEach((span, index) => {
        const isLastSpan = index === allDataSpans.length - 1;
        const isMatchingMention = span.textContent.match(/(@|📍)(\w*)/);

        if (!isLastSpan) {
            return;
        }

        if (isMatchingMention) {
            span.classList.add('mention-highlight');
        }
    });
};

export const uncolorMentions = () => {
    const allDataSpans = [...document.querySelectorAll('span[data-text="true"]')];
    allDataSpans.forEach(span => {
        span.classList.remove('mention-highlight');
    });
};

export const addSuggestionsLoader = () => {
    setTimeout(() => {
        try {
            document.querySelector('[class*=MentionTheme_mentionSuggestions]').classList.remove('w-360');
        } catch (error) {
            console.log(error);
        }
    }, 10);
};

export const removeSuggestionsLoader = () => {
    setTimeout(() => {
        try {
            document.querySelector('[class*=MentionTheme_mentionSuggestions]').classList.add('w-360');
        } catch (error) {
            console.log(error);
        }
    }, 10);
};

export const insertMentionTrigger = (editorState, trigger) => {
    try {
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const textWithEntity = Modifier.insertText(currentContent, selection, trigger, null);
        return EditorState.push(editorState, textWithEntity, 'insert-characters');
    } catch (error) {
        console.log(error);
        return editorState;
    }
};
