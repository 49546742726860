import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import mixpanel, { mixpanelProperties, mixpanelEvents } from '../../../../helpers/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import { mapActions } from '../../../../redux/map';
import Like from '../../../common/Like';
import { FormattedMessage } from "react-intl";

const Engagements = ({ isLiked, stepId, stepTitle, likesCount, postsCount, viewsCount, className }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const [liked, setLiked] = useState(isLiked);
    
    const renderEngagementCounter = (count, engagementTitle) => {
        // TODO: Format numbers with loadash
        if (!count) return;
        return (
            <span className={styles.engagement}>
                <span className={styles.boldCount}>{count}</span> {engagementTitle}
            </span>
        );
    };
    
    const onClickLike = () => {
        if (!isLiked) {
            mixpanel.track(mixpanelEvents.STEP_LIKE, {
                [mixpanelProperties.STEP_TITLE]: stepTitle,
                [mixpanelProperties.STEP_ID]: stepId,
            });
        }
        setLiked(isLiked => !isLiked);
        dispatch(mapActions.likeStep(mapId, stepId, liked ? 0 : 1, likesCount || 0));
    };
    return (
        <div className={classNames(styles.block, className)}>
            <Like className={styles.like} isLiked={isLiked} onClickLike={onClickLike} likesCount={likesCount}/>
            {renderEngagementCounter(postsCount, postsCount > 1
                ? <FormattedMessage id="stepProfile.posts"/>
                : <FormattedMessage id="stepProfile.post"/>)}
            {renderEngagementCounter(viewsCount, viewsCount > 1
                ? <FormattedMessage id="stepProfile.views"/>
                : <FormattedMessage id="stepProfile.view"/>)}
        </div>
    );
};

export default Engagements;
