import * as timeago from 'timeago.js';
import { generateImageUrl } from '../helpers/index';

const USER_IMAGE_WIDTH = 301;
const USER_IMAGE_HEIGHT = 239;
const UserRewardTypes = {
    POST_CLAPPED: 'POST_CLAPPED',
    STEP_DISCOVERED: 'STEP_DISCOVERED',
    POST_CREATED: 'POST_CREATED',
    TAG_ACCEPTED: 'TAG_ACCEPTED',
    STEP_RATED: 'STEP_RATED',
    POST_IMAGE_ADDED: 'POST_IMAGE_ADDED',
    LONG_POST_TEXT_BONUS: 'LONG_POST_TEXT_BONUS',
};

class Member {
    constructor({ id, display_name, email, username, image, created_at, joined_to_map_at, following = [], points_details = [] }, mapId) {
        this.id = id;
        this.email = email;
        this.name = display_name;
        this.username = username;
        this.followedMaps = following;
        this.joinedAtString = timeago.format(created_at || joined_to_map_at);
        this.imageSrc = generateImageUrl({
            imageId: image,
            reason: 'avatar',
            width: USER_IMAGE_WIDTH,
            height: USER_IMAGE_HEIGHT,
        });

        this.stepsCount = 0;
        this.postsCount = 0;
        this.clapsCount = 0;
        this.tagsCount = 0;
        this.ratedStepsCount = 0;
        this.imagesCount = 0;
        this.longPostTextBonus = 0;
        this.pointsDetails = points_details;

        if (points_details.length) {
            points_details.forEach(point => {
                switch (point.type) {
                    case UserRewardTypes.POST_CLAPPED:
                        this.clapsCount = point.count;
                        break;

                    case UserRewardTypes.STEP_DISCOVERED:
                        this.stepsCount = point.count;
                        break;

                    case UserRewardTypes.POST_CREATED:
                        this.postsCount = point.count;
                        break;

                    case UserRewardTypes.TAG_ACCEPTED:
                        this.tagsCount = point.count;
                        break;

                    case UserRewardTypes.STEP_RATED:
                        this.ratedStepsCount = point.count;
                        break;

                    case UserRewardTypes.POST_IMAGE_ADDED:
                        this.imagesCount = point.count;
                        break;

                    case UserRewardTypes.LONG_POST_TEXT_BONUS:
                        this.longPostTextBonus = point.count;
                        break;

                    default:
                        break;
                }
            });
        }
    }
}

export default Member;
