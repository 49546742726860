import React from 'react';
import styles from './index.module.scss';

const TagsSelected = ({ item, idx, handleSelectTag }) => {
    return (
        <div key={idx} className={styles.tagSelected}>
            <span className={styles.nameTag}>{item?.name}</span>
            {handleSelectTag && <img onClick={() => handleSelectTag(item)} className={styles.deleteImg} src={'/assets/img/icoDeleteGray.png'} alt={'t'} />}
        </div>
    );
};

export default TagsSelected;
