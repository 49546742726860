import React from 'react';
import BlueButton from '../common/buttons/BlueCircleButton';
import copiesPrefix from '../../copies.json';

import styles from './index.module.scss';

const copies = copiesPrefix.filters;

const EmptyTags = ({ onClick }) => {
    return (
        <div className={styles.block}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <span dangerouslySetInnerHTML={{ __html: copies.description }} />
                </div>
                <div>
                    <BlueButton onClick={onClick} text={copies.add_tags} className={styles.addBtn} />
                </div>
                <div>
                    <img className={styles.emptyImg} src='/assets/img/emptyTags.png' alt='' />
                </div>
            </div>
        </div>
    );
};

export default EmptyTags;
