import React from 'react';

const IconSortBy = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='m8.75 15-2.5 2.5-2.5-2.5M6.25 2.5v15M11.25 5l2.5-2.5 2.5 2.5M13.75 17.5v-15'
            stroke='#3A3C3F'
            stroke-width='1.3'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default IconSortBy;
