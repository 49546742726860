import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, useLocation } from 'react-router-dom';
import MapNameAndDescription from './components/MapNameAndDescription';
import WelcomeMessage from './components/WelcomeMessage';
import MapImage from './components/MapImage';
import MapPermissions from './components/MapPermissions';
import MapKeywords from './components/MapKeywords';
import { useDispatch, useSelector } from 'react-redux';
import { mapSelectors } from '../../redux/map';
import classNames from 'classnames';
import mixpanel, { mixpanelEvents } from '../../helpers/mixpanel';
import SubMenu from './SubMenu';
import Rating from './components/Rating/index';
import copiesPrefix from '../../copies.json';
import { userActionActions, userActionSelectors } from '../../redux/userAction';
import styles from './index.module.scss';
import { SettingsTabs as Tabs } from './constants';
import { Members } from '../Members';

const copies = copiesPrefix.mapSettings;

const Setting = ({ selectedTab, map, setClose, setShow, show, closing }) => {
    switch (selectedTab) {
        case Tabs.MEMBERS:
            return <Members />;

        case Tabs.RATINGS:
            return <Rating />;

        default:
        case Tabs.GENERAL:
            return (
                <>
                    <MapNameAndDescription map={map} />
                    <MapImage setClose={setClose} onClick={setShow} map={map} />
                    <WelcomeMessage />
                    <MapPermissions setClose={setClose} onClick={setShow} map={map} />
                    <MapKeywords setClose={setClose} onClick={setShow} keywords={map?.keywords} mapId={map?._id} />
                    {show && (
                        <div
                            className={classNames(styles.hiddenBlock, {
                                [styles.open]: closing,
                            })}
                        />
                    )}
                </>
            );
    }
};

const MapSettings = ({ tabName }) => {
    const { state: initialSettingsParams } = useLocation();
    const { tabName: tabNameParam } = useParams();
    const { selectedMap } = useOutletContext();
    const { _id: mapId } = selectedMap || {};
    const dispatch = useDispatch();
    const map = useSelector(mapSelectors.map(mapId));
    const [show, setShow] = useState(false);
    const [closing, setClose] = useState(false);
    const goto = useSelector(userActionSelectors.goto);
    const [selectedTab, setSelectedTab] = useState(tabName || Tabs.GENERAL);

    useEffect(() => {
        setSelectedTab(tabNameParam || tabName || Tabs.GENERAL);
    }, [tabNameParam, tabName]);

    useEffect(() => {
        if (initialSettingsParams) dispatch(userActionActions.goto(initialSettingsParams.section));
        mixpanel.track(mixpanelEvents.MAP_SETTINGS_SCREEN_SHOW);
    }, []);

    useEffect(() => {
        if (!goto) {
            return;
        }

        dispatch(userActionActions.goto(null));
    }, [goto]);

    return (
        <div className={styles.container}>
            <p className={styles.title}>{copies.title}</p>
            <SubMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <div className={classNames(styles.block)}>
                <Setting selectedTab={selectedTab} map={map} setClose={setClose} setShow={setShow} show={show} closing={closing} />
            </div>
        </div>
    );
};

export default MapSettings;
