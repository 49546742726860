import Qs from 'qs';
import queryString from 'query-string';
import {
    LIKE_STEP,
    GET_STEP,
    ARCHIVE_STEP,
    ADD_TAG_TO_STEP,
    DELETE_TAG_FROM_STEP,
    ADD_NEW_TAG_TO_STEP,
    EDIT_STEP_RATING,
    CREATE_STEP,
    CLEAR_SEARCHED_GOOGLE_PLACES,
    GET_PLACE_DATA,
    GET_SEARCH_RESULT_CANCEL,
    GET_SEARCH_RESULT_MAP,
    FETCH_STEPS_BY_BOUNDS,
    CHANGE_PREVIEW_TAGS,
    SORTED_STEPS,
    RESTORE_STEP,
    CHANGE_STATUS_STEPS,
    GET_PENDING_STEPS,
    DELETE_PENDING_STEPS,
    POST_PENDING_STEPS,
    SET_CHANGE_LOCATION,
    SET_CHANGE_LOCATION_ID,
    SEARCH_STEPS,
} from './types';

export const likeStep = (mapId, stepId, action) => ({
    type: LIKE_STEP,
    request: {
        method: 'POST',
        url: `/steps3/steps/${stepId}/like`,
        data: {
            action,
        },
    },
    meta: {
        mapId,
        stepId,
        thunk: true,
    },
});

export const getFullSteps = ({ mapId, locationId }) => ({
    type: 'GET_FULL_STEP',
    request: {
        method: 'GET',
        url: `/steps3/fullsteps`,
        params: {
            map_id: mapId,
            locationId: locationId,
        },
    },
    meta: {
        thunk: true,
    },
});

export const getStep = stepId => ({
    type: GET_STEP,
    request: {
        method: 'GET',
        url: `/steps3/steps/${stepId}`,
    },
    meta: {
        thunk: true,
    },
});

export const getMapSteps = ({ mapId }) => ({
    type: 'GET_MAP_STEPS',
    request: {
        method: 'GET',
        url: `/map/${mapId}/steps`,
    },
    meta: {
        thunk: true,
    },
});

export const archiveStep = ({ mapId, stepsId, routeName, excludeStepsIds, deleteAll, firstCursorSteps }) => ({
    type: ARCHIVE_STEP,
    request: {
        method: 'DELETE',
        url: '/removemultiplestepsfrommap',
        data: {
            exclude_steps_ids: excludeStepsIds,
            delete_all: deleteAll,
            first_cursor: firstCursorSteps,
            map_id: mapId,
            step_ids: stepsId,
            text: 'Removed by map-manager',
        },
    },
    meta: {
        mapId,
        stepId: stepsId,
        route: routeName,
        thunk: true,
        deleteAll,
        excludeStepsIds,
    },
});

export const restoreStep = (stepId, mapId) => ({
    type: RESTORE_STEP,
    request: {
        method: 'PUT',
        url: `/restorestep/${stepId}`,
    },
    meta: {
        stepId: stepId,
        mapId,
        thunk: true,
    },
});

export const addTagToStep = (stepId, mapId, tagId, status) => ({
    type: ADD_TAG_TO_STEP,
    request: {
        method: 'POST',
        url: `/step/${stepId}/tag/${mapId}`,
        data: {
            status,
            tags: tagId,
            is_new_schema: true,
        },
    },
    meta: {
        stepId,
        tags: tagId,
        thunk: true,
    },
});

export const deleteTagFromStep = (stepId, mapId, tagId, status) => ({
    type: DELETE_TAG_FROM_STEP,
    request: {
        method: 'DELETE',
        url: `/step/${stepId}/tag/${mapId}`,
        data: {
            status,
            tags: tagId,
            is_new_schema: true,
        },
    },
    meta: {
        stepId,
        tags: tagId,
        thunk: true,
    },
});

export const addNewTagToStep = (mapId, data, categoryName) => ({
    type: ADD_NEW_TAG_TO_STEP,
    request: {
        method: 'POST',
        url: `/map/${mapId}/tags`,
        data,
    },
    meta: {
        categoryName: categoryName,
        thunk: true,
    },
});

export const editStepRating = (stepId, data) => ({
    type: EDIT_STEP_RATING,
    request: {
        method: 'POST',
        url: `/steps3/steps/${stepId}/rate`,
        data,
    },
    meta: {
        thunk: true,
        stepId,
        newRatingComponent: data,
    },
});

export const createStep = data => ({
    type: CREATE_STEP,
    request: {
        method: 'POST',
        url: '/step',
        data,
    },
    meta: {
        thunk: true,
    },
});

export const getPlaceData = placeId => ({
    type: GET_PLACE_DATA,
    request: {
        method: 'GET',
        url: '/googleplacedataforstepcreation',
        params: {
            place_id: placeId,
            get_hpaw: 'all',
        },
    },
    meta: {
        thunk: true,
    },
});

export const getSearchResultMap = (mapId, term, distinctId) => ({
    type: GET_SEARCH_RESULT_MAP,
    request: {
        method: 'GET',
        url: '/iframe/search',
        params: { map_id: mapId, term, distinct_id: distinctId },
    },
    meta: {
        thunk: true,
        abortOn: GET_SEARCH_RESULT_CANCEL,
    },
});

export const clearSearchedGooglePlaces = () => ({
    type: CLEAR_SEARCHED_GOOGLE_PLACES,
});

export const fetchStepsByBounds = (query, cursor = null) => ({
    type: FETCH_STEPS_BY_BOUNDS,
    request: {
        method: 'GET',
        url: `/steps3/steps?${query}`,
    },
    meta: {
        cursor,
        thunk: true,
    },
});

export const sortStepsBy = (query, cursor = null) => ({
    type: SORTED_STEPS,
    request: {
        method: 'GET',
        url: `/steps3/steps?${query}`,
    },
    meta: {
        cursor,
        thunk: true,
    },
});

export const changePreviewTags = (data, stepId) => {
    return {
        type: CHANGE_PREVIEW_TAGS,
        payload: {
            stepId,
            data,
        },
    };
};

export const setChangeLocation = ({ data }) => {
    return {
        type: SET_CHANGE_LOCATION,
        payload: {
            data,
        },
    };
};

export const setChangeLocationId = (id, show) => {
    return {
        type: SET_CHANGE_LOCATION_ID,
        payload: { id, show },
    };
};

export const getPendingSteps = ({ mapId, cursor, withLocation }) => {
    const query = queryString.stringify({
        map_id: mapId,
        cursor,
        only_with_location: withLocation,
    });
    return {
        type: GET_PENDING_STEPS,
        request: {
            method: 'GET',
            url: `/steps3/pendingposts?${query}`,
        },
        meta: {
            thunk: true,
        },
    };
};

export const deletePendingSteps = pendingId => {
    return {
        type: DELETE_PENDING_STEPS,
        request: {
            method: 'DELETE',
            url: `/steps3/pendingposts/${pendingId}`,
        },
        meta: {
            thunk: true,
        },
    };
};

export const postPendingSteps = (pendingId, { lat, lon, text, tags, title, attachments }) => {
    return {
        type: POST_PENDING_STEPS,
        request: {
            method: 'POST',
            url: `/steps3/pendingposts/${pendingId}/approve`,
            data: {
                location_parameters: {
                    lat: lat,
                    lon: lon,
                    title: title,
                },
                text_array: [
                    {
                        text: text.text,
                        text_type: 'text',
                    },
                ],
                tags_ids: tags,
                attachments: attachments,
                client_type: 'map_manager',
            },
        },
        meta: {
            thunk: true,
        },
    };
};

export const changeStatusSteps = data => {
    return {
        type: CHANGE_STATUS_STEPS,
        payload: {
            data,
        },
    };
};

export const searchStepsByValue = ({ map_id, data_type, term, lat, lon, include_archived, max_radius }) => {
    const query = queryString.stringify({
        map_id,
        data_type,
        term,
        lat,
        lon,
        include_archived,
        max_radius,
    });

    return {
        type: SEARCH_STEPS,
        request: {
            method: 'GET',
            url: `/steps3/search?${query}`,
        },
        meta: { thunk: true },
    };
};

export const searchStepsStepCreation = ({ map_id, search_term, lat, lon }) => {
    const query = queryString.stringify({
        map_id,
        search_term,
        lat,
        lon,
    });

    return {
        type: SEARCH_STEPS,
        request: {
            method: 'GET',
            url: `/steps3/searchforpostcreation?${query}`,
        },
        meta: { thunk: true },
    };
};
