import React, { useEffect, useState } from 'react';
import Toast from './Toast';

const TOAST_TIMEOUT = 6000;

const ToastContext = React.createContext({});

const ToastContextProvider = ({ children }) => {
  const [toast, setToast] = useState({});
  const [isToastView, setIsToastView] = useState(false);
  
  useEffect(() => {
    let timeoutId;
    if (toast.text || toast.id) {
      setIsToastView(true);
      
      timeoutId = setTimeout(() => {
        setIsToastView(false);
        toast.onFinish && toast.onFinish();
      }, toast.timeout || TOAST_TIMEOUT);
    }
    
    return () => {
      // clear timeout when unmounting in case multiple toast are being triggered
      // or for any other edge cases
      clearTimeout(timeoutId);
    };
  }, [toast]);
  
  return (
    <ToastContext.Provider value={{ setToast }}>
      <Toast
        id={toast.id}
        type={toast.type}
        text={toast.text}
        values={toast.values}
        cta={toast.cta}
        color={toast.color}
        secondaryCta={toast.secondaryCta}
        isOpen={isToastView}
        className={toast.className}
        withIcon={toast.withIcon}
      />
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastContextProvider };
