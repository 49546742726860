import React from 'react';

const IconAddress = ({ stroke }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='26' viewBox='0 0 21 26'>
        <g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
            <g stroke={stroke} strokeWidth='2'>
                <g transform='translate(-339 -223) translate(340 224)'>
                    <path d='M19 9.5c0 7.389-9.5 13.722-9.5 13.722S0 16.89 0 9.5C0 4.253 4.253 0 9.5 0S19 4.253 19 9.5h0z' />
                    <circle cx='9.5' cy='9.5' r='3.167' />
                </g>
            </g>
        </g>
    </svg>
);

export default IconAddress;
