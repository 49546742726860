import React from 'react';
import { noop } from 'lodash';
import { CreateNewPostModal } from '../../../Feed/FeedPost/CreateNewPostModal';
import { PostTypes } from '../../../../constants';

const ModalsEditPost = ({ onEditSuccess = noop, onCancel = noop, addImage = false, content, postId, stepId, imagesId }) => {
    return (
        <CreateNewPostModal
            isOpen
            onClose={onCancel}
            onSuccess={onEditSuccess}
            postId={postId}
            stepId={stepId}
            imageIds={imagesId}
            postType={PostTypes.EDIT_POST}
            body={content}
            openWithImageUpload={addImage}
        />
    );
};

export default ModalsEditPost;
