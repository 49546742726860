import React from 'react';

const IconDownload = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
        <path
            stroke='#3A3C3F'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.5'
            d='M19 13.333v3.111c0 .413-.164.809-.456 1.1-.291.292-.687.456-1.1.456H6.556c-.413 0-.809-.164-1.1-.456-.292-.291-.456-.687-.456-1.1v-3.11'
        />
        <path stroke='#3A3C3F' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M8.111 9.444l3.89 3.89 3.888-3.89M12 13.333V4' />
    </svg>
);

export default IconDownload;
