import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import noop from 'lodash';
import ExifReader from 'exifreader';
import { useOutletContext } from 'react-router-dom';
import { mapActions, mapSelectors } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import { useDebounce } from '../../hooks';
import { ToastContext, ToastTypes } from '../../context';
import { ArrowLeft, ImageSquare, MapPin } from 'phosphor-react';
import copiesPrefix from '../../copies.json';
import Option from './components/Option';
import SearchInput from '../common/inputs/SearchInput2';
import SearchResults, { SearchTypes } from '../SearchSteps2';
import FileInput, { FileTypes } from '../common/inputs/FileInput';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { getAddressByCoordinates, getPlaceCoordinates } from '../../helpers';
import DropPinFlow from './components/DropPinView/DropPinFlow';
import { stepActions } from '../../redux/step2';

const copies = copiesPrefix.addStep;

const AddStep = ({
    onClickPrev = noop,
    onClickDropPin = noop,
    onClickPlace = noop,
    onClickCreatePlace = noop,
    onClickArea = noop,
    onClickStep = noop,
    onLocationFromPhotoSuccess = noop,
    onAddStepMiniFlowFinished = noop,
    onLocationFromPhotoClicked = noop,
    defaultSearchValue = '',
}) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
    const mapName = useSelector(mapSelectors.mapName(mapId));
    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const [searchLoading, setSearchLoading] = useState(false);
    const [importDropDownOpen, setImportDropDownOpen] = useState(false);
    const searchDebounce = useDebounce(searchValue, 300);
    const { setToast } = useContext(ToastContext);
    const searchInputRef = useRef(null);
    const { setIsMapClean } = useOutletContext();

    useEffect(() => {
        mixpanel.track(mixpanelEvents.STEP_CREATION_SHOWED, {
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: mapName,
        });

        searchInputRef.current.focus();
        dispatch(mapActions.setGhostStep(mapId, null));
        setTimeout(() => {
            //TODO: timeout should be remove after stepManager container will be refactored
            setIsMapClean(true);
        }, 50);
    }, []);

    const onInputChange = e => {
        const inputValue = e.target.value;
        setSearchValue(inputValue);
    };

    const onClickInputDiscard = () => {
        setSearchValue('');
    };

    const onFileAdd = async file => {
        try {
            const imageData = await ExifReader.load(file, { expanded: true });
            const gps = imageData.gps || {};
            const { Latitude: lat, Longitude: lon } = gps;
            if (lat && lon) {
                mixpanel.track(mixpanelEvents.MM_STEP_CREATION_OPEN_COMPUTER_FILES_PHOTO_ADDED, {
                    [mixpanelProperties.FLOW]: 'location_from_photo',
                    [mixpanelProperties.HAS_COORIDNATES]: true,
                    [mixpanelProperties.COORDINATES]: [lat, lon],
                });
                const address = await getAddressByCoordinates({ lat, lon });
                dispatch(
                    mapActions.setGhostStep(mapId, {
                        position: { lat, lon },
                        address,
                        suggestToAdd: true,
                        isStickToMap: true,
                        customPopup: () => (
                            <DropPinFlow
                                address={address}
                                mapId={mapId}
                                onClick={() => {}}
                                onCancel={() => {
                                    mixpanel.track(mixpanelEvents.MM_STEP_CREATION_DROP_PIN_PLACE_SELECTED_CANCEL, {
                                        [mixpanelProperties.MAP_ID]: mapId,
                                        [mixpanelProperties.MAP_NAME]: mapName,
                                        [mixpanelProperties.FLOW]: 'drop_pin',
                                    });
                                    mapActions.setGhostStep(null);
                                }}
                                fromFlow='locationFromPhoto'
                                file={file}
                                position={{ lat, lon }}
                                onDone={params => onAddStepMiniFlowFinished(params, file ? 'location_from_photo' : 'drop_pin')}
                            />
                        ),
                    })
                );
                dispatch(
                    mapActions.setMapCenter(mapId, {
                        ...mapCenter,
                        position: { lat, lon },
                    })
                );
                onLocationFromPhotoSuccess({ address, lat, lon });
            } else {
                mixpanel.track(mixpanelEvents.MM_STEP_CREATION_OPEN_COMPUTER_FILES_PHOTO_ADDED, {
                    [mixpanelProperties.FLOW]: 'location_from_photo',
                    [mixpanelProperties.HAS_COORIDNATES]: false,
                });
                setToast({ text: copies.no_location_found, type: ToastTypes.ERROR });
            }
        } catch {
            setToast({ text: copies.no_location_found, type: ToastTypes.ERROR });
        }
    };

    return (
        <div className={styles.block}>
            <div className={styles.header}>
                <ArrowLeft className={styles.backButton} size='20' color='#2171EC' onClick={onClickPrev} />
                <h3 className={styles.title}>{copies.title}</h3>
            </div>
            <SearchInput
                className={styles.searchInput}
                value={searchValue}
                onChange={onInputChange}
                loading={searchLoading}
                onClickDiscard={onClickInputDiscard}
                ref={searchInputRef}
                placeholder={copies.search_input}
            />
            {searchDebounce.trim() && !searchLoading && <span className={styles.didYouMean}>{copies.did_you_mean}</span>}
            <SearchResults
                searchTypes={[SearchTypes.PLACE, SearchTypes.LOCATION, SearchTypes.STEP, SearchTypes.ADDRESS]}
                className={styles.searchResults}
                searchValue={searchValue}
                searchValueDebounce={searchDebounce}
                displayLoading={false}
                onLoadingStart={() => setSearchLoading(true)}
                onLoadingEnd={() => setSearchLoading(false)}
                hideCreatePlaceOnLoading
                showActionButtons
                sortByCategories={false}
                onClickPlace={async place => {
                    const isPlaceExists = place.result_type === 'step';
                    const isGooglePlace = place.place_id;
                    let flow = '';

                    if (isPlaceExists) {
                        flow = 'step_exists';
                    } else if (isGooglePlace) {
                        flow = 'google_location';
                    } else {
                        flow = 'new_location';
                    }

                    mixpanel.track(mixpanelEvents.STEP_CREATION_SEARCH_RESULT_CLICKED, {
                        [mixpanelProperties.MAP_ID]: mapId,
                        [mixpanelProperties.MAP_NAME]: mapName,
                        [mixpanelProperties.FLOW]: flow,
                    });

                    if (isPlaceExists) {
                        onClickStep(place);
                        return;
                    }
                    // Location.coordinates is sorted like [lon, lat] instead of [lat, lon] for some reason.
                    // We should Remove the use of place.lat & place.lon when location.coordinates will be fixed.
                    let position = (place.lat && place.lon ? [place.lat, place.lon] : place.location?.coordinates) || [];
                    let googlePlaceImages;

                    if (place.result_type === 'place') {
                        const { place: placeData } = await dispatch(stepActions.stepCreationPreview({ mapId, placeId: place.place_id }));
                        const { images, lat, lon } = placeData || {};
                        googlePlaceImages = images;
                        if (!position) {
                            position = [lat, lon];
                        }
                    }

                    position = position.filter(Boolean);

                    if (!position.length) {
                        const results = await getPlaceCoordinates(place);
                        position = Object.values(results.location);
                    }

                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: position,
                            zoom: 10,
                        })
                    );
                    const id = place.result_type === 'location' ? place.id : place.place_id;

                    dispatch(
                        mapActions.setGhostStep(mapId, {
                            title: place.title,
                            address: place.geocode_address?.formatted_address || place.address || place.description || place.title || place.secondary_title,
                            position,
                            type: place.result_type,
                            googlePlaceImages,
                            _id: id,
                            isStickToMap: true,
                        })
                    );

                    onClickPlace(place);
                }}
                onClickCreatePlace={onClickCreatePlace}
                onClickArea={onClickArea}
                onClickStep={params => {
                    mixpanel.track(mixpanelEvents.STEP_CREATION_SEARCH_RESULT_CLICKED, {
                        [mixpanelProperties.MAP_ID]: mapId,
                        [mixpanelProperties.MAP_NAME]: mapName,
                        [mixpanelProperties.FLOW]: 'step_exists',
                    });
                    onClickStep(params);
                }}
                isUseSearchForStepCreation
            />
            {!searchDebounce.trim() && (
                <div className={styles.bottom}>
                    <span className={styles.otherOptions}>{copies.other_options}</span>
                    <Option
                        icon={<MapPin className={styles.icon} size={20} color='#2171EC' weight='bold' />}
                        text={copies.drop_pin}
                        onClick={onClickDropPin}
                        className={styles.option}
                    />
                    <FileInput onFileAdd={onFileAdd} allowedTypes={FileTypes.IMAGES} onClick={onLocationFromPhotoClicked}>
                        <Option
                            className={styles.option}
                            icon={<ImageSquare className={styles.icon} size={20} color='#2171EC' weight='bold' />}
                            text={copies.location_from_photo}
                        />
                    </FileInput>
                    {/*<div className={styles.importsDropDownWrapper}>*/}
                    {/*    <Option*/}
                    {/*        icon={<ArrowSquareIn className={styles.icon} size={20} color='#2171EC' weight='bold'/>}*/}
                    {/*        text={copies.import}*/}
                    {/*        className={classNames(styles.import, styles.option)}*/}
                    {/*        isActive={importDropDownOpen}*/}
                    {/*        onClick={() => {*/}
                    {/*            mixpanel.track(mixpanelEvents.STEP_CREATION_IMPORT_CLICKED, {*/}
                    {/*                [mixpanelProperties.MAP_ID]: mapId,*/}
                    {/*                [mixpanelProperties.MAP_NAME]: mapName,*/}
                    {/*            });*/}
                    {/*            setImportDropDownOpen(open => !open);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    {importDropDownOpen &&
                            <ImportDropDown className={styles.importDropDown} onClickBack={() => setImportDropDownOpen(false)}/>}*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    );
};

export default AddStep;
