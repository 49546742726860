import React, { useState, useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import tagsTemplateData from '../../data/tagsTemplates';
import Category from './Category';
import { mapActions, mapSelectors } from '../../redux/map';
import { useSelector, useDispatch } from 'react-redux';
import ModalsTagsTemplate from '../common/modals/ModalsTagsTemplate';
import Lottie from 'react-lottie';
import loaderAnimation from '../../lottie/circleLoader.json';
import copiesPrefix from '../../copies.json';

const copies = copiesPrefix.tags.tagTemplates;

const TagsTemplate = ({ onCancel, mapId }) => {
    const dispatch = useDispatch();
    const map = useSelector(mapSelectors.map(mapId));
    const mapCategories = map?.tags_categories;
    const mapTagObject = map?.tag_objects;
    const [selectedMenuCategory, setSelectedMenuCategory] = useState({});
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [pendingPopUp, setPendingPopUp] = useState();
    const [loadingDoneClick, setLoadingDoneClick] = useState(false);
    const guideTaskCompleted = useSelector(mapSelectors.guideTaskCompleted(mapId));
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    useEffect(() => {
        setSelectedMenuCategory(organizeAllCategory);
    }, []);

    // Clear category duplicates - for 'All' categories preview
    const organizeAllCategory = useMemo(() => {
        const organizedCategoriesArray = [];
        tagsTemplateData?.forEach(category => {
            category.subCategories.forEach(subCategory => {
                if (organizedCategoriesArray.findIndex(addedCategory => addedCategory.name === subCategory.name) < 0) {
                    organizedCategoriesArray.push(subCategory);
                }
            });
        });
        return { name: 'All', subCategories: organizedCategoriesArray };
    }, []);

    const handleSelectSubCategory = clickedCategory => {
        const clickedCategoryIndex = selectedSubCategories.findIndex(subCategory => subCategory.name === clickedCategory.name);
        if (clickedCategoryIndex > -1) {
            setSelectedSubCategories(prevSubCategories => {
                prevSubCategories.splice(clickedCategoryIndex, 1);
                return [...prevSubCategories];
            });
        } else {
            // setSelectedSubCategories(prevSubCategories => [...prevSubCategories, clickedCategory])
            checkExistingCategory(clickedCategory);
        }
    };

    const checkExistingCategory = clickedCategory => {
        const existingCategory = mapCategories.find(category => category.name === clickedCategory.name);
        if (existingCategory) {
            setPendingPopUp({
                type: 'category',
                subject: `"${clickedCategory.name}"`,
                textFirst: copies.categoryExists.description,
                clickedCategory: clickedCategory,
                existingCategory: existingCategory,
            });
        } else {
            checkExistingTags(clickedCategory);
        }
    };

    const checkExistingTags = (clickedCategory, existingCategory) => {
        const existingCategoryId = existingCategory?._id;
        const newTagsToAdd = []; // used to create new category with new tags OR add new tags to existing category
        const tagsToMove = []; // used to move tags to existing or new category
        clickedCategory.tags.forEach(tag => {
            let isTagExists = false;
            mapTagObject.forEach(tagObject => {
                if (tagObject.name === tag && !isTagExists) {
                    const newTagObject = {
                        _id: tagObject._id,
                        name: tag,
                        isApproved: true,
                    }; // use isApproved to add skip method to popUp
                    if (existingCategory) {
                        newTagObject.categoryId = existingCategoryId;
                    }
                    tagsToMove.push(newTagObject);
                    isTagExists = true;
                }
            });
            if (!isTagExists) {
                if (existingCategory) {
                    newTagsToAdd.push({
                        name: tag,
                        category_id: existingCategoryId,
                        category: existingCategory.name,
                        category_priority: existingCategory.priority,
                    });
                } else {
                    newTagsToAdd.push(tag);
                }
            }
        });
        const updatedCategoryData = {
            name: clickedCategory.name,
            categoryId: existingCategoryId,
            tags: clickedCategory.tags,
            newTagsToAdd: newTagsToAdd,
            existingTagsToMove: tagsToMove,
        };
        if (tagsToMove.length > 1) {
            setPendingPopUp({
                subject: tagsToMove.length,
                textFirst: copies.tagsExists.title,
                textSecond: copies.tagsExists.description,
                category: updatedCategoryData,
            });
        } else if (tagsToMove.length === 1) {
            setPendingPopUp({
                subject: `"${tagsToMove[0].name}"`,
                textFirst: copies.tagExists.title,
                textSecond: copies.tagExists.description,
                category: updatedCategoryData,
            });
        } else {
            setSelectedSubCategories(prevSubCategories => [...prevSubCategories, updatedCategoryData]);
        }
    };

    const handleClickDone = () => {
        setLoadingDoneClick(true);
        const categoriesHandlePromises = [];
        selectedSubCategories.forEach(category => {
            categoriesHandlePromises.push(handleCategory(category));
        });
        Promise.all(categoriesHandlePromises)
            .then(() => {
                !guideTaskCompleted.MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS &&
                    dispatch(mapActions.setGuideTaskComplete(mapId, 'MAP_MANAGER_JOURNEY_TASKS_ADDED_TAGS'));
                onCancel();
            })
            .catch(() => {
                onCancel();
            });
    };

    const handleCategory = async category => {
        const promises = [];
        if (category.categoryId) {
            if (category.newTagsToAdd.length > 0) promises.push(addNewTagsToExistingCategory(category.newTagsToAdd));
            category.existingTagsToMove.forEach(existingTag => promises.push(moveExistingTag(existingTag._id, category.categoryId)));
        } else {
            const createdCategoryResponse = await createNewCategoryWithTags(category.name, category.newTagsToAdd);
            const createdCategoryId = createdCategoryResponse.data.categories[0]._id;
            category.existingTagsToMove.forEach(existingTag => existingTag.isApproved && promises.push(moveExistingTag(existingTag._id, createdCategoryId)));
        }
        return Promise.all(promises);
    };

    const createNewCategoryWithTags = (categoryName, tagNames) => {
        const categoriesAndTagsData = {
            name: categoryName,
            tag_names: tagNames,
        };
        return dispatch(mapActions.addNewCategoryWithTags(mapId, [categoriesAndTagsData]));
    };

    const addNewTagsToExistingCategory = (tagObjectsArray = []) => {
        return dispatch(mapActions.addNewTagToMap(mapId, { tags: tagObjectsArray }));
    };

    const moveExistingTag = (existingTagId, categoryId) => {
        return dispatch(mapActions.moveToCategory(mapId, categoryId, existingTagId));
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <ClickAwayListener onClickAway={!pendingPopUp && onCancel}>
                    <div className={styles.panel}>
                        <div className={styles.topButtons} onClick={() => {}} disabled={false}>
                            <div className={styles.closeButton} onClick={onCancel}>
                                <img src='/assets/img/icon_cancel_blue.svg' />
                            </div>
                            <button
                                className={classNames({
                                    [styles.enabled]: selectedSubCategories.length > 0,
                                    [styles.disabled]: selectedSubCategories.length === 0,
                                })}
                                onClick={() => selectedSubCategories.length > 0 && handleClickDone()}
                            >
                                {loadingDoneClick ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={40} height={40} /> : copies.done}
                            </button>
                        </div>
                        <div className={styles.header}>
                            <h2>{copies.title}</h2>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: copies.description,
                                }}
                            />
                        </div>
                        <div className={styles.content}>
                            <div className={styles.sideMenu}>
                                <div
                                    className={classNames(styles.category, {
                                        [styles.selected]: selectedMenuCategory?.name === 'All',
                                    })}
                                    onClick={() => setSelectedMenuCategory(organizeAllCategory)}
                                >
                                    {copies.categories.all}
                                </div>
                                {tagsTemplateData.map(category => (
                                    <div
                                        className={classNames(styles.category, {
                                            [styles.selected]: category.name === selectedMenuCategory?.name,
                                        })}
                                        onClick={() => setSelectedMenuCategory(category)}
                                    >
                                        {category.name}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.categories}>
                                {selectedMenuCategory.subCategories?.map(subCategory => (
                                    <Category
                                        category={subCategory}
                                        isSelected={selectedSubCategories.findIndex(selectedSubCategory => selectedSubCategory.name === subCategory.name) > -1}
                                        handleSelectCategory={category => handleSelectSubCategory(category)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
            {pendingPopUp && (
                <ModalsTagsTemplate
                    onCancel={() => setPendingPopUp()}
                    onApprove={() => {
                        setPendingPopUp();
                        if (pendingPopUp.type === 'category') {
                            checkExistingTags(pendingPopUp.clickedCategory, pendingPopUp.existingCategory);
                        } else {
                            setSelectedSubCategories(prevSubCategories => [...prevSubCategories, pendingPopUp.category]);
                        }
                    }}
                    subject={pendingPopUp.subject}
                    textFirst={pendingPopUp.textFirst}
                    textSecond={pendingPopUp.textSecond}
                    approveButtonText={pendingPopUp.type === 'category' ? copies.categoryExists.replace : copies.replace}
                    cancelButtonText={pendingPopUp.type === 'category' ? copies.categoryExists.cancel : copies.cancel}
                />
            )}
        </div>
    );
};

export default TagsTemplate;
