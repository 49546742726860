import { PostTypes } from '../constants';
import { generateImageUrl } from '../helpers';
import { ImageReasons, Types } from './constants';
import { TextArray } from './TextArray';

class Comment {
    constructor(
        {
            creator_preview = {},
            secondaries = [],
            created_at = null,
            text_array = [],
            map_id,
            step_id,
            _id,
            parent_post_id,
            is_clapped_by_user = false,
            claps_count = 0,
            screens_chain = [],
            userName = '',
            userImageId = null,
            createdAt = null,
            attachments,
            secondaries_cursor,
            comments_count,
            isTemp = false,
            type,
        },
        height = 260,
        width = 350
    ) {
        const images = attachments.filter(({ type }) => type === Types.IMAGE || type === Types.GOOGLE_IMAGE);

        this.replies = secondaries.length > 0 ? secondaries.map(reply => new Comment(reply)) : secondaries;
        this.repliesOpened = 1;
        this.repliesCursor = secondaries_cursor;
        this.totalReplies = comments_count;
        this.userType = creator_preview?.type;
        this.userImageId = creator_preview?.image || userImageId;
        this.userName = creator_preview?.display_name || userName;
        this.creatorId = creator_preview?._id;
        this.createdAt = created_at || createdAt;
        this.mapId = map_id;
        this.stepId = step_id;
        this.id = _id;
        this.isFeedPost = type === PostTypes.FEED;
        this.parentPostId = parent_post_id;
        this.isClapped = is_clapped_by_user;
        this.clapsCount = claps_count;
        this.screensChain = screens_chain;
        this.fullSizeImages = images.map(({ attachment }) => generateImageUrl({ imageId: attachment.image_id, reason: ImageReasons.POST }));
        this.attachments = images.map(image =>
            generateImageUrl({ imageId: image?.attachment?.image_id || image?.image_id, reason: 'map_manager_Comment', height, width })
        );
        this.images = images;
        this.content = text_array.map(({ text }) => text).join('');
        this.textArray = text_array.map(item => new TextArray(item));
        // temp comment before server response
        this.isTemp = isTemp;
    }
}

export default Comment;
