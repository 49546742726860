import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import config from '../../../../../config/index';
import { useDebounce } from '../../../../../hooks';
import 'leaflet/dist/leaflet.css';
import './mapStyle.scss';
import DivIcon from '../../../../../containers/MapContainer/components/DivIcon';
import PixiOverlay from '../../../../../containers/MapContainer/pixiOverlay';

const stepIcon = {
    selectedStep: {
        key: 'selectedStepIcon',
        src: '/assets/img/selected-no-shadows.svg',
    },
};

const convertToPixiMarkers = ({ steps }) => {
    const markers = steps.map(step => ({
        id: step?._id,
        position: step?.position ? [step?.position?.lat, step?.position?.lon || step?.position?.lng] : [step?.lat, step?.lon],
        tooltip: step?.address || step?.description || step?.location_name || step?.instagram_location_name,
        iconId: stepIcon?.selectedStep?.key,
        customIcon: stepIcon?.selectedStep?.src,
    }));
    return markers;
};

const MapLocation = ({ files, infoLocation, infoLocationNew }) => {
    const [markers, setMarkers] = useState([]);
    const [renderTrigger, setRenderTrigger] = useState({ trig: true });
    const renderTriggerDebounce = useDebounce(renderTrigger, 50);
    // const [stepPreviewAnchorState, setStepPreviewAnchorState] = useState('right');

    const initMapComponent = () => {
        setMarkers([]);
    };
    useEffect(() => {
        initMapComponent();
    }, [infoLocationNew, infoLocation?._id]);

    useEffect(() => renderMarkers(), []);
    useEffect(() => {
        setRenderTrigger({ ...renderTrigger });
    }, [infoLocation, infoLocationNew]);

    const renderMarkers = () => {
        setMarkers(
            convertToPixiMarkers({
                steps: infoLocationNew && Object.keys(infoLocationNew).length > 0 ? [infoLocationNew] : [infoLocation],
            })
        );
    };

    return (
        <>
            <MapContainer
                center={
                    infoLocationNew && Object.keys(infoLocationNew).length > 0
                        ? [infoLocationNew?.position?.lat, infoLocationNew?.position?.lon || infoLocationNew?.position?.lng]
                        : [infoLocation.lat, infoLocation.lon]
                }
                zoom={15}
                zoomControl={false}
                preferCanvas
                doubleClickZoom={false}
                minZoom={4}
                maxZoom={20}
                maxBounds={[
                    [85, 180],
                    [85, -180],
                    [-85, -180],
                    [-85, 180],
                ]}
                maxBoundsViscosity={1}
            >
                <TileLayer
                    maxZoom={20}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={`https://api.mapbox.com/styles/v1/${config.mapbox.user_name}/${config.mapbox.style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${config.mapbox.access_token}`}
                />
                <DivIcon
                    className=''
                    key={`previewStep`}
                    position={
                        infoLocationNew && Object.keys(infoLocationNew).length > 0
                            ? [infoLocationNew?.position?.lat, infoLocationNew?.position?.lon || infoLocationNew?.position?.lng]
                            : [infoLocation.lat, infoLocation.lon]
                    }
                    iconAnchor={[0, 0]}
                    iconSize={[0, 0]}
                    zIndexOffset={600}
                ></DivIcon>
                <PixiOverlay markers={markers} />
            </MapContainer>
        </>
    );
};

export default MapLocation;
