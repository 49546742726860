import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Camera } from 'phosphor-react';
import { FormattedMessage } from 'react-intl';
import { CurrentUserAvatar } from '../../../common/Avatar';

import styles from './index.module.scss';

const AddPostButton = ({ onClickAddPost = noop, className }) => {
    return (
        <div className={classNames(styles.block, className)}>
            <CurrentUserAvatar className={styles.userImage} />
            <button onClick={onClickAddPost} className={styles.addPostButton}>
                <FormattedMessage id='stepProfile.add_post' />
                <Camera
                    className={styles.camera}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onClickAddPost({ triggerAddImage: true });
                    }}
                    size={18}
                    color='#000000'
                />
            </button>
        </div>
    );
};

export default AddPostButton;
