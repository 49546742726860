import { useEffect, useRef } from "react";

const useInputFocus = (condition = false) => {
  const inputRef = useRef(null);
  
  useEffect(() => {
    if (condition && inputRef.current) {
      inputRef.current.focus();
    }
  }, [condition]);
  
  return inputRef;
};

export default useInputFocus;