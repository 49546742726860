import React from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';

const ModalsTagsTemplate = ({ textFirst, textSecond, onCancel, onApprove, subject, approveButtonText, cancelButtonText }) => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.panel}>
                    <span className={styles.textFirst}>
                        <span className={styles.boldText}>{subject} </span>
                        {textFirst}
                    </span>
                    <span className={styles.textSecond}>{textSecond}</span>
                    <div className={styles.buttons}>
                        <CustomButton className={styles.cancel} onClick={onCancel} type={ButtonType.SECONDARY_BLACK}>
                            {cancelButtonText}
                        </CustomButton>
                        <CustomButton className={styles.approve} onClick={onApprove} type={ButtonType.PRIMARY_BLUE}>
                            {approveButtonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalsTagsTemplate;
