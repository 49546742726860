export const LIKE_STEP = 'LIKE_STEP';
export const GET_STEP = 'GET_STEP';
export const ARCHIVE_STEP = 'ARCHIVE_STEP';
export const RESTORE_STEP = 'RESTORE_STEP';
export const ADD_TAG_TO_STEP = 'ADD_TAG_TO_STEP';
export const DELETE_TAG_FROM_STEP = 'DELETE_TAG_FROM_STEP';
export const ADD_NEW_TAG_TO_STEP = 'ADD_NEW_TAG_TO_STEP';
export const EDIT_STEP_RATING = 'EDIT_STEP_RATING';
export const CREATE_STEP = 'CREATE_STEP';
export const CLEAR_SEARCHED_GOOGLE_PLACES = 'CLEAR_SEARCHED_GOOGLE_PLACES';
export const GET_PLACE_DATA = 'GET_PLACE_DATA';
export const GET_SEARCH_RESULT_CANCEL = 'GET_SEARCH_RESULT_CANCEL';
export const GET_SEARCH_RESULT_MAP = 'GET_SEARCH_RESULT_MAP';
export const FETCH_STEPS_BY_BOUNDS = 'FETCH_STEPS_BY_BOUNDS';
export const CHANGE_PREVIEW_TAGS = 'CHANGE_PREVIEW_TAGS';
export const SORTED_STEPS = 'SORTED_STEPS';
export const CHANGE_STATUS_STEPS = 'CHANGE_STATUS_STEPS';
export const GET_PENDING_STEPS = 'GET_PENDING_STEPS';
export const DELETE_PENDING_STEPS = 'DELETE_PENDING_STEPS';
export const POST_PENDING_STEPS = 'POST_PENDING_STEPS';
export const SET_CHANGE_LOCATION = 'SET_CHANGE_LOCATION';
export const SET_CHANGE_LOCATION_ID = 'SET_CHANGE_LOCATION_ID';
export const SEARCH_STEPS = 'SEARCH_STEPS';
