import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { userSelectors } from '../../../../redux/user';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import Outcome from '../Outcome';
import { BaseCard } from '../BaseCard';
import { generateImageUrl } from '../../../../helpers/index';
import copiesPrefix from '../../../../copies.json';
import { TextArrayBody } from '../../../../components/common';
import styles from './index.module.scss';
import Comment from '../../../../components/PostStructure/Comment';
import { Outcomes } from '../../constants';
import PostSlider from '../../../../components/PostSlider';

const copies = copiesPrefix.mapUpdates;

const ReportStepCard = ({
    post = {},
    avatarImageId,
    createdAt,
    textArray,
    message,
    outcome,
    handlerManager,
    managerPreview,
    onViewOnMapClick = noop,
    onDisregard = noop,
    onTagClick = noop,
    onDelete = noop,
    onKeep = noop,
    onCardClick = noop,
    ...rest
}) => {
    const user = useSelector(userSelectors.user);
    const maps = useSelector(userSelectors.maps);
    const [isDisabled, setIsDisabled] = useState(false);
    const selectedMapId = useSelector(userSelectors.selectedMapId);
    const selectedMap = maps?.filter(({ _id }) => _id === selectedMapId)[0];
    const managers = selectedMap?.managers;
    const hasHandlerManager = handlerManager && handlerManager !== user.id;
    const managerInfomation = managers?.filter(({ _id }) => _id === handlerManager)[0];
    const isComment = Boolean(post?.parent_post_id);

    const imageSrc = useMemo(() => {
        return generateImageUrl({ imageId: post?.attachments?.[0]?.attachment?.image_id, reason: 'recent_activity_post' });
    }, [post]);

    const buttons = [
        <CustomButton type={ButtonType.SECONDARY_BLACK} size={ButtonSize.MEDIUM} onClick={onViewOnMapClick}>
            <FormattedMessage id='mapUpdates.view_on_map' />
        </CustomButton>,
        <CustomButton
            type={ButtonType.SECONDARY}
            size={ButtonSize.MEDIUM}
            clicked={outcome !== Outcomes.UNHANDLED || isDisabled}
            onClick={() => {
                onDelete();
                setIsDisabled(true);
            }}
        >
            {outcome === Outcomes.DELETE ? copies.removed : copies.archive_step}
        </CustomButton>,
        <CustomButton singleClick size={ButtonSize.MEDIUM} type={ButtonType.PRIMARY} onClick={onKeep} clicked={outcome !== Outcomes.UNHANDLED || isDisabled}>
            {outcome === Outcomes.DISREGARD ? copies.kept : copies.keep_step}
        </CustomButton>,
    ];

    return (
        <BaseCard
            avatarImgId={avatarImageId}
            createdAt={createdAt}
            textArray={textArray}
            message={message}
            onClose={onDisregard}
            onTagClick={onTagClick}
            onCardClick={() => {
                onCardClick(post);
            }}
            {...rest}
            shouldRenderFooter={outcome === Outcomes.DELETE || outcome === Outcomes.DISREGARD || hasHandlerManager}
            footer={<Outcome text={outcome === Outcomes.DISREGARD ? copies.kept_by : copies.removed_by} managerPreview={managerPreview || managerInfomation} />}
            buttons={isComment ? buttons.slice(1, 3) : buttons}
        >
            {isComment ? (
                <Comment
                    comment={{
                        createdAt: post?.created_at,
                        userName: post?.user_preview?.display_name,
                        userImageId: post?.user_preview?.image,
                        textArray: post?.text_array,
                        temp: true,
                    }}
                />
            ) : (
                <>
                    <TextArrayBody body={post?.text_array} isTaggingDisabled className='m-b-8' />
                    {imageSrc && (
                        <PostSlider
                            width={504}
                            height={504}
                            slidesToShow={1}
                            slidesToScroll={1}
                            dots={false}
                            classNamesPostImg={styles.postImg}
                            images={[post?.attachments?.[0]]}
                        />
                    )}
                </>
            )}
        </BaseCard>
    );
};

export default ReportStepCard;
