import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Loader from '../../components/Loader';
import config from '../../config';

const FeedBack = () => {
    const [loader, setLoader] = useState(false);
    const [tokenGenerated, setTokenGenerated] = useState(null);

    useEffect(async () => {
        const accessToken = localStorage.getItem('at');
        let response = await fetch(`${config.domain.address}/steps3/auth/sso/single-sign-on-token?type=productboard`, {
            headers: {
                Authorization: accessToken,
                app_version: config.version.path,
                app_platform: 'map_manager',
            },
        });
        response = await response.json();
        setTokenGenerated(response?.token);
    }, []);

    return (
        <div className={styles.blockFeedback} data-cy='feedback'>
            <iframe
                onLoad={() => setLoader(true)}
                src={`https://portal.productboard.com/unelzerjbddq7kqajhn5axuq?token=${tokenGenerated}`}
                frameborder='0'
                height='100%'
                width='100%'
            />
            {!loader && <Loader/>}
        </div>
    );
};

export default FeedBack;
