import { useState } from 'react';
import { noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../redux/user';
import { postAnnouncement, editPost, createFeedPost, createPostsInPost } from '../../../redux/post/actions';
import { PlatformTypes } from '../../../constants';
import { TextArray } from '../../../models';
import { PostTypes, TagTypes, MixPanelTagTypes, MixpanelTagContext } from '../../../constants';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';
import { mapSelectors } from '../../../redux/map';
import context from '../../../constants/context';

const usePost = () => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const mapName = useSelector(mapSelectors.mapName(mapId));
    const [isLoading, setIsLoading] = useState(false);

    const post = async ({ body, type, stepId, imageIds, onSuccess = noop, onError = noop, postId }) => {
        let response = {};
        const textArray = body.map(item => new TextArray(item).getRequestPayload());
        const attachments = imageIds.map(imageId => ({
            type: 'IMAGE',
            image_id: imageId,
            thumbnail_image_id: imageId,
        }));

        for (const textObj of textArray) {
            if (textObj.text_type !== 'text')
                mixpanel.track(mixpanelEvents.FEED_POST_TAGGED, {
                    [mixpanelProperties.TAG_TYPE]: textObj.text_type,
                    [mixpanelProperties.TAG]: textObj.text,
                    [mixpanelProperties.TAG_ID]: textObj.user_id || textObj.place_id || textObj.step_id,
                });
        }
        setIsLoading(true);

        try {
            switch (type) {
                case PostTypes.ANNOUNCEMENT:
                    response = await dispatch(
                        postAnnouncement({
                            mapId,
                            textArray,
                            attachments,
                        })
                    );
                    break;

                case PostTypes.FEED:
                    response = await dispatch(
                        createFeedPost({
                            mapId,
                            postId,
                            textArray,
                            attachments,
                            context: context.FEED_VIEW
                        })
                    );
                    break;

                case PostTypes.STEP:
                case PostTypes.POST: {
                    const data = {
                        map_id: mapId,
                        step_id: stepId,
                        parent_post_id: postId,
                        client_type: 'map_manager',
                        text_array: textArray,
                        attachments,
                    };

                    response = await dispatch(createPostsInPost(data, stepId, null, mapId));
                    break;
                }

                case PostTypes.EDIT_POST:
                    response = await dispatch(
                        editPost(postId, {
                            postId,
                            text_array: textArray,
                            client_type: PlatformTypes.MAP_MANAGER,
                            attachments,
                            mapId,
                        })
                    );
                    break;

                default:
                    break;
            }

            const results = response?.data?.announcement || response?.data?.post;
            setIsLoading(false);

            if (!results) {
                console.error('Something went wrong');
                return;
            }

            if (type !== PostTypes.EDIT_POST) {
                const allowedTypes = [TagTypes.STEP_TAG, TagTypes.PLACE_TAG, TagTypes.USER_TAG];
                const tagObjects = body.filter(textArray => allowedTypes.includes(textArray.type));

                tagObjects.forEach(({ text, type }) => {
                    mixpanel.track(mixpanelEvents.TAG_ADDED_TO_POST, {
                        [mixpanelProperties.POST_ID]: response._id || response.data.post._id,
                        [mixpanelProperties.MAP_ID]: mapId,
                        [mixpanelProperties.MAP_NAME]: mapName,
                        [mixpanelProperties.TAG]: text,
                        [mixpanelProperties.TAG_TYPE]: MixPanelTagTypes[type],
                        [mixpanelProperties.CONTEXT]: MixpanelTagContext.WHILE_POSTING,
                    });
                });
            }

            onSuccess(results);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            onError(error);
        }
    };

    return { post, isLoading };
};

export default usePost;
