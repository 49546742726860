import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../redux/user';
import { mapSelectors } from '../../../redux/map';
import { convertJourneysToArray } from '../..//MapStages/helpers';

const useStagesProgress = () => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const journeys = convertJourneysToArray(map?.journeysStatus || {});
    const flags = map?.flags?.MAP_MANAGER_JOURNEY_TASKS;
    const stepsCount = map?.steps_count;
    const membersCount = map?.members_count;
    const mapDescriptionCount = map?.description ? 1 : 0;
    const mapTagsCount = Object.keys(map?.tag_objects || {}).length;

    const levelsData = {
        ADD_20_STEPS_TO_MAP: {
            realProgress: stepsCount,
            isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_ADDED_20_STEPS,
        },
        GET_25_MEMBERS: {
            realProgress: membersCount,
            isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_25_MEMBER_JOINED_MAP,
        },
        ADD_DESCRIPTION_TO_MAP: {
            realProgress: mapDescriptionCount,
            isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_ADDED_MAP_DESCRIPTION,
        },
        ADD_3_TAGS_TO_MAP: {
            realProgress: mapTagsCount,
            isAlreadyCompleted: flags?.MAP_MANAGER_JOURNEY_TASKS_3_ADDED_TAGS,
        },
    };

    const currentProgress = useMemo(() => {
        let progress = 0;
        const total = journeys.reduce((prevTotal, journey) => prevTotal + journey.total, 0);

        journeys.forEach(journey => {
            const isAlreadyCompleted = levelsData[journey.name]?.isAlreadyCompleted;
            const maxProgress = Math.min(levelsData[journey.name]?.realProgress, total);
            const journeyProgress = isAlreadyCompleted ? journey.total : maxProgress;
            const relativeProgress = Math.round((total / journeys.length) * (journeyProgress / journey.total));
            progress += relativeProgress;
        });

        return { total, progress };
    }, [flags, stepsCount, membersCount, mapDescriptionCount, mapTagsCount]);

    const isAdvancedLevel = map.map_level > 1 || currentProgress.progress >= currentProgress.total;

    return { ...currentProgress, isAdvancedLevel };
};

export default useStagesProgress;
