import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import { ClickAwayListener } from '@material-ui/core';
import { X } from 'phosphor-react';
import SearchInput from '../../inputs/SearchInput';
import TagsBlock from './TagsBlock';
import TagsSelected from './TagsSelected';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../../redux/user';
import { mapSelectors, mapActions } from '../../../../redux/map';
import { useNavigate } from 'react-router';
import IconTag from '../../icons/IconTag';
import { mapTagObjects } from '../../../../helpers';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import ROUTES from '../../../../config/routes';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../../lottie/grayCircleAnimation.json';
import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.tags.addTagsModal;

const ModalsSelectTags = ({ onCancel, map, tagsObject, pendingSelectedTags, isSelectTagsModalOpen, setPendingSelectedTags, selectedModalTags, fromWhere }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [objectTags, setObjectTags] = useState(tagsObject || {});
    const [showSelect, setShowSelect] = useState(false);
    const [selectedTags, setSelectedTags] = useState(pendingSelectedTags || []);
    const [disableAddTags, setDisableAddTags] = useState(false);
    const [tagsToDelete, setTagsToDelete] = useState([]);
    const [tagsToAdd, setTagsToAdd] = useState([]);
    const [loader, setLoader] = useState(false);
    const obj_tags = useSelector(mapSelectors.mapTags(map?._id));
    const user = useSelector(userSelectors.user);

    const searchTag = useCallback(
        e => {
            let value = '';
            if (e !== undefined) {
                value = e.target.value;
            } else {
                value = '';
            }
            setSearchValue(value);
            const searchResult = Object.entries(tagsObject).reduce((searchResult, [key, objects]) => {
                const regexp = new RegExp(value, 'i');
                const search = objects.tags.filter(({ name }) => name.match(regexp));
                if (search.length > 0) {
                    const dataCategoriesNew = {};
                    dataCategoriesNew[key] = JSON.parse(JSON.stringify(tagsObject[key]));
                    dataCategoriesNew[key].tags = search;
                    Object.assign(searchResult, dataCategoriesNew);
                } else if (value.length === 0) {
                    Object.assign(searchResult, tagsObject);
                }
                return searchResult;
            }, {});
            setObjectTags(searchResult);
        },
        [dispatch, tagsObject]
    );

    const handleSelectTag = useCallback(
        item => {
            const newSelectedTags = selectedTags?.find(el => el.name === item.name)
                ? selectedTags.filter(tag => tag.name !== item.name)
                : [...selectedTags, item];
            if (selectedModalTags) {
                const selectedModalTagsId = selectedModalTags?.tags.map(item => item?._id);
                const itemsId = newSelectedTags.map(el => el?._id);
                const toDelete = selectedModalTagsId.filter(x => !itemsId.includes(x));
                setTagsToDelete(toDelete);
                const toAdd = itemsId.filter(x => !selectedModalTagsId.includes(x));
                setTagsToAdd(toAdd);
            }
            setSelectedTags(newSelectedTags);
        },
        [selectedTags]
    );

    const addTagsDone = items => {
        if (selectedModalTags) {
            const selectedModalTagsId = selectedModalTags?.tags.map(item => item?._id);
            const itemsId = items.map(el => el?._id);
            const toDelete = selectedModalTagsId.filter(x => !itemsId.includes(x));
            const toAdd = itemsId.filter(x => !selectedModalTagsId.includes(x));
            setPendingSelectedTags(items, toDelete, toAdd, selectedModalTags.stepIds);
        } else {
            setPendingSelectedTags(items);
        }

        onCancel();
    };

    const addTagsToMap = useCallback(
        (category, name) => {
            setDisableAddTags(true);
            setShowSelect(false);
            setSearchValue('');
            searchTag();
            setLoader(true);
            const id = category?._id;
            const newTag = {
                active: true,
                category_id: id,
                name: name,
                priority: 0,
            };
            dispatch(
                mapActions.addMapTags(map?._id, {
                    tags: [
                        {
                            name: searchValue,
                            category_id: id,
                            category: category?.name,
                        },
                    ],
                })
            ).then(({ data }) => {
                newTag._id = data.tags[0]._id;
                newTag.category_id = data.tags[0].category_id;
                const newObject = Object.values(tagsObject).map(item => {
                    if (item.category_id === id) {
                        item.tags.push(newTag);
                    }
                    return item;
                });
                setDisableAddTags(false);
                setObjectTags(newObject);
                setTagsToAdd([...tagsToAdd, newTag]);
                setSelectedTags([...selectedTags, newTag]);
                setLoader(false);
            });

            mixpanel.track(mixpanelEvents.ADD_TAG, {
                [mixpanelProperties.ADD_TO]: 'step',
                [mixpanelProperties.FROM_WHERE]: fromWhere,
                [mixpanelProperties.HOW]: 'search',
                [mixpanelProperties.IS_STEP_CREATED_BY_SAME_USER]: user.name,
                [mixpanelProperties.IS_FIRST_ON_MAP]: obj_tags.length > 0 ? false : true,
            });
        },
        [dispatch, map, selectedTags, tagsObject, searchValue, tagsToAdd]
    );

    const createCategoryAndTag = searchValue => {
        setDisableAddTags(true);
        setLoader(true);
        dispatch(
            mapActions.addMapCategories(map?._id, {
                categories: [
                    {
                        name: 'General',
                        tag_names: [searchValue],
                    },
                ],
            })
        ).then(({ data }) => {
            if (data) {
                dispatch(mapActions.fetchSingleMap(map?._id)).then(({ data }) => {
                    if (data) {
                        const newTag = {
                            active: true,
                            category_id: data.tag_objects[0].category_id,
                            name: data.tag_objects[0].name,
                            priority: 0,
                            _id: data.tag_objects[0]._id,
                        };
                        let tagsObjects = mapTagObjects(data.tag_objects, data?.tags_categories);
                        setDisableAddTags(false);
                        setObjectTags(tagsObjects);
                        setTagsToAdd([...tagsToAdd, newTag]);
                        setSelectedTags([...selectedTags, newTag]);
                        setSearchValue('');
                        setLoader(false);
                    }
                });
            }
        });
        mixpanel.track(mixpanelEvents.ADD_TAG, {
            [mixpanelProperties.ADD_TO]: 'step',
            [mixpanelProperties.FROM_WHERE]: fromWhere,
            [mixpanelProperties.HOW]: 'search',
            [mixpanelProperties.IS_STEP_CREATED_BY_SAME_USER]: user.name,
            [mixpanelProperties.IS_FIRST_ON_MAP]: obj_tags.length > 0 ? false : true,
        });
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    return (
        <BaseModal className={styles.modal} isActive onClose={onCancel} size={ModalSizes.MEDIUM}>
            <div className={styles.panelHead}>
                <div className={styles.dFlex}>
                    <span className={styles.title}>{copies.title}</span>
                    <X className={styles.xButton} color='#666A70' size={16} onClick={onCancel} />
                </div>
                <SearchInput value={searchValue} className={styles.searchBar} placeholder={copies.placeholder} onChange={searchTag} />
            </div>
            <div className={styles.panelBody}>
                {map?.tag_objects?.length === 0 &&
                searchValue?.length === 0 &&
                (Object.values(objectTags).filter(el => el.tags > 0).length === 0 || objectTags?.filter(el => el.tags > 0).length === 0) ? (
                    <div className={styles.emptyTagsBlock}>
                        <div>
                            <p className={styles.emptyTagsTitle}>
                                <IconTag colorFill={'#666a70'} colorStroke={'#666a70'} colorCircle={'#ffffff'} />
                                {copies.no_tags}
                            </p>
                            <p
                                className={styles.emptyTagsText}
                                dangerouslySetInnerHTML={{
                                    __html: copies.description,
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        {selectedTags.length > 0 && (
                            <div className={styles.tagsSelectedBlock}>
                                {selectedTags.map((item, index) => (
                                    <TagsSelected key={item?.name} handleSelectTag={handleSelectTag} item={item} idx={index} />
                                ))}
                            </div>
                        )}
                        {loader ? (
                            <p>
                                <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={100} height={100} />
                            </p>
                        ) : (
                            objectTags &&
                            Object.values(objectTags).map((item, index) => (
                                <TagsBlock key={item?.name} selectedTags={selectedTags} handleSelectTag={handleSelectTag} item={item} idx={index} />
                            ))
                        )}
                        {/* Regex is used to detect empty strings - which contains only spaces */}
                        {!loader &&
                            searchValue.replace(/\s/g, '').length > 0 &&
                            map?.tags.findIndex(tag => tag.toLowerCase() === searchValue.toLowerCase()) < 0 &&
                            !Object.values(objectTags)
                                .map(el => el.tags.map(s => s.name))[0]
                                ?.includes(searchValue) && (
                                <div className={styles.createNewTag}>
                                    <button
                                        onClick={
                                            map.tags_categories.length > 1
                                                ? () => setShowSelect(true)
                                                : map.tags_categories.length === 0
                                                ? () => createCategoryAndTag(searchValue)
                                                : () => addTagsToMap(map.tags_categories[0], searchValue)
                                        }
                                        disabled={disableAddTags}
                                    >
                                        + {copies.create_new_tag} ‘{searchValue}’
                                    </button>
                                    {showSelect && (
                                        <ClickAwayListener onClickAway={() => setShowSelect(false)}>
                                            <div className={styles.selectCategoryBlock}>
                                                <div className={styles.headerText}>{copies.select_category}</div>
                                                <div className={styles.listCategory}>
                                                    {map.tags_categories.map((item, index) => (
                                                        <div onClick={() => addTagsToMap(item, searchValue)} key={index} className={styles.listCategoryName}>
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    )}
                                </div>
                            )}
                    </>
                )}
            </div>
            <div className={styles.panelFooter}>
                <button className={styles.tagSettings} onClick={() => navigate('/' + ROUTES.TAGS)}>
                    {copies.tag_settings}
                </button>
                <button
                    onClick={() => addTagsDone(selectedTags)}
                    disabled={
                        (selectedModalTags ? tagsToDelete?.length === 0 && tagsToAdd?.length === 0 : selectedTags.length === 0) &&
                        isSelectTagsModalOpen?.disableDone
                    }
                    className={styles.doneButton}
                >
                    {copies.done}
                </button>
            </div>
        </BaseModal>
    );
};

export default ModalsSelectTags;
