import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../../../../components/common/buttons/CustomButton';
import classNames from 'classnames';

const LAST_PAGE = 3;
const NEXT = 'Next';
const HINT = 'Got it';

const getButtonProps = ({ page, nextClicked, closeHint, isHint }) => {
    if (isHint) return { text: HINT, handler: closeHint, customDisabled: false };
    else if (page !== LAST_PAGE && page !== 4) return { text: NEXT, handler: nextClicked };
    else return { text: NEXT, handler: nextClicked, customDisabled: false };
};

const NextStep = ({ page, lastStepClicked, nextClicked, isHint, closeHint, disabled }) => {
    const { text, handler, customDisabled } = getButtonProps({ page, lastStepClicked, nextClicked, isHint, closeHint });
    const buttonDisabled = customDisabled ?? disabled;
    
    return (
        <div className={classNames(styles.nextBtn, { [styles.padding]: page === 3 })}>
            {text && <CustomButton size={'largeSize'} type={ButtonType.PRIMARY_BLUE} text={text} onClick={handler} disabled={buttonDisabled}/>}
        </div>
    );
};

export default NextStep;
