import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';

import styles from './Avatar.module.scss';

export const AvatarSizesNames = {
    EXTRA_SMALL: 'EXTRA_SMALL',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
};

export const AvatarSizes = {
    EXTRA_SMALL: '10',
    SMALL: '24',
    MEDIUM: '36',
    LARGE: '60',
};

const Avatar = ({ src, alt, size = AvatarSizesNames.SMALL, className }) => {
    return (
        <img
            src={src}
            role='img'
            aria-label={alt}
            style={{ backgroundImage: `url(${src})` }}
            className={classNames(styles.avatar, styles[camelCase(size)], className)}
        />
    );
};

export default Avatar;

Avatar.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    size: PropTypes.oneOf(Object.values(AvatarSizesNames)),
    className: PropTypes.string,
};
