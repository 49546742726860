export const stepsLayer = ({ allowOverlap }) => ({
    id: 'steps-layer',
    type: 'symbol',
    source: 'steps-source',
    layout: {
        'icon-image': 'step',
        'icon-allow-overlap': !!allowOverlap,
    },
});

export const ovalLayer = ({ allowOverlap }) => ({
    id: 'oval-layer',
    type: 'symbol',
    source: 'steps-source',
    layout: {
        'icon-image': 'oval',
        'icon-allow-overlap': !!allowOverlap,
    },
});

export const recommendedLayer = ({ allowOverlap }) => ({
    id: 'recommended-layer',
    type: 'symbol',
    source: 'steps-recommended-source',
    layout: {
        'icon-image': 'recommended',
        'icon-allow-overlap': !!allowOverlap,
    },
});

export const newStepsLayer = ({ allowOverlap }) => ({
    id: 'new-steps-layer',
    type: 'symbol',
    source: 'steps-new-source',
    layout: {
        'icon-image': 'new',
        'icon-allow-overlap': !!allowOverlap,
    },
});
