import { useState } from 'react';
import { uploadImages as upload } from './utils';

const useImageCarousel = ({ imageIds }) => {
    const [images, setImages] = useState(imageIds.map(imageId => ({ imageId })));

    const onLoadFilesStart = imageFiles => {
        const newImages = Array.from(imageFiles).map(imageFile => ({
            ...imageFile,
            isLoading: true,
        }));

        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const onUploadSuccess = imageObject => {
        setImages(prevImages => {
            return prevImages.map(image => {
                if (image.uuid === imageObject?.uuid) {
                    return { ...image, ...imageObject, isLoading: false };
                }

                return image;
            });
        });
    };

    const uploadImages = files => {
        return upload(files, { onLoadFilesStart, onUploadSuccess });
    };

    return {
        images,
        uploadImages,
        isUploadImagesPending: images.some(image => image.isLoading),
        onRemoveImage: ({ imageId }) => setImages(images.filter(image => image.imageId !== imageId)),
    };
};

export default useImageCarousel;
