import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

export const mixPanelSortClick = (sortBy, orderBy) => {
    mixpanel.track(mixpanelEvents.LV_SORT_CLICK, {
        [mixpanelProperties.SORT_BY]: sortBy,
        [mixpanelProperties.ORDER]: orderBy === 'asc' ? 'ascending' : 'descending',
    });
}

export const mixPanelAddTagsClick = (selectedStepsCount) => {
    mixpanel.track(mixpanelEvents.LV_BULK_ADD_TAGS_CLICK, {
        [mixpanelProperties.NUM_SELECTED]: selectedStepsCount,
    });
}

export const mixPanelBulkArchiveClick = (selectedStepsCount) => {
    mixpanel.track(mixpanelEvents.LV_BULK_ARCHIVE_CLICK, {
        [mixpanelProperties.NUM_SELECTED]: selectedStepsCount,
    });
}