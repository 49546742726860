import { useDispatch, useSelector } from 'react-redux';
import { mapSelectors } from '../../redux/map';
import styles from './index.module.scss';
import { mapActions } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import { ClickAwayListener } from '@material-ui/core';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';

const TagSearchResultList = ({ categories, searchInput, setToggleAddSearchResultToCategory, setSearch, isFirstTagForMap, fromWhere }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const tags = useSelector(mapSelectors.mapTags(mapId));
    const user = useSelector(state => state.user);

    const handleAddNewTagToSelectedCategory = category => {
        const newTag = {
            category_priority: category.priority,
            name: searchInput,
            category_id: category._id,
        };
        let isTagExist = tags.find(t => {
            return t.name === searchInput && t.category_id === newTag.category_id;
        });
        if (isTagExist === undefined) {
            dispatch(mapActions.addNewTagToMap(mapId, { tags: [newTag] }));
            mixpanel.track(mixpanelEvents.ADD_TAG, {
                [mixpanelProperties.ADD_TO]: 'map',
                [mixpanelProperties.FROM_WHERE]: 'step_components',
                [mixpanelProperties.HOW]: 'search',
                [mixpanelProperties.IS_STEP_CREATED_BY_SAME_USER]: user.name,
                [mixpanelProperties.IS_FIRST_ON_MAP]: isFirstTagForMap,
            });
            setToggleAddSearchResultToCategory(false);
            setSearch('');
        }
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => setToggleAddSearchResultToCategory(false)}>
                <ul className={styles.catMenu}>
                    <li className={styles.liCatTitle}>Select Category</li>
                    {categories &&
                        categories.map((category, i) => (
                            <li key={i} onClick={() => handleAddNewTagToSelectedCategory(category)} className={styles.liCat}>
                                {category.name}
                            </li>
                        ))}
                </ul>
            </ClickAwayListener>
        </>
    );
};

export default TagSearchResultList;
