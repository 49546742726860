import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Comment from '../Comment';
import { LoadingBlock } from '../../Feed/LoadingPostsBlocks';
import { postActions } from '../../../redux/posts';
import styles from './InfiniteComments.module.scss';
import { useEffect, useRef, useState } from 'react';

let scrollTimeoutId = null;

const InfiniteComments = ({ postId, comments, cursor, repliesSettings, showReplies, isReply, className, scrollableTarget }) => {
    const dispatch = useDispatch();
    const [isScrolling, setIsScrolling] = useState()
    const scrollRef = useRef()

    useEffect(() => {
    }, [comments, cursor]);

    if (!comments || !comments.length) {
        return null;
    }

    return (
        <div id='commentsContainer' className={classNames(styles.container, className)}>
            <InfiniteScroll
                scrollableTarget={`scrollTarget_${postId}`}
                dataLength={comments.length}
                next={() => {
                    dispatch(postActions.getPostComments(postId, cursor));
                }}
                hasMore={!!cursor}
                scrollThreshold={0.5}
                loader={<LoadingBlock />}
                className={styles.commentsContainer}
                ref={scrollRef}
                onScroll={() => {
                    if (scrollTimeoutId) clearTimeout(scrollTimeoutId);
                    setIsScrolling(true);
    
                    scrollTimeoutId = setTimeout(() => {
                        setIsScrolling(false);
                    }, 500);
                }}
            >
                {comments.map(comment => (
                    <Comment comment={comment} isReply={isReply} repliesSettings={repliesSettings} key={comment.id} showReplies={showReplies} isScrolling={isScrolling}/>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default InfiniteComments;
