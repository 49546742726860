import {
    SET_SELECTED_PLACE_TO_ADD,
    SET_CURRENT_PAGE,
    OPEN_STEP_PROFILE,
    SET_TEMP_PENDING_ID,
    ADD_PENDING_CHANGED_LOCATION,
    SET_OPEN_TAGS,
    EXPIRED_FORGOT_TOKEN,
    GO_TO,
} from './types';

export const goto = where => ({
    type: GO_TO,
    where,
});

export const setTempPendingId = pendingId => ({
    type: SET_TEMP_PENDING_ID,
    pendingId,
});

export const addPendingChangedLocation = location => ({
    type: ADD_PENDING_CHANGED_LOCATION,
    location,
});

export const setSelectedPlaceToAdd = selectedPlace => ({
    type: SET_SELECTED_PLACE_TO_ADD,
    selectedPlace,
});

export const setCurrentPage = currentPage => ({
    type: SET_CURRENT_PAGE,
    currentPage,
});
export const setOpenTags = openTags => ({
    type: SET_OPEN_TAGS,
    openTags,
});

export const openStepProfile = stepId => ({
    type: OPEN_STEP_PROFILE,
    payload: stepId,
});

export const handleExpiredForgotToken = expiredToken => ({
    type: EXPIRED_FORGOT_TOKEN,
    expiredToken,
});
