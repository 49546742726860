import styles from './index.module.scss';
import { noop } from 'lodash';
import CustomButton, { ButtonSize, ButtonType } from '../../../../components/common/buttons/CustomButton';
import { blobUrl } from '../../../../helpers';
import { X } from 'phosphor-react';

const AddLocation = ({ onClick, address, file, withCancelButton = false, onCancel = noop }) => {
    return (
        <div className={styles.container}>
            {file && <img className={styles.image} src={blobUrl(file)} />}
            <span className={styles.title}>{address}</span>
            <CustomButton text='Next' size={ButtonSize.SMALL} type={ButtonType.PRIMARY} onClick={onClick} />
            {withCancelButton && (
                <span className={styles.cancelButton}>
                    <X size={20} color='#2171EC' weight='bold' onClick={onCancel} alt='close-popup' className='pointer' />
                </span>
            )}
        </div>
    );
};

export default AddLocation;
