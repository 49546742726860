import React, { useEffect, useState, useMemo } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import { removeWhitespace } from './../../../../helpers/string';

const ModalsRename = ({ textFirst, textSecond, onCancel, onRename, placeholder, handleOnChange, isExist, content, onEnterKey, oldName }) => {
    const [value, setValue] = useState(null);
    const isEmpty = useMemo(() => !value && removeWhitespace(value) === '', [value]);
    const isSame = useMemo(() => value === oldName, [value, oldName]);

    useEffect(() => {
        setValue(content ? content.name : null);
    }, [content]);

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <ClickAwayListener onClickAway={onCancel}>
                    <div className={styles.panel}>
                        <span className={styles.textFirst}>{textFirst}</span>
                        <span className={styles.textSecond}>{textSecond}</span>
                        <div className={styles.inputBlock}>
                            <input
                                autoFocus
                                value={value || content.name}
                                placeholder={placeholder}
                                onChange={e => {
                                    setValue(e.target.value);
                                    handleOnChange(e.target.value);
                                }}
                            />
                            {isExist && <label>*This tag already exists</label>}
                        </div>
                        <div className={styles.buttons}>
                            <CustomButton className={styles.cancel} onClick={onCancel} type={ButtonType.SECONDARY_BLACK}>
                                Cancel
                            </CustomButton>
                            <CustomButton
                                className={styles.rename}
                                disabled={isExist || isEmpty || isSame}
                                onClick={onRename}
                                type={ButtonType.PRIMARY_BLUE}
                                onKeyPress={e => onEnterKey(e)}
                            >
                                Rename
                            </CustomButton>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default ModalsRename;
