import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const SearchInput = ({
    className,
    onChange,
    onFocus,
    value,
    placeholder,
    hiddenIcon,
    searchLocation = false,
    autoFocus = false,
    onBlur,
    disabled = false,
    isDisableIcon = false,
}) => {
    const input = useRef(null);

    useEffect(() => {
        !disabled && input.current.focus();
    }, [autoFocus, disabled]);

    return (
        <div
            className={classNames([styles.inputBlock, className], {
                [styles.fixInputIcon]: hiddenIcon,
            })}
            onFocus={onFocus}
        >
            {!hiddenIcon && <img src={disabled && !isDisableIcon ? '/assets/img/icoSearch.svg' : '/assets/img/icoSearchGray.svg'} className={styles.icon} />}
            <input
                type='text'
                role='search'
                disabled={disabled}
                placeholder={placeholder || 'Search'}
                className={styles.input}
                autoFocus={autoFocus}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                ref={input}
                onFocus={onFocus}
            />
            {value?.length > 0 && searchLocation && (
                <div className={styles.blueSearch}>
                    <img src='/assets/img/ico_search3.svg' className={styles.icon} />
                </div>
            )}
        </div>
    );
};

export default SearchInput;
