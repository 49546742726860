import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';
import DragAndDrop from './DragAndDrop';
import { generateImageUrl, getUploadUserProfileImgLink, uploadImageToS3 } from '../../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import { ClickAwayListener } from '@material-ui/core';
import { userActions, userSelectors } from '../../../../redux/user';
import BasicInput from '../../inputs/BasicInput';
import Avatar from '@material-ui/core/Avatar';

const ModalsUserProfile = ({ onClose }) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.user);
    const userImageTemp = useSelector(userSelectors.userImageTemp);
    const [imageLoader, setImageLoader] = useState(false);
    const [file, setFile] = useState(false);
    const [disableImage, setDisableImage] = useState(false);
    const [loaderButton, setLoaderButton] = useState(false);
    const [hovered, setHovered] = useState(false);
    const refUserImage = useRef(null);
    const refUserImage2 = useRef(null);
    const [firstName, setFirstName] = useState(user?.first_name || user?.name.split(' ')[0]);
    const [lastName, setLastName] = useState(user?.last_name || user?.name.split(' ')[1]);

    useLayoutEffect(() => {
        refUserImage2.current?.addEventListener('change', onLoadFiles);
        refUserImage.current?.addEventListener('change', onLoadFiles);
        return () => {
            refUserImage2.current?.removeEventListener('change', onLoadFiles);
            refUserImage.current?.removeEventListener('change', onLoadFiles);
        };
    });

    useEffect(() => {
        if (user?.image || user?.user_image_temp) {
            setImageLoader(true);
        }
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    const onLoadFiles = e => {
        setImageLoader(true);
        setFile(e?.target?.files ? e.target.files[0] : e[0]);
    };
    const handleSaveImage = async (first, last, item) => {
        setLoaderButton(true);
        setDisableImage(true);

        if (item) {
            const data = await getUploadUserProfileImgLink();
            uploadImageToS3({
                uploadUrl: data.url,
                file: item,
                onUploadedFile: () => {
                    dispatch(userActions.setUserCoverImage(URL.createObjectURL(item)));
                    setFile(false);
                    if (user?.first_name === firstName || user?.last_name === lastName) {
                        setLoaderButton(false);
                        setDisableImage(false);
                        onClose();
                    }
                },
            });
        }
        if (user?.first_name !== firstName || user?.last_name !== lastName) {
            dispatch(userActions.editUserData({ firstName: first, lastName: last })).then(({ payload }) => {
                if (payload) {
                    dispatch(userActions.getUserData());
                    setLoaderButton(false);
                    setDisableImage(false);
                    onClose();
                }
            });
        }

        if (user?.first_name === firstName && user?.last_name === lastName && !item) {
            onClose();
        }
    };

    const handleFirstNameChange = e => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = e => {
        setLastName(e.target.value);
    };

    return (
        <div className={styles.modals}>
            <ClickAwayListener onClickAway={onClose}>
                <div className={styles.block}>
                    <div className={styles.modalsHeader}>
                        <div onClick={onClose} className={styles.closeBlock}>
                            <img src='/assets/img/icon_cancel_blue.svg' />
                        </div>
                    </div>
                    <div className={styles.modalsBody}>
                        <div className={styles.titleText}>Edit Profile</div>
                        <div className={styles.modalsImageDrop}>
                            <DragAndDrop imageLoader={imageLoader} onDrop={files => onLoadFiles(files)}>
                                <div className={styles.img}>
                                    {imageLoader ? (
                                        <img
                                            onMouseOver={() => setHovered(true)}
                                            src={
                                                file
                                                    ? URL.createObjectURL(file)
                                                    : userImageTemp
                                                    ? userImageTemp
                                                    : generateImageUrl({
                                                          imageId: user?.image,
                                                          width: 149,
                                                          height: 149,
                                                          reason: 'show_user_image',
                                                      })
                                            }
                                            alt=''
                                        />
                                    ) : (
                                        <Avatar
                                            onMouseOver={() => setHovered(true)}
                                            className={styles.avatarUser}
                                            alt={user?.first_name || user?.name.split(' ')[0]}
                                            src={generateImageUrl({
                                                imageId: user?.image,
                                                width: 149,
                                                height: 149,
                                                reason: 'show_user_image',
                                            })}
                                        />
                                    )}
                                    {hovered && (
                                        <div onMouseLeave={() => setHovered(false)} className={styles.hoveredBlock}>
                                            <CustomButton
                                                size={'smallSize'}
                                                onClick={() => refUserImage.current.click()}
                                                type={ButtonType.PRIMARY_BLACK}
                                                disabled={disableImage}
                                                text={'Edit Image'}
                                            />
                                            <input
                                                style={{
                                                    opacity: '0',
                                                    top: '0',
                                                    position: 'absolute',
                                                    zIndex: -1,
                                                }}
                                                accept={['image/png', 'image/jpeg', 'image/jpg']}
                                                type='file'
                                                ref={refUserImage}
                                            />
                                        </div>
                                    )}
                                </div>
                            </DragAndDrop>
                        </div>
                        <div className={styles.modalsBlockInput}>
                            <div className={styles.blockInput}>
                                <p>First Name </p>
                                <BasicInput
                                    className={styles.sizeInput}
                                    type='text'
                                    onChange={handleFirstNameChange}
                                    value={firstName}
                                    // errorText={'Error'}
                                    // error={isError}
                                    placeholder='First Name'
                                    onKeyDown={null}
                                />
                            </div>
                            <div className={styles.blockInput}>
                                <p>Last Name </p>
                                <BasicInput
                                    className={styles.sizeInput}
                                    type='text'
                                    onChange={handleLastNameChange}
                                    value={lastName}
                                    // errorText={'Error'}
                                    // error={isError}
                                    placeholder='Last Name'
                                    onKeyDown={null}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.modalsFooter}>
                        <CustomButton size={'mediumSize'} onClick={onClose} type={ButtonType.TERTIARY_BLACK} text={'Cancel'} />
                        <CustomButton
                            size={'mediumSize'}
                            className={styles.buttonSave}
                            onClick={!loaderButton ? () => handleSaveImage(firstName, lastName, file) : () => {}}
                            type={ButtonType.PRIMARY}
                            disabled={firstName?.length === 0 || lastName?.length === 0}
                        >
                            {loaderButton ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={35} height={35} /> : 'Save'}
                        </CustomButton>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default ModalsUserProfile;
