import { v1 as uuidv1 } from 'uuid';
import { TagTypes } from '../constants';
class TextArray {
    constructor(item, postId) {
        const { uuid, text = '', id, step_id, place_id, user_id, image = '', role = '', type = 'text', text_type } = item;
        this.text = text;
        this.id = id || place_id || user_id || step_id || '';
        this.uuid = uuidv1() + (uuid ? uuid : '') + id;
        this.type = text_type || type || TagTypes.TEXT;
        this.text_type = this.type || TagTypes.TEXT;
        this.post_id = postId;

        if (!this.uuid || !this.type) {
            console.trace('TextArray: missing id or type');
        }

        switch (this.type) {
            case TagTypes.STEP_TAG:
                this.step_id = step_id || id;
                break;
            case TagTypes.PLACE_TAG:
                this.place_id = place_id || id;
                break;
            case TagTypes.USER_TAG:
                this.image = image;
                this.role = role;
                this.user_id = user_id || id;
                break;
            default:
                break;
        }
    }

    getRequestPayload() {
        switch (this.type) {
            case TagTypes.STEP_TAG:
                return {
                    text: this.text,
                    text_type: this.text_type,
                    step_id: this.step_id,
                };

            case TagTypes.PLACE_TAG:
                return {
                    text: this.text,
                    text_type: this.text_type,
                    place_id: this.place_id,
                };

            case TagTypes.USER_TAG:
                return {
                    text: this.text,
                    text_type: this.text_type,
                    user_id: this.user_id,
                };

            default:
                return {
                    text: this.text,
                    text_type: this.text_type,
                };
        }
    }
}

class TextArrayPlaceItem extends TextArray {
    constructor(text, options = {}) {
        super({ text, ...options });
        this.text_type = TagTypes.PLACE_TAG;
        this.place_id = options.place_id || options.placeId || options.id;
        this.id = this.place_id;
    }
}

class TextArrayStepItem extends TextArray {
    constructor(text, options = {}) {
        super({ text, ...options });
        this.text_type = TagTypes.STEP_TAG;
        this.step_id = options.step_id || options.stepId || options.id;
        this.id = this.step_id;
    }
}

class TextArrayUserItem extends TextArray {
    constructor(text, options = {}) {
        super({ text, ...options });
        this.text_type = TagTypes.USER_TAG;
        this.user_id = options.user_id || options.userId || options.id;
        this.id = this.user_id;
    }
}

class TextArrayTextItem extends TextArray {
    constructor(text, options = {}) {
        super({ text, ...options });
        this.text_type = TagTypes.TEXT;
        this.id = uuidv1() + options.uuid;
    }
}

class TextArrayLocationItem {
    constructor(text, options = {}) {
        switch (options.type) {
            case TagTypes.STEP_TAG:
                return new TextArrayStepItem(text, options);
            case TagTypes.PLACE_TAG:
                return new TextArrayPlaceItem(text, options);
            default:
                return new TextArrayTextItem(text, options);
        }
    }
}

export { TextArray, TextArrayPlaceItem, TextArrayStepItem, TextArrayUserItem, TextArrayTextItem, TextArrayLocationItem };
