import { useEffect } from 'react';
import { noop } from 'lodash';
import CustomButton, { ButtonSize, ButtonType } from '../../common/buttons/CustomButton';
import { setIsUserSeenWelcomeMessage } from '../helpers';
import copies from '../../../copies.json';

import styles from './WelcomeMessageFirstTimeModal.module.scss';

const copiesPrefix = copies.welcomeMessage.firstTimeModal;

const WelcomeMessageFirstTimeModal = ({ isOpen = false, onConfirm = noop, onCancel = noop, userId = null }) => {
    useEffect(() => {
        const handler = () => {
            // for when the user refreshes the page while the modal is open
            setIsUserSeenWelcomeMessage(userId);
        };

        window.addEventListener('beforeunload', handler);

        return () => {
            window.removeEventListener('beforeunload', handler);
        };
    }, [userId]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.modalWrapper}>
            <div className={styles.modal}>
                <h1 className={styles.title}>{copiesPrefix.title}</h1>
                <p className={styles.decsription} dangerouslySetInnerHTML={{ __html: copiesPrefix.description }} />
                <div className={styles.ctaWrapper}>
                    <CustomButton
                        onClick={() => {
                            setIsUserSeenWelcomeMessage(userId);
                            onCancel();
                        }}
                        type={ButtonType.SECONDARY_BLACK}
                        size={ButtonSize.MEDIUM}
                    >
                        {copiesPrefix.cancel}
                    </CustomButton>
                    <CustomButton
                        onClick={() => {
                            setIsUserSeenWelcomeMessage(userId);
                            onConfirm();
                        }}
                        type={ButtonType.PRIMARY_BLUE}
                        size={ButtonSize.MEDIUM}
                    >
                        {copiesPrefix.confirm}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default WelcomeMessageFirstTimeModal;
