import { ButtonType } from "../../common/buttons/CustomButton"
import { buttonCta } from '../contants'

const slidesData = [
    {
        title: 'You reached Advanced Level!',
        description: 'Enjoy exclusive tools and features like Announcements, Insights and more!',
        imageSrc: '/assets/img/mapStagesUnlockLevel1.svg',
        titleSize: 22,
        primaryButton: {
            text: 'Next',
            type: ButtonType.PRIMARY,
            cta: buttonCta.NEXT,
        }

    },
    {
        title: 'Announcements',
        description: 'Turn your post into an announcement that will stay pinned to the top of your map’s feed for everyone to see. Any time you publish an announcement, your map members will receive a push notification about it, too!',
        backgroundColor: '#EBE9F7',
        imageSrc: '/assets/img/mapStagesUnlockAnnouncemnts.png',
        primaryButton: {
            text: 'Try it',
            type: ButtonType.PRIMARY_BLACK,
            cta: buttonCta.ANNOUNCEMENT,
        }
    },
    {
        title: 'Insights',
        description: 'Enjoy real-time stats about your map’s performance, like number of views, new members and more, plus tips to help grow your map even more.',
        backgroundColor: '#FFE5E2',
        imageSrc: '/assets/img/mapStagesUnlockInsights.png',
        primaryButton: {
            text: 'Try it',
            type: ButtonType.PRIMARY_BLACK,
            cta: buttonCta.INSIGHTS,
        }
    },
    {
        title: 'More exposure',
        description: 'Your map will now appear in more search results, helping your community grow even faster.',
        backgroundColor: '#FFF1DE',
        imageSrc: '/assets/img/mapStagesUnlockSearch.png',
        primaryButton: {
            text: 'Got it',
            type: ButtonType.PRIMARY,
            cta: buttonCta.GOT_IT,
        }

    },
]

export default slidesData