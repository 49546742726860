import React, { useState } from 'react';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { matchPath, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippy.js/react';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import { generateImageUrl } from '../../helpers';
import { mapSelectors } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import ROUTES from '../../config/routes';
import MenuButton, { menuButtonType } from '../common/buttons/MenuButton';
import MapPicker from '../MapPicker';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import ModalsUserProfile from '../common/modals/ModalsUserProfile';
import UserPlus from '../common/icons/UserPlus';
import ModalsDownloadApp from '../common/modals/ModalsDownloadApp';
import MapStagesIndication from './MapStagesIndication';

import copiesPrefix from '../../copies.json';
import styles from './index.module.scss';
import Logo from '../common/Logo';

const copies = copiesPrefix.sidebar;

const MainMenu = ({ maps = [], mapId, containerChange = noop, onLogout = noop, setSharePopup = noop, onClickMap = noop, onClickShare = noop }) => {
    const navigate = useNavigate();
    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
    const user = useSelector(userSelectors.user);
    const map = useSelector(mapSelectors.map(mapId));
    const userImageTemp = useSelector(userSelectors.userImageTemp);
    const isInsightsLocked = map.flags?.blockedFeatures?.INSIGHTS;
    const [openUserProfile, setOpenUserProfile] = useState(false);

    const feedBack = () => {
        containerChange(ROUTES.FEEDBACK);
        navigate(ROUTES.FEEDBACK);
    };

    const goToCreateMap = () => {
        localStorage.removeItem('taskStorage');
        mixpanel.track(mixpanelEvents.CREATE_MAP_START, {
            [mixpanelProperties.MAP_NAME]: null,
            [mixpanelProperties.MAP_ID]: null,
        });
        localStorage.setItem('page', 'MM');
        localStorage.setItem('ob', true);
        navigate(ROUTES.ONBOARD);
    };

    const logout = () => {
        navigate(ROUTES.LOGIN);
        const tooltipArray = localStorage.getItem('tooltipStorage') && localStorage.getItem('tooltipStorage').split(',');
        localStorage.removeItem('at');
        localStorage.removeItem('rt');
        localStorage.removeItem('recent_mapId');
        localStorage.removeItem('prompt');
        onLogout && onLogout();
        localStorage.setItem('tooltipStorage', tooltipArray);
        localStorage.setItem('userId', user?.id || user?._id);
    };
    const handleShareMap = () => {
        mixpanel.track(mixpanelEvents.SHARE_SHOW, {
            [mixpanelProperties.SHARE_ELEMENT]: 'map',
            [mixpanelProperties.FROM_WHERE]: 'menu',
        });
        onClickShare && onClickShare();
        setSharePopup(true);
    };

    const checkIfMapActive = () => {
        return matchPath(ROUTES.MAP, window.location.pathname);
    };

    return (
        <>
            {isOpenDownloadModal && (
                <ModalsDownloadApp
                    fromWhere={'share'}
                    openDownloadModal={true}
                    closeModal={() => {
                        setIsOpenDownloadModal(false);
                    }}
                />
            )}
            {openUserProfile && <ModalsUserProfile onClose={() => setOpenUserProfile(false)} />}
            <div className={styles.container}>
                <div>
                    <div className='flex items-end relative p-4' onClick={() => navigate(ROUTES.DASHBOARD)} data-cy='main-screen-logo'>
                        <Logo size={Logo.Sizes.SMALL} isLink={false} />
                        <span className='ml-[6px] text-[10px] text-slate-400 leading-3 tracking-wide font-medium'>Map Manager</span>
                    </div>
                    <hr className={styles.line} />
                    <div className={styles.myMap}>
                        <MapPicker onClickMap={onClickMap} maps={maps} />
                        <CustomButton type={ButtonType.BLACK} className={styles.inviteButton} onClick={handleShareMap}>
                            <div style={{ display: 'flex' }}>
                                <UserPlus />
                                <span>
                                    <FormattedMessage id='mapManager.mainMenu.invite' />
                                </span>
                            </div>
                        </CustomButton>
                    </div>
                    <hr className={styles.line} />
                    <MapStagesIndication />
                    <hr className={styles.line} />
                    <div className={styles.menuButtons}>
                        <MenuButton
                            type={menuButtonType.map}
                            notificationsNumber={null}
                            number={String(map?.new_pending_posts_count)}
                            containerState={checkIfMapActive()}
                            onClick={() => {
                                !checkIfMapActive() && navigate('/' + ROUTES.MAP);
                            }}
                        />
                        <MenuButton
                            type={menuButtonType.feed}
                            containerState={matchPath(`${ROUTES.MAP}/${ROUTES.FEED}`, window.location.pathname)}
                            onClick={() => navigate(`/${ROUTES.MAP}/${ROUTES.FEED}`)}
                        />
                        <MenuButton
                            type={menuButtonType.tags}
                            containerState={matchPath(ROUTES.TAGS, window.location.pathname)}
                            onClick={() => containerChange(ROUTES.TAGS)}
                        />
                        <MenuButton
                            type={menuButtonType.insights}
                            notificationsNumber={null}
                            containerState={matchPath(ROUTES.INSIGHTS, window.location.pathname)}
                            onClick={() => {
                                containerChange(ROUTES.INSIGHTS);
                            }}
                            data-cy={'menu-item-Insights'}
                            isLocked={isInsightsLocked}
                        />
                        <MenuButton
                            type={menuButtonType.updates}
                            number={String(map?.new_recent_activities_count)}
                            containerState={matchPath(ROUTES.UPDATES, window.location.pathname)}
                            onClick={() => containerChange(ROUTES.UPDATES)}
                        />
                        {/*<MenuButton*/}
                        {/*    type={menuButtonType.imports}*/}
                        {/*    containerState={matchPath(ROUTES.IMPORTED, window.location.pathname)}*/}
                        {/*    onClick={() => containerChange(ROUTES.IMPORTED)}*/}
                        {/*/>*/}
                        <MenuButton
                            type={menuButtonType.settings}
                            containerState={matchPath(`${ROUTES.MAP_SETTING}/*`, window.location.pathname)}
                            onClick={() => {
                                containerChange(`${ROUTES.MAP_SETTING}?map_id=${mapId}`);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    {!user.has_logged_in_from_mobile && (
                        <div className={styles.share}>
                            <span className={styles.downloadTitle}>
                                Check your map in the
                                <br />
                                Atly app
                            </span>
                            <CustomButton
                                onClick={() => {
                                    setIsOpenDownloadModal(true);
                                }}
                                type={ButtonType.PRIMARY}
                                text='Link to download'
                                className={styles.downloadButton}
                            />
                        </div>
                    )}
                    <div className={styles.footerButtons}>
                        <MenuButton
                            type={menuButtonType.createMap}
                            className={styles.feedback}
                            containerState={matchPath(ROUTES.FEEDBACK, window.location.pathname)}
                            onClick={() => {
                                goToCreateMap();
                            }}
                        />
                    </div>
                    <hr className={styles.line} />
                    <div className={styles.user}>
                        <Tippy
                            className={'transparent-theme'}
                            flipBehavior={['right']}
                            placement='right-start'
                            hideOnClick
                            animation='fade'
                            trigger='click'
                            arrow={false}
                            aria={null}
                            offset={'10, 200'}
                            content={
                                <div className={styles.userProfileBlock}>
                                    <div onClick={() => setOpenUserProfile(true)} className={styles.profileAction}>
                                        <img src={'/assets/img/steps3Images/editPost.svg'} alt={''} />
                                        <p>{copies.edit}</p>
                                    </div>
                                    <div onMouseDown={logout} className={styles.profileAction}>
                                        <p>{copies.logout}</p>
                                    </div>
                                </div>
                            }
                        >
                            <div className={styles.userBlock}>
                                <div className={styles.userName}>
                                    <img
                                        alt={user?.name}
                                        src={
                                            userImageTemp
                                                ? userImageTemp
                                                : generateImageUrl({
                                                      imageId: user?.image,
                                                      width: 62,
                                                      height: 62,
                                                      reason: 'show_user_image',
                                                  })
                                        }
                                        className={styles.avatar}
                                    />
                                    <span>{user?.first_name ? `${user?.first_name} ${user?.last_name}` : user?.name}</span>
                                </div>
                                <img className={styles.arrowProfile} src='/assets/img/steps3Images/arrow_right.svg' alt='' />
                            </div>
                        </Tippy>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainMenu;
