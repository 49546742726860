import React from 'react';
import SocialLogin from 'react-social-login';

// eslint-disable-next-line react/prefer-stateless-function
class Facebook extends React.Component {
    render() {
        const { triggerLogin, triggerLogout, children, ...props } = this.props;
        return (
            <button onClick={triggerLogin} {...props}>
                {children}
            </button>
        );
    }
}

export default SocialLogin(Facebook);
