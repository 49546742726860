import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../redux/user';
import BaseModal, { ModalSizes } from '../../../modalSysyem/BaseModal';
import PaginationSlider, { arrowDirection } from '../../common/PaginationSlider';
import styles from './index.module.scss';
import FeatureCard from './FeatureCard';
import { X } from 'phosphor-react';
import CustomButton, { ButtonType } from '../../common/buttons/CustomButton';

const WhatsNew = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const whatsNew = useSelector(userSelectors.whatsNew)?.components;
    const [isClose, setIsClose] = useState(false);

    return (
        <BaseModal
            isActive={!isClose}
            size={ModalSizes.MEDIUM}
            className={styles.modalContainer}
            onClose={() => {
                setIsClose(true);
            }}
        >
            <X
                size={22}
                className={styles.x}
                onClick={() => {
                    setIsClose(true);
                }}
            />
            <PaginationSlider
                slides={whatsNew.map((val, index) => (
                    <FeatureCard
                        title={val.title}
                        description={val.text}
                        badge={val.badge}
                        backgroundColor={val.background_color}
                        badgeColor={val.badge_color}
                        imageId={val.image_id}
                    />
                ))}
                options={{ loop: true, paginationPosition: 'left' }}
                onSlideChange={index => setCurrentIndex(index)}
            >
                <div className={styles.arrows}>
                    <PaginationSlider.Arrow style={{ display: currentIndex == 0 ? 'none' : 'flex' }} direction={arrowDirection.PREV} />
                    <PaginationSlider.Arrow style={{ display: currentIndex == whatsNew.length - 1 ? 'none' : 'flex' }} />
                    {currentIndex == whatsNew.length - 1 && (
                        <CustomButton
                            className={styles.gotIt}
                            type={ButtonType.PRIMARY_BLACK}
                            text={'Got it'}
                            onClick={() => {
                                setIsClose(true);
                            }}
                        />
                    )}
                </div>
            </PaginationSlider>
        </BaseModal>
    );
};

export default WhatsNew;
