import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { mapActions, mapSelectors } from '../../../../redux/map';
import SocialPostInfo, { socialPostInfoTypes } from '../../../../components/common/info/SocialPostInfo';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import className from 'classnames';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import PostSummaryRoll from '../../../../containers/StepsManager/components/StepsList/PostSummaryRoll';
import Avatar from '@material-ui/core/Avatar';
import { generateImageUrl, hasWhiteSpace } from '../../../../helpers';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import PreviewTags from '../../../../components/PreviewTags';
import { Linkify } from '../../../../components/common';
import * as timeago from 'timeago.js';
import { userActionActions, userActionSelectors } from '../../../../redux/userAction';
import { modalsActions } from '../../../../redux/modals';
import { noop } from 'lodash';

const locale = function (number, index) {
    // number: the time ago / time in number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
        ['just now', 'right now'],
        ['%s seconds ago', 'in %s seconds'],
        ['1 minute ago', 'in 1 minute'],
        ['%s minutes ago', 'in %s minutes'],
        ['1 hour ago', 'in 1 hour'],
        ['%s hours ago', 'in %s hours'],
        ['1 day ago', 'in 1 day'],
        ['%s days ago', 'in %s days'],
        ['1 week ago', 'in 1 week'],
        ['%s weeks ago', 'in %s weeks'],
        ['1 month ago', 'in 1 month'],
        ['%s months ago', 'in %s months'],
        ['1 year ago', 'in 1 year'],
        ['%s years ago', 'in %s years'],
    ][index];
};
timeago.register('pt_BR', locale);

const StepPreview = ({ mapId, stepId, onClick = noop, direction, onClickPost, onClickAddPost, setOpenProfile }) => {
    const dispatch = useDispatch();
    const step = useSelector(mapSelectors.steps(mapId))[stepId];
    const stepPreviewRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const tagsFilter = step?.components?.filter(component => component?.tags_preview);
    const ratingFilter = step?.components?.filter(component => component?.rating_subcomponents_preview);
    const tag_preview = tagsFilter?.[0]?.tags_preview;
    const ratings_subcomponents_preview = ratingFilter?.[0]?.rating_subcomponents_preview;
    const scoreRating = ratings_subcomponents_preview?.filter(item => item?.type === 'score1to5');
    const ratingsCount = scoreRating?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
    const openStepId = useSelector(userActionSelectors.openStepId);
    const [textPost, setTextPost] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        dispatch(mapActions.getFullStepData(mapId, stepId)).then(({ data }) => {
            if (data) {
                if (data?.step?.secondaries?.length > 0) {
                    const text = document.getElementById(`textPostPreview-${stepId}`);
                    if (text) {
                        setTextPost(text?.firstChild?.outerHTML);
                    }
                }
            }
        });
    }, [stepId]);

    useEffect(() => {
        if (step && !step.isShortData) setIsLoading(false);
    }, [step]);

    const stopEventPropagation = e => {
        e?.stopPropagation();
        e?.nativeEvent?.stopImmediatePropagation();
    };

    const toggleImagePreview = ({ imagePreviewShow, imagePreviewArray, imagePreviewIndex }) => {
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: imagePreviewShow,
                imagePreviewArray: imagePreviewArray,
                imagePreviewIndex: imagePreviewIndex,
            })
        );
    };

    return (
        <div ref={stepPreviewRef}>
            {isLoading || !step ? (
                <div style={{ padding: 15 }} className={styles.container}>
                    <div className={classNames([styles.title, 'animate'])} style={{ width: '360px', height: '30px' }} />
                    <div className={classNames([styles.tags, 'animate'])} style={{ marginLeft: 0, width: '200px', height: '20px' }} />
                    <div className={classNames([styles.retings, 'animate'])} style={{ width: '200px', height: '20px' }} />
                    <div className={classNames([styles.componentNamePreview, 'animate'])} style={{ width: '100px', height: '30px', margin: '14px 0 0 0' }} />
                    <div className={classNames([styles.postInfo, 'animate'])} style={{ width: '345px', height: '80px' }} />
                </div>
            ) : (
                <div className={styles.container}>
                    <div
                        className={styles.clickBlock}
                        onClick={e => {
                            stopEventPropagation(e);
                            onClick && onClick({ area: 'board' });
                        }}
                    />
                    <div className={classNames(styles.posts)}>
                        {step.posts_attachments_list?.posts_attachments?.length > 0 ? (
                            step.posts_attachments_list?.posts_attachments?.length === 1 ? (
                                <div className={styles.postBlockOneImage}>
                                    <div
                                        onClick={e => {
                                            stopEventPropagation(e);
                                            onClick({ area: 'board' });
                                        }}
                                        className={styles.clickBlockEmpty}
                                    />
                                    {step.posts_attachments_list?.posts_attachments.map((el, index) => (
                                        <img
                                            onClick={() =>
                                                toggleImagePreview({
                                                    imagePreviewShow: true,
                                                    imagePreviewIndex: index,
                                                    imagePreviewArray: step.posts_attachments_list?.posts_attachments,
                                                })
                                            }
                                            style={{ width: '207px' }}
                                            className={styles.img}
                                            src={generateImageUrl({
                                                imageId: step.posts_attachments_list?.posts_attachments[0].attachment?.image_id,
                                                reason: 'step_preview',
                                            })}
                                            alt={''}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div
                                    className={styles.postBlock}
                                    onClick={e => {
                                        e?.stopPropagation();
                                        e?.nativeEvent?.stopImmediatePropagation();
                                    }}
                                >
                                    <PostSummaryRoll
                                        // onClickPostPage={onClickPostPage}
                                        // onClickElement={onClickElement}
                                        stepPostsImage={step.posts_attachments_list?.posts_attachments}
                                    />
                                </div>
                            )
                        ) : (
                            <div className={styles.emptyParentPostBlock}>
                                <div
                                    onClick={e => {
                                        stopEventPropagation(e);
                                        onClick({ area: 'board' });
                                    }}
                                    className={styles.clickBlockPhoto}
                                />
                                <div className={styles.emptyPostBlock} onClick={() => onClick({ area: 'board', triggerAddImage: true })}>
                                    <div className={styles.ico}>
                                        <img src='/assets/img/steps3Images/addPhoto.svg' alt='' />
                                        <span>Add photo</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <span className={styles.title}>{step.title}</span>
                    {tag_preview && (
                        <div id={'tagBlock'} className={styles.tags}>
                            {tag_preview?.length > 0 && (
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <PreviewTags tagsPreview={tag_preview} stepElement={true} />
                                </div>
                            )}
                        </div>
                    )}
                    {step.secondaries?.length > 0 && (
                        <div className={styles.postsBlock}>
                            <>
                                <div className={styles.d} id={`textPostPreview-${step?._id}`}>
                                    <div>
                                        <Avatar
                                            alt={''}
                                            src={generateImageUrl({
                                                imageId: step?.secondaries?.[0]?.creator_preview?.image,
                                                width: 16,
                                                height: 16,
                                                reason: 'show_user_image',
                                            })}
                                            className={styles.img}
                                        />
                                        <div className={styles.name}>{step?.secondaries?.[0]?.creator_preview.display_name}</div>
                                        <Linkify>
                                            {step?.secondaries?.[0]?.text}
                                            {step?.secondaries?.[0]?.type === 'CREATED_BY' &&
                                                (step?.secondaries?.[0]?.text.length === 1
                                                    ? hasWhiteSpace(step?.secondaries?.[0]?.text)
                                                    : step?.secondaries?.[0]?.text.length === 0) && <span>Discovered this place!</span>}
                                        </Linkify>
                                    </div>
                                </div>
                                {textPost && (
                                    <HTMLEllipsis
                                        unsafeHTML={textPost}
                                        maxLine={3}
                                        ellipsisHTML={`<span style='opacity: 0.36;color: #0c0c0c;cursor: pointer;'> ...</span>`}
                                        lineheight='40'
                                        basedOn='letters'
                                        style={{
                                            overflowWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    />
                                )}
                            </>
                        </div>
                    )}
                    <div className={styles.componentNamePreview}>
                        {tag_preview && tag_preview?.length === 0 && (
                            <Grid xs={6} style={{ justifyContent: 'flex-start' }} container>
                                <div className={styles.option}>
                                    <span
                                        className={classNames(styles.addTags)}
                                        onClick={e => {
                                            stopEventPropagation(e);
                                            if (openStepId) {
                                                dispatch(userActionActions.setOpenTags(true));
                                            } else {
                                                onClick({ area: 'tags' });
                                            }
                                            mixpanel.track(
                                                mixpanelEvents.STEP_TAGS_EDIT_OPEN,
                                                { [mixpanelProperties.STEP_TITLE]: step?.title },
                                                { [mixpanelProperties.STEP_ID]: stepId },
                                                { [mixpanelProperties.FROM_WHERE]: 'map_preview_add' }
                                            );
                                        }}
                                    >
                                        Add Tags
                                    </span>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={3} style={{ justifyContent: 'flex-end' }} container>
                            {step['likes_count'] !== 0 && (
                                <SocialPostInfo className={classNames(styles.social)} number={step['likes_count']} type={socialPostInfoTypes.LIKES} />
                            )}
                        </Grid>
                        <Grid item xs={3} style={{ justifyContent: 'flex-end' }} container>
                            {step['posts_count'] !== 0 && (
                                <SocialPostInfo className={classNames(styles.social)} number={step['posts_count']} type={socialPostInfoTypes.POSTS} />
                            )}
                        </Grid>
                    </div>
                    {/*{step?.secondaries?.length > 0 &&
                            <div onClick={()=>onClickPost(step?.secondaries?.[0])} className={className(styles.postInfo)}>
                                <PostSummary el={step?.secondaries?.[0]} />
                            </div>
                        }*/}
                </div>
            )}
        </div>
    );
};

export default StepPreview;
