import React from 'react';
import { noop } from 'lodash';
import styles from './index.module.scss';
import { ImageSquare } from 'phosphor-react';
import copiesPrefix from '../../../../../copies.json';

const copies = copiesPrefix.stepProfile;

const EmptyImage = ({ onClickAddPost = noop }) => (
    <div className={styles.block} onClick={onClickAddPost}>
        <div className={styles.addImageBlock}>
            <ImageSquare className={styles.icon} size={22} color='#2171EC' />
            <span className={styles.text}>{copies.upload_photo}</span>
        </div>
    </div>
);

export default EmptyImage;
