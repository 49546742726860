import React from 'react';
import styles from './task.module.scss';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import copies from '../../copies.json';

const taskCopies = copies.insights.tasks.addMoreSteps;

const MoreStepsTask = ({ onClickHandle, done }) => {
    return (
        <div className={styles.container}>
            {done && <div className={styles.doneContainer} />}
            <div className={styles.header}>
                <span className={styles.title}>
                    {taskCopies.title}
                    {done && (
                        <span className={styles.done}>
                            <img src='/assets/img/steps3Images/insights/done.svg' />
                            {copies.insights.tasks.done}
                        </span>
                    )}
                </span>
                <span className={styles.description}>{taskCopies.description}</span>
            </div>
            <div className={styles.footer}>
                {!done && (
                    <CustomButton onClick={onClickHandle} className={styles.handleButton} type={ButtonType.PRIMARY_BLUE}>
                        {taskCopies.CTA}
                    </CustomButton>
                )}
            </div>
        </div>
    );
};

export default MoreStepsTask;
