import { success } from '@redux-requests/core';
import { GET_FEED_POSTS, ADD_ANNOUNCEMENT, SET_IS_CONFIRMED_ANNOUNCEMENT_POPOVER, RESET_FEED, SET_FEED_ANCHOR } from './constants';
import { DELETE_POST } from '../posts/types';
import { PostTypes } from '../../constants';
import { CREATE_POST } from '../map/types';

const initialState = {
    feedPostsIds: [],
    cursor: null,
    announcementId: null,
    isFeedLoading: false,
    isPostAdded: false,
    feedAnchorPosition: 0,
};

const Feed = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ANNOUNCEMENT: {
            const { announcement } = action.payload;

            if (!announcement) {
                return state;
            }

            return {
                ...state,
                announcementId: announcement._id,
                feedPostsIds: [announcement._id, ...state.feedPostsIds.filter(id => id !== announcement._id)],
            };
        }

        case RESET_FEED: {
            return initialState
        }

        case CREATE_POST: {
            return {
                ...state,
                isPostAdded: false,
            };
        }

        case success(CREATE_POST): {
            return {
                ...state,
                isPostAdded: true,
            };
        }

        case GET_FEED_POSTS: {
            return { ...state, isFeedLoading: !action.meta?.isQuiet };
        }

        case SET_FEED_ANCHOR: {
            return { ...state, feedAnchorPosition: action.payload.anchorPosition };
        }

        case success(GET_FEED_POSTS): {
            const hasCursor = action.meta?.cursor;
            const { posts, cursor } = action.response?.data;

            if (!posts) {
                return state;
            }

            return {
                ...state,
                cursor,
                announcementId: posts.find(post => post.type === PostTypes.ANNOUNCEMENT && post.is_pinned === true)?._id,
                feedPostsIds: hasCursor ? [...state.feedPostsIds, ...posts.map(post => post._id)] : posts.map(post => post._id),
                isFeedLoading: false,
            };
        }

        case DELETE_POST: {
            const { postId: postToRemoveId } = action.meta;
            const filteredFeed = state.feedPostsIds.filter(postId => postId !== postToRemoveId);

            return {
                ...state,
                feedPostsIds: filteredFeed,
            };
        }

        case SET_IS_CONFIRMED_ANNOUNCEMENT_POPOVER: {
            const { isConfirmed } = action.payload;
            return {
                ...state,
                isConfirmedAnnouncementPopover: isConfirmed,
            };
        }

        default:
            return state;
    }
};

export default Feed;
