const templates = [
    {
        name: 'Food',
        subCategories: [
            {
                name: 'Environment',
                tags: ['Outdoor seating', 'Indoor seating', 'Kid friendly', 'Accessible', 'Reservation needed', 'Live music', 'Date night'],
            },
            {
                name: 'Food Sensitivities / Dietary Restrictions',
                tags: ['Vegan', 'Gluten free', 'Keto friendly', 'Organic'],
            },
            {
                name: 'Type of place',
                tags: [
                    'Restaurant',
                    'Diner',
                    'Coffee shop',
                    'Food Truck',
                    'Bakery',
                    'Cocktail bar',
                    'Casual',
                    'Upscale',
                    'Brunch',
                    'Breakfast',
                    'Market',
                    'Desserts',
                    'Bar',
                ],
            },
            {
                name: 'Cuisine',
                tags: ['Pizza', 'Burgers', 'Ice cream', 'Bagels', 'Steak', 'Seafood', 'Sushi', 'Mexican', 'Italian'],
            },
            {
                name: 'Atmosphere / Ambiance',
                tags: ['Elegant', 'Relaxed', 'Luxurious', 'Fun & vibrant', 'Stylish', 'Small & cozy', 'Popular', 'Casual', 'Romantic'],
            },
        ],
    },
    {
        name: 'Art',
        subCategories: [
            {
                name: 'Place',
                tags: ['Gallery', 'Pop up', 'Museum', 'Exhibit', 'Street art'],
            },
            {
                name: 'School / Style',
                tags: ['Modern', 'Classic', 'Expressionism', 'Abstract', 'Impressionism', 'Pop art', 'Sculpture', 'Architecture'],
            },
        ],
    },
    {
        name: 'Lifestyle',
        subCategories: [
            {
                name: 'Type of place',
                tags: [
                    'Restaurant',
                    'Diner',
                    'Coffee shop',
                    'Food Truck',
                    'Bakery',
                    'Cocktail bar',
                    'Casual',
                    'Upscale',
                    'Brunch',
                    'Breakfast',
                    'Market',
                    'Desserts',
                    'Bar',
                ],
            },
            {
                name: 'Atmosphere / Ambiance',
                tags: ['Elegant', 'Relaxed', 'Luxurious', 'Fun & vibrant', 'Stylish', 'Small & cozy', 'Popular', 'Casual', 'Romantic'],
            },
            {
                name: 'Things to do',
                tags: ['Vinyard', 'Brewery', 'Museum', 'Park', 'Shopping', 'Dining', 'Spa', 'Accommodation', 'Kid friendly'],
            },
        ],
    },
    {
        name: 'Travel',
        subCategories: [
            {
                name: 'Activities',
                tags: ['Picnic', 'Bicycle trail', 'Hike', 'Diving', 'Snorkeling', 'Water sports', 'Fishing', 'Guided tour', 'Winter sports'],
            },
            {
                name: 'Season / Seasonal',
                tags: ['Spring', 'Summer', 'Autumn', 'Winter', 'All year'],
            },
            {
                name: 'Type of place',
                tags: ['Spring', 'Viewpoint', 'Lake', 'Beach', 'Waterfalls', 'Camping site', 'Park'],
            },
        ],
    },
];

export default templates;
