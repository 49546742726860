import React, { useState } from 'react';
import styles from './index.module.scss';
import { Check } from 'phosphor-react';
import classNames from 'classnames';
import copiesPrefix from '../../../../copies.json';

const copies = copiesPrefix.stepProfile;
const TAGS_LIMIT = 3;
const TOOLTIP_TAGS_LIMIT = 8;

const Tags = ({ tags, onClickMore, className }) => {
    const [showMoreTagsTooltip, setShowTooltip] = useState(false);

    const noTags = () => (
        <>
            <Check className={styles.emptyIcon} size={11} color='#fff' weight='bold' />
            <span className={styles.emptyTags}>{copies.no_tags}</span>
        </>
    );

    const renderShowMoreTags = (tags = []) => {
        const tagsLength = tags.length;
        if (!tagsLength) return;
        return (
            <div className={styles.showMoreTags} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                {showMoreTagsTooltip && (
                    <>
                        <div className={styles.tooltip}>
                            {tags.slice(0, TOOLTIP_TAGS_LIMIT).map(tag => (
                                <span className={styles.tagName}>{tag.name}</span>
                            ))}
                            {tagsLength > TOOLTIP_TAGS_LIMIT && (
                                <span className={classNames(styles.tagName, styles.showMore)} onClick={onClickMore}>
                                    {copies.view_all}
                                </span>
                            )}
                            <span className={styles.tooltipInvisibleArrow} />
                            <span className={styles.tooltipArrow} />
                        </div>
                    </>
                )}
                <span className={styles.showMoreCount}>+ {tagsLength}</span>
            </div>
        );
    };

    const renderTags = () => {
        const tagsToDisplay = tags.slice(0, TAGS_LIMIT);
        return (
            <>
                {tagsToDisplay.map(tag => (
                    <span className={styles.tag} key={tag._id}>
                        <Check className={styles.icon} size={11} color='#fff' weight='bold' />
                        <span className={styles.tagName}>{tag.name}</span>
                    </span>
                ))}
                {tags.length > TAGS_LIMIT && renderShowMoreTags(tags.slice(TAGS_LIMIT, tags?.length))}
            </>
        );
    };

    return (
        <div className={classNames(styles.block, className)}>
            <div className={styles.tags}>{tags?.length > 0 ? renderTags() : noTags()}</div>
            <button className={styles.showMoreButton} onClick={onClickMore}>
                {tags?.length > 0 ? copies.edit : copies.tag}
            </button>
        </div>
    );
};

export default Tags;
