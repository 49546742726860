import React from 'react';
import styles from './index.module.scss';
import CustomButton, { ButtonType, ButtonSize } from '../common/buttons/CustomButton';
import copiesPrefix from '../../copies.json';

const copies = copiesPrefix.mapManager;

const EmptyMapList = ({ onClickAddPost }) => {
    return (
        <div className={styles.content}>
            <p className={styles.bold}>{copies.emptyState.empty_map}</p>
            <div className={styles.buttonBlock}>
                <CustomButton onClick={onClickAddPost} size={ButtonSize.MEDIUM} type={ButtonType.SECONDARY_BLUE} text={copies.emptyState.add_first_step} />
            </div>
            <img src={'/assets/img/emptyStep.svg'} alt={''} />
        </div>
    );
};

export default EmptyMapList;
