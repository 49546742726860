import React, { useState, useEffect, useCallback, useMemo, useLayoutEffect } from 'react';
import { mapTagObjects } from '../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { userSelectors } from '../../redux/user';
import { mapSelectors, mapActions } from '../../redux/map';
import { stepActions } from '../../redux/step';
import { useNavigate, useParams, useOutletContext, generatePath, useSearchParams } from 'react-router-dom';
import { generateImageUrl, isStepTypeOf } from '../../helpers';
import { useModal } from '../../hooks';
import { Engagements, StepProfileHeader, cancelButtonType, Ratings, Tags, AddPostButton, EmptyImages, Posts } from './components';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import ImageCarousel from '../../lib/ImageCarousel';
import RatingsModal from '../common/modals/ModalsRating';
import { PlaceInfo } from '../PlaceInfo';
import ArchiveModal from '../ArchivedStepPopup';
import ROUTES from '../../config/routes';
import { StepTypes } from '../../constants';
import ShareModal from '../ShareMapPopUp';
import TagsModal from '../common/modals/ModalsSelectTags';
import ChangeLocationModal from '../common/modals/ModalsChangeLocation';
import Skeleton from './Skeleton';
import classNames from 'classnames';
import { modalsActions } from '../../redux/modals';
import { CreateNewPostModal } from '../Feed/FeedPost';
import { PostTypes } from '../../constants';
import Routes from '../../config/routes';
import { AddPlaceSteps } from '../AddStep';

import styles from './index.module.scss';

const modalTypes = {
    info: 'info',
    tags: 'tags',
    ratings: 'ratings',
    archive: 'archive',
    share: 'share',
    location: 'location',
};

const postsScrollTarget = 'stepProfileContent';

const StepProfile = ({ stepId: id = '', onBack: onBackProp, onClickPost: onClickPostProp, viewOnMap = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setIsMapClean } = useOutletContext();
    const { modalOpen: openedModalType, setModalOpen: setModal } = useModal();

    const stepId = useParams().stepId || id;
    const [serachParams] = useSearchParams();
    const openTagsModal = serachParams.get('openTagsModal') || false;
    const [modalOptions, setModalOptions] = useState({}); // TODO: move to use modal
    const [loading, setLoading] = useState(false);
    const mapId = useSelector(userSelectors.selectedMapId);
    const step = useSelector(mapSelectors.steps(mapId))[stepId];
    const map = useSelector(mapSelectors.map(mapId));
    const user = useSelector(userSelectors.user);
    const tagsObject = step?.components?.find(component => component.id === 'selected_tags')?.tags;
    const selectedTags = tagsObject?.filter(tag => tag.is_selected);
    const ratingsObject = step?.components?.find(component => component.id === 'rating');
    let ratingSubComponents = ratingsObject?.rating_subcomponents?.filter(rating => rating.average > 0) || [];
    const stepAttachments = step?.posts_attachments_list?.posts_attachments;
    // TODO: remove TagsModal variables
    const mapTagsObject = useSelector(mapSelectors.tag_objects(map?._id));
    let modalTagsObject = mapTagObjects(mapTagsObject, map?.tags_categories);
    const selectedModalTags = { tags: selectedTags || [], stepId: [stepId], stepCreatorId: '', stepTitle: step?.title };
    const isArchived = step?.archived;

    const fetchData = async () => {
        setLoading(true);
        await dispatch(mapActions.getFullStepData(mapId, stepId));
        setLoading(false);
    };

    useLayoutEffect(() => {
        if (openTagsModal) toggleModal(modalTypes.tags);
        fetchData();
    }, []);

    const onImageClick = index => {
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: true,
                imagePreviewArray: stepAttachments,
                imagePreviewIndex: index,
                stepId: stepId,
            })
        );
    };

    useLayoutEffect(() => {
        (async () => {
            // TODO: Remove when backend support userPreview in GetStep
            if (step && !step?.user_preview && step.creator_id) {
                setLoading(true);
                await dispatch(mapActions.getStepCreatorData(mapId, stepId, step.creator_id));
                setLoading(false);
            }
        })();
    }, [step, stepId]);

    useLayoutEffect(() => {
        if (!step || !step?.creator_id) {
            return;
        }

        (async () => {
            setLoading(true);
            await dispatch(mapActions.getStepCreatorData(mapId, stepId, step.creator_id));
            setLoading(false);
        })();
    }, [user.display_name]);

    const generateStepImages = useMemo(() => {
        return stepAttachments?.map(image => {
            return generateImageUrl({
                imageId: image.attachment.image_id,
                rotate: true,
                width: 245,
                height: 184,
                reason: 'generate_step_image',
            });
        });
    }, [stepAttachments]);

    const toggleModal = modalType => {
        if (!openedModalType) {
            setModal(modalType);
        } else {
            setModal(null);
            setModalOptions({});
        }
    };

    const changeSelectedTags = useCallback(
        (item = null, tagsToDelete = [], tagsToAdd = []) => {
            return dispatch(mapActions.bulkAddTags(mapId, selectedModalTags?.stepId, tagsToAdd, tagsToDelete, item));
        },
        [map?._id, selectedModalTags?.stepId]
    );

    const onClickRestore = () => {
        dispatch(stepActions.restoreStep(stepId, mapId));
    };

    const onNewPostUpload = async post => {
        setModal(null);
        setModalOptions({});
        fetchData();

        if (post.text) {
            mixpanel.track(mixpanelEvents.ADD_POST_ADD_TEXT, {
                [mixpanelProperties.STEP_NAME]: step?.title,
                [mixpanelProperties.STEP_ID]: stepId,
                [mixpanelProperties.MAP_ID]: mapId,
                [mixpanelProperties.MAP_NAME]: map?.name,
                [mixpanelProperties.USER_ID]: user?.id,
                [mixpanelProperties.USER_NAME]: user?.name,
            });
        }
    };

    const onNewPostImageUpload = () => {
        mixpanel.track(mixpanelEvents.ADD_IMAGE_UPLOAD, {
            [mixpanelProperties.STEP_NAME]: step?.title,
            [mixpanelProperties.STEP_ID]: stepId,
            [mixpanelProperties.MAP_NAME]: map?.name,
            [mixpanelProperties.USER_ID]: user?.id,
            [mixpanelProperties.USER_NAME]: user?.name,
            [mixpanelProperties.FROM_WHERE]: 'step_page',
        });
    };

    const onClickAddImageButton = () => {
        mixpanel.track(mixpanelEvents.ADD_IMAGE_CLICK, {
            [mixpanelProperties.STEP_NAME]: step?.title,
            [mixpanelProperties.STEP_ID]: stepId,
            [mixpanelProperties.MAP_NAME]: map?.name,
            [mixpanelProperties.USER_ID]: user?.id,
            [mixpanelProperties.USER_NAME]: user?.name,
            [mixpanelProperties.FROM_WHERE]: 'step_page',
        });
    };
    const onClickEditLocation = step => {
        dispatch(
            mapActions.setMapCenter(mapId, {
                position: [step.lat, step.lon || step.lng],
                zoom: 15,
            })
        );
        navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`, { state: { flowStepId: AddPlaceSteps.EDIT_STEP_DETAILS, step } });
        setIsMapClean(true);
    };

    const onClickArchive = () => {};
    const onClickPost = postId => {
        if (onClickPostProp) onClickPostProp(postId);
        else navigate(generatePath(`/${ROUTES.MAP}/${ROUTES.POST_PAGE}`, { postId }));
    };
    const onClickPrev = () => {
        if (onBackProp) {
            onBackProp();
            return;
        }

        dispatch(mapActions.setGhostStep(mapId, null));
        navigate(-1);
    };

    if (!step || step?.isShortData || loading) {
        return <Skeleton viewOnMap={viewOnMap} />;
    }

    return (
        <>
            <div role='dialog' aria-labelledby='Step Profile' className={classNames(styles.block, { [styles.noPointerEvents]: isArchived })}>
                {isArchived && <div className={styles.blur} />}
                <StepProfileHeader
                    className={styles.header}
                    title={step?.title}
                    address={step?.geocode_address?.formatted_address || step?.address || step.hpaw?.address}
                    businessStatus={step?.hpaw?.business_status}
                    stepIndications={step?.indications}
                    stepCreator={step?.user_preview}
                    isArchived={isArchived}
                    isNew={isStepTypeOf(StepTypes.NEW, step)}
                    onClickRestore={onClickRestore}
                    onClickInfo={() => toggleModal(modalTypes.info)}
                    onClickShare={() => toggleModal(modalTypes.share)}
                    onClickEditLocation={onClickEditLocation ? () => onClickEditLocation(step) : null}
                    onCancel={onClickPrev}
                    onClickArchive={() => {
                        onClickArchive();
                        toggleModal(modalTypes.archive);
                    }}
                    onClickMapView={viewOnMap ? () => toggleModal(modalTypes.location) : null}
                    cancelIcon={viewOnMap ? cancelButtonType.X_BUTTON : cancelButtonType.BACK_BUTTON}
                />
                <div className={styles.content} id={postsScrollTarget}>
                    {stepAttachments?.length ? (
                        <ImageCarousel
                            classNameDiv={styles.imageContainer}
                            classNameImage={styles.image}
                            images={generateStepImages}
                            settings={{
                                slidesToShow: 1.95,
                                slidesToScroll: 1,
                            }}
                            onImageClick={onImageClick}
                        />
                    ) : (
                        <EmptyImages className={styles.imageContainer} onClickAddPost={() => setModal(modalTypes.addPost)} />
                    )}
                    <Engagements
                        className={styles.engagements}
                        stepId={stepId}
                        stepTitle={step?.title}
                        isLiked={step?.is_liked_by_user}
                        postsCount={step?.posts_count}
                        viewsCount={step?.views_count}
                        likesCount={step?.likes_count}
                    />
                    <Tags className={styles.tags} tags={selectedTags} onClickMore={() => toggleModal(modalTypes.tags)} />
                    {/* <Ratings
                          className={styles.ratings}
                          selectedStars={Math.round(ratingSubComponents[0]?.average)}
                          ratingTitle={ratingSubComponents[0]?.text}
                          ratingsCount={ratingsObject?.amount}
                          onClickMore={() => toggleModal(modalTypes.ratings)}
                      /> */}
                    <AddPostButton
                        className={styles.addPostButton}
                        onClickAddPost={({ triggerAddImage }) => {
                            setModal(modalTypes.addPost);
                            setModalOptions({ triggerAddImage });
                        }}
                    />
                    <Posts
                        usernameHeadlineOnly
                        className={styles.posts}
                        stepId={stepId}
                        onClickPost={onClickPost}
                        scrollTarget={postsScrollTarget}
                        onPinPostChange={fetchData}
                    />
                </div>
            </div>
            {openedModalType === modalTypes.addPost && (
                <CreateNewPostModal
                    isOpen
                    onClose={() => {
                        setModal(null);
                        setModalOptions({});
                    }}
                    onSuccess={onNewPostUpload}
                    onUploadImage={onNewPostImageUpload}
                    onClickAddImage={onClickAddImageButton}
                    stepId={stepId}
                    hasAnnouncement={false}
                    openWithImageUpload={modalOptions.triggerAddImage}
                    postType={PostTypes.STEP}
                />
            )}
            {openedModalType === modalTypes.info && (
                <PlaceInfo
                    key={`infoModal${stepId}`}
                    stepId={stepId}
                    mapId={mapId}
                    onPrevClick={toggleModal}
                    onUpdateSuccess={() => {
                        fetchData();
                    }}
                />
            )}
            {openedModalType === modalTypes.ratings && <RatingsModal step={step} onClickPrev={toggleModal} />}
            {openedModalType === modalTypes.archive && (
                <ArchiveModal
                    handleBack={toggleModal}
                    mapId={mapId}
                    setDeleteIdStep={() => {}}
                    stepId={step?._id}
                    stepTitle={step?.title}
                    handleArchivePopup={() => {
                        onClickPrev();
                        toggleModal();
                    }}
                />
            )}
            {openedModalType === modalTypes.tags && (
                <TagsModal
                    fromWhere={'step_page'}
                    selectedModalTags={selectedModalTags}
                    pendingSelectedTags={selectedModalTags.tags}
                    setPendingSelectedTags={async (item, tagsToDelete, tagsToAdd) => {
                        await changeSelectedTags(item, tagsToDelete, tagsToAdd);
                        // TODO: a workaround to update tags - there was a mutation in the reducer which
                        // was way to complex to fix for the release, so we just refetch the step.
                        // We need a fix in the reducer or in this component.
                        fetchData();
                    }}
                    map={map}
                    tagsObject={modalTagsObject}
                    onCancel={toggleModal}
                />
            )}
            {openedModalType === modalTypes.share && (
                <ShareModal selectedStep={step} setSharePopup={toggleModal} shareWhat={'step'} shareStep={'shareStep'} fromWhere={'step_profile'} />
            )}
            {openedModalType === modalTypes.location && <ChangeLocationModal stepId={stepId} infoLocation={step} onCancel={toggleModal} />}
        </>
    );
};

export default StepProfile;
