import WhatIsAStepTooltip from '../../WhatIsAStepTooltip';
import styles from './index.module.scss';

const STEPS_ON_MAP_ONLY = 'Head over to the Map Manager to view and manage your map.';
const STEPS_ONLY_IN_PENDING = 'Head over to the “Pending steps” page in the Map Manager to add these steps to your map!';

const getContent = (addedStepsCount, waitingStepsCount) => {
    if (addedStepsCount && !waitingStepsCount) return <p>{STEPS_ON_MAP_ONLY}</p>;
    else if (waitingStepsCount && !addedStepsCount) return <p>{STEPS_ONLY_IN_PENDING}</p>;
    else
        return (
            <>
                <p>{waitingStepsCount} more are awaiting your approval on the "Pending steps" page in the Map Manager.</p>
                <p>Head over there now to add them to your map!</p>
            </>
        );
};

const getTitle = (addedStepsCount, waitingStepsCount, mapName) => {
    if (waitingStepsCount && !addedStepsCount) return <span className={styles.bold}>{waitingStepsCount} steps were imported to Steps</span>;
    else
        return (
            <span className={styles.bold}>
                {addedStepsCount} steps were added to your {mapName} map.
            </span>
        );
};

const FinalScreenDesktop = ({ type, mapName, addedStepsCount, waitingStepsCount }) => {
    return (
        <>
            <p className={styles.bigTitle}>
                {type ? 'Woohoo! ' : 'Congrats!'}
                <img src='/assets/img/steps3Images/smile.png' alt='' />
            </p>
            <p className={styles.congratsText}>
                {type ? getTitle(addedStepsCount, waitingStepsCount, mapName) : 'You added the first Step to your map!'}{' '}
                <WhatIsAStepTooltip>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <div className={styles.tooltipBlock}>
                            <p style={{ marginBottom: 0 }}>A Step is a place in the real world that you add to your Map.</p>
                            <p style={{ marginBottom: 14 }}>
                                You can post your own unique content about each Step, including reviews and photos, and others who join your Map can view and
                                comment on your Steps.
                            </p>
                            <span>Got It</span>
                        </div>
                    </div>
                </WhatIsAStepTooltip>
            </p>
            <p className={styles.secondCongratsText}>{type ? getContent(addedStepsCount, waitingStepsCount) : 'Keep adding more Steps.'}</p>
        </>
    );
};

export default FinalScreenDesktop;
