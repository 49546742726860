import { useState, useCallback, useEffect } from 'react';
import { createPortal, unmountComponentAtNode } from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

const isTestEnv = process.env.NODE_ENV === 'test' || process.env.STORYBOOK_ENV === 'test';

const FallbackComponent = ({ children } = {}) => {
    return <span>{children}</span>;
};

const usePortal = elementQuerySelector => {
    const element = document.querySelector(elementQuerySelector) || document.querySelector('#root');
    const [portal, setPortal] = useState({
        render: () => null,
        remove: () => null,
    });

    const createPortalCallback = useCallback(element => {
        if (!element && !isTestEnv) {
            return;
        }

        const Portal = ({ children }) => {
            if (isTestEnv) {
                return children;
            }

            return createPortal(children, element);
        };

        const remove = () => {
            element && unmountComponentAtNode(element);
        };

        const PortalWithError = ({ children }) => {
            return (
                <ErrorBoundary
                    fallback={children}
                    onError={(error, info) => {
                        console.error(error, info);
                    }}
                >
                    <Portal>{children}</Portal>
                </ErrorBoundary>
            );
        };

        return { render: PortalWithError, remove };
    }, []);

    useEffect(() => {
        if (element && !isTestEnv) {
            portal.remove();
        }

        const newPortal = createPortalCallback(element);
        if (!newPortal) {
            return FallbackComponent;
        }

        setPortal(newPortal);
        return () => {
            if (isTestEnv) {
                return;
            }

            newPortal.remove(element);
        };
    }, [element]);

    return portal.render;
};

export default usePortal;
