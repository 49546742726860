export const LOGIN = 'LOGIN';
export const REGISTRATION = 'REGISTRATION';
export const RESOLVE_IP = 'RESOLVE_IP';
export const SET_SELECTED_MAP_ID = 'SET_SELECTED_MAP_ID';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const USER_DATA = 'USER_DATA';
export const SET_USER_AUTH = 'SET_USER_AUTH';
export const MESSAGE_REQUEST = 'MESSAGE_REQUEST';
export const SET_MAPS = 'SET_MAPS';
export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const SET_USER_COVER_IMAGE = 'SET_USER_COVER_IMAGE';
export const CREATE_MAP = 'CREATE_MAP';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
