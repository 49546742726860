// TODO: This is too much, we need to simplify this.
// plus we don't even have environment variables for all environments, so most is just hardcoded in disguise
const config = {
  api: {
    url:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_BACKEND_URL || "http://localhost:3001/api"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_BACKEND_URL || "http://localhost:3001/api"
          : process.env.REACT_APP_STEPS_API_URL || "http://localhost:3001/api"
  },
  domain: {
    addressAlt: "https://dev2.steps.me/dev",
    address:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_DOMAIN || "https://api.steps.me/v1"
        : process.env.REACT_APP_DOMAIN === "test"
          ? process.env.REACT_APP_DOMAIN || "https://dev.steps.me/dev"
          : process.env.REACT_APP_DOMAIN || "https://dev.steps.me/dev",
    embed:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_STEPS_PROD_URL || "https://embed.steps.me"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_STEPS_TEST_URL || "https://devembed.steps.me"
          : process.env.REACT_APP_STEPS_URL || "https://devembed.steps.me",
    web:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_STEPS_PROD_URL || "https://web.atly.com"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_STEPS_TEST_URL || "https://devweb.atly.com"
          : process.env.REACT_APP_STEPS_URL || "https://devweb.atly.com"
  },
  site: {
    address:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? "https://mapmanager.atly.com"
        : process.env.REACT_APP_STEPS_DOMAIN === "test"
          ? "https://devdashboard.atly.com"
          : "https://devdashboard.atly.com"
  },
  version: {
    stage: process.env.REACT_APP_STEPS_ENV === "production" ? "P" : "D",
    // IMPORTANT: Update major/minor/patch ONLY. CI/CD handles the path & build properties.
    major: 3,
    minor: 19,
    patch: 1,
    // Do not change these values. CI/CD handles these.
    path: "3.19.1.1719P",
    build: 0
  },
  social: {
    facebookClientId:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_FACEBOOK_CLIENT_ID || "616723192244212"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_FACEBOOK_CLIENT_ID || "616723192244212"
          : process.env.REACT_APP_FACEBOOK_CLIENT_ID || "616723192244212",
    instagramClientId:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_STEPS_PROD_INSTAGRAM_CLIENT_ID || "843725939570887"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_STEPS_TEST_INSTAGRAM_CLIENT_ID || "519469029265067"
          : process.env.REACT_APP_STEPS_INSTAGRAM_CLIENT_ID || "519469029265067",
    googleClientId:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID || "975459575100-16okgh4tm7u1nl3d90856ddbm9v9a0qs.apps.googleusercontent.com"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_GOOGLE_CLIENT_ID || "975459575100-im1bkungfa2mvqska8l8tls7u0s42cou.apps.googleusercontent.com"
          : process.env.REACT_APP_GOOGLE_CLIENT_ID || "975459575100-im1bkungfa2mvqska8l8tls7u0s42cou.apps.googleusercontent.com",
    appleClientId:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_APPLE_CLIENT_ID || "com.atly.mapmanager"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_APPLE_CLIENT_ID || "com.atly.devdashboard"
          : process.env.REACT_APP_APPLE_CLIENT_ID || "com.atly.devdashboard"
  },
  aws: {
    secret:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_AWS_SECRET_KEY || "O7OeGd9Yj1mQLYCbzQgik1n3rB1nXwg8wN2JjZz6"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_AWS_SECRET_KEY || "O7OeGd9Yj1mQLYCbzQgik1n3rB1nXwg8wN2JjZz6"
          : process.env.REACT_APP_AWS_SECRET_KEY || "O7OeGd9Yj1mQLYCbzQgik1n3rB1nXwg8wN2JjZz6",
    key:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_AWS_KEY || "AKIAR4R7TH64G5QKAQNQ"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_AWS_KEY || "AKIAR4R7TH64G5QKAQNQ"
          : process.env.REACT_APP_AWS_KEY || "AKIAR4R7TH64G5QKAQNQ",
    s3region:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_AWS_S3_REGION || "us-east-2"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_AWS_S3_REGION || "us-east-2"
          : process.env.REACT_APP_AWS_S3_REGION || "us-east-2",
    triggerPrefix:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_FUNCTION_TRIGGER_PREFIX || "steps-api-dev-mobile"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_FUNCTION_TRIGGER_PREFIX || "steps-api-dev-mobile"
          : process.env.REACT_APP_FUNCTION_TRIGGER_PREFIX || "steps-api-dev-mobile"
  },
  map: {
    googleMapKey:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_GOOGLE_MAP_PROD_API_KEY || "AIzaSyDYvCThcZPz6H2f8qcOuDoAfV7JS2mpCY4"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_GOOGLE_MAP_TEST_API_KEY || "AIzaSyDYvCThcZPz6H2f8qcOuDoAfV7JS2mpCY4"
          : process.env.REACT_APP_GOOGLE_MAP_API_KEY || "AIzaSyDYvCThcZPz6H2f8qcOuDoAfV7JS2mpCY4"
  },
  mixpanel: {
    token:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_MIXPANEL_TOKEN || "077ff37ea1f556ace8f37a5754faca1c"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_MIXPANEL_TOKEN || "72123733c2cb16af4962b13a8c0c1243"
          : process.env.REACT_APP_MIXPANEL_TOKEN || "72123733c2cb16af4962b13a8c0c1243"
  },
  splitio: {
    authorizationKey:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_STEPS_PROD_SPLITIO_KEY || "v9jnurui8pl6fl71cnull1f13m6lirre34gi"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_STEPS_TEST_SPLITIO_KEY || "rcimu88il34jc63tjoh95oro0nhrboqn4l49"
          : process.env.REACT_APP_STEPS_SPLITIO_KEY || "rcimu88il34jc63tjoh95oro0nhrboqn4l49"
  },
  logrocket: {
    key:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_LOGROCKET_TOKEN || "v9uynn/steps"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_LOGROCKET_TOKEN || "v9uynn/steps-dev"
          : process.env.REACT_APP_TEST_LOGROCKET_TOKEN || "v9uynn/steps-dev"
  },
  branch: {
    key:
      process.env.REACT_APP_STEPS_ENV === "production"
        ? process.env.REACT_APP_PROD_BRANCH_KEY || "key_live_lnS6lLZQ42dahHSK3DNv4gjpAubqgRM4"
        : process.env.REACT_APP_STEPS_ENV === "test"
          ? process.env.REACT_APP_TEST_BRANCH_KEY || "key_test_mgM9pR4R59bbjJIOYzK6AlbaqxmFmHXb"
          : process.env.REACT_APP_BRANCH_KEY || "key_test_mgM9pR4R59bbjJIOYzK6AlbaqxmFmHXb"
  },
  mapbox: {
    user_name: "stepsprod",
    style_id: "ckncu0enk1tdx17n0ruk0rz5k",
    access_token: "pk.eyJ1Ijoic3RlcHNwcm9kIiwiYSI6ImNrbW5va2Y0bTB5eGEycXJ6aTVnYnh2aXMifQ.ybxcnXuaQ4WB00KuJdvuWg"
  }
};

module.exports = config;
