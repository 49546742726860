import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import { PostTypes, ScreenNames, PlatformTypes } from '../../constants';
import { postSelectors, postActions } from '../../redux/post';
import { mapSelectors } from '../../redux/map';
import { userSelectors } from '../../redux/user';

// TODO: a general mixpanel hook
const useMixpanel = ({ mapId, announcementId }) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.user);
    const map = useSelector(mapSelectors.mapSelector(mapId));
    const isSeenAnnouncement = useSelector(postSelectors.isSeenAnnouncement({ userId: user.id, postId: announcementId }));
    const [isTracked, setIsTracked] = useState(false);

    useEffect(() => {
        if (!announcementId || isSeenAnnouncement || isTracked) {
            return;
        }

        mixpanel.track(mixpanelEvents.ADD_POST_VIEW, {
            [mixpanelProperties.POST_ID]: announcementId,
            [mixpanelProperties.USER_ID]: user.id,
            [mixpanelProperties.MAP_ID]: mapId,
            [mixpanelProperties.MAP_NAME]: map.name,
            [mixpanelProperties.USER_NAME]: user.name,
            [mixpanelProperties.SCREEN]: ScreenNames.MAP_PROFILE_FEED,
            [mixpanelProperties.PLATFORM]: PlatformTypes.MAP_MANAGER,
            [mixpanelProperties.POST_TYPE]: capitalize(PostTypes.ANNOUNCEMENT),
        });
        setIsTracked(true);
        dispatch(postActions.seenAnnouncement({ userId: user.id, postId: announcementId }));
    }, [announcementId]);
};

export default useMixpanel;
