import { createContext, useState, useEffect } from 'react';
import { noop } from 'lodash';
import { getSelectionData } from '../utils';

const TextSelectionContext = createContext({
    setSelectionData: noop,
    selectionData: null,
});

// To eliminate multiple document listeners
const TextSelectionProvider = ({ children }) => {
    const [selectionData, setSelectionData] = useState({});

    const handleSelectionData = () => {
        const data = getSelectionData();
        setSelectionData(data);
    };

    useEffect(() => {
        document.addEventListener('click', handleSelectionData);

        return () => {
            document.removeEventListener('click', handleSelectionData);
        };
    }, []);

    return <TextSelectionContext.Provider value={{ selectionData, setSelectionData }}>{children}</TextSelectionContext.Provider>;
};

export { TextSelectionContext, TextSelectionProvider };
