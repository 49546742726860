import React, { useState } from 'react';
import styles from '../index.module.scss';
import { X, Trash } from 'phosphor-react';

export const DiscardButtonTypes = {
    X: 'x',
    TRASH: 'trash',
};

const DiscardButton = ({ discardButtonType = DiscardButtonTypes.X, onClickDiscard }) => {
    const [discardButtonHover, setDiscardButtonHover] = useState(false);

    const discardButtonProps = {
        className: styles.xIcon,
        size: 16,
        color: discardButtonHover ? '#3A3C3F' : '#B5B7BB',
        onClick: e => {
            e.stopPropagation();
            onClickDiscard();
        },
        onMouseEnter: () => setDiscardButtonHover(true),
        onMouseLeave: () => setDiscardButtonHover(false),
        'data-testid': 'discard-button',
    };

    const renderDiscardButton = props => {
        const discardButton = {
            [DiscardButtonTypes.X]: <X {...props} />,
            [DiscardButtonTypes.TRASH]: <Trash {...props} />,
        };
        return discardButton[discardButtonType];
    };

    return renderDiscardButton(discardButtonProps);
};

export default DiscardButton;
