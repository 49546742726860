import { Navigate, Outlet } from "react-router";
import Loader from "../layouts/Loader";

const AUTH_STATUS = {
  PENDING: -1,
  LOGGED_OUT: 0,
  LOGGED_IN: 1
};

const RequireAuth = ({ userAuth }) => {
  if (userAuth === AUTH_STATUS.PENDING) {
    return <Loader />;
  }

  if (userAuth === AUTH_STATUS.LOGGED_OUT) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default RequireAuth;