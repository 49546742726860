import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { userActions, userSelectors } from '../../../redux/user';
import { ClickAwayListener } from '@material-ui/core';
import { postActions } from '../../../redux/post';
import { REQUEST_NOTIFICATIONS } from '../../../config/constants';
import classNames from 'classnames';
import IconError from '../icons/IconError';

const RequestMessage = ({ setDataRequest, dataRequest, importFile }) => {
    const dispatch = useDispatch();
    const message = useSelector(userSelectors.requestMessageSelector);

    const closeNotification = () => {
        dispatch(userActions.setMessageRequest());
        setDataRequest && setDataRequest({});
    };

    useEffect(() => {
        setTimeout(() => {
            closeNotification();
        }, 5000);
    }, [message]);

    const againRequest = () => {
        dispatch(
            userActions.setMessageRequest({
                text: REQUEST_NOTIFICATIONS.POSTING,
                status: 'waiting',
                img: REQUEST_NOTIFICATIONS.IMG_WAITING,
            })
        );
        dispatch(
            postActions.callRequestAgain({
                type: dataRequest?.type,
                method: dataRequest?.method,
                url: dataRequest?.url,
                data: dataRequest?.data,
                meta: dataRequest?.meta,
            })
        ).then(({ data }) => {
            if (data) {
                dispatch(
                    userActions.setMessageRequest({
                        text: dataRequest.text.success,
                        status: 'success',
                        img: REQUEST_NOTIFICATIONS.IMG_SUCCESS,
                    })
                );
                setTimeout(() => {
                    dispatch(userActions.setMessageRequest());
                    setDataRequest && setDataRequest({});
                }, 2500);
            } else {
                dispatch(
                    userActions.setMessageRequest({
                        text: dataRequest.text.error,
                        status: 'error',
                        img: REQUEST_NOTIFICATIONS.IMG_ERROR,
                    })
                );
            }
        });
    };

    if (!message || !message.status) return null;

    return (
        <ClickAwayListener onClickAway={() => closeNotification()}>
            <div onClick={dataRequest && Object?.keys(dataRequest)?.length > 0 ? () => againRequest() : null} className={styles.requestInfo_position}>
                <div
                    className={classNames(styles.requestInfo_block, {
                        [styles.importFile]: importFile,
                    })}
                >
                    {message?.img && (
                        <>
                            {importFile ? (
                                <IconError />
                            ) : (
                                <div className={message?.status === 'waiting' ? styles.requestInfo_waiting : styles.requestInfo_img}>
                                    <img src={message?.img} alt='' />
                                </div>
                            )}
                        </>
                    )}
                    <div className={styles.requestInfo_text}>
                        <p
                            className={
                                message?.status === 'success'
                                    ? styles.titleSuccess
                                    : message?.status === 'waiting'
                                    ? styles.titleWaiting
                                    : importFile
                                    ? styles.titleErrorUpload
                                    : styles.titleError
                            }
                        >
                            {message?.text}
                        </p>
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default RequestMessage;
