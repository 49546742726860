import classNames from 'classnames';
import { IconLoader } from '../../common/icons';
import copies from '../../../copies.json';

import styles from './LoadingPostsBlocks.module.scss';

const LoadingBlock = () => {
    return (
        <h4 className={classNames(styles.block, styles.loader)}>
            <span className={styles.icon}>
                <IconLoader />
            </span>
            <span>{copies?.feed?.blocks?.loading}</span>
        </h4>
    );
};

export default LoadingBlock;
