import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import Checkbox from '../../../../../../../components/common/Checkbox';
import { SelectModes } from '../../../../../../../constants';
import SortArrows from '../../../../../../../components/common/SortArrows';
import noop from 'lodash';

export const headerAlign = {
    LEFT: 'left',
    CENTER: 'center',
};

export const headerSort = {
    ASC: 'asc',
    DESC: 'desc',
};

const Header = ({ title, onClick = noop, align = headerAlign.CENTER, sortAble = false, orderBy, onClickSelectAll = noop, selectMode }) => {
    const selectAllClick = e => {
        e.stopPropagation();
        onClickSelectAll();
    };

    return (
        <th>
            <div className={classNames(styles.header, { [styles.sortAble]: sortAble, [styles.alignLeft]: align === headerAlign.LEFT })} onClick={onClick}>
                {onClickSelectAll && (
                    <Checkbox
                        className={styles.selectAll}
                        onClick={e => selectAllClick(e)}
                        unSelect={selectMode === SelectModes.UNSELECT}
                        checked={selectMode}
                    />
                )}
                <span className={styles.title}>
                    {title}
                    {sortAble && <SortArrows className={styles.sort} sort={orderBy} size={4} />}
                </span>
            </div>
        </th>
    );
};

export default Header;
