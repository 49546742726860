import { useState } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { IconInfo, IconCheck } from '../../icons';
import { PopperTooltip } from '../../Tooltips';
import styles from './Checkbox.module.scss';

const Checkbox = ({ id, initialState, label, showCheckboxTooltip, checkboxTooltip: CheckboxTooltip, tooltip, disabled, onChange = noop }) => {
    const [isChecked, setIsChecked] = useState(initialState || false);

    const iconCheck = <span className={styles.checkboxIcon}>{isChecked && !disabled && <IconCheck width={12} height={8} />}</span>;

    const Tooltip = () => {
        if (!showCheckboxTooltip) {
            return iconCheck;
        }
        return <CheckboxTooltip isClicked={isChecked}>{iconCheck}</CheckboxTooltip>;
    };

    return (
        <div
            id={id}
            data-testid={id}
            className={classNames({ [styles.checkbox]: true, [styles.disabled]: disabled })}
            onClick={() => {
                onChange(disabled ? false : !isChecked);
                setIsChecked(!isChecked);
            }}
        >
            <span className={styles.input}>
                <Tooltip />
            </span>
            <span className={styles.label}>{label}</span>
            <PopperTooltip tooltip={tooltip} tooltipClass={styles.checkboxTooltip}>
                <IconInfo size={16} />
            </PopperTooltip>
        </div>
    );
};

export default Checkbox;
