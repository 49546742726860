import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import Image from './Image';
import { modalsActions } from '../../redux/modals';
import { generateImageUrl, blobUrl } from '../../helpers';

import styles from './ImagesCarousel.module.scss';

const ImagesCarousel = ({ images = [], onRemoveImage = noop }) => {
    const dispatch = useDispatch();
    const sliderRef = useRef(null);
    const [lastImagesCount, setLastImagesCount] = useState(images.length);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    useEffect(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.update();
        if (lastImagesCount > 0) sliderRef.current.swiper.slideTo(images.length);
        setLastImagesCount(images.length);
    }, [images.length]);

    if (process.env.NODE_ENV === 'test') {
        // FIXME: swiper + jest issues
        return null;
    }

    return (
        <Swiper
            spaceBetween={4}
            slidesPerView={2.6}
            ref={sliderRef}
            modules={[Navigation, FreeMode]}
            freeMode={true}
            className={styles.swiperContainer}
            navigation={{
                disabledClass: 'hidden',
                hiddenClass: 'hidden',
                nextEl: '.next-arrow',
                prevEl: '.prev-arrow',
            }}
        >
            {images.map((image, index) => {
                let url = '';
                if (image.file) {
                    url = blobUrl(image.file);
                } else if (image.imageId) {
                    url = generateImageUrl({
                        imageId: image.imageId,
                        reason: 'map_manager_Create_Post',
                    });
                } else if (image.url) {
                    url = image.url;
                }

                return (
                    <SwiperSlide key={image.uuid}>
                        <Image
                            key={image.uuid}
                            src={url}
                            isLoading={image.isLoading}
                            onClick={() => {
                                dispatch(
                                    modalsActions.toggleImagePreview({
                                        imagePreviewShow: true,
                                        imagePreviewArray: images.map(image => ({ imageId: image.imageId, file: { url: image.file.url } })),
                                        imagePreviewIndex: index,
                                    })
                                );
                            }}
                            onRemoveImage={() => onRemoveImage({ url, imageId: image.imageId })}
                        />
                    </SwiperSlide>
                );
            })}
            <div className='swiper-button-prev prev-arrow' onClick={handlePrev}>
                <span></span>
            </div>
            <div className='swiper-button-next next-arrow' onClick={handleNext}>
                <span></span>
            </div>
        </Swiper>
    );
};

export default ImagesCarousel;
