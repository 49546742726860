import { useState, useEffect } from 'react';

const coordiantesRegex = /-?[0-9]{1,3}[.]?[0-9]*/g;

const useSearchCoordinates = value => {
    const [coordinates, setCoordinates] = useState({ lat: null, lon: null });

    useEffect(() => {
        if (!value) return;
        const coordinates = [...value.matchAll(coordiantesRegex)];

        let [lat, lon] = coordinates;
        lat = Number(lat && lat[0].trim());
        lon = Number(lon && lon[0].trim());

        if (lat && lon) {
            setCoordinates({ lat, lon });
        } else {
            setCoordinates({ lat: null, lon: null });
        }
    }, [value]);

    return coordinates;
};

export default useSearchCoordinates;
