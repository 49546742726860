import { useEffect, useState } from 'react';
import { isMobile, isDesktop } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../../config/routes';
import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import Logo from '../../../../components/common/Logo';
import { createDeepLink } from '../../../../helpers';
import Cover from './Cover';

import stylesDesktop from './index.module.scss';
import stylesMobile from './index.mobile.module.scss';

const styles = isMobile ? stylesMobile : stylesDesktop;

const APP_STORE_URL = 'https://apps.apple.com/us/app/steps-map-your-world/id1449597018';
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.steps.app.steps&hl=en&gl=US&pli=1';

const ResetSuccess = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [appLink, setAppLink] = useState('');
    const navigate = useNavigate();
    
    useEffect(() => {
        (async () => {
            const link = await createDeepLink(null, {
                channel: 'qr_code',
                client_type: 'map_manager',
                isAutls: true,
                reason: 'mm_change_password_link',
                feature: 'change_password',
                stage: 'change_password',
                link_type: 'store',
            });
            setAppLink(link);
        })();
    }, []);
    
    return (
        <>
            <div className={styles.container}>
                <div className={styles.coverImage}>
                    <Cover/>
                </div>
                <div className={styles.details}>
                    <div/>
                    <div className={styles.content}>
                        <span className={styles.title}>
                            <FormattedMessage id='resetPassword.success.title'/>
                        </span>
                        <span className={styles.subtitle}>
                            <FormattedMessage id='resetPassword.success.subTitle'/>
                        </span>
                        <div className={styles.actionButtons}>
                            <CustomButton
                                disabled={!appLink}
                                type={ButtonType.PRIMARY_BLUE}
                                onClick={() => {
                                    if (isMobile) {
                                        window.open(appLink, '_top');
                                        return;
                                    }
                                    
                                    setIsModalOpen(true);
                                }}
                                className={styles.ctaButton}
                            >
                                <FormattedMessage id={isMobile ? 'resetPassword.success.open_app' : 'resetPassword.success.app_login'}/>
                            </CustomButton>
                            {isDesktop && (
                                <CustomButton
                                    type={ButtonType.PRIMARY_BLUE}
                                    className={styles.ctaButton}
                                    onClick={() => {
                                        navigate(`/${Routes.MAP}`);
                                    }}
                                >
                                    <FormattedMessage id='resetPassword.success.desktop_login'/>
                                </CustomButton>
                            )}
                        </div>
                    </div>
                    <div className={styles.logo}>
                        <Logo size={isMobile ? Logo.Sizes.SMALL : Logo.Sizes.MEDIUM} color='#212B37'/>
                    </div>
                </div>
            </div>
            {isDesktop && (
                <BaseModal className={styles.modalContainer} isActive={isModalOpen} size={ModalSizes.SMALL} onClose={() => setIsModalOpen(false)}>
                    <div>
                        <Logo size={isMobile ? Logo.Sizes.SMALL : Logo.Sizes.MEDIUM}/>
                    </div>
                    <span className={styles.modalTitle}>
                        <FormattedMessage id='resetPassword.success.modalTitle'/>
                    </span>
                    <QRCodeCanvas value={appLink} size={320}/>
                    <div className={styles.storeButtons}>
                        <CustomButton
                            type={ButtonType.SECONDARY_BLACK}
                            onClick={() => {
                                window.open(APP_STORE_URL, '_blank');
                            }}
                        >
                            <span className='m-r-8 flex'>
                                <img src='/assets/img/steps3LoginLayout/Apple.svg' alt='' className={styles.appleIcon}/>
                            </span>
                            <FormattedMessage id='resetPassword.success.app_store'/>
                        </CustomButton>
                        <CustomButton
                            type={ButtonType.SECONDARY_BLACK}
                            onClick={() => {
                                window.open(GOOGLE_PLAY_URL, '_blank');
                            }}
                        >
                            <span className='m-r-8 flex'>
                                <img src='/assets/img/steps3LoginLayout/Google.svg' alt='' className={styles.facebookIcon}/>
                            </span>
                            <FormattedMessage id='resetPassword.success.google_play'/>
                        </CustomButton>
                    </div>
                </BaseModal>
            )}
        </>
    );
};

export default ResetSuccess;
