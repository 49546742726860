import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import classNames from 'classnames';
import { Sliders as SlidersIcon } from '../../../../components/common/icons';
import { activityTypes } from '../../RecentActivities';

import styles from './Filters.module.scss';

// TODO: rename file
const MapUpdatesHeader = ({
    filteredActivityTypes = [],
    unhandledActivities = [],
    handleFilterClick = noop,
    setIsUnhandledOnly = noop,
    isUnhandledOnly = false,
}) => {
    return (
        <div className={styles.topPanel}>
            <span className={styles.title}>
                <FormattedMessage id='recentActivities.title' defaultMessage='Map Updates' />
            </span>
            <div className={styles.filtersContainer}>
                <div className={styles.icon}>
                    <SlidersIcon size={20} color='#B5B7BB' />
                </div>
                <div className={styles.filterTypes}>
                    {Object.keys(activityTypes).map(activityType => {
                        return (
                            <span
                                className={classNames({
                                    [styles.selected]: filteredActivityTypes.includes(activityType),
                                })}
                                onClick={() => handleFilterClick(activityType)}
                            >
                                <FormattedMessage id={`recentActivities.filter.${activityType.toLowerCase()}`} />
                            </span>
                        );
                    })}
                </div>
                <div className={styles.handleSwitch}>
                    <span onClick={() => setIsUnhandledOnly(true)} className={classNames({ [styles.active]: isUnhandledOnly })}>
                        Open{unhandledActivities > 0 && ` (${unhandledActivities})`}
                    </span>
                    <span className={styles.buffer}>/</span>
                    <span
                        onClick={() => setIsUnhandledOnly(false)}
                        className={classNames({
                            [styles.active]: !isUnhandledOnly,
                        })}
                    >
                        All updates
                    </span>
                </div>
            </div>
        </div>
    );
};

export default MapUpdatesHeader;
