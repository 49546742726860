import React, { useRef, useContext } from 'react';
import { noop } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from '../common/Skeletons/PostSkeleton';
import useMixpanel from './useMixpanel';
import { useNavigate } from 'react-router-dom';
import Routes from '../../config/routes';
import { Post, ShowCommentsOptions } from '../PostStructure';
import { LoadingBlock, NoMorePostsBlock, UpToDateBlock } from './LoadingPostsBlocks';
import styles from './Feed.module.scss';
import { PopupContext } from '../../context';
import { useSelector, useDispatch } from 'react-redux';
import { feedActions, feedSelectors } from '../../redux/feed';

const INPUT_HEIGHT = 105;
const SEARCH_HEIGHT = 36;
const TOTAL_HEIGHT = INPUT_HEIGHT + SEARCH_HEIGHT;

const FeedPosts = ({
    mapId,
    onPostClick,
    announcementId,
    loadMorePosts,
    feedPostsIds = [],
    isLoading,
    cursor = null,
    firstUnseenByUserPostId = null,
    scrollTarget = '',
    onTagPlace = noop,
    onTagError = noop,
    onTagClick = noop,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setActivePopupId } = useContext(PopupContext);
    const scrollRef = useRef();
    const scrollAnchor = useSelector(feedSelectors.feedAnchorPosition);
    const initialScrollY = scrollAnchor;

    useMixpanel({ mapId, announcementId });

    if (isLoading) {
        return <Skeleton />;
    }

    if (feedPostsIds.length === 0) {
        return null;
    }

    const saveScrollPosition = () => dispatch(feedActions.setFeedAnchor({ anchorPosition: scrollRef.current.lastScrollTop }));

    /* TODO: InfiniteScroll component concat the feed on every compilation */
    /* TODO: InfiniteScroll component is slow */
    return (
        <InfiniteScroll
            // target is in StepsList
            id='feed-posts-container'
            scrollableTarget={scrollTarget}
            dataLength={feedPostsIds.length}
            next={() => loadMorePosts({ nextPage: !!cursor, quiet: true, cursor })}
            hasMore={!!cursor}
            scrollThreshold={0.8}
            onScroll={() => setActivePopupId(null)}
            height={window.innerHeight - TOTAL_HEIGHT}
            className={styles.infiniteFeedPosts}
            endMessage={<NoMorePostsBlock />}
            loader={<LoadingBlock />}
            ref={scrollRef}
            initialScrollY={initialScrollY}
        >
            {feedPostsIds.map((postId, index) => {
                return (
                    <span key={postId} id={postId}>
                        {firstUnseenByUserPostId === postId && index !== 0 && <UpToDateBlock />}
                        <Post
                            onTagPlace={onTagPlace}
                            fromWhere='feed'
                            className={styles.post}
                            onClick={() => onPostClick(postId)}
                            postId={postId}
                            onTagError={onTagError}
                            onTagClick={onTagClick}
                            commentsSettings={{
                                isShowAll: ShowCommentsOptions.PARTIAL,
                                numberOfLastCommentsToShow: 1,
                            }}
                            repliesSettings={{ isShowAll: ShowCommentsOptions.NONE }}
                            saveScrollPosition={saveScrollPosition}
                            onClickUserBadge={stepId => navigate(`${Routes.DASHBOARD}?step_id=${stepId}`)}
                        />
                    </span>
                );
            })}
        </InfiniteScroll>
    );
};

export default FeedPosts;
