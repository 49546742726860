import { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import StepElement from './StepElement';
import { distance, getStepsByBounds } from '../../../../helpers';
import { userSelectors } from '../../../../redux/user';
import { mapSelectors, mapActions } from '../../../../redux/map';
import { stepSelectors } from '../../../../redux/step';
import EmptyStepList from '../../../../components/EmptyStepList';
import EmptyMapList from '../../../../components/EmptyMapList';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import LoadingBlock from '../../../../components/Feed/LoadingPostsBlocks/LoadingBlock';
import { generatePath, useLocation, useNavigate, useOutletContext } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import Routes from '../../../../config/routes';
import { useContext } from 'react';
import { TagsContext } from '../../../../context';

import styles from './index.module.scss';

let isScrolling = false;

const List = ({ isLoading, sortedBy, setRememberPosition, filteredSteps }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const mapId = useSelector(userSelectors.selectedMapId);
    const statusSteps = useSelector(stepSelectors.statusSteps);
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId)) || {};
    const stepsList = useSelector(mapSelectors.map(mapId))?.currentList;
    const { clearFilteredTag, selectedTags, isFiltered } = useContext(TagsContext);
    const { setAddImage, setShowModals, setBoldStepId, setDisableClickOnMap } = useOutletContext();
    const totalSteps = useSelector(mapSelectors.map(mapId))?.steps_count;
    const stepsCount = stepsList?.steps?.length;
    const scrollAnchor = stepsList.anchor;
    const scrollRef = useRef(null);
    const initialScrollY = scrollAnchor;
    
    const onClickStepElement = ({ _id }) => {
        mixpanel.track(mixpanelEvents.MAP_LIST_POST_SLIDER_ARROW_CLICK);
        // remember scroll position after getting back to the step list
        dispatch(mapActions.setStepsListAnchor(mapId, { anchorPosition: scrollRef.current.lastScrollTop }));
        setRememberPosition(true);
        const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId: _id });
        navigate(url, { state: { fromWhere: 'steps_list' } });
    };
    
    const onClickAddPost = () => {
        setAddImage(true);
        setShowModals(true);
    };
    
    const onHoverElement = stepId => location.pathname.includes(Routes.MAP) && setBoldStepId(stepId);
    
    const onClickAddPostClick = () => {
        setDisableClickOnMap(false);
        navigate(Routes.ADD_STEP);
    };
    
    const onClickAddBtn = ({ stepId, area }) => {
        const queryParams = createSearchParams({ openTags: area === 'tags' });
        const pathname = generatePath(Routes.STEP_PROFILE, { stepId });
        navigate({ pathname, search: queryParams.toString() }, { state: { fromWhere: 'steps_list' } });
    };
    
    const findClosestStep = useCallback(
        steps => {
            const distances = steps.map(step => distance(step.lat, step.lon, mapCenter?.position.lat, mapCenter?.position?.lng));
            for (let i = 0; i < distances?.length; i++) {
                if (distance(mapCenter?.position?.lat, mapCenter?.position?.lng, steps[i].lat, steps[i].lon) <= Math.min(...distances)) {
                    const coords = { lat: steps[i].lat, lon: steps[i].lon };
                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: coords,
                            zoom: 12,
                        })
                    );
                }
            }
        },
        [mapCenter?.position]
    );
    
    if (stepsCount > 0 || (stepsCount === 0 && isLoading)) {
        return (
            <InfiniteScroll
                onScroll={() => {
                    isScrolling = true;
                    setTimeout(() => (isScrolling = false), 1000);
                }}
                className={styles.infiniteScroll}
                dataLength={stepsList.steps.length}
                next={async () => {
                    const result = await getStepsByBounds({
                        mapId,
                        bounds: mapCenter.bounds,
                        cursor: stepsList.cursor,
                        sortedBy: sortedBy,
                        status: statusSteps,
                        tag: selectedTags.map(tag => tag._id),
                    });
                    dispatch(
                        mapActions.updateStepsList(mapId, {
                            steps: stepsList.steps.concat(result.steps),
                            cursor: result.cursor,
                            total: result.total,
                        })
                    );
                }}
                hasMore={!!stepsList.cursor}
                loader={<LoadingBlock/>}
                scrollableTarget='stepPreviewList'
                ref={scrollRef}
                initialScrollY={initialScrollY}
            >
                {stepsList.steps.map(step => {
                    if (!step) {
                        return null;
                    }
                    
                    return (
                        <StepElement
                            key={step._id}
                            step={step.isLoading ? null : step}
                            onClickAddPost={onClickAddPost}
                            onClickElement={() => onClickStepElement && onClickStepElement(step)}
                            onClickAddTags={() => {
                                onClickAddBtn({
                                    stepId: step._id,
                                    area: 'tags',
                                });
                            }}
                            onMouseEnter={() => !isScrolling && onHoverElement(step._id)}
                            onMouseLeave={() => !isScrolling && onHoverElement(null)}
                        />
                    )
                })}
            </InfiniteScroll>
        );
    }
    
    if (totalSteps === 0) {
        return (
            <EmptyMapList
                onClickAddPost={() => {
                    onClickAddPostClick();
                    mixpanel.track(mixpanelEvents.ADD_STEP_OPEN, {
                        [mixpanelProperties.FROM_WHERE]: 'empty_map',
                    });
                    localStorage.setItem('fromWhereAddPost', 'empty_map');
                }}
            />
        );
    }
    
    if (isFiltered) {
        return (
            <div className={styles.filterEmptyBlock}>
                {filteredSteps?.steps.length === 0 ? (
                    <>
                        <p className={styles.emptyFilterTitle}>No steps to show</p>
                        <p>There are no steps matching the {selectedTags?.length === 1 ? 'filter' : 'filters'} you selected.</p>
                    </>
                ) : (
                    <>
                        <p className={styles.emptyFilterTitle}>No steps here</p>
                        <p>See the closest step matching your {selectedTags?.length === 1 ? 'filter' : 'filters'}.</p>
                    </>
                )}
                {filteredSteps?.steps.length !== 0 && (
                    <CustomButton
                        onClick={() => findClosestStep(filteredSteps.steps)}
                        className={styles.closestStep}
                        type={ButtonType.PRIMARY_BLUE}
                        text={'See closest step'}
                        size={'mediumSize'}
                    />
                )}
                <CustomButton onClick={clearFilteredTag} type={ButtonType.SECONDARY} text={'Clear Filter'} size={'mediumSize'}/>{' '}
            </div>
        );
    }
    
    return (
        <EmptyStepList
            onClickAddPost={() => {
                onClickAddPostClick();
                mixpanel.track(mixpanelEvents.ADD_STEP_OPEN, {
                    [mixpanelProperties.FROM_WHERE]: 'no_post_area',
                });
                localStorage.setItem('fromWhereAddPost', 'no_post_area');
            }}
        />
    );
};

export default List;
