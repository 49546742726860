import { useState, useMemo, useEffect, useCallback } from 'react';
import { isEmpty, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../redux/user';
import { mapActions, mapSelectors } from '../../redux/map';
import CustomButton, { ButtonType, ButtonSize } from '../common/buttons/CustomButton';
import { EditStepDetailsHeader, SearchMapStepsByPosition } from './components';
import { getPlaceCoordinates, getAddressByCoordinates } from '../../helpers';
import EditNameOfPlace from './EditNameOfPlace';
import mixpanel, { mixpanelEvents, mixpanelProperties } from "../../helpers/mixpanel";
import { useNavigate } from "react-router-dom";

const EditStepDetails = ({
  nameOfPlace = '',
  onClickPrev = noop,
  onClickDone = noop,
  step = {},
  position = {},
  isPlaceClicked = false,
  isCreateAPlaceClicked = false,
  fromWhere = '',
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapId = useSelector(userSelectors.selectedMapId);
  const mapName = useSelector(mapSelectors.mapName(mapId));
  const ghostStep = useSelector(mapSelectors.ghostStep(mapId)) || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const previousAddress = useMemo(() => (
      step.address ||
      step.secondary_title ||
      step?.geocode_address?.formatted_address ||
      step.hpaw?.address ||
      ghostStep.address ||
      ''
  ), [step.address, step?.geocode_address?.formatted_address, step.hpaw?.address, step.secondary_title]);
  const [editedLocation, setEditedLocation] = useState({
    title: nameOfPlace,
    address: previousAddress
  });
  const onSearchResultClick = useCallback(async (result) => {
    setIsLoading(true);
    let newPosition = {
      lat: result?.location?.coordinates?.[1],
      lon: result?.location?.coordinates?.[0],
    };
    
    if (!result?.location?.coordinates) {
      const coordinates = await getPlaceCoordinates(result);
      newPosition = coordinates.location;
    }
    
    dispatch(
        mapActions.setGhostStep(mapId, {
          address: result.title,
          position: newPosition,
          type: result.location_id ? 'location' : 'place',
          title: step?.title || nameOfPlace,
          suggestToAdd: false,
          isOpenTooltip: false,
          stickToMap: true,
        }),
    );
    
    dispatch(
        mapActions.setMapCenter(mapId, {
          position: newPosition,
          zoom: 18,
        }),
    );
    
    const newTitle = nameOfPlace || result.title || step.title || editedLocation.title || '';
    let newAddress = result.geocode_address?.formatted_address || result.address || '';
    
    if (!newAddress && newPosition) {
      newAddress = await getAddressByCoordinates({
        lat: newPosition.lat,
        lon: newPosition.lon || newPosition.lng,
      });
    }
    
    const isAddStepAndHasTitle = isPlaceClicked && editedLocation.title;
    setEditedLocation({
      title: isAddStepAndHasTitle ? editedLocation.title : newTitle,
      address: newAddress
    });
    setIsLoading(false);
  }, [editedLocation.title, isPlaceClicked, mapId, nameOfPlace, step.title]);
  
  useEffect(() => {
    const hasLocationInfo = editedLocation.title && editedLocation.address;
    setIsSubmitDisabled(!hasLocationInfo);
  }, [editedLocation]);
  
  return (
      <div>
        <EditStepDetailsHeader onPrevious={onClickPrev} isAddPlaceTitle={isPlaceClicked} title={nameOfPlace}/>
        <EditNameOfPlace
            className='mb-4 mx-8'
            stepName={nameOfPlace}
            isVisible={isPlaceClicked && !isCreateAPlaceClicked}
            onChange={(value) => {
              setEditedLocation({ ...editedLocation, title: value });
            }}
        />
        <SearchMapStepsByPosition
            position={position}
            defaultValue={previousAddress}
            onCancel={() => setIsSubmitDisabled(true)}
            fromWhere={fromWhere}
            onSearchResultClick={onSearchResultClick}/>
        <div className='absolute bottom-7 right-6'>
          <CustomButton
              className="w-32"
              type={ButtonType.PRIMARY}
              size={ButtonSize.MEDIUM}
              loader={isLoading}
              disabled={isSubmitDisabled}
              onClick={() => {
                if (isEmpty(step)) {
                  // ADD STEP FLOW
                  mixpanel.track(mixpanelEvents.STEP_CREATION_NEW_LOCATION_NEXT_CLICKED, {
                    [mixpanelProperties.MAP_ID]: mapId,
                    [mixpanelProperties.MAP_NAME]: mapName,
                    [mixpanelProperties.FLOW]: 'new_location',
                    [mixpanelProperties.ADDRESS]: editedLocation.address,
                  });
                  
                  dispatch(mapActions.setGhostStep(mapId, {
                    ...ghostStep,
                    title: editedLocation.title,
                    address: editedLocation.address,
                    suggestToAdd: false,
                    isOpenTooltip: true,
                    isStickToMap: true,
                  }));
                  
                  onClickDone(editedLocation.title, editedLocation.address);
                  return;
                }
                
                // EDIT STEP FLOW
                dispatch(
                    mapActions.editStepLocation(mapId, {
                      stepId: step._id,
                      lat: ghostStep.position.lat,
                      lon: ghostStep.position.lon || ghostStep.position.lng,
                      title: step.title,
                      address: editedLocation.address
                    }),
                );
                
                dispatch(mapActions.setGhostStep(mapId, null));
                navigate(-1);
              }}>
            <FormattedMessage id={isCreateAPlaceClicked ? 'addStep.next' : 'addStep.done'}/>
          </CustomButton>
        </div>
      </div>
  );
  
};

export default EditStepDetails;