import { useEffect } from 'react';
import classNames from 'classnames';
import styles from './CursorFollower.module.scss';

const followCursor = event => {
    const target = document.querySelector('#cursor-follower');
    const rect = target?.getBoundingClientRect();
    target.style.left = `${event.pageX - rect?.width / 2}px`;
    target.style.top = `${event.pageY - rect?.height - 36}px`;
};

const CursorFollowingElement = ({ className, children, isEnabled }) => {
    useEffect(() => {
        window.addEventListener('mousemove', followCursor);
        return () => {
            window.removeEventListener('mousemove', followCursor);
        };
    }, []);

    return (
        <span id='cursor-follower' className={classNames(className, { [styles.isHidden]: !isEnabled })}>
            {children}
        </span>
    );
};

export default CursorFollowingElement;
