import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { feedActions, feedSelectors } from '../../redux/feed';
import { mapSelectors } from '../../redux/map';

const useFeed = ({ mapId }) => {
    const dispatch = useDispatch();
    const [firstUnseenByUserPostId, setFirstUnseenByUserPostId] = useState(null);
    const currentMapPosition = useSelector(mapSelectors.mapCenter(mapId));
    const cursor = useSelector(feedSelectors.feedCursor);

    // TODO: refactor, is confusing
    const fetchFeed = async ({ quiet = true, mapId: currentMapId = mapId } = {}) => {
        if (currentMapPosition?.position?.lat === 0 || currentMapPosition?.position?.lng === 0 || !currentMapId) {
            return { posts: [] };
        }

        const results = await dispatch(
            feedActions.getFeedPosts({
                mapId: currentMapId,
                cursor: quiet ? cursor : null,
                lat: currentMapPosition?.position?.lat,
                lon: currentMapPosition?.position?.lng,
                isQuiet: quiet,
            })
        );

        const posts = get(results, 'data.posts', []);
        const firstSeenId = posts.find(({ is_seen_by_user }) => is_seen_by_user)?._id;

        firstSeenId !== firstUnseenByUserPostId && setFirstUnseenByUserPostId(firstSeenId);

        return { posts, cursor };
    };

    return {
        loadMorePosts: ({ nextPage, quiet } = {}) => {
            return fetchFeed({ mapId, nextPage, quiet });
        },
        fetchFeed,
        cursor,
        firstUnseenByUserPostId,
    };
};

export default useFeed;
