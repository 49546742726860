import React, { useRef, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import loaderAnimation from '../../../lottie/import.json';
import Lottie from 'react-lottie';
import { FileType } from '../../../helpers/constants';
import CustomButton, { ButtonType } from '../../../components/common/buttons/CustomButton';
import IconUpload from '../../../components/common/icons/IconUpload';

export const DropZone = ({ onLoadFiles, type, loading = false }) => {
    const fileInput = useRef(null);

    const upload = e => {
        onLoadFiles && onLoadFiles(e.target.files);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    return (
        <>
            <CustomButton
                className={styles.dFlex}
                size={'largeSize'}
                onClick={() => !loading && fileInput.current.click()}
                type={ButtonType.SECONDARY_BLUE}
                text={
                    loading ? (
                        <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={60} height={60} />
                    ) : (
                        <>
                            {' '}
                            <IconUpload /> {type === FileType.EXCEL ? 'Upload Excel file' : 'Upload KML file'}{' '}
                        </>
                    )
                }
            />
            <input
                style={{
                    display: 'none',
                    position: 'absolute',
                }}
                accept={
                    type === FileType.EXCEL
                        ? '.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                        : '.kml, application/inkml+xml, application/vnd.google-earth.kml+xml'
                }
                multiple={false}
                type='file'
                ref={fileInput}
                onChange={upload}
            />
        </>
    );
};

export default DropZone;
