import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { noop } from 'lodash';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import IconEditAccordion from '../../../../components/common/icons/IconEditAccordion';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import IconDelete from '../../../../components/common/icons/IconDelete';
import { mapActions } from '../../../../redux/map';
import { arraysEqual } from '../../../../helpers';
import loaderAnimation from '../../../../lottie/circleLoader.json';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';
import { CHANGES_SAVED_TEXT, ToastContext } from '../../../../context/ToastContext';
import { ToastDurations } from '../../../../context/ToastContext/constants';

import copiesPrefix from '../../../../copies.json';
import styles from './MapKeywords.module.scss';

const copies = copiesPrefix.mapSettings.sections.keywords;

const SPACE_CODE = 'Space';
const ENTER_CODE = 'Enter';
const COMMA_CHAR = ',';

const MapKeywords = ({ onClick = noop, setClose = noop, keywords: initKeywords = [], mapId = null }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [value, setValue] = useState('');
    const accordionRef = useRef(null);
    const keyWordsRef = useRef(null);
    const [keywords, setKeywords] = useState(initKeywords);
    const [disabled, setDisable] = useState(true);
    const [keywordExist, setKeyWordExist] = useState(false);
    const [loader, setLoader] = useState(false);
    const { setToast } = useContext(ToastContext);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    // before event has actual value
    const handleKeyDown = event => {
        const { value } = event.target;
        const isEnterChar = event.code === ENTER_CODE;
        const isCommaChar = event.key === COMMA_CHAR;
        const shouldAddKeyword = (isEnterChar || isCommaChar) && !keywordExist;

        if (shouldAddKeyword) {
            event.preventDefault();
            if (keywords.length > 0) {
                keywords.forEach(data => {
                    if (data?.label !== value) {
                        setDisable(false);
                        setKeywords([...keywords, ...value.trim().split(COMMA_CHAR)]);
                    }
                });
            } else {
                setDisable(false);
                setKeywords([...keywords, ...value.trim().split(COMMA_CHAR)]);
            }
            setValue('');
        } else if (isCommaChar) {
            event.preventDefault();
            setValue(value);
        }
    };

    useEffect(() => {
        setKeywords(initKeywords);
    }, [initKeywords.length]);

    useEffect(() => {
        if (arraysEqual(keywords, initKeywords)) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [keywords, initKeywords]);

    const addKeyWord = useCallback(
        keyword => {
            const newKeyWordExist = keywords.includes(keyword?.toLowerCase().replace(/^\s*/, '')?.replace(/\s*$/, ''));
            if (newKeyWordExist) {
                setKeyWordExist(true);
            } else {
                setKeyWordExist(false);
            }

            setValue(keyword);
        },
        [keywords]
    );

    const handleDelete = keywordToDelete => {
        setDisable(false);
        setKeywords(keywords => keywords.filter(keyword => keyword !== keywordToDelete));
    };

    const onCancel = useCallback(() => {
        setKeywords(initKeywords);
        setValue('');
        accordionRef.current.click();
        setDisable(true);
    }, [initKeywords]);

    const onSave = useCallback(() => {
        const toDelete = keywords.filter(x => !keywords.includes(x));
        const toAdd = keywords.filter(x => !keywords.includes(x));
        mixpanel.track(mixpanelEvents.MODIFY_MAP_KEYWORDS, {
            [mixpanelProperties.FROM_WHERE]: 'map_settings',
            [mixpanelProperties.KEYWORD_ADDED]: toAdd,
            [mixpanelProperties.KEYWORD_DELETED]: toDelete,
        });
        setLoader(true);
        dispatch(mapActions.updateMap(mapId, { keywords }))
            .then(({ data }) => {
                if (data) {
                    setLoader(false);
                    setDisable(true);
                    accordionRef.current.click();
                    setToast({ text: CHANGES_SAVED_TEXT, timeout: ToastDurations.SHORT });
                }
            })
            .catch(() => {
                setLoader(false);
            });
    }, [keywords]);

    return (
        <div
            onMouseOver={() => !open && setHover(true)}
            onMouseLeave={() => !open && setHover(false)}
            className={classNames(styles.accordionContainer, {
                [styles.hovered]: hover,
                [styles.active]: open,
            })}
        >
            <Accordion
                onChange={() => {
                    if (open) {
                        onClick(true);
                        setTimeout(() => {
                            setClose(true);
                        }, 100);
                        setTimeout(() => {
                            onClick(false);
                            setClose(false);
                        }, 1100);
                    } else {
                        onClick(false);
                        setClose(false);
                    }
                    setHover(false);
                    setOpen(!open);
                }}
                className={styles.accordionBlock}
            >
                <AccordionSummary
                    ref={accordionRef}
                    className={classNames(styles.accordionContent)}
                    expandIcon={open ? null : <IconEditAccordion />}
                    aria-controls='panel-mapKeywords'
                    id='panel-mapKeywords'
                >
                    <Typography className={styles.accordionHeading}>{copies.title}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px 24px 16px' }}>
                    <div style={{ width: 'calc(100%)' }}>
                        <p className={styles.text}>{copies.description}</p>
                        <span onClick={() => keyWordsRef.current.focus()} className={styles.keyWordsBlock}>
                            {keywords.map(keyword => {
                                return (
                                    <span key={keyword} className={styles.deleteKeyBlock} data-testid='keyword'>
                                        <span>{keyword}</span>
                                        <IconDelete
                                            style={{
                                                position: 'relative',
                                                top: '2px',
                                                marginLeft: '5px',
                                                minWidth: '12px',
                                                minHeight: '12px',
                                            }}
                                            onClick={() => handleDelete(keyword)}
                                        />
                                    </span>
                                );
                            })}
                            <input
                                id='test'
                                value={value}
                                placeholder={copies.placeholder}
                                ref={keyWordsRef}
                                className={styles.input}
                                onChange={event => {
                                    try {
                                        addKeyWord(event.target.value);
                                        // updated value
                                        const isNextKeywordValid = event.target?.value?.trim()?.split(COMMA_CHAR)?.filter(Boolean)?.length > 0;

                                        if (!isNextKeywordValid) {
                                            event.preventDefault();
                                            setValue('');
                                            return;
                                        }
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                        </span>
                        <p className={styles.subText}>{copies.hint}</p>
                        {keywordExist && (
                            <span style={{ marginTop: '10px' }} className={styles.error}>
                                {copies.input_error_exists}
                            </span>
                        )}
                        <span className={styles.accordionFooter}>
                            <CustomButton className={styles.cancelBtn} onClick={onCancel} type={ButtonType.TERTIARY_BLACK}>
                                {copies.cancel_button}
                            </CustomButton>
                            <CustomButton
                                className={styles.saveBtn}
                                disabled={disabled}
                                onClick={onSave}
                                type={ButtonType.PRIMARY}
                                text={
                                    loader ? <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={40} height={40} /> : copies.save_button
                                }
                            />
                        </span>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default MapKeywords;
