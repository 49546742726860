import { useContext } from "react";
import { PopupContext } from "../../../../context";
import { TagTypes } from "../../../../constants";
import TextSpan from "./TextSpan";
import { UserTagPopover } from "./UserTagPopover";
import { noop } from "lodash";

const BodyItem = props => {
  const { activePopupId, setActivePopupId } = useContext(PopupContext);
  const { uuid, userId, textType, text, userPopoverId, onClick } = props;
  const isUserTagView =
    textType === TagTypes.USER_TAG &&
    activePopupId &&
    userPopoverId &&
    activePopupId === userPopoverId;
  const textSpan =
    <TextSpan
      {...props}
      setActivePopupId={setActivePopupId}
      onClick={isUserTagView && onClick} />;

  if (isUserTagView) {
    return (
      <UserTagPopover key={uuid} userId={userId} userName={text} isActive>
        {textSpan}
      </UserTagPopover>
    );
  }

  return textSpan;
};

export default BodyItem;
