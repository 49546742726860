import classNames from 'classnames';
import { noop } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, X, Info, ShareNetwork } from 'phosphor-react';
import styles from './index.module.scss';
import { DropdownMenu, MenuSize } from '../../../common/DropdownMenu';
import copiesPrefix from '../../../../copies.json';
import { StepBadge } from '../../../common';
import CustomButton, { ButtonSize } from '../../../common/buttons/CustomButton';
import { ButtonType } from './../../../common/buttons/CustomButton/index';
import { FormattedMessage } from 'react-intl';

const copies = copiesPrefix.stepProfile;

export const cancelButtonType = {
    BACK_BUTTON: 'backButton',
    X_BUTTON: 'xButton',
};

const ICON_SIZE = 17;

const StepProfileHeader = ({
    title = '',
    address = '',
    cancelIcon = cancelButtonType.BACK_BUTTON,
    stepCreator,
    isArchived,
    isNew,
    onCancel = noop,
    onClickRestore = noop,
    onClickEditLocation,
    onClickArchive,
    onClickInfo,
    onClickShare,
    onClickMapView,
    className,
    businessStatus = '',
    stepIndications = [],
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    console.log({ stepIndications });

    const removeUrlStepIdParam = () => {
        navigate(
            {
                pathname: location.pathname,
            },
            { replace: true }
        );
    };

    const onClickPrev = () => {
        removeUrlStepIdParam();
        onCancel();
    };

    const cancelButtons = {
        backButton: (
            <span data-cy='back-button'>
                <ArrowLeft className={styles.backButton} size='20' color='#2171EC' onClick={onClickPrev} />
            </span>
        ),
        xButton: <X className={styles.backButton} size='18' color='#2171EC' onClick={onClickPrev} />,
    };
    const renderDropdown = () => {
        if (!(onClickEditLocation || onClickArchive)) return;
        const options = [];

        if (onClickArchive) {
            options.unshift({
                title: copies.archive_step,
                onClick: onClickArchive,
            });
        }

        if (onClickEditLocation) {
            options.unshift({
                title: copies.edit_location,
                onClick: onClickEditLocation,
            });
        }
        return <DropdownMenu options={options} menuSize={MenuSize.ExtraLarge} buttonSize={ICON_SIZE} />;
    };

    const renderButtons = () => (
        <div className='flex items-center mt-2'>
            {onClickInfo && (
                <Info size={ICON_SIZE} data-cy='step-info' className='hover:bg-gray-100 cursor-pointer rounded-full' onClick={onClickInfo} color='#3A3C3F' />
            )}
            {onClickShare && (
                <ShareNetwork size={ICON_SIZE} onClick={onClickShare} className='hover:bg-gray-100 cursor-pointer rounded-full mx-2' color='#3A3C3F' />
            )}
            {renderDropdown()}
        </div>
    );

    const renderArchived = () => (
        <div className={styles.archived}>
            <span className={styles.text}>{copies.step_archived}</span>
            <button className={styles.restoreButton} onClick={onClickRestore}>
                {copies.restore}
            </button>
        </div>
    );

    return (
        <div className={classNames(styles.block, className)}>
            <div className={styles.upperSection}>
                {cancelButtons[cancelIcon]}
                <div className={styles.content}>
                    <div className={styles.titleContainer}>
                        <h4 className={styles.title}>{title}</h4>
                        <StepBadge businessStatus={businessStatus} stepIndications={stepIndications} />
                    </div>
                    <span className={styles.address}>{address}</span>
                </div>
                {isArchived ? renderArchived() : renderButtons()}
            </div>
            {onClickMapView && (
                <CustomButton className={styles.mapViewButton} type={ButtonType.SECONDARY_BLACK} size={ButtonSize.MEDIUM} onClick={onClickMapView}>
                    <FormattedMessage id='stepProfile.mapView' defaultMessage='Map View' />
                </CustomButton>
            )}
        </div>
    );
};

export default StepProfileHeader;
