import React from 'react';
import Avatar, { AvatarSizesNames, AvatarSizes } from './Avatar';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../redux/user';
import { generateImageUrl } from '../../../helpers';

const CurrentUserAvatar = ({ withName = false, size = AvatarSizesNames.MEDIUM, ...rest}) => {
    const user = useSelector(userSelectors.user);

    return (
        <Avatar
            size={size}
            alt={user?.name}
            src={
                user.userImageTemp ||
                generateImageUrl({
                    imageId: user?.image,
                    width: AvatarSizes.MEDIUM,
                    height: AvatarSizes.MEDIUM,
                    reason: 'show_user_image',
                })
            }
            {...rest}
        />
    );
};

export default CurrentUserAvatar;
