import classNames from 'classnames';
import { ArrowRight, CaretRight, Plus } from '../../common/icons';
import React from 'react';

export const LoadingSpinner = ({ className }) =>
    <img alt=''
         className={classNames('spin', className)}
         src='/assets/img/loadingSpinner.svg' />;

const GlobeIcon = ({ className }) =>
    <img alt='' className={className}
         src='/assets/img/icon_globe_search.svg' />;
const StepIcon = ({ className }) =>
    <img alt='' className={className}
         src='/assets/img/icon_step_search.svg' />;
const BlueStepIcon = ({ className }) =>
    <img alt='' className={className}
         src='/assets/img/searchBlueStepPin.svg' />;

export const LocationIcon = ({ type, ...rest }) => {
    switch (type) {
        default:
        case 'globe':
            return <GlobeIcon {...rest} />;

        case 'step':
            return <StepIcon {...rest} />;

        case 'blueStep':
            return <BlueStepIcon {...rest} />;
    }
};

export const AddIcon = ({ type, ...rest }) => {
    switch (type) {
        default:
        case 'plus':
            return <Plus {...rest} />;

        case 'arrow':
            return <ArrowRight {...rest} />;

        case 'grayArrow':
            return <CaretRight {...rest} />;
    }
};