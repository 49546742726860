import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const BlueButton = ({ className, text, ref, disabled, onClick }) => {
    return (
        <button disabled={disabled} ref={ref} className={classNames([styles.block, className])} onClick={onClick}>
            {text}
        </button>
    );
};

export default BlueButton;
