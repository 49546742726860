import classNames from 'classnames';
import CustomButton, { ButtonSize, ButtonType } from '../buttons/CustomButton';

import styles from './Tooltips.module.scss';
import { IconDelete } from './../icons';

export const TooltipDirections = {
    LEFT: 'left',
    RIGHT: 'right',
    UP: 'up',
    DOWN: 'down',
    DOWN_MIDDLE: 'downMiddle',
};

export const TooltipColors = {
  BLACK: "bg-color-black",
  WHITE: "bg-color-white",
  BLUE: "bg-color-blue",
};

export { ButtonType as TooltipCtaButtonType, ButtonSize as TooltipCtaButtonSize };

const Tooltip = ({
    title,
    description,
    buttonText,
    currentStep,
    totalSteps,
    onClick = () => {},
    onCtaClick = () => {},
    ctaType = ButtonType.GHOST,
    ctaSize = ButtonSize.SMALL,
    direction = TooltipDirections.DOWN,
    className,
    hasCloseButton,
  withFooter = true,
  bgColor = TooltipColors.WHITE,
    onClose = () => {},
}) => {
    return (
        <div className={classNames(styles.locationNameBlock, styles.direction)}>
            <div className={styles.locationPick} onClick={onClick}>
        <div
          className={classNames(
            styles.tooltipStepBlock,
            styles.tooltipStepBlockShow,
            styles[direction],
            styles[bgColor],
            className
          )}
        >
                    {hasCloseButton && (
                        <span className={styles.closeButton} onClick={onClose}>
                            <IconDelete />
                        </span>
                    )}
          <div
            className={classNames(
              styles.tooltipStepBlockText,
              styles[direction.toLowerCase()],
              styles[bgColor]
            )}>
                        <p className={styles.boldText}>{title}</p>
                        <p className={styles.text}>{description}</p>
            {withFooter && (
              <div className={styles.bottomBlock}>
                            <CustomButton onClick={onCtaClick} text={buttonText} type={ctaType} size={ctaSize} />
                {currentStep && totalSteps && (
                  <span className={styles.numberSteps}>
                    {currentStep}/{totalSteps}
                  </span>
                )}
              </div>
            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tooltip;
