import React from 'react';

const IconDelete = ({ onClick, style, stroke = '#3A3C3F', size = 12 }) => (
    <svg style={style} onClick={onClick} width={size} height={size} viewBox={`0 0 12 12`} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M9 3L3 9' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3 3L9 9' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

export default IconDelete;
