import React, { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import SearchInput from '../common/inputs/SearchInput';
import SearchResult, { iconType } from '../common/buttons/SearchResult';
import styles from './index.module.scss';
import { useDebounce, useSearchCoordinates } from '../../hooks';
import config from '../../config';
import { mapSelectors, mapActions } from '../../redux/map';
import { userSelectors } from '../../redux/user';
import mixpanel from 'mixpanel-browser';
import ConfirmModal from '../ExitModal';
import { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';
import SearchResultsSkeleton from './SearchResultsSkeleton';
import { CoordinatesSearchResults } from '../CoordinatesSearchResults';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { userActionActions } from '../../redux/userAction';
import Routes from '../../config/routes';
import { noop } from 'lodash';

const copies = copiesPrefix.addLocation;
let isSearchResultProcessed = false;

const TWENTY_KM = 20000;
const ONE_HUNDRED_METERS = 100;

const GLOBE_TYPES = ['street_address', 'route', 'locality', 'country'];

const SelectLocation = ({ inputValue = '' }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const [searchValue, setSearchValue] = useState(inputValue || '');
    const debouncedSearchValue = useDebounce(searchValue, 250);
    const coordinates = useSearchCoordinates(searchValue);
    const mapId = useSelector(userSelectors.selectedMapId);
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
    const ghostStep = useSelector(mapSelectors.ghostStep(mapId));
    let [stepsResults, setStepsResults] = useState([]);
    let [placeLocationsResults, setPlaceLocationsResults] = useState([]);
    let [areaAddressResults, setAreaAddressResults] = useState([]);
    const [title, setTitle] = useState(copies.title);
    const [modalData, setModalData] = useState(false);

    const { setDisableClickOnMap, setIsMapClean, setPendingPostSelected } = useOutletContext();
    const {
        state: { step, pendingPost },
    } = location;
    const isImportMode = !!pendingPost || !!step;
    const changeLocationShow = isImportMode;

    useEffect(() => {
        if (pendingPost) setPendingPostSelected(pendingPost);
        setIsMapClean(true);
    }, []);

    useEffect(() => {
        if (!isImportMode) setTitle(!ghostStep ? copies.title : copies.createStep.fields.name.title);
        else {
            if (ghostStep && ghostStep.title === 'selected location') setTitle(copies.createStep.fields.name.title);
            if (!ghostStep) setTitle(copies.title);
        }
    }, [ghostStep]);

    useEffect(() => {
        (async () => {
            const isCoordinateSearch = coordinates.lat && coordinates.lon;
            if (isCoordinateSearch) {
                return;
            }

            setIsLoading(true);
            const accessToken = localStorage.getItem('at');
            const queryParams = {
                lon: ghostStep ? ghostStep?.position?.lon || ghostStep?.position?.lng : mapCenter?.position?.lng,
                lat: ghostStep ? ghostStep?.position?.lat : mapCenter?.position?.lat,
                map_id: mapId,
                search_term: debouncedSearchValue,
                max_radius: TWENTY_KM,
            };
            if (ghostStep && ghostStep.title === 'selected location' && !debouncedSearchValue) {
                queryParams.max_radius = ONE_HUNDRED_METERS;
            }

            if (!ghostStep?.isConfirmLocation) {
                const query = queryString.stringify(queryParams);
                let response = null;
                try {
                    response = await fetch(`${config.domain.address}/steps3/searchforpostcreation?${query}`, {
                        headers: {
                            Authorization: accessToken,
                            app_version: config.version.path,
                            app_platform: 'map_manager',
                        },
                    });
                    response = await response.json();
                } catch (e) {
                    setIsLoading(false);
                    return;
                }
                const foundedSteps = response.results.filter(val => val['result_type'] === 'step');
                setStepsResults(foundedSteps);

                const foundedPlaces = response.results
                    .filter(val => val['result_type'] === 'place')
                    ?.filter(val => !GLOBE_TYPES.some(item => val?.types?.includes(item)));
                const foundedLocations = response.results.filter(val => val['result_type'] === 'location' && !val.is_there_step);
                const placesArray = [];
                foundedPlaces.concat(foundedLocations).filter(function (item) {
                    const i = placesArray.findIndex(x => x.place_id === item.place_id);
                    if (i <= -1) {
                        placesArray.push(item);
                    }
                    return null;
                });
                setPlaceLocationsResults(placesArray);

                const foundedArea = response.results.filter(val => val['result_type'] === 'area');
                const foundedAddress = response.results.filter(val => val['result_type'] === 'address');
                const areasArray = [];
                foundedArea.concat(foundedAddress).filter(function (item) {
                    const i = areasArray.findIndex(x => x.place_id === item.place_id);
                    if (i <= -1) {
                        areasArray.push(item);
                    }
                    return null;
                });
                setAreaAddressResults(areasArray);
            } else {
                setAreaAddressResults([]);
                setPlaceLocationsResults([]);
                setStepsResults([]);
            }
            setIsLoading(false);
        })();
    }, [debouncedSearchValue, coordinates.lat, coordinates.lon]);

    const onSelectedPlace = place => {
        if (!pendingPost) {
            dispatch(userActionActions.setSelectedPlaceToAdd(place));
            setDisableClickOnMap(true);
            navigate('/' + Routes.MAP);
        }
    };

    const onClickLocationNotFound = (searchValue, item) => {
        item &&
            dispatch(
                mapActions.setGhostStep(mapId, {
                    title: 'selected location',
                    isOpenTooltip: false,
                    isStickToMap: false,
                    isConfirmLocation: false,
                    position: item.position,
                })
            );
        navigate(`/${Routes.MAP}/${Routes.NEW_PLACE}`, { state: { step, pendingPost } });
    };

    const onCancel = () => {
        if (pendingPost) {
            // setPendingPostSelected(null);
            setIsMapClean(false);
            dispatch(mapActions.setGhostStep(mapId, null));
            navigate(-1);
            return;
        } else if (step) {
            setIsMapClean(false);
            dispatch(mapActions.setGhostStep(mapId, null));
        }
        setDisableClickOnMap(true);
        navigate(-1);
    };

    const trackResultClick = (resultType, resultNum, stepId) => {
        const mixPanelPropsObj = {
            [mixpanelProperties.FROM_WHERE]: isImportMode ? 'imported' : 'step_page',
            [mixpanelProperties.RESULT_TYPE]: resultType,
            [mixpanelProperties.RESULT_NUM]: resultNum,
        };
        if (stepId) mixPanelPropsObj[mixpanelProperties.STEP_ID] = stepId;
        mixpanel.track(mixpanelEvents.ADD_LOCATION_DONE, mixPanelPropsObj);
    };

    const renderSearchResults = useCallback(() => {
        return (
            <>
                {placeLocationsResults?.length > 0 && (
                    <div className={styles.container}>
                        <p className={styles.categoryTitle}>{copies.places}</p>
                        {placeLocationsResults.map((result, idx) => (
                            <SearchResult
                                idx={idx}
                                type={result?.result_type === 'place' ? 'google_place' : 'steps_place'}
                                className={styles.searchResult}
                                key={result._id}
                                iconSrc={iconType.step}
                                content={result?.title || result?.description}
                                subContent={result.geocode_address?.formatted_address || result.secondary_title}
                                archived={result?.archived}
                                isImportMode={isImportMode}
                                postsAmount={!ghostStep || changeLocationShow ? null : '0'}
                                onClickAddPost={async () => {
                                    if (isSearchResultProcessed) return;
                                    isSearchResultProcessed = true;
                                    if (!result?.location) {
                                        await getPlaceCoordinates(result);
                                    }
                                    onSelectedPlace && onSelectedPlace(getResultStructre(result, idx + 1));
                                    isSearchResultProcessed = false;
                                }}
                                onClick={async () => {
                                    if (isSearchResultProcessed) return;
                                    isSearchResultProcessed = true;
                                    if (changeLocationShow) {
                                        await selectChangeLocation(getResultStructre(result, idx + 1));
                                    } else {
                                        if (!result?.location) {
                                            await getPlaceCoordinates(result);
                                        }
                                        onSelectedPlace && onSelectedPlace(getResultStructre(result, idx + 1));
                                    }
                                    isSearchResultProcessed = false;
                                }}
                            />
                        ))}
                    </div>
                )}
                {stepsResults?.length > 0 && (
                    <div className={styles.container}>
                        <p className={styles.categoryTitle}>{copies.steps_on_map}</p>
                        {stepsResults.map((result, idx) => (
                            <SearchResult
                                idx={idx}
                                type={result?.result_type}
                                className={styles.searchResult}
                                key={result._id}
                                iconSrc={iconType.step}
                                content={result.title}
                                subContent={result.geocode_address?.formatted_address || result.secondary_title}
                                postsAmount={result['posts_count'] || 1}
                                onClick={
                                    changeLocationShow
                                        ? () => {
                                              selectChangeLocation(getResultStructre(result, placeLocationsResults?.length + idx + 1));
                                          }
                                        : () => {
                                              onSelectedPlace && onSelectedPlace(getResultStructre(result, placeLocationsResults?.length + idx + 1));
                                          }
                                }
                            />
                        ))}
                    </div>
                )}
                {areaAddressResults?.length > 0 && !ghostStep && (
                    <div className={styles.container}>
                        <p className={styles.categoryTitle}>Areas & Addresses</p>
                        {areaAddressResults.map((result, idx) => {
                            const resultNum = placeLocationsResults?.length + stepsResults?.length + (idx + 1);
                            return (
                                <SearchResult
                                    idx={idx}
                                    type={result?.result_type === 'area' ? result?.result_type : 'street_num'}
                                    archived={result?.archived}
                                    className={styles.searchResult}
                                    key={result['place_id']}
                                    iconSrc={iconType.globe}
                                    content={result.description?.split(', ')[0] || result.title?.split(', ')[0]}
                                    subContent={result.description?.split(', ')[1] || result.title?.split(', ')[1]}
                                    postsAmount={null}
                                    onClick={
                                        result['result_type'] === 'area'
                                            ? () => onClickArea(result, resultNum)
                                            : changeLocationShow
                                            ? () => selectChangeLocation(result)
                                            : () => onClickPlace(result, 'area', resultNum)
                                    }
                                />
                            );
                        })}
                    </div>
                )}
            </>
        );
    }, [stepsResults, changeLocationShow, placeLocationsResults, areaAddressResults, ghostStep]);

    const getPlaceCoordinates = async place => {
        const accessToken = localStorage.getItem('at');
        let response = await fetch(`${config.domain.address}/steps3/search/placeidtocoordinate?place_id=${place['place_id'] || place?.placeId}`, {
            headers: {
                Authorization: accessToken,
                app_version: config.version.path,
                app_platform: 'map_manager',
            },
        });
        response = await response.json();
        if (place?.location) {
            place.location = response.geometry.location;
        } else {
            place.position = response.geometry.location;
        }
        return place;
    };

    const onClickArea = useCallback(
        async (place, resultNum) => {
            trackResultClick('area', resultNum);
            if (place['place_id'] !== ghostStep?._id) setSearchValue('');
            dispatch(mapActions.setGhostStep(mapId, null));
            await getPlaceCoordinates(place);
            dispatch(
                mapActions.setMapCenter(mapId, {
                    position: place?.location || place?.position,
                    zoom: 13,
                })
            );
        },
        [mapId, ghostStep]
    );

    const onClickPlace = async (place, type = null, resultNum) => {
        dispatch(mapActions.setGhostStep(mapId, null));
        await getPlaceCoordinates(place);
        switch (place.types[0]) {
            case 'street_address':
                if (!type) {
                    dispatch(
                        mapActions.setGhostStep(mapId, {
                            position: place.location,
                            title: place.description.split(', ')[0],
                            subTitle: place.description.split(', ')[1],
                            suggestToAdd: false,
                            isOpenTooltip: true,
                            isStickToMap: false,
                        })
                    );
                }
                trackResultClick('street_num', resultNum);
                setSearchValue('');
                dispatch(
                    mapActions.setMapCenter(mapId, {
                        position: place?.location || place?.position,
                        zoom: 18,
                    })
                );
                break;
            case 'route':
                setSearchValue('');
                dispatch(
                    mapActions.setMapCenter(mapId, {
                        position: place?.location || place?.position,
                        zoom: 18,
                    })
                );
                break;
            case 'locality':
                setSearchValue('');
                dispatch(
                    mapActions.setMapCenter(mapId, {
                        position: place?.location || place?.position,
                        zoom: 13,
                    })
                );
                break;
            case 'country':
                setSearchValue('');
                dispatch(
                    mapActions.setMapCenter(mapId, {
                        position: place?.location || place?.position,
                        zoom: 7,
                    })
                );
                break;
            default:
                onSelectedPlace(getResultStructre(place, resultNum));
        }
    };

    const getResultStructre = (result, resultNum) => {
        switch (result['result_type']) {
            case 'step':
                trackResultClick('step', resultNum, result._id);
                return {
                    title: result.title,
                    position: { lat: result.lat, lon: result.lon },
                    type: 'step',
                    stepId: result._id,
                };
            case 'location':
                trackResultClick('location', resultNum, result._id);
                return {
                    title: result.title,
                    position: { lat: result.lat, lon: result.lon },
                    type: 'location',
                    locationId: result._id,
                };
            case 'place':
                trackResultClick('place', resultNum, result._id);
                return {
                    title: result?.title || result.description.split(', ')[0],
                    position: result?.location || result?.position,
                    type: 'place',
                    placeId: result['place_id'],
                };
            default:
                return null;
        }
    };

    const onClickPrev = () => {
        if (ghostStep) {
            dispatch(mapActions.setGhostStep(mapId, null));
            setTitle(copies.title);
            setSearchValue('');
        } else {
            onCancel && onCancel();
        }
    };

    const selectChangeLocation = async result => {
        if (!modalData && step?.place_id) {
            setModalData(result);
            return;
        }
        setModalData(null);

        if (result?.placeId || result?.place_id) {
            await getPlaceCoordinates(result);
            switch (result?.types?.[0] || result?.type) {
                case 'street_address':
                    dispatch(
                        mapActions.setGhostStep(mapId, {
                            position: result.position,
                            title: 'selected location',
                            suggestToAdd: false,
                            isOpenTooltip: false,
                            isStickToMap: false,
                        })
                    );
                    isImportMode && setTitle(copies.createStep.fields.name.title);
                    setSearchValue('');
                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: result?.location || result?.position,
                            zoom: 18,
                        })
                    );
                    break;
                case 'route':
                    setSearchValue('');
                    dispatch(mapActions.setGhostStep(mapId, null));
                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: result?.location || result?.position,
                            zoom: 18,
                        })
                    );
                    break;
                case 'locality':
                    setSearchValue('');
                    dispatch(mapActions.setGhostStep(mapId, null));
                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: result?.location || result?.position,
                            zoom: 13,
                        })
                    );
                    break;
                case 'country':
                    setSearchValue('');
                    dispatch(mapActions.setGhostStep(mapId, null));
                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: result?.location || result?.position,
                            zoom: 7,
                        })
                    );
                    break;
                default:
                    dispatch(
                        mapActions.setGhostStep(mapId, {
                            position: result?.location || result?.position,
                            title: result?.description || result?.title,
                            suggestToAdd: false,
                            isOpenTooltip: true,
                            isStickToMap: false,
                            isConfirmLocation: true,
                            type: result.type,
                            _id: result.locationId || result.placeId || result.stepId,
                            changeLocationStepId: step?._id,
                        })
                    );
                    dispatch(
                        mapActions.setMapCenter(mapId, {
                            position: result?.location || result?.position,
                            zoom: 18,
                        })
                    );
            }
        } else {
            dispatch(
                mapActions.setGhostStep(mapId, {
                    position: result?.position || result?.location,
                    title: result?.title || result?.description,
                    suggestToAdd: false,
                    isOpenTooltip: true,
                    isStickToMap: false,
                    isConfirmLocation: true,
                    type: result.type,
                    _id: result.locationId || result.placeId || result.stepId,
                    changeLocationStepId: step?._id,
                })
            );
            dispatch(
                mapActions.setMapCenter(mapId, {
                    position: result?.position || result?.location,
                    zoom: 18,
                })
            );
        }
    };

    // TODO: copies
    return (
        <>
            {modalData && (
                <ConfirmModal
                    tertiaryText={'Cancel'}
                    textDanger={'Continue'}
                    text='Are you sure you want to change this location?'
                    subText={
                        'Changing the location will remove information about this place that was obtained from Google (ex: website, telephone number, photos, etc.)'
                    }
                    onCancel={() => setModalData(null)}
                    onDiscard={() => {
                        selectChangeLocation(modalData);
                    }}
                />
            )}
            <div className={styles.block}>
                <div className={styles.top}>
                    <div className={styles.something}>
                        <img
                            alt='Previous icon'
                            className={styles.previous}
                            src={changeLocationShow ? '/assets/img/icon_cancel_blue.svg' : '/assets/img/icon-arrow-left.svg'}
                            onClick={onClickPrev}
                        />
                        <div className={styles.title}>
                            <span>{title}</span>
                        </div>
                        <div className={styles.searchInputDiv}>
                            <SearchInput
                                className={styles.searchInput}
                                placeholder={!ghostStep ? copies.placeholder : copies.createStep.fields.name.placeholder}
                                onChange={e => setSearchValue(e.target.value)}
                                value={searchValue}
                                hiddenIcon={!!ghostStep}
                                searchLocation
                            />
                        </div>
                        {title === copies.createStep.fields.name.title && ghostStep && (
                            <div className={styles.subTitle}>
                                <span>Suggestions near ‘{ghostStep.title}’</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.content}>
                    <CoordinatesSearchResults latitude={coordinates.lat} longitute={coordinates.lon} onResults={setPlaceLocationsResults} />
                    {isLoading ? <SearchResultsSkeleton /> : renderSearchResults()}
                    {searchValue !== '' && !isLoading && (
                        <div style={{ margin: '21.5px 24px' }}>
                            <span className={styles.resultNotFound} onClick={() => onClickLocationNotFound(searchValue, ghostStep)}>
                                Can’t find it?{' '}
                                <span className={styles.bold}>
                                    Create a new place for ‘{searchValue}’ {ghostStep?.isOpenTooltip ? `at '${ghostStep.title}'` : 'at pinned location'}
                                </span>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SelectLocation;
