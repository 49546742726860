export const buttonCta = {
    NEXT: 'next',
    INSIGHTS: 'insights',
    ANNOUNCEMENT: 'announcement',
    GOT_IT: 'gotIt',
}

export const levelOneTasks = {
    ADD_20_STEPS_TO_MAP: {
        title: "Add 20 places",
        cta: 'Add more'
    },
    GET_25_MEMBERS: {
        title: "Get 25 map members",
        cta: 'Share map',
    },
    ADD_DESCRIPTION_TO_MAP: {
        title: "Add a map description",
        cta: 'Edit description'
    },
    ADD_3_TAGS_TO_MAP: {
        title: "Add 3 tags",
        cta: 'Add more'
    },
}

export const finishedJourney = {
    "ADD_20_STEPS_TO_MAP": {
        "total": 20,
        "progress": 20
    },
    "GET_25_MEMBERS": {
        "total": 25,
        "progress": 25
    },
    "ADD_DESCRIPTION_TO_MAP": {
        "total": 1,
        "progress": 1
    },
    "ADD_3_TAGS_TO_MAP": {
        "total": 3,
        "progress": 3
    }
}