import BaseModal, { ModalSizes } from '../../../../modalSysyem/BaseModal';
import CustomButton, { ButtonSize, ButtonType } from '../../buttons/CustomButton';
import styles from './index.module.scss';

const ModalDelete = ({ content, header, cancelContent, okContent, showModal, onDelete, onCancel }) => {
    return (
        <BaseModal isActive={showModal} size={ModalSizes.SMALL} onClose={onCancel}>
            <div className={styles.container}>
                <span className={styles.header}>{header}</span>
                <span className={styles.body}>{content}</span>

                <div className={styles.buttonsPanel}>
                    <span onClick={onCancel} className={styles.cancel}>
                        {cancelContent}
                    </span>
                    <CustomButton text={okContent} type={ButtonType.DANGER} size={ButtonSize.MEDIUM} onClick={onDelete} className={styles.deleteButton} />
                </div>
            </div>
        </BaseModal>
    );
};

export default ModalDelete;
