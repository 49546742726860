import classNames from 'classnames';
import styles from './index.module.scss';

const Skeleton = () => (
    <div className={styles.block}>
        <span className={classNames(styles.image, 'animate')} />
        <div className={styles.rightSection}>
            <span className={classNames(styles.title, 'animate')} />
            <div className={styles.tags}>
                <span className={classNames(styles.tag, 'animate')} />
                <span className={classNames(styles.tag, 'animate')} />
                <span className={classNames(styles.tag, 'animate')} />
            </div>
            <div className={styles.engagements}>
                <span className={classNames(styles.highlight, 'animate')} />
                <span className={classNames(styles.like, 'animate')} />
                <span className={classNames(styles.count, 'animate')} />
                <span className={classNames(styles.count, styles.postsCount, 'animate')} />
                <span className={classNames(styles.label, 'animate')} />
                <span className={classNames(styles.count, styles.viewsCount, 'animate')} />
                <span className={classNames(styles.label, 'animate')} />
            </div>
        </div>
    </div>
);

export default Skeleton;
