import React from 'react';
import styles from './index.module.scss';
import config from '../../../../config';
import { GoogleLogin } from 'react-google-login';

const GoogleLoginButton = ({ handleSocialLogin, handleSocialLoginFailure, text, fireMixpanelEvent }) => {
  return (
      <div onClick={fireMixpanelEvent}>
        <GoogleLogin
            clientId={config.social.googleClientId}
            render={renderProps => (
                <button className={styles.googleButton} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <img className={styles.googleIcon} src='/assets/img/steps3LoginLayout/Google.svg' alt=''/>
                  {text}
                </button>
            )}
            onSuccess={user =>
                handleSocialLogin(user, 'google')}
            onFailure={handleSocialLoginFailure}
            cookiePolicy='single_host_origin'
            uxMode='redirect'
            redirectUri={`${window.location.origin}/login`}
        />
      </div>
  );
};

export default GoogleLoginButton;
