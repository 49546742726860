import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../../../redux/user";
import { mapActions, mapSelectors } from "../../../../redux/map";
import styles from "./index.module.scss";
import Table from "./components/Table";
import SearchInput, { FilterButton } from "../../../../components/common/inputs/SearchInput2";
import { filterStepsByTags, getStepsByBounds, mapTagObjects } from "../../../../helpers";
import { mixPanelAddTagsClick, mixPanelBulkArchiveClick } from "./helpers";
import { headerAlign } from "./components/Table/Header";
import { SelectModes } from "../../../../constants";
import { SortDirection } from "../../../../constants/sort";
import { useDebounce, useModal } from "../../../../hooks";
import NoStepsMatch from "./components/NoStepsMatch";
import EmptyMap from "./components/EmptyMap";
import BulkActions from "./components/BulkActions";
import ArchivePopUp from "../../../../components/ArchivedStepPopup";
import TagsModal from "../../../../components/common/modals/ModalsSelectTags";
import copiesPrefix from "../../../../copies.json";
import { generatePath, useNavigate, useOutletContext } from "react-router";
import { TagsContext } from "../../../../context";
import Routes from "../../../../config/routes";
import { userActionActions } from "../../../../redux/userAction";
import ModalsCreatePost from "../../../../components/common/modals/ModalsCreatePost";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents, mixpanelProperties } from "../../../../helpers/mixpanel";
import { PostPage } from "../../../../components/PostStructure/PostPage";
import StepProfile from "../../../../components/StepProfile";

const copies = copiesPrefix.listView;

const sortDefaultValue = { selector: "title", orderBy: SortDirection.ASC };
const selectionDefaultValue = { mode: SelectModes.SELECT, steps: [] };
const ModalTypes = {
  TAGS: "tags",
  ARCHIVE: "archive",
  ADD_POST: "addPost"
};

const ListView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { toggleBulkLoader } = useOutletContext();
  const { onClickFilterButton, clearFilteredTag: clearTagsFilter, selectedTags } = useContext(TagsContext);
  
  const mapId = useSelector(userSelectors.selectedMapId);
  const user = useSelector(userSelectors.user);
  const map = useSelector(mapSelectors.map(mapId));
  const mapTotalSteps = useSelector(mapSelectors.map(mapId))?.steps_count;
  const { modalOpen: openedModalType, setModalOpen: setModal } = useModal(false);
  const [stepsList, setStepsList] = useState({
    steps: [],
    cursor: null,
    total: 0
  });
  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 250);
  const [selection, setSelection] = useState(selectionDefaultValue);
  const [sort, setSort] = useState(sortDefaultValue);
  const sortDebounce = useDebounce(sort, 250);
  const [loading, setLoading] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const shouldDisplayClearFiltersButton = stepsList.total !== mapTotalSteps && selectedTags?.length > 0 && (stepsList.cursor || stepsList.firstCursor);
  
  const [postPageIdToOpen, setPostPageIdToOpen] = useState("");
  const [stepProfileIdToOpen, setStepProfileIdToOpen] = useState("");
  
  const columns = [
    { selector: "title", title: "Place Name", align: headerAlign.LEFT, sortAble: true },
    { selector: "last_edited", title: "Last edit", sortAble: true },
    { selector: "tags", title: "Tags" },
    { selector: "posts_count", title: "Posts", sortAble: true },
    { selector: "likes_count", title: "Likes", sortAble: true },
    { selector: "views_count", title: "Views", sortAble: true }
  ];
  const selectedTagsIds = useMemo(() => {
    return selectedTags.map(tag => tag._id);
  }, [selectedTags]);
  
  // Tags Modal data - should be placed on StepsManager
  const tag_objects = useSelector(mapSelectors.tag_objects(map?._id));
  let tagsObject = mapTagObjects(tag_objects, map?.tags_categories);
  const [tagsModalData, setTagsModalData] = useState({ tags: [], stepIds: [] });
  
  useEffect(() => {
    loadResults(true);
  }, [searchDebounce, sortDebounce, selectedTagsIds, selectedTags]);
  
  useEffect(() => {
    clearAllDataFilters();
  }, [mapId]);
  
  useEffect(() => {
    if (stepsList.steps.length === 0) return;
    setStepsList(prevStepsList => {
      prevStepsList.steps.forEach(step => {
        step.isSelected = isStepSelected(step._id);
      });
      return { ...prevStepsList };
    });
  }, [selection, stepsList.steps]);
  
  useEffect(() => {
    if (searchValue !== "") setSearchLoader(true);
  }, [searchValue]);
  
  const loadResults = async shouldRemovePrevResults => {
    if ((!shouldRemovePrevResults && !stepsList.cursor) || loading) return;
    setLoading(true);
    const result = await getStepsByBounds({
      mapId,
      cursor: !shouldRemovePrevResults ? stepsList.cursor : null,
      tag: selectedTagsIds,
      orderedBy: sortDebounce.orderBy,
      sortedBy: sortDebounce.selector,
      searchTerm: searchDebounce,
      status: "active",
      limit: shouldRemovePrevResults ? 20 : undefined
    });
    dispatch(mapActions.updateStepsFromFetch(mapId, result.steps));
    if (shouldRemovePrevResults) {
      setStepsList(result);
    } else {
      setStepsList(prevStepList => ({
        steps: prevStepList.steps.concat(result.steps),
        cursor: result.cursor,
        firstCursor: result.firstCursor || prevStepList.firstCursor,
        total: result.total
      }));
    }
    setLoading(false);
    setSearchLoader(false);
  };
  
  const updateStepPostsCount = (stepId, deleteCount, countComments) => {
    const data = {
      stepId,
      delete: deleteCount,
      count_comments: countComments
    };
    if (!deleteCount) {
      delete data.delete;
    }
    const step = stepsList?.steps?.find(elem => elem?._id === data?.stepId);
    if (step) {
      if (data.delete) {
        step.posts_count = step.posts_count - 1 - data?.count_comments;
      } else {
        step.posts_count = step.posts_count + 1;
      }
      dispatch(mapActions.updateStepsList(mapId, stepsList));
    }
  };
  
  const isStepSelected = stepId => {
    if (!stepId) return false;
    const isSelected = selection.steps.some(rowId => rowId === stepId);
    if (selection.mode === SelectModes.SELECT) return isSelected;
    return !isSelected;
  };
  
  const onSelectRow = rowId => {
    const isCheckedAlready = selection.steps.find(stepId => stepId === rowId);
    const isLastStepChecked = !isCheckedAlready && selection.mode === SelectModes.SELECT && selection.steps.length === mapTotalSteps - 1;
    const singleLastStepUncheked = !isCheckedAlready && selection.mode === SelectModes.UNSELECT && selection.steps.length === mapTotalSteps - 1;
    if (isLastStepChecked) {
      setSelection({ mode: SelectModes.UNSELECT, steps: [] });
      return;
    }
    if (singleLastStepUncheked) {
      setSelection({ mode: SelectModes.SELECT, steps: [] });
      return;
    }
    if (isCheckedAlready) setSelection(prevSelection => ({ ...prevSelection, steps: prevSelection.steps.filter(stepId => stepId !== rowId) }));
    else setSelection(prevSelection => ({ ...prevSelection, steps: [...prevSelection.steps, rowId] }));
  };
  
  const onClickSelectAll = () => {
    const selectedStepsLength = selection.steps.length;
    const onUnselectMode = selection.mode === SelectModes.UNSELECT;
    const shouldUnselectAll = selectedStepsLength > 0 || (onUnselectMode && selectedStepsLength === 0);
    const isFiltered = selectedTags.length > 0;
    
    if (isFiltered) {
      const filteredStepsIds = findAllFilteredStepsIds();
      setSelection({
        mode: SelectModes.SELECT,
        steps: selectedStepsLength === 0 ? filteredStepsIds : []
      });
      return;
    }
    setSelection({
      mode: shouldUnselectAll ? SelectModes.SELECT : SelectModes.UNSELECT,
      steps: []
    });
  };
  
  const selectAllMode = () => {
    const noSelectedSteps = selection.steps.length === 0;
    if (selection.mode === SelectModes.UNSELECT && noSelectedSteps) return SelectModes.SELECT;
    if (selection.mode === SelectModes.SELECT && noSelectedSteps) return;
    return SelectModes.UNSELECT;
  };
  
  const changeSort = columnTitle => {
    if (columnTitle !== sort.selector) setSort({ selector: columnTitle, orderBy: SortDirection.ASC });
    else
      setSort(prevSort => ({
        ...prevSort,
        orderBy: prevSort.orderBy === SortDirection.ASC ? SortDirection.DES : SortDirection.ASC
      }));
  };
  
  const selectedRowsCount = useMemo(() => {
    const selectionLength = selection.steps.length;
    if (selection.mode === SelectModes.SELECT) return selectionLength;
    return mapTotalSteps - selectionLength;
  }, [selection]);
  
  const toggleModal = modalType => {
    if (!openedModalType) setModal(modalType);
    else setModal(false);
  };
  
  const archiveSteps = stepIds => {
    // if(selection.mode === SelectModes.UNSELECT) reload list to find all deleted steps
    setStepsList(prevStepsList => {
      const prevSteps = prevStepsList.steps;
      const updatedSteps = prevSteps.filter(step => !stepIds.includes(step._id));
      const deletedStepsCount = prevSteps.length - updatedSteps.length;
      const updatedTotal = prevStepsList.total - deletedStepsCount;
      return { ...prevStepsList, steps: updatedSteps, total: updatedTotal };
    });
    setSelection(selectionDefaultValue);
  };
  
  const tagsClick = clickedStep => {
    if (selectedRowsCount === 0) {
      const tagsObject = clickedStep?.components?.find(component => component.id === "selected_tags")?.tags;
      const selectedTags = tagsObject?.filter(tag => tag.is_selected);
      setTagsModalData({
        tags: selectedTags,
        stepIds: [clickedStep._id],
        stepCreatorId: "",
        stepTitle: clickedStep.title
      });
    } else {
      // @TODO - Move tagsModal to stepsManager and remove this code
      let selectedTags = [];
      const steps = Object.values(map.steps);
      const selectedStep = clickedStep || steps.find(step => isStepSelected(step._id));
      let tagsComponent = selectedStep.components?.find(component => component.type === "selected_tags");
      if (tagsComponent) {
        selectedTags = tagsComponent.tags.filter(tag => tag.is_selected);
      } else {
        const selectedStepTagIds = selectedStep.selected_tags_ids;
        selectedTags = tag_objects.filter(tagObj => selectedStepTagIds.includes(tagObj._id));
      }
      const newSelectionStepsArr = clickedStep && !isStepSelected(clickedStep?._id) ? [...selection.steps, clickedStep?._id] : selection.steps;
      if (clickedStep) {
        setTagsModalData({
          tags: selectedTags,
          stepIds: [clickedStep._id]
        });
      } else {
        steps.forEach(step => {
          if (selectedTags.length === 0) {
            setTagsModalData({
              tags: [],
              stepIds: newSelectionStepsArr
            });
            return;
          }
          if (isStepSelected(step._id) || step._id === selectedStep._id) {
            const stepSelectedTagsIds = step.selected_tags_ids;
            selectedTags = selectedTags.filter(tag => stepSelectedTagsIds.includes(tag._id));
          }
        });
        setTagsModalData({
          tags: selectedTags,
          stepIds: newSelectionStepsArr
        });
      }
    }
    setModal(ModalTypes.TAGS);
    mixPanelAddTagsClick(selectedRowsCount);
  };
  
  const updateTags = async(newTags, tagsToDelete, tagsToAdd, stepIds) => {
    const isUnselectMode = selection.mode === SelectModes.UNSELECT;
    const firstCursor = isUnselectMode ? stepsList.firstCursor : null;
    dispatch(mapActions.bulkAddTags(mapId, stepIds, tagsToAdd, tagsToDelete, newTags, isUnselectMode, firstCursor));await loadResults(true);
    setSelection(selectionDefaultValue);
  };
  
  const renderNoStepsComponent = () => {
    if (mapTotalSteps === 0) return <EmptyMap onClickAddStep={() => navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`)}/>;
    return <NoStepsMatch onClickClearFilters={clearAllDataFilters} isSearchValue={searchDebounce}/>;
  };
  
  const findAllFilteredStepsIds = () => {
    const filteredSteps = filterStepsByTags(selectedTags, Object.values(map.steps));
    const filteredStepsIds = filteredSteps.map(step => step._id);
    return filteredStepsIds;
  };
  
  const navigateToStepProfile = ({ step, openTags, fromWhere, viewOnMap, editLocation = true }) => {
    mixpanel.track(mixpanelEvents.STEP_PAGE_OPEN, {
      [mixpanelProperties.STEP_TITLE]: step?.title,
      [mixpanelProperties.FROM_WHERE]: fromWhere
    });
    const pathname = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId: step?._id });
    navigate({ pathname });
  };
  
  const onClickRow = step => {
    //navigateToStepProfile({ step, fromWhere: 'list_view', viewOnMap: true, editLocation: false });
    setStepProfileIdToOpen(step._id);
    mixpanel.track(mixpanelEvents.TAGS_POPUP_OPEN, {
      [mixpanelProperties.FROM_WHERE]: "lv_table"
    });
  };
  
  const closeStepProfile = () => navigate("/" + Routes.MAP);
  
  const clearAllDataFilters = () => {
    clearTagsFilter();
    setSort(sortDefaultValue);
  };
  
  const onClickPosts = step => {
    setModal(ModalTypes.ADD_POST);
    dispatch(
      userActionActions.setSelectedPlaceToAdd({
        title: step?.title || step?.location_title,
        position: [step?.lat, step?.lon],
        type: "step",
        stepId: step?._id || step?.step_id
      })
    );
  };
  
  return (
    <div className={styles.block}>
      <div className={styles.searchBar}>
        <SearchInput
          value={searchValue}
          onChange={e => {
            const inputValue = e.target.value;
            setSearchValue(inputValue);
          }}
          onClickDiscard={() => setSearchValue("")}
          loading={searchLoader}
        />
        <FilterButton className={styles.filterButton} onClick={onClickFilterButton} selectedFilters={selectedTagsIds.length}/>
      </div>
      {mapTotalSteps > 0 && (
        <div className={styles.details}>
          <span className={styles.stepsCount}>
            {copies.showing} {stepsList.steps.length} / {stepsList.total} {copies.steps}
          </span>
          {shouldDisplayClearFiltersButton && (
            <span className={styles.showAll} onClick={clearAllDataFilters}>
              {copies.clear_filters}
            </span>
          )}
        </div>
      )}
      {stepsList.total > 0 || (stepsList.total === 0 && loading && mapTotalSteps > 0) ? (
        <Table
          columns={columns}
          data={stepsList.steps}
          onClickRow={onClickRow}
          onSelectRow={onSelectRow}
          onClickTags={tagsClick}
          onClickSelectAll={onClickSelectAll}
          onClickPosts={onClickPosts}
          selectAllMode={selectAllMode()}
          sortBy={sort.selector}
          orderBy={sort.orderBy}
          changeSort={changeSort}
          onScrollToBottom={loadResults}
          isLoading={loading}
        />
      ) : (
        renderNoStepsComponent()
      )}
      {selectedRowsCount > 0 && (
        <BulkActions
          handleAddTags={() => {
            tagsClick();
            toggleModal(ModalTypes.TAGS);
          }}
          handleArchive={() => {
            toggleModal(ModalTypes.ARCHIVE);
            mixPanelBulkArchiveClick(selectedRowsCount);
          }}
          onClose={() => setSelection(selectionDefaultValue)}
          number={selectedRowsCount}
        />
      )}
      {openedModalType === ModalTypes.ARCHIVE && (
        <ArchivePopUp
          mapId={mapId}
          forSeveralSteps
          closeStepProfile={closeStepProfile}
          setDeleteIdStep={archiveSteps}
          stepId={selection.steps}
          archiveAll={selection.mode === SelectModes.UNSELECT}
          handleArchivePopup={() => toggleModal()}
          firstCursor={stepsList.firstCursor}
          setBulkLoader={toggleBulkLoader}
        />
      )}
      {openedModalType === ModalTypes.TAGS && (
        <TagsModal
          fromWhere={"lv pop up"}
          selectedModalTags={tagsModalData}
          isSelectTagsModalOpen
          pendingSelectedTags={tagsModalData.tags}
          setPendingSelectedTags={updateTags}
          map={map}
          tagsObject={tagsObject}
          onCancel={toggleModal}
        />
      )}
      {openedModalType === ModalTypes.ADD_POST && (
        <ModalsCreatePost
          updateStepPostsCount={updateStepPostsCount}
          onCancel={() => {
            toggleModal();
            dispatch(userActionActions.setSelectedPlaceToAdd(null));
            dispatch(mapActions.setGhostStep(mapId, null));
          }}
          onClose={toggleModal}
          clickReplyPage={false}
          mapId={mapId}
          user={user}
          listView
        />
      )}
      <div className={styles.stepProfileContainer}>
        {!!postPageIdToOpen && (
          <PostPage key={postPageIdToOpen} postId={postPageIdToOpen} onBack={() => setPostPageIdToOpen("")} className={styles.postPage}/>
        )}
        {!!stepProfileIdToOpen && (
          <StepProfile
            key={stepProfileIdToOpen}
            stepId={stepProfileIdToOpen}
            onBack={() => setStepProfileIdToOpen("")}
            onClickPost={postId => setPostPageIdToOpen(postId)}
            viewOnMap
          />
        )}
      </div>
    </div>
  );
};

export default ListView;
