import React from 'react';

const IconTitle = ({ stroke }) => (
    <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'>
        <g stroke={stroke} strokeWidth='2' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
            <path d='M1 3.625V1h14v2.625M5.375 15h5.25M8 1v14' />
        </g>
    </svg>
);

export default IconTitle;
