export const PostTypes = {
    ANNOUNCEMENT: 'ANNOUNCEMENT',
    POST: 'CREATED_BY',
    NORMAL: 'NORMAL',
    FEED: 'FEED',
    STEP: 'STEP',
    EDIT_POST: 'edit_post',
};

// TODO: rename
export const TagTypes = {
    STEP_TAG: 'step_tag',
    PLACE_TAG: 'place_tag',
    USER_TAG: 'user_tag',
    TEXT: 'text',
};

export const MixPanelTagTypes = {
    [TagTypes.STEP_TAG]: 'step',
    [TagTypes.PLACE_TAG]: 'google_place',
    [TagTypes.USER_TAG]: 'user',
};

export const MixpanelTagContext = {
    WHILE_POSTING: 'while_posting',
    AFTER_POSTING: 'after_posting',
};
