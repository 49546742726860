import React, { useState } from 'react';
import { NewTagInput } from '../../TagCategory/components/NewTagInput';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions } from '../../../redux/map';
import { userSelectors } from '../../../redux/user';
import { mapSelectors } from '../../../redux/map';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';
import { removeWhitespace } from '../../../helpers';
import { Plus } from 'phosphor-react';

const NewTagCategoryInput = ({ category, step }) => {
    const dispatch = useDispatch();
    const mapId = useSelector(userSelectors.selectedMapId);
    const [disable, setDisable] = useState(true);
    const [addNewTagObj, setAddNewTagObj] = useState({ categoryName: false });
    const [errorDuplicate, setErrorDuplicate] = useState(false);
    const mapTags = useSelector(mapSelectors.mapTags(mapId));

    const handleAddNewTagButton = categoryName => {
        mixpanel.track(mixpanelEvents.MM_STEP_CREATION_ADD_TAGS_CLICKED, {
            [mixpanelProperties.STEP_NAME]: step?.title,
            [mixpanelProperties.STEP_ID]: step?._id,
            [mixpanelProperties.CATEGORY]: categoryName
        })
        if (categoryName === addNewTagObj.categoryName) {
            setAddNewTagObj({ categoryName: false });
        } else {
            setAddNewTagObj({ categoryName });
        }
    };

    const handleTagSubmittion = value => {
        if (value.length > 0 && value.trim().length !== 0 && !disable) {
            handleSubmitNewTagToCategory(value);
        } else {
            setAddNewTagObj({ categoryName: false });
            setDisable(true);
        }
    };

    const handleSubmitNewTagToCategory = tagName => {
        if (tagName === '') {
            return handleUnsavedTag();
        }
        const newTag = {
            category_priority: category.category_priority,
            name: tagName,
            category_id: category.category_id,
            category: category.category,
        };
        dispatch(mapActions.addNewTagToMap(mapId, { tags: [newTag] }));
        setAddNewTagObj({ categoryName: false });
        setDisable(true);
    };

    const handleUnsavedTag = () => {
        setAddNewTagObj({ categoryName: false });
        mixpanel.track(mixpanelEvents.ADD_TAG, {
            [mixpanelProperties.ADD_TO]: 'map',
            [mixpanelProperties.FROM_WHERE]: 'step components',
            [mixpanelProperties.HOW]: 'category_button',
        });
    };

    const handleNewTagOnChange = tagName => {
        let isTagExist;
        const isEmpty = removeWhitespace(tagName);

        if (isEmpty !== '') {
            isTagExist = handleIsExist(tagName, mapTags);
            isTagExist = mapTags.find(t => {
                return tagName !== '' ? removeWhitespace(t.name).toLowerCase() === tagName.toLowerCase() : false;
            });
        }
        if (!isTagExist) {
            setErrorDuplicate(false);
            setDisable(true);
            if (tagName !== '') {
                setDisable(false);
            } else {
                setDisable(true);
            }
        } else {
            setErrorDuplicate(true);
            setDisable(true);
        }
    };

    const handleIsExist = (itemName, array, item = false) => {
        let ans;
        // If the user also sent an item (a full object)
        if (item) {
            ans = array.filter(Boolean).find(a => (itemName !== '' ? a.name.toLowerCase() === itemName.toLowerCase() && item._id !== a._id : false));
        } else {
            ans = array.filter(Boolean).find(a => (itemName !== '' ? a.name.toLowerCase() === itemName.toLowerCase() : false));
        }
        if (ans) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {addNewTagObj.categoryName === category?.category && (
                <NewTagInput
                    isDisabled={disable}
                    isValueDuplicate={errorDuplicate}
                    onClickAway={value => handleTagSubmittion(value)}
                    onSubmit={value => handleTagSubmittion(value)}
                    onChange={handleNewTagOnChange}
                    onKeyPress={(value, keyCode) => {
                        if (disable || keyCode !== 13 || value.length === 0) {
                            return;
                        }
                        handleSubmitNewTagToCategory(value, category);
                    }}
                    onCancel={() => {
                        handleUnsavedTag();
                    }}
                />
            )}
            {!(addNewTagObj && addNewTagObj.categoryName === category?.category) && (
                <button onClick={() => handleAddNewTagButton(category?.category)} className={styles.addNewTagButton}>
                    <Plus color='#515B67' size={18} />
                </button>
            )}
        </>
    );
};

export default NewTagCategoryInput;
