import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import styles from './UserTagPopover.module.scss';

const UserPointsDetails = ({ count = 0, name, icon: Icon }) => {
    if (count === 0) {
        return null;
    }

    return (
        <div className={classNames('flex-between m-b-8', styles.contribution)}>
            <span className='flex-center'>
                <span className='m-r-4 flex-center'>
                    <Icon size={20} />
                </span>
                <FormattedMessage id={`feed.userTagPopOver.${name}`} />
            </span>
            <span className='flex-center'>{count}</span>
        </div>
    );
};

export default UserPointsDetails;
