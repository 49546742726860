import { useEffect, useState } from "react";
import { AskAroundQuestion } from "./AskAroundQuestion";
import Logo from "../../components/common/Logo";
import { AskAroundLinkBox } from "./AskAroundLinkBox";
import { ToastContextProvider } from "../../context";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import mixpanel, { mixpanelEvents, mixpanelProperties } from "../../helpers/mixpanel";
import { isAndroid, isDesktop, isIOS } from "react-device-detect";

const AskAround = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const [link, setLink] = useState(null);
  
  const firstName = query.get("firstname");
  const lastName = query.get("lastname");
  const imageId = query.get("image_id");
  const userId = query.get("user_id");
  const targetMapId = query.get("target_map_id");
  const targetMapName = query.get("target_map_name");
  
  useEffect(() => {
    mixpanel.identify(userId);
  }, [userId]);
  
  useEffect(() => {
    mixpanel.register({
      [mixpanelProperties.MAP_ID]: targetMapId,
      [mixpanelProperties.MAP_NAME]: targetMapName,
      [mixpanelProperties.PLATFORM]: isDesktop ? 'desktop' : 'mobile',
      [mixpanelProperties.SOURCE_PLATFORM]: isIOS ? 'iOS' : isAndroid ? 'Android' : undefined,
    });
    
    mixpanel.track(mixpanelEvents.ASK_AROUND_SHOWED, {
      [mixpanelProperties.FLOW_TYPE]: "admin_flow"
    });
  }, []);
  
  useEffect(() => {
    document.body.style.height = "100%";
    const scrollableElement = document.querySelector("#ask-around-input");
    disablePageScroll(scrollableElement);
    
    return () => {
      enablePageScroll(scrollableElement);
    };
  }, []);
  
  if (!firstName || !targetMapId || !userId) {
    navigate("/");
    return null;
  }
  
  return (
    <HelmetProvider>
      <ToastContextProvider>
        <Helmet>
          <title>Atly | Ask Around</title>
          <meta name="theme-color" media="(prefers-color-scheme: light)"
                content="#fe4084"/>
          <meta name="theme-color" media="(prefers-color-scheme: dark)"
                content="#fe4084"/>
        </Helmet>
        <div id="ask-around"
             className="w-screen h-full bg-gradient-porange flex items-center fixed top-0">
          <div
            className="md:min-w-[400px] md:w-1/3 md:py-16 flex flex-col h-full w-full m-auto box-border">
            <div className="flex flex-col justify-between w-full h-full m-0">
              <div className="m-3">
                <AskAroundQuestion
                  isSubmitted={Boolean(link)}
                  userImageId={imageId}
                  userId={userId}
                  mapId={targetMapId}
                  mapName={targetMapName}
                  firstName={firstName}
                  lastName={lastName}
                  onSuccess={setLink}
                />
                {link &&
                  <div className="mt-3">
                    <AskAroundLinkBox
                      mapId={targetMapId}
                      mapName={targetMapName}
                      link={link}/>
                  </div>}
                <p className="text-white w-full text-center text-sm py-5 flex flex-col box-border">
                  Recs you receive will be added to<br/>
                  <b className="text-white font-bold">{targetMapName}</b>
                </p>
              </div>
              <div className="w-full text-center mb-5">
                <Logo type={Logo.Types.WHITE} size={Logo.Sizes.SMALL}/>
              </div>
            </div>
          </div>
        </div>
      </ToastContextProvider>
    </HelmetProvider>
  );
};

export default AskAround;