import classNames from "classnames";
import { noop } from "lodash";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import BaseModal, { ModalSizes } from "../../../../modalSysyem/BaseModal";
import CustomButton, { ButtonSize, ButtonType } from "../../../common/buttons/CustomButton";
import useAddStep from "./useAddStep";

import styles from "../FeedPost.module.scss";

const AddStepToMapModal = ({ placeId, placeName = "", mapName = "", isOpen = false, onClose = noop, onConfirm = noop }) => {
  const { setStepToAdd } = useAddStep();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <BaseModal isActive={isOpen} size={ModalSizes.MEDIUM} className={styles.addStepModal}
               onClose={onClose}>
      <div className={styles.header}></div>
      <div className={styles.body}>
        <h2 className={styles.title}>
          <FormattedMessage id="feed.addStep.title" defaultMessage="Add step"
                            values={{ placeName }} />
        </h2>
        <p>
          <FormattedMessage id="feed.addStep.description" values={{ placeName, mapName }} />
        </p>
      </div>
      <div className={classNames("flex space-between", styles.footer)}>
        <CustomButton type={ButtonType.GHOST2} size={ButtonSize.MEDIUM} onClick={onClose}>
          <FormattedMessage id="feed.addStep.cancel" />
        </CustomButton>
        <CustomButton
          loader={isLoading}
          type={ButtonType.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={async () => {
            setIsLoading(true);
            await setStepToAdd({ placeId });
            setIsLoading(false);
            onConfirm();
          }}
        >
          <FormattedMessage id="feed.addStep.confirm" />
        </CustomButton>
      </div>
    </BaseModal>
  );
};

export default AddStepToMapModal;
