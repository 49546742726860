import { useState, useEffect, useRef, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { usePopper } from 'react-popper';
import { userSelectors } from '../../../../../redux/user';
import { memberActions } from '../../../../../redux/member';
import { Member } from '../../../../../models';
import { useClickAway, usePortal } from '../../../../../hooks';
import { IconClap, ChatCentered, MapPin, ImageSquare, Star, Tag } from '../../../icons';
import { PopupContext } from '../../../../../context';
import UserPointsDetails from './UserPointsDetails';

import styles from './UserTagPopover.module.scss';

const UserTagPopover = ({ isActive = false, userId, isScrolling = false, children }) => {
    const dispatch = useDispatch();
    const popoverRef = useRef(null);
    const mapId = useSelector(userSelectors.selectedMapId);
    const Portal = usePortal('#user-tag-popover-portal');
    const [user, setUser] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const { setActivePopupId } = useContext(PopupContext);

    const isContributor = useMemo(() => {
        if (!user) {
            return false;
        }
        return user.stepsCount > 0 || user.postsCount > 0 || user.clapsCount > 0;
    }, [user]);

    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'right-end',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });

    useClickAway(popoverRef, () => {
        setActivePopupId(null);
    });

    useEffect(() => {
        if (!isScrolling || !isActive) {
            return;
        }

        setActivePopupId(null);
    }, [isScrolling]);

    useEffect(() => {
        if (!userId || !mapId || !isActive) {
            return;
        }

        (async () => {
            const results = await dispatch(memberActions.getMapFollowerDetails({ mapId, userId }));
            const user = results?.data?.user;
            if (!user) {
                return;
            }
            setUser(new Member(user, mapId));
        })();
    }, [dispatch, mapId, userId, isActive]);

    const renderPopoverContent = () => {
        if (!user) {
            return (
                <div ref={popoverRef} className={styles.userTagPopover}>
                    <div className={classNames('animate', styles.imageSkeleton)} />
                    <div className={styles.header}>
                        <div>
                            <h1 className={classNames('animate')} />
                            <span className={classNames('animate', styles.joinedAt)} />
                        </div>
                        <span className={classNames('animate', styles.roleTag)} />
                    </div>
                </div>
            );
        }

        return (
            <div ref={popoverRef}>
                <div className={styles.userImageWrapper}>
                    <img src={user.imageSrc} alt={user.name} className='animate' />
                </div>
                <div className={styles.header}>
                    <div className={styles.userInfoWrapper}>
                        <h1>{user.name}</h1>
                        <span className={styles.joinedAt}>
                            <FormattedMessage
                                id='feed.userTagPopOver.joined_at'
                                values={{
                                    date: user.joinedAtString,
                                }}
                            />
                        </span>
                    </div>
                    {isContributor && <span className={styles.roleTag}>Contributor</span>}
                </div>
                <div className={classNames(styles.body, user.pointsDetails.length === 0 && styles.empty)}>
                    <UserPointsDetails name='steps' count={user.stepsCount} icon={MapPin} />
                    <UserPointsDetails name='posts' count={user.postsCount} icon={ChatCentered} />
                    <UserPointsDetails name='photos' count={user.imagesCount} icon={ImageSquare} />
                    <UserPointsDetails name='ratings' count={user.ratingsCount} icon={Star} />
                    <UserPointsDetails name='tags' count={user.tagsCount} icon={Tag} />
                    <UserPointsDetails name='claps' count={user.clapsCount} icon={IconClap} />
                </div>
            </div>
        );
    };

    if (!isActive) {
        return children;
    }

    if (!userId || !mapId) {
        console.error('UserTagPopover: Missing required props', { isActive, userId, mapId });
        return children;
    }

    return (
        <>
            <span className={popperStyles.tooltip} ref={setReferenceElement}>
                {children}
            </span>
            <Portal>
                <div role='dialog' className={styles.userTagPopover} ref={setPopperElement} style={popperStyles.popper} {...attributes.popper}>
                    {renderPopoverContent()}
                </div>
            </Portal>
        </>
    );
};

export default UserTagPopover;
