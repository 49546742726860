import { Marker as LeafletMarker, DivIcon } from 'leaflet';
import { createLayerHook, createElementHook, LeafletProvider } from '@react-leaflet/core';
import React, { useImperativeHandle, forwardRef } from 'react';
import { createPortal } from 'react-dom';

function createMarker({ position, ...options }, ctx) {
    const divIcon = new DivIcon(options);
    const instance = new LeafletMarker(position, { icon: divIcon, ...options });
    return { instance, context: { ...ctx, overlayContainer: instance } };
}

function updateMarker(marker, props, prevProps) {
    if (props.position !== prevProps.position) {
        marker.setLatLng(props.position);
    }
    if (props.zIndexOffset != null && props.zIndexOffset !== prevProps.zIndexOffset) {
        marker.setZIndexOffset(props.zIndexOffset);
    }
    if (props.opacity != null && props.opacity !== prevProps.opacity) {
        marker.setOpacity(props.opacity);
    }
    if (marker.dragging != null && props.draggable !== prevProps.draggable) {
        if (props.draggable === true) {
            marker.dragging.enable();
        } else {
            marker.dragging.disable();
        }
    }
}

const createLayerCom = useElement => {
    function ContainerComponent(props, ref) {
        const { instance, context } = useElement(props).current;

        const container = context.overlayContainer._icon;

        useImperativeHandle(ref, () => instance);

        const provider = props.children == null ? null : <LeafletProvider value={context}>{props.children}</LeafletProvider>;
        if (!provider) {
            return null;
        }
        // return provider;
        if (container) return createPortal(provider, container);
        return provider;
    }

    return forwardRef(ContainerComponent);
};

const createCustomMarker = function () {
    const useElement = createElementHook(createMarker, updateMarker);
    const useLayer = createLayerHook(useElement);

    return createLayerCom(useLayer);
};
const CustomMarker = createCustomMarker();

export default CustomMarker;
