import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { mapActions, mapSelectors } from '../../redux/map';
import React, { useState } from 'react';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import Zoom from '@material-ui/core/Zoom';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ModalsDelete from '../common/modals/ModalsDelete';
import ModalsRename from '../common/modals/ModalsRename';
import copiesPrefix from '../../copies.json';
import { removeWhitespace } from '../../helpers';

const copies = copiesPrefix.tag;

const useStyles = makeStyles(() => ({
    tooltipBlock: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        color: '#fff',
        fontSize: '15px',
        maxWidth: '210px',
        fontWeight: 'normal',
        padding: '8px 16px',
        borderRadius: '4px',
        backgroundColor: '#3a3c3f',
        '& .MuiTooltip-arrow:before': {
            backgroundColor: '#3a3c3f',
        },
    },
}));

const Tag = ({ tag = {}, categories = [], category = {}, counter }) => {
    const [deleteTagPopUp, setDeleteTagPopUp] = useState(false);
    const [editValue, setEditValue] = useState(null);
    const [value, setValue] = useState(null);
    const [editTagPopUp, setEditTagPopUp] = useState(false);
    const mapId = useSelector(state => state.user.selectedMapId);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isExist, setIsExist] = useState(false);
    const [openedDropdown, setOpenedDropdown] = useState(false);
    const obj_tags = useSelector(mapSelectors.mapTags(mapId));

    const handleClose = () => {
        setOpenedDropdown(false);
    };

    const handleEditTagById = (tagName, tagId) => {
        dispatch(mapActions.editTags(mapId, tagId, removeWhitespace(tagName)));
        setEditTagPopUp(false);
    };

    const handleDeleteTagById = tagId => {
        dispatch(mapActions.deleteTags(mapId, [tagId]));
    };

    const handleMomveToCategory = (categoryId, tagId) => {
        dispatch(mapActions.moveToCategory(mapId, categoryId, tagId));
        mixpanel.track(mixpanelEvents.COMPONENTS_TAGS_3_DOTS, {
            [mixpanelProperties.ACT]: 'move to category',
        });
    };

    const handleIsExist = (itemName, array, item = false) => {
        // If the user also sent an item (a full object)
        const isExist = array.find(a => {
            const isSameId = a.id !== item.id;
            const isSameInput = removeWhitespace(a.name).toLowerCase() === removeWhitespace(itemName).toLowerCase();

            if (itemName !== '') {
                if (item) {
                    return isSameInput && isSameId;
                }

                return isSameInput;
            }

            return false;
        });

        setIsExist(isExist);
    };

    return (
        <div key={tag?._id} className={styles.tooltip}>
            <ClickAwayListener onClickAway={() => handleClose(category)}>
                <div className={styles.newSingleTag}>
                    <Tooltip
                        TransitionComponent={Zoom}
                        placement='top-center'
                        arrow
                        classes={{ tooltip: classes.tooltipBlock }}
                        title={
                            <>
                                {counter}
                                {copies.tooltip}
                            </>
                        }
                    >
                        <span key={tag?._id} className={styles.newSingleTagText}>
                            {tag.name}
                        </span>
                    </Tooltip>
                    <div
                        className={classNames(styles.dropMenuBlock, {
                            [styles.tagMenuIconActive]: openedDropdown,
                        })}
                        style={openedDropdown ? { backgroundColor: '#ddd' } : {}}
                    >
                        <img
                            alt='menu'
                            src={'/assets/img/dot_menu.svg'}
                            className={classNames(styles.tagMenuIcon, {
                                [styles.tagMenuIconActive]: openedDropdown,
                            })}
                            onClick={() => setOpenedDropdown(true)}
                        />
                        {openedDropdown && (
                            <ul className={styles.ulClassParrent}>
                                <li
                                    onClick={() => {
                                        mixpanel.track(mixpanelEvents.COMPONENTS_TAGS_3_DOTS, {
                                            [mixpanelProperties.ACT]: 'rename',
                                        });
                                        setEditTagPopUp(true);
                                        setEditValue(tag);
                                        setValue(tag);
                                        setOpenedDropdown(false);
                                    }}
                                    className={styles.parent}
                                >
                                    <a className={styles.text}>{copies.rename}</a>
                                </li>
                                {categories.length > 1 && (
                                    <li className={styles.parent}>
                                        <a className={styles.text}>{copies.move_to_category}</a>
                                        <span className={styles.expand}>
                                            <img src={'/assets/img/moreMenu.svg'} />
                                        </span>
                                        <ul
                                            className={classNames(styles.child, styles.ulClassChildren)}
                                            style={categories.length === 2 ? { padding: '3px 0' } : {}}
                                        >
                                            {categories.map(
                                                (c, i) =>
                                                    c._id !== category._id && (
                                                        <li
                                                            key={i}
                                                            onClick={() => {
                                                                handleMomveToCategory(c._id, tag._id);
                                                            }}
                                                        >
                                                            <a className={styles.text}>{c.name}</a>
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </li>
                                )}
                                <li
                                    onClick={() => {
                                        setDeleteTagPopUp(true);
                                        setEditValue(tag);
                                        setOpenedDropdown(false);
                                        mixpanel.track(mixpanelEvents.COMPONENTS_TAGS_3_DOTS, {
                                            [mixpanelProperties.ACT]: 'delete',
                                        });
                                    }}
                                    className={styles.parent}
                                >
                                    <a className={classNames(styles.text, styles.textDelete)}>{copies.delete}</a>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </ClickAwayListener>
            {editTagPopUp && (
                <ModalsRename
                    isExist={isExist}
                    oldName={tag.name}
                    content={editValue}
                    placeholder={copies.tag_name_placeholder}
                    textFirst={copies.rename_tag}
                    textSecond={copies.change_name_warning}
                    handleOnChange={e => {
                        handleIsExist(e, obj_tags, value);
                        setEditValue(editValue => ({
                            ...editValue,
                            name: e,
                        }));
                    }}
                    onCancel={() => {
                        setIsExist(false);
                        setEditTagPopUp(false);
                    }}
                    onRename={() => {
                        tag._id && handleEditTagById(editValue.name, tag._id);
                        setEditTagPopUp(false);
                        setIsExist(false);
                    }}
                />
            )}
            {deleteTagPopUp && (
                <ModalsDelete
                    textFirst={`${copies.delete_confirmation[0]}<b>${editValue.name}</b>${copies.delete_confirmation[1]}`}
                    textSecond={
                        <>
                            {copies.delete_warning[0]}
                            {counter}
                            {copies.delete_warning[1]}
                        </>
                    }
                    onCancel={() => setDeleteTagPopUp(false)}
                    onDecline={() => {
                        handleDeleteTagById(editValue._id);
                        setDeleteTagPopUp(false);
                    }}
                />
            )}
        </div>
    );
};

export default Tag;
