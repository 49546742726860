import { IMAGE_PREVIEW } from './types';

function setImagePreview({ imagePreviewShow, imagePreviewArray, imagePreviewIndex, stepId }) {
    return {
        type: IMAGE_PREVIEW,
        payload: { imagePreviewShow, imagePreviewArray, imagePreviewIndex, stepId },
    };
}

export const toggleImagePreview = ({ imagePreviewShow, imagePreviewArray, imagePreviewIndex, stepId }) => {
    return {
        type: IMAGE_PREVIEW,
        payload: { imagePreviewShow, imagePreviewArray, imagePreviewIndex, stepId },
    };
};
