import React, { forwardRef, useState } from 'react';
import styles from './index.module.scss';
import IconMoreSettingsCategories from '../common/icons/IconSettingsCategories';
import { ClickAwayListener } from '@material-ui/core';
import copiesPrefix from '../../copies.json';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';

const copies = copiesPrefix.ratings;

const CategoryRating = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    const { categoriesNumber, idx } = props;
    const needToShowMoveCategoryUp = categoriesNumber !== 1 && idx > 0;
    const needToShowMoveCategoryDown = categoriesNumber !== 1 && idx < categoriesNumber - 1;

    return (
        <div ref={ref} key={props?.item?.id} className={styles.categoryBlock}>
            <div className={styles.category}>
                <span>{props?.item?.text}</span>
                <button onClick={() => handleClick()} className={styles.buttonMore} style={{ background: open ? '#dddddd' : '#f7f7f7' }}>
                    <IconMoreSettingsCategories />
                </button>
            </div>
            {open && (
                <ClickAwayListener onClickAway={() => handleClick()}>
                    <div className={styles.menu}>
                        {needToShowMoveCategoryUp && (
                            <div
                                onClick={() => {
                                    mixpanel.track(mixpanelEvents.MM_SETTINGS_RATING_UP, {
                                        [mixpanelProperties.RATING_TITLE]: props?.item?.text,
                                    });
                                    props.handleUp(props?.idx);
                                    handleClick();
                                }}
                            >
                                <span style={{ color: '#3a3c3f' }}>{copies.move_category_up}</span>
                            </div>
                        )}
                        {needToShowMoveCategoryDown && (
                            <div
                                onClick={() => {
                                    mixpanel.track(mixpanelEvents.MM_SETTINGS_RATING_DOWN, {
                                        [mixpanelProperties.RATING_TITLE]: props?.item?.text,
                                    });
                                    props.handleDown(props?.idx);
                                    handleClick();
                                }}
                            >
                                <span style={{ color: '#3a3c3f' }}>{copies.move_category_down}</span>
                            </div>
                        )}
                        <div
                            onClick={() => {
                                mixpanel.track(mixpanelEvents.MM_SETTINGS_RATING_DELETED, {
                                    [mixpanelProperties.RATING_TITLE]: props?.item?.text,
                                });
                                props.handleDelete(props?.item.text, props?.id);
                                handleClick();
                            }}
                        >
                            <span style={{ color: '#ff5f4d' }}>{copies.delete_category}</span>
                        </div>
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
});

export default CategoryRating;
