import { noop } from 'lodash';
import React from 'react';

const IconRecommended = ({ stroke = 'none', fill = 'none', size = 21, onClick = noop }) => (
    <svg width={size} height={size} viewBox='0 0 21 20' fill={fill} xmlns='http://www.w3.org/2000/svg' onClick={onClick}>
        <path
            d='M10.5 1L13.4355 6.92429L20 7.88013L15.25 12.489L16.371 19L10.5 15.9243L4.629 19L5.75 12.489L1 7.88013L7.5645 6.92429L10.5 1Z'
            stroke={stroke}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default IconRecommended;
