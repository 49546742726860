import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { mapSelectors } from '../../../../redux/map';
import { userSelectors } from '../../../../redux/user';
import { DropPinViewContext } from './DropPinViewContext';

const SIDEBAR_WIDTH = 500;

const useDropPin = map => {
    const mapId = useSelector(userSelectors.selectedMapId);
    const mapCenter = useSelector(mapSelectors.mapCenter(mapId));
    const { onDropPinConfirm, onDropPinCancel, isDropPinView, location, setLocation } = useContext(DropPinViewContext);

    useEffect(() => {
        if (!map.current || !map.current.easeTo) {
            return;
        }

        if (!isDropPinView) {
            map.current.easeTo({
                animate: true,
                duration: 500,
                zoom: mapCenter?.zoom,
                center: [location?.position?.lon || mapCenter?.position?.lng || 0, location?.position?.lat || mapCenter?.position?.lat || 0],
                padding: { top: 0, bottom: 0, left: SIDEBAR_WIDTH, right: 0 },
            });
            return;
        }

        map.current.easeTo({
            animate: true,
            padding: { top: 0, bottom: 0, left: 0, right: 0 },
            duration: 500,
        });
    }, [isDropPinView]);

    return {
        cancelDropPinLocation: newLocation => {
            if (!isDropPinView) {
                return;
            }

            setLocation(newLocation);
            onDropPinCancel(newLocation);
        },
        confirmDropPinLocation: newLocation => {
            if (!isDropPinView) {
                return;
            }

            setLocation(newLocation);
            onDropPinConfirm(newLocation);
        },
    };
};

export default useDropPin;
