import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, userSelectors } from '../../../redux/user';
import Grid from '@material-ui/core/Grid';
import styles from './index.module.scss';
import ROUTES from '../../../config/routes';
import { useNavigate } from 'react-router-dom';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../helpers/mixpanel';
import { isOnboarding } from '../../../helpers';
import CustomButton, { ButtonSize, ButtonType } from '../../../components/common/buttons/CustomButton';
import Logo from '../../../components/common/Logo';
import { SITE_URL } from '../../../constants';

const MapName = ({ handleSetPopup, handleClickNext, page, handleClickBack, mapName, setMapName, setType, setImportSteps, backToDefaultPage }) => {
    const dispatch = useDispatch();
    const maps = useSelector(userSelectors.maps);
    const [isMapExist, setIsMapExist] = useState(false);
    const navigate = useNavigate();
    const handleMapNameChange = useCallback(
        e => {
            setMapName(e.target.value);
            const map = maps.find(map => map.name === e.target.value.trim());
            if (map) setIsMapExist(true);
            else setIsMapExist(false);
            localStorage.setItem('temp_mapName', e.target.value.trim());
            if (!localStorage.getItem('is-instagram-failed')) localStorage.removeItem('recent_mapId');
            localStorage.removeItem('is-instagram-failed');
        },
        [setMapName]
    );

    const leftSideContent =
        page === 1
            ? {
                  FIRST_LINE: "Let's add",
                  SECOND_LINE: 'some',
                  BOLD: 'places',
              }
            : {
                  FIRST_LINE: "Let's create",
                  SECOND_LINE: '',
                  BOLD: 'your map',
              };

    const getNextPageByDeviceType = () => {
        const toChooseHowToAdd = page + 1;
        return toChooseHowToAdd;
    };

    const handleKeyDown = useCallback(
        e => {
            if (e.keyCode === 13 && mapName?.length >= 3 && !isMapExist) {
                const nextPage = getNextPageByDeviceType();
                handleClickNext(nextPage);
            }
        },
        [mapName]
    );

    useEffect(() => {
        localStorage.setItem('temp_mapCount', maps.length);
        if (page === 0) {
            mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                [mixpanelProperties.PAGE]: 'name',
                [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                [mixpanelProperties.MAP_NAME]: null,
                [mixpanelProperties.MAP_ID]: null,
            });
        } else if (page === 1) {
            mixpanel.register({ [mixpanelProperties.MAP_NAME]: mapName });
            mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                [mixpanelProperties.PAGE]: 'add_steps',
                [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                [mixpanelProperties.MAP_NAME]: mapName,
            });
        }
    }, [page]);

    return (
        <>
            <Grid item className={styles.block}>
                <div className={styles.leftBlock}>
                    <div
                        role='presentation'
                        onClick={() => {
                            mixpanel.track(mixpanelEvents.MM_OB_LETS_CREATE_YOUR_MAP_STEPS_LOGO_CLICKED, {
                                [mixpanelProperties.IS_OB]: isOnboarding(),
                            });
                            maps?.length > 0 ? backToDefaultPage() : (window.location.href = SITE_URL);
                        }}
                        className={classNames(styles.logoBlock)}
                    >
                        <Logo type={Logo.Types.WHITE} size={Logo.Sizes.SMALL} />
                    </div>
                    <span className={styles.text} data-cy='onboarding-create-map-title'>
                        {leftSideContent.FIRST_LINE}
                        <br />
                        <span className={styles.text}>{leftSideContent.SECOND_LINE} </span>
                        <span className={styles.bold}>{leftSideContent.BOLD}</span>
                    </span>
                    <div className={styles.backBtn}>
                        <CustomButton
                            size={'largeSize'}
                            onClick={() => {
                                mixpanel.track(mixpanelEvents.MM_OB_LETS_CREATE_YOUR_MAP_BACK_BUTTON_CLICKED, {
                                    [mixpanelProperties.IS_OB]: isOnboarding(),
                                });
                                const afterPage = localStorage.getItem('page');
                                if (afterPage === 'login' && page === 0) {
                                    dispatch(userActions.setUserAuth(0));
                                    navigate(ROUTES.LOGIN);
                                    localStorage.setItem('page', null);
                                    localStorage.setItem('at', null);
                                    localStorage.setItem('rt', null);
                                } else if (afterPage === 'register' && page === 0) {
                                    dispatch(userActions.setUserAuth(0));
                                    navigate(ROUTES.SIGN_UP);
                                    localStorage.setItem('page', null);
                                    localStorage.setItem('at', null);
                                    localStorage.setItem('rt', null);
                                } else if (afterPage === 'MM' && page === 0) {
                                    navigate('/' + ROUTES.MAP);
                                    localStorage.setItem('page', null);
                                } else if (page - 1 < 0) {
                                    dispatch(userActions.setUserAuth(0));
                                    navigate(ROUTES.SIGN_UP);
                                    localStorage.setItem('page', null);
                                    localStorage.setItem('at', null);
                                    localStorage.setItem('rt', null);
                                } else {
                                    handleClickBack(page - 1);
                                }
                            }}
                            type={ButtonType.TERTIARY_ON_BLUE}
                            text={
                                <>
                                    <img src='/assets/img/steps3Images/white-arrow.svg' alt='' />
                                    <p>Back</p>
                                </>
                            }
                        />
                    </div>
                </div>
                <div className={styles.rightBlock}>
                    {page === 0 && (
                        <div className={styles.pRelative}>
                            <p className={styles.title}>First, give it a name</p>
                            <input
                                className={classNames(styles.input)}
                                placeholder={`${'   '} Add map name here`}
                                onKeyDown={e => handleKeyDown(e)}
                                onChange={handleMapNameChange}
                                value={mapName}
                                autoFocus
                            />
                            <div className={classNames(styles.mapNameExist, { [styles.mapNameExistShow]: isMapExist })}>
                                <img src={'/assets/img/steps3Images/red_warning.svg'} width='16' height='16' />
                                <p>That map name is already taken.</p>
                            </div>
                        </div>
                    )}
                    {page === 1 && (
                        <div className={styles.importStepsBlock}>
                            <p className={styles.title}>Add places to your map</p>
                            <p className={styles.subTitle}>Your map, your rules! Add anything you want—</p>
                            <p className={styles.subTitle}>your favorite restaurants, romantic date spots, hiking trails and more!</p>
                            <CustomButton
                                type={ButtonType.PRIMARY}
                                size={ButtonSize.LARGE}
                                onClick={() => {
                                    mixpanel.track(mixpanelEvents.OB_ADD_STEPS_CHOOSE, {
                                        [mixpanelProperties.ADD_STEPS]: 'manually',
                                        [mixpanelProperties.FIRST_MAP]: isOnboarding(),
                                        [mixpanelProperties.MAP_NAME]: mapName,
                                    });
                                    setType(null);
                                    handleClickNext(page + 1);
                                    localStorage.setItem('addStepsChoose', 'manually');
                                }}
                            >
                                Add a place
                            </CustomButton>
                            {/*<div className={styles.orBlock}>*/}
                            {/*    <hr/>*/}
                            {/*    <span>Or</span>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<p className={styles.importFrom}>Import places from: </p>*/}
                            {/*<div className={styles.buttons}>*/}
                            {/*    <ImportSteps*/}
                            {/*        onClick={() => {*/}
                            {/*            handleClickNext(5);*/}
                            {/*            localStorage.setItem('addStepsChoose', 'instagram');*/}
                            {/*            mixpanel.track(mixpanelEvents.OB_ADD_STEPS_CHOOSE, {*/}
                            {/*                [mixpanelProperties.ADD_STEPS]: 'instagram',*/}
                            {/*                [mixpanelProperties.FIRST_MAP]: isOnboarding(),*/}
                            {/*                [mixpanelProperties.MAP_NAME]: mapName,*/}
                            {/*            });*/}
                            {/*        }}*/}
                            {/*        title={'Instagram'}*/}
                            {/*        src={'/assets/img/steps3Images/instagramLogo.svg'}*/}
                            {/*    />*/}
                            {/*    <ImportSteps*/}
                            {/*        onClick={() => {*/}
                            {/*            handleClickNext(page + 1);*/}
                            {/*            setImportSteps(true);*/}
                            {/*            setType(FileType.GOOGLE);*/}
                            {/*            localStorage.setItem('addStepsChoose', 'google');*/}
                            {/*            mixpanel.track(mixpanelEvents.OB_ADD_STEPS_CHOOSE, {*/}
                            {/*                [mixpanelProperties.ADD_STEPS]: 'google',*/}
                            {/*                [mixpanelProperties.FIRST_MAP]: isOnboarding(),*/}
                            {/*                [mixpanelProperties.MAP_NAME]: mapName,*/}
                            {/*            });*/}
                            {/*        }}*/}
                            {/*        title={'Google My Maps'}*/}
                            {/*        src={'/assets/img/steps3Images/googleMapsLogo.svg'}*/}
                            {/*    />*/}
                            {/*    <ImportSteps*/}
                            {/*        onClick={() => {*/}
                            {/*            handleClickNext(page + 1);*/}
                            {/*            setImportSteps(true);*/}
                            {/*            setType(FileType.EXCEL);*/}
                            {/*            localStorage.setItem('addStepsChoose', 'excel');*/}
                            {/*            mixpanel.track(mixpanelEvents.OB_ADD_STEPS_CHOOSE, {*/}
                            {/*                [mixpanelProperties.ADD_STEPS]: 'excel',*/}
                            {/*                [mixpanelProperties.FIRST_MAP]: isOnboarding(),*/}
                            {/*                [mixpanelProperties.MAP_NAME]: mapName,*/}
                            {/*            });*/}
                            {/*        }}*/}
                            {/*        title={'Excel'}*/}
                            {/*        src={'/assets/img/steps3Images/exelLogo.svg'}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*<span className={styles.stepsManually}>*/}
                            {/*    Want to import from another platform?{'  '}*/}
                            {/*    <span*/}
                            {/*        onClick={() => {*/}
                            {/*            mixpanel.track(mixpanelEvents.MM_OB_LETS_ADD_STEPS_LET_US_KNOW_BUTTON, {*/}
                            {/*                [mixpanelProperties.IS_OB]: isOnboarding(),*/}
                            {/*            });*/}
                            {/*            handleSetPopup(true);*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        Let us know*/}
                            {/*    </span>*/}
                            {/*</span>*/}
                        </div>
                    )}
                    {page !== 1 && (
                        <div className={styles.positionButton}>
                            <CustomButton
                                size={'largeSize'}
                                onClick={() => {
                                    if (isMapExist) return;
                                    const nextPage = getNextPageByDeviceType();
                                    mixpanel.track(mixpanelEvents.MM_OB_LETS_CREATE_YOUR_MAP_NEXT_BUTTON_CLICKED, {
                                        [mixpanelProperties.MAP_NAME]: mapName,
                                        [mixpanelProperties.IS_OB]: isOnboarding(),
                                    });
                                    handleClickNext(nextPage);
                                }}
                                type={ButtonType.PRIMARY_BLUE}
                                text='Next'
                                disabled={mapName.length < 3 || isMapExist}
                            />
                        </div>
                    )}
                </div>
            </Grid>
        </>
    );
};

export default MapName;
