import React from 'react';

const Icon = ({ width, height, stroke }) => {
    return (
        <svg width={width || '20'} height={height || '24'} viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='map-pin (3) copy 76'>
                <path
                    id='Path'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M10 23C10 23 1 17 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 17 10 23 10 23Z'
                    fill='white'
                    stroke={stroke || '#979797'}
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <circle
                    id='Oval'
                    cx='10'
                    cy='10.3529'
                    r='3'
                    fill='white'
                    stroke={stroke || '#979797'}
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
        </svg>
    );
};

export default Icon;
