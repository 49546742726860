import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import FacebookLoginButton from '../../components/common/buttons/FacebookLoginButton';
import GoogleLoginButton from '../../components/common/buttons/GoogleLoginButton';
import ContactUs from '../../components/ContactUs';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../config/routes';
import { LOGIN_TYPES } from '../../config/constants';
import { userActions } from '../../redux/user';
import { useDispatch } from 'react-redux';
import AppleLoginButton from '../../components/common/buttons/AppleLoginButton/AppleLoginButton';
import LoginInput from '../../components/common/inputs/LoginInput';
import RegisterInput from '../../components/common/inputs/RegisterInput';
import classNames from 'classnames';
import CustomButton, { ButtonType } from '../../components/common/buttons/CustomButton';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';
import { useWindowSize } from '../../hooks';
import Logo from '../../components/common/Logo';

const copies = copiesPrefix.signUp;

const Register = props => {
  const fbRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setMail] = useState('');
  const [password, setPass] = useState('');
  const [loader, setLoader] = useState(false);
  const [errorEmail, setErrorMail] = useState(false);
  const [errorRegister, setErrorRegister] = useState(false);
  const [passLengthErr, setPassLengthErr] = useState(false);
  const [typePassword, setTypePassword] = useState('password');
  const adrPattern = /[0-9a-z_-]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
  const { isMobile } = useWindowSize();
  
  useEffect(() => {
    if (matchPath(location.pathname, ROUTES.LOGIN)?.isExact && fbRef.current.state?.isConnected) {
      fbRef.current.logout();
    }
  });
  
  useEffect(() => {
    mixpanel.track(mixpanelEvents.SIGN_UP_PREVIEW);
  }, []);
  
  const handleEmail = useCallback(
      e => {
        if (errorEmail) {
          if (adrPattern.test(e.target.value) === true) {
            setErrorMail(false);
            setMail(e.target.value);
          } else {
            setErrorMail(true);
            setMail(e.target.value);
          }
        } else {
          setMail(e.target.value);
        }
      },
      [errorEmail],
  );
  
  const handlePassword = useCallback(
      e => {
        if (passLengthErr) {
          if (e.target.value.length > 5) {
            setPass(e.target.value);
            setPassLengthErr(false);
          } else {
            setPass(e.target.value);
            setPassLengthErr(true);
          }
        } else {
          setPass(e.target.value);
        }
      },
      [passLengthErr],
  );
  
  const handleFirstName = useCallback(e => {
    setFirstName(e.target.value);
  }, []);
  
  const handleLastName = useCallback(e => {
    setLastName(e.target.value);
  }, []);
  
  const handleRegister = useCallback(() => {
    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    setErrorRegister(false);
    // check email
    if (!email) {
      setErrorMail(true);
    } else if (adrPattern.test(email) === false) {
      setErrorMail(true);
    } else {
      setErrorMail(false);
    }
    // check password
    if (!password || password.length <= 5) {
      if (password.length <= 5) {
        setPassLengthErr(true);
      } else {
        setPassLengthErr(false);
      }
    } else {
      if (password.length > 5 && adrPattern.test(email) === true && trimmedFirstName.length > 0 && trimmedLastName.length > 0) {
        setLoader(true);
        // send request to register
        mixpanel.track(mixpanelEvents.SIGN_UP_MANUAL_CLICK);
        dispatch(
            userActions.registration({
              email,
              password,
              firstName: trimmedFirstName,
              lastName: trimmedLastName,
              distinctId: mixpanel.get_distinct_id(),
            }),
        ).then(({ payload }) => {
          if (payload) {
            localStorage.setItem('page', 'register');
            setLoader(false);
            props?.setIsLoading?.(true);
            mixpanel.identify(payload.id);
            mixpanel.people.set('$user_id', payload.id);
            mixpanel.register({
              [mixpanelProperties.USER_ID]: payload.id,
              [mixpanelProperties.USER_NAME]: payload.display_name,
            });
            if (localStorage.getItem('userId') !== payload.id) {
              localStorage.setItem('tooltipStorage', '');
            }
            dispatch(userActions.setUserAuth(1));
            if (isMobile) navigate(ROUTES.ONBOARD);
            else navigate('/' + ROUTES.MAP);
          } else {
            setLoader(false);
            props?.setIsLoading?.(false);
            setErrorRegister(true);
          }
        });
      }
    }
  }, [email, password, firstName, lastName, adrPattern]);
  
  const handleSocial = useCallback((data, nameType) => {
    props?.setIsLoading?.(true);
    let provider;
    let token;
    let first_name;
    let last_name;
    let name;
    if (nameType === LOGIN_TYPES.GOOGLE) {
      provider = LOGIN_TYPES.GOOGLE;
      token = data.tokenId;
    }
    if (nameType === LOGIN_TYPES.FACEBOOK) {
      provider = LOGIN_TYPES.FACEBOOK;
      token = data._token.accessToken;
    }
    if (nameType === LOGIN_TYPES.APPLE) {
      provider = LOGIN_TYPES.APPLE;
      token = data.authorization.id_token;
      if (data?.user) {
        first_name = data?.user?.name?.firstName;
        last_name = data?.user?.name?.lastName;
        name = `${data?.user?.name?.firstName} ${data?.user?.name?.lastName}`;
      }
    }
    
    dispatch(
        userActions.login({
          provider,
          token,
          distinctId: mixpanel.get_distinct_id(),
          first_name,
          last_name,
          name,
        }),
    ).then(({ payload: user, error }) => {
      if (user) {
        localStorage.setItem('page', 'register');
        dispatch(userActions.setUserAuth(1));
        navigate('/' + ROUTES.MAP);
        const { _id, display_name } = user;
        if (localStorage.getItem('userId') !== (user?.id || _id)) {
          localStorage.setItem('tooltipStorage', '');
        }
        mixpanel.identify(_id);
        mixpanel.people.set('$user_id', _id);
        mixpanel.register({
          [mixpanelProperties.USER_ID]: _id,
          [mixpanelProperties.USER_NAME]: display_name,
        });
      }
      
      if (error || !user) {
        navigate(ROUTES.SIGN_UP);
      }
    });
  }, []);
  
  const handleSocialFailure = response => {
  };
  
  const handleLoginPage = () => {
    navigate(ROUTES.LOGIN);
    mixpanel.track(mixpanelEvents.SIGN_UP_LOG_IN_CLICKED);
  };
  
  return (
      <div className={classNames(styles.signUpBlock, "my-20 md:my-0")}>
        <div className="absolute top-4 left-4 md:top-10 md:left-10">
          <Logo size={isMobile ? Logo.Sizes.SMALL : Logo.Sizes.MEDIUM}/>
        </div>
        <div className={styles.textCenter}>
          <div className={styles.titleText}>{copies.title}</div>
          <p>
            {copies.subtitle}
            <span onClick={handleLoginPage}>{copies.login_link}</span>
          </p>
          <div className="flex flex-col md:flex-row">
            <div>
              <div className={styles.facebookButtonBlock}>
                <FacebookLoginButton
                    fbRef={fbRef}
                    handleSocialLogin={data => handleSocial(data, LOGIN_TYPES.FACEBOOK)}
                    handleSocialLoginFailure={handleSocialFailure}
                    text={copies.facebook}
                    fireMixpanelEvent={() => mixpanel.track(mixpanelEvents.SIGN_UP_FB_CLICK)}
                />
              </div>
              <div className={styles.googleButtonBlock}
                   onClick={() => mixpanel.track(mixpanelEvents.SIGN_UP_GOOGLE_CLICK)}>
                <GoogleLoginButton
                    handleSocialLogin={data => handleSocial(data, LOGIN_TYPES.GOOGLE)}
                    handleSocialLoginFailure={handleSocialFailure}
                    text={copies.google}
                    fireMixpanelEvent={() => mixpanel.track(mixpanelEvents.SIGN_UP_GOOGLE_CLICK)}
                />
              </div>
              <div className={styles.appleButtonBlock}>
                <AppleLoginButton
                    handleSocialLoginFailure={handleSocialFailure}
                    handleSocialLogin={data => handleSocial(data, LOGIN_TYPES.APPLE)}
                    text={copies.apple}
                    fireMixpanelEvent={() => mixpanel.track(mixpanelEvents.SIGN_UP_APPLE_CLICK)}
                />
              </div>
            </div>
            <div className={styles.divider}>
              <hr size={107}/>
              <div className={styles.leftHr}/>
              <span className={styles.orText}>{copies.divider}</span>
              <div className={styles.rightHr}/>
              <hr size={107}/>
            </div>
            <div className={styles.form}>
              <LoginInput
                  onClick={errorRegister ? () => setErrorRegister(false) : () => setErrorMail(false)}
                  type='email'
                  setState={setMail}
                  onChange={handleEmail}
                  value={email}
                  errorText={errorRegister ? copies.email_exist_error : copies.email_invalid_error}
                  error={errorEmail || errorRegister}
                  placeholder={copies.email_placeholder}
              />
              <LoginInput
                  onClick={() => setPassLengthErr(false)}
                  typePassword={typePassword}
                  setTypePassword={setTypePassword}
                  type={typePassword}
                  setState={setPass}
                  onChange={handlePassword}
                  value={password}
                  errorText={copies.password_error}
                  error={passLengthErr}
                  placeholder={copies.password_placeholder}
              />
              <div className={classNames(styles.fullNameBlock, "w-full m-auto md:w-auto md:m-0")}>
                <RegisterInput
                    type='text'
                    setState={setFirstName}
                    onChange={handleFirstName}
                    value={firstName}
                    placeholder={copies.first_name_placeholder}
                />
                <RegisterInput
                    type='text'
                    setState={setLastName}
                    onChange={handleLastName}
                    value={lastName}
                    placeholder={copies.last_name_placeholder}
                />
              </div>
              <div
                  className={classNames(styles.manualButtonBlock, {
                    [styles.manualButtonBlockShow]:
                    firstName.trim().length > 0 && lastName.trim().length > 0 && email.length > 0 && password.length > 0,
                  })}
              >
                <CustomButton
                    size={'mediumSize'}
                    onClick={handleRegister}
                    type={ButtonType.SECONDARY_BLUE}
                    disabled={loader}
                    loader={loader}
                    text={copies.signup_button}
                    className={styles.signUpButton}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-10 md:my-0">
          <ContactUs/>
        </div>
      </div>
  );
};

export default Register;
