import { SET_ONBOARD_MAP_CENTER } from './types';

const initialState = {
    mapCenter: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ONBOARD_MAP_CENTER:
            return {
                ...state,
                mapCenter: action.center,
            };
        default:
            return state;
    }
};
export default reducer;
