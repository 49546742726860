import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomButton, { ButtonType, ButtonSize } from './../../../common/buttons/CustomButton';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../helpers/mixpanel';

import styles from './CreateNewPostModal.module.scss';

let singleton = false;
const AnnouncementConfirmationPopover = ({ onConfirm, isLocked }) => {
    const copies = isLocked ? 'feed.announcementLocked' : 'feed.announcementExists';

    useEffect(() => {
        if (isLocked && !singleton) {
            singleton = true;
            mixpanel.track(mixpanelEvents.LEVEL_PROGRESS_TOOLTIP_SHOWED, {
                [mixpanelProperties.TOOLTIP_TYPE]: 'announcement',
            });
        }
    }, [isLocked]);

    return (
        <div className={styles.announcementExistsMessage}>
            <FormattedMessage id={`${copies}.title`} tagName='h1' />
            <FormattedMessage id={`${copies}.description`} tagName='p' />
            {isLocked && <FormattedMessage id={`${copies}.second_description`} tagName='p' />}
            {!isLocked && (
                <div className={styles.announcementConfirmationfooter}>
                    <CustomButton onClick={onConfirm} type={ButtonType.PRIMARY_ON_BLUE} size={ButtonSize.MEDIUM}>
                        <FormattedMessage id={`${copies}.confirm`} />
                    </CustomButton>
                </div>
            )}
        </div>
    );
};

export default AnnouncementConfirmationPopover;
