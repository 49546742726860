import { useCallback } from "react";
import { noop } from "lodash";
import { TagTypes, MixPanelTagTypes } from "../../../../constants";
import mixpanel, { mixpanelEvents, mixpanelProperties } from "../../../../helpers/mixpanel";
import { Linkify } from "../..";

const TextSpan = ({
  uuid,
  id,
  item,
  isTag,
  postId,
  text,
  textType,
  userPopoverId,
  stepId,
  placeId,
  userId,
  setActivePopupId,
  setStepToAdd,
  onClick = noop
}) => {
  // TODO: should come from TagLocationInPost render props
  const dataAttributes = useCallback(
    ({ text_type, place_id, text } = {}) => {
      let data = {};
      if (place_id) data["data-id"] = place_id;
      if (stepId) data["data-id"] = stepId;
      if (text) data["data-name"] = text;
      if (text_type === TagTypes.USER_TAG) data["data-userid"] = userId;
      if (text_type) data["data-type"] = text_type;
      return data;
    },
    [id]
  );

  return (
    <span
      key={uuid}
      id={uuid || id}
      {...dataAttributes(item)}
      className={isTag ? "tag" : ""}
      onClick={async event => {
        if (document.getSelection()?.toString()) {
          return;
        }

        if (!isTag) {
          return;
        }

        mixpanel.track(mixpanelEvents.TAG_CLICKED, {
          [mixpanelProperties.POST_ID]: postId,
          [mixpanelProperties.TAG]: text,
          [mixpanelProperties.TAG_TYPE]: MixPanelTagTypes[textType]
        });

        if (textType === TagTypes.USER_TAG) {
          event.stopPropagation();
          setActivePopupId(userPopoverId);
          return;
        }

        if (onClick) {
          event.preventDefault();
          onClick();
          return;
        }

        if ([TagTypes.STEP_TAG, TagTypes.PLACE_TAG].includes(textType)) {
          await setStepToAdd({
            placeId: textType === TagTypes.PLACE_TAG ? placeId || id : null,
            stepId: textType === TagTypes.STEP_TAG ? stepId || id : null,
            shouldShowAddStepModal: true
          });
        }
      }}
    >
            <Linkify>{text}</Linkify>
        </span>
  );
};

export default TextSpan;
