import React from 'react';

const Icon = ({ stroke }) => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10 16.6667V8.33334' stroke={stroke} stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M15 16.6667V3.33333' stroke={stroke} stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M5 16.6667V13.3333' stroke={stroke} stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
);

export default Icon;
