import React from 'react';

import styles from './index.module.scss';
import Terms from './Terms';
import Policy from './Policy';

const ModalsLoginPage = props => {
    return (
        <div className={styles.container}>
            <div className={styles.panel}>
                <div className={styles.box}>
                    <img src='/assets/img/ic-close.svg' onClick={props?.handleClick} alt='' />
                    <p className={styles.title}>{props?.terms ? 'Terms of use' : 'Privacy Policy'}</p>
                    <div className={`${props?.terms ? styles.blockInfo : styles.blockInfoPolicy}`}>
                        {props?.terms && <Terms />}
                        {props?.policy && <Policy />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalsLoginPage;
