import React, { useRef, useEffect, useCallback } from 'react';
import { noop } from 'lodash';
import { ImageSquare } from 'phosphor-react';
import classNames from 'classnames';
import CustomButton, { ButtonType } from '../common/buttons/CustomButton';
import IconImageUpload from '../common/icons/IconImageUpload';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import styles from './index.module.scss';

const ACCEPT_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

const Label = ({ text }) => {
    if (!text) {
        return null;
    }

    return <span className={styles.label}>{text}</span>;
};

export const DropZone = ({ onLoadFiles, addImage, text, newImg, onboarding, addOn, fromWhere, stepId, buttonSize = 20, onClick = noop }) => {
    const fileInput = useRef(null);

    const trackImageEvent = eventName => {
        mixpanel.track(eventName, {
            [mixpanelProperties.FROM_WHERE]: fromWhere || localStorage.getItem('fromWhereAddPost') || 'step_page',
            [mixpanelProperties.ADD_ON]: addOn,
            [mixpanelProperties.STEP_ID]: stepId,
        });
    };

    const handleOnClick = useCallback(() => {
        onClick()
        if (addOn && stepId) trackImageEvent(mixpanelEvents.ADD_IMAGE_CLICK);
        fileInput.current.click();
        onClick();
    }, [fileInput]);

    const upload = e => {
        if (addOn && stepId) trackImageEvent(mixpanelEvents.ADD_IMAGE_UPLOAD);
        onLoadFiles && onLoadFiles(e.target.files);
        e.target.value = '';
    };

    useEffect(() => {
        if (addImage) {
            handleOnClick();
        }
    }, [addImage]);

    return (
        <div className={styles.dropZoneBlock}>
            {onboarding ? (
                <CustomButton
                    className={styles.dFlex}
                    size={'smallSize'}
                    type={ButtonType.TERTIARY_BLUE}
                    onClick={handleOnClick}
                    text={
                        <>
                            <IconImageUpload />
                            <Label text={text} />
                        </>
                    }
                />
            ) : (
                <span onClick={handleOnClick} className={classNames('flex-center', styles.addImageWrapper)}>
                    <ImageSquare className={classNames(styles.addImg, { [styles.iconMargin]: text })} size={buttonSize} color='#2171EC' />
                    <Label text={text} />
                </span>
            )}
            <input
                style={{
                    display: 'none',
                    position: 'absolute',
                }}
                accept={ACCEPT_TYPES}
                multiple
                type='file'
                ref={fileInput}
                onChange={upload}
            />
        </div>
    );
};

export default DropZone;
