import React, { useCallback, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { noop } from "lodash";
import { useDispatch } from "react-redux";
import { modalsActions } from "../../../../redux/modals";
import { generateImageUrl } from "../../../../helpers";
import ImageCarousel from "../../../../lib/ImageCarousel";
import CustomButton, { ButtonSize, ButtonType } from "../../../../components/common/buttons/CustomButton";

const AddStepExtended = ({ title = "", images = [], address = "", onClickAddStep: onClickAddStepProp = noop }) => {
  const dispatch = useDispatch;
  const [isDisabled, setIsDisabled] = useState(false);

  const generateStepImages = useMemo(() => {
    return images?.map(image => {
      return generateImageUrl({
        imageId: image.image_id,
        rotate: true,
        width: 245,
        height: 184,
        reason: "generate_step_image"
      });
    });
  }, [images]);

  const generateStepFullSizeImages = useCallback(() => {
    return images?.map(image => {
      return generateImageUrl({
        imageId: image.image_id,
        rotate: true,
        reason: "generate_step_image"
      });
    });
  }, [images]);

  const onImageClick = index => {
    dispatch(
      modalsActions.toggleImagePreview({
        imagePreviewShow: true,
        imagePreviewArray: images,
        imagePreviewIndex: index
      })
    );
  };

  const renderImages = () => {
    if (!images) return;
    return (
      <ImageCarousel
        classNameDiv={styles.imageContainer}
        classNameImage={styles.image}
        images={generateStepImages}
        settings={{
          slidesToShow: 1.65,
          slidesToScroll: 1
        }}
        onImageClick={onImageClick}
      />
    );
  };

  const onClickAddStep = () => {
    setIsDisabled(true);
    onClickAddStepProp();
  };

  return (
    <div className={styles.block}>
      {renderImages()}
      <h2 className={styles.title}>{title}</h2>
      {address && <span className={styles.address}>{address}</span>}
      <CustomButton disabled={isDisabled} className={styles.addStepButton} type={ButtonType.PRIMARY}
                    size={ButtonSize.MEDIUM} onClick={onClickAddStep}>
        {/* FIXME: intl does not work here for some reason <FormattedMessage id="add_place_button"/>*/}
        Add a place
      </CustomButton>
    </div>
  );
};

export default AddStepExtended;
