import { noop } from 'lodash';
import { CurrentUserAvatar } from '../../../common/Avatar';
import copies from '../../../../copies.json';

import styles from '../FeedPost.module.scss';

const PLACEHOLDER = copies.feed.input_placeholder;

const CreatePostInput = ({ onClick = noop }) => {
  return (
    <div className={styles.feedInput} onClick={onClick}>
      <CurrentUserAvatar className={styles.avatar}/>
      <div
        className="text-sm text-[#b5b7bb] flex items-center w-full h-5 cursor-pointer bg-gray-100 rounded-full border-2 border-gray-200 px-4 py-2 outline-none"
        data-cy='announcement-input'>
        {PLACEHOLDER}
      </div>
    </div>
  );
};

export default CreatePostInput;
