export const StepTypes = {
    REGULAR: 'REGULAR',
    NEW: 'NEW',
    RECOMMENDED: 'RECOMMENDED',
};

export const StepStatuses = {
    TEMPORARILY_CLOSED: 'CLOSED_TEMPORARILY',
    PERMANENTLY_CLOSED: 'CLOSED_PERMANENTLY',
    OPERATIONAL: 'OPERATIONAL',
};
