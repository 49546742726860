import copies from '../../copies.json';

const copiesPrefix = copies.toast;

export const HTML_COPIED_TEXT = copiesPrefix.html_copied_text;
export const LINK_COPIED_TEXT = copiesPrefix.link_copied_text;
export const TEXT_COPIED = copiesPrefix.text_copied;
export const CHANGES_SAVED_TEXT = copiesPrefix.changes_saved_text;
export const ADDED_YOUR_MAP_TEXT = copiesPrefix.added_your_map_text;
export const STEPS_ADDED_TEXT = copiesPrefix.steps_added_text;
export const RATINGS_SAVED = copiesPrefix.ratings_saved;
export const VIEW_CTA_TEXT = copiesPrefix.view;
export const REMOVED_UPDATE = copiesPrefix.removed_update;
export const UNDO = copiesPrefix.undo;

export const ToastTypes = {
    ERROR: 'ERROR',
    LOADING: 'LOADING',
    INFO: 'INFO', // default
};

export const ToastDurations = {
    SHORT: 2500,
    DEFAULT: 3000,
    LONG: 6000,
};
