import React from 'react';

const IconPlayVideo = () => (
    <svg width='20' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M10 18.833a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666z'
            stroke='#3A3C3F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path d='m8.333 7.167 5 3.333-5 3.333V7.167z' stroke='#3A3C3F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
);

export default IconPlayVideo;
