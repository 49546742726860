import ReactDOM from 'react-dom';
import { StepTypes } from '../../../constants';

const mapboxgl = process.env.NODE_ENV === 'test' ? require('mapbox-gl/dist/mapbox-gl.js') : require('!mapbox-gl');
const { Popup, Marker } = mapboxgl;

const stepsPopupComponents = {};

const getStepsPopupId = (map, step, popupId) => `${popupId}-${map._mapId}-${step._id}`;

export const isPopupExist = (map, popupId) => Object.keys(stepsPopupComponents).find(index => index.includes(`${popupId}-${map._mapId}`));

export const isPopupOnStep = (map, step) => Object.keys(stepsPopupComponents).find(index => index.includes(`${map._mapId}-${step._id}`));

export const stepTypes = {
    selectedStep: 'selected-step-marker',
    temporaryStep: 'ghost-step-marker',
    NEW: 'selectedNewStep',
    RECOMMENDED: 'selectedRecommendedStep',
    REGULAR: 'selected-step-marker',
    DROP_PIN_LOCATION_SELECTED: 'map_pin_black',
    DROP_PIN_LOCATION_FOUND: 'map_pin_blue',

    PENDING_STEP: 'pending-step-marker',
};

export const createStepMarker = (map, step, type, { onMouseLeave, onClick, className }) => {
    const lng = step.lon || step.lng || step[1];
    const lat = step.lat || step[0];

    if (!lng || !lat || !step) {
        return;
    }

    const stepElement = document.createElement('div');
    stepElement.className = className;
    stepElement.style.backgroundImage = `url(/assets/img/steps3Images/${type || stepTypes.selectedStep}.svg)`;

    const stepIndications = step.indications;
    const parsedIndications = typeof stepIndications === 'string' ? JSON.parse(stepIndications) : stepIndications;
    const isStepNewOrRecommended = parsedIndications?.some(indication => indication.type === StepTypes.NEW || indication.type === StepTypes.RECOMMENDED);
    if (isStepNewOrRecommended || type === stepTypes.temporaryStep) {
        stepElement.style.width = '46px';
        stepElement.style.height = '57px';
    } else {
        stepElement.style.width = '36px';
        stepElement.style.height = '44px';
    }

    const marker = new Marker(stepElement);

    marker.setLngLat([lng, lat]).addTo(map);
    const markerElement = marker.getElement();
    markerElement.addEventListener('mouseleave', () => {
        onMouseLeave && onMouseLeave();
    });
    markerElement.addEventListener('click', e => {
        onClick && onClick();
        e.stopPropagation();
    });
    return marker;
};

export const createStepPopup = (map, step, popupId, popupComponent) => {
    if (stepsPopupComponents[getStepsPopupId(map, step, popupId)]) return;
    deletePopupFromMap(map, popupId);
    deletePopupOnStep(map, step);
    const popup = new Popup({
        closeButton: false,
        closeOnClick: false,
        focusAfterOpen: true,
        offset: 30,
    });
    const reactElement = document.createElement('div');
    ReactDOM.render(popupComponent, reactElement);
    try {
        popup
            .setLngLat([step.lon || step.lng || step[1], step.lat || step[0]])
            .setDOMContent(reactElement)
            .addTo(map);
    } catch (error) {
        console.log(error);
    }
    stepsPopupComponents[getStepsPopupId(map, step, popupId)] = popup;
    return popup;
};

export const deletePopupFromMap = (map, popupId) => {
    const index = isPopupExist(map, popupId);
    if (stepsPopupComponents[index]) {
        stepsPopupComponents[index]?.remove();
        delete stepsPopupComponents[index];
    }
};

export const deletePopupOnStep = (map, step) => {
    const index = isPopupOnStep(map, step);
    if (stepsPopupComponents[index]) {
        stepsPopupComponents[index]?.remove();
        delete stepsPopupComponents[index];
    }
};

export const deleteAllPopupsFromMap = map => {
    const popupsIndexes = Object.keys(stepsPopupComponents).filter(index => index.includes(`-${map._mapId}-`));
    for (const index of popupsIndexes) {
        stepsPopupComponents[index]?.remove();
        delete stepsPopupComponents[index];
    }
};
