import { Sliders } from 'phosphor-react';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchInput from '../../../../components/common/inputs/SearchInput2';
import SearchPopUp from '../../../../components/SearchSteps2';
import { AddPlaceSteps } from '../../../../components/AddStep';
import { mapActions } from '../../../../redux/map';
import { userSelectors } from '../../../../redux/user';
import { getPlaceCoordinates } from '../../../../helpers';
import { TagsContext } from '../../../../context';
import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import Routes from '../../../../config/routes';

import styles from './index.module.scss';
import { FormattedMessage } from 'react-intl';

const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapId = useSelector(userSelectors.selectedMapId);
  const [searchValue, setSearchValue] = useState("");
  const [isSearchOpen, setSearchOpen] = useState(Boolean(searchValue));
  const { setDisableClickOnMap } = useOutletContext();
  const { clearFilteredTag, onClickFilterButton } = useContext(TagsContext);
  
  useEffect(() => {
    const pendingSearch = localStorage.getItem("pendingSearch");
    if (pendingSearch) {
      setSearchValue(pendingSearch);
      setSearchOpen(true);
      localStorage.removeItem("pendingSearch");
    }
  }, []);
  
  return (
    <>
      <div className={styles.searchBar}>
        <SearchInput
          value={searchValue}
          onFocus={() => searchValue?.trim() && !isSearchOpen && setSearchOpen(true)}
          onChange={e => {
            const inputValue = e.target.value;
            setSearchValue(inputValue);
            inputValue?.trim() && setSearchOpen(true);
          }}
          onClickDiscard={() => setSearchValue("")}
        />
        <CustomButton className={styles.addStepButton} onClick={() => navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`)} type={ButtonType.PRIMARY_BLUE}>
          <FormattedMessage id="searchSteps.add_place_button" />
        </CustomButton>
        <div className={styles.filterButton} onClick={onClickFilterButton}>
          <Sliders className={styles.filterIcon} />
        </div>
      </div>
      {searchValue && isSearchOpen && (
        <SearchPopUp
          showActionButtons
          mapId={mapId}
          searchValue={searchValue}
          className={styles.searchDropDown}
          onCancel={() => setSearchOpen(false)}
          onClickArea={() => setSearchOpen(false)}
          onClickCreatePlace={() => {
            setSearchValue("");
            clearFilteredTag();
            setDisableClickOnMap(false);
            navigate(`/${Routes.MAP}/${Routes.NEW_PLACE}`, {
              state: {
                placeName: searchValue,
                instantCreateStep: true
              }
            });
          }}
          onClickStep={step => {
            setSearchValue("");
            clearFilteredTag();
            const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, {
              stepId: step._id
            });
            navigate(url, { state: { from: "search_result" } });
            dispatch(
              mapActions.setMapCenter(mapId, {
                position: [step.lat, step.lon],
                zoom: 18
              })
            );
          }}
          onClickPlace={async place => {
            clearFilteredTag();
            if (!(place.lat && place.lon)) {
              place = await getPlaceCoordinates(place);
            }
            setSearchValue(place.title);
            const placeLocationObject = place.location;
            const placePosition = [place.lat || placeLocationObject?.lat, place.lon || placeLocationObject?.lon || placeLocationObject?.lng];
            dispatch(
              mapActions.setGhostStep(mapId, {
                position: {
                  lat: place.lat || placeLocationObject?.lat,
                  lon: place.lon || placeLocationObject?.lon || placeLocationObject?.lng
                },
                title: place.title || place.description,
                isOpenTooltip: true,
                isStickToMap: false,
                type: place.result_type,
                _id: place._id || place.place_id,
                address: place?.secondary_title
              })
            );
            dispatch(
              mapActions.setMapCenter(mapId, {
                position: placePosition,
                zoom: 18
              })
            );
            setSearchOpen(false);
            navigate(`/${Routes.MAP}/${Routes.ADD_STEP}`, {
              state: {
                flowStepId: AddPlaceSteps.ADD_STEP_FORM
              }
            });
          }}
        />
      )}
    </>
  );
};

export default SearchBar;
