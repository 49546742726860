import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../helpers/mixpanel';
import CommentsSection from './CommentsSection';
import styles from './index.module.scss';
import PostBody from './PostBody';
import PostHeader from './PostHeader';
import { feedActions } from '../../redux/feed';
import { postSelectors, postActions as postsActions } from '../../redux/posts';
import { postActions } from '../../redux/post';
import { userSelectors } from '../../redux/user';
import Input from '../common/inputs/Input';
import { CurrentUserAvatar } from '../common/Avatar';
import copies from '../../copies.json';
import ModalsEditPost from '../common/modals/ModalsEditPost';
import { useModal } from '../../hooks';
import ModalDelete from '../common/modals/ModalDelete';
import { generatePath, useNavigate } from 'react-router';
import Routes from '../../config/routes';
import DiscoveredBlock from '../Feed/LoadingPostsBlocks/DiscoveredBlock';

const deletePostCopies = copies.deletePost;

export const ShowCommentsOptions = {
    ALL: 'SHOW_ALL',
    NONE: 'SHOW_NONE',
    PARTIAL: 'SHOW_PARTIAL',
};

export const initialHeaderSettings = {
    showSecondaryImage: true,
    viewOnMap: true,
    creatorTitle: false,
    mentionsStepId: false,
};

const Post = ({
    postId = null,
    onClickUserBadge = noop,
    commentsSettings,
    repliesSettings,
    showReplies,
    allowComment = true,
    headerSettings = initialHeaderSettings,
    className,
    inputClassName,
    fromWhere = '',
    isInPostPage,
    onClick = noop,
    onBack = noop,
    onPinPostChange = noop,
    saveScrollPosition = noop,
    onTagPlace = noop,
    onTagError = noop,
    onTagClick = noop,
    isPinnable = false,
    isScrolling = false,
    usernameHeadlineOnly = false,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const post = useSelector(postSelectors.postById(postId)) || {};
    const {
        title,
        isClapped,
        clapsCount,
        totalComments,
        stepId,
        id,
        body,
        userId,
        attachments,
        attachmentsList,
        imagesId,
        comments = [],
        type,
        commentsCursor = null,
        isPinned,
        createdAt,
        fullSizeImages,
        mapId = null, // TODO: check and if not replace with selectedMapId
        creator,
        isFeedPost = false,
        stepTitle,
    } = post;
    const currentUser = useSelector(userSelectors.user);
    const [focusInput, setIsFocusInput] = useState(false);
    const { modalOpen: editPostModalOpen, setModalOpen: setEditPostModalOpen } = useModal(false);
    const { modalOpen: deletePostModalOpen, setModalOpen: setDeletePostModalOpen } = useModal(false);

    const pinPost = useCallback(() => {
        dispatch(postsActions.pinPost(postId, Number(!isPinned)));
        onPinPostChange(postId, Number(!isPinned));
    }, []);

    const deletePost = useCallback(() => {
        dispatch(postsActions.deletePost(mapId, stepId, postId));
        fromWhere === 'post_page' && onBack();
    }, [fromWhere, mapId, stepId, postId]);

    const onClickStepTitle = () => {
        saveScrollPosition();
        const url = generatePath(`/${Routes.MAP}/${Routes.STEP_PROFILE}`, { stepId });
        navigate(url);
    };

    const navigateToPostPage = useCallback(() => {
        if (isInPostPage) {
            setIsFocusInput(true);
            return;
        }
        saveScrollPosition();
        onClick(postId);
        mixpanel.track(mixpanelEvents.POST_PROFILE_OPEN, {
            [mixpanelProperties.STEP_TITLE]: title,
            [mixpanelProperties.FROM_WHERE]: 'map_list',
        });
    }, [postId]);

    const commentOnPost = async ({ textArray, files, value }) => {
        if ((!textArray.length && !files.length) || !value.trim()) {
            return;
        }

        const hasLocation = Boolean(textArray.find(({ text_type }) => text_type === 'step_tag'));
        const hasMemberTagged = Boolean(textArray.find(({ text_type }) => text_type === 'user_tag'));
        const locationTaggedCount = hasLocation ? textArray.filter(({ text_type }) => text_type === 'step_tag').length : 0;
        const memberTaggedCount = hasLocation ? textArray.filter(({ text_type }) => text_type === 'user_tag').length : 0;

        const comment = {
            parent_post_id: id,
            client_type: 'map_manager',
            text_array: textArray,
            attachments: files.map(image => ({
                type: 'IMAGE',
                image_id: image.imageId,
            })),
        };

        if (isFeedPost) {
            // comment on feed post
            await dispatch(
                postActions.createFeedPost({
                    mapId,
                    parentPostId: id,
                    textArray,
                    attachments: files.map(image => ({
                        type: 'IMAGE',
                        image_id: image.imageId,
                    })),
                })
            );
            dispatch(feedActions.getFeedPosts({ mapId }));
        } else {
            dispatch(
                postsActions.commentOnPost({
                    data: comment,
                    mapId,
                    stepId,
                    postId: id,
                    userName: currentUser.name,
                    userImageId: currentUser.image,
                    createdAt: new Date().getTime(),
                    replaceCurrent: fromWhere === 'feed',
                })
            );
        }

        mixpanel.track(mixpanelEvents.MM_POST_COMMENT_FINISHED, {
            [mixpanelProperties.FROM_WHERE]: fromWhere,
            [mixpanelProperties.STEP_ID]: stepId,
            [mixpanelProperties.IMAGE]: files.length > 0,
            [mixpanelProperties.TEXT]: value.length > 0,
            [mixpanelProperties.HAS_LOCATION_TAGGED]: hasLocation,
            [mixpanelProperties.HAS_MEMBER_TAGGED]: hasMemberTagged,
            [mixpanelProperties.LOCATION_TAGGED_COUNT]: locationTaggedCount,
            [mixpanelProperties.MEMBER_TAGGED_COUNT]: memberTaggedCount,
        });
    };
    if (!id) return <></>;

    return (
        <>
            {type === 'CREATED_BY' ? (
                <DiscoveredBlock user={creator} className={styles.discoverCard} step={{ id: stepId, title: stepTitle }} createdAt={createdAt} />
            ) : (
                <div
                    className={classNames(
                        styles.container,
                        {
                            [styles.postPage]: fromWhere === 'post_page',
                            [styles.borderBottom]: !showReplies,
                        },
                        className
                    )}
                >
                    {/* work around for double scroll(fromWhere === 'post_page') */}
                    <div className={classNames(styles.scroll, { [styles.postPageScroll]: fromWhere === 'post_page' })} id={`scrollTarget_${id}`}>
                        <PostHeader
                            postId={id}
                            settings={{
                                ...headerSettings,
                                showSecondaryImage: isFeedPost ? false : headerSettings.showSecondaryImage,
                                creatorTitle: isFeedPost ? true : headerSettings.creatorTitle,
                            }}
                            className={styles.header}
                            onClickComment={() => setIsFocusInput(true)}
                            onClickEdit={() => setEditPostModalOpen(true)}
                            onDeleteClicked={() => setDeletePostModalOpen(true)}
                            onPinPostClick={pinPost}
                            onClickUserBadge={onClickUserBadge}
                            stepId={stepId}
                            isFeedPost={isFeedPost}
                            isPinnable={isPinnable}
                            onClickTitle={onClickStepTitle}
                            userId={userId}
                            isScrolling={isScrolling}
                            usernameHeadlineOnly={usernameHeadlineOnly}
                        />
                        <PostBody
                            id={id}
                            isClapped={isClapped}
                            clapsCount={clapsCount}
                            commentsCount={totalComments}
                            stepId={stepId}
                            body={body}
                            mapId={mapId}
                            userId={userId}
                            type={type}
                            attachments={attachments}
                            attachmentsList={attachmentsList}
                            fullSizeImages={fullSizeImages}
                            creator={creator}
                            onPostClick={navigateToPostPage}
                            onClickComment={() => setIsFocusInput(true)}
                            title={title}
                            fromWhere={fromWhere}
                            className={styles.body}
                            onTagPlace={onTagPlace}
                            onTagError={onTagError}
                            onTagClick={onTagClick}
                        />
                        {!!comments?.length && (
                            <CommentsSection
                                postId={id}
                                comments={comments}
                                totalComments={totalComments}
                                cursor={commentsCursor}
                                commentsSettings={commentsSettings}
                                repliesSettings={repliesSettings}
                                showReplies={showReplies}
                                className={styles.comments}
                                scrollableTarget='scrollTarget'
                                onShowMoreCommentClicked={navigateToPostPage}
                            />
                        )}
                    </div>

                    {allowComment && (
                        <div
                            className={classNames(styles.commentContainer, inputClassName, {
                                [styles.postPage]: fromWhere === 'post_page',
                            })}
                        >
                            <CurrentUserAvatar />
                            <Input
                                id={`post-${id}-comment`}
                                isFocus={focusInput}
                                deleteOnEnter
                                placeholder={copies?.post?.leave_reply}
                                withFilePicker
                                onEnter={commentOnPost}
                            />
                        </div>
                    )}
                    {editPostModalOpen && (
                        <ModalsEditPost
                            onEditSuccess={() => setEditPostModalOpen(false)}
                            onCancel={() => setEditPostModalOpen(false)}
                            content={body}
                            attachments={attachments}
                            postId={id}
                            imagesId={imagesId}
                        />
                    )}
                    <ModalDelete
                        header={deletePostCopies?.header}
                        content={deletePostCopies?.content}
                        okContent={deletePostCopies?.delete}
                        cancelContent={deletePostCopies?.cancel}
                        showModal={deletePostModalOpen}
                        onCancel={() => setDeletePostModalOpen(false)}
                        onDelete={deletePost}
                    />
                </div>
            )}
        </>
    );
};

export default Post;

Post.propTypes = {
    comments: PropTypes.array,
    commentsSettings: PropTypes.exact({
        isShowAll: PropTypes.oneOf([ShowCommentsOptions.ALL, ShowCommentsOptions.NONE, ShowCommentsOptions.PARTIAL]),
        numberOfLastCommentsToShow: PropTypes.number,
        numberToLoad: PropTypes.number,
    }),
    repliesSettings: PropTypes.exact({
        isShowAll: PropTypes.oneOf([ShowCommentsOptions.ALL, ShowCommentsOptions.NONE, ShowCommentsOptions.PARTIAL]),
        numberOfLastCommentsToShow: PropTypes.number,
        numberToLoad: PropTypes.number,
    }),
    showReplies: PropTypes.bool,
};
