const IconClap = ({ className, width = 20, height = 22, color = '#3A3C3F', isClapped }) => {
    if (isClapped) {
        return (
            <svg width={width} height={height} viewBox='0 0 32 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.153 4.241a.65.65 0 0 0 .851.333.643.643 0 0 0 .336-.846L6.087.886a.65.65 0 0 0-.851-.333.643.643 0 0 0-.336.847l1.253 2.84Zm3.401.042a.64.64 0 0 0 .65.635.64.64 0 0 0 .64-.646l-.016-3.102a.64.64 0 0 0-.65-.635.64.64 0 0 0-.64.646l.016 3.102ZM5.781 7.135a.648.648 0 0 1-.902.148L2 5.231a.64.64 0 0 1-.15-.896.648.648 0 0 1 .902-.149l2.88 2.053a.64.64 0 0 1 .15.896Z'
                    fill='#1463DD'
                />
                <path
                    d='m7.004 4.574-.068-.159.068.16Zm-.851-.333.158-.07v.001l-.158.069Zm1.187-.513.158-.07-.158.07ZM6.087.886l-.158.07.158-.07ZM5.236.553l.068.16-.068-.16ZM4.9 1.4l-.158.07v-.002L4.9 1.4Zm5.304 3.518-.003-.173h.002v.173Zm-.65-.635-.173.001.173-.001Zm1.29-.01-.174.002v-.002h.173Zm-.016-3.103H11h-.173Zm-.65-.635.002.173h-.001l-.001-.173Zm-.64.646.173-.003v.002h-.173ZM4.88 7.283l-.1.141.1-.141ZM2 5.231l.1-.142-.1.142Zm-.15-.896.142.1-.141-.1Zm.902-.149.1-.141-.1.141Zm2.88 2.053-.1.14.1-.14Zm1.442-1.506a.823.823 0 0 1-1.08-.423l.318-.138a.477.477 0 0 0 .625.243l.137.318Zm.425-1.074a.816.816 0 0 1-.425 1.074l-.137-.318a.47.47 0 0 0 .245-.618l.317-.138ZM6.246.817l1.252 2.841-.317.14L5.93.956l.317-.14ZM5.167.394a.823.823 0 0 1 1.079.423l-.318.138a.477.477 0 0 0-.624-.243L5.167.394Zm-.426 1.075A.816.816 0 0 1 5.167.393l.137.318a.47.47 0 0 0-.245.619l-.318.137ZM5.994 4.31 4.742 1.469l.316-.14 1.253 2.842-.317.14Zm4.213.78a.814.814 0 0 1-.826-.807l.346-.003a.468.468 0 0 0 .474.464l.005.346Zm.81-.821a.814.814 0 0 1-.812.821l-.002-.346a.467.467 0 0 0 .467-.47l.346-.005ZM11 1.17l.015 3.101-.346.002-.015-3.102L11 1.17Zm-.826-.808a.814.814 0 0 1 .826.807l-.346.003a.468.468 0 0 0-.475-.464l-.005-.346Zm-.81.821a.814.814 0 0 1 .812-.821l.002.346a.467.467 0 0 0-.468.47l-.346.005Zm.016 3.1-.016-3.101.346-.002.016 3.102-.346.002Zm-4.401 2.86c.214.151.51.102.66-.108l.282.201a.821.821 0 0 1-1.142.19l.2-.283ZM2.1 5.09l2.88 2.052-.2.282L1.9 5.372l.2-.282Zm-.108-.655a.466.466 0 0 0 .108.654l-.2.283a.812.812 0 0 1-.19-1.138l.282.201Zm.66-.107a.475.475 0 0 0-.66.107l-.282-.201a.821.821 0 0 1 1.142-.189l-.2.283Zm2.88 2.052L2.65 4.327l.2-.282 2.88 2.053-.2.282Zm.109.654a.466.466 0 0 0-.109-.654l.2-.283c.37.262.453.772.19 1.138l-.281-.201Z'
                    stroke='#1463DD'
                    fill='#1463DD'
                    strokeWidth={0.5}
                />
                <path
                    d='m19.868 13.848-2.31.17-2.692-.17-4.788.99a2.243 2.243 0 0 0-3.886 2.243l4.262 7.383a8.972 8.972 0 0 0 14.892.977 8.973 8.973 0 0 0 .65-9.95l-2.242-3.886a2.243 2.243 0 1 0-3.886 2.243Z'
                    fill='#86B6FF'
                    stroke='#1463DD'
                    strokeWidth={1.5}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path d='m15.533 15.315-2.916-5.052a2.244 2.244 0 0 1 3.885-2.243l4.487 7.771' fill='#86B6FF' />
                <path
                    d='m15.533 15.315-2.916-5.052a2.244 2.244 0 0 1 3.885-2.243l4.487 7.771'
                    stroke='#1463DD'
                    strokeWidth={1.5}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path d='m12.097 18.337-4.263-7.383a2.243 2.243 0 1 1 3.886-2.243l3.814 6.605' fill='#86B6FF' />
                <path
                    d='m12.097 18.337-4.263-7.383a2.243 2.243 0 1 1 3.886-2.243l3.814 6.605'
                    stroke='#1463DD'
                    strokeWidth={1.5}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='m17.778 17.129-2.307-.207-2.628-.605-4.886.199a2.243 2.243 0 0 0-4.198 1.582l3.007 7.977a8.972 8.972 0 1 0 16.793-6.33l-1.582-4.199a2.243 2.243 0 1 0-4.199 1.583Z'
                    fill='#86B6FF'
                    stroke='#1463DD'
                    strokeWidth={1.5}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path d='m13.264 17.875-2.058-5.457a2.243 2.243 0 0 1 4.199-1.583l3.165 8.397' fill='#86B6FF' />
                <path
                    d='m13.264 17.875-2.058-5.457a2.243 2.243 0 0 1 4.199-1.583l3.165 8.397'
                    stroke='#1463DD'
                    strokeWidth={1.5}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path d='M9.38 20.298 6.373 12.32a2.243 2.243 0 1 1 4.199-1.583l2.69 7.138' fill='#86B6FF' />
                <path
                    d='M9.38 20.298 6.373 12.32a2.243 2.243 0 1 1 4.199-1.583l2.69 7.138'
                    stroke='#1463DD'
                    strokeWidth={1.5}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        );
    }

    return (
        <svg width={width} height={height} viewBox='0 0 32 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.153 4.241a.65.65 0 0 0 .851.333.643.643 0 0 0 .336-.846L6.087.886a.65.65 0 0 0-.851-.333.643.643 0 0 0-.336.847l1.253 2.84Zm3.401.042a.64.64 0 0 0 .65.635.64.64 0 0 0 .64-.646l-.016-3.102a.64.64 0 0 0-.65-.635.64.64 0 0 0-.64.646l.016 3.102ZM5.781 7.135a.648.648 0 0 1-.902.148L2 5.231a.64.64 0 0 1-.15-.896.648.648 0 0 1 .902-.149l2.88 2.053a.64.64 0 0 1 .15.896Z'
                fill='#3A3C3F'
            />
            <path
                d='m7.004 4.574-.068-.159.068.16Zm-.851-.333.158-.07v.001l-.158.069Zm1.187-.513.158-.07-.158.07ZM6.087.886l-.158.07.158-.07ZM5.236.553l.068.16-.068-.16ZM4.9 1.4l-.158.07v-.002L4.9 1.4Zm5.304 3.518-.003-.173h.002v.173Zm-.65-.635-.173.001.173-.001Zm1.29-.01-.174.002v-.002h.173Zm-.016-3.103H11h-.173Zm-.65-.635.002.173h-.001l-.001-.173Zm-.64.646.173-.003v.002h-.173ZM4.88 7.283l-.1.141.1-.141ZM2 5.231l.1-.142-.1.142Zm-.15-.896.142.1-.141-.1Zm.902-.149.1-.141-.1.141Zm2.88 2.053-.1.14.1-.14Zm1.442-1.506a.823.823 0 0 1-1.08-.423l.318-.138a.477.477 0 0 0 .625.243l.137.318Zm.425-1.074a.816.816 0 0 1-.425 1.074l-.137-.318a.47.47 0 0 0 .245-.618l.317-.138ZM6.246.817l1.252 2.841-.317.14L5.93.956l.317-.14ZM5.167.394a.823.823 0 0 1 1.079.423l-.318.138a.477.477 0 0 0-.624-.243L5.167.394Zm-.426 1.075A.816.816 0 0 1 5.167.393l.137.318a.47.47 0 0 0-.245.619l-.318.137ZM5.994 4.31 4.742 1.469l.316-.14 1.253 2.842-.317.14Zm4.213.78a.814.814 0 0 1-.826-.807l.346-.003a.468.468 0 0 0 .474.464l.005.346Zm.81-.821a.814.814 0 0 1-.812.821l-.002-.346a.467.467 0 0 0 .467-.47l.346-.005ZM11 1.17l.015 3.101-.346.002-.015-3.102L11 1.17Zm-.826-.808a.814.814 0 0 1 .826.807l-.346.003a.468.468 0 0 0-.475-.464l-.005-.346Zm-.81.821a.814.814 0 0 1 .812-.821l.002.346a.467.467 0 0 0-.468.47l-.346.005Zm.016 3.1-.016-3.101.346-.002.016 3.102-.346.002Zm-4.401 2.86c.214.151.51.102.66-.108l.282.201a.821.821 0 0 1-1.142.19l.2-.283ZM2.1 5.09l2.88 2.052-.2.282L1.9 5.372l.2-.282Zm-.108-.655a.466.466 0 0 0 .108.654l-.2.283a.812.812 0 0 1-.19-1.138l.282.201Zm.66-.107a.475.475 0 0 0-.66.107l-.282-.201a.821.821 0 0 1 1.142-.189l-.2.283Zm2.88 2.052L2.65 4.327l.2-.282 2.88 2.053-.2.282Zm.109.654a.466.466 0 0 0-.109-.654l.2-.283c.37.262.453.772.19 1.138l-.281-.201Z'
                stroke='#3A3C3F'
                strokeWidth={0.5}
            />
            <path
                d='m19.868 13.848-2.31.17-2.692-.17-4.788.99a2.243 2.243 0 0 0-3.886 2.243l4.262 7.383a8.972 8.972 0 0 0 14.892.977 8.973 8.973 0 0 0 .65-9.95l-2.242-3.886a2.243 2.243 0 1 0-3.886 2.243Z'
                fill='#fff'
                stroke='#3A3C3F'
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='m15.533 15.315-2.916-5.052a2.244 2.244 0 0 1 3.885-2.243l4.487 7.771' fill='#fff' />
            <path
                d='m15.533 15.315-2.916-5.052a2.244 2.244 0 0 1 3.885-2.243l4.487 7.771'
                stroke='#3A3C3F'
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='m12.097 18.337-4.263-7.383a2.243 2.243 0 1 1 3.886-2.243l3.814 6.605' fill='#fff' />
            <path
                d='m12.097 18.337-4.263-7.383a2.243 2.243 0 1 1 3.886-2.243l3.814 6.605'
                stroke='#3A3C3F'
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='m17.778 17.129-2.307-.207-2.628-.605-4.886.199a2.243 2.243 0 0 0-4.198 1.582l3.007 7.977a8.972 8.972 0 1 0 16.793-6.33l-1.582-4.199a2.243 2.243 0 1 0-4.199 1.583Z'
                fill='#fff'
                stroke='#3A3C3F'
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='m13.264 17.875-2.058-5.457a2.243 2.243 0 0 1 4.199-1.583l3.165 8.397' fill='#fff' />
            <path
                d='m13.264 17.875-2.058-5.457a2.243 2.243 0 0 1 4.199-1.583l3.165 8.397'
                stroke='#3A3C3F'
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M9.38 20.298 6.373 12.32a2.243 2.243 0 1 1 4.199-1.583l2.69 7.138' fill='#fff' />
            <path
                d='M9.38 20.298 6.373 12.32a2.243 2.243 0 1 1 4.199-1.583l2.69 7.138'
                stroke='#3A3C3F'
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default IconClap;
