import config from '../config';
import branch from 'branch-sdk';
import { PLATFORM_TYPE } from '../config/constants';
import { get } from 'lodash';
import queryString from 'query-string';
import axios from 'axios';
import mixpanel from './mixpanel';
import { MAX_LAT, MAX_LNG, MIN_LAT, MIN_LNG, StepTypes } from '../constants';

branch.init(config.branch.key, { no_journeys: true }, function (err, data) {
  localStorage.setItem('dataSessionStart', JSON.stringify(data));
});

const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent);

export const generateImageUrl = ({ imageId, width, height, reason, rotate }) => {
  if (!imageId || !reason) {
    return;
  }
  
  const query = queryString.stringify({
    width: width,
    height: height,
    reason: reason,
    auto_rotate: rotate,
    reflected_distinct_id: mixpanel.get_distinct_id(),
    app_platform: 'map_manager',
    app_version: config.version.path,
  });
  
  return `${config.domain.address}/imagedashboard/${imageId}?${query}`;
};

export const arrToObj = (arr, keyAsId) => {
  const res = {};
  for (const elem of arr) res[elem[keyAsId]] = elem;
  return res;
};

export const hasWhiteSpace = s => {
  return s.indexOf(' ') >= 0;
};

export const getClientType = () => (isMobile ? PLATFORM_TYPE.MAP_MANAGER_MOBILE : PLATFORM_TYPE.MAP_MANAGER);

export const sendEventNotification = async ({ data, eventName }) => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/event`, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
    body: JSON.stringify({
      eventName,
      data,
    }),
  }).then(res => res.json());
};

export const copyToClipboard = textToCopy => {
  let textArea;
  
  function isOS() {
    //can use a better detection logic here
    return navigator.userAgent.match(/ipad|iphone/i);
  }
  
  function createTextArea(text) {
    textArea = document.createElement('textArea');
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = text;
    document.body.appendChild(textArea);
  }
  
  function selectText() {
    let range, selection;
    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }
  
  function copyTo() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
  
  createTextArea(textToCopy);
  selectText();
  copyTo();
};

export const createQrCode = mapId =>
    new Promise((resolve, reject) =>
        branch.link(
            process.env.REACT_APP_STEPS_ENV === 'production'
                ? {
                  channel: 'generate_link',
                  feature: 'create link',
                  stage: 'create link',
                  data: {
                    $desktop_url: `https://web.steps.me/m/${mapId}`,
                    $deeplink_path: `open/m/${mapId}`,
                    $canonical_identifier: `map/${mapId}`,
                    $canonical_url: `https://web.steps.me/m/${mapId}`,
                    product: 'map-manager',
                    created_by_map_manager: 'yes',
                  },
                }
                : process.env.REACT_APP_STEPS_ENV === 'test' && {
              channel: 'generate_link',
              feature: 'create link',
              stage: 'create link',
              data: {
                $desktop_url: `https://devweb.steps.me/m/${mapId}`,
                $deeplink_path: `open/m/${mapId}`,
                $canonical_identifier: `map/${mapId}`,
                $canonical_url: `https://devweb.steps.me/m/${mapId}`,
                product: 'map-manager',
                created_by_map_manager: 'yes',
              },
            },
            (err, data) => {
              if (err) reject(err);
              resolve(data);
            }
        )
    );

export const createDeepLink = async (
    mapId,
    { campaign, inProductShareMethod, channel, feature = 'sharing', stage, client_type, link_type, useMixPanelMerge, data, reason, isAutls }
) => {
  const accessToken = localStorage.getItem('at');
  const isAutlsEndPoint = isAutls ? 'autls/' : '';
  const path = `/${isAutlsEndPoint}branch-links`;
  const result = await fetch(encodeURI(config.domain.address + path), {
    method: 'POST',
    headers: {
      app_version: config.version.path,
      app_platform: 'map_manager',
      Authorization: accessToken,
    },
    body: JSON.stringify({
      link_type, // ["map", "step", "map_store", "step_store", "store"]
      origin_url: window.location.href,
      in_product_share_method: inProductShareMethod,
      distinct_id: mixpanel.get_distinct_id(),
      data:
          data || mapId
              ? {
                ...data,
                map_id: mapId,
                product: 'map_manager',
              }
              : {},
      use_mixpanel_merge: useMixPanelMerge, // We should use this flag just when referring to store!
      channel,
      feature,
      reason,
      client_type: 'map_manager', // @TODO - Remove this line when new branch params merged to develop
    }),
  });
  const { link } = await result.json();
  return link;
};

export const mapTagObjects = (tagObjects, tagCategories) => {
  if (tagCategories) {
    const categoryAllLength = tagObjects.filter(el => (el.category === 'All' || el.category === 'General') && el.category_id !== null).length;
    if (categoryAllLength > 0 && !tagCategories.some(el => (el.name === 'All' || el.name === 'General') && el._id !== null)) {
      tagCategories.push({ name: 'General' });
    }
    
    const mapCategories = tagCategories.map(el => ({
      category: el.name,
      category_id: el._id,
      priority: el.priority,
      is_new: false,
      should_be_deleted: false,
      should_be_renamed: false,
      tags: tagObjects
          .filter(item => item.category_id === el._id)
          .map(s => ({
            active: s.active,
            category: s.category,
            category_id: s.category_id,
            category_priority: s.category_priority,
            is_selected: s.is_selected,
            name: s.name,
            priority: s.priority,
            _id: s._id,
            is_new: false,
            should_be_deleted: false,
            should_be_renamed: false,
          })),
    }));
    
    mapCategories.sort((a, b) => {
      return b.priority - a.priority;
    });
    
    return mapCategories.reduce((acc, el) => {
      acc[el.category_id] = el;
      return acc;
    }, {});
  } else {
    const categories = tagObjects.map(tag => tag.category);
    const categoryAllLength = tagObjects.filter(el => (el.category === 'All' || el.category === 'General') && el.category_id !== null).length;
    if (categoryAllLength > 0 && !tagObjects?.some(el => (el.name === 'All' || el.name === 'General') && el._id !== null)) {
      categories.push({ name: 'General' });
    }
    const mapCategories = tagObjects.map(el => ({
      category: el.category,
      category_id: el.category_id,
      category_priority: el.category_priority,
      is_new: false,
      should_be_deleted: false,
      should_be_renamed: false,
      tags: tagObjects
          .filter(item => item.category_id === el.category_id)
          .map(s => ({
            active: s.active,
            category: s.category,
            category_id: s.category_id,
            category_priority: s.category_priority,
            is_selected: s.is_selected,
            name: s.name,
            priority: s.priority,
            _id: s._id,
            is_new: false,
            should_be_deleted: false,
            should_be_renamed: false,
          })),
    }));
    
    mapCategories.sort((a, b) => {
      return b.category_priority - a.category_priority;
    });
    
    return mapCategories.reduce((acc, el) => {
      if (el.category !== undefined) acc[el.category_id] = el;
      return acc;
    }, {});
  }
};

export const uploadToAWS = async files => {
  if (files.length === 0) {
    return;
  }
  for (const { file, links } of files) {
    const url = Array.isArray(links) ? links[0].url : links.url;
    if (!url) {
      return;
    }
    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    };
    await axios.put(url, file, config);
  }
};

export const blobUrl = blob => {
  if (!blob?.url) {
    blob.url = URL.createObjectURL(blob);
  }
  return blob?.url;
};

export const getUploadImportFile = type => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/steps3/upload-import-file?import_type=${type}`, {
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const getUploadImageInfo = () => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + '/uploadpostimage', {
    method: 'GET',
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  })
      .then(res => res.json())
      .catch(error => {
        console.log(error);
      });
};

export const getUploadMapImgLink = mapId => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/uploadmapimage?map_id=${mapId}`, {
    method: 'GET',
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const getUploadUserProfileImgLink = () => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/uploadprofilepic`, {
    method: 'GET',
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const getHashValue = key => {
  var matches = window.location.hash.match(new RegExp(key + '=([^&]*)'));
  return matches ? matches[1] : null;
};

export const getInstagramPostsData = (code, mapId, redirectUrl) => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/steps3/instagram/onboardingposts`, {
    method: 'POST',
    body: JSON.stringify({
      code,
      map_id: mapId,
      redirect_url: redirectUrl,
    }),
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const approvePendingPost = postId => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/steps3/pendingposts/${postId}/approve`, {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const removePendingPost = postId => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/steps3/pendingposts/${postId}`, {
    method: 'DELETE',
    body: JSON.stringify({}),
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const getPendingPosts = (mapId, { cursor, isWithLocationOnly }) => {
  const accessToken = localStorage.getItem('at');
  const query = {
    map_id: mapId,
    only_with_location: isWithLocationOnly,
  };
  if (cursor) query.cursor = cursor;
  if (!isWithLocationOnly) delete query.isWithLocationOnly;
  return fetch(config.domain.address + `/steps3/pendingposts?${queryString.stringify(query)}`, {
    method: 'GET',
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const getImportData = importId => {
  const accessToken = localStorage.getItem('at');
  return fetch(config.domain.address + `/steps3/imports/${importId}`, {
    method: 'GET',
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const handleRecentActivitie = async (mapId, activitieId, outcome) => {
  const accessToken = localStorage.getItem('at');
  let data = {};
  try {
    const response = await fetch(config.domain.address + `/steps3/recentactivities/${activitieId}/handle`, {
      method: 'POST',
      body: JSON.stringify({ response: outcome }),
      headers: {
        Authorization: accessToken,
        app_version: config.version.path,
        app_platform: 'map_manager',
      },
    });
    data = await response.json();
  } catch (error) {
    console.error('handleRecentActivitie error!', error);
  }
  
  return data;
};

export const getRecentActivities = (mapId, { cursor, isOnlyUnhandled, filteredTypes }) => {
  const accessToken = localStorage.getItem('at');
  const query = {
    map_id: mapId,
    only_unhandled: isOnlyUnhandled,
    only_types: filteredTypes,
  };
  if (cursor) query.cursor = cursor;
  if (!isOnlyUnhandled) delete query.only_unhandled;
  
  return fetch(config.domain.address + `/steps3/recentactivities?${queryString.stringify(query)}`, {
    method: 'GET',
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const uploadImageToS3 = async ({ uploadUrl, file, onUploadedFile }) => {
  if (!uploadUrl || !file) return;
  
  const reader = new FileReader();
  reader.onloadend = async () => {
    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    };
    await axios.put(uploadUrl, file, config);
    onUploadedFile(file);
  };
  
  return await reader.readAsDataURL(file);
};

export const getManagerMaps = () =>
    new Promise(resolve => {
      const accessToken = localStorage.getItem('at');
      const fetchData = async () => {
        const result = await fetch(config.domain.address + '/steps3/managermaps', {
          headers: {
            Authorization: accessToken,
            app_version: config.version.path,
            app_platform: 'map_manager',
          },
        });
        if (result.ok) {
          const { maps } = await result.json();
          resolve(maps);
        } else setTimeout(fetchData, 500);
      };
      setTimeout(fetchData);
    });

const getInBoundsCoordinates = coordinates => {
  return {
    top_left_lat: Math.min(coordinates?.['_ne']?.lat, MAX_LAT), // lat < MAX_LAT
    bottom_right_lat: Math.max(coordinates?.['_sw']?.lat, MIN_LAT), // lat > MIN_LAT
    top_left_lon: Math.max(coordinates?.['_sw']?.lng, MIN_LNG), // lng > MIN_LNG
    bottom_right_lon: Math.min(coordinates?.['_ne']?.lng, MAX_LNG), // lng < MAX_LNG
  };
};

export const getStepsByBounds = async ({ mapId, bounds, cursor = null, sortedBy, status, tag, searchTerm, orderedBy, limit }) => {
  const accessToken = localStorage.getItem('at');
  const query = queryString.stringify({
    map_id: mapId,
    cursor,
    tag,
    client_type: 'map_manager',
    sort_by: sortedBy,
    order_by: orderedBy,
    status,
    limit,
    search_term: searchTerm,
    ...getInBoundsCoordinates(bounds),
  });
  const result = await fetch(config.domain.address + `/steps3/steps?${query}`, {
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  });
  const data = await result.json();
  return {
    steps: data.steps,
    firstCursor: data.first_cursor,
    cursor: data.cursor,
    total: data['total_results'],
  };
};

export const refreshToken = async ({ at, rt }) => {
  const result = await fetch(config.domain.address + '/renew', {
    method: 'POST',
    body: JSON.stringify({ access_token: at, refresh_token: rt }),
    headers: {
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  });
  const tokens = await result.json();
  return tokens;
};

export const sendMessagePromoteToSupport = ({ type, message }) => {
  const accessToken = localStorage.getItem('at');
  const query = {
    type: type,
    message: message,
  };
  return fetch(config.domain.address + `/mapsupportemail`, {
    method: 'POST',
    body: JSON.stringify(query),
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const copyToClipboardFunc = text => navigator.clipboard.writeText(text);

export const checkAverage = value => {
  if (value === 5) {
    return true;
  } else if (value === 4) {
    return true;
  } else if (value === 3) {
    return true;
  } else if (value === 2) {
    return true;
  } else if (value === 1) {
    return true;
  } else {
    return false;
  }
};

export function deleteSameString(a) {
  let i = 0;
  let q = 0;
  for (; q < a.length; ++q) {
    if (a[q] !== a[q - 1]) {
      a[i++] = a[q];
    }
  }
  a.length = i;
  return a;
}

export const arraysEqual = (_arr1, _arr2) => {
  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;
  let arr1 = _arr1.concat().sort();
  let arr2 = _arr2.concat().sort();
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

// Format 1200 -> 1.2k
export const thousandsFormatter = num => {
  return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K' : Math.sign(num) * Math.abs(num);
};

export const getImageSrcFromFile = ({ image, reason, isGenerateInFixedSize = false, width = 200, height = 200 }) => {
  let imageObj = { imageId: image?.attachment?.image_id, reason };
  
  if (image?.attachment) {
    if (isGenerateInFixedSize) return generateImageUrl({ ...imageObj, width, height });
    else return generateImageUrl(imageObj);
  }
  
  return blobUrl(image.file);
};

export const shortName = (item, size) => {
  let sliced = [];
  let k = 0;
  let j = size;
  if (item?.length > size) {
    for (let i = 0; i < Math.ceil(item.length / size); i++) {
      sliced.push(item?.slice(k, j));
      j += size;
      k += size;
    }
    return sliced;
  }
  
  return [item];
};

export const distance = (lat1, lon1, lat2, lon2) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  return dist;
};

export const convertToSeperatorOrAbbreviate = number => {
  if (number < 10_000) return convertNumberToThousandSepartor({ numberToConvert: number });
  else
    return convertNumberToAbbreviatedString({
      numberToConvert: number,
    });
};

export const convertNumberToThousandSepartor = ({ numberToConvert, minNumberToNotConvert = 999 }) => {
  if (numberToConvert <= minNumberToNotConvert) return numberToConvert;
  return Intl.NumberFormat('en-US').format(numberToConvert);
};

export const convertNumberToAbbreviatedString = ({ numberToConvert, minNumberToNotConvert = 999 }) => {
  if (numberToConvert <= minNumberToNotConvert) return numberToConvert;
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(numberToConvert);
};

export const updateAllStepsTags = ({ mapId, tagsToAdd, tagsToRemove, excludedStepsIds, cursor }) => {
  const accessToken = localStorage.getItem('at');
  const query = JSON.stringify({
    map_id: mapId,
    set_to_all: true,
    tags_to_add: tagsToAdd,
    tags_to_remove: tagsToRemove,
    first_cursor: cursor,
    exclude_steps_ids: excludedStepsIds,
  });
  return fetch(config.domain.address + `/steps3/bulk-set-tags`, {
    method: 'POST',
    body: query,
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  }).then(res => res.json());
};

export const searchByValue = async ({ map_id, data_type, term, lat, lon, include_archived, max_radius }) => {
  const accessToken = localStorage.getItem('at');
  const query = queryString.stringify({
    map_id,
    data_type,
    term,
    lat,
    lon,
    include_archived,
    max_radius,
  });
  let response = await fetch(`${config.domain.address}/steps3/search?${query}`, {
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  });
  const { results } = await response.json();
  return results;
};

export const searchForStepCreation = async ({ map_id, search_term, lat, lon }) => {
  const accessToken = localStorage.getItem('at');
  const query = queryString.stringify({
    map_id,
    search_term,
    lat,
    lon,
  });
  let response = await fetch(`${config.domain.address}/steps3/searchforpostcreation?${query}`, {
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  });
  
  const { results } = await response.json();
  return results;
};

export const getPlaceCoordinates = async place => {
  const accessToken = localStorage.getItem('at');
  const placeId = place.place_id || place.id || place._id;
  const url = `${config.domain.address}/steps3/search/placeidtocoordinate?place_id=${placeId}`;
  
  const response = await axios.get(url, {
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  });
  
  return {
    ...place,
    location: response?.data?.geometry?.location,
  };
};

export const sendMMEmailLink = async email => {
  await fetch(config.domain.address + `/steps3/autls/emails/map-manager-link`, {
    method: 'POST',
    headers: {
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
    body: JSON.stringify({
      email,
      distinct_id: mixpanel.get_distinct_id(),
    }),
  });
};

export const checkIfAlreadyInPosition = (currentLocation, clickedLocation) => {
  if (!clickedLocation || !currentLocation) {
    return false;
  }
  return currentLocation.lat.toFixed(2) === clickedLocation.lat.toFixed(2) && currentLocation.lng.toFixed(2) === clickedLocation.lng.toFixed(2);
};

export const isStepTypeOf = (stepType, step) => {
  const stepIndications = get(step, 'indications', []);
  return stepIndications.some(indication => indication.type === stepType);
};

export const findStepType = step => {
  const stepIndications = get(step, 'indications[0]', {});
  return stepIndications?.type || step.preview_type || StepTypes.REGULAR;
};
export const getAddressByCoordinates = async ({ lat, lon }) => {
  const accessToken = localStorage.getItem('at');
  const query = queryString.stringify({
    lat,
    lon,
  });
  let response = await fetch(`${config.domain.address}/steps3/reverse-geocode?${query}`, {
    headers: {
      Authorization: accessToken,
      app_version: config.version.path,
      app_platform: 'map_manager',
    },
  });
  const { address } = await response.json();
  return address;
};

export const orderedTagsByCategory = tags => {
  const orderedTags = {};
  for (const tag of tags) {
    if (!orderedTags[tag.category_id]) orderedTags[tag.category_id] = [tag];
    else orderedTags[tag.category_id].push(tag);
  }
  return orderedTags;
};

export const filterStepsByTags = (tags, steps) => {
  let filteredSteps = null;
  const orderedTags = orderedTagsByCategory(tags);
  for (const categoryId in orderedTags) {
    let filteredStepsCategory = {};
    for (const tag of orderedTags[categoryId]) {
      filteredStepsCategory = {
        ...filteredStepsCategory,
        ...arrToObj(
            steps.filter(step => step.selected_tags_ids.includes(tag._id)),
            '_id'
        ),
      };
    }
    if (filteredStepsCategory.length == 0) return [];
    if (!filteredSteps) filteredSteps = filteredStepsCategory;
    else {
      for (const stepId in filteredSteps) {
        if (!filteredStepsCategory[stepId]) delete filteredSteps[stepId];
      }
    }
  }
  return Object.values(filteredSteps || {});
};

export * from './string';
export * from './storage';
export * from './permissions';
export * from './search';
export * from './object';
export * from './regex';
