export const Outcomes = {
    UNHANDLED: 'unhandled',
    DELETE: 'delete',
    DISREGARD: 'disregard',
    CLAP: 'clap',
    REPLY: 'reply',
    THANKS: 'thanks',
    DECLINE: 'decline',
    APPROVE: 'approve',
    IGNORE: 'ignore',
};

export const CardsTypes = {
    USER_JOINED_MAP: 'USER_JOINED_MAP',
    USER_RATED: 'USER_RATED',
    TAGS_EDITED: 'TAGS_EDITED',
    USER_SUGGESTED_TAGS: 'USER_SUGGESTED_TAGS',
    USER_POSTED: 'USER_POSTED',
    STEP_DISCOVERED: 'STEP_DISCOVERED',
    REPORT_STEP: 'REPORT_STEP',
    REPORT_POST: 'REPORT_POST',
    USER_SUGGESTED_STEP: 'USER_SUGGESTED_STEP',
    BUSINESS_STATUS_CHANGED: 'BUSINESS_STATUS_CHANGED',
};
