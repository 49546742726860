import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export const DragAndDrop = ({ children, onDrop, types, takeFirst, imageLoader, ...props }) => {
    const [isDragOverStarted, toggleDragOver] = useState(false);
    const [counter, setCount] = useState(0);

    const onDragEnter = e => {
        let count = counter + 1;
        e.preventDefault();
        e.stopPropagation();
        toggleDragOver(count > 0);
        setCount(count);
    };
    const onDragLeave = e => {
        let count = counter - 1;
        toggleDragOver(count > 0);
        setCount(count);
    };
    const onFileDrop = e => {
        e.preventDefault();
        setCount(0);
        toggleDragOver(false);
        onDrop(e.dataTransfer.files);
    };
    const onDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    return (
        <div
            className={classNames(styles.dropWrapper, { [styles.imgNoEmpty]: imageLoader }, { [styles.imgHover]: isDragOverStarted })}
            onDrop={onFileDrop}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            {...props}
        >
            {isDragOverStarted && (
                <div className={styles.dropContaner}>
                    <div>
                        <img className={styles.imgEmpty} src='/assets/img/steps3Images/dranAndDropColor.svg' alt='' />
                        <p className={styles.text}>Drop your file here</p>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

export default DragAndDrop;
