import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip';

const VideoPlayer = ({ onClose }) => {
    const [smallPlayer, stePlayer] = useState(false);
    const [openHoverTooltip, setHoverTooltip] = useState(false);

    return (
        <div
            className={classNames(styles.videoPlayerContainer, {
                [styles.smallPlayer]: smallPlayer,
            })}
        >
            <div
                className={classNames(styles.block, {
                    [styles.smallBlock]: smallPlayer,
                })}
            >
                <div
                    onClick={onClose}
                    className={classNames(styles.close, {
                        [styles.closeSmall]: smallPlayer,
                    })}
                >
                    <img src='/assets/img/steps3Images/closeVideo.svg' alt='' />
                </div>
                <Tooltip
                    TransitionComponent={Zoom}
                    placement='left'
                    title='Exit full screen'
                    disableHoverListener={smallPlayer}
                    open={openHoverTooltip}
                    onClick={() => {
                        setHoverTooltip(false);
                        stePlayer(!smallPlayer);
                    }}
                >
                    {!smallPlayer ? (
                        <div
                            onMouseOver={() => !smallPlayer && setHoverTooltip(true)}
                            onMouseLeave={() => !smallPlayer && setHoverTooltip(false)}
                            className={styles.fullScreen}
                        >
                            <img src='/assets/img/steps3Images/fullScreen.svg' alt='' />
                        </div>
                    ) : (
                        <img className={classNames(styles.smallFullScreen)} src='/assets/img/steps3Images/closeSmall.svg' alt='' />
                    )}
                </Tooltip>
                <iframe
                    src='https://www.youtube.com/embed/nkNYtydPwns?controls=2&modestbranding=1&rel=0&showinfo=0&fs=0&iv_load_policy=3'
                    frameBorder='0'
                    width='100%'
                    height='100%'
                />
                {smallPlayer && <div className={styles.text}>How do I export a file from Google My Maps?</div>}
            </div>
        </div>
    );
};
export default VideoPlayer;
