import React, { useState, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './index.module.scss';
import Tippy from '@tippy.js/react';
import { useNavigate } from 'react-router-dom';
import { userSelectors } from '../../redux/user';
import { mapSelectors, mapActions } from '../../redux/map';
import { stepActions } from '../../redux/step';
import copiesPrefix from '../../copies.json';
import { generateImageUrl, getUploadMapImgLink, uploadImageToS3 } from '../../helpers';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import { intToShortString } from '../../helpers/string';

const copies = copiesPrefix.sidebar;

const MapPicker = ({ maps = [], onClickMap }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mapId = useSelector(userSelectors.selectedMapId);
  const map = useSelector(mapSelectors.map(mapId));
  const mapSelector = useSelector(mapSelectors.mapSelector(mapId));
  const totalSteps = useSelector(mapSelectors.map(mapId))?.steps_count;
  const membersCount = mapSelector['members_count'] || 0;
  const coverChangeImageInput3 = useRef(null);
  const [hovered, setHover] = useState(false);
  const [openMapsDropDown, setOpenMapsDropDown] = useState(false);
  const isEmptyImg = false;
  
  useLayoutEffect(() => {
    coverChangeImageInput3.current?.addEventListener('change', changeMapCoverPhoto);
    return () => {
      coverChangeImageInput3.current?.removeEventListener('change', changeMapCoverPhoto);
    };
  });
  
  const changeMapCoverPhoto = e => {
    const target = e.target;
    getUploadMapImgLink(map?._id).then(data => {
      uploadImageToS3({
        uploadUrl: data.url,
        file: target.files[0],
        onUploadedFile: () => dispatch(mapActions.setMapCoverImage(map._id, URL.createObjectURL(target.files[0]))),
      });
    });
  };
  
  const handleClick = () => {
    setOpenMapsDropDown(open => !open);
  };
  
  const clickArrow = map => {
    // TODO: Add mapId queryParam when possible
    dispatch(stepActions.changeStatusSteps('active'));
    localStorage.removeItem('taskStorage');
    setOpenMapsDropDown(false);
    onClickMap(map);
  };
  
  return (
      <div className={styles.mapData}>
        <div
            className={styles.imgBlock}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
          {!isEmptyImg && hovered ? (
              <Tippy
                  className={styles.tooltip}
                  animation='fade'
                  interactive
                  delay={300}
                  placement='right'
                  offset='0, 1'
                  arrow={false}
                  content={
                    <div className={styles.tooltip}>
                      <span className={styles.arrow}/>
                      {map?.image_temp || !map?.image.includes('DEFAULT') ? copies.change_photo : copies.add_photo}
                    </div>
                  }
              >
                <div className={styles.changeImg} onClick={() => coverChangeImageInput3.current.click()}>
                  <img src='/assets/img/circleArrow.svg' alt=''/>
                  <input accept={['image/png', 'image/jpeg', 'image/jpg']}
                         type='file' ref={coverChangeImageInput3}/>
                </div>
              </Tippy>
          ) : (
              <img
                  className={styles.img}
                  src={
                      map?.image_temp ||
                      generateImageUrl({
                        imageId: map?.image,
                        width: 78,
                        height: 78,
                        reason: 'show_map_image',
                      })
                  }
                  alt=''
              />
          )}
        </div>
        <div className={styles.mapDescription} onClick={handleClick}>
          <div className={styles.mapName} style={{ cursor: maps.length > 1 ? 'pointer' : 'default' }}>
            <p className={styles.name}>{map?.name}</p>
            {maps.length > 1 && (
                <div className={styles.mapsDropdownWrapper}>
                  <img
                      className={classNames(styles.arrow, {
                        [styles.openedArrow]: openMapsDropDown,
                      })}
                      src='/assets/img/sideBarArrow.svg'
                      alt=''
                  />
                </div>
            )}
          </div>
          <span className={styles.mapDetails}>
                    {intToShortString(totalSteps, 1)} {totalSteps === 1 ? copies.step : copies.steps}
            {membersCount > 0 && (
                <>
                  <span style={{ margin: '0px 4px' }}>•</span>
                  {intToShortString(membersCount, 1) || ''} {mapSelector['members_count'] === 1 ? copies.member : copies.members}
                </>
            )}
                </span>
        </div>
        {openMapsDropDown && maps.length > 1 && (
            <ClickAwayListener onClickAway={() => setOpenMapsDropDown(false)}>
              <div className={styles.changeMapDropDown}>
                <div className={styles.mapList}>
                  {maps?.map(
                      mapValue =>
                          mapValue?._id !== mapId && (
                              <div key={mapValue?._id} onClick={() => clickArrow(mapValue)}>
                                <img
                                    src={generateImageUrl({
                                      imageId: mapValue?.image,
                                      width: 32,
                                      height: 32,
                                      reason: 'sidebar_image',
                                    })}
                                    alt=''
                                />
                                <span>{mapValue?.name}</span>
                              </div>
                          )
                  )}
                </div>
              </div>
            </ClickAwayListener>
        )}
      </div>
  );
};

export default MapPicker;
