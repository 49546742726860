import React, { useEffect } from 'react';
import styles from './index.module.scss';
import loaderAnimation from '../../../../../lottie/arrowAnimation.json';
import Lottie from 'react-lottie';
import { FileType } from '../../../../../helpers/constants';
import mixpanel, { mixpanelEvents, mixpanelProperties } from '../../../../../helpers/mixpanel';

const ImportLoader = ({ loaderCount, foundedSteps, mapName, type }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
    };

    useEffect(() => {
        if (type === FileType.EXCEL) {
            mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                [mixpanelProperties.PAGE]: 'loading',
                [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                [mixpanelProperties.FIRST_MAP]: parseInt(localStorage.getItem('temp_mapCount')) === 0,
                [mixpanelProperties.MAP_NAME]: mapName,
            });
        } else if (type === FileType.GOOGLE) {
            mixpanel.track(mixpanelEvents.OB_PAGE_OPEN, {
                [mixpanelProperties.PAGE]: 'loading',
                [mixpanelProperties.ADD_STEPS]: localStorage.getItem('addStepsChoose'),
                [mixpanelProperties.FIRST_MAP]: parseInt(localStorage.getItem('temp_mapCount')) === 0,
                [mixpanelProperties.MAP_NAME]: mapName,
            });
        }
    }, [type]);

    return (
        <div className={styles.importingBlock}>
            {type === FileType.EXCEL ? (
                <img src='/assets/img/steps3Images/icoExel.svg' alt='' />
            ) : (
                <img src='/assets/img/steps3Images/icoGoogleMaps.svg' alt='' />
            )}
            <Lottie options={defaultOptions} isStopped={false} isPaused={false} width={30} height={30} />
            <p className={styles.title}>{type === FileType.EXCEL ? 'Importing places to your map...' : 'Importing locations from Google My Maps'}</p>
            <span className={styles.percent}>{loaderCount}%</span>
            {(process.env.REACT_APP_STEPS_ENV === 'test' ? foundedSteps > 10 : foundedSteps > 500) && type === FileType.GOOGLE && (
                <p className={styles.textFoundedSteps}>
                    <b>Note:</b> A maximum of 500 locations from this import will be added to your map. <br /> Remaining locations will appear in your Pending
                    Steps page.
                </p>
            )}
        </div>
    );
};
export default ImportLoader;
