import { PlaceInfoInput } from "./PlaceInfoInput";

export const PlaceInfoPhone = ({ onChange, ...rest }) => {
  return (
      <PlaceInfoInput
          {...rest}
          onChange={value => {
            onChange(value.replace(/[^0-9]/g, ''));
          }}/>
  );
};