import CustomButton, { ButtonType } from '../../../../components/common/buttons/CustomButton';
import IconBackArrow from '../../../../components/common/icons/IconBackArrow';
import styles from './index.module.scss';

const BackButton = ({ handleBackClick }) => {
    return (
        <div className={styles.backBtn}>
            <CustomButton
                size={'largeSize'}
                onClick={handleBackClick}
                type={ButtonType.TERTIARY_BLACK}
                text={
                    <>
                        <IconBackArrow />
                        Back
                    </>
                }
            />
        </div>
    );
};

export default BackButton;
