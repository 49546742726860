import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { userSelectors } from '../../redux/user';
import { mapActions, mapSelectors } from '../../redux/map';
import { userActionActions } from '../../redux/userAction';
import GrowthCard, { GrowthCardType } from '../../components/GrowthCard';
import UpdatesCard from '../../components/UpdatesCard';
import GuideTasksRoll from '../../components/GuideTasksRoll';
import ROUTES from '../../config/routes';
import mixpanel, { mixpanelEvents } from '../../helpers/mixpanel';
import copiesPrefix from '../../copies.json';
import { LockSimple as LockIcon } from 'phosphor-react';

const copies = copiesPrefix.insights;

const Insights = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setSharePopup } = useOutletContext();
    const mapId = useSelector(userSelectors.selectedMapId);
    const map = useSelector(mapSelectors.map(mapId));
    const isLocked = map.flags.blockedFeatures.INSIGHTS;
    const insights = useSelector(mapSelectors.insights(mapId));
    // @TODO: Replace with real lock Data

    useEffect(() => {
        if (!mapId) return;
        dispatch(mapActions.getInsights(mapId));
        mixpanel.track(mixpanelEvents.MAP_INSIGHTS_OPEN);
    }, [mapId]);

    const moveToLevelsPage = () => navigate(`/${ROUTES.MAP}/${ROUTES.MAP_STAGES}`);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {copies.title}
                    {isLocked && <LockIcon className={styles.lock} color='#B5B7BB' size={20} />}
                </div>
                {isLocked && (
                    <p className={styles.subTitle}>
                        {copies.locked}{' '}
                        <span className={styles.bold} onClick={moveToLevelsPage}>
                            {copies.learnMore}
                        </span>
                    </p>
                )}
            </div>
            {!isLocked ? (
                <>
                    <div className={styles.growth}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span className={styles.growthTitle}>{copies.growth}</span>
                            <span className={styles.growthPeriodTitle}>{copies.growthPeriod}</span>
                        </div>
                        <div className={styles.cards}>
                            <div className={styles.growthCardSize}>
                                <GrowthCard
                                    growthCardType={GrowthCardType.Views}
                                    value={insights.map_views?.value}
                                    changedPrecent={insights.map_views?.change_percent}
                                />
                            </div>
                            <div className={styles.growthCardSize}>
                                <GrowthCard
                                    growthCardType={GrowthCardType.NewMember}
                                    value={insights.new_members?.value}
                                    changedPrecent={insights.new_members?.change_percent}
                                />
                            </div>
                            <div className={styles.growthCardSize}>
                                <GrowthCard
                                    growthCardType={GrowthCardType.Interactions}
                                    value={insights.interactions?.value}
                                    changedPrecent={insights.interactions?.change_percent}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.activityCards}>
                        <div style={{ height: '324px', width: '555px' }}>
                            <GuideTasksRoll
                                statusFlags={insights.map_flags}
                                onClickShare={() => setSharePopup?.(true)}
                                onClickAddStep={() => navigate(`/${ROUTES.MAP}/${ROUTES.ADD_STEP}`)}
                                onClickAddTags={() => navigate(`/${ROUTES.TAGS}`)}
                                onClickAddDescription={() => {
                                    dispatch(userActionActions.goto('description'));
                                    navigate(`/${ROUTES.MAP_SETTINGS_GENERAL}`);
                                }}
                                onClickAddImage={() => {
                                    dispatch(userActionActions.goto('image'));
                                    navigate(`/${ROUTES.MAP_SETTINGS_GENERAL}`);
                                }}
                            />
                        </div>
                        <div style={{ height: '324px', width: '555px' }}>
                            <UpdatesCard onClickViewAll={() => navigate(ROUTES.UPDATES)} />
                        </div>
                    </div>
                </>
            ) : (
                <img className={styles.emptyInsights} alt='' src='/assets/img/emptyInsights.png' />
            )}
        </div>
    );
};

export default Insights;
