export * from './user';
export * from './post';
export * from './stepTypes';
export * from './imagesSize';
export * from './platform';
export * from './screenNames';
export * from './selection';
export * from './branch';
export * from './tagListTypes';
export * from './map';

export const SITE_URL = 'https://www.atly.com';
export const MAP_MANAGER_URL = 'https://mapmanager.atly.com';