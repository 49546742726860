import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import { UserTypes } from '../../../../constants';
import CustomButton, { ButtonType } from '../../buttons/CustomButton';

import styles from './index.module.scss';

const ModalsChangePermissionMember = ({ role, displayName = '', onCancel = noop, onSuccess = noop }) => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <ClickAwayListener onClickAway={onCancel}>
                    <div className={styles.panel}>
                        <span className={styles.textFirst}>
                            <FormattedMessage
                                values={{ displayName, b: chunks => <b>{chunks}</b> }}
                                id={role === UserTypes.MEMBER ? 'members.make_user' : 'members.make_admin'}
                            />
                        </span>
                        <div className={styles.buttons}>
                            <CustomButton size={'mediumSize'} onClick={onCancel} type={ButtonType.SECONDARY_BLACK}>
                                <FormattedMessage id={'members.buttons.cancel'} />
                            </CustomButton>
                            <CustomButton size={'mediumSize'} onClick={onSuccess} type={ButtonType.PRIMARY_BLUE}>
                                <FormattedMessage id={'members.buttons.confirm'} />
                            </CustomButton>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default ModalsChangePermissionMember;
