import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const LoginInput = ({ onKeyDown, onChange, value, type, placeholder, error, errorText, setState, setTypePassword, typePassword, onClick }) => {
    return (
        <div className={styles.inputBlock}>
            <input
                onClick={onClick}
                type={type}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                className={classNames(styles.inputMail, {
                    [styles.inputError]: error,
                })}
                onChange={onChange}
                value={value}
            />
            {value?.length > 0 && typePassword && (
                <img
                    onClick={typePassword === 'password' ? () => setTypePassword('text') : () => setTypePassword('password')}
                    className={styles.showIcon}
                    src={typePassword === 'text' ? '/assets/img/steps3LoginLayout/iconHidden.svg' : '/assets/img/steps3LoginLayout/iconShow.svg'}
                    alt={''}
                />
            )}
            {value?.length > 0 && (
                <img onClick={() => setState('')} className={styles.clearIcon} src={'/assets/img/steps3LoginLayout/iconClear.svg'} alt={''} />
            )}
            {error && errorText && <span>{errorText}</span>}
            {error && <img className={styles.errorIcon} src={'/assets/img/steps3LoginLayout/iconError.svg'} alt={'errorIcon'} />}
        </div>
    );
};

export default LoginInput;
