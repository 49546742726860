export const SET_MAP_COVER_IMAGE = 'SET_MAP_COVER_IMAGE';
export const CLEAR_STEP_AND_MAP_IMAGES = 'CLEAR_STEP_AND_MAP_IMAGES';
export const CHANGE_GATES_STATUSES = 'CHANGE_GATES_STATUSES';
export const ADD_MAP_TAGS = 'ADD_MAP_TAGS';
export const ADD_NEW_TAGS_TO_MAP = 'ADD_NEW_TAGS_TO_MAP';
export const DELETE_TAGS = 'DELETE_TAGS';
export const EDIT_CATEGORY_NAME = 'EDIT_CATEGORY_NAME';
export const EDIT_CATEGORY_PRIORITY = 'EDIT_CATEGORY_PRIORITY';
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const ADD_NEW_CATEGORY_WITH_TAGS = 'ADD_NEW_CATEGORY_WITH_TAGS';
export const MOVE_TAG_TO_CATEGORY = 'MOVE_TAG_TO_CATEGORY';
export const EDIT_TAG_NAME = 'EDIT_TAG_NAME';
