import classNames from 'classnames';
import { noop } from 'lodash';
import { Avatar, AvatarSizesNames } from '../Avatar';
import { PostSecondaryHeadline } from '../PostSecondaryHeadline';

import copies from '../../../copies.json';
import styles from './PostHeadline.module.scss';
import { useContext, useEffect } from 'react';
import { UserTagPopover } from '../../common';
import { PopupContext } from '../../../context';

export const UserTypes = {
    USER: 'USER',
    ADMIN: 'ADMIN',
    STEPS_BOT: 'STEPS_BOT',
};

export const AnnouncementTag = () => {
    return <span className={styles.badge}>{copies.feed?.badge}</span>;
};

const PostHeadline = ({
    className,
    title,
    userName,
    userType,
    createdAt,
    onClick = noop,
    onClickTitle = noop,
    address = '',
    mainImage = '',
    secondaryImage = '',
    badge = null,
    userId,
    postId,
    isPinned = false,
    isStep = false,
    isTitleUserName = false,
    isScrolling = false,
    usernameHeadlineOnly = false,
}) => {
    const { activePopupId, setActivePopupId } = useContext(PopupContext);

    useEffect(() => {
        return () => {
            setActivePopupId(null);
        };
    }, []);

    return (
        <div className={classNames(styles.badgeWrapper, className)} onClick={onClick}>
            <div className={styles.wrapper} style={{ marginRight: secondaryImage ? '4px' : '0' }}>
                <div
                    className={classNames(styles.imagesWrapper, {
                        pointer: isStep,
                    })}
                    onClick={onClickTitle}
                >
                    <Avatar
                        src={mainImage}
                        size={secondaryImage ? AvatarSizesNames.SMALL : AvatarSizesNames.MEDIUM}
                        className={classNames(styles.userAvatarImage, {
                            [styles.secondary]: !!secondaryImage,
                            [styles.singleAvatarImage]: !secondaryImage,
                        })}
                    />
                    {secondaryImage && (
                        <div
                            src={secondaryImage}
                            alt={secondaryImage}
                            className={styles.secondaryImage}
                            style={{ backgroundImage: `url(${secondaryImage})` }}
                        />
                    )}
                </div>
            </div>
            <div className={styles.userInfo}>
                <UserTagPopover isActive={activePopupId === postId && isTitleUserName} userId={userId} key={postId} isScrolling={isScrolling}>
                    <span
                        className={classNames(styles.title, {
                            pointer: isStep || isTitleUserName,
                            bold: isTitleUserName,
                        })}
                        data-cy='user-badge-title'
                        onClick={() => {
                            if (isTitleUserName) {
                                setActivePopupId(postId);
                            } else {
                                onClickTitle();
                            }
                        }}
                    >
                        {title}
                    </span>
                </UserTagPopover>
                {!!address && <span className={styles.address}>{address}</span>}
                <PostSecondaryHeadline
                    isPinned={isPinned}
                    createdAt={createdAt}
                    shouldShowUsername={title !== userName}
                    userName={userName}
                    userType={userType}
                    badge={badge}
                    address={address}
                    userId={userId}
                    postId={postId}
                    isScrolling={isScrolling}
                />
            </div>
        </div>
    );
};

export default PostHeadline;
