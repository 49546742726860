import React, { useState, useEffect, useContext } from 'react';
import 'react-image-lightbox/style.css';
import styles from './index.module.scss';
import { ToastContext } from '../../../context';
import { modalsActions } from '../../../redux/modals';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { modalsSelectors } from '../../../redux/modals';
import { stepActions } from '../../../redux/step2';
import { userSelectors } from '../../../redux/user';
import { CaretLeft, CaretRight, X, DotsThree } from 'phosphor-react';
import BaseModal, { ModalSizes } from '../../../modalSysyem/BaseModal';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../lottie/circleLoader.json';
import ConfirmationModal, { ButtonType } from '../../modals/Confirmation';
import { generateImageUrl } from '../../../helpers';
import copiesPrefix from '../../../copies.json';
import context from '../../../constants/context';
import mixpanel, { mixpanelProperties, mixpanelEvents } from '../../../helpers/mixpanel';
import { mapSelectors } from '../../../redux/map';

const copies = copiesPrefix.imageFullScreenPreview;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
};

const ImagePreview = () => {
    const dispatch = useDispatch();
    const imagePreviewArray = useSelector(modalsSelectors.imagePreviewArray);
    const imagePreviewIndex = useSelector(modalsSelectors.imagePreviewIndex);
    const imagePreviewShow = useSelector(modalsSelectors.imagePreviewShow);
    const [photoIndex, setPhotoIndex] = useState(imagePreviewIndex || 0);
    const mapId = useSelector(userSelectors.selectedMapId);
    const currentImage = imagePreviewArray[photoIndex];
    const step = useSelector(mapSelectors.steps(mapId))[currentImage?.attachment?.step_id];
    const imagePreviewStepId = useSelector(modalsSelectors.imagePreviewStepId);
    const { setToast } = useContext(ToastContext);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [isDefaultModalOpen, setDefaultModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        if (isDeleteModalOpen)
            mixpanel.track(mixpanelEvents.FULLSCREEN_PHOTO_DELETE_PHOTO_CLICKED, {
                [mixpanelProperties.PHOTO_ID]: currentImage?.attachment?.image_id,
                [mixpanelProperties.STEP_ID]: currentImage?.attachment?.step_id,
                [mixpanelProperties.STEP_NAME]: step?.title,
            });
    }, [isDeleteModalOpen]);

    useEffect(() => {
        if (isDefaultModalOpen)
            mixpanel.track(mixpanelEvents.FULLSCREEN_PHOTO_SET_PHOTO_AS_DEFAULT_CLICKED, {
                [mixpanelProperties.PHOTO_ID]: currentImage?.attachment?.image_id,
                [mixpanelProperties.STEP_ID]: currentImage?.attachment?.step_id,
                [mixpanelProperties.STEP_NAME]: step?.title,
            });
    }, [isDefaultModalOpen]);

    const movePrev = () => {
        setPhotoIndex((photoIndex + imagePreviewArray.length - 1) % imagePreviewArray.length);
    };

    const moveNext = () => {
        setPhotoIndex((photoIndex + 1) % imagePreviewArray.length);
    };

    useEffect(() => {
        if (imagePreviewShow) {
            setIsLoaded(false);
            setPhotoIndex(imagePreviewIndex || 0);
        }
    }, [imagePreviewShow]);

    const getImageUri = attachment => {
        if (!attachment) return;
        if (attachment.src) return attachment.src;
        if (attachment.file) return attachment.file.url;
        return generateImageUrl({
            imageId: attachment.attachment.image_id,
            reason: 'mm_full_screen_preview',
        });
    };

    const onClose = () => {
        if (isDropDownOpen) {
            setIsDropDownOpen(false);
            return;
        }
        setDefaultModalOpen(false);
        setDeleteModalOpen(false);
        setIsLoaded(false);
        setPhotoIndex(-1);
        dispatch(
            modalsActions.toggleImagePreview({
                imagePreviewShow: false,
                imagePreviewArray: [],
                imagePreviewIndex: null,
            })
        );
    };

    const onClickMoreOptions = () => {
        if (!isDropDownOpen) {
            mixpanel.track(mixpanelEvents.FULLSCREEN_PHOTO_MORE_OPTIONS_CLICKED, {
                [mixpanelProperties.PHOTO_ID]: currentImage?.attachment?.image_id,
                [mixpanelProperties.STEP_ID]: currentImage?.attachment?.step_id,
                [mixpanelProperties.STEP_NAME]: step?.title,
            });
        }
        setIsDropDownOpen(!isDropDownOpen);
    };

    const onSetPhotoDefault = () => {
        mixpanel.track(mixpanelEvents.FULLSCREEN_PHOTO_DEFAULT_PHOTO_MENU_PHOTO_SET_AS_DEFAULT, {
            [mixpanelProperties.PHOTO_ID]: currentImage?.attachment?.image_id,
            [mixpanelProperties.STEP_ID]: currentImage?.attachment?.step_id,
            [mixpanelProperties.STEP_NAME]: step?.title,
        });
        dispatch(
            stepActions.updateStep(mapId, currentImage?.attachment?.step_id, {
                context: context.ENLARGED_PHOTO,
                thumbnail_image_id: currentImage?.attachment?.image_id,
            })
        );
        onClose();
        setToast({ text: copies.defaultModal.toastText });
    };

    const onDeletePhoto = () => {
        mixpanel.track(mixpanelEvents.FULLSCREEN_PHOTO_DELETE_PHOTO_MENU_PHOTO_DELETED, {
            [mixpanelProperties.PHOTO_ID]: currentImage?.attachment?.image_id,
            [mixpanelProperties.STEP_ID]: currentImage?.attachment?.step_id,
            [mixpanelProperties.STEP_NAME]: step?.title,
        });
        dispatch(
            stepActions.deleteImage(mapId, {
                imageId: currentImage?.attachment?.image_id,
                context: context.ENLARGED_PHOTO,
                stepId: currentImage?.attachment?.step_id,
                postId: currentImage?.attachment?.post_id,
            })
        );
        onClose();
        setToast({ text: copies.deleteModal.toastText });
    };

    return (
        <BaseModal className={styles.baseModal} isActive={imagePreviewShow} wraperClassName={styles.ModalStyles} size='None' onClose={onClose} withCloseIcon>
            {!isLoaded && (
                <Lottie
                    options={defaultOptions}
                    style={{ position: 'absolute', top: '50%', left: '50%' }}
                    isStopped={false}
                    isPaused={false}
                    width={100}
                    height={100}
                />
            )}
            <img className={styles.image} height={650} src={getImageUri(currentImage)} onLoad={() => setIsLoaded(true)} />
            {imagePreviewArray.length > 1 && (
                <>
                    <button data-testid='imagePreviewPrevButton' className={classNames([styles.button, styles.left])} onClick={movePrev}>
                        <CaretLeft size={20} color={'white'} />
                    </button>
                    <button data-testid='imagePreviewNextButton' className={classNames([styles.button, styles.right])} onClick={moveNext}>
                        <CaretRight size={20} color={'white'} />
                    </button>
                </>
            )}
            <div className={styles.topRight}>
                {!!currentImage?.attachment?.image_id && (
                    <button data-testid='settingFullScreen' onClick={onClickMoreOptions} className={classNames([styles.button])}>
                        <DotsThree size={20} color={'white'} />
                        {isDropDownOpen && (
                            <div className={styles.dropdown}>
                                <div
                                    className={styles.dropdownOption}
                                    onClick={() => {
                                        setDefaultModalOpen(true);
                                        setIsDropDownOpen(false);
                                    }}
                                >
                                    <span>{copies.defaultCTA}</span>
                                </div>
                                <div
                                    className={styles.dropdownOption}
                                    onClick={() => {
                                        setDeleteModalOpen(true);
                                        setIsDropDownOpen(false);
                                    }}
                                >
                                    <span style={{ color: '#E44F51' }}>{copies.deleteCTA}</span>
                                </div>
                            </div>
                        )}
                    </button>
                )}
                <button className={classNames([styles.button])} onClick={onClose}>
                    <X size={20} color={'white'} />
                </button>
            </div>
            <ConfirmationModal
                isOpen={isDefaultModalOpen}
                title={copies.defaultModal.title}
                withCancelButton
                onConfirm={onSetPhotoDefault}
                onClose={() => {
                    mixpanel.track(mixpanelEvents.FULLSCREEN_PHOTO_DEFAULT_PHOTO_MENU_CANCELLED, {
                        [mixpanelProperties.PHOTO_ID]: currentImage?.attachment?.image_id,
                        [mixpanelProperties.STEP_ID]: currentImage?.attachment?.step_id,
                        [mixpanelProperties.STEP_NAME]: step?.title,
                    });
                    setDefaultModalOpen(false);
                }}
                ctaTitle={copies.defaultModal.ctaTitle}
                description={copies.defaultModal.description}
            />
            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                title={copies.deleteModal.title}
                withCancelButton
                ctaTitle={copies.deleteModal.ctaTitle}
                onConfirm={onDeletePhoto}
                ctaType={ButtonType.DANGER}
                onClose={() => {
                    mixpanel.track(mixpanelEvents.FULLSCREEN_PHOTO_DELETE_PHOTO_MENU_CANCELLED, {
                        [mixpanelProperties.PHOTO_ID]: currentImage?.attachment?.image_id,
                        [mixpanelProperties.STEP_ID]: currentImage?.attachment?.step_id,
                        [mixpanelProperties.STEP_NAME]: step?.title,
                    });
                    setDeleteModalOpen(false);
                }}
                description={copies.deleteModal.description}
            />
        </BaseModal>
    );
};
export default ImagePreview;
