import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { userActionActions } from '../../redux/userAction';
import { useQuery } from '../../hooks/useQuery';
import ROUTES from '../../config/routes';

const TokenCheck = ({ children }) => {
    const query = useQuery();
    const dispatch = useDispatch();

    if (!query.expiry || Date.now() > parseInt(query.expiry)) {
        dispatch(userActionActions.handleExpiredForgotToken(true));
        return <Navigate to={ROUTES.FORGOT_PASSWORD}/>;
    }

    if (!query.token) {
        return <Navigate to='/'/>;
    }

    return children;
};

export default TokenCheck;
